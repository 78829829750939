import { Injectable } from '@angular/core';
import {
  AdditionalBenefitDataPreset,
  BasedOrderTotalCalculation,
  BasedProductAmount,
  SeparationType,
  DiscountCodeTarget,
  DiscountCodeType,
  MaxAmountBenefitReview,
  MinPurchaseOption,
  MinRequirement,
  OrderStatusReviewWriteAvailable,
  ReviewBenefitAccessRestriction,
  ReviewBenefitProvisionPolicyDetail,
  ReviewBenefitRestriction,
  ReviewCommonPolicy,
  ReviewManipulationPoint,
  ShippingStatus,
  ShopBenefit,
  PriceRule,
  MinPurchaseAmountOption,
  AdditionalBenefitType,
  MappedAdditionalBenefit,
  AdditionalBenefit,
} from 'src/app/models/shop/auth/benefit/benefit.model';
import { BackendService } from 'src/app/service/backend.service';
import { cloneDeep, isEqual } from 'lodash';
import { AdminService } from 'src/app/service/admin.service';
import { GenericService } from 'src/app/service/generic.service';
import _ from 'lodash';
import { ReviewType } from 'src/app/models/review/review.model';

@Injectable({
  providedIn: 'root',
})
export class SettingBenefitService {
  constructor(private backendService: BackendService, private genericService: GenericService, private adminService: AdminService) {}

  ShopBenefit!: ShopBenefit;
  OriginShopBenefit!: ShopBenefit;
  ReviewBenefitProvisionPolicyDetail!: ReviewBenefitProvisionPolicyDetail;
  OriginReviewBenefitProvisionPolicyDetail!: ReviewBenefitProvisionPolicyDetail;
  mappedAdditionalBenefit!: MappedAdditionalBenefit[];
  benefitReviewType!: ReviewType | undefined;

  checkBenefitDetailChanged() {
    return !isEqual(this.ReviewBenefitProvisionPolicyDetail, this.OriginReviewBenefitProvisionPolicyDetail);
  }

  checkShopBenefitChanges() {
    return (
      isEqual(this.ShopBenefit.review_benefit_provision_policy, this.OriginShopBenefit.review_benefit_provision_policy) &&
      isEqual(this.ShopBenefit.review_benefit_restriction_policy, this.OriginShopBenefit.review_benefit_restriction_policy)
    );
  }

  getShopReviewBenefit(params: { shop_access_code: string }) {
    let url = 'review-benefit/';

    return this.backendService.select(url, params);
  }

  updateShopReviewBenefit(shopBenefit: ShopBenefit) {
    let url = 'review-benefit/';

    return this.backendService.patch(url, shopBenefit, {
      shop_access_code: this.adminService.getShopAccessCode(),
    });
  }

  getReviewBenefitProvisionPolicyDetail(benefitReviewType: ReviewType) {
    let url = 'review-benefit/detail';

    return this.backendService.select(url, {
      shop_access_code: this.adminService.getShopAccessCode(),
      review_type: benefitReviewType,
    });
  }

  updateReviewBenefitProvisionPolicyDetail(reviewBenefitDetail: ReviewBenefitProvisionPolicyDetail) {
    let url = 'review-benefit/detail/' + reviewBenefitDetail.id;

    return this.backendService.update(url, reviewBenefitDetail);
  }

  sortReviewBenefitProvisionPolicyDetail(detail: ReviewBenefitProvisionPolicyDetail) {
    detail.additional_benefits = detail.additional_benefits.sort((a, b) => {
      return a.type! - b.type! || a.position! - b.position!;
    });
  }

  mapReviewBenefitProvisionPolicyDetail() {
    // if (this.mappedAdditionalBenefit?.length) {
    //   const additionalBenefits: AdditionalBenefit[] = []
    //   this.mappedAdditionalBenefit.forEach(benefit => benefit.benefits?.forEach(additionalBenefit => {
    //     additionalBenefits.push(additionalBenefit)
    //   }))
    //   this.ReviewBenefitProvisionPolicyDetail.additional_benefits = additionalBenefits
    // }
    this.mappedAdditionalBenefit = _(this.ReviewBenefitProvisionPolicyDetail?.additional_benefits)
      .groupBy((benefit) => benefit.type!)
      .map((benefits, type) => ({ type, benefits }))
      .value() as any;
    this.mappedAdditionalBenefit.forEach((additionalBenefit) => {
      additionalBenefit.benefits?.forEach((benefit) => (benefit.position = additionalBenefit.benefits?.indexOf(benefit)));
    });
  }

  setReviewBenefitProvisionPolicyDetail(detail: ReviewBenefitProvisionPolicyDetail) {
    this.mapReviewBenefitProvisionPolicyDetail();
    this.sortReviewBenefitProvisionPolicyDetail(detail);
    this.ReviewBenefitProvisionPolicyDetail = cloneDeep(detail);
    this.OriginReviewBenefitProvisionPolicyDetail = cloneDeep(detail);
  }

  getPriceRule(params: { id?: number } = {}) {
    let url = `review-benefit/price-rule/${params.id}`;

    return this.backendService.select(url, params);
  }

  updatePriceRule(priceRule: PriceRule, params: {} = {}) {
    let url = `review-benefit/price-rule/${priceRule.id}`;

    return this.backendService.update(url, priceRule, params);
  }

  public get reviewCommonPolicy(): typeof ReviewCommonPolicy {
    return ReviewCommonPolicy;
  }

  public get basedProductAmount(): typeof BasedProductAmount {
    return BasedProductAmount;
  }

  public get basedOrderTotalCalculation(): typeof BasedOrderTotalCalculation {
    return BasedOrderTotalCalculation;
  }

  public get MinPurchaseOption(): typeof MinPurchaseOption {
    return MinPurchaseOption;
  }

  public get MaxAmountBenefitReview(): typeof MaxAmountBenefitReview {
    return MaxAmountBenefitReview;
  }

  public get ShippingStatus(): typeof ShippingStatus {
    return ShippingStatus;
  }

  public get ReviewBenefitRestriction(): typeof ReviewBenefitRestriction {
    return ReviewBenefitRestriction;
  }

  public get OrderStatusReviewWriteAvailable(): typeof OrderStatusReviewWriteAvailable {
    return OrderStatusReviewWriteAvailable;
  }

  public get ReviewBenefitAccessRestriction(): typeof ReviewBenefitAccessRestriction {
    return ReviewBenefitAccessRestriction;
  }

  public get ReviewManipulationPoint(): typeof ReviewManipulationPoint {
    return ReviewManipulationPoint;
  }

  public get DiscountCodeTarget(): typeof DiscountCodeTarget {
    return DiscountCodeTarget;
  }

  public get MinRequirement(): typeof MinRequirement {
    return MinRequirement;
  }

  public get DiscountCodeType(): typeof DiscountCodeType {
    return DiscountCodeType;
  }

  public get SeparationType(): typeof SeparationType {
    return SeparationType;
  }

  public get MinPurchaseAmountOption(): typeof MinPurchaseAmountOption {
    return MinPurchaseAmountOption;
  }

  public get ReviewType(): typeof ReviewType {
    return ReviewType;
  }

  public get AdditionalBenefitType(): typeof AdditionalBenefitType {
    return AdditionalBenefitType;
  }

  backgroundColors = {
    order: '#ffeccb',
    order_product: '#e8fcf3',
    customer: '#e3f0ff',
    maintenance: '#ffe8e5',
    quality: '#eeeefe',
  };

  colors = {
    order: '#ffa251',
    order_product: '#7bcbc3',
    customer: '#589bff',
    maintenance: '#ff6d6d',
    quality: '#837aff',
  };

  additionalBenefitDataPreset: AdditionalBenefitDataPreset[] = [
    {
      category: 'Quality',
      name: 'Text Review',
      type: AdditionalBenefitType.TEXT_REVIEW,
      desc: 'You can offer additional rewards depending on the number of letters in the text review',
      backgroundColor: this.backgroundColors.quality,
      color: this.colors.quality,
    },
    {
      category: 'Quality',
      name: 'Photo Review',
      type: AdditionalBenefitType.PHOTO_REVIEW,
      desc: 'You can offer additional rewards depending on the number of images in the photo review',
      backgroundColor: this.backgroundColors.quality,
      color: this.colors.quality,
    },
    // {
    //   category: "Quality",
    //   name: 'Video Review',
    //   type: AdditionalBenefitType.VIDEO_REVIEW,
    //   desc: 'You can offer additional rewards depending on the length of video review',
    //   backgroundColor: this.backgroundColors.quality,
    //   color: this.colors.quality,
    // },
    // {
    //   category: "Quality",
    //   name: 'Survey Review',
    //  type: AdditionalBenefitType.SURVEY_REVIEW,
    //   desc: 'You can offer additional rewards depending on the number of answered questions',
    //   backgroundColor: this.backgroundColors.quality,
    //   color: this.colors.quality,
    // },
    // {
    //   category: "Order",
    //   name: 'First Review in the product',
    //   type: AdditionalBenefitType.VIDEO_REVIEW,
    //   desc: 'You can provide additional rewards for the 1st ~ 5th media reviews of the product.',
    //   backgroundColor: this.backgroundColors.order,
    //   color: this.colors.order,
    // },
    // {
    //   category: "Order",
    //   name: 'First Review after signing up',
    //   type: AdditionalBenefitType.VIDEO_REVIEW,
    //   desc: 'You can provide additional rewards for the first media review ever after signing up',
    //   backgroundColor: this.backgroundColors.order,
    //   color: this.colors.order,
    // },
    // {
    //   category: "Product/Order",
    //   name: 'Order&Product Amount',
    //   type: AdditionalBenefitType.PRODUCT_AND_ORDER_AMOUNT,
    //   desc: 'You can provide additional rewards to whom wrote a review on a product/order over certain amount',
    //   backgroundColor: this.backgroundColors.order_product,
    //   color: this.colors.order_product,
    // },
    // {
    //   category: "Product/Order",
    //   name: 'Specific product',
    //   type: AdditionalBenefitType.SPECIFIC_PRODUCT,
    //   desc: 'You can provide additional rewards to whom wrote a review on a Specific product/collection',
    //   backgroundColor: this.backgroundColors.order_product,
    //   color: this.colors.order_product,
    // },
    // {
    //   category: "Customer",
    //   name: 'Customer Segment',
    //   type: AdditionalBenefitType.CUSTOMER_SEGMENT,
    //   desc: 'You can provide additional rewards to customers in certain customer segments',
    //   backgroundColor: this.backgroundColors.customer,
    //   color: this.colors.customer,
    // },
  ];

  getAdditionalBenefitDataPreset(type: AdditionalBenefitType) {
    const result = this.additionalBenefitDataPreset.filter((item) => item.type == type)[0];
    return result ? result : undefined;
  }
}
