import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private backendService: BackendService) { }

  getCustomer(params: {
    customer_id: number,
    shop_access_code: string,
  }) {
    let url = `customers/${params.customer_id}`
    return this.backendService.select(url, params)
  }
}
