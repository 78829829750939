import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalysisLog } from 'src/app/models/ai/ai.model';
import { WritingPlatform } from 'src/app/models/review/review.model';
import { AlphaApps } from 'src/app/models/shop/profile/store-detail.model';
import { ReviewAppPlatform } from 'src/app/models/shop/review/options/review-import.model';
import { AdminService } from 'src/app/service/admin.service';
import { AiService } from 'src/app/service/ai.service';
import { GenericService } from 'src/app/service/generic.service';

@Component({
  selector: 'app-analysis-detail',
  templateUrl: './analysis-detail.component.html',
  styleUrls: ['./analysis-detail.component.less'],
})
export class AnalysisDetailComponent implements OnInit {
  constructor(public aiService: AiService, private genericService: GenericService, public adminService: AdminService, private router: Router) {
    this.aiId = history.state.id;
    this.platform = history.state.platform;
    if (!this.aiId) history.back();
  }

  ngOnInit(): void {
    if (this.aiId && this.platform) this.aiService.getAnalysisDetail(this.platform, this.aiId);
    if (this.platform == ReviewAppPlatform.ALI_EXPRESS) this.genericService.LNBCategory = 'aliexpress-analysis';
  }
  aiId!: number;
  platform!: ReviewAppPlatform;

  onClickImportBtn(log: AnalysisLog) {
    if (log.is_review_saved) {
      this.adminService.ShopAdminAccount.shop?.shop_detail?.use_apps.includes(AlphaApps.REVIEW)
        ? this.router.navigate(['/admin/product-reviews/reviews'], { state: { import: { writing_platform: WritingPlatform.ALIEXPRESS, product_id: log.product_id } } })
        : this.aiService.openDialogStartAlphaReview();
    } else this.aiService.openDialogProductVariantSelect(log, true);
  }
}
