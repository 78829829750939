"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.unstable_Picker = exports.loadMore = exports.search = exports.update = exports.cancel = exports.open = exports.select = exports.ALL_RESOURCE_VERTICAL_ALIGNMENT = exports.ALL_MEDIA_KINDS = exports.ALL_BADGE_STATUSES = exports.ALL_BADGE_PROGRESSES = exports.Action = void 0;
var helper_1 = require("../helper");
var ActionSet_1 = require("../ActionSet");
var types_1 = require("../types");
var Action;
(function (Action) {
    Action["OPEN"] = "APP::PICKER::OPEN";
    Action["SELECT"] = "APP::PICKER::SELECT";
    Action["UPDATE"] = "APP::PICKER::UPDATE";
    Action["CANCEL"] = "APP::PICKER::CANCEL";
    Action["SEARCH"] = "APP::PICKER::SEARCH";
    Action["LOAD_MORE"] = "APP::PICKER::LOAD_MORE";
})(Action = exports.Action || (exports.Action = {}));
/**
 * To be used on validator as matchEnum(...). Make sure as new values are added to update
 * the items below
 *
 * Note: Intentionally not using an `enum type` directly as this would cause a dependency
 * of the same enum on the consumer API rather than just the string values.
 */
exports.ALL_BADGE_PROGRESSES = [
    'incomplete',
    'partiallyComplete',
    'complete',
];
exports.ALL_BADGE_STATUSES = [
    'success',
    'info',
    'attention',
    'critical',
    'warning',
    'new',
];
exports.ALL_MEDIA_KINDS = ['Avatar', 'Thumbnail'];
exports.ALL_RESOURCE_VERTICAL_ALIGNMENT = [
    'leading',
    'trailing',
    'center',
];
function select(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.unstable_Picker,
        type: Action.SELECT,
    });
}
exports.select = select;
function open(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.unstable_Picker,
        type: Action.OPEN,
    });
}
exports.open = open;
function cancel(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.unstable_Picker,
        type: Action.CANCEL,
    });
}
exports.cancel = cancel;
function update(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.unstable_Picker,
        type: Action.UPDATE,
    });
}
exports.update = update;
function search(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.unstable_Picker,
        type: Action.SEARCH,
    });
}
exports.search = search;
function loadMore(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.unstable_Picker,
        type: Action.LOAD_MORE,
    });
}
exports.loadMore = loadMore;
/**
 * @unstable This API may be updated without warning in the future
 */
var unstable_Picker = /** @class */ (function (_super) {
    __extends(unstable_Picker, _super);
    function unstable_Picker(app, options) {
        var _this = _super.call(this, app, types_1.Group.unstable_Picker, types_1.Group.unstable_Picker) || this;
        _this.items = [];
        _this.selectedItems = [];
        _this.set(options, false);
        return _this;
    }
    Object.defineProperty(unstable_Picker.prototype, "payload", {
        get: function () {
            return __assign(__assign({}, this.options), { id: this.id });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(unstable_Picker.prototype, "options", {
        get: function () {
            return {
                items: this.items,
                maxSelectable: this.maxSelectable,
                selectedItems: this.selectedItems,
                title: this.title,
                loading: this.loading,
                searchQuery: this.searchQuery,
                searchQueryPlaceholder: this.searchQueryPlaceholder,
                primaryActionLabel: this.primaryActionLabel,
                secondaryActionLabel: this.secondaryActionLabel,
                emptySearchLabel: this.emptySearchLabel,
                canLoadMore: this.canLoadMore,
                loadingMore: this.loadingMore,
                verticalAlignment: this.verticalAlignment,
                allowEmptySelection: this.allowEmptySelection,
                resourceName: this.resourceName,
            };
        },
        enumerable: false,
        configurable: true
    });
    unstable_Picker.prototype.set = function (options, shouldUpdate) {
        if (shouldUpdate === void 0) { shouldUpdate = true; }
        var mergedOptions = helper_1.getMergedProps(this.options, options);
        var _a = mergedOptions.selectedItems, selectedItems = _a === void 0 ? [] : _a, _b = mergedOptions.maxSelectable, maxSelectable = _b === void 0 ? 0 : _b, _c = mergedOptions.items, items = _c === void 0 ? [] : _c, _d = mergedOptions.loading, loading = _d === void 0 ? false : _d, title = mergedOptions.title, searchQuery = mergedOptions.searchQuery, searchQueryPlaceholder = mergedOptions.searchQueryPlaceholder, primaryActionLabel = mergedOptions.primaryActionLabel, secondaryActionLabel = mergedOptions.secondaryActionLabel, emptySearchLabel = mergedOptions.emptySearchLabel, _e = mergedOptions.canLoadMore, canLoadMore = _e === void 0 ? false : _e, _f = mergedOptions.loadingMore, loadingMore = _f === void 0 ? false : _f, verticalAlignment = mergedOptions.verticalAlignment, allowEmptySelection = mergedOptions.allowEmptySelection, resourceName = mergedOptions.resourceName;
        this.title = title;
        this.items = items;
        this.selectedItems = selectedItems;
        this.maxSelectable = maxSelectable;
        this.loading = loading;
        this.searchQuery = searchQuery;
        this.searchQueryPlaceholder = searchQueryPlaceholder;
        this.primaryActionLabel = primaryActionLabel;
        this.secondaryActionLabel = secondaryActionLabel;
        this.emptySearchLabel = emptySearchLabel;
        this.canLoadMore = canLoadMore;
        this.loadingMore = loadingMore;
        this.verticalAlignment = verticalAlignment;
        this.allowEmptySelection = allowEmptySelection;
        this.resourceName = resourceName;
        if (shouldUpdate) {
            this.update();
        }
        return this;
    };
    unstable_Picker.prototype.dispatch = function (action, payload) {
        if (action === Action.OPEN) {
            this.open();
        }
        else if (action === Action.UPDATE) {
            this.update();
        }
        else if (action === Action.CANCEL) {
            this.cancel();
        }
        else if (action === Action.SELECT) {
            this.selectedItems = (payload === null || payload === void 0 ? void 0 : payload.selectedItems) || [];
            this.app.dispatch(select({ id: this.id, selectedItems: this.selectedItems }));
        }
        else if (action === Action.SEARCH) {
            this.searchQuery = (payload === null || payload === void 0 ? void 0 : payload.searchQuery) || '';
            this.app.dispatch(search({ id: this.id, searchQuery: this.searchQuery }));
        }
        else if (action === Action.LOAD_MORE) {
            this.loadMore();
        }
        return this;
    };
    unstable_Picker.prototype.update = function () {
        this.app.dispatch(update(this.payload));
    };
    unstable_Picker.prototype.open = function () {
        this.app.dispatch(open(this.payload));
    };
    unstable_Picker.prototype.cancel = function () {
        this.app.dispatch(cancel({ id: this.id }));
    };
    unstable_Picker.prototype.loadMore = function () {
        this.app.dispatch(loadMore(this.payload));
    };
    return unstable_Picker;
}(ActionSet_1.ActionSet));
exports.unstable_Picker = unstable_Picker;
