export class ShopNotice {
  id?: number
  shop_id?: number
  title?: string
  content_pc?: string
  content_mobile?: string
  status?: NoticeStatus
  views?: number
  posting_start?: Date
  posting_end?: Date
  created_at?: Date
  writer?: string
  posting_at?: NoticePosition
  update: boolean = false
}

export enum NoticeType {
  NOTICE = 1,
  EVENT = 2
}


export enum NoticeStatus {
  BEFORE_POSTING = 1,
  POSTING = 2,
  END_POSTING = 3,
}


export enum NoticePosition {
  REVIEW_WRITING_PAGE = 1,
  HOMEPAGE = 2,
}


