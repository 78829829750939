import { Component, EventEmitter, Input, OnInit, Optional, Output, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import { Product, ProductInReviewImport, ProductReviewImportLimit } from 'src/app/models/product/product.model';
import { AdminService } from 'src/app/service/admin.service';
import { BackendService } from 'src/app/service/backend.service';
import { BillingService } from 'src/app/service/billing.service';
import { GenericService } from 'src/app/service/generic.service';
import { ReviewService } from 'src/app/service/review.service';
import { ImportService } from '../import.service';
import { ReviewAppPlatform, ReviewImportLog, TransferStatus } from 'src/app/models/shop/review/options/review-import.model';
import { DialogReviewImportMigrationComponent } from '../dialog-review-import-migration/dialog-review-import-migration.component';
import { DialogReviewImportDoneComponent } from '../dialog-review-import-done/dialog-review-import-done.component';
import { DialogTemplateDeleteComponent } from '../../../dialog/dialog-template-delete/dialog-template-delete.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-template-import',
  templateUrl: './template-import.component.html',
  styleUrls: ['./template-import.component.less'],
})
export class TemplateImportComponent implements OnInit {
  @Input('product') product!: ProductInReviewImport;
  @Input('importPlatform') importPlatform!: ReviewAppPlatform | 0;
  @Output() deletedAll = new EventEmitter<boolean>();
  @Output() importStart = new EventEmitter<boolean>();
  constructor(
    @Optional() @SkipSelf() private adminService: AdminService,
    @Optional() @SkipSelf() public billingService: BillingService,
    public importService: ImportService,
    private genericService: GenericService,
    private reviewService: ReviewService,
    private backendService: BackendService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  checkProductReviewImportLimitPlatform(limits: ProductReviewImportLimit[], platform: ReviewAppPlatform) {
    return limits.filter((item) => item.import_platform == platform).length;
  }

  checkDisableReviewImportBtn(limits: ProductReviewImportLimit[], platform: ReviewAppPlatform) {
    if (this.billingService.shopBilling?.plan == this.billingService.ShopBillingPlan.PREMIUM) {
      return false;
    }

    const limit = limits.filter((item) => item.import_platform == platform);
    if (limit.length) {
      const target = limit[0];

      if (platform == ReviewAppPlatform.ALI_EXPRESS) {
        return target.imported_review_count >= this.importService.LIMIT_IMPORT_REVIEW_ALIEXPRESS;
      } else if (platform == ReviewAppPlatform.AMAZON) {
        return target.imported_review_count >= this.importService.LIMIT_IMPORT_REVIEW_AMAZON;
      }
    }

    return false;
  }

  openDialogReviewImportMigration(product: Product) {
    const dialogRef = this.dialog.open(DialogReviewImportMigrationComponent, {
      width: '470px',
      maxHeight: '90vh',
      data: { product: product, import_platform: this.importPlatform },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openDialogReviewImportDone({ reviewImportLog: result, product: product });
        if (this.importPlatform === ReviewAppPlatform.ALI_EXPRESS) this.importStart.emit(true);
      }
    });
  }

  openDialogReviewImportDone(data: { reviewImportLog: ReviewImportLog; product: Product }) {
    const dialogRef = this.dialog.open(DialogReviewImportDoneComponent, {
      width: this.getImportProcessDialogWidth(data.reviewImportLog),
      data: data,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      if (result == 'retry') this.openDialogReviewImportMigration(data.product);
      if (this.importPlatform === ReviewAppPlatform.AMAZON) this.importStart.emit(true);
    });
  }

  getImportProcessDialogWidth(reviewImportLog: ReviewImportLog) {
    let width = '550px';
    if (reviewImportLog.app_platform == ReviewAppPlatform.ALI_EXPRESS) {
      width = '550px';
    } else if (reviewImportLog.app_platform == ReviewAppPlatform.AMAZON) {
      if (reviewImportLog.progress_status == TransferStatus.IN_PROGRESS) {
        width = '480px';
      } else if (reviewImportLog.progress_status == TransferStatus.FAILED || reviewImportLog.progress_status == TransferStatus.SUCCESSED) {
        width = '520px';
      }
    }
    return width;
  }

  viewAllReview(productId: number) {
    let writingPlatform = this.importPlatform == ReviewAppPlatform.ALI_EXPRESS ? this.reviewService.WritingPlatform.ALIEXPRESS : this.reviewService.WritingPlatform.AMAZON;
    this.router.navigate(['/admin/product-reviews/reviews'], {
      state: {
        import: {
          writing_platform: writingPlatform,
          product_id: productId,
        },
      },
    });
  }

  deleteAllProductImportReview(product: ProductInReviewImport) {
    const dialogRef = this.dialog.open(DialogTemplateDeleteComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;

      let url = `reviews/import/product/${product.id}/reviews`;

      product._deleteLock = true;
      this.backendService
        .delete(url, {
          shop_access_code: this.adminService.getShopAccessCode(),
          product_id: product.id,
          import_platform: this.importPlatform,
        })
        .subscribe({
          next: (response) => {
            this.deletedAll.emit(result);
            this.genericService.openSnackBar('Task complete.');
          },
          error: (error) => {
            product._deleteLock = false;
          },
        });
    });
  }

  get ReviewAppPlatform(): typeof ReviewAppPlatform {
    return ReviewAppPlatform;
  }
}
