<article id="dialog-install-widget-guide">
  <section class="flex-wrapper">
    <header class="admin-header-1">How to apply my widget in Shopify</header>
    <img (click)="onClose()" src="assets/icon/close_big_gray.svg" style="align-self: flex-start; cursor: pointer" />
  </section>

  <section class="guide-slides">
    <swiper #guideSwiper [config]="swiperConfig" (slideChange)="getSlideIndex()">
      <ng-template swiperSlide>
        <img src="assets/image/list-designer/copy_code_1.png" />
        <div class="guide-slides-info">
          <div class="title bold">Please <span class="focus">Copy</span> the Code</div>
          <div class="code-copy flex-wrapper">
            {{ code || 'WidgetID' }}
            <div (click)="copyWidgetId(code)" class="btn-white" style="width: 56px">Copy</div>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <img src="assets/image/list-designer/core_script.gif" />
        <div class="guide-slides-info">
          <div class="title bold"><span class="focus">Activate</span> the core script in Shopify.</div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <img src="assets/image/list-designer/add_app_block.gif" />
        <div class="guide-slides-info">
          <div class="title bold"><span class="focus">Add</span> an app block in the desired location.</div>
        </div>
      </ng-template>

      <ng-template swiperSlide>
        <img src="assets/image/list-designer/copy_code_2.gif" />
        <div class="guide-slides-info">
          <div class="title bold"><span class="focus">Paste the copied code</span> to the added app block!</div>
        </div>
      </ng-template>
    </swiper>

    <div class="prev swiper-btn" (click)="swipePrev()" [ngClass]="{ hide: slideIndex === 1 }">
      <img src="assets/icon/prev_light.svg" />
    </div>
    <div class="next swiper-btn" (click)="swipeNext()" [ngClass]="{ hide: slideIndex == 4 }">
      <img src="assets/icon/next_light.svg" />
    </div>
  </section>
  <section class="flex-wrapper" style="position: relative">
    @if (slideIndex! > 1) {
    <div (click)="swipePrev()" class="bold" style="cursor: pointer" [@inOutAnimation]>Previous</div>
    }
    <div class="pagination-wrapper">
      <span class="pagination" [ngClass]="{ active: slideIndex! <= 4 }"></span>
      <span class="pagination" [ngClass]="{ active: slideIndex! > 1 && slideIndex! <= 4 }"></span>
      <span class="pagination" [ngClass]="{ active: slideIndex! > 2 && slideIndex! <= 4 }"></span>
      <span class="pagination" [ngClass]="{ active: slideIndex! == 4 }"></span>
    </div>
    <div (click)="slideIndex! == 4 ? goToThemeEditor() : swipeNext()" class="btn-black" style="margin-left: auto" [ngStyle]="{ width: slideIndex! == 4 ? '185px' : '64px' }">
      {{ slideIndex! == 4 ? 'Go to apply the widget' : 'Next' }}
    </div>
  </section>
</article>
