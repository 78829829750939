import { Component, OnInit } from '@angular/core';
import { SettingMessagingService } from '../../../../service/setting-messaging.service';
import { BackendService } from 'src/app/service/backend.service';
import { AdminService } from 'src/app/service/admin.service';

@Component({
  selector: 'app-touchpoints-notification',
  templateUrl: './touchpoints-notification.component.html',
  styleUrls: ['./touchpoints-notification.component.less'],
})
export class TouchpointsNotificationComponent implements OnInit {
  constructor(public settingMessagingService: SettingMessagingService, public backendService: BackendService, public adminService: AdminService) {}

  ngOnInit(): void {
    this.settingMessagingService.getMessagingNotificationList();
  }

  ngAfterViewInit(): void {}
}
