"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isV1Config = exports.MessageType = exports.LifecycleHook = exports.PermissionType = void 0;
var types_1 = require("@shopify/app-bridge-core/client/types");
Object.defineProperty(exports, "MessageType", { enumerable: true, get: function () { return types_1.MessageType; } });
var types_2 = require("@shopify/app-bridge-core/client/types");
Object.defineProperty(exports, "PermissionType", { enumerable: true, get: function () { return types_2.PermissionType; } });
Object.defineProperty(exports, "LifecycleHook", { enumerable: true, get: function () { return types_2.LifecycleHook; } });
/**
 * @internal
 * @param config
 */
function isV1Config(config) {
    return !config.host;
}
exports.isV1Config = isV1Config;
