import { Component, NgZone, OnInit, Optional, SkipSelf } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { cloneDeep, isEqual } from 'lodash';
import { Shop, ShopAdminAccount } from 'src/app/models/shop/shop.model';
import { AlphaApps } from 'src/app/models/shop/profile/store-detail.model';
import { AdminService } from 'src/app/service/admin.service';
import { ShopService } from 'src/app/service/shop.service';
import { inOutAnimation } from '../../animations';
import { BillingService } from 'src/app/service/billing.service';
import { GenericService } from 'src/app/service/generic.service';
import { ShopBilling } from 'src/app/models/billing/billing.model';

@Component({
  selector: 'global-navigation-bar',
  templateUrl: './global-navigation-bar.component.html',
  styleUrls: ['./global-navigation-bar.component.less'],
  animations: [inOutAnimation],
})
export class GlobalNavigationBarComponent implements OnInit {
  constructor(
    private route: Router,
    @Optional() @SkipSelf() public adminService: AdminService,
    @Optional() @SkipSelf() public shopService: ShopService,
    @Optional() @SkipSelf() public billingService: BillingService,
    public genericService: GenericService,
  ) {
    this.route.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url.includes('admin/onboarding')) {
          this.isOnboarding = true;
        } else {
          this.isOnboarding = false;
        }
      }
    });
  }

  ngOnInit(): void {
    if (this.route.url.includes('admin/onboarding')) {
      this.isOnboarding = true;
    } else {
      this.isOnboarding = false;
      this.shops = this.adminService.ShopAdminAccount?.shops;
      this.selectedShop = this.shops?.filter((item) => item.access_code == this.adminService.ShopAdminAccount.shop?.access_code)[0];
    }
  }

  onChangeSelectedShop(shop: Shop) {
    this.adminService.setAdminAccountSession(this.adminService.ShopAdminAccount, shop);
    this.shopService.currency = this.adminService.ShopAdminAccount?.shop!.currency as string;
    this.route.navigateByUrl('.', { skipLocationChange: true }).then(() => {
      this.route.navigate(['admin', 'market-place']);
    });

    this.updateBillingInfo();
  }

  updateBillingInfo() {
    this.billingService.getBilling().subscribe((response) => {
      this.billingService.shopBilling = response.body as ShopBilling;
      this.billingService.originShopBilling = cloneDeep(response.body as ShopBilling);
    });
  }

  shops: Shop[] = [];
  selectedShop!: Shop;

  shop_logo = 'favicon.ico';

  isOnboarding: boolean = false;

  showUser: boolean = false;
  showHelp: boolean = false;

  goToExternalLink(url: string) {
    window.open(url, '_blank');
  }
}
