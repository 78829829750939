<article
  id="alpha-datepicker"
  class="admin-input"
  (mouseenter)="display = true"
  (mouseleave)="display = false"
  [ngStyle]="{ 'min-width': mode == 'dateRange' ? '240px' : '' }"
  [matMenuTriggerFor]="menu"
>
  <div class="alpha-datepicker-container">
    <img src="assets/icon/calender_gray.svg" class="alpha-datepicker-icon" />
    <span *ngIf="mode === 'date'">{{ selected | date : 'mediumDate' }}</span>
    <span *ngIf="mode === 'dateRange'">{{ selected.start | date : dateType }} - {{ selected.end | date : dateType }}</span>
  </div>

  <mat-menu #menu="matMenu">
    <section class="alpha-datepicker-calender-container">
      <mat-calendar (click)="$event.stopPropagation()" #dp [selected]="selected" [dateFilter]="dateFilter" (selectedChange)="onModelChange($event)"> </mat-calendar>
    </section>
  </mat-menu>
</article>
