<article id="browse-booster" class="setting-article" style="max-width: 1000px">
  <header class="admin-header-1 flex-wrapper">
    <div class="flex-wrapper" style="gap: 10px">Browse Booster</div>
    @if (browseBoosterService.activeCoreScript !== null && !browseBoosterService.activeCoreScript) {
    <div class="booster-core" [@inOutAnimation]>
      <img src="assets/icon/note_green.svg" /> <strong>Note:</strong>
      Activate
      <div (click)="browseBoosterService.getDeepLinkingUrl()">Core Script <img src="assets/icon/link_blue_bold.svg" /></div>
    </div>
    }
  </header>

  <mat-tab-group class="admin-tab" style="margin: 16px 0">
    <mat-tab label="Program Setup"> @if (browseBoosterService.browseBoosterSetup) {<app-browse-booster-program-setup></app-browse-booster-program-setup>} </mat-tab>
    <mat-tab label="Design & Branding"> @if (browseBoosterService.browseBoosterSetup) { <app-browse-booster-design-branding></app-browse-booster-design-branding> } </mat-tab>
    <mat-tab label="Emails">
      <section class="gray-border-wrapper flex-wrapper" style="justify-content: flex-start; gap: 20px">
        <img class="emils-icon" src="assets/icon/booster_emails.svg" />
        <div class="emails-desc">
          <header class="admin-header-4 flex-wrapper" style="width: 100%">Program Re-engagement Email <span class="upcoming">Upcoming</span></header>
          <summary class="admin-content-description" style="padding: 2px 0 0">
            Send a program re-engagement email to customers who have completed the program and used their coupon. This can encourage customers to return to your store.
          </summary>
        </div>
      </section>
    </mat-tab>
    <mat-tab label="Analytics">
      <section class="gray-border-wrapper">
        <div class="flex-wrapper">
          <header class="admin-header-4">Page View per Product</header>
          <span class="upcoming">Upcoming</span>
        </div>
        <summary class="admin-content-description" style="padding-top: 2px">
          Send a confirmation request email to customers whose orders haven't been verified yet. This helps ensure only genuine buyers leave reviews.
        </summary>

        <img style="width: 100%" src="assets/image/browse-booster/analytics-ex.png" />
      </section>
    </mat-tab>
  </mat-tab-group>

  <alpha-save-bar
    (save)="browseBoosterService.updateBrowseBoosterSetup(browseBoosterService.browseBoosterSetup)"
    (discard)="browseBoosterService.onDiscard()"
    [show]="!browseBoosterService.checkSettingsChanged()"
    [maxWidth]="'960px'"
  ></alpha-save-bar>
</article>
