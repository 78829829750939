import { Component, Inject, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ContactInformation, IdentityStatus, ShopDKIM } from 'src/app/models/shop/profile/contact-information.model';
import { BackendService } from 'src/app/service/backend.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ShopService } from 'src/app/service/shop.service';
import { AdminService } from 'src/app/service/admin.service';
import { cloneDeep } from 'lodash';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-dialog-email-verify',
  templateUrl: './dialog-email-verify.component.html',
  styleUrls: ['./dialog-email-verify.component.less'],
})
export class DialogEmailVerifyComponent implements OnInit {
  @ViewChild('dkimTable') dkimTable!: MatTable<ShopDKIM>;

  constructor(
    public dialogRef: MatDialogRef<DialogEmailVerifyComponent>,
    private backendService: BackendService,
    private adminService: AdminService,
    @Optional() @SkipSelf() public shopService: ShopService,
    private clipboard: Clipboard,
    @Inject(MAT_DIALOG_DATA) public data: ContactInformation,
  ) {}

  ngOnInit(): void {
    this.contactInfomationCopy = cloneDeep(this.data);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  identityType: string = 'domain';

  contactInfomationCopy!: ContactInformation;
  dataSource: ShopDKIM[] = this.data.shop_dkims;

  displayColumns: string[] = ['type', 'name', 'value'];
  checkVerifyStatus: any;

  checkEmailDisable() {
    if (
      this.contactInfomationCopy.origin_email_sender_name === this.contactInfomationCopy.email_sender_email &&
      this.contactInfomationCopy.email_status === IdentityStatus.VERIFIED
    ) {
      return true;
    }
    return false;
  }

  createShopSESDomian() {
    let url = 'shops/' + this.backendService.shop_id + '/ses/domain';

    this.backendService
      .create(
        url,
        {},
        {
          domain: this.contactInfomationCopy.domain,
        },
      )
      .subscribe((response) => {
        this.dataSource = response.body;
        this.dkimTable.renderRows();
      });
  }

  createShopSESEmail() {
    let url = 'shops/' + this.backendService.shop_id + '/ses/email';

    this.backendService
      .create(
        url,
        {},
        {
          email_sender_email: this.contactInfomationCopy.email_sender_email,
        },
      )
      .subscribe((response) => {
        this.contactInfomationCopy.email_status = IdentityStatus.PENDING;
        // this.dkimTable.renderRows()
      });
    this.checkVerifyStatus = setInterval(() => {
      if (this.contactInfomationCopy.email_status == IdentityStatus.UNVERIFIED || this.contactInfomationCopy.email_status == IdentityStatus.VERIFIED) {
        clearInterval(this.checkVerifyStatus);
        return;
      }
      this.verifyShopIdentity(this.contactInfomationCopy.email_sender_email);
    }, 5000);
  }

  verifyShopIdentity(identity: string) {
    let url = 'shops/' + this.backendService.shop_id + '/ses/identities';

    this.backendService
      .create(url, {
        identity: identity,
      })
      .subscribe((response) => {
        this.dataSource = this.data.shop_dkims as ShopDKIM[];
        if (response.body.email_status == IdentityStatus.VERIFIED) {
          this.contactInfomationCopy = response.body as ContactInformation;
          this.data = response.body as ContactInformation;
        }
      });
  }

  copy(str: string) {
    this.clipboard.copy(str);
    this.backendService.openSnackBar('Copy!');
  }
}
