<section class="content-table-row flex-wrapper">
  <section class="content-table-row-product">
    <div class="product-img">
      <img
        src="{{ product.product_image }}"
        onerror="this.src='https://s3.ap-northeast-2.amazonaws.com/alpha-review.global/media/assets/widget/ar_img_error.png'; this.classList.add('img-error');"
      />
    </div>

    <div class="product-info-container">
      <div class="product-title">{{ product.title }}</div>
      <div class="flex-wrapper" style="gap: 6px">
        <img src="assets/icon/star_darkgray.svg" />
        {{ product.rating | number : '1.1-1' }} ({{ product.review_count | number }} Reviews)
      </div>
      <div class="flex-wrapper">
        <div class="product-id flex-wrapper" style="gap: 6px"><span class="bold-text">ID</span> {{ product.platform_id }}</div>
        <div class="product-created flex-wrapper" style="gap: 6px"><span class="bold-text">Created on</span> {{ product.created_at | date : 'mediumDate' }}</div>
      </div>
      <div class="flex-wrapper">
        <div class="product-status flex-wrapper" style="gap: 6px"><span class="bold-text">Status</span> {{ product.is_published ? 'Active' : 'Disable' }}</div>
        <div *ngIf="product.last_imported" class="product-created flex-wrapper" style="gap: 6px">
          <span class="bold-text">Last imported</span> {{ product.last_imported | date : 'mediumDate' }}
        </div>
      </div>
    </div>
  </section>

  <section class="content-table-row-action">
    <button
      *ngIf="product.is_aliexpress_importing"
      class="btn-black importing-btn"
      style="position: relative"
      (mouseenter)="product._hover = true"
      (mouseleave)="product._hover = false"
    >
      <custom-tooltip *ngIf="product._hover" [marginTop]="5" contentWidth="200px;"> Complete in 5 minutes </custom-tooltip>
      <img style="margin-right: 6px" src="assets/icon/up_down_white.svg" />
      <span style="line-height: 14px">Importing</span>
    </button>

    <button
      *ngIf="!product.is_aliexpress_importing"
      class="btn-black"
      style="width: 100%; position: relative"
      (mouseenter)="product._hover = true"
      (mouseleave)="product._hover = false"
      [disabled]="checkDisableReviewImportBtn(product.product_review_import_limit!, importService.ReviewAppPlatform.ALI_EXPRESS)"
      (click)="openDialogReviewImportMigration(product)"
    >
      <custom-tooltip
        *ngIf="
          billingService.shopBilling?.plan == billingService.ShopBillingPlan.FREE &&
          checkDisableReviewImportBtn(product.product_review_import_limit!, importService.ReviewAppPlatform.ALI_EXPRESS) &&
          product._hover
        "
        iconSrc="assets/icon/diamond.svg"
        xPosition="after"
        yPosition="below"
      >
        Free Plan: 100 Reviews/Product.
        <a class="hyper-link-a" [routerLink]="['/admin/settings']" [state]="{ tabType: 'subscription' }"> Upgrade now!</a> for Unlimited Reviews!
      </custom-tooltip>
      <span>Import Reviews</span>
    </button>

    <div class="flex-wrapper" style="gap: 8px">
      <button class="btn-white" style="width: 50%" (click)="viewAllReview(product.id)">View all</button>
      <button class="btn-white" style="width: 50%" (click)="deleteAllProductImportReview(product)" [disabled]="product._deleteLock">Delete all</button>
    </div>
    <div class="review-import-migration">
      <div *ngIf="billingService.shopBilling?.plan != billingService.ShopBillingPlan.FREE">
        <span class="importing-count"> {{ product.imported_review_count ?? 0 | number }}</span>
        <span> Reviews Imported</span>
      </div>
      @if (billingService.shopBilling?.plan == billingService.ShopBillingPlan.FREE) { <span><span class="import-number">{{ product.imported_review_count ?? 0 }}</span>/100 Reviews Imported</span> }
    </div>
  </section>
</section>
