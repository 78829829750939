import { Component, ElementRef, forwardRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'alpha-color-input',
  template: `
    <div #cs class="admin-input" style="width: 140px; display: flex; align-items: center; padding-right: 6px" (click)="mainColor.click()">
      <div
        style="border-radius: 100%;
        width: 10px;
        height: 10px;
        flex-basis: 10px;
        flex-shrink: 0;"
        [ngStyle]="{ 'background-color': _color }"
      ></div>
      <div style="margin-left: 10px; font-size: 14px; line-height: 24px; flex-grow: 1;" [ngStyle]="{ color: _color }">
        {{ _color | uppercase }}
      </div>
      <input #mainColor type="color" style="visibility: hidden;" [(ngModel)]="_color" (ngModelChange)="onModelChange($event)" />
      <img src="assets/icon/arrow_down_gray.svg" style="width: 8px" />
    </div>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomerColorInputComponent), // replace name as appropriate
      multi: true,
    },
  ],
})
export class CustomerColorInputComponent implements OnInit {
  constructor(private _renderer: Renderer2) {}

  ngOnInit(): void {}

  _onChange: any = () => {};
  _onTouch: any = () => {};
  _color: string = '#333336';

  @ViewChild('cs') cs?: ElementRef;

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._renderer.setProperty(this.cs?.nativeElement, 'disabled', isDisabled);
  }

  writeValue(_color: string) {
    this._color = _color;
  }

  onModelChange(e: string) {
    this._color = e;
    this._onChange(e);
  }
}
