import { Component, OnInit, Inject } from '@angular/core';
import { ShopNotice } from 'src/app/models/shop/shop-notice.model';
import { BackendService } from 'src/app/service/backend.service';
import { AdminService } from 'src/app/service/admin.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-customer-service-notice-detail',
  templateUrl: './customer-service-notice-detail.component.html',
  styleUrls: ['./customer-service-notice-detail.component.less'],
})
export class CustomerServiceNoticeDetailComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CustomerServiceNoticeDetailComponent>,
    private backendService: BackendService,
    private adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: ShopNotice,
  ) {}

  deviceType: 'MO' | 'PC' = 'PC';

  config = {
    placeholder: '',
    tabsize: 2,
    height: 200,
    uploadImagePath: this.backendService.makeUrl('/shops/wysiwyg/uploadfile'),
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'video', 'hr']],
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
  };

  ngOnInit(): void {
    if (this.data.id) {
      this.getShopNoticeDetail();
      this.data.update = false;
    } else {
      this.data.writer = this.adminService.ShopAdminAccount.user_name;
    }

    // $('asd').summernote
  }

  onNoClick(): void {
    this.dialogRef.close(undefined);
  }

  checkValidNotice() {
    if (!this.data.posting_end || !this.data.posting_start) {
      return false;
    }

    return true;
  }

  onConfirm(): void {
    this.dialogRef.close(this.data);
  }

  onImageUpload(event: any) {}

  getShopNoticeDetail() {
    let url = 'shops/' + this.backendService.shop_id + '/notice/' + this.data.id;
    this.backendService.select(url).subscribe((response) => {
      this.data.content_pc = response.body['content_pc'];
      this.data.content_mobile = response.body['content_mobile'];
    });
  }
}
