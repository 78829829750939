import { Component, OnDestroy, OnInit, Optional, SkipSelf } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthAdminService } from 'src/app/auth/auth-admin.service';
import { Shop, ShopAdminAccount } from 'src/app/models/shop/shop.model';
import { AlphaApps } from 'src/app/models/shop/profile/store-detail.model';
import { AdminService } from 'src/app/service/admin.service';
import { GenericService } from 'src/app/service/generic.service';
import { ShopService } from 'src/app/service/shop.service';
import { DialogAdminAccountResetPasswordComponent } from '../dialog/dialog-admin-account-reset-password/dialog-admin-account-reset-password.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less', '../admin.component.less'],
})
export class LoginComponent implements OnInit, OnDestroy {
  constructor(
    @Optional() @SkipSelf() private authAdminService: AuthAdminService,
    @Optional() @SkipSelf() private adminService: AdminService,
    @Optional() @SkipSelf() private shopService: ShopService,
    private genericService: GenericService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
    sessionStorage.clear();

    if (history.state.email) {
      this.id = history.state.email;
    }
  }

  shopAccessCode!: string;

  id: string = '';
  password: string = '';

  doLogin: boolean = false;

  init = false;
  egg: any = undefined;

  top = 50;
  left = 50;
  count = 0;
  interval = 1024;
  size = 1;
  isdemoLoading: boolean = false;

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      if (data.demoLogin) {
        this.isdemoLoading = true;
        this.onLoginDemo();
      }
    });
    this.adminService.clearAccessCode();

    setTimeout(() => {
      this.init = true;
    }, this.interval);

    setTimeout(() => {
      this.egg = setInterval(() => this.easterEgg(), this.interval);
    }, 15000);
  }

  easterEgg() {
    return;
    this.count += 1;
    this.top = Math.floor(Math.random() * 100);
    this.left = Math.floor(Math.random() * 100);
    this.interval = this.interval > 50 ? Math.floor(this.interval / 1.02) : this.interval;
    this.count = 0;
    clearInterval(this.egg);
    this.egg = setInterval(() => this.easterEgg(), this.interval);
  }

  openDialogAccountResetPassword() {
    this.dialog
      .open(DialogAdminAccountResetPasswordComponent, {
        data: undefined,
        width: '420px',
      })
      .afterClosed()
      .subscribe((result) => {
        //;
      });
  }

  checkValidAccount() {
    if (!this.id) {
      this.genericService.openSnackBar('Not Valid ID');
      return false;
    }

    if (!this.password) {
      this.genericService.openSnackBar('Not Valid Password');
      return false;
    }

    return true;
  }
  onLogin() {
    this.doLogin = true;
    this.authAdminService.adminLogin({ id: this.id, password: this.password }).subscribe({
      next: (response) => {
        if (response.status !== 200) return;
        let shopAdminAccount = response.body as ShopAdminAccount;
        shopAdminAccount.shops = [...shopAdminAccount.alpha_plus_shops, ...shopAdminAccount.browse_booster_shops].filter(
          (shop, idx, self) => idx === self.findIndex((t) => t.platform_id === shop.platform_id),
        );

        shopAdminAccount.shops.forEach((shop) => (shop.access_code = shop.access_code?.slice(2, shop.access_code.length)));
        this.handleLoginSuccess(shopAdminAccount);
      },
      error: (error) => {
        if (error.status == 401) this.genericService.openSnackBar('Invalid account or password. Please try again. ');
        this.doLogin = false;
      },
      complete: () => {
        this.doLogin = false;
      },
    });
  }

  onLoginDemo() {
    this.doLogin = true;
    this.authAdminService.adminLoginDemo().subscribe({
      next: (response) => {
        if (response.status == 200) {
          let shopAdminAccount = response.body as ShopAdminAccount;
          shopAdminAccount.shops = Array.from(new Set([...shopAdminAccount.alpha_plus_shops, ...shopAdminAccount.browse_booster_shops]));
          this.handleLoginSuccess(shopAdminAccount, true);
        }
      },
      error: (error) => {
        this.doLogin = false;
      },
      complete: () => {
        this.doLogin = false;
      },
    });
  }

  private handleLoginSuccess(shopAdminAccount: ShopAdminAccount, isDemo: boolean = false): void {
    this.adminService.setAdminAccountSession(shopAdminAccount);
    this.adminService.isDemo = isDemo;

    this.shopService.currency = shopAdminAccount.shop!.currency as string;
    this.shopService.shopName = shopAdminAccount.shop!.shop_name as string;

    this.router.navigate(['admin', 'market-place']);
  }

  ngOnDestroy(): void {
    clearInterval(this.egg);
  }
}
