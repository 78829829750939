import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminAccountRoleDescriptionComponent } from './admin-account-role-description/admin-account-role-description.component';



@NgModule({
  declarations: [
    AdminAccountRoleDescriptionComponent
  ],
  imports: [
    CommonModule
  ], exports: [AdminAccountRoleDescriptionComponent]
})
export class AdminSharedModule { }
