import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { inOutAnimation } from 'src/app/admin/animations';
import { ListDesignerPreset } from 'src/app/models/list-designer/widget.model';

@Component({
  selector: 'app-dialog-automatic-product',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dialog-automatic-product.component.html',
  styleUrl: './dialog-automatic-product.component.less',
  animations: [inOutAnimation],
})
export class DialogAutomaticProductComponent {
  constructor(@Inject(MAT_DIALOG_DATA) private data: boolean, private dialogRef: MatDialogRef<DialogAutomaticProductComponent>) {
    this.useReview = data;
  }
  useReview: boolean | null = null;
  selectedAutomatic: ListDesignerPreset | null = null;

  automaticOptions = {
    [ListDesignerPreset.MANUAL]: {
      icon: '',
      title: '',
      description: '',
    },
    [ListDesignerPreset.BEST_SELLERS]: {
      icon: 'assets/icon/icon_best_seller.svg',
      title: 'Top selling products',
      description: 'Top 20 best-selling products are automatically selected and the product list is updated daily.',
    },
    [ListDesignerPreset.NEW_ARRIVALS]: {
      icon: 'assets/icon/icon_new.svg',
      title: 'Newly released products',
      description: 'Top 20 newly created products are automatically selected and the product list is updated daily.',
    },
    [ListDesignerPreset.TOP_REVIEWED_PRODUCTS]: {
      icon: 'assets/icon/icon_top.svg',
      title: 'Most reviewed products',
      description: 'Top 20 products with the most reviews are automatically selected and the product list is updated daily.',
      hover: false,
    },
  };

  return = () => {
    return false;
  };

  onCancel() {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.dialogRef.close(this.selectedAutomatic);
  }

  get ListDesignerPreset(): typeof ListDesignerPreset {
    return ListDesignerPreset;
  }
}
