import { DatePipe } from '@angular/common';
import { Component, OnInit, Optional, SkipSelf } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import moment from 'moment';
import { Customer } from 'src/app/models/customer/customer.model';
import { SendingStatus } from 'src/app/models/order/requests-order/requests-order.model';
import { Product } from 'src/app/models/product/product.model';
import { ReviewType } from 'src/app/models/review/review.model';
import { SendingChannel, ShopMessagingOption } from 'src/app/models/settings/shop-messaging-option.model';
import { AdminService } from 'src/app/service/admin.service';
import { AnalyticsService } from 'src/app/service/analytics.service';
import { CustomerService } from 'src/app/service/customer.service';
import { GenericService } from 'src/app/service/generic.service';
import { RequestsService } from 'src/app/service/requests.service';
import { ReviewService } from 'src/app/service/review.service';
import { dateFormat } from 'src/app/shared/format';
import { DialogProductVariantSelectComponent } from '../../dialog/dialog-product-variant-select/dialog-product-variant-select.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.less'],
  providers: [DatePipe],
})
export class AnalyticsComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    public reviewService: ReviewService,
    public requestService: RequestsService,
    private customerService: CustomerService,
    private analyticsService: AnalyticsService,
    private genericService: GenericService,
    private datePipe: DatePipe,
    private router: Router,
    @Optional() @SkipSelf() private adminService: AdminService,
  ) {}

  analyticsParams: AnalyticsParams = new AnalyticsParams();
  view: [any, number] = [1200, 300];

  dateRange: DateRange<Date> = new DateRange(new Date(new Date().setMonth(new Date().getMonth() - 1)), new Date());

  ngOnInit(): void {
    this.getAllAnalytice();
  }

  getAllAnalytice() {
    this.getReviewCount();
    this.getRequestCount();
    this.getRatings();
    this.getReviewRate();
    this.getTopProduct();
    this.getTopCustomer();
  }

  dateRangeFilter(date: Date): boolean {
    return true;
  }

  onChangeDateRange(dateRange: DateRange<Date>) {
    if (dateRange.start && dateRange.end) {
      this.getAllAnalytice();
    }
  }

  // width() {
  //   let content = $('.gray-border-wrapper');
  //   let content_width = content.width();

  //   this.view = [content_width, 300];
  // }

  getReviewCount() {
    let params = {
      start_date: moment(this.dateRange.start?.getTime()).format(dateFormat),
      end_date: moment(this.dateRange.end?.getTime()).format(dateFormat),
      filter: JSON.stringify({
        review_type: this.analyticsParams.review_count.filter?.reviewType,
        review_sort: this.analyticsParams.review_count.filter?.reviewSort,
      }),
    };

    if (this.analyticsParams.review_count.product) {
      params = {
        ...params,
        ...{
          product_platform_id: this.analyticsParams.review_count.product.platform_id,
        },
      };
    }
    this.analyticsService.getReviewCount(params).subscribe((response) => {
      this.analyticsParams.review_count.data = [];

      const data = response.body['data'];
      for (let key in data) {
        this.analyticsParams.review_count.data.push({
          name: this.datePipe.transform(key, 'MMM dd'),
          value: data[key],
        });
      }
      Object.assign(this, this.analyticsParams.review_count);
    });
  }

  getRequestCount() {
    let params = {
      start_date: moment(this.dateRange.start?.getTime()).format(dateFormat),
      end_date: moment(this.dateRange.end?.getTime()).format(dateFormat),
      filter: JSON.stringify({
        sending_channel: this.analyticsParams.request_count.filter?.senddingChannel,
        sending_status: this.analyticsParams.request_count.filter?.senddingStatus,
      }),
    };

    if (this.analyticsParams.request_count.product) {
      params = {
        ...params,
        ...{
          product_platform_id: this.analyticsParams.request_count.product?.platform_id,
        },
      };
    }

    this.analyticsService.getRequestCount(params).subscribe((response) => {
      this.analyticsParams.request_count.data = [];

      const data = response.body.data;

      for (let key in data) {
        this.analyticsParams.request_count.data.push({
          name: key,
          value: data[key],
        });
      }

      Object.assign(this, this.analyticsParams.request_count);
    });
  }

  getReviewRate() {
    let params = {
      start_date: moment(this.dateRange.start?.getTime()).format(dateFormat),
      end_date: moment(this.dateRange.end?.getTime()).format(dateFormat),
      filter: JSON.stringify({
        is_manual: this.analyticsParams.review_rate.filter?.isManual,
      }),
    };

    if (this.analyticsParams.review_rate.product) {
      params = {
        ...params,
        ...{
          product_platform_id: this.analyticsParams.review_rate.product.platform_id,
        },
      };
    }

    this.analyticsService.getReviewRate(params).subscribe((response) => {
      this.analyticsParams.review_rate.data = [];
      const data = [];

      for (let key in response.body.data) {
        data.push({
          name: key,
          value: response.body.data[key],
        });
      }

      this.analyticsParams.review_rate.data.push({
        name: 'Review Rate',
        series: data,
      });

      Object.assign(this, this.analyticsParams.review_rate.data);
    });
  }

  getRatings() {
    let params = {
      start_date: moment(this.dateRange.start?.getTime()).format(dateFormat),
      end_date: moment(this.dateRange.end?.getTime()).format(dateFormat),
    };

    if (this.analyticsParams.ratings.product) {
      params = {
        ...params,
        ...{
          product_platform_id: this.analyticsParams.ratings.product.platform_id,
        },
      };
    }

    this.analyticsService.getReviewRating(params).subscribe((response) => {
      this.analyticsParams.ratings.data = [];
      let data = [];

      for (let key in response.body.data) {
        data.push({
          name: key,
          value: response.body.data[key],
        });
      }

      this.analyticsParams.ratings.data.push({
        name: 'Rating',
        series: data,
      });
      Object.assign(this, this.analyticsParams.ratings);
    });
  }

  getTopProduct() {
    let params = {
      start_date: moment(this.dateRange.start?.getTime()).format(dateFormat),
      end_date: moment(this.dateRange.end?.getTime()).format(dateFormat),
    };
    this.analyticsService.getTopProduct(params).subscribe((response) => {
      this.analyticsParams.top_product.data = response.body['top_product'] as RankBoardRow[];
    });
  }

  getTopCustomer() {
    let params = {
      start_date: moment(this.dateRange.start?.getTime()).format(dateFormat),
      end_date: moment(this.dateRange.end?.getTime()).format(dateFormat),
    };

    this.analyticsService.getTopCustomer(params).subscribe((response) => {
      this.analyticsParams.top_customer.data = response.body['top_customer'] as RankBoardRow[];
    });
  }

  redirectShopifyCustomer(custoemr_id: number) {
    this.customerService
      .getCustomer({
        customer_id: custoemr_id,
        shop_access_code: this.adminService.getShopAccessCode(),
      })
      .subscribe((response) => {
        let customer: Customer = response.body;
      });
  }
  deleteProduct(key: keyof AnalyticsParams) {
    this.analyticsParams[key].product = undefined;
  }

  customColors() {
    return '#589bff';
  }

  openDialogProductVariantSelect(key: keyof AnalyticsParams) {
    const dialogRef = this.dialog.open(DialogProductVariantSelectComponent, {
      width: '480px',
      maxHeight: '70vh',
      data: {
        mode: 'radio',
        action: 'choice',
      },
    });

    dialogRef.afterClosed().subscribe((result: Product[]) => {
      if (result) {
        this.analyticsParams[key].product = result[0];
        if (key == 'review_count') this.getReviewCount();

        if (key == 'ratings') this.getRatings();

        if (key == 'review_rate') this.getReviewRate();

        if (key == 'request_count') this.getRequestCount();

        if (key == 'top_customer') this.getTopCustomer();

        if (key == 'top_product') this.getTopProduct();
      }
    });
  }

  routeReview(productId?: number, customerName?: string) {
    this.router.navigate(['admin/product-reviews/reviews'], { state: { product_id: productId, customer_name: customerName } });
  }
}

class AnalyticsParams {
  review_count: AnalyticsParamsDetail = new AnalyticsParamsDetail();
  review_rate: AnalyticsParamsDetail = new AnalyticsParamsDetail();
  request_count: AnalyticsParamsDetail = new AnalyticsParamsDetail();
  ratings: AnalyticsParamsDetail = new AnalyticsParamsDetail();
  top_product: AnalyticsParamsDetail = new AnalyticsParamsDetail();
  top_customer: AnalyticsParamsDetail = new AnalyticsParamsDetail();
}

class AnalyticsParamsDetail {
  product?: Product;
  filter: {
    reviewType?: ReviewType[];
    reviewSort?: string[];
    senddingChannel?: SendingChannel[];
    senddingStatus?: SendingStatus[];
    isManual?: boolean[];
  } = {
    reviewType: [ReviewType.BASIC_REVIEW, ReviewType.LONGTERM_USE_REVIEW, ReviewType.SOCIAL_REVIEW],
    reviewSort: ['buyer', 'unknown'],
    senddingChannel: [SendingChannel.EMAIL, SendingChannel.SMS, SendingChannel.WEBPUSH],
    senddingStatus: [SendingStatus.WAITING, SendingStatus.SUCCESS, SendingStatus.CANCEL, SendingStatus.FAILURE],
    isManual: [true, false],
  };
  data: any[] = [];
}

class RankBoardRow {
  customer_id?: number;
  customer_name: string = '';
  title: string = '';
  ratings: number = 0;
  total: number = 0;
  increase: number = 0;
}
