"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LifecycleHook = exports.PermissionType = exports.MessageType = void 0;
/**
 * @internal
 */
var MessageType;
(function (MessageType) {
    MessageType["GetState"] = "getState";
    MessageType["Dispatch"] = "dispatch";
    MessageType["Subscribe"] = "subscribe";
    MessageType["Unsubscribe"] = "unsubscribe";
})(MessageType = exports.MessageType || (exports.MessageType = {}));
/**
 * @internal
 */
var PermissionType;
(function (PermissionType) {
    PermissionType["Dispatch"] = "Dispatch";
    PermissionType["Subscribe"] = "Subscribe";
})(PermissionType = exports.PermissionType || (exports.PermissionType = {}));
/**
 *
 * There are two types of hooks: `LifecycleHook.DispatchAction` and `LifecycleHook.UpdateAction`.
 *
 * @remarks
 * `DispatchAction` hooks are run when an action is dispatched with the `.dispatch()` function:
 *
 * ```js
 * const toastNotice = Toast.create(app, {message: 'Product saved'});
 * toastNotice.dispatch(Toast.Action.SHOW);
 * ```
 *
 * `UpdateAction` hooks are run when an action is updated, using the `.set()` function:
 *
 * ```js
 * toastNotice.set({message: 'Product could not be saved', isError: true});
 * ```
 *
 * @public
 */
var LifecycleHook;
(function (LifecycleHook) {
    LifecycleHook["UpdateAction"] = "UpdateAction";
    LifecycleHook["DispatchAction"] = "DispatchAction";
})(LifecycleHook = exports.LifecycleHook || (exports.LifecycleHook = {}));
