<article id="account-reset-password">
  <section class="account-container" *ngIf="mode == 'wait'">
    <section class="account-img">
      <img src="assets/icon/lock_blue.png">
    </section>
    <section>
      <header class="account-title-1">
        Reset Password
      </header>
      <summary class="account-content">
        Enter a new password for account: email_address
      </summary>
    </section>

    <section [formGroup]="formGroup">
      <section style="margin-top: 20px;">
        <header class="account-title-2">
          Enter a new password:
        </header>
        <input class="admin-input" style="width: 100%; margin-top: 10px;" placeholder="Password" type="password"
          formControlName="password">
            <div class="account-content" style="margin-top: 10px;font-size: 12px">Mixing upper, lower case letters, symbols, and more enhances password safety.</div>
      </section>

      <section style="margin-top: 20px;">
        <header class="account-title-2">
          Confirm password:
        </header>
        <input class="admin-input" style="width: 100%; margin-top: 10px;" placeholder="Confirm password" type="password"
          formControlName="passwordConfirm">
        <div *ngIf="formGroup.controls['passwordConfirm'].invalid && formGroup.controls['passwordConfirm'].dirty"
          class="warning-container">
          <img src="assets/icon/caution_red.svg">
          <div style="margin-left: 8px;">
            Please enter the same Password as above.
          </div>
        </div>
      </section>
    </section>

    <section style="margin-top: 20px;">
      <button class="btn-black" style="width: 100%;" (click)="onConfirm()"
        [disabled]="formGroup.controls['password'].invalid || formGroup.controls['passwordConfirm'].invalid || formGroup.controls['password'].value != formGroup.controls['passwordConfirm'].value">
        Reset Password
      </button>
    </section>
  </section>

  <section class="account-container" *ngIf="mode == 'done'">
    <section class="account-img">
      <img src="assets/icon/lock_green.png">
    </section>
    <section>
      <header class="account-title-1" style="text-align: center;">
        Reset Password
      </header>
      <summary class="account-content" style="text-align: center;">
        Your password has been reset successfully.
        Go back to AlphaReview admin login page
        and continue logging in.
      </summary>
    </section>

    <section style="margin-top: 20px;">
      <button class="btn-black" style="width: 100%;" (click)="onDone()">
        Done
      </button>
    </section>
  </section>

  <section class="account-container" *ngIf="mode == 'fail'">
    <section class="account-img">
      <img src="assets/icon/lock_red.png">
    </section>
    <section>
      <header class="account-title-1" style="text-align: center;">
        Please Try again
      </header>
      <summary class="account-content" style="text-align: center;">
        Your request to reset your password has
        expired or the link has already been used.
      </summary>
    </section>

    <section style="margin-top: 20px;">
      <button class="btn-black" style="width: 100%;" (click)="onDone()">
        Back to Home
      </button>
    </section>
  </section>

</article>
