import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { BrowseBoosterService } from '../browse-booster.service';
import { MatMenuModule } from '@angular/material/menu';
import { inOutAnimation } from '../../animations';

@Component({
  selector: 'app-browse-booster-design-branding',
  standalone: true,
  templateUrl: './browse-booster-design-branding.component.html',
  styleUrl: './browse-booster-design-branding.component.less',
  imports: [CommonModule, SharedModule, MatMenuModule],
  animations: [inOutAnimation],
})
export class BrowseBoosterDesignBrandingComponent {
  constructor(public browseBoosterService: BrowseBoosterService) {}

  mode: 'PC' | 'MO' = 'PC';
  positionOptions: string[] = ['Top left', 'Center left', 'Bottom left', 'Top right', 'Center right', 'Bottom right'];
}
