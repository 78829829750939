"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Action = exports.validateAction = exports.validateProps = exports.buttonGroupSchema = void 0;
var ButtonGroup_1 = require("../../actions/ButtonGroup");
Object.defineProperty(exports, "Action", { enumerable: true, get: function () { return ButtonGroup_1.Action; } });
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
var button_1 = require("./button");
exports.buttonGroupSchema = type_validate_1.composeSchemas(button_1.buttonSchema, type_validate_1.matchesObject({
    buttons: type_validate_1.matchesArray(type_validate_1.makeOptional(button_1.buttonSchemaWithId)),
}));
function validateProps(props) {
    return type_validate_1.validate(props, exports.buttonGroupSchema);
}
exports.validateProps = validateProps;
function validateAction(action) {
    var validator = utils_1.createActionValidator(ButtonGroup_1.Action, exports.buttonGroupSchema, true, true);
    return type_validate_1.validate(action, validator);
}
exports.validateAction = validateAction;
