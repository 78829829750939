import { Component, Input, OnInit } from '@angular/core';
import { LineItem } from 'src/app/models/order/line-item.model';
import { Product } from 'src/app/models/product/product.model';

@Component({
  selector: 'app-product-option',
  template: `
    <span style="color: #91949c; font-size:13px">
      <ng-container *ngFor="let option of product_options; last as isLast">
        <span style="font-weight:600; margin-right:6px">{{
          option.title
        }}</span>
        <span>{{ option.value }}</span>
        <span *ngIf="!isLast" style="margin: 0 5px">/</span>
      </ng-container>
    </span>
  `,
  styles: [],
})
export class ProductOptionComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    //this.makeProductOption();
    this.makeProductOptionMyReview();
  }

  //@Input() lineItem!: LineItem;
  @Input() product!: Product;

  product_options: { title: string; value: string }[] = [];

  // makeProductOption(): displayProductOption[] {
  //   let title: any;
  //   let value: any;
  //   const product_variant = this.lineItem?.product.product_variant;

  //   for (let i = 0; i < this.lineItem?.product.product_options!.length; i++) {
  //     if (
  //       this.lineItem?.product!.product_options![i].values.indexOf(
  //         product_variant!['option' + (i + 1)]
  //       ) > 0
  //     ) {
  //       this.lineItem?.product!.product_options![i].name;
  //       product_variant!['option' + (i + 1)];
  //     }

  //     title = this.lineItem?.product!.product_options![i].name;
  //     value = product_variant!['option' + (i + 1)];
  //     this.product_options.push({ title, value });
  //   }
  //
  //   return [{ title: title, value: value }];
  // }
  makeProductOptionMyReview(): displayProductOption[] {
    let title: any;
    let value: any;
    const product_variant = this.product.product_variant;

    for (let i = 0; i < this.product.product_options!.length; i++) {
      if (
        this.product!.product_options![i].values.indexOf(
          product_variant!['option' + (i + 1)]
        ) > 0
      ) {
        this.product!.product_options![i].name;
        product_variant!['option' + (i + 1)];
      }

      title = this.product!.product_options![i].name;
      value = product_variant!['option' + (i + 1)];
      this.product_options.push({ title, value });
    }
    //
    return [{ title: title, value: value }];
  }
}

interface displayProductOption {
  title: string;
  value: string;
}
