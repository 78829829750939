import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commaSeparatedNumber'
})
export class CommaSeparatedNumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let reg = /(^[+-]?\d+)(\d{3})/;
    let n = Math.floor(value).toString()

    while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
    return n;
  }
}
