import { Component, OnInit, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import { ShopAdminAccount } from 'src/app/models/shop/shop.model';
import { AdminService } from 'src/app/service/admin.service';
import { BackendService } from 'src/app/service/backend.service';
import { DialogManageReviewExportComponent } from '../dialog-manage-review-export/dialog-manage-review-export.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-manage-review-demo-guide',
  templateUrl: './dialog-manage-review-demo-guide.component.html',
  styleUrls: ['./dialog-manage-review-demo-guide.component.less'],
})
export class DialogManageReviewDemoGuideComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogManageReviewExportComponent>,
    private backendService: BackendService,
    private router: Router,
    @Optional() @SkipSelf() private adminService: AdminService,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}
  goCheckWidgets() {
    const url = 'reviews/themes/preview';
    this.backendService.select(url, { shop_access_code: this.adminService.getShopAccessCode() }).subscribe((response) => {
      window.open(response.body, '_blank');
    });
  }
  goCheckModerationGuide() {
    window.open('https://alph.oopy.io/2a8dbb9d-cbb5-40b4-8cbe-3baf295e5d1f', '_blank');
  }
  close() {
    const url = 'shops/';
    const payload = { check_demo_review: true };
    this.backendService.patch(url, payload, { shop_access_code: this.adminService.getShopAccessCode() }).subscribe((response) => {
      // this.adminService.setAdminAccountSession(this.adminService._shopAdminAccount as ShopAdminAccount, response.body);
    });
    this.dialogRef.close();
  }
}
