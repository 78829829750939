import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-account-invite-complete',
  templateUrl: './account-invite-complete.component.html',
  styleUrls: ['./account-invite-complete.component.less', '../../../admin.component.less'],
})
export class AccountInviteCompleteComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<AccountInviteCompleteComponent>) {}

  ngOnInit(): void {}

  onConfirm() {
    this.dialogRef.close(true);
  }
}
