import { Review } from 'src/app/models/review/review.model';

export class ReviewFixed {
  id: number = 0;
  target: ReviewFixedTarget = ReviewFixedTarget.ALL;
  target_id: number = 0;
  rank: number = 0;

  shop_id: number = 0;
  review: Review[] = [];
  fixed_type: boolean = false;
}

export enum ReviewFixedTarget {
  ALL = 0,
  LABEL = 1,
  PRODUCT = 2,
}

export enum ReviewFixedType {
  TOP = 0,
  BOTTOM = 1,
}
// enumeration 0 ,1 ,2
