<article id="notification-emails">
  <mat-accordion multi="true">
    <mat-expansion-panel *ngIf="settingMessagingService.verificationEmail" class="gray-border-wrapper" style="margin-top: 16px">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="flex-wrapper" style="gap: 25px">
            <img src="assets/icon/ns_verification.svg" />
            <div class="setting-content-detail">
              <header class="setting-content-header">
                <div class="setting-content-header-value">
                  <span>Verification Email</span>
                </div>
              </header>
              <summary class="setting-content-header-summary">
                Send a confirmation request email to customers whose orders haven't been verified yet. This helps ensure only genuine buyers leave reviews.
              </summary>
            </div>
          </div>
          <alpha-toggle-btn
            [(sourceBoolean)]="settingMessagingService.verificationEmail.use_setting_type_detail"
            (sourceBooleanChange)="settingMessagingService.patchMessaging(settingMessagingService.verificationEmail, settingMessagingService.verificationEmail)"
            positiveText="On"
            negativeText="Off"
          >
          </alpha-toggle-btn>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-notification-verification></app-notification-verification>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="settingMessagingService.benefitInfo" class="gray-border-wrapper margin-top-important">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="flex-wrapper" style="gap: 25px">
            <img src="assets/icon/ns_reward.svg" />
            <div class="setting-content-detail">
              <header class="setting-content-header">
                <div class="setting-content-header-value">
                  <span>Reward Reminder</span>
                </div>
              </header>
              <summary class="setting-content-header-summary">Email customers about their earned review rewards to encourage repeat business.</summary>
            </div>
          </div>
          <alpha-toggle-btn
            [(sourceBoolean)]="settingMessagingService.benefitInfo.use_setting_type_detail"
            (sourceBooleanChange)="settingMessagingService.patchMessaging(settingMessagingService.benefitInfo, settingMessagingService.benefitInfo)"
            positiveText="On"
            negativeText="Off"
          >
          </alpha-toggle-btn>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-notification-review-reward></app-notification-review-reward>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="settingMessagingService.reviewReplyInfo" class="gray-border-wrapper margin-top-important">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="flex-wrapper" style="gap: 25px">
            <img src="assets/icon/ns_reply.svg" />
            <div class="setting-content-detail">
              <header class="setting-content-header">
                <div class="setting-content-header-value">
                  <span>Reply Notification</span>
                </div>
              </header>
              <summary class="setting-content-header-summary">Send an email to customers when you respond to their reviews to maintain open communication.</summary>
            </div>
          </div>
          <alpha-toggle-btn
            [(sourceBoolean)]="settingMessagingService.reviewReplyInfo.use_setting_type_detail"
            (sourceBooleanChange)="settingMessagingService.patchMessaging(settingMessagingService.reviewReplyInfo, settingMessagingService.reviewReplyInfo)"
            positiveText="On"
            negativeText="Off"
          >
          </alpha-toggle-btn>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-notification-review-reply></app-notification-review-reply>
    </mat-expansion-panel>
  </mat-accordion>

  <div routerLink="/admin/settings" *ngIf="!adminService.isDemo" class="btn-reset"><img src="assets/icon/external_link.svg" />Change Sender Email</div>
</article>
