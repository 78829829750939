import { Component, ElementRef, forwardRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'alpha-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomCheckboxComponent),
      multi: true,
    },
  ],
})
export class CustomCheckboxComponent implements ControlValueAccessor, OnInit {
  constructor(private _renderer: Renderer2) {}

  ngOnInit(): void {}

  _onChange: any = () => {};
  _onTouch: any = () => {};
  _checked: boolean = false;

  @ViewChild('cb') cb?: ElementRef;

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._renderer.setProperty(this.cb?.nativeElement, 'disabled', isDisabled);
  }

  writeValue(checked: boolean) {
    this._checked = checked;
  }

  onModelChange(e: boolean) {
    this._checked = e;
    this._onChange(e);
  }
}
