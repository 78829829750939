import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShopsRoutingModule } from './shops-routing.module';
import { ListComponent } from './list/super-admin-shops-list.component';
import { DetailComponent } from './detail/super-admin-shops-detail.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShopInfoComponent } from './detail/shop-info/shop-info.component';
import { MatDividerModule } from '@angular/material/divider';
import { MemoComponent } from './detail/memo/memo.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NavComponent } from './detail/nav/nav.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
@NgModule({
  declarations: [ListComponent, DetailComponent, ShopInfoComponent, MemoComponent, NavComponent],
  imports: [
    CommonModule,
    ShopsRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatInputModule,
    MatDialogModule,
    FormsModule,
    MatDividerModule,
    ScrollingModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatBadgeModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class ShopsModule {}
