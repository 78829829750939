<article id="list-widget-guide">
  <section class="admin-content-gnb">
    <div class="flex-wrapper" style="width: 100%">
      <header class="admin-header-1">
        <img (click)="backToWidgetCustom()" src="assets/icon/back_btn_gray.svg" />
        {{ widget.name }}
      </header>
      <div class="flex-wrapper" style="gap: 10px">
        <div routerLink="/admin/list-designer/widget-list" class="btn-white" style="padding: 15px; height: fit-content">Back to Widget List</div>
        <div (click)="listDesignerService.getDeepLinkingUrl()" class="btn-black" style="padding: 15px; height: fit-content">Go to Shopify Theme Editor</div>
      </div>
    </div>
  </section>

  <section class="setting-article" style="max-width: 650px">
    <img style="width: 40px; height: 40px" src="assets/icon/check_circle_blue.svg" />
    <header class="admin-header-1" style="font-size: 24px">You successfully created a widget!</header>
    <div class="gray-border-wrapper flex-wrapper">
      {{ widget.code }}
      <div (click)="copyWidgetId()" class="btn-black" style="padding: 8px 10px; height: fit-content; width: 126px">Copy Widget ID</div>
    </div>
    <div class="guide-wrapper">
      <div class="gray-border-wrapper">
        <img src="assets/image/list-designer/guide_1.png" />
        <div class="guide-content"><span>1.</span>Copy the widget ID.</div>
      </div>
      <div class="gray-border-wrapper">
        <div class="guide-img">
          <img src="assets/image/list-designer/guide_2.png" />
        </div>
        <div class="guide-content">
          <span>2.</span>
          <div>
            Navigate to the <span class="guide-link" (click)="listDesignerService.getDeepLinkingUrl()">Shopify Theme Editor</span> and
            <strong>activate the List Designer core script.</strong>
          </div>
        </div>
      </div>
      <div class="gray-border-wrapper">
        <div class="guide-img">
          <img src="assets/image/list-designer/guide_3.png" />
        </div>
        <div class="guide-content"><span>3.</span> Add sections and app blocks to the desired location.</div>
      </div>
      <div class="gray-border-wrapper">
        <div class="guide-img">
          <img src="assets/image/list-designer/guide_4.png" />
        </div>
        <div class="guide-content"><span>4.</span> In the app block settings, paste the copied widget ID.</div>
      </div>
    </div>
  </section>
</article>
