import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-write-dialog-notice',
  templateUrl: './write-dialog-notice.component.html',
  styleUrls: ['./write-dialog-notice.component.less'],
})
export class WriteDialogNoticeComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<WriteDialogNoticeComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      header: string;
      content: string;
      btn: number;
    },
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  onClickCancel() {
    this.dialogRef.close(false);
  }

  onClickConfirm() {
    this.dialogRef.close(true);
  }
}
