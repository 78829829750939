import { Injectable } from '@angular/core';
import { BackendService } from '../service/backend.service';

@Injectable({
  providedIn: 'root',
})
export class AuthAdminService {
  constructor(private backendService: BackendService) {}

  adminLogin(payload: { id: string; password: string }) {
    let url = 'auth/shop/login';

    const formData = new FormData();
    for (let key in payload) {
      formData.append(key, payload[key as 'id' | 'password']);
    }
    return this.backendService.create(url, formData);
  }

  adminLoginBridge(accessToken: string) {
    let url = 'auth/shop/login/bridge';

    let formData = new FormData();
    formData.set('code', accessToken);

    return this.backendService.create(url, formData);
  }

  adminLoginDemo() {
    let url = 'auth/shop/login/demo';
    return this.backendService.create(url);
  }
}
