import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalNavigationBarComponent } from './global-navigation-bar/global-navigation-bar.component';
import { LeftNavigationBarComponent } from './left-navigation-bar/left-navigation-bar.component';

import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { TemplateModule } from 'src/app/template/template.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { DialogAppGuidanceComponent } from './left-navigation-bar/dialog-app-guidance/dialog-app-guidance.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProductReviewsNavComponent } from './left-navigation-bar/product-reviews-nav/product-reviews-nav.component';

@NgModule({
  declarations: [GlobalNavigationBarComponent, LeftNavigationBarComponent, DialogAppGuidanceComponent, ProductReviewsNavComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    MatTabsModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSelectModule,

    PipeModule,
    SharedModule,
    TemplateModule,
  ],
  exports: [GlobalNavigationBarComponent, LeftNavigationBarComponent],
})
export class LayoutModule {}
