import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ShopAccountPosition, ShopAdminAccount } from 'src/app/models/shop/shop.model';
import { GenericService } from 'src/app/service/generic.service';
import { ShopService } from 'src/app/service/shop.service';

@Component({
  selector: 'app-dialog-admin-account-invite',
  templateUrl: './dialog-admin-account-invite.component.html',
  styleUrls: ['./dialog-admin-account-invite.component.less', '../../admin.component.less'],
})
export class DialogAdminAccountInviteComponent implements OnInit {
  constructor(
    public shopService: ShopService,
    public dialogRef: MatDialogRef<DialogAdminAccountInviteComponent>,
    public genericService: GenericService, // @Inject(MAT_DIALOG_DAT) // public data: { //
  ) // }
  {}

  ngOnInit(): void {}

  shopAdminAccount: ShopAdminAccount = new ShopAdminAccount();

  onNoClick() {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.shopService.inviteShopAccount(this.shopAdminAccount).subscribe({
      next: (response) => {
        this.genericService.openSnackBar('Email Sent.');
        this.dialogRef.close(true);
      },
      error: (error) => {
        if (error.status == 409) {
          this.genericService.openSnackBar('Account Duplicated.');
        }
      },
    });
  }

  checkValidEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
}
