<article id="start-review">
  <header class="admin-header-1">
    Start with AlphaReview and <br />
    Import AliExpress Reviews Now
  </header>
  <summary class="admin-content-description">
    Get started with AlphaReview in just a minute! <br />
    AlphaReview supports you in collecting, managing, and displaying reviews seamlessly.
  </summary>

  <section class="content-wrapper">
    <section class="index-marquee">
      <div class="marquee">
        <ul class="marquee-content">
          <li><img src="assets/image/onboarding/index_marquee_1.png" /></li>
          <li>
            <div class="flex-wrapper" style="background-color: #fff; border-radius: 6px; justify-content: flex-start; padding: 10px">
              <img style="margin-right: 6px" src="assets/image/onboarding/index_marquee_2.png" />
              <div style="line-height: 1; font-size: 11px">
                <div style="display: inline-flex; align-items: center; font-size: 11px">
                  Chloe
                  <span style="margin-left: 2px">liked your review</span>
                </div>
                <span style="color: #91949c; display: block">3 min ago</span>
              </div>
            </div>
          </li>
          <li><img src="assets/image/onboarding/index_marquee_3.png" /></li>
          <li><img src="assets/image/onboarding/index_marquee_4.png" /></li>
          <li style="font-size: 8px; background-color: #fff; border-radius: 6px; padding: 10px">
            <div style="border-radius: 4px; border: solid 0.5px #d7d9de; padding: 9px 7px; text-align: center">
              <div class="flex-wrapper" style="justify-content: center; line-height: 1.2">
                <img src="assets/icon/star_purple.svg" />
                <div style="font-size: 16px; font-weight: 600; margin-left: 2px">
                  <span #ratings>4.8</span>
                  <span style="color: #b8bbc2; font-weight: normal">&nbsp;/5</span>
                </div>
              </div>
              <div style="line-height: 1; padding-bottom: 5px; margin-bottom: 5px; border-bottom: 0.5px solid #d7d9de">
                <span style="color: #b8bbc2">Reviews </span><span #reviewCounts style="font-weight: 600">340</span>
              </div>
              <div style="line-height: 1">Share your experience!</div>
              <div
                class="btn-outline"
                style="
                  padding: 4px 7px;
                  border-radius: 4px;
                  font-size: 10px;
                  font-weight: 600;
                  height: fit-content;
                  width: fit-content;
                  line-height: 1;
                  margin: 6px auto 0;
                  cursor: initial;
                "
              >
                Write a review
              </div>
            </div>
            <div style="margin-top: 9px">
              <div class="flex-wrapper" style="justify-content: flex-start; margin-bottom: 8px">
                <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                  <img style="margin-right: 1px" src="assets/icon/star_purple.svg" />
                </ng-container>
              </div>
              <div style="width: 115px; height: 8px; background-color: #eee; margin-bottom: 8px; border-radius: 4px"></div>
              <div style="width: 85px; height: 8px; background-color: #eee; margin-bottom: 8px; border-radius: 4px"></div>
              <div style="width: 100px; height: 8px; background-color: #eee; border-radius: 4px"></div>
            </div>
          </li>
          <li><img src="assets/image/onboarding/index_marquee_5.png" /></li>
        </ul>

        <ul class="marquee-content">
          <li><img src="assets/image/onboarding/index_marquee_1.png" /></li>
          <li class="flex-wrapper" style="background-color: #fff; border-radius: 6px; justify-content: flex-start; padding: 10px">
            <img style="margin-right: 6px" src="assets/image/onboarding/index_marquee_2.png" />
            <div style="line-height: 1; font-size: 11px">
              <div style="display: inline-flex; align-items: center; font-size: 11px">
                Chloe
                <span style="margin-left: 2px; line-height: 1.2">liked your review</span>
              </div>
              <span style="color: #91949c; display: block">3 min ago</span>
            </div>
          </li>
          <li><img src="assets/image/onboarding/index_marquee_3.png" /></li>
          <li><img src="assets/image/onboarding/index_marquee_4.png" /></li>
          <li style="font-size: 8px; background-color: #fff; border-radius: 6px; padding: 10px">
            <div style="border-radius: 4px; border: solid 0.5px #d7d9de; padding: 9px 7px; text-align: center">
              <div class="flex-wrapper" style="justify-content: center; line-height: 1.2">
                <img src="assets/icon/star_purple.svg" />
                <div style="font-size: 16px; font-weight: 600; margin-left: 2px">
                  <span #ratings>4.8</span>
                  <span style="color: #b8bbc2; font-weight: normal">&nbsp;/5</span>
                </div>
              </div>
              <div style="line-height: 1; padding-bottom: 5px; margin-bottom: 5px; border-bottom: 0.5px solid #d7d9de">
                <span style="color: #b8bbc2">Reviews </span><span #reviewCounts style="font-weight: 600">340</span>
              </div>
              <div style="line-height: 1">Share your experience!</div>
              <div
                class="btn-outline"
                style="
                  padding: 4px 7px;
                  border-radius: 4px;
                  font-size: 10px;
                  font-weight: 600;
                  height: fit-content;
                  width: fit-content;
                  line-height: 1;
                  margin: 6px auto 0;
                  cursor: initial;
                "
              >
                Write a review
              </div>
            </div>
            <div style="margin-top: 9px">
              <div class="flex-wrapper" style="justify-content: flex-start; margin-bottom: 8px">
                <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                  <img style="margin-right: 1px" src="assets/icon/star_purple.svg" />
                </ng-container>
              </div>
              <div style="width: 115px; height: 8px; background-color: #eee; margin-bottom: 8px; border-radius: 4px"></div>
              <div style="width: 85px; height: 8px; background-color: #eee; margin-bottom: 8px; border-radius: 4px"></div>
              <div style="width: 100px; height: 8px; background-color: #eee; border-radius: 4px"></div>
            </div>
          </li>
          <li><img src="assets/image/onboarding/index_marquee_5.png" /></li>
        </ul>
      </div>

      <div class="marquee">
        <ul class="marquee-content">
          <li><img src="assets/image/onboarding/index_marquee_6.png" /></li>
          <li class="flex-wrapper" style="background-color: #fff; border-radius: 6px; justify-content: space-between; padding: 10px 5px">
            <div style="line-height: 1; text-align: center; flex-basis: calc(100% / 3)">
              <div style="font-size: 11px; font-weight: 600">20</div>
              <div style="font-size: 6px; color: #91949c">Posts</div>
            </div>
            <div style="line-height: 1; text-align: center; flex-basis: calc(100% / 3)">
              <div #followers style="font-size: 11px; font-weight: 600">8645</div>
              <div style="font-size: 6px; color: #91949c">Followers</div>
            </div>
            <div style="line-height: 1; text-align: center; flex-basis: calc(100% / 3)">
              <div style="font-size: 11px; font-weight: 600">88</div>
              <div style="font-size: 6px; color: #91949c">Following</div>
            </div>
          </li>
          <li><img src="assets/image/onboarding/index_marquee_8.png" /></li>
          <li><img src="assets/image/onboarding/index_marquee_9.png" /></li>
          <li><img src="assets/image/onboarding/index_marquee_10.png" /></li>
          <li style="background-color: #fff; border-radius: 6px; padding: 10px; line-height: 1.4">
            <div>Sales</div>
            <div #salesCount style="font-size: 14px; font-weight: bold">$ 12.345</div>
            <img src="assets/image/onboarding/index_marquee_11.png" />
          </li>
        </ul>

        <ul class="marquee-content">
          <li><img src="assets/image/onboarding/index_marquee_6.png" /></li>
          <li class="flex-wrapper" style="background-color: #fff; border-radius: 6px; justify-content: space-between; padding: 10px 5px">
            <div style="line-height: 1; text-align: center; flex-basis: calc(100% / 3)">
              <div style="font-size: 11px; font-weight: 600">20</div>
              <div style="font-size: 6px; color: #91949c">Posts</div>
            </div>
            <div style="line-height: 1; text-align: center; flex-basis: calc(100% / 3)">
              <div #followers style="font-size: 11px; font-weight: 600">8645</div>
              <div style="font-size: 6px; color: #91949c">Followers</div>
            </div>
            <div style="line-height: 1; text-align: center; flex-basis: calc(100% / 3)">
              <div style="font-size: 11px; font-weight: 600">88</div>
              <div style="font-size: 6px; color: #91949c">Following</div>
            </div>
          </li>
          <li><img src="assets/image/onboarding/index_marquee_8.png" /></li>
          <li><img src="assets/image/onboarding/index_marquee_9.png" /></li>
          <li><img src="assets/image/onboarding/index_marquee_10.png" /></li>
          <li style="background-color: #fff; border-radius: 6px; padding: 10px; line-height: 1.4">
            <div>Sales</div>
            <div #salesCount style="font-size: 14px; font-weight: bold">$ 12,345</div>
            <img src="assets/image/onboarding/index_marquee_11.png" />
          </li>
        </ul>
      </div>
    </section>
  </section>

  <section class="info-text">In the free plan, you can import up to the latest 100 reviews for this product.</section>
  <section class="flex-wrapper" style="justify-content: flex-end; gap: 10px">
    <div (click)="onCancel()" class="btn-white" style="height: 36px; width: 80px">Cancel</div>
    <div (click)="onConfirm()" class="btn-black" style="width: 191px">Start with AlphaReview</div>
  </section>
</article>
