"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.relativePathSchema = exports.relativeUrlSchema = exports.isValidRelativePath = exports.createActionValidator = void 0;
var type_validate_1 = require("./type-validate");
function createActionValidator(type, payloadSchema, payloadRequired, idRequired) {
    if (payloadSchema === void 0) { payloadSchema = undefined; }
    if (payloadRequired === void 0) { payloadRequired = false; }
    if (idRequired === void 0) { idRequired = false; }
    var idSchema = type_validate_1.matchesObject({
        id: idRequired ? type_validate_1.matchesString() : type_validate_1.makeOptional(type_validate_1.matchesString()),
    });
    var schema = payloadSchema ? type_validate_1.composeSchemas(idSchema, payloadSchema) : idSchema;
    return type_validate_1.matchesObject({
        type: type_validate_1.matchesEnum(type, {
            message: function (_, val) { return "The action type `" + val + "` is invalid or unsupported"; },
        }),
        payload: payloadRequired ? schema : type_validate_1.makeOptional(schema),
    });
}
exports.createActionValidator = createActionValidator;
function isValidRelativePath(path) {
    return typeof path === 'string' && (path === '' || path.startsWith('/'));
}
exports.isValidRelativePath = isValidRelativePath;
exports.relativeUrlSchema = type_validate_1.composeSchemas(type_validate_1.matchesString(), function (value) {
    return isValidRelativePath(value)
        ? undefined
        : [{ error: 'invalid_relative_path', value: value, message: 'expected string to start with `/`' }];
});
exports.relativePathSchema = type_validate_1.matchesObject({
    path: exports.relativeUrlSchema,
});
