<section class="analysis-loader" [ngClass]="{ loading: loading }">
  <div class="progress-area">
    <div class="progress"></div>
  </div>
  <header class="admin-header-1" style="gap: 7px">
    <img src="assets/icon/emoji_loader.png" />
    Analyzing Reviews...
  </header>
  <summary>
    Hang tight! We're uncovering valuable insights for you. <br />
    This won't take long.
  </summary>

  <swiper #loadingSwiper [config]="swiperConfig">
    <ng-template swiperSlide>
      <img src="assets/image/page/guidance_reviews.png" />
    </ng-template>
    <ng-template swiperSlide>
      <div class="gif-wrapper">
        <img class="gif" src="assets/image/landing/collect-main.gif" />
      </div>
    </ng-template>
    <ng-template swiperSlide>
      <img src="assets/image/page/guidance_import.png" />
    </ng-template>
    <ng-template swiperSlide>
      <img src="assets/image/page/guidance_display.png" />
    </ng-template>
    <ng-template swiperSlide>
      <img src="assets/image/page/guidance_analytics.png" />
    </ng-template>
    <ng-template swiperSlide>
      <img src="assets/image/page/guidance_sales.png" />
    </ng-template>
    <ng-template swiperSlide>
      <img src="assets/image/page/guidance_marketing.png" />
    </ng-template>
    <ng-template swiperSlide>
      <img src="assets/image/page/guidance_touch.png" />
    </ng-template>
  </swiper>
  <div class="pagination"></div>
</section>
