<article id="design-branding" class="gray-border-wrapper">
  <!-- <section class="design-preview">
    <section class="device-toggle-container">
      <div class="device-toggle" [ngClass]="{ active: mode == 'PC' }" (click)="mode = 'PC'">PC</div>
      <div class="device-toggle" [ngClass]="{ active: mode == 'MO' }" (click)="mode = 'MO'">Mobile</div>
    </section>

    @if(mode === 'PC') { @switch (browseBoosterService.browseBoosterSetup.web_position) { @case ('Top left') {
    <img src="assets/image/browse-booster/top-left-pc.png" [@inOutAnimation] /> } @case ('Center left') {
    <img src="assets/image/browse-booster/center-left-pc.png" [@inOutAnimation] /> } @case ('Bottom left') {
    <img src="assets/image/browse-booster/bottom-left-pc.png" [@inOutAnimation] /> } @case ('Top right') {
    <img src="assets/image/browse-booster/top-right-pc.png" [@inOutAnimation] /> } @case ('Center right') {
    <img src="assets/image/browse-booster/center-right-pc.png" [@inOutAnimation] /> } @case ('Bottom right') {
    <img src="assets/image/browse-booster/bottom-right-pc.png" [@inOutAnimation] /> } } } @if(mode === 'MO') { @switch (browseBoosterService.browseBoosterSetup.mobile_position) {
    @case ('Top left') { <img src="assets/image/browse-booster/top-left-mo.png" [@inOutAnimation] /> } @case ('Center left') {
    <img src="assets/image/browse-booster/center-left-mo.png" [@inOutAnimation] /> } @case ('Bottom left') {
    <img src="assets/image/browse-booster/bottom-left-mo.png" [@inOutAnimation] /> } @case ('Top right') {
    <img src="assets/image/browse-booster/top-right-mo.png" [@inOutAnimation] /> } @case ('Center right') {
    <img src="assets/image/browse-booster/center-right-mo.png" [@inOutAnimation] /> } @case ('Bottom right') {
    <img src="assets/image/browse-booster/bottom-right-mo.png" [@inOutAnimation] /> } } }
  </section> -->

  <section class="design-settings">
    <section class="design-color flex-wrapper">
      <header class="admin-header-4">Point Color</header>
      <div class="color-picker">
        <alpha-color-input class="content-input" [(ngModel)]="browseBoosterService.browseBoosterSetup.point_color"></alpha-color-input>
        <summary class="admin-content-description" style="padding: 6px 0 0; font-size: 12px">Point color will be applied to the button component color</summary>
      </div>
    </section>

    <section class="design-position">
      <header class="admin-header-3">Floating Action Button Position</header>
      <div class="flex-wrapper">
        <section class="position web">
          <header class="admin-header-4 list-circle">Web</header>
          <div class="flex-wrapper">
            <header class="admin-header-4">Position</header>
            <span [matMenuTriggerFor]="webPosition" class="setup-select" style="width: 141px"
              >{{ browseBoosterService.browseBoosterSetup.web_position }} <img src="assets/icon/arrow_down_deep_gray.svg"
            /></span>
            <mat-menu #webPosition="matMenu" class="browse-booster-menu">
              @for (position of positionOptions; track $index) {
              <div mat-menu-item (click)="browseBoosterService.browseBoosterSetup.web_position = position; mode = 'PC'" style="width: 133px">{{ position }}</div>
              }
            </mat-menu>
          </div>
        </section>
        <section class="position mo">
          <header class="admin-header-4 list-circle">Mobile</header>
          <div class="flex-wrapper">
            <header class="admin-header-4">Position</header>
            <span [matMenuTriggerFor]="moPosition" class="setup-select" style="width: 141px"
              >{{ browseBoosterService.browseBoosterSetup.mobile_position }} <img src="assets/icon/arrow_down_deep_gray.svg"
            /></span>
            <mat-menu #moPosition="matMenu" class="browse-booster-menu">
              @for (position of positionOptions; track $index) {
              <div mat-menu-item (click)="browseBoosterService.browseBoosterSetup.mobile_position = position; mode = 'MO'" style="width: 133px">{{ position }}</div>
              }
            </mat-menu>
          </div>
        </section>
      </div>
    </section>
  </section>
</article>
