import { Component, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ContactInformation } from 'src/app/models/shop/profile/contact-information.model';
import { ShopDetail } from 'src/app/models/shop/profile/store-detail.model';
import { WebpushPrivacySetting } from 'src/app/models/shop/profile/webpush-privacy.model';
import { AdminService } from 'src/app/service/admin.service';
import { BackendService } from 'src/app/service/backend.service';
import { GenericService } from 'src/app/service/generic.service';
import { ShopService } from 'src/app/service/shop.service';
import { DialogEmailVerifyComponent } from './dialog-email-verify/dialog-email-verify.component';
import { COUNTRY } from 'src/app/shared/preset/country';
import { INDUSTRY } from 'src/app/shared/preset/industry';
import { cloneDeep, isEqual } from 'lodash';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-store-setting',
  templateUrl: './store-setting.component.html',
  styleUrls: ['./store-setting.component.less'],
})
export class StoreSettingComponent implements OnInit {
  @Input() useAlphaPlus?: boolean;

  constructor(
    public dialog: MatDialog,
    private backendService: BackendService,
    private adminService: AdminService,
    public genericService: GenericService,
    @Optional() @SkipSelf() public shopService: ShopService,
  ) {}

  shopDetail!: ShopDetail;
  originShopDetail!: ShopDetail;

  contactInformation!: ContactInformation;
  originContactInformation!: ContactInformation;

  webpushPrivacy!: WebpushPrivacySetting;

  shopLogo?: File;
  countries: string[] = COUNTRY;
  industries: string[] = INDUSTRY;
  weakAuthentication: boolean = false;

  ngOnInit(): void {
    this.getShopDetail();
    this.getShopContactInformation();
    this.useAlphaPlus && this.getWebpushPrivacy();
  }

  openEmailVerifyDialog() {
    const dialogRef = this.dialog.open(DialogEmailVerifyComponent, {
      width: '800px',
      maxHeight: '630px',
      height: 'fit-content',
      data: this.contactInformation,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getShopContactInformation();
    });
  }

  getShopContactInformation() {
    this.shopService
      .getShopContactInformation({
        shop_access_code: this.adminService.getShopAccessCode(),
      })
      .subscribe((response) => {
        this.contactInformation = response.body;
        this.contactInformation.origin_email_sender_name = this.contactInformation.email_sender_email;
        this.contactInformation.origin_domain = this.contactInformation.domain;

        this.originContactInformation = cloneDeep(this.contactInformation);

        this.weakAuthentication =
          this.contactInformation.domain_status !== this.shopService.IdentityStatus.VERIFIED && this.contactInformation.email_status == this.shopService.IdentityStatus.VERIFIED;
      });
  }

  patchShopContactInformation() {
    this.shopService
      .patchShopContactInformation(this.contactInformation, {
        shop_access_code: this.adminService.getShopAccessCode(),
      })
      .subscribe((response) => {
        this.contactInformation = response.body;
        this.originContactInformation = cloneDeep(this.contactInformation);
        this.backendService.openSnackBar('Success!');
      });
  }

  getShopDetail() {
    this.shopService.getShopDetail().subscribe((response) => {
      this.shopDetail = response.body;
      this.originShopDetail = cloneDeep(this.shopDetail);
    });
  }

  patchShopDetail() {
    this.shopService.patchShopDetail(this.shopDetail).subscribe((response) => {
      // 서버로부터 받은 ShopDetail 객체 내의 store_name과 industry 속성
      const { store_name, industry } = response.body;

      // 현재 세션에서 ShopAdminAccount 객체 가져오기
      let currentAdminAccount = this.adminService.ShopAdminAccount;

      // 현재 선택된 shop의 shop_detail 업데이트
      if (currentAdminAccount && currentAdminAccount.shop && currentAdminAccount.shop.shop_detail) {
        currentAdminAccount.shop.shop_detail.store_name = store_name;
        currentAdminAccount.shop.shop_detail.industry = industry;
      }

      // shops 배열 내의 해당 shop 찾아서 업데이트
      const shopIndex = currentAdminAccount.shops.findIndex((shop) => shop.id === currentAdminAccount.shop?.id);
      if (currentAdminAccount && shopIndex !== -1 && currentAdminAccount.shops[shopIndex].shop_detail) {
        currentAdminAccount.shops[shopIndex].shop_detail!.store_name = store_name;
        currentAdminAccount.shops[shopIndex].shop_detail!.industry = industry;
      }

      // 업데이트된 ShopAdminAccount 객체로 세션 업데이트
      this.adminService.setAdminAccountSession(currentAdminAccount);

      this.shopDetail = response.body;
      this.originShopDetail = cloneDeep(this.shopDetail);
      this.backendService.openSnackBar('Success!');
    });
  }

  getWebpushPrivacy() {
    let url = 'shops/privacy';

    this.backendService.select(url, { shop_access_code: this.adminService.getShopAccessCode() }).subscribe((response) => {
      this.webpushPrivacy = response.body;
    });
  }

  patchWebpushPrivacy() {
    let url = 'shops/privacy';

    this.backendService
      .create(url, this.webpushPrivacy, {
        shop_access_code: this.adminService.getShopAccessCode(),
      })
      .subscribe((response) => {
        this.webpushPrivacy = response.body;
        this.backendService.openSnackBar('Success!');
      });
  }

  getShopLogoChange(event: any) {
    this.shopLogo = event;
  }
  getShopDetailChange(event: any) {
    this.shopDetail = event;
  }

  checkSettingsChange() {
    return isEqual(this.contactInformation, this.originContactInformation) && isEqual(this.shopDetail, this.originShopDetail);
  }
}
