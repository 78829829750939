<article id="analytics" class="setting-article" style="max-width: 1240px; padding-bottom: 20px" #ContainerRef>
  <section class="flex-wrapper">
    <header class="admin-header-1" style="gap: 10px">
      Analytics <a class="btn-anchor" href="https://alph.oopy.io/dc24905c-c3e1-4e3f-8416-b587a3d86d88" target="_blank">Help</a>
    </header>

    <alpha-datepicker [(selected)]="dateRange" (selectedChange)="onChangeDateRange($event)"></alpha-datepicker>
  </section>

  <section class="gray-border-wrapper">
    <header class="admin-header-3">Reviews</header>
    <summary class="admin-content-description">This graph shows the number of reviews have been written through the requests or webpage.</summary>
    <section class="analytics-filter">
      <div style="display: flex">
        <div class="filter-btn filter-btn-product" style="margin-right: 6px" *ngIf="analyticsParams.review_count.product">
          <img class="filter-btn-product-image" [src]="analyticsParams.review_count.product.product_image" />
          <div class="filter-btn-product-title ellipsis">
            {{ analyticsParams.review_count.product.title }}
          </div>
          <img class="filter-btn-product-delete" (click)="deleteProduct('review_count')" src="assets/icon/close_big_gray.svg" />
        </div>
        <div class="btn-white" style="width: 142px" (click)="openDialogProductVariantSelect('review_count')">Select a product</div>
      </div>
      <div class="select-wrapper">
        <select class="filter-select" style="width: 190px" [(ngModel)]="analyticsParams.review_count.filter.reviewType" (ngModelChange)="getReviewCount()">
          <option [value]="[reviewService.ReviewType.BASIC_REVIEW, reviewService.ReviewType.LONGTERM_USE_REVIEW, reviewService.ReviewType.SOCIAL_REVIEW]" selected disabled>
            Review type
          </option>
          <option [ngValue]="[reviewService.ReviewType.BASIC_REVIEW, reviewService.ReviewType.LONGTERM_USE_REVIEW, reviewService.ReviewType.SOCIAL_REVIEW]">All</option>
          <option [ngValue]="[reviewService.ReviewType.BASIC_REVIEW]">Basic review</option>
          <option [ngValue]="[reviewService.ReviewType.LONGTERM_USE_REVIEW]">Long-term Review</option>
          <option [ngValue]="[reviewService.ReviewType.SOCIAL_REVIEW]">Social review</option>
        </select>
        <select [(ngModel)]="analyticsParams.review_count.filter.reviewSort" (ngModelChange)="getReviewCount()" class="filter-select" style="width: 190px">
          <option [value]="['buyer', 'unknown']" selected disabled>Reviewer type</option>
          <option [ngValue]="['buyer', 'unknown']">All</option>
          <option [ngValue]="['buyer']">Buyer</option>
          <option [ngValue]="['unknown']">Unknown</option>
        </select>
      </div>
    </section>
    <section *ngIf="analyticsParams.review_count.data.length">
      <ngx-charts-bar-vertical
        [view]="[ContainerRef.offsetWidth - 60, 300]"
        [results]="analyticsParams.review_count.data"
        [legend]="false"
        [yAxis]="true"
        [xAxis]="false"
        [customColors]="customColors"
        [roundEdges]="false"
        [barPadding]="5"
        [roundDomains]="true"
      >
      </ngx-charts-bar-vertical>
    </section>
  </section>
  <section class="gray-border-wrapper">
    <header class="admin-header-3">Review Rate</header>
    <summary class="admin-content-description">This graph shows the percentage of basic reviews for orders that already have review requests.</summary>
    <section class="analytics-filter">
      <section class="analytics-filter">
        <div style="display: flex">
          <div class="filter-btn filter-btn-product" style="margin-right: 6px" *ngIf="analyticsParams.review_rate.product">
            <img class="filter-btn-product-image" [src]="analyticsParams.review_rate.product.product_image" />
            <div class="filter-btn-product-title ellipsis">
              {{ analyticsParams.review_rate.product.title }}
            </div>
            <img class="filter-btn-product-delete" (click)="deleteProduct('review_rate')" src="assets/icon/close_big_gray.svg" />
          </div>
          <div class="btn-white" style="width: 142px" (click)="openDialogProductVariantSelect('review_rate')">Select a product</div>
        </div>
      </section>
      <div class="select-wrapper">
        <select class="filter-select" style="width: 190px" [(ngModel)]="analyticsParams.review_rate.filter.isManual" (ngModelChange)="getReviewRate()">
          <option [value]="[true, false]" selected disabled>Order sorts</option>
          <option [ngValue]="[true, false]">All</option>
          <option [ngValue]="[true]">Shopify orders only</option>
          <option [ngValue]="[false]">Manual orders only</option>
        </select>
      </div>
    </section>

    <div *ngIf="analyticsParams.review_rate.data.length" style="position: relative">
      <ngx-charts-line-chart
        [view]="[ContainerRef.offsetWidth - 60, 300]"
        [results]="analyticsParams.review_rate.data"
        [legend]="false"
        [yAxis]="true"
        [xAxis]="false"
        [customColors]="customColors"
        [rotateXAxisTicks]="false"
        [xAxisTicks]="[1]"
      >
      </ngx-charts-line-chart>
    </div>
  </section>

  <section class="gray-border-wrapper">
    <header class="admin-header-3">Requests</header>
    <summary class="admin-content-description">This graph shows the number of requests have been made automatically and manually.</summary>
    <section class="analytics-filter">
      <div style="display: flex">
        <div class="filter-btn filter-btn-product" style="margin-right: 6px" *ngIf="analyticsParams.request_count.product">
          <img class="filter-btn-product-image" [src]="analyticsParams.request_count.product.product_image" />
          <div class="filter-btn-product-title ellipsis">
            {{ analyticsParams.request_count.product.title }}
          </div>
          <img class="filter-btn-product-delete" (click)="deleteProduct('request_count')" src="assets/icon/close_big_gray.svg" />
        </div>
        <div class="btn-white" style="width: 142px" (click)="openDialogProductVariantSelect('request_count')">Select a product</div>
      </div>
      <div class="select-wrapper">
        <select [(ngModel)]="analyticsParams.request_count.filter.senddingChannel" class="filter-select" style="width: 190px" (ngModelChange)="getRequestCount()">
          <option [value]="[requestService.SendingChannel.EMAIL, requestService.SendingChannel.SMS, requestService.SendingChannel.WEBPUSH]" selected disabled>
            Request channel
          </option>
          <option [ngValue]="[requestService.SendingChannel.EMAIL, requestService.SendingChannel.SMS, requestService.SendingChannel.WEBPUSH]">All</option>
          <option [ngValue]="[requestService.SendingChannel.EMAIL]">Email</option>
          <option [ngValue]="[requestService.SendingChannel.SMS]">SMS</option>
          <option [ngValue]="[requestService.SendingChannel.WEBPUSH]">WebPush</option>
        </select>
        <select [(ngModel)]="analyticsParams.request_count.filter.senddingStatus" class="filter-select" style="width: 190px" (ngModelChange)="getRequestCount()">
          <option
            [value]="[requestService.SendingStatus.WAITING, requestService.SendingStatus.SUCCESS, requestService.SendingStatus.CANCEL, requestService.SendingStatus.FAILURE]"
            selected
            disabled
          >
            Request Status
          </option>
          <option
            [ngValue]="[requestService.SendingStatus.WAITING, requestService.SendingStatus.SUCCESS, requestService.SendingStatus.CANCEL, requestService.SendingStatus.FAILURE]"
          >
            All
          </option>
          <option [ngValue]="[requestService.SendingStatus.WAITING]">Pending</option>
          <option [ngValue]="[requestService.SendingStatus.SUCCESS]">Sent</option>
          <option [ngValue]="[requestService.SendingStatus.CANCEL, requestService.SendingStatus.FAILURE]">Failed/Deleted</option>
        </select>
      </div>
    </section>

    <section *ngIf="analyticsParams.request_count.data.length">
      <ngx-charts-bar-vertical
        [view]="[ContainerRef.offsetWidth - 60, 300]"
        [results]="analyticsParams.request_count.data"
        [legend]="false"
        [yAxis]="true"
        [xAxis]="false"
        [customColors]="customColors"
        [roundEdges]="false"
        [barPadding]="5"
        [roundDomains]="true"
      >
      </ngx-charts-bar-vertical>
    </section>
  </section>
  <section class="gray-border-wrapper">
    <header class="admin-header-3">Ratings</header>
    <summary class="admin-content-description">This graph shows the daily average ratings.</summary>
    <section class="analytics-filter">
      <div style="display: flex">
        <div class="filter-btn filter-btn-product" style="margin-right: 6px" *ngIf="analyticsParams.ratings.product">
          <img class="filter-btn-product-image" [src]="analyticsParams.ratings.product.product_image" />
          <div class="filter-btn-product-title ellipsis">
            {{ analyticsParams.ratings.product.title }}
          </div>
          <img class="filter-btn-product-delete" (click)="deleteProduct('ratings')" src="assets/icon/close_big_gray.svg" />
        </div>
        <div class="btn-white" style="width: 142px" (click)="openDialogProductVariantSelect('ratings')">Select a product</div>
      </div>
    </section>

    <div *ngIf="analyticsParams.ratings.data.length" style="position: relative">
      <ngx-charts-line-chart
        [view]="[ContainerRef.offsetWidth - 60, 300]"
        [results]="analyticsParams.ratings.data"
        [legend]="false"
        [yAxis]="true"
        [xAxis]="false"
        [customColors]="customColors"
      >
      </ngx-charts-line-chart>
    </div>
  </section>

  <section class="analtics-rank-container">
    <section class="gray-border-wrapper">
      <header class="admin-header-2">Top Products by reviews</header>

      <section>
        <header class="table-header">
          <div class="rank">Rank</div>
          <div class="title ellipsis">Product</div>
          <div class="rating">Ratings</div>
          <div class="reviews">Reviews</div>
        </header>
        <main class="table-row-container">
          <section class="table-row" *ngFor="let item of analyticsParams.top_product.data; let idx = index">
            <div class="rank">
              {{ idx + 1 }}
            </div>
            <div class="title" (click)="routeReview(item.id, undefined)" style="cursor: pointer; display: flex; align-items: center; gap: 6px">
              <img class="icon-share-out" src="assets/icon/external_link.svg" />
              <div class="title-content ellipsis-1">
                {{ item.title }}
              </div>
            </div>
            <div class="rating">★ {{ item.rating | number : '1.1-1' }}</div>
            <div class="reviews">
              <div class="flex-wrapper" style="gap: 4px">
                <div class="reviews-triangle">▲</div>
                <div class="reviews-increment">
                  {{ item.increase | number }}
                </div>
              </div>
              <div class="reviews-total" style="font-size: 13px">
                {{ item.total | number }}
              </div>
            </div>
          </section>
        </main>
      </section>
    </section>

    <section class="gray-border-wrapper">
      <header class="admin-header-2">Top Customers by reviews</header>

      <section>
        <header class="table-header">
          <div class="rank">Rank</div>
          <div class="title ellipsis">Customer</div>
          <!-- <div class="rating">Ratings</div> -->
          <div class="reviews">Reviews</div>
        </header>
        <main class="table-row-container">
          <section class="table-row" *ngFor="let item of analyticsParams.top_customer.data; let idx = index">
            <div class="rank">
              {{ idx + 1 }}
            </div>
            <div class="title" (click)="routeReview(undefined, item.customer_name)" style="cursor: pointer; display: flex; align-items: center; gap: 6px">
              <img class="icon-share-out" src="assets/icon/external_link.svg" />
              <div class="title-content ellipsis-1">
                {{ item.customer_name }}
              </div>
            </div>
            <!-- <div class="rating">★ {{ 4.5 | number : '1.1-1' }}</div> -->
            <div class="reviews">
              <div class="flex-wrapper" style="gap: 4px">
                <div class="reviews-triangle">▲</div>
                <div class="reviews-increment">
                  {{ item.increase | number }}
                </div>
              </div>
              <div class="reviews-total" style="font-size: 13px">
                {{ item.total | number }}
              </div>
            </div>
          </section>
        </main>
      </section>
    </section>
  </section>
</article>
