<article id="touchpoints" class="setting-article" style="max-width: 1000px">
  <header class="admin-header-1" style="gap: 10px">
    Touchpoints <a class="btn-anchor" href="https://alph.oopy.io/b2f85d90-c593-4e9f-92e7-95bcbbc2b4c7" target="_blank">Help</a>
  </header>
  <summary class="admin-content-description">Manage touchpoints with customers, including notification emails and customer notices.</summary>
  <mat-tab-group class="admin-tab" style="margin-bottom: 16px" [selectedIndex]="genericService.changeSelectedIndex()">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="notification" (click)="genericService.LNBCategory = 'Notification Emails'">
          <div>Notification Emails</div>
        </div>
      </ng-template>
      <app-touchpoints-notification></app-touchpoints-notification>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="customer-notice" (click)="genericService.LNBCategory = 'Customer Notice'">
          <div>Customer Notice</div>
        </div>
      </ng-template>
      <app-customer-service-notice></app-customer-service-notice>
    </mat-tab>
  </mat-tab-group>
</article>
