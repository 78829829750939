import { Component, Inject, OnInit, Optional, SkipSelf } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Product } from 'src/app/models/product/product.model';
import { Review, ReviewMedia, ReviewMediaType, ReviewType, WriterType } from 'src/app/models/review/review.model';
import { AdminService } from 'src/app/service/admin.service';
import { ProductService } from 'src/app/service/product.service';
import { ReviewService } from 'src/app/service/review.service';
import { GenericService } from 'src/app/service/generic.service';
import { CustomConfirmDialogComponent } from 'src/app/dialog/custom-confirm-dialog/custom-confirm-dialog.component';
import { inOutAnimation } from 'src/app/admin/animations';
import { cloneDeep, isEqual } from 'lodash';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-manage-review-write',
  templateUrl: './dialog-manage-review-write.component.html',
  styleUrls: ['./dialog-manage-review-write.component.less'],
  animations: [inOutAnimation],
})
export class DialogManageReviewWriteComponent implements OnInit {
  constructor(
    @Optional() @SkipSelf() private adminService: AdminService,
    @Optional() @SkipSelf() public reviewService: ReviewService,
    @Inject(MAT_DIALOG_DATA) public data: { type: string; review?: Review },
    public productService: ProductService,
    public genericService: GenericService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogManageReviewWriteComponent>,
  ) {}

  ngOnInit(): void {
    if (this.data.type === 'edit') {
      this.review = this.data.review!;
      this.initialContent = this.review.content;
      this.originReview = cloneDeep(this.review);
    } else this.setNewReview();
  }

  review!: Review;
  originReview!: Review;
  products: Product[] = [];
  searchContent = '';
  selectedProduct?: Product;
  writeAReview: boolean = true;
  reviewMedia: UntypedFormControl = new UntypedFormControl('');
  reviewMediaMax: boolean = false;
  initialContent?: string;
  contentChangeAlert: boolean = false;
  isLoading: boolean = false;

  onContentChange(newContent: string) {
    this.initialContent = newContent;
    const modifiedCharacters = this.calculateModifiedCharacters(this.initialContent!, newContent);
    if (modifiedCharacters >= 50) this.contentChangeAlert = true;
    else this.contentChangeAlert = false;
  }

  calculateModifiedCharacters(initial: string, modified: string): number {
    const minLength = Math.min(initial.length, modified.length);
    const differences = Array.from({ length: minLength }, (_, i) => initial[i] !== modified[i]);
    return differences.reduce((count, diff) => count + (diff ? 1 : 0), Math.abs(initial.length - modified.length));
  }

  getProducts() {
    this.productService.getProducts({ shop_access_code: this.adminService.getShopAccessCode() }).subscribe((response) => {
      this.products = response.body.data as Product[];
    });
  }

  setNewReview() {
    this.review = new Review();

    if (this.data.type == 'product') {
      this.getProducts();
      this.writeAReview = false;
      this.review.type = ReviewType.BASIC_REVIEW;
    }

    if (this.data.type == 'store') {
      this.writeAReview = true;
      this.review.type = ReviewType.STORE_REVIEW;
    }

    this.review.shop_id = this.adminService.getShopId();
    this.review.created_at = new Date();
    this.review.customer_email = '';
    this.review.customer_name = '';
    this.review.writer_type = WriterType.ADMINISTRATOR;

    this.originReview = cloneDeep(this.review);
  }

  onCancel() {
    this.review.content = this.originReview.content;
    this.dialogRef.close();
  }

  checkSubmitLock() {
    const trigger =
      this.data.type !== 'edit'
        ? this.review.rating && this.review.created_at && this.review.customer_firstname && this.review.content
        : this.review.rating && this.review.created_at && this.review.customer_firstname && this.initialContent;

    if (!trigger) return false;
    if (this.data.type === 'edit' && this.contentChangeAlert) return false;
    return true;
  }

  onSubmit() {
    if (this.data.type === 'edit') this.review.content = this.initialContent!;

    const formData = this.reviewService.convertReviewToFormdata(this.review);
    this.reviewService.createAdminReview(formData, { shop_access_code: this.adminService.getShopAccessCode() }).subscribe(async (response) => {
      if (this.review.review_media.length) {
        this.isLoading = true;
        await this.genericService.delay(2000);
      }
      this.dialogRef.close(true);
      this.isLoading = false;
      this.reviewService.getManageReview();
    });
  }

  onClickSelectedProduct() {
    this.writeAReview = true;
    this.review.product = this.selectedProduct;
    this.review.product_id = this.selectedProduct!.id;
    this.review.product_variant = undefined;

    this.originReview = cloneDeep(this.review);
  }

  onChangeMedia(event: any) {
    this.showImagePreview(event);
  }

  showImagePreview(event: any) {
    if (!event.target.files && !event.target.files.length) return;
    const files: File[] = event.target.files;

    for (const file of files) {
      const reader = new FileReader();
      let data: ReviewMedia = new ReviewMedia();

      if (this.review.id) data.review_id = this.review.id;

      reader.readAsDataURL(file);

      if (file.type.indexOf('image') > -1) {
        data.type = ReviewMediaType.PHOTO;
      } else if (file.type.indexOf('video') > -1) {
        data.type = ReviewMediaType.VIDEO;
      } else {
        continue;
      }

      data.file = file;

      reader.onload = () => {
        data.previewURL = reader.result as string;
        this.review.review_media.push(data);
        this.maximumReviewMedia(this.review.review_media);
      };
    }
  }

  maximumReviewMedia(reviewMedia: ReviewMedia[]) {
    if (reviewMedia.length >= 5) {
      this.reviewMediaMax = true;
      reviewMedia.splice(5, reviewMedia.length);
    }
  }

  onClickReviewMediaDelete(reviewMedia: ReviewMedia) {
    this.review.review_media = this.review.review_media.filter((item) => item != reviewMedia);
    if (this.review.review_media.length < 5) this.reviewMediaMax = false;
  }

  checkedReviewWrite() {
    return isEqual(this.review, this.originReview);
  }

  openDialogCancelGuard(target: 'back' | 'cancel') {
    if (this.checkedReviewWrite() && target == 'back') this.writeAReview = false;
    if (this.checkedReviewWrite() && target == 'cancel') this.dialogRef.close();

    if (!this.checkedReviewWrite()) {
      let dialogRef = this.dialog.open(CustomConfirmDialogComponent, { width: '380px' });
      let instance = dialogRef.componentInstance;
      instance.header = target === 'back' ? 'Do you want to return to product selection?' : 'Do you want to stop writing a review?';
      instance.content =
        target === 'back'
          ? `If you go back to product selection, your current
          review progress will not be saved.`
          : `You haven't finished your review yet. If you leave
          now, your changes will not be saved. Do you
          want to go back and continue writing?`;
      instance.confirm = target === 'back' ? 'Go Back' : 'Leave';
      instance.cancel = 'Stay on Page';

      dialogRef.afterClosed().subscribe((result) => {
        if (!result) return;
        if (target === 'back') {
          this.writeAReview = false;
          this.review = this.originReview;
        } else {
          this.dialogRef.close();
        }
      });
    }
  }

  get ReviewType(): typeof ReviewType {
    return ReviewType;
  }

  get ReviewMediaType(): typeof ReviewMediaType {
    return ReviewMediaType;
  }
}
