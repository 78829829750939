import { Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthAdminService } from 'src/app/auth/auth-admin.service';
import { AuthSuperAdminService } from 'src/app/auth/auth-super-admin.service';
import { ShopAdminAccount } from 'src/app/models/shop/shop.model';
import { SuperAdminDetailOption } from 'src/app/models/super-admin/super-admin.shops.enums';
import { SuperAdminShopInfo } from 'src/app/models/super-admin/super-admin.shops.model';
import { AdminService } from 'src/app/service/admin.service';
import { ShopService } from 'src/app/service/shop.service';
import { SuperAdminService } from 'src/app/service/super-admin.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.less'],
  encapsulation: ViewEncapsulation.Emulated,

})
export class NavComponent implements OnInit {
  @Input('option') option?: SuperAdminDetailOption;
  @Output() changeOption = new EventEmitter<number>();
  @Input('shopInfo') shopInfo?: SuperAdminShopInfo;
  constructor(
    private router: Router,
    public adminService: AdminService,
    public superService: SuperAdminService,
    public authSuperAdminService: AuthSuperAdminService,
    public shopService: ShopService,
  ) { }

  ngOnInit(): void {
  }

  setOptionInfo() {
    this.changeOption.emit(SuperAdminDetailOption.SHOP_INFO)
  }

  setOptionStatus() {
    this.changeOption.emit(SuperAdminDetailOption.APP_STATUS)
  }
  toShop() {
    window.open(`https://${this.shopInfo?.shop_detail?.store_url}`, "_blank")
  }
  toDashboard() {
    this.authSuperAdminService.shopDashboardBridge(this.shopInfo?.id as number).subscribe(response => {
      if (response.status == 200) {
        const shopAdminAccount = response.body as ShopAdminAccount

        this.adminService.setAdminAccountSession(shopAdminAccount)
        this.shopService.currency = shopAdminAccount.shop!.currency as string

        const url = this.router.serializeUrl(
          this.router.createUrlTree([`/admin/login/bridge/${shopAdminAccount.access_token}`])
        );
        window.open(url, '_blank');
      }
    })
  }
}
