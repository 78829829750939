<article id="program-setup">
  <mat-accordion multi="true">
    <mat-expansion-panel class="gray-border-wrapper" style="margin-top: 16px">
      <mat-expansion-panel-header>
        <mat-panel-title class="setup-header">
          <img src="assets/icon/booster_mission.svg" />
          <div class="setup-header-content">
            <header class="admin-header-3">Mission Settings</header>
            <summary class="admin-content-description">Set the mission for customers browsing your store.</summary>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <section class="setup-contents">
        <div class="setup-mission-content">
          <header class="admin-header-4">When to show the program to customers</header>
          <summary class="admin-content-description">Set when the program will first be displayed to customers.</summary>
          <div class="setup-option">
            After
            <span [matMenuTriggerFor]="seconds" class="setup-select" style="width: 60px"
              >{{ browseBoosterService.browseBoosterSetup.time_to_show_program }} <img src="assets/icon/arrow_down_deep_gray.svg"
            /></span>
            <mat-menu #seconds="matMenu" class="browse-booster-menu">
              @for (seconds of [5, 10, 15, 20]; track $index) {
              <div mat-menu-item (click)="browseBoosterService.browseBoosterSetup.time_to_show_program = seconds">{{ seconds }}</div>
              }
            </mat-menu>
            seconds on
            <span [matMenuTriggerFor]="place" class="setup-select" style="width: 135px"
              >{{ browseBoosterService.browseBoosterSetup.place_to_show_program }} <img src="assets/icon/arrow_down_deep_gray.svg"
            /></span>
            <mat-menu #place="matMenu" class="browse-booster-menu">
              @for (place of ['any pages','product page', 'cart page']; track $index) {
              <div mat-menu-item (click)="browseBoosterService.browseBoosterSetup.place_to_show_program = place" style="width: 127px">{{ place }}</div>
              }
            </mat-menu>
          </div>
        </div>

        <div class="setup-mission-content">
          <header class="admin-header-4">Number of products to view for a coupon</header>
          <summary class="admin-content-description">Set how many products customers should explore to earn a coupon. Too many might lower participation.</summary>
          <div class="setup-option">
            Customers must view
            <span [matMenuTriggerFor]="viewProduct" class="setup-select" style="width: 60px"
              >{{ browseBoosterService.browseBoosterSetup.number_of_products }} <img src="assets/icon/arrow_down_deep_gray.svg"
            /></span>
            <mat-menu #viewProduct="matMenu" class="browse-booster-menu">
              @for (viewProduct of genericService.range(1, 11); track $index) {
              <div mat-menu-item (click)="browseBoosterService.browseBoosterSetup.number_of_products = viewProduct">{{ viewProduct }}</div>
              }
            </mat-menu>
            products to receive a coupon
            <span class="setup-info">(Maximum 10 products)</span>
          </div>
        </div>

        <div class="setup-mission-content">
          <header class="admin-header-4">Participation limits per customer</header>
          <summary class="admin-content-description">Set the number and frequency of customer program participation.</summary>
          <div class="setup-option" style="flex-direction: column; column-gap: 10px; align-items: flex-start">
            <div class="list-circle">
              Each customer can participate up to
              <span [matMenuTriggerFor]="limit" class="setup-select" style="width: 60px"
                >{{ browseBoosterService.browseBoosterSetup.participation_limit }} <img src="assets/icon/arrow_down_deep_gray.svg"
              /></span>
              <mat-menu #limit="matMenu" class="browse-booster-menu">
                @for (limit of [1,3,5]; track $index) {
                <div mat-menu-item (click)="browseBoosterService.browseBoosterSetup.participation_limit = limit">{{ limit }}</div>
                }
              </mat-menu>
              times
            </div>
            <div class="list-circle">
              Each customer can participate
              <span [matMenuTriggerFor]="participation" class="setup-select" style="width: 180px"
                >{{
                  browseBoosterService.browseBoosterSetup.frequency_of_participation === 1
                    ? 'once a day'
                    : browseBoosterService.browseBoosterSetup.frequency_of_participation === 7
                    ? 'once a week'
                    : 'Once every 2 weeks'
                }}
                <img src="assets/icon/arrow_down_deep_gray.svg"
              /></span>
              <mat-menu #participation="matMenu" class="browse-booster-menu">
                @for (participation of [1,7,14]; track $index) {
                <div mat-menu-item (click)="browseBoosterService.browseBoosterSetup.frequency_of_participation = participation" style="width: 172px">
                  {{ participation === 1 ? 'once a day' : participation === 7 ? 'once a week' : 'Once every 2 weeks' }}
                </div>
                }
              </mat-menu>
            </div>
          </div>
        </div>
      </section>
    </mat-expansion-panel>

    <mat-expansion-panel class="gray-border-wrapper">
      <mat-expansion-panel-header>
        <mat-panel-title class="setup-header">
          <img src="assets/icon/booster_coupon.svg" />
          <div class="setup-header-content">
            <header class="admin-header-3">Coupon Settings</header>
            <summary class="admin-content-description">Set the reward for customers who complete the mission.</summary>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <section class="setup-contents">
        <div class="setup-note"><strong>Note:</strong> The coupon is limited to one use per customer for a single order.</div>
        <div class="setup-discount">
          <header class="admin-header-4">Discount Value</header>
          <mat-radio-group [(ngModel)]="browseBoosterService.browseBoosterSetup.discount_value">
            @for (discountValue of [3,5,10,15]; track $index) {
            <mat-radio-button [value]="discountValue">{{ discountValue }}% Off</mat-radio-button>
            }
            <mat-radio-button [value]="toNumber(discountValueInput.value)">
              <input
                #discountValueInput
                placeholder="Custom"
                class="admin-input"
                style="width: 112px"
                [value]="![3, 5, 10, 15].includes(browseBoosterService.browseBoosterSetup.discount_value!) ? browseBoosterService.browseBoosterSetup.discount_value : null"
                (click)="browseBoosterService.browseBoosterSetup.discount_value = toNumber(discountValueInput.value)"
              />
              %
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="setup-discount">
          <header class="admin-header-4">Minimum Requirements</header>
          <mat-radio-group [(ngModel)]="browseBoosterService.browseBoosterSetup.use_minimum_requirement">
            <mat-radio-button [value]="false">None</mat-radio-button>
            <mat-radio-button [value]="true">
              Minimum purchase amount should be ₩
              <input
                class="admin-input"
                style="width: 75px"
                [(ngModel)]="browseBoosterService.browseBoosterSetup.minimum_requirement"
                (click)="browseBoosterService.browseBoosterSetup.use_minimum_requirement = true"
              />
              to use discount code
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="setup-discount">
          <header class="admin-header-4">Discount Code Name <span class="upcoming">Upcoming</span></header>
          <input class="admin-input" style="width: 200px" placeholder="THANKYOU" disabled /> <span class="setup-etc">0 <span class="soft">/200</span></span>
        </div>

        <div class="setup-discount">
          <header class="admin-header-4">Active Dates <span class="upcoming">Upcoming</span></header>
          <summary class="admin-content-description">The start date of the discount code is always fixed as the date on which the discount code was issued.</summary>
          <div class="setup-option">
            Valid for <span class="setup-select disabled" style="width: fit-content">24 hours <img src="assets/icon/arrow_down_deep_gray.svg" /></span> from the day issued
          </div>
        </div>

        <div class="setup-discount">
          <header class="admin-header-4">Combinations <span class="upcoming">Upcoming</span></header>
          <summary class="admin-content-description">This order discount can be combined with:</summary>
          <div class="setup-option" style="gap: 20px">
            <span class="fake"><input type="checkbox" disabled />Product discounts</span>
            <span class="fake"><input type="checkbox" disabled />Order discounts</span>
            <span class="fake"><input type="checkbox" disabled />Shipping discounts</span>
          </div>
        </div>
      </section>
    </mat-expansion-panel>

    <mat-expansion-panel class="gray-border-wrapper">
      <mat-expansion-panel-header>
        <mat-panel-title class="setup-header">
          <img src="assets/icon/booster_product.svg" />
          <div class="setup-header-content">
            <header class="admin-header-3">Recommended Product Settings</header>
            <summary class="admin-content-description" style="padding: 0">Select products to recommend for customer browsing.</summary>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <section class="setup-contents">
        <div class="setup-product">
          <header class="admin-header-4" style="gap: 10px">
            <img src="assets/icon/info_cool_gray.svg" /> Personalized Product Recommendations
            <alpha-toggle-btn [(sourceBoolean)]="browseBoosterService.browseBoosterSetup.use_personal_recommendation_product" [positiveText]="'active'"></alpha-toggle-btn>
          </header>
          <summary class="admin-content-description">
            Increase the likelihood of purchase conversions by encouraging customers to explore personalized product recommendations.
          </summary>
        </div>

        <div class="setup-product">
          <header class="admin-header-4">Manual Product Selection for Recommendation</header>
          <summary class="admin-content-description flex-wrapper" style="gap: 20px">
            Choose specific products to display in the recommended items list, giving you control over what customers see while browsing.
            <div (click)="openDialogProductVariantSelect()" class="btn-black" style="padding: 0 10px; width: fit-content; text-wrap: nowrap">Add a Product</div>
          </summary>

          <div class="setup-product-table">
            <header class="content-table-header">
              <div class="content-table-name delete" style="width: 28px; flex-shrink: 0"></div>
              <div class="content-table-name" style="width: 100%">{{ browseBoosterService.browseBoosterSetup.browse_booster_products.length }} products</div>
              <div class="content-table-name" style="width: 80px; flex-shrink: 0">Status</div>
            </header>

            @for (product of browseBoosterService.browseBoosterSetup.browse_booster_products; track $index) {
            <section class="content-table-row flex-wrapper">
              <div (click)="removeProduct(product)" class="delete" style="width: 28px; flex-shrink: 0"><img src="assets/icon/close_gray_circle.svg" /></div>
              <div class="product" style="width: 100%">
                <img class="thumbnail" src="{{ product.product_thumbnail }}" />
                <strong>{{ product.product_title }}</strong>
              </div>
              <div class="status" style="width: 80px; flex-shrink: 0">
                <div [ngClass]="product.product_status !== ProductStatus.ACTIVE ? 'inactive' : 'active'">
                  <span class="circle circle-out"><span class="circle circle-in"></span></span>
                  {{ product.product_status === ProductStatus.ACTIVE ? 'Active' : 'Inactive' }}
                </div>
              </div>
            </section>
            }
          </div>
        </div>
      </section>
    </mat-expansion-panel>
  </mat-accordion>
</article>
