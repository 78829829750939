"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isShopifyPing = exports.isShopifyPOS = exports.isShopifyMobile = exports.isShopifyEmbedded = exports.isMobile = void 0;
var env_1 = require("../util/env");
function isMobile() {
    return isShopifyMobile() || isShopifyPOS() || isShopifyPing();
}
exports.isMobile = isMobile;
function isShopifyEmbedded() {
    return (env_1.isClient && window.top !== window.self) || env_1.isUnframed;
}
exports.isShopifyEmbedded = isShopifyEmbedded;
function isShopifyMobile() {
    return typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Shopify Mobile') >= 0;
}
exports.isShopifyMobile = isShopifyMobile;
function isShopifyPOS() {
    return typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Shopify POS') >= 0;
}
exports.isShopifyPOS = isShopifyPOS;
function isShopifyPing() {
    return typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Shopify Ping') >= 0;
}
exports.isShopifyPing = isShopifyPing;
