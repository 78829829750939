<article id="marketing" class="setting-article" style="max-width: 1000px">
  <header class="admin-header-1">SEO & Marketing</header>
  <summary class="admin-content-description" style="padding-bottom: 0">
    Boost your Google SEO, share reviews on Social Media, and display Instagram post to amplify your Online Presence.
  </summary>

  <section class="gray-border-wrapper flex-wrapper" style="margin-top: 22px" [routerLink]="'google-seo'">
    <img src="assets/icon/google_seo.svg" />
    <div class="setting-content-detail">
      <header class="setting-content-header">
        <div class="setting-content-header-value">Google SEO</div>
      </header>
      <summary class="setting-content-header-summary">Activate Google Rich Snippets to display star ratings and review counts directly in Google search results.</summary>
    </div>
    <div class="setting-content-go">
      <img [src]="'assets/icon/arrow_plat_right.svg'" />
    </div>
  </section>

  <section class="gray-border-wrapper flex-wrapper" style="margin-top: 22px" [routerLink]="'social-push'">
    <img src="assets/icon/social_push.svg" />
    <div class="setting-content-detail">
      <header class="setting-content-header">
        <div class="setting-content-header-value">Social Push</div>
      </header>
      <summary class="setting-content-header-summary">Boost social proof with 'Social Push': Auto-share positive reviews on Facebook and Twitter.</summary>
    </div>
    <div class="setting-content-go">
      <img [src]="'assets/icon/arrow_plat_right.svg'" />
    </div>
  </section>

  <section class="gray-border-wrapper flex-wrapper" style="margin-top: 22px" [routerLink]="'instagram-curation'">
    <img src="assets/icon/insta_curation.svg" />
    <div class="setting-content-detail">
      <header class="setting-content-header">
        <div class="setting-content-header-value">
          Instagram Curation
          <span class="setting-content-coming">Coming Soon</span>
        </div>
      </header>
      <summary class="setting-content-header-summary">Import Instagram posts to showcase real customer experiences on your storefront.</summary>
    </div>
    <div class="setting-content-go">
      <img [src]="'assets/icon/arrow_plat_right.svg'" />
    </div>
  </section>
</article>
