import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor() { }

  setCookie(
    cookieName: string,
    cookieValue: string,
    days?: number | undefined
  ) {
    let date: Date = new Date();
    let expires: string = 'expires='; // + date.toUTCString();
    if (days) {
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = 'expires=' + date.toUTCString();
    }
    document.cookie = cookieName + '=' + cookieValue + '; ' + expires;
  }

  getCookie(cookieName: string) {
    let name = cookieName + '=';
    let ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  setCookieArray(cookieName: string, cookieArray: Array<any>) {
    let str: string = '';
    for (let key in cookieArray) {
      if (str != '') str += ',';

      str += key + ':' + cookieArray[key];
    }
    this.setCookie(cookieName, str);
  }

  getCookieArray(cookieName: string) {
    let str: string = this.getCookie(cookieName);
    let tmp1: string[] = str.split(',');
    let reData: any = [];
    for (let i in tmp1) {
      let tmp2: number[] = tmp1[i].split(':').map((item) => parseInt(item, 10));
      reData[tmp2[0]] = tmp2[1];
    }
    // ;
    return reData;
  }
}
