import { MatPaginator } from '@angular/material/paginator';

export class PaginationHandler {
  constructor(private _paginator: MatPaginator, private _skip: number, private _limit: number) {
    this.limit = this._limit;

    this.paginator.length = 0;
    this.paginator.pageSize = this.limit;
  }

  set paginator(paginator: MatPaginator) {
    this._paginator = paginator;
  }

  get paginator() {
    return this._paginator;
  }

  set skip(skip: number) {
    this._skip = skip;
  }

  get skip() {
    return this._skip;
  }

  set limit(limit: number) {
    this._limit = limit;
  }

  get limit() {
    return this._limit;
  }

  pagingFunction!: CallableFunction;
}
