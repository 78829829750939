<article id="dialog-admin-account-update-position">
  <header class="admin-header-1">Edit Member</header>
  <section class="account-container">
    <div class="account-profile">
      <img src="assets/icon/profile-default.png" />
    </div>
    <div class="account-info">
      <div class="account-username">
        {{ data.user_name }}
      </div>
      <div class="account-id">
        {{ data.account_id }}
      </div>
    </div>
  </section>

  <section style="margin-bottom: 20px">
    <div class="role-container">
      <div class="role-title">Role</div>
      <section class="select-wrapper role-content">
        <select class="admin-select" [(ngModel)]="data.position" style="width: 100%">
          <option [ngValue]="shopService.ShopAccountPosition.OWNER">Owner</option>
          <option [ngValue]="shopService.ShopAccountPosition.MANAGER">Manager</option>
          <option [ngValue]="shopService.ShopAccountPosition.STAFF">Staff</option>
        </select>
      </section>
    </div>

    <div class="role-container">
      <div class="role-title"></div>
      <section class="select-wrapper role-content">
        <admin-account-role-description [shopAdminAccount]="data"></admin-account-role-description>
      </section>
    </div>
  </section>

  <section class="flex-wrapper">
    <a class="account-delete" (click)="openDialogAccountDelete()"> Delete Account </a>
    <div class="flex-wrapper">
      <button class="btn-gray" style="padding: 12px 22px; height: unset" (click)="onNoClick()">Cancel</button>
      <button class="btn-confirm" (click)="onConfirm()">Save</button>
    </div>
  </section>
</article>
