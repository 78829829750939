<article id="app-guidance">
  <header class="admin-header-1" style="white-space: pre-line">{{ target?.header }}</header>
  <div class="guidance-img" [ngClass]="target!.target">
    <img src="{{ target?.imgSrc }}" />
  </div>
  <div class="guidance-contant">
    <div class="guidance-description" [innerHTML]="target?.description"></div>
    <a *ngIf="target?.target !== 'sales-popup'" href="{{ target?.link }}" target="_blank">Learn More →</a>
  </div>
  <div class="flex-wrapper">
    <div (click)="onCancel()" class="btn-white" style="width: 80px; height: 36px">Cancel</div>
    <div (click)="onStart()" class="btn-black" style="width: 191px">Start with AlphaReview</div>
  </div>
</article>
