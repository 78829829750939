import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { BackendService } from './service/backend.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LandingLayoutModule } from './home/landing-layout/landing-layout.module';
import { HomeModule } from './home/home.module';
import { SuperAdminModule } from './super-admin/super-admin.module';
import { AdminModule } from './admin/admin.module';
import { HomeComponent } from './home/home.component';
import { DialogModule } from './dialog/dialog.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

@NgModule({
  declarations: [AppComponent, HomeComponent],
  providers: [
    BackendService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  imports: [
    AdminModule,
    BrowserModule,
    DialogModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatFormFieldModule,
    HttpClientModule,
    LandingLayoutModule,
    HomeModule,
    ReactiveFormsModule,
    SuperAdminModule,
  ],
})
export class AppModule {}
