import { Component, ElementRef, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { SalesPopupService } from '../sales-popup.service';
import { BillingService } from 'src/app/service/billing.service';
import { SalesPopupConfigCommon } from 'src/app/models/marketing/sales-popup.model';
import { SeparationType } from 'src/app/models/shop/auth/benefit/benefit.model';
import { DialogSettingProductCollectionSelectComponent } from 'src/app/dialog/setting-dialog/dialog-setting-product-collection-select/dialog-setting-product-collection-select.component';
import { inOutAnimation } from 'src/app/admin/animations';
import { isEqual } from 'lodash';
import { GenericService } from 'src/app/service/generic.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-sales-popup-general-settings',
  templateUrl: './sales-popup-general-settings.component.html',
  styleUrls: ['./sales-popup-general-settings.component.less'],
  animations: [inOutAnimation],
})
export class SalesPopupGeneralSettingsComponent implements OnInit {
  @ViewChild('previewPc') private previewPc!: ElementRef;
  @ViewChild('previewMo') private previewMo!: ElementRef;
  constructor(
    private marketingPopupService: SalesPopupService,
    @Optional() @SkipSelf() public billingService: BillingService,
    private genericService: GenericService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  originSalesPopupCommon!: SalesPopupConfigCommon;
  salesPopupCommon!: SalesPopupConfigCommon;

  getSalesPopupCommon() {
    this.marketingPopupService.getPopupConfigCommon().subscribe((response) => {
      this.salesPopupCommon = response.body;
      this.originSalesPopupCommon = { ...this.salesPopupCommon };
    });
  }

  updateSalesPopupCommon(salesPopupCommon: SalesPopupConfigCommon) {
    this.marketingPopupService.updatePopupConfigCommon(salesPopupCommon).subscribe((response) => {
      this.salesPopupCommon = response.body;
      this.originSalesPopupCommon = { ...this.salesPopupCommon };
      this.genericService.openSnackBar('Changes Saved!');
    });
  }

  checkSettingChanges() {
    return isEqual(this.salesPopupCommon, this.originSalesPopupCommon);
  }

  changePosition(el: HTMLInputElement, position: string) {
    if (position === 'left') {
      this.previewPc.nativeElement.style.left = el.value + '%';
      this.salesPopupCommon.web_margin_left = Number(el.value);
    }
    if (position === 'bottomPc') {
      this.previewPc.nativeElement.style.bottom = el.value + '%';
      this.salesPopupCommon.web_margin_bottom = Number(el.value);
    }
    if (position === 'bottomMo') {
      this.previewMo.nativeElement.style.bottom = el.value + '%';
      this.salesPopupCommon.mobile_margin_bottom = Number(el.value);
    }
  }

  openSelectBlocklist(salesPopupCommon: SalesPopupConfigCommon, target: SeparationType) {
    let items: any[] = [];

    if (target === SeparationType.PRODUCT && salesPopupCommon.product_blocklist.length) items = salesPopupCommon.product_blocklist;
    if (target === SeparationType.COLLECTION && salesPopupCommon.collection_blocklist.length) items = salesPopupCommon.collection_blocklist;

    const dialogRef = this.dialog.open(DialogSettingProductCollectionSelectComponent, {
      width: '880px',
      maxHeight: '90vh',
      data: {
        target: target,
        swappable: false,
        items: items,
        salesPopup: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      if (target === SeparationType.PRODUCT) salesPopupCommon.product_blocklist = result.items.map((item: any) => item.platform_id);
      if (target === SeparationType.COLLECTION) salesPopupCommon.collection_blocklist = result.items.map((item: any) => item.platform_id);
    });
  }

  get SeparationType(): typeof SeparationType {
    return SeparationType;
  }
}
