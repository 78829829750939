"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.ContextualSaveBar = exports.update = exports.discard = exports.save = exports.hide = exports.show = exports.Action = void 0;
var ContextualSaveBar_1 = require("@shopify/app-bridge-core/actions/ContextualSaveBar");
Object.defineProperty(exports, "Action", { enumerable: true, get: function () { return ContextualSaveBar_1.Action; } });
Object.defineProperty(exports, "show", { enumerable: true, get: function () { return ContextualSaveBar_1.show; } });
Object.defineProperty(exports, "hide", { enumerable: true, get: function () { return ContextualSaveBar_1.hide; } });
Object.defineProperty(exports, "save", { enumerable: true, get: function () { return ContextualSaveBar_1.save; } });
Object.defineProperty(exports, "discard", { enumerable: true, get: function () { return ContextualSaveBar_1.discard; } });
Object.defineProperty(exports, "update", { enumerable: true, get: function () { return ContextualSaveBar_1.update; } });
Object.defineProperty(exports, "ContextualSaveBar", { enumerable: true, get: function () { return ContextualSaveBar_1.ContextualSaveBar; } });
/**
 * Returns a new instance of a ContextualSaveBar action set
 * @param app the client application
 *
 */
function create(app, options) {
    return new ContextualSaveBar_1.ContextualSaveBar(app, options);
}
exports.create = create;
