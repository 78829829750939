<article id="custom-tooltip" [@inOutAnimation] [ngStyle]="{
    top: top >= 0 ? top + '%' : 'unset',
    right: right >= 0 ? right + '%' : 'unset',
    bottom: bottom >= 0 ? bottom + '%' : 'unset',
    left: left >= 0 ? left + '%' : 'unset',
    'margin-top': marginTop ? marginTop + 'px' : 'unset',
    'margin-bottom': marginBottom ? marginBottom + 'px' : 'unset',
    transform: 'translate(' + translateX + '%, ' + translateY +'%)',
    'background-color': backgroundColor,
    'color': color
}">
    <div class="custom-tooltip-icon-container" [ngClass]="{background: iconBackground}" *ngIf="iconSrc">
        <img [src]="iconSrc">
    </div>
    <div class="custom-tooltip-content" [ngStyle]="{width: contentWidth, 'text-align': textAlign}">
        <ng-content>
        </ng-content>
    </div>
</article>