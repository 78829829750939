import { Component, ElementRef, Input, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { CdkDragDrop, DragDropModule, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AdminService } from 'src/app/service/admin.service';
import { GenericService } from 'src/app/service/generic.service';
import { SalesPopupService } from '../sales-popup.service';
import { BillingService } from 'src/app/service/billing.service';
import { SalesPopupConfig, SalesPopupMetric, SalesPopupMetricType } from 'src/app/models/marketing/sales-popup.model';
import { WidgetInstallationPosition } from 'src/app/models/widget/widget.model';
import { CustomConfirmDialogComponent } from 'src/app/dialog/custom-confirm-dialog/custom-confirm-dialog.component';
import { inOutAnimation } from 'src/app/admin/animations';
import { cloneDeep, isEqual } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-sales-popup-detail',
  templateUrl: './sales-popup-detail.component.html',
  styleUrls: ['./sales-popup-detail.component.less'],
  animations: [inOutAnimation],
})
export class SalesPopupDetailComponent implements OnInit {
  @Input('popupType') popupType!: WidgetInstallationPosition;
  constructor(
    @Optional() @SkipSelf() public billingService: BillingService,
    public marketingPopupService: SalesPopupService,
    public adminService: AdminService,
    private genericService: GenericService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  demoDevice: string = 'PC';
  checkIncludeVars?: boolean;

  displayUpdatePlanTooltip: boolean = false;

  premiumSalesPopupList: SalesPopupMetricType[] = [
    SalesPopupMetricType.RECENT_PURCHASE_SPOTLIGHT,
    SalesPopupMetricType.PRODUCT_PURCHASE_SUMMARY,
    SalesPopupMetricType.PURCHASE_SUMMERY,
    SalesPopupMetricType.STORE_PURCHASE_SUMMARY,
    SalesPopupMetricType.LIVE_VISITORS,
    SalesPopupMetricType.VISITOR_SUMMARY,
  ];

  conditionType = {
    onlyTimeRange: [SalesPopupMetricType.RECENT_PURCHASE_SPOTLIGHT, SalesPopupMetricType.ADD_TO_CARTS_SPOTLIGHT],
    onlyThreshold: [SalesPopupMetricType.LIVE_VISITORS],
    timeAndThreshold: [
      SalesPopupMetricType.PRODUCT_PURCHASE_SUMMARY,
      SalesPopupMetricType.STORE_PURCHASE_SUMMARY,
      SalesPopupMetricType.CART_SUMMARY,
      SalesPopupMetricType.VISITOR_SUMMARY,
      SalesPopupMetricType.PURCHASE_SUMMERY,
    ],
    timeAndRating: [SalesPopupMetricType.RECENT_REVIEW_SPOTLIGHT],
    timeAndThresholdAndRating: [SalesPopupMetricType.PRODUCT_REVIEW_SUMMARY, SalesPopupMetricType.ALL_REVIEW_SUMMARY, SalesPopupMetricType.REVIEW_SUMMARY],
  };

  iconType = {
    product: [
      SalesPopupMetricType.PRODUCT_PURCHASE_SUMMARY,
      SalesPopupMetricType.PURCHASE_SUMMERY,
      SalesPopupMetricType.STORE_PURCHASE_SUMMARY,
      SalesPopupMetricType.RECENT_PURCHASE_SPOTLIGHT,
    ],
    cart: [SalesPopupMetricType.CART_SUMMARY, SalesPopupMetricType.ADD_TO_CARTS_SPOTLIGHT],
    review: [
      SalesPopupMetricType.PRODUCT_REVIEW_SUMMARY,
      SalesPopupMetricType.ALL_REVIEW_SUMMARY,
      SalesPopupMetricType.REVIEW_SUMMARY,
      SalesPopupMetricType.RECENT_REVIEW_SPOTLIGHT,
    ],
    visitor: [SalesPopupMetricType.VISITOR_SUMMARY, SalesPopupMetricType.LIVE_VISITORS],
    viewProduct: [
      SalesPopupMetricType.RECENT_PURCHASE_SPOTLIGHT,
      SalesPopupMetricType.PRODUCT_PURCHASE_SUMMARY,
      SalesPopupMetricType.RECENT_REVIEW_SPOTLIGHT,
      SalesPopupMetricType.PRODUCT_REVIEW_SUMMARY,
    ],
  };

  getSalesPopupConfigDetail(popupType: WidgetInstallationPosition) {
    if (popupType) this.popupType = popupType;
    this.marketingPopupService.dragInduce = false;

    this.marketingPopupService.getPopupConfigDetail(this.popupType).subscribe((response) => {
      this.marketingPopupService.salesPopupConfig = response.body as SalesPopupConfig;
      this.filterMetrics(this.marketingPopupService.salesPopupConfig);
      if (!this.marketingPopupService.liveMetrics.length) this.marketingPopupService.dragInduce = true;
      this.marketingPopupService.originSalesPopupConfig = cloneDeep(this.marketingPopupService.salesPopupConfig);
    });
    this.marketingPopupService.getDeepLinkingUrl();
    this.marketingPopupService.checkCoreScriptActive();
  }

  updateSalesPopupConfigDetail(salesPopupConfig: SalesPopupConfig) {
    salesPopupConfig.sales_popup_metrics = this.marketingPopupService.salesPopupConfig.sales_popup_metrics;
    this.marketingPopupService.updatePopupConfigDetail(this.popupType!, salesPopupConfig).subscribe((response) => {
      this.marketingPopupService.salesPopupConfig = response as SalesPopupConfig;

      this.filterMetrics(this.marketingPopupService.salesPopupConfig);
      this.genericService.openSnackBar('Changes Saved!');
      this.marketingPopupService.originSalesPopupConfig = cloneDeep(this.marketingPopupService.salesPopupConfig);
    });
  }

  filterMetrics(config: SalesPopupConfig) {
    const homeOnlyMetric = [
      SalesPopupMetricType.PRODUCT_PURCHASE_SUMMARY,
      SalesPopupMetricType.STORE_PURCHASE_SUMMARY,
      SalesPopupMetricType.PRODUCT_REVIEW_SUMMARY,
      // cart 메트릭 숨김처리
      SalesPopupMetricType.CART_SUMMARY,
      SalesPopupMetricType.ADD_TO_CARTS_SPOTLIGHT,
    ];

    const productOnlyMetric = [
      SalesPopupMetricType.PURCHASE_SUMMERY,
      SalesPopupMetricType.REVIEW_SUMMARY,
      // cart 메트릭 숨김처리
      SalesPopupMetricType.ADD_TO_CARTS_SPOTLIGHT,
      SalesPopupMetricType.CART_SUMMARY,
    ];

    if (this.popupType === WidgetInstallationPosition.MAIN_PAGE)
      this.marketingPopupService.salesPopupConfig.sales_popup_metrics = config.sales_popup_metrics.filter((item) => !productOnlyMetric.includes(item.type));
    if (this.popupType === WidgetInstallationPosition.PRODUCT_DETAIL_PAGE)
      this.marketingPopupService.salesPopupConfig.sales_popup_metrics = config.sales_popup_metrics.filter((item) => !homeOnlyMetric.includes(item.type));

    !this.marketingPopupService.salesPopupConfig.is_active && config.sales_popup_metrics.forEach((metric) => (metric.is_active = false));

    this.marketingPopupService.liveMetrics = config.sales_popup_metrics.filter((item) => item.is_active);
    this.marketingPopupService.deActivatedMetrics = config.sales_popup_metrics.filter((item) => !item.is_active);
    this.marketingPopupService.selectedMetric = this.marketingPopupService.liveMetrics.length ? this.marketingPopupService.liveMetrics[0] : config.sales_popup_metrics[0];
  }

  checkSettingChanges() {
    return isEqual(this.marketingPopupService.salesPopupConfig, this.marketingPopupService.originSalesPopupConfig);
  }

  onSelectMetricChange(e: any, metric: SalesPopupMetric, target: string) {
    this.marketingPopupService.salesPopupConfig.sales_popup_metrics.find((item) => {
      if (item.id !== metric.id) return;
      if (target === 'content') return (item.content = e);
      if (target === 'time_range') return (item.condition.time_range = e);
      if (target === 'threshold') return (item.condition.threshold = e);
      if (target === 'rating') return (item.condition.rating = e);
      if (target === 'is_active') {
        this.metricActiveChange(e, metric, item);
        return (item.is_active = e);
      }
    });
    this.marketingPopupService.liveMetrics.length ? (this.marketingPopupService.dragInduce = false) : (this.marketingPopupService.dragInduce = true);
  }

  metricActiveChange(e: any, metric: SalesPopupMetric, item: SalesPopupMetric) {
    if (e) {
      this.marketingPopupService.liveMetrics.push(metric);
      this.marketingPopupService.deActivatedMetrics = this.marketingPopupService.salesPopupConfig.sales_popup_metrics.filter(
        (deactivated) => !this.marketingPopupService.liveMetrics.includes(deactivated),
      );
      this.marketingPopupService.salesPopupConfig.sales_popup_metrics = this.marketingPopupService.liveMetrics.concat(this.marketingPopupService.deActivatedMetrics);
    }
    if (!e) {
      let changeItem = this.marketingPopupService.liveMetrics.splice(this.marketingPopupService.liveMetrics.indexOf(item), 1);
      this.marketingPopupService.deActivatedMetrics.push(changeItem[0]);
    }
  }

  dragAndDrop(e: CdkDragDrop<SalesPopupMetric[]>) {
    if (e.previousContainer === e.container) moveItemInArray(e.container.data, e.previousIndex, e.currentIndex);
    else {
      transferArrayItem(e.previousContainer.data, e.container.data, e.previousIndex, e.currentIndex);

      const firstDragTrigger = e.container.data.length == 1 && !this.marketingPopupService.selectedMetric.is_active;
      firstDragTrigger && this.togglePopupActive(this.marketingPopupService.salesPopupConfig, this.marketingPopupService.selectedMetric);

      this.marketingPopupService.liveMetrics.forEach((live) => (live.is_active = true));
      this.marketingPopupService.deActivatedMetrics.forEach((deactivated) => (deactivated.is_active = false));
    }
    this.marketingPopupService.salesPopupConfig.sales_popup_metrics = this.marketingPopupService.liveMetrics.concat(this.marketingPopupService.deActivatedMetrics);
    this.marketingPopupService.liveMetrics.length ? (this.marketingPopupService.dragInduce = false) : (this.marketingPopupService.dragInduce = true);
  }

  setTemplateVars(metric: SalesPopupMetric): string[] {
    let templateVars: string[];
    let includesVars: string[] = [];

    switch (metric.type) {
      case SalesPopupMetricType.RECENT_PURCHASE_SPOTLIGHT:
      case SalesPopupMetricType.ADD_TO_CARTS_SPOTLIGHT:
      case SalesPopupMetricType.RECENT_REVIEW_SPOTLIGHT:
        templateVars = ['first_name', 'city', 'province', 'country', 'product_title'];
        break;
      case SalesPopupMetricType.PRODUCT_PURCHASE_SUMMARY:
      case SalesPopupMetricType.PURCHASE_SUMMERY:
        templateVars = ['order_count', 'time_range', 'product_title'];
        break;
      case SalesPopupMetricType.STORE_PURCHASE_SUMMARY:
        templateVars = ['order_count', 'time_range'];
        break;
      case SalesPopupMetricType.CART_SUMMARY:
        templateVars = ['cart_count', 'time_range', 'product_title'];
        break;
      case SalesPopupMetricType.PRODUCT_REVIEW_SUMMARY:
        templateVars = ['satisfied_count', 'time_range', 'product_title'];
        break;
      case SalesPopupMetricType.ALL_REVIEW_SUMMARY:
        templateVars = ['satisfied_count', 'time_range'];
        break;
      case SalesPopupMetricType.LIVE_VISITORS:
        templateVars = ['live_visitor_count'];
        break;
      case SalesPopupMetricType.VISITOR_SUMMARY:
        templateVars = ['visit_count', 'time_range'];
        break;
      case SalesPopupMetricType.REVIEW_SUMMARY:
        templateVars = ['satisfied_count', 'time_range', 'product_title'];
        break;
      default:
        templateVars = [];
    }

    templateVars.forEach((item) => includesVars.push(`{${item}}`));
    this.checkIncludeVars = includesVars.some((item) => metric.content.includes(item));

    return templateVars;
  }

  togglePopupActive(config: SalesPopupConfig, selectedMetric?: SalesPopupMetric) {
    if (config?.is_active && !selectedMetric) {
      let configMetrics = config.sales_popup_metrics;

      this.billingService.shopBilling?.plan == this.billingService.ShopBillingPlan.FREE
        ? (configMetrics = config.sales_popup_metrics.filter((metric) => !this.premiumSalesPopupList.includes(metric.type)))
        : (configMetrics = config.sales_popup_metrics);

      configMetrics.forEach((metric) => (metric.is_active = true));
      this.marketingPopupService.liveMetrics = configMetrics.filter((metric) => metric.is_active);

      this.billingService.shopBilling?.plan == this.billingService.ShopBillingPlan.FREE
        ? configMetrics.filter((metric) => !metric.is_active)
        : (this.marketingPopupService.deActivatedMetrics = []);

      this.marketingPopupService.dragInduce = false;
    }

    if (!config?.is_active) {
      let dialogRef = this.dialog.open(CustomConfirmDialogComponent, { width: '480px' });
      let instance = dialogRef.componentInstance;

      let popupType = this.popupType === WidgetInstallationPosition.MAIN_PAGE ? 'Homepage' : 'Product page';

      if (!selectedMetric) {
        instance.header = 'Are you sure?';
        instance.content = `If you deactivate ${popupType} Popup, it will stop the popups from appearing on your ${popupType.toLowerCase()}.`;
        instance.confirm = 'Deactivate';
      }
      if (selectedMetric) {
        instance.header = 'Activate Sales Popup';
        instance.content = `To utilize this feature, activate the ${popupType} Popup first.`;
        instance.confirm = 'Activate';
      }

      dialogRef.afterClosed().subscribe((result) => {
        if (!selectedMetric && result) {
          config?.sales_popup_metrics.forEach((metric) => (metric.is_active = false));
          this.marketingPopupService.deActivatedMetrics = config.sales_popup_metrics.filter((metric) => !metric.is_active);
          this.marketingPopupService.liveMetrics = [];
          this.marketingPopupService.dragInduce = true;
        }
        if (!selectedMetric && !result) config.is_active = true;
        if (selectedMetric && !result) {
          this.marketingPopupService.salesPopupConfig = this.marketingPopupService.originSalesPopupConfig;
          this.getSalesPopupConfigDetail(this.popupType);
        }
        if (selectedMetric && result) {
          selectedMetric.is_active = true;
          config.is_active = true;
        }
      });
    }
  }

  previewVariable(selectedMetric: SalesPopupMetric) {
    let varsValue: string;
    let timeRange = this.marketingPopupService.selectedMetric.condition.time_range;

    if (typeof timeRange === 'string') {
      timeRange = timeRange
        .replace(/last_day/g, 'last 1 day')
        .replace(/last_week/g, 'last 1 week')
        .replace(/last_month/g, 'last 1 month')
        .replace(/last_3_month/g, 'last 3 month')
        .replace(/last_6_month/g, 'last 6 month')
        .replace(/last_year/g, 'last 1 year')
        .replace(/entire_period/g, 'entire period');
    }

    varsValue = selectedMetric.content
      .replace(/{first_name}/g, `<span style="color:${this.marketingPopupService.pointColor}; font-weight:bold">James</span>`)
      .replace(/{province}|{city}/g, `<span style="color:${this.marketingPopupService.pointColor}; font-weight:bold">New York</span>`)
      .replace(/{country}/g, `<span style="color:${this.marketingPopupService.pointColor}; font-weight:bold">US</span>`)
      .replace(/{time_range}/g, `<span style="color:${this.marketingPopupService.pointColor}; font-weight:bold">${timeRange}</span>`)
      .replace(/{product_title}/g, `<span style="color:${this.marketingPopupService.pointColor}; font-weight:bold">Long Sleeve T-Shirt</span>`)
      .replace(
        /{satisfied_count}|{live_visitor_count}|{visit_count}|{cart_count}|{order_count}/g,
        `<span style="color:${this.marketingPopupService.pointColor}; font-weight:bold">1754</span>`,
      );

    return varsValue;
  }
}
