import { AfterViewInit, Component, OnChanges, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { BackendService } from 'src/app/service/backend.service';
import { SuperAdminShops } from 'src/app/models/super-admin/super-admin.shops.model';
import { Router } from '@angular/router';
import { DetailComponent } from '../detail/super-admin-shops-detail.component';
import { ShopAdminAccount } from 'src/app/models/shop/shop.model';
import { SuperAdminService } from 'src/app/service/super-admin.service';
import { AppStatusType } from 'src/app/models/super-admin/super-admin.shops.enums';
import { AdminService } from 'src/app/service/admin.service';
import { ShopService } from 'src/app/service/shop.service';
import { AuthAdminService } from 'src/app/auth/auth-admin.service';
import { AuthSuperAdminService } from 'src/app/auth/auth-super-admin.service';
import { PaginationHandler } from 'src/app/shared/handler/pagination-handler';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-list',
  templateUrl: './super-admin-shops-list.component.html',
  styleUrls: ['./super-admin-shops-list.component.less'],
})
export class ListComponent implements OnInit, AfterViewInit, OnChanges {
  constructor(
    private backendservice: BackendService,
    @Optional() @SkipSelf() public superAdminService: SuperAdminService,
    @Optional() @SkipSelf() private shopService: ShopService,
    @Optional() @SkipSelf() private authService: AuthAdminService,
    @Optional() @SkipSelf() private authSuperAdminService: AuthSuperAdminService,
    @Optional() @SkipSelf() private adminService: AdminService,
    private router: Router,
    public dialog: MatDialog,
  ) {}

  shops: MatTableDataSource<SuperAdminShops> = new MatTableDataSource();
  rawData!: SuperAdminShops[];
  displayedColumns: string[] = ['shop_name', 'shop_url', 'service_status', 'shop_initial_info', 'shop_detail', 'id', 'setup'];

  count_status_1!: number;
  count_status_2!: number;
  count_status_3!: number;

  statusFilter: number[] = [this.superAdminService.AppStatusType.WAIT, this.superAdminService.AppStatusType.PROCEED];

  searchKeyword: string = '';

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  paginatorHandler!: PaginationHandler;
  ngOnChanges() {
    this.shops.paginator = this.paginator;
  }
  ngAfterViewInit(): void {
    this.getShops();
  }
  ngOnInit(): void {
    this.getShopStatusCounter();
  }
  firstPage() {
    this.paginator.pageIndex = 0;
  }
  shopWait!: number;
  shopProceed!: number;
  shopDelete!: number;

  getShopStatusCounter() {
    let url = 'super-admin/shops/counter';
    this.backendservice.select(url).subscribe((response) => {
      this.shopWait = response.body[AppStatusType.WAIT];
      this.shopProceed = response.body[AppStatusType.PROCEED];
    });
  }
  changeStatusFilter(status: number[]) {
    this.paginator.pageIndex = 0;
    this.statusFilter = status;
    this.getShops();
  }

  toShop(shop_url: string) {
    window.open(`https://${shop_url}`, '_blank');
  }
  toDashboard(shop_id: number) {
    this.authSuperAdminService.shopDashboardBridge(shop_id).subscribe((response) => {
      if (response.status == 200) {
        const shopAdminAccount = response.body as ShopAdminAccount;
        shopAdminAccount.shops = Array.from(new Set([...shopAdminAccount.alpha_plus_shops, ...shopAdminAccount.browse_booster_shops])).filter(
          (shop, idx, self) => idx === self.findIndex((t) => t.platform_id === shop.platform_id),
        );

        let shop = shopAdminAccount.shops.find((shop) => shop.access_code == this.adminService.getShopAccessCode());
        this.adminService.setAdminAccountSession(shopAdminAccount);
        this.shopService.currency = shopAdminAccount.shop!.currency as string;

        const url = this.router.serializeUrl(this.router.createUrlTree([`/admin/login/bridge/${shopAdminAccount.access_token}`]));
        window.open(url, '_blank');
      }
    });
  }
  getShops() {
    const url = 'super-admin/shops';
    this.backendservice
      .select(url, {
        service_status: this.statusFilter,
        search_keyword: this.searchKeyword,
        skip: this.paginator.pageIndex * this.paginator.pageSize,
        limit: this.paginator.pageSize,
      })
      .subscribe({
        next: (response) => {
          this.rawData = response.body.shops as SuperAdminShops[];
          this.preprocessing();
          this.shops.data = this.rawData;

          this.paginator.length = response.body.length;
        },
        error: (error) => {
          if (error.status == 403) {
            this.router.navigate(['super-admin', 'login']);
          }
        },
      });
  }

  preprocessing() {
    for (let data of this.rawData) {
      if (data.shop_initial_info) {
        data.onboarding = this.countSetup(data.shop_initial_info);
        data.setup = this.countOnboarding(data.shop_initial_info);
      } else {
        data.onboarding = 0;
        data.setup = 0;
      }
    }
  }

  countSetup(shop_initial_info: any) {
    return [shop_initial_info.process_onboarding_1, shop_initial_info.process_onboarding_2, shop_initial_info.process_onboarding_3, shop_initial_info.process_onboarding_4].filter(
      Boolean,
    ).length;
  }

  countOnboarding(shop_initial_info: any) {
    return [
      shop_initial_info.process_setup_1,
      shop_initial_info.process_setup_2,
      shop_initial_info.process_setup_3,
      shop_initial_info.process_setup_4,
      shop_initial_info.process_setup_5,
      shop_initial_info.process_setup_6,
    ].filter(Boolean).length;
  }

  openDetail(shop_id: number) {
    const dialogRef = this.dialog.open(DetailComponent, {
      height: '95vh',
      width: '95vw',
      data: { shop_id: shop_id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getShops();
    });
  }
}
