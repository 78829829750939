"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Returns a deeply merged object with properties from the 2 provided objects
 * @remarks - Only deeply merge objects. The second argument overrides the
 * first if it is provided as `null`, `undefined` or an array.
 * @public
 * */
function mergeProps(obj, newObj) {
    if (newObj == null) {
        return newObj;
    }
    // If setting to a different prototype or a non-object or non-array, don't merge any props
    if (typeof obj === 'undefined' ||
        !Object.prototype.isPrototypeOf.call(Object.getPrototypeOf(obj), newObj) ||
        (newObj.constructor.name !== 'Object' && newObj.constructor.name !== 'Array')) {
        return newObj;
    }
    var clone = {};
    Object.keys(newObj).forEach(function (key) {
        var exists = Object.prototype.hasOwnProperty.call(obj, key);
        if (!exists) {
            clone[key] = newObj[key];
        }
        else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
            clone[key] = mergeProps(obj[key], newObj[key]);
        }
        else {
            clone[key] = newObj[key];
        }
    });
    // Copy old props that are not present in new object only if this is a simple object
    Object.keys(obj).forEach(function (key) {
        var exists = Object.prototype.hasOwnProperty.call(newObj, key);
        if (!exists) {
            clone[key] = obj[key];
        }
    });
    // Set prototype of cloned object to match original
    Object.setPrototypeOf(clone, Object.getPrototypeOf(obj));
    return clone;
}
exports.default = mergeProps;
