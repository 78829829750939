<article id="subscription-setting" style="padding-bottom: 20px">
  <section *ngIf="billingService.shopBilling" class="shop-billing-plan-box gray-border-wrapper" style="margin-top: 24px">
    <section class="toggle-select-box-container">
      <div
        class="toggle-select-box"
        [ngClass]="{ active: billingService.BillingInterval.MONTHLY == billingService.shopBilling.interval }"
        (click)="billingService.shopBilling.interval = billingService.BillingInterval.MONTHLY"
      >
        Per Monthly
      </div>
      <div
        class="toggle-select-box"
        [ngClass]="{ active: billingService.BillingInterval.YEARLY == billingService.shopBilling.interval }"
        (click)="billingService.shopBilling.interval = billingService.BillingInterval.YEARLY"
      >
        Per Yearly

        <div class="sale-tooltip">
          <img src="assets/icon/fire_icon.png" />
          <ng-container *ngIf="billingService.shopBilling.interval == billingService.BillingInterval.MONTHLY; else yearly">
            Switch plan to yearly <br />
            and SAVE 40%!
          </ng-container>
          <ng-template #yearly>SAVE 40%!</ng-template>
        </div>
      </div>
    </section>

    <section style="display: flex; flex-direction: column">
      <section class="shop-billing-plan-container">
        <div style="padding: 28px 30px">
          <section class="shop-billing-plan-header">
            <header class="shop-billing-plan-header-title premium">Premium</header>
            <div class="round-badge premium">
              <span
                *ngIf="billingService.shopBilling?.plan == billingService.ShopBillingPlan.PREMIUM"
                [ngStyle]="{ cursor: billingService.shopBilling.plan != billingService.ShopBillingPlan.PREMIUM ? 'pointer' : 'default' }"
              >
                Your Current Plan
              </span>
              <span *ngIf="billingService.shopBilling?.plan == billingService.ShopBillingPlan.FREE"> Most Popular </span>
            </div>
          </section>
          <section>
            <span class="shop-billing-plan-price" *ngIf="billingService.BillingInterval.YEARLY == billingService.shopBilling.interval; else priceInterval">
              {{ billingService.PREMIUM_PLAN_PRICE * (1 - billingService.YEARLY_PLAN_DISCOUNT) | currency }}</span
            >
            <ng-template #priceInterval>
              <span class="shop-billing-plan-price">{{ 9.9 | currency }}</span>
            </ng-template>
            <span class="shop-billing-plan-period"> /mo</span>
          </section>

          <section class="shop-billing-plan-description-container">
            <section class="shop-billing-plan-description">
              <div class="shop-billing-plan-description-marker"></div>
              <div class="shop-billing-plan-description-content">Everything in Free</div>
            </section>
            <section class="shop-billing-plan-description">
              <div class="shop-billing-plan-description-marker"></div>
              <div class="shop-billing-plan-description-content">Unlimited Aliexpress Review Import</div>
            </section>
            <section class="shop-billing-plan-description">
              <div class="shop-billing-plan-description-marker"></div>
              <div class="shop-billing-plan-description-content">Unlimited Amazon Review Import</div>
            </section>
            <section class="shop-billing-plan-description">
              <div class="shop-billing-plan-description-marker"></div>
              <div class="shop-billing-plan-description-content">Unlimited Media Reminder</div>
            </section>
            <section class="shop-billing-plan-description">
              <div class="shop-billing-plan-description-marker"></div>
              <div class="shop-billing-plan-description-content">Widget · Review Form Localization</div>
            </section>
            <section class="shop-billing-plan-description">
              <div class="shop-billing-plan-description-marker"></div>
              <div class="shop-billing-plan-description-content">Display Unlimited Sales Popup</div>
            </section>
            <section class="shop-billing-plan-description">
              <div class="shop-billing-plan-description-marker"></div>
              <div class="shop-billing-plan-description-content">Marketing Tools <span class="round-badge orange"> Coming Soon </span></div>
            </section>
          </section>

          <section
            class="shop-billing-plan-start-premium-btn"
            *ngIf="
              billingService.shopBilling?.plan == billingService.ShopBillingPlan.FREE ||
              (billingService.shopBilling?.plan == billingService.ShopBillingPlan.PREMIUM &&
                billingService.shopBilling?.interval == billingService.BillingInterval.YEARLY &&
                billingService.originShopBilling?.interval != billingService.BillingInterval.YEARLY)
            "
            (click)="upgradeBillingPlan(billingService.ShopBillingPlan.PREMIUM, billingService.shopBilling.interval)"
          >
            Start 30 Days FREE Trial
          </section>

          <section class="shop-billing-plan-trial-description" *ngIf="billingService.shopBilling?.plan != billingService.ShopBillingPlan.PREMIUM">
            Free trial offered for first-time Premium users only
          </section>
        </div>
      </section>
    </section>
    <section class="shop-billing-plan-container">
      <section class="shop-billing-plan-header" (click)="openSettingSubscriptionDowngradeDialog()">
        <header class="shop-billing-plan-header-title">Free</header>
        <div class="round-badge" [ngStyle]="{ cursor: billingService.shopBilling.plan != billingService.ShopBillingPlan.FREE ? 'pointer' : 'default' }">
          <span *ngIf="billingService.shopBilling?.plan != billingService.ShopBillingPlan.FREE; else currentPlan"> Downgrade </span>
          <ng-template #currentPlan> Your Current Plan </ng-template>
        </div>
      </section>
    </section>
    <section class="shop-billing-plan-view-more" (click)="openSettingSubscriptionSummaryDialog()">View More</section>
  </section>

  <section class="shop-billing-plan-box gray-border-wrapper" style="margin-top: 20px">
    <header class="admin-header-3">
      <span *ngIf="billingService.shopBilling?.plan == billingService.ShopBillingPlan.FREE; else premiumHeader"> Upgrade to Premium for Enhanced Benefits </span>
      <ng-template #premiumHeader> These amazing features are all yours to keep! </ng-template>
    </header>
    <summary class="admin-content-description">
      <span *ngIf="billingService.shopBilling?.plan == billingService.ShopBillingPlan.FREE; else premiumDescription"> Keep Free Features & Unlock More! </span>
      <ng-template #premiumDescription> Stay premium to enjoy these exclusive benefits. </ng-template>
    </summary>
    <section style="display: flex; margin-top: 20px">
      <section class="shop-billing-benefit-container" style="margin-right: 5px">
        <div class="shop-billing-benefit-content">
          <header class="admin-header-3">Aliexpress Review Import</header>
          <summary class="admin-content-description">
            Enjoy unlimited AliExpress review imports with our premium plan, perfect for fully establishing credibility across your entire product range.
          </summary>
        </div>
        <div class="shop-billing-benefit-content">
          <header class="admin-header-3">Sales Popup</header>
          <summary class="admin-content-description">
            Unlock the full potential of Sales Popup in our premium plan—get unrestricted access to all metrics and advanced customization options like product or collection
            blocklists.
          </summary>
        </div>
        <div class="shop-billing-benefit-content">
          <header class="admin-header-3">Media Reminders for Reviews</header>
          <summary class="admin-content-description">Automatically prompt customers to add photos or videos to their reviews, enriching your social proof.</summary>
        </div>
        <div class="shop-billing-benefit-content">
          <header class="admin-header-3">Review Forms Customization</header>
          <summary class="admin-content-description">Tailor the text elements of your review submission page to better resonate with your audience.</summary>
        </div>
        <div class="shop-billing-benefit-content">
          <div class="round-badge orange">Coming Soon</div>
          <header class="admin-header-3">Instagram Curation</header>
          <summary class="admin-content-description">Curate and showcase customer-generated content from Instagram, offering social proof and driving engagement.</summary>
        </div>
      </section>
      <section class="shop-billing-benefit-container">
        <div class="shop-billing-benefit-content" style="margin-left: 5px">
          <header class="admin-header-3">Amazon Review Import</header>
          <summary class="admin-content-description">
            Go beyond the limitations of free plans with unlimited Amazon review imports, enriching all your product pages with trusted testimonials.
          </summary>
        </div>
        <div class="shop-billing-benefit-content" style="margin-left: 5px">
          <header class="admin-header-3">Review Groups</header>
          <summary class="admin-content-description">Cluster similar products together to share reviews, boosting social proof across your catalog.</summary>
        </div>
        <div class="shop-billing-benefit-content" style="margin-left: 5px">
          <header class="admin-header-3">Long-term Review</header>
          <summary class="admin-content-description">Collect reviews over an extended period to capture long-term customer satisfaction and product durability.</summary>
        </div>
        <div class="shop-billing-benefit-content" style="margin-left: 5px">
          <header class="admin-header-3">Language Localization</header>
          <summary class="admin-content-description">
            Customize every text element in the widget to match your local language, enhancing the user experience for your diverse customer base.
          </summary>
        </div>
        <div class="shop-billing-benefit-content" style="margin-left: 5px">
          <div class="round-badge orange">Coming Soon</div>
          <header class="admin-header-3">Social Push (Facebook, Twitter)</header>
          <summary class="admin-content-description">Automatically post your best reviews to social media, increasing visibility and drawing in a larger audience.</summary>
        </div>
      </section>
    </section>
  </section>

  <section
    class="shop-billing-plan-box flex-wrapper ready-unlock-container"
    style="margin-top: 20px; border: unset"
    *ngIf="billingService.shopBilling?.plan != billingService.ShopBillingPlan.PREMIUM"
    (click)="upgradeBillingPlan(billingService.ShopBillingPlan.PREMIUM, billingService.shopBilling!.interval)"
  >
    <div class="ready-unlock">Ready to Unlock All Premium Features?</div>

    <button class="btn-confirm orange" style="width: fit-content; font-weight: bold">Upgrade Now!</button>
  </section>
</article>
