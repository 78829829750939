<mat-drawer-container class="lnb-container">
    <mat-drawer #drawer class="nav" mode="side" opened>
        <div>
            <img class="side-bar-logo" src="assets/image/super-admin/side-bar-alphalogo.png">
        </div>
        <div>
            <mat-icon (click)="toShops()" class="side-bar-icon">home</mat-icon>
        </div>
        <div>
            <mat-icon (click)="toPosts()" class="side-bar-icon">list</mat-icon>
        </div>
        <div>
            <img mat-button [matMenuTriggerFor]="menu" class="account-icon"
                src="assets/image/super-admin/account-icon.svg">
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="logout()">퇴근</button>
                <button mat-menu-item (click)="toAccounts()">계정 관리</button>
            </mat-menu>
        </div>
    </mat-drawer>
</mat-drawer-container>