export const INDUSTRY: string[] = [
  "Electronics",
  "Animals & Pets",
  "Art & Entertainment",
  "Baby & Toddler",
  "Hardward & Building Supplies",
  "Health & Beauty",
  "Home, Garder & Furniture",
  "Mature",
  "Office Supplies",
  "Sports & Outdoors",
  "Automotive",
  "Toys & Games",
  "Clothing, Fashion & Accessories",
  "Flowers & Gifts",
  "Food & Drinks",
  "Jewelry & Watches",
  "Services & Virtual Services",
  "Tobacco Products",
  "Travel & Leisure",
  "Other",
]
