<article id="sales-popup" class="setting-article" style="max-width: 1240px; padding-bottom: 20px">
  <header class="admin-header-1" style="gap: 10px">
    Sales Popup
    <div class="flex-wrapper" *ngIf="tabGroup.selectedIndex !== TabSalesPopup.SETTING" [@inOutAnimation]>
      <a
        *ngIf="tabGroup.selectedIndex == TabSalesPopup.HOMEPAGE; else productPage"
        class="btn-anchor"
        href="https://alph.oopy.io/42c929d0-dc21-448d-b2d9-a78846c41794"
        target="_blank"
        >Help</a
      >
      <ng-template #productPage>
        <a class="btn-anchor" href="https://alph.oopy.io/bc3d76a9-2cd4-4646-b275-26f4edd39940" target="_blank">Help</a>
      </ng-template>
    </div>
  </header>
  <summary class="admin-content-description">
    Setting up popups is all about boosting both conversions and trust. Popups will showcase the popularity and demand for your store and products.
  </summary>

  <mat-tab-group #tabGroup [selectedIndex]="genericService.changeSelectedIndex()" (selectedIndexChange)="onDiscard()" class="admin-tab">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="homepge-popup" (click)="genericService.LNBCategory = 'Homepage'">
          <div>Homepage Popup</div>
        </div>
      </ng-template>
      <app-sales-popup-detail [popupType]="WidgetInstallationPosition.MAIN_PAGE"></app-sales-popup-detail>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div class="product-page-popup" (click)="genericService.LNBCategory = 'Product Page'">
          <div>Product Page Popup</div>
        </div>
      </ng-template>
      <app-sales-popup-detail [popupType]="WidgetInstallationPosition.PRODUCT_DETAIL_PAGE"></app-sales-popup-detail>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div class="general-settings" (click)="genericService.LNBCategory = 'General Settings'">
          <div>General Settings</div>
        </div>
      </ng-template>
      <app-sales-popup-general-settings></app-sales-popup-general-settings>
    </mat-tab>
  </mat-tab-group>

  <alpha-save-bar
    *ngIf="popupDetail"
    (save)="onSave()"
    (discard)="onDiscard()"
    [show]="!popupDetail.checkSettingChanges() || !generalSettings.checkSettingChanges()"
    [maxWidth]="'1200px'"
  ></alpha-save-bar>
</article>
