<article id="setting-email-custom" *ngIf="messagingEmailTemplate" style="padding-bottom: 20px">
  <header class="admin-content-gnb">
    <div class="admin-content-gnb-btn-back" (click)="back()">
      <img [src]="'assets/icon/arrow_plat_left.svg'" />
    </div>
    <div class="admin-content-gnb-title">
      <div style="display: flex; align-items: center">
        <div *ngIf="!updateTemplateName">
          {{ messagingEmailTemplate.template_title }}
        </div>
        <input *ngIf="updateTemplateName" [(ngModel)]="messagingEmailTemplate.template_title" (keydown.enter)="updateTemplateName = false" />
        <!-- <input> -->
        <div style="display: flex; align-items: center; cursor: pointer" (click)="updateTemplateName = !updateTemplateName">
          <img [src]="'assets/icon/edit_deep_gray.svg'" style="margin-left: 5px" />
        </div>
      </div>
    </div>
    <div class="admin-content-gnb-content">
      <div class="content-author">
        <div>{{ adminService.ShopAdminAccount.user_name }}</div>
        <img [src]="'assets/icon/info_deep_gray.svg'" style="margin-left: 5px" />
      </div>
    </div>
    <div class="flex-wrapper setting-email-btn-wrapper">
      <div class="btn-black" (click)="saveMessagingEmailTemplate()">Save</div>
      <div class="btn-white" (click)="saveSelectMessagingEmailTemplate()">Save & Select</div>
    </div>
  </header>
  <section class="setting-email-preview preview" style="padding-top: 20px">
    <div>
      <section class="setting-email-content-wrapper" style="width: 1000px">
        <section style="margin-bottom: 10px">
          <header class="email-custom-header-3" style="max-width: 100px; margin-bottom: 0">Subject</header>
          <alpha-textarea [minimumDisplay]="true" [(sourceString)]="messagingEmailTemplate.subject" placeholder="Thank you for leaving a review!" [personalVars]="templateVars">
          </alpha-textarea>
        </section>
        <section>
          <header class="email-custom-header-3" style="max-width: 100px; margin-bottom: 0">Preview Text</header>
          <alpha-textarea
            [minimumDisplay]="true"
            [(sourceString)]="messagingEmailTemplate.preview_text"
            placeholder="The comments are written on the reviews that you wrote! Check the comments."
            [personalVars]="templateVars"
          >
          </alpha-textarea>
        </section>
      </section>
    </div>
  </section>

  <section class="setting-email-preview">
    <header class="email-custom-header-3 flex-wrapper" style="width: 1000px; padding-bottom: 0; margin-bottom: 12px">
      <!-- Compose Email -->
      <div>
        Compose Email
        <span class="email-custom-header-summary">Email content</span>
      </div>
      <section class="device-toggle-container">
        <div class="device-toggle" [ngClass]="{ active: demoDevice == 'PC' }" (click)="demoDevice = 'PC'">PC</div>
        <div class="device-toggle" [ngClass]="{ active: demoDevice == 'MO' }" (click)="demoDevice = 'MO'">Mobile</div>
      </section>
    </header>

    <section class="setting-email-custom">
      <div class="setting-email-container" style="width: 460px">
        <section class="setting-email-content-wrapper" style="margin-top: 0" *ngIf="messagingSettingType == settingMessagingService.messagingSettingType.REVIEW_REQUEST_SETTING">
          <ng-container>
            <div class="email-custom-content" style="margin-bottom: 0">
              <header class="email-custom-header-4">
                <!-- Font -->
                Content Design theme
              </header>
              <div class="email-custom-value">
                <select [(ngModel)]="messagingEmailTemplate.design_theme" (ngModelChange)="onChange()">
                  <option *ngFor="let designTheme of designThemeList" [value]="designTheme.value">
                    {{ designTheme.key }}
                  </option>
                </select>
              </div>
            </div>
          </ng-container>
        </section>
        <section class="setting-email-content-wrapper">
          <header class="email-custom-header-3" style="margin-bottom: 20px; max-width: fit-content">
            <!-- Content Design Components -->
            Content Design Components
          </header>

          <div class="email-custom-content split-bottom">
            <header class="email-custom-header-4">
              <!-- Font -->
              Font
            </header>
            <div class="email-custom-value">
              <select [(ngModel)]="messagingEmailTemplate.font" (ngModelChange)="onChange()">
                <option *ngFor="let font of fontList" [value]="font.key">
                  {{ font.value }}
                </option>
              </select>
            </div>
          </div>

          <div class="split-bottom">
            <div class="email-custom-content">
              <header class="email-custom-header-4">
                <!-- Main Color -->
                Main Color
              </header>
              <div class="email-custom-value">
                <input type="color" (blur)="onChange()" class="email-custom-color-pallet" [(ngModel)]="messagingEmailTemplate.main_color" />
              </div>
            </div>

            <div class="email-custom-content">
              <header class="email-custom-header-4">
                <!-- Secondary Color -->
                Secondary Color
              </header>
              <div class="email-custom-value">
                <input type="color" (blur)="onChange()" class="email-custom-color-pallet" [(ngModel)]="messagingEmailTemplate.secondary_color" />
              </div>
            </div>
          </div>

          <div style="margin-bottom: 20px">
            <header class="email-custom-header-3">
              <!-- Content Subject -->
              Content Subject
            </header>
            <alpha-textarea
              [height]="'120px'"
              (sourceStringChange)="onChange()"
              [(sourceString)]="messagingEmailTemplate.content_subject"
              [placeholder]="'Write A Review'"
              [maxStringLength]="80"
              [personalVars]="templateVars"
            >
            </alpha-textarea>
          </div>

          <div style="margin-bottom: 20px">
            <header class="email-custom-header-3">
              <!-- Content -->
              Content message
            </header>
            <alpha-textarea
              [height]="'140px'"
              (sourceStringChange)="onChange()"
              [(sourceString)]="messagingEmailTemplate.content"
              [placeholder]="
                'Hi [name]!  Please take a moment to review your most recent items. We\'d love to hear your feedback on your recent order.  Please share your experience in a review to help other customers just like you. Many thanks, [company name]'
              "
              [maxStringLength]="300"
              [personalVars]="templateVars"
            ></alpha-textarea>
          </div>

          <div *ngIf="messagingSettingTypeDetail >= settingMessagingService.messagingSettingTypeDetail.VERIFICATION_EMAIL">
            <header class="email-custom-header-3">
              <!-- Content -->
              Note
            </header>
            <alpha-textarea
              [height]="'140px'"
              (sourceStringChange)="onChange()"
              [(sourceString)]="messagingEmailTemplate.note"
              [placeholder]="'We will never show your email address on our website. We just want to confirm it was really you who left the review.'"
              [maxStringLength]="300"
              [personalVars]="templateVars"
            ></alpha-textarea>
          </div>

          <div>
            <header class="email-custom-header-3">
              <!-- Button Content -->
              Button Content
            </header>
            <alpha-textarea
              [height]="'120px'"
              (sourceStringChange)="onChange()"
              [(sourceString)]="messagingEmailTemplate.button_content"
              [placeholder]="'Write A Review'"
              [maxStringLength]="80"
              [personalVars]="templateVars"
            >
            </alpha-textarea>
          </div>
        </section>

        <!-- <header class="email-custom-header-3">
          Satisfaction Button
        </header>

        <div class="email-custom-content">
          <header class="email-custom-header-4">평점 모양</header>
          <select class="email-custom-value"></select>
        </div>

        <div class="email-custom-content">
          <header class="email-custom-header-4">평점 순서</header>
          <select class="email-custom-value"></select>
        </div>

        <header class="email-custom-header-3">
          Instagram Share Button
        </header>

        <div class="email-custom-content">
          <header class="email-custom-header-4">평점 1점</header>
          <select class="email-custom-value"></select>
        </div>

        <div class="email-custom-content">
          <header class="email-custom-header-4">평점 2점</header>
          <select class="email-custom-value"></select>
        </div>

        <div class="email-custom-content">
          <header class="email-custom-header-4">평점 3점</header>
          <select class="email-custom-value"></select>
        </div>

        <div class="email-custom-content">
          <header class="email-custom-header-4">평점 4점</header>
          <select class="email-custom-value"></select>
        </div>

        <div class="email-custom-content">
          <header class="email-custom-header-4">평점 5점</header>
          <select class="email-custom-value"></select>
        </div>

        <header class="email-custom-header-3">
          Instagram Share Button
        </header>

        <label class="email-custom-summary">· 공백 포함 최대 characters까지 입력 가능합니다</label>

        <div class="email-custom-content">
          <header class="email-custom-header-4">"Share it" Message</header>
          <select class="email-custom-value"></select>
        </div>

        <div class="email-custom-content">
          <header class="email-custom-header-4">Button Message</header>
          <select class="email-custom-value"></select>
        </div> -->

        <section class="setting-email-content-wrapper">
          <header class="email-custom-header-3 flex-wrapper" style="max-width: fit-content">
            Review page button
            <label class="switch">
              <input type="checkbox" class="email-toggle-button" [(ngModel)]="messagingEmailTemplate.use_review_page_button" (change)="onChange()" /><span class="slider"></span>
            </label>
          </header>

          <div>
            <input
              class="admin-input"
              (blur)="onChange()"
              [placeholder]="'If you want to modify or delete the review?'"
              [(ngModel)]="messagingEmailTemplate.review_page_button_message"
            />
          </div>
          <label class="email-custom-summary"><img src="assets/icon/info_deep_gray.svg" /> You can enter up to 70 characters, including spaces</label>

          <header class="email-custom-header-3" style="margin-top: 20px; max-width: fit-content">
            <!-- Unsubscribe Message -->
            Unsubscribe Message
            <label class="switch">
              <input type="checkbox" class="email-toggle-button" (change)="onChange()" [(ngModel)]="messagingEmailTemplate.use_unsubscribe_message" /><span class="slider"></span>
            </label>
          </header>
          <div>
            <input class="admin-input" (blur)="onChange()" [(ngModel)]="messagingEmailTemplate.unsubscribe_message" [placeholder]="'Unscribe from review request emails'" />
          </div>
          <label class="email-custom-summary"><img src="assets/icon/info_deep_gray.svg" /> You can enter up to 50 characters, including spaces</label>
        </section>
      </div>

      <section class="setting-email-container" style="width: 520px">
        <!-- <header class="email-custom-header-1">
          Preview
        </header>

        <section
          style="padding: 15px; background-color: white; margin-top: 20px"
        >
          <header class="email-custom-preview-header">
            <div style="display: flex">
              <div class="email-custom-preview-header-title">Subject</div>

              <div class="email-custom-preview-header-content">
                {{ messagingEmailTemplate.subject }}
              </div>
            </div>

            <div style="display: flex; margin-top: 6px">
              <div class="email-custom-preview-header-title">Preview Text</div>
              <div class="email-custom-preview-header-content">
                {{ messagingEmailTemplate.preview_text }}
              </div>
            </div>
          </header>
        </section> -->
        <main id="preview-email" #previewEmail style="justify-content: center; display: flex; background-color: white; pointer-events: none">
          <div [ngStyle]="{ width: demoDevice == 'PC' ? '100%' : '360px' }" [innerHTML]="previewEmailHTML | safeHtml"></div>
        </main>
      </section>
    </section>
  </section>
</article>
