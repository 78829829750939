import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/service/admin.service';

@Component({
  selector: 'app-dialog-app-guidance',
  templateUrl: './dialog-app-guidance.component.html',
  styleUrls: ['./dialog-app-guidance.component.less'],
})
export class DialogAppGuidanceComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DialogAppGuidanceComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: string,
    private router: Router,
    private adminService: AdminService,
  ) {}

  ngOnInit(): void {
    this.target = this.dialogContents.find((item) => item.target === this.data);
  }

  target?: guidanceContent;

  dialogContents: guidanceContent[] = [
    {
      target: 'reviews',
      header: `Start Managing Reviews Easily
              with AlphaReview in Just a Minute`,
      imgSrc: `assets/image/page/guidance_reviews.png`,
      description: `Experience the convenience of gathering all reviews in one place, seamlessly <strong>rewarding customers with coupons,</strong> and <strong>featuring standout reviews,</strong> all within our exceptional UI.`,
      link: `https://a-review.co/home/moderate`,
    },
    {
      target: 'collect',
      header: `Start Collecting Reviews Easily
              with AlphaReview in Just a Minute`,
      imgSrc: `assets/image/landing/collect-main.gif`,
      description: `Maximize review collection with AlphaReview. Send <strong>customizable</strong> emails automatically for reviews and <strong>reminders</strong>, engaging more customers and gathering valuable feedback with ease.`,
      link: `https://a-review.co/home/collect`,
    },
    {
      target: 'import',
      header: `Start Importing Reviews Easily
              with AlphaReview in Just a Minute`,
      imgSrc: `assets/image/page/guidance_import.png`,
      description: `<strong>Effortlessly import</strong> reviews with AlphaReview! Just enter an AliExpress URL, and in under 10 seconds, watch your reviews <strong>seamlessly integrate.</strong> Amazon reviews? They're just as easy.`,
      link: `https://a-review.co/home/collect`,
    },
    {
      target: 'display',
      header: `Start Displaying Reviews Beautifully
              with AlphaReview in Just a Minute`,
      imgSrc: `assets/image/page/guidance_display.png`,
      description: `Enhance your site's appeal with AlphaReview's <strong>fully customizable</strong> widgets. Display reviews in <strong>stunning, diverse formats</strong> that captivate your audience. Set up in just a minute, and transform your site's look with beautifully presented feedback.`,
      link: `https://a-review.co/home/showcase`,
    },
    {
      target: 'analytics',
      header: `Start with AlphaReview in Just a Minute
              and Elevate Your Store!`,
      imgSrc: `assets/image/page/guidance_analytics.png`,
      description: `AlphaReview offers everything you need about reviews - collecting, organizing, and displaying them beautifully, all at an affordable price or even for free. Enhance your store's social proof with us and drive your store towards success.`,
      link: `https://a-review.co/home`,
    },
    {
      target: 'sales-popup',
      header: `Start Displaying Social Proof
              with AlphaReview in Just a Minute`,
      imgSrc: `assets/image/page/guidance_sales.png`,
      description: `Showcase social proof vividly with real-time visitors, total visitor count, and purchase numbers displayed directly. Elevate customer trust in your store effortlessly. It's free!`,
      link: `https://a-review.co/home`,
    },
    {
      target: 'marketing',
      header: `Start Communicating with Customers
              with AlphaReview in Just a Minute`,
      imgSrc: `assets/image/page/guidance_marketing.png`,
      description: `Automatically post positive reviews on Facebook and X to spread trust in your store far and wide. The more you share across multiple channels, the more your credibility and sales will grow.`,
      link: `https://a-review.co/home/marketing`,
    },
    {
      target: 'touchpoints',
      header: `Start Communicating with Customers
              with AlphaReview in Just a Minute`,
      imgSrc: `assets/image/page/guidance_touch.png`,
      description: `Responding to all customer reviews is essential to protect your brand reputation, build stronger relationships, and boost customers' loyalty, whether the reviews are positive or negative. Manage customer relationship smartly with review replies.`,
      link: `https://a-review.co/home/moderate`,
    },
  ];

  onCancel() {
    this.dialogRef.close();
  }

  onStart() {
    this.dialogRef.close();
    this.router.navigate([`/admin/onboarding/${this.adminService.getShopAccessCode()}/app-install`]);
  }
}

interface guidanceContent {
  [key: string]: string;
}
