<article id="review-import" class="setting-article" style="max-width: 1000px">
  <header class="admin-header-1">
    <div class="flex-wrapper" style="gap: 10px">
      Import
      <a class="btn-anchor" href="https://alph.oopy.io/472cbc82-3109-4bbb-a83b-dfb9adec11cf#4dbb2a11-99c5-413e-91ba-8210e5414958" target="_blank">Help</a>
    </div>
    <button class="btn-white" (click)="openDialogReviewImportHistory()" style="width: 140px; margin-left: auto">Import History</button>
  </header>
  <summary class="admin-content-description">Enrich your reviews by importing from various sources such as AliExpress, Amazon, and other review app.</summary>
  <section style="position: relative">
    <mat-tab-group class="admin-tab" style="margin-bottom: 16px" [selectedIndex]="genericService.changeSelectedIndex()" (selectedTabChange)="getProducts()">
      <mat-tab label="From Aliexpress">
        <!-- Import 기능에 이상이 있을경우 warning 섹션  -->
        <!-- <section class="notice-box warning">
          <img src="assets/icon/warning_red.svg" />
          We're sorry for any trouble caused.
          <b style="font-weight: bold">AliExpress is testing new page formats and URLs, which may affect importing product reviews differently across countries.</b>
          If you receive an error message or notice no reviews imported, please be patient and try again later. Our team is working on a solution and will keep you updated. Thank
          you for your understanding.
        </section> -->

        <section class="gray-border-wrapper" style="height: calc(100vh - 241px)">
          <div style="height: 100%">
            <section class="from-alphareview-note">
              <span style="font-weight: bold">Notice:</span>Imports can sometimes fail due to several reasons. Figure it out with our
              <a href="https://alph.oopy.io/9546176e-fa1c-4e7c-b5ab-2b3375cc1b0b" target="_blank"> troubleshooting guide<img src="assets/icon/link_deep_blue.svg" /> </a>
              or reach out to us.
            </section>

            <section class="flex-wrapper" style="margin-bottom: 11px">
              <form class="admin-form" style="width: 220px">
                <button (click)="getProducts()">
                  <img src="assets/icon/search_gray.svg" />
                </button>
                <input
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="productService.searchContent"
                  (keydown.enter)="getProducts()"
                  placeholder="Search"
                  style="padding-left: 0"
                />
              </form>

              <select class="admin-input" style="width: 170px" [ngModel]="''" (ngModelChange)="onChangeSortDropDown($event)">
                <option i18n selected disabled value="">Sort</option>
                <option i18n [ngValue]="'-review_count'">Most reviewed</option>
                <option i18n [ngValue]="'review_count'">Least reviewed</option>
                <option i18n [ngValue]="'-rating'">Highest ratings</option>
                <option i18n [ngValue]="'rating'">Lowest ratings</option>
                <option i18n [ngValue]="'newest'">Latest creation</option>
              </select>
            </section>
            <section class="content-scroll" style="height: calc(100% - 143px); border-bottom: 1px solid #d3d4d8">
              <header class="content-table-header">
                <div class="content-table-name" style="width: 100%">Product</div>
                <div class="content-table-name" style="width: 200px; flex-shrink: 0">Action</div>
              </header>

              <section style="max-height: calc(100% - 42px); height: 100%; overflow-y: auto">
                <ng-container *ngFor="let product of aliexpressDataSource">
                  <app-template-import
                    [product]="product"
                    [importPlatform]="this.importService.ReviewAppPlatform.ALI_EXPRESS"
                    (deletedAll)="getProducts()"
                    (importStart)="importedStart($event)"
                  ></app-template-import>
                </ng-container>
              </section>
            </section>

            <mat-paginator #aliexpressPaginator [pageSizeOptions]="[10, 20, 50]" (page)="getProducts()"> </mat-paginator>
          </div>
        </section>
      </mat-tab>

      <mat-tab label="From Amazon">
        <!-- Import 기능에 이상이 있을경우 warning 섹션  -->
        <!-- <section class="notice-box warning">
          <img src="assets/icon/warning_red.svg" />
          <b style="font-weight: bold"> August 23, 2023 Update: </b>
          We're currently experiencing some minor issues with our Amazon Review Importer feature. It's temporarily not
          working as intended, but we're on it! Please bear with us as we work to resolve this quickly.
        </section> -->

        <section class="gray-border-wrapper" style="height: calc(100vh - 241px)">
          <div style="height: 100%">
            <section class="from-alphareview-note"><span style="font-weight: bold">Notice:</span>If you face any issues importing reviews, reach out to us anytime.</section>

            <section class="flex-wrapper" style="margin-bottom: 11px">
              <form class="admin-form" style="width: 220px">
                <button (click)="getProducts()">
                  <img src="assets/icon/search_gray.svg" />
                </button>
                <input
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="productService.searchContent"
                  (keydown.enter)="getProducts()"
                  placeholder="Search"
                  style="padding-left: 0"
                />
              </form>

              <select class="admin-input" style="width: 170px" [ngModel]="''" (ngModelChange)="onChangeSortDropDown($event)">
                <option i18n selected disabled value="">Sort</option>
                <option i18n [ngValue]="'-review_count'">Most reviewed</option>
                <option i18n [ngValue]="'review_count'">Least reviewed</option>
                <option i18n [ngValue]="'-rating'">Highest ratings</option>
                <option i18n [ngValue]="'rating'">Lowest ratings</option>
                <option i18n [ngValue]="'newest'">Latest creation</option>
              </select>
            </section>
            <section class="content-scroll" style="height: calc(100% - 143px); border-bottom: 1px solid #d3d4d8">
              <header class="content-table-header">
                <div class="content-table-name" style="width: 100%">Product</div>
                <div class="content-table-name" style="width: 200px; flex-shrink: 0">Action</div>
              </header>

              <section style="max-height: calc(100% - 42px); height: 100%; overflow-y: auto">
                <ng-container *ngFor="let product of amazonDataSource">
                  <app-template-import
                    [product]="product"
                    [importPlatform]="this.importService.ReviewAppPlatform.AMAZON"
                    (deletedAll)="getProducts()"
                    (importStart)="importedStart($event)"
                  ></app-template-import>
                </ng-container>
              </section>
            </section>

            <mat-paginator #amazonPaginator [pageSizeOptions]="[10, 20, 50]" (page)="getProducts()"> </mat-paginator>
          </div>
        </section>
      </mat-tab>

      <mat-tab label="From CSV File">
        <section class="from-alphareview-note" style="margin-top: 24px">
          <span style="font-weight: bold">Notice:</span>If both the 'product_id' and 'product_handle' field are empty, the reviews will be imported as store reviews.
        </section>

        <section class="gray-border-wrapper" style="margin-top: 0">
          <header class="admin-header-3">Import from Supported Review Apps</header>
          <summary class="admin-content-description">Upload the completed file back to Alpha Review.</summary>

          <ol>
            <li>Export the reviews from the review app you were using.</li>
            <li>
              <header>Select the review app you were using.</header>
              <section class="select-wrapper">
                <select style="width: 240px; margin: 10px 0" [(ngModel)]="fromOtherReviewPreviousProvider">
                  <option [ngValue]="importService.ReviewAppPlatform.JUDGE_ME">Judge.me</option>
                  <option [ngValue]="importService.ReviewAppPlatform.LOOX">Loox</option>
                  <option [ngValue]="importService.ReviewAppPlatform.STAMPED_IO">Stamped.io</option>
                  <option [ngValue]="importService.ReviewAppPlatform.YOTPO">Yotpo</option>
                  <option [ngValue]="importService.ReviewAppPlatform.OKENDO">OKendo</option>
                  <option [ngValue]="importService.ReviewAppPlatform.RYVIU">Ryviu</option>
                  <option [ngValue]="importService.ReviewAppPlatform.RIVYO">Rivyo</option>
                  <option [ngValue]="importService.ReviewAppPlatform.PRODUCT_REVIEWS">Shopify Product Reviews</option>
                </select>
              </section>
              <section style="display: flex; align-items: center; gap: 3px">
                <span class="admin-content-description" style="padding: 0">Click to</span>
                <span [ngSwitch]="fromOtherReviewPreviousProvider">
                  <a
                    href="https://support.judge.me/support/solutions/articles/44001815603-export-reviews-from-judge-me"
                    target="_blank"
                    class="show-guide"
                    *ngSwitchCase="importService.ReviewAppPlatform.JUDGE_ME"
                  >
                    see how to export reviews from Judge.me</a
                  >
                  <a href="https://help.loox.io/article/21-how-do-i-export-my-reviews" class="show-guide" target="_blank" *ngSwitchCase="importService.ReviewAppPlatform.LOOX">
                    see how to export reviews from Look</a
                  >
                  <a
                    href="https://stampedsupport.zendesk.com/hc/en-us/articles/8839244356891-Exporting-Reviews-Checkout-Comments-or-NPS"
                    target="_blank"
                    class="show-guide"
                    *ngSwitchCase="importService.ReviewAppPlatform.STAMPED_IO"
                  >
                    see how to export reviews from Stamped.io</a
                  >
                  <a
                    href="https://support.yotpo.com/en/article/exporting-reviews-from-yotpo"
                    class="show-guide"
                    target="_blank"
                    *ngSwitchCase="importService.ReviewAppPlatform.YOTPO"
                  >
                    see how to export reviews from Yotpo</a
                  >
                  <a href="https://alph.oopy.io/d737f993-bf64-46da-b364-7ae57fd53991" class="show-guide" *ngSwitchCase="importService.ReviewAppPlatform.OKENDO" target="_blank">
                    see how to export reviews from OKendo</a
                  >
                  <a
                    href="https://docs.ryviu.com/en/articles/5591221-exporting-reviews-to-csv-file"
                    class="show-guide"
                    target="_blank"
                    *ngSwitchCase="importService.ReviewAppPlatform.RYVIU"
                  >
                    see how to export reviews from Ryviu</a
                  >
                  <a
                    href="https://webcontrive.helpscoutdocs.com/article/80-how-to-export-reviews"
                    class="show-guide"
                    target="_blank"
                    *ngSwitchCase="importService.ReviewAppPlatform.RIVYO"
                  >
                    see how to export reviews from Rivyo</a
                  >
                  <a
                    href="https://help.shopify.com/en/manual/products/product-reviews/import-export-reviews"
                    target="_blank"
                    class="show-guide"
                    *ngSwitchCase="importService.ReviewAppPlatform.PRODUCT_REVIEWS"
                  >
                    see how to export reviews from Shopify Product Reviews</a
                  >
                </span>
              </section>
            </li>
            <li>
              <header>Upload the file.</header>
              <summary class="admin-content-description">File format must be CSV.</summary>
            </li>
          </ol>
          <button (click)="openDialogReviewImportUpload(fromOtherReviewPreviousProvider)" class="btn-confirm">Select File</button>
        </section>

        <section class="gray-border-wrapper" style="margin-top: 20px">
          <header class="admin-header-3">From AlphaReview Template</header>
          <summary class="admin-content-description">If your previous app is not listed above, use AlphaReview Template to import</summary>

          <ol>
            <li>Export the reviews from the review app you were using.</li>
            <li>
              <a href="https://s3.ap-northeast-2.amazonaws.com/alpha-review.global/public/platform/shopify/alphareview_review_import.csv" class="show-guide" target="download"
                >Download</a
              >
              the AlphaReview template CSV file.
            </li>
            <li>
              <header>
                Fill in the appropriate data according to the AlphaReview template format. It will be easier if you refer to this
                <a href="https://alph.oopy.io/bf4bfeb2-9e52-49c0-b34c-1acb750a5bf0" class="show-guide" target="_blank">guide</a>
              </header>
              <summary class="admin-content-description" style="padding-bottom: 0">Required data: content, rating, customer_name, created_at, product_handle or product_id</summary>
            </li>
            <li>
              <header>Upload the file.</header>
              <summary class="admin-content-description">File format must be CSV.</summary>
            </li>
          </ol>
          <button class="btn-confirm" (click)="openDialogReviewImportUpload(ReviewAppPlatform.CSV)">Select File</button>
        </section>
      </mat-tab>
    </mat-tab-group>
  </section>
</article>
