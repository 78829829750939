"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleAppPrint = void 0;
var redirect_1 = require("./redirect");
function isRunningOniOS() {
    return navigator.userAgent.indexOf('iOS') >= 0;
}
function createHiddenInput() {
    var currentWindow = redirect_1.getWindow();
    if (!currentWindow || !currentWindow.document || !currentWindow.document.body) {
        return;
    }
    var inputElement = currentWindow.document.createElement('input');
    inputElement.style.display = 'none';
    currentWindow.document.body.appendChild(inputElement);
    return inputElement;
}
function printWindow() {
    var _a;
    (_a = redirect_1.getWindow()) === null || _a === void 0 ? void 0 : _a.print();
}
function handleMobileAppPrint() {
    var input = createHiddenInput();
    if (!input) {
        return;
    }
    input.select();
    printWindow();
    input.remove();
}
function handleAppPrint() {
    if (isRunningOniOS()) {
        handleMobileAppPrint();
    }
    else {
        printWindow();
    }
}
exports.handleAppPrint = handleAppPrint;
