import { Injectable } from '@angular/core';
import { WidgetInstallationPosition, WidgetSet, WidgetType } from 'src/app/models/widget/widget.model';
import { AdminService } from 'src/app/service/admin.service';
import { BackendService } from 'src/app/service/backend.service';
import { isEqual } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DisplayService {
  constructor(private backendService: BackendService, private adminService: AdminService) {}

  coreScriptActive?: boolean = true;
  deepLinkingUrl?: string;

  widgetSet!: WidgetSet;
  originWidgetSet!: WidgetSet;

  hideSaveBar: boolean = true;

  counter(max: number): Array<number> {
    return new Array(max);
  }

  getWidgetOptionSet(type: WidgetInstallationPosition) {
    let url = 'widget/design/custom/widget/option/set';
    return this.backendService.select(url, { type: type, shop_access_code: this.adminService.getShopAccessCode() });
  }

  updateWidgetOptions(widgetSet: WidgetSet) {
    let url = `widget/design/custom/widget/option/set/${widgetSet.id}`;
    return this.backendService.update(url, widgetSet, { shop_access_code: this.adminService.getShopAccessCode() });
  }

  getDeepLinkingUrl() {
    let url = 'widget/deeplinking';

    this.backendService.select(url, { shop_access_code: this.adminService.getShopAccessCode() }).subscribe((response) => {
      let body = response.body;
      this.deepLinkingUrl = body.app_embed_block_url;

      window.open(this.deepLinkingUrl, '_blank');
    });
  }

  checkCoreScriptActive() {
    let url = 'shops/action/check/core-script';

    this.backendService.create(url, {}, { shop_access_code: this.adminService.getShopAccessCode() }).subscribe((response) => {
      let body = response.body;
      this.coreScriptActive = body.ok;
    });
  }

  checkSettingChanges() {
    return isEqual(this.widgetSet, this.originWidgetSet);
  }

  get WidgetType(): typeof WidgetType {
    return WidgetType;
  }
}
