import { Component, Input, OnInit } from '@angular/core';
import { ShopAdminAccount } from 'src/app/models/shop/shop.model';
import { ShopService } from 'src/app/service/shop.service';

@Component({
  selector: 'admin-account-role-description',
  templateUrl: './admin-account-role-description.component.html',
  styleUrls: ['./admin-account-role-description.component.less']
})
export class AdminAccountRoleDescriptionComponent implements OnInit {

  constructor(
    public shopService: ShopService
  ) { }

  @Input('shopAdminAccount') shopAdminAccount!: ShopAdminAccount

  ngOnInit(): void {
  }
}
