"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPackageName = exports.getVersion = exports.getMergedProps = exports.updateActionFromPayload = exports.isValidOptionalString = exports.isValidOptionalNumber = exports.NonSnakeCaseGroup = exports.getEventNameSpace = exports.forEachInEnum = exports.findMatchInEnum = exports.actionWrapper = void 0;
var helper_1 = require("@shopify/app-bridge-core/actions/helper");
Object.defineProperty(exports, "actionWrapper", { enumerable: true, get: function () { return helper_1.actionWrapper; } });
Object.defineProperty(exports, "findMatchInEnum", { enumerable: true, get: function () { return helper_1.findMatchInEnum; } });
Object.defineProperty(exports, "forEachInEnum", { enumerable: true, get: function () { return helper_1.forEachInEnum; } });
Object.defineProperty(exports, "getEventNameSpace", { enumerable: true, get: function () { return helper_1.getEventNameSpace; } });
Object.defineProperty(exports, "NonSnakeCaseGroup", { enumerable: true, get: function () { return helper_1.NonSnakeCaseGroup; } });
Object.defineProperty(exports, "isValidOptionalNumber", { enumerable: true, get: function () { return helper_1.isValidOptionalNumber; } });
Object.defineProperty(exports, "isValidOptionalString", { enumerable: true, get: function () { return helper_1.isValidOptionalString; } });
Object.defineProperty(exports, "updateActionFromPayload", { enumerable: true, get: function () { return helper_1.updateActionFromPayload; } });
Object.defineProperty(exports, "getMergedProps", { enumerable: true, get: function () { return helper_1.getMergedProps; } });
// eslint-disable-next-line @typescript-eslint/no-var-requires
var packageJson = require('../package.json');
function getVersion() {
    return packageJson.version;
}
exports.getVersion = getVersion;
function getPackageName() {
    return packageJson.name;
}
exports.getPackageName = getPackageName;
