import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-empty-image',
  template: `
  <section [ngStyle]="{width: outerWidth, height: outerHeight, 'border': '1px solid ' + fill}" style="display: flex; align-items: center; justify-content: center; border-radius: 6px">
    <svg  viewBox="0 0 20 20" focusable="false" style="width: 20px; height: 20px"
      aria-hidden="true">
      <path [attr.fill]="fill"
        d="M2.5 1a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-15a1.5 1.5 0 0 0-1.5-1.5h-15zm5 3.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm8.999 12.5h-13.002c-.41 0-.64-.46-.4-.79l3.553-4.051c.19-.21.52-.21.72-.01l1.63 1.851 3.06-4.781a.5.5 0 0 1 .84.02l4.039 7.011c.18.34-.06.75-.44.75z">
      </path>
    </svg>
  </section>
  `,
  styles: [
  ]
})
export class IconEmptyImageComponent implements OnInit {
  outerWidth: string = '53px'
  outerHeight: string = '53px'
  fill: string = '#e6e9ec'
  constructor() { }

  ngOnInit(): void {
  }

}
