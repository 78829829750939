<main id="landing-showcase">
  <article class="showcase-visual landing-flex-wrapper">
    <section class="showcase-visual-summary">
      <span class="landing-header-label yellow"> Showcase Reviews </span>
      <header class="landing-header white">
        <span class="showcase-yellow">Showcase</span> <br />
        <br class="mobr" />
        Happy Customer Reviews <br />
        <br class="mobr" />
        and <span class="showcase-yellow">Boost Sales</span>
      </header>
      <summary class="landing-summary" *ngIf="!landingService.mobile">
        The store's design influences the <br />
        customers’ purchase decisions <br />
        more than expected. Therefore, <br />
        AlphaReview offers the most <br />
        beautiful widgets which are fully <br />
        customizable.
      </summary>
      <summary class="landing-summary" *ngIf="landingService.mobile">
        AlphaReview offers the most beautiful widgets which are fully
        customizable.
      </summary>
      <button
        class="showcase-btn"
        onclick="window.open('https://alphareview-demo-store.myshopify.com/')"
      >
        Go to Demo Store
      </button>
    </section>
    <section class="showcase-visual-img">
      <img src="assets/image/landing/showcase-main.png" />
    </section>
  </article>
  <!-- showcase visual end -->

  <article class="showcase-enjoy">
    <span class="landing-header-label gray">Basic Widgets</span>
    <header class="landing-header">
      Let Your Customers Enjoy Browsing Reviews
    </header>
    <summary class="landing-summary" *ngIf="!landingService.mobile">
      Install AlphaReview's review widgets with the best user interface, <br />
      and let customers enjoy browsing reviews. <br />
      Browsing reviews will be easy and fun, which can eventually lead to a
      natural purchase conversion.
    </summary>

    <img
      *ngIf="!landingService.mobile"
      src="assets/image/landing/showcase-enjoy.png "
    />
    <img
      *ngIf="landingService.mobile"
      src="assets/image/landing/showcase-enjoy-mo.png "
    />
  </article>
  <!-- showcase enjoy end -->

  <article class="showcase-widget">
    <span class="landing-header-label dark">Media Widgets</span>
    <header class="landing-header">
      Display Media Reviews <br class="mobr" />
      on Stunning Widgets
    </header>
    <summary class="landing-summary" *ngIf="!landingService.mobile">
      Images and Videos allow your customers to connect with the product
      visually. <br />
      Seeing a picture or video of someone who owns the product wearing or using
      it <br />
      helps customers visualize how it will fit into their lives. <br />
      In other words, displaying media reviews on the product page significantly
      impacts the conversion rate.
    </summary>

    <div class="inner showcase-widget-photo">
      <swiper [config]="widgetPhotoConfig">
        <ng-template swiperSlide>
          <img
            *ngIf="!landingService.mobile"
            src="assets/image/landing/widget-photos-gray.png"
          />
          <img
            *ngIf="landingService.mobile"
            src="assets/image/landing/widget-photos-mo.png"
          />
        </ng-template>
        <ng-template swiperSlide>
          <img
            *ngIf="!landingService.mobile"
            src="assets/image/landing/widget-simple-gray.png"
          />
          <img
            *ngIf="landingService.mobile"
            src="assets/image/landing/widget-simple-mo.png"
          />
        </ng-template>
      </swiper>
    </div>

    <span class="landing-header-label dark">Happy Customer Widgets</span>
    <header class="landing-header">
      Show Off Happy Customers with Trending Widgets
    </header>
    <summary class="landing-summary" *ngIf="!landingService.mobile">
      AlphaReview provides multiple attractive widgets to showcase happy
      customer reviews. <br />
      These widgets turn window shoppers into your actual customers. <br />
      You can choose stunning reviews to showcase proudly on the beautiful
      widgets <br />
      and increase the conversion rate.
    </summary>

    <div class="inner showcase-widget-text">
      <swiper [config]="widgetTextConfig">
        <ng-template swiperSlide>
          <img
            *ngIf="!landingService.mobile"
            src="assets/image/landing/widget-ranking-gray.png"
          />
          <img
            *ngIf="landingService.mobile"
            src="assets/image/landing/widget-ranking-mo.png"
          />
        </ng-template>
        <ng-template swiperSlide>
          <img
            *ngIf="!landingService.mobile"
            src="assets/image/landing/widget-text-gray.png"
          />
          <img
            *ngIf="landingService.mobile"
            src="assets/image/landing/widget-text-mo.png"
          />
        </ng-template>
        <ng-template swiperSlide>
          <img
            *ngIf="!landingService.mobile"
            src="assets/image/landing/widget-highlight-gray.png"
          />
          <img
            *ngIf="landingService.mobile"
            src="assets/image/landing/widget-highlight-mo.png"
          />
        </ng-template>
      </swiper>
    </div>
  </article>
  <!-- showcase widget end -->

  <article class="showcase-customize">
    <header class="landing-header">
      Customize the Widgets <br class="mobr" />
      to Match Your Brand
    </header>
    <summary class="landing-summary">
      AlphaReview enables you to customize all <br class="mobr" />
      the review widgets to match your store <br class="mobr" />
      style. <br />
      You can easily modify the look and <br class="mobr" />
      feel of the review widget <br />
      to match your <br class="mobr" />
      brand concept without changing a single <br class="mobr" />
      line of code. <br />
      And we still support CSS&JS <br class="mobr" />
      customization!
    </summary>
    <img src="assets/image/landing/showcase-customize.png" />
  </article>
</main>
