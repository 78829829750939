import { Injectable } from '@angular/core';
import { AlphaApps, ShopDetail } from 'src/app/models/shop/profile/store-detail.model';
import { Shop } from 'src/app/models/shop/shop.model';
import { AdminService } from 'src/app/service/admin.service';
import { ShopService } from 'src/app/service/shop.service';
import { LeftNavigationBarService } from '../layout/left-navigation-bar/left-navigation-bar.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor(private adminService: AdminService, private shopService: ShopService, private lnbService: LeftNavigationBarService) {}

  shop?: Shop;
  shopDetail!: ShopDetail;
  integrate: boolean = false;
  email: string = '';
  routeLink: string = `/admin/onboarding/${this.adminService.getShopAccessCode()}`;

  checkIntegrateAccount() {
    this.shopService.checkExistsAccount().subscribe((response) => {
      this.integrate = response.body.ok;
      this.email = response.body.email;
    });
  }

  setShopDetailUseApp(selectedApp: AlphaApps): void {
    if (!this.shopDetail) {
      this.shopService.getShopDetail().subscribe((response) => {
        this.shopDetail = response.body;
        this.updateUseApp(selectedApp);
      });
    } else {
      this.updateUseApp(selectedApp);
    }
  }

  updateUseApp(selectedApp: AlphaApps): void {
    const updateApp = (app: AlphaApps) => {
      if (!this.shopDetail.use_apps.includes(app)) {
        this.shopDetail.use_apps.push(app);
        shop?.shop_detail?.use_apps.push(app);
        sessionStorage.setItem(`use_${AlphaApps[app].toLowerCase()}`, 'true');
      }
    };
    let shop = this.adminService.ShopAdminAccount.shop;

    switch (selectedApp) {
      case AlphaApps.REVIEW:
        updateApp(AlphaApps.REVIEW);
        this.lnbService.selectedApp = AlphaApps.REVIEW;
        break;
      case AlphaApps.AI:
        updateApp(AlphaApps.AI);
        break;
      case AlphaApps.LIST_DESIGNER:
        updateApp(AlphaApps.LIST_DESIGNER);
        break;
      case AlphaApps.BROWSE_BOOSTER:
        updateApp(AlphaApps.BROWSE_BOOSTER);
        break;
      default:
        break;
    }

    this.shopService.patchShopDetail(this.shopDetail).subscribe();
    this.adminService.setAdminAccountSession(this.adminService.ShopAdminAccount, shop);

    this.lnbService.shopDetail = this.shopDetail;
    this.lnbService.setRecommendation();
  }
}
