export enum SendingChannel {
  EMAIL = 1,
  SMS = 2,
  WEBPUSH = 3,
  REVISIT = 4
}

export class ShopMessagingOption {
  id: number | undefined = undefined
  shop_id: number | undefined = undefined
  email_sender_name: string | undefined = undefined
  email_sender_email: string | undefined = undefined
  sms_sender_name: string | undefined = undefined
  sms_sender_phone: string | undefined = undefined
}
