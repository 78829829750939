<!-- <article style="display: inline-block; vertical-align: middle;"> -->
<label class="checkbox-container">
  <input #cb class="checkbox-input" type="checkbox" [(ngModel)]="_checked" (ngModelChange)="onModelChange($event)"
    style="display: none" />
  <div class="checkbox">
    <img src="assets/icon/checkbox_on_black.svg" [ngStyle]="{ display: _checked ? 'block' : 'none' }"
      (click)="$event.stopPropagation()" />
    <img src="assets/icon/checkbox_off_round_ver2.svg" [ngStyle]="{ display: !_checked ? 'block' : 'none' }"
      (click)="$event.stopPropagation()" />
  </div>

  <div class="checkbox-content" (click)="$event.stopPropagation()">
    <ng-content> </ng-content>
  </div>
</label>
<!-- </article> -->