"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.Toast = exports.primaryAction = exports.clear = exports.show = exports.Action = void 0;
var Toast_1 = require("@shopify/app-bridge-core/actions/Toast");
Object.defineProperty(exports, "Toast", { enumerable: true, get: function () { return Toast_1.Toast; } });
var Toast_2 = require("@shopify/app-bridge-core/actions/Toast");
Object.defineProperty(exports, "Action", { enumerable: true, get: function () { return Toast_2.Action; } });
Object.defineProperty(exports, "show", { enumerable: true, get: function () { return Toast_2.show; } });
Object.defineProperty(exports, "clear", { enumerable: true, get: function () { return Toast_2.clear; } });
Object.defineProperty(exports, "primaryAction", { enumerable: true, get: function () { return Toast_2.primaryAction; } });
function create(app, options) {
    return new Toast_1.Toast(app, options);
}
exports.create = create;
