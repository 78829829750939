import { Component, OnInit } from '@angular/core';
import SwiperCore, {
  Autoplay,
  EffectFade,
  Pagination,
  SwiperOptions,
} from 'swiper';
import { LandingService } from '../landing.service';
SwiperCore.use([Autoplay, Pagination, EffectFade]);
@Component({
  selector: 'app-landing-showcase',
  templateUrl: './landing-showcase.component.html',
  styleUrls: ['./landing-showcase.component.less', '../home.component.less'],
})
export class LandingShowcaseComponent implements OnInit {
  constructor(public landingService: LandingService) {}

  ngOnInit(): void {
    this.landingService.nextPrev();
  }

  idx: string = 'showcase';

  widgetPhotoConfig: SwiperOptions = {
    effect: 'fade',
    autoplay: {
      delay: 3000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },
    pagination: {
      clickable: true,
      renderBullet: function (index: number, className: any) {
        let widget = [
          `<img src='assets/icon/landing-widget-photo.svg' /><span>Wall Photos</span>`,
          `<img src='assets/icon/landing-widget-gallery.svg' /><span>Gallery</span>`,
        ];

        return (
          '<div class="' +
          className +
          '"><span class="widget-pagination photo">' +
          widget[index] +
          '</span></div>'
        );
      },
    },
  };

  widgetTextConfig: SwiperOptions = {
    effect: 'fade',
    centeredSlides: true,
    autoplay: {
      delay: 3000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },
    pagination: {
      clickable: true,
      renderBullet: function (index: number, className: any) {
        let widget = [
          `<svg xmlns="http://www.w3.org/2000/svg" width="48.1" height="48.1" viewBox="0 0 48.1 48.1">
            <g data-name="그룹 14720">
                <path data-name="패스 8132" d="M455.05 223.791a5.093 5.093 0 0 0-2.718-9.205 5.093 5.093 0 0 1-4.753-5.88 5.093 5.093 0 0 0-8.431-4.587 5.094 5.094 0 0 1-7.519-.8 5.093 5.093 0 0 0-9.2 2.718 5.094 5.094 0 0 1-5.88 4.753 5.093 5.093 0 0 0-4.587 8.431 5.093 5.093 0 0 1-.8 7.519 5.093 5.093 0 0 0 2.717 9.2 5.093 5.093 0 0 1 4.753 5.88 5.093 5.093 0 0 0 8.431 4.587 5.093 5.093 0 0 1 7.519.8 5.093 5.093 0 0 0 9.205-2.718 5.093 5.093 0 0 1 5.88-4.753 5.093 5.093 0 0 0 4.587-8.431 5.093 5.093 0 0 1 .796-7.514z" transform="translate(-409.055 -201.217)" style="fill:#8a8b8e" class="change"/>
                <text data-name="1" transform="translate(16.55 33)" style="fill:#fff;font-size:25px;font-family:NotoSans-SemiBold,Noto Sans;font-weight:600; display:inline-flex; justify-content:center"><tspan x="0" y="0">1</tspan></text>
            </g>
          </svg>
          <span class='pc'>Ranked Reviews</span>
          <span class='mo'>Ranked</span>`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
              <g data-name="그룹 14718" transform="translate(-935 -4071)">
                  <circle data-name="타원 3695" cx="24" cy="24" r="24" transform="translate(935 4071)" style="fill:#8a8b8e" class="change"/>
                  <g data-name="그룹 14651">
                      <path data-name="사각형 29108" transform="translate(946.6 4084)" style="fill:#fff" d="M0 0h25v3H0z"/>
                      <path data-name="사각형 29109" transform="translate(953.6 4105)" style="fill:#fff" d="M0 0h11v2.1H0z"/>
                      <path data-name="사각형 29110" transform="rotate(90 -1562.876 2523.379)" style="fill:#fff" d="M0 0h20.817v2.805H0z"/>
                      <path data-name="패스 8128" d="M2.552 0s-.193 2.6.309 3.4 2.232.712 2.232.712V6.06H0z" transform="rotate(90 -1565.897 2518.557)" style="fill:#fff"/>
                      <path data-name="패스 8129" d="M2.552 6s-.193-2.578.309-3.363 2.232-.705 2.232-.705V0H0z" transform="rotate(90 -1556.427 2528.028)" style="fill:#fff"/>
                      <path data-name="패스 8130" d="M2.92 6.312s-.1-2.706.5-3.534 2.665-.743 2.665-.743V0H0z" transform="rotate(180 480.308 2053.537)" style="fill:#fff"/>
                      <path data-name="패스 8131" d="M2.906.055s-.09 2.678.51 3.505 2.665.74 2.665.74v2.038H0z" transform="translate(957.584 4100.735)" style="fill:#fff"/>
                  </g>
              </g>
          </svg>
          <span>Highlight-Text</span>`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
              <g data-name="그룹 14719" transform="translate(-1185 -4071)">
                  <circle data-name="타원 3696" cx="24" cy="24" r="24" transform="translate(1185 4071)" style="fill:#8a8b8e" class="change"/>
                  <path data-name="패스 5049" d="M-.206-1.881.02 13.655l3.239 3.81 3.8-3.8L6.871-1.87z" transform="rotate(45 -4321.907 3505.878)" style="fill:#fff"/>
                  <path data-name="사각형 29111" transform="translate(1197 4101)" style="fill:#fff" d="M0 0h25v4H0z"/>
              </g>
          </svg>
          <span>Highlight</span>`,
        ];

        return (
          '<div class="' +
          className +
          '"><span class="widget-pagination text">' +
          widget[index] +
          '</span></div>'
        );
      },
    },
  };
}
