import { Component, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ProductInReviewImport } from 'src/app/models/product/product.model';
import { ReviewAppPlatform } from 'src/app/models/shop/review/options/review-import.model';
import { AdminService } from 'src/app/service/admin.service';
import { BackendService } from 'src/app/service/backend.service';
import { GenericService } from 'src/app/service/generic.service';
import { ProductService } from 'src/app/service/product.service';
import { DialogReviewImportHistoryComponent } from './dialog-review-import-history/dialog-review-import-history.component';
import { DialogReviewImportUploadComponent } from './dialog-review-import-upload/dialog-review-import-upload.component';
import { ImportService } from './import.service';
import { Subscription, interval } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-import-review',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.less'],
})
export class ImportComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  @ViewChild('aliexpressPaginator') aliexpressPaginator!: MatPaginator;
  @ViewChild('amazonPaginator') amazonPaginator!: MatPaginator;

  private productList: ProductInReviewImport[] = [];
  private pollingSubscription?: Subscription;
  constructor(
    @Optional() @SkipSelf() private adminService: AdminService,
    private backendService: BackendService,
    private dialog: MatDialog,
    public importService: ImportService,
    public productService: ProductService,
    public genericService: GenericService,
  ) {}
  ngOnInit(): void {}

  ngAfterViewInit() {
    this.getProducts();
    let selectedIndex = history.state.selected_index;
    if (selectedIndex) this.tabGroup.selectedIndex = selectedIndex;
    else this.tabGroup.selectedIndex = 0;
  }
  ngOnDestroy() {
    this.stopPollingGetProducts();
  }

  fromOtherReviewPreviousProvider: ReviewAppPlatform = ReviewAppPlatform.JUDGE_ME;
  aliexpressDataSource?: ProductInReviewImport[];
  amazonDataSource?: ProductInReviewImport[];
  productEmbed: string[] = ['imported_review_count', 'last_imported', 'product_review_import_limit', 'is_aliexpress_importing', 'is_amazon_importing'];
  sortType: string = '';

  importedStart(importing: any) {
    importing && this.getProducts();
  }

  getProducts(
    params: {} = {
      date_about: this.productService.dateAbout,
      embed: this.productEmbed,
      search_content: this.productService.searchContent,
      sorting_type: this.productService.sortingType,
    },
  ) {
    let url = 'products';
    const import_platform = this.getImportPlatform();

    if (!import_platform) return;
    this.backendService
      .select(url, {
        shop_access_code: this.adminService.getShopAccessCode(),
        ...this.getSkipLimit(),
        import_platform: import_platform,
        ...params,
      })
      .subscribe((response) => {
        this.productList = response.body.data;

        if (import_platform == ReviewAppPlatform.ALI_EXPRESS) {
          this.aliexpressDataSource = this.productList;
          this.aliexpressPaginator.length = response.body.length as number;
        }
        if (import_platform == ReviewAppPlatform.AMAZON) {
          this.amazonDataSource = this.productList;
          this.amazonPaginator.length = response.body.length as number;
        }
        this.startPollingGetProducts();
      });
  }

  startPollingGetProducts() {
    this.pollingSubscription = interval(3000).subscribe(() => {
      if (this.productList.some((product) => product.is_aliexpress_importing === true)) this.getProducts();
      else this.stopPollingGetProducts();
    });
  }

  stopPollingGetProducts() {
    if (this.pollingSubscription) this.pollingSubscription.unsubscribe();
  }

  getImportPlatform() {
    if (this.tabGroup?.selectedIndex == 0) {
      this.genericService.LNBCategory = 'From Aliexpress';
      return this.importService.ReviewAppPlatform.ALI_EXPRESS;
    } else if (this.tabGroup?.selectedIndex == 1) {
      this.genericService.LNBCategory = 'From Amazon';
      return this.importService.ReviewAppPlatform.AMAZON;
    } else {
      this.genericService.LNBCategory = 'From CSV Import';
      return 0;
    }
  }

  getSkipLimit() {
    if (this.getImportPlatform() == ReviewAppPlatform.ALI_EXPRESS) {
      const skip = this.aliexpressPaginator.pageSize * this.aliexpressPaginator.pageIndex;
      const limit = this.aliexpressPaginator.pageSize;
      return { skip: skip, limit: limit };
    } else if (this.getImportPlatform() == ReviewAppPlatform.AMAZON) {
      const skip = this.amazonPaginator.pageSize * this.amazonPaginator.pageIndex;
      const limit = this.amazonPaginator.pageSize;
      return { skip: skip, limit: limit };
    }
    return {};
  }

  openDialogReviewImportUpload(reviewAppPlatform: ReviewAppPlatform) {
    const dialogRef = this.dialog.open(DialogReviewImportUploadComponent, {
      width: '400px',
      data: {
        reviewAppPlatform: reviewAppPlatform,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openDialogReviewImportHistory() {
    const dialogRef = this.dialog.open(DialogReviewImportHistoryComponent, {
      width: '814px',
      maxHeight: '90vh',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  onChangeSortDropDown(sortingType: any) {
    this.productService.sortingType = sortingType;
    this.getProducts();
    this.sortType = sortingType;
  }

  get ReviewAppPlatform(): typeof ReviewAppPlatform {
    return ReviewAppPlatform;
  }
}
