import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { AdminService } from './admin.service';
import { AiAnalysis, AnalysisLog, AnalysisLogProgressStatus } from '../models/ai/ai.model';
import { PaginationHandler } from '../shared/handler/pagination-handler';
import { ReviewAppPlatform } from '../models/shop/review/options/review-import.model';
import { DialogProductVariantSelectComponent } from '../admin/dialog/dialog-product-variant-select/dialog-product-variant-select.component';
import { DialogStartAlphaReviewComponent } from '../admin/alpha-ai/ai-aliexpress/ali-dialog/dialog-start-alpha-review/dialog-start-alpha-review.component';
import { Router } from '@angular/router';
import { Product } from '../models/product/product.model';
import { GenericService } from './generic.service';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { AlphaApps } from '../models/shop/profile/store-detail.model';

@Injectable({
  providedIn: 'root',
})
export class AiService {
  constructor(
    private backendService: BackendService,
    private adminService: AdminService,
    private genericService: GenericService,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  analysisList: AiAnalysis[] = [];
  analysisLogs: AnalysisLog[] = [];
  analysisLogFilters: AnalysisLogFilter = new AnalysisLogFilter();
  paginationHandler!: PaginationHandler;
  searchKeyword: string = '';
  searchAbout: 'product_name' = 'product_name';
  manageAnalysisLoading: boolean = false;

  analysisDetail!: AiAnalysis;
  neutralProportion: number = 0;

  isEmpty: boolean = false;

  dummyProducts = [
    {
      img: 'assets/image/ai/dummy_1.png',
      category: 'Home Improvements & Lightings',
      title: 'LED Desk Lamp Bar Restaurant Ambiance Wireless Table Lamps Study Office Light Waterproof Touch Lamp with USB Charging',
      url: 'https://www.aliexpress.com/item/1005005672763024.html',
    },
    {
      img: 'assets/image/ai/dummy_2.png',
      category: 'Women’s Bags',
      title: "Women's Wallet Shoulder Mini Leather Bags Straps Mobile Phone Big Card Holders Wallet Handbag Money Pockets Girls Small Bags",
      url: 'https://www.aliexpress.com/item/1005004702400508.html',
    },
    {
      img: 'assets/image/ai/dummy_3.png',
      category: 'Jewelry & Watches',
      title: "2023 Classic Stainless steel Roman Digital Round Pendant Earrings Korean Fashion Jewelry For Women's Temperament Accessories",
      url: 'https://www.aliexpress.com/item/1005005184677018.html',
    },
  ];

  verifyECommerceUrlList(payload: {} = {}, platform: ReviewAppPlatform) {
    let url = `ai/import/${ReviewAppPlatform.ALI_EXPRESS}/verify`;
    return this.backendService.create(url, payload);
  }

  importECommerce(payload: {} = {}, platform: ReviewAppPlatform) {
    let url = `ai/import/${platform}/`;
    return this.backendService.create(url, payload, { shop_access_code: this.adminService.getShopAccessCode() });
  }

  getAnalysis(params: {}, platform: ReviewAppPlatform) {
    let url = `ai/analysis/${platform}`;
    return this.backendService.select(url, params);
  }

  getAnalysisLog(params: {}, platform: ReviewAppPlatform) {
    let url = `ai/analysis/${platform}/logs`;
    return this.backendService.select(url, params);
  }

  getAnalysisStatus(platform: ReviewAppPlatform) {
    let url = `ai/import/${platform}/status`;
    return this.backendService.select(url, { shop_access_code: this.adminService.getShopAccessCode() });
  }

  importAnalysisReview(params: {}, log: AnalysisLog) {
    let url = `ai/import/${log.app_platform}/analysis/${log.id}`;
    return this.backendService.create(url, {}, params);
  }

  getAnalysisList(event: PageEvent | undefined = undefined, log?: boolean) {
    if (this.manageAnalysisLoading) return;
    this.manageAnalysisLoading = true;

    event && this.updatePaginationOnEvent(event);

    let params: any = {
      skip: this.paginationHandler.skip ?? 0,
      limit: this.paginationHandler.limit ?? 10,
      shop_access_code: this.adminService.getShopAccessCode(),
    };

    if (!log) {
      params.search_keyword = this.searchKeyword;
      this.getAliexpressAnalysis(params);
    } else {
      params.filters = JSON.stringify(this.analysisLogFilters);
      this.getAliexpressAnalysisLog(params);
    }
  }

  getAliexpressAnalysis(params: {}) {
    this.getAnalysis(params, ReviewAppPlatform.ALI_EXPRESS).subscribe((response) => {
      this.manageAnalysisLoading = false;
      this.analysisList = response.body.data;
      this.paginationHandler.paginator.length = response.body.length;

      this.analysisList.length ? (this.isEmpty = false) : (this.isEmpty = true);
    });
  }

  getAnalysisDetail(platform: ReviewAppPlatform, aiId: number) {
    let url = `ai/analysis/${platform}/detail/${aiId}`;
    this.backendService.select(url).subscribe((response) => {
      this.analysisDetail = response.body;
      this.neutralProportion = 100 - (this.analysisDetail.negative_proportion + this.analysisDetail.positive_proportion);
      this.neutralProportion = parseFloat(this.neutralProportion.toFixed(1));
    });
  }

  getAliexpressAnalysisLog(params: {}) {
    this.getAnalysisLog(params, ReviewAppPlatform.ALI_EXPRESS).subscribe((response) => {
      this.manageAnalysisLoading = false;
      this.analysisLogs = response.body.data;
      this.paginationHandler.paginator.length = response.body.length;
    });
  }

  updatePaginationOnEvent(event: PageEvent): void {
    if (event) {
      this.paginationHandler.skip = event.pageSize * event.pageIndex;
      this.paginationHandler.limit = event.pageSize;
    } else {
      this.paginationHandler.skip = 0;
      this.paginationHandler.paginator.firstPage();
    }
  }

  openDialogStartAlphaReview() {
    const dialogRef = this.dialog.open(DialogStartAlphaReviewComponent, {
      width: '550px',
      panelClass: 'start-alpha-review',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.router.navigate([`/admin/onboarding/${this.adminService.getShopAccessCode()}/app-install`]);
    });
  }

  openDialogProductVariantSelect(log: AnalysisLog, detail?: boolean) {
    const dialogRef = this.dialog.open(DialogProductVariantSelectComponent, {
      width: '480px',
      maxHeight: '70vh',
      data: { mode: 'radio', action: 'choice', ai: true },
    });

    dialogRef.afterClosed().subscribe((result: Product[]) => {
      if (!result) return;

      const shopDetail = this.adminService.ShopAdminAccount.shop?.shop_detail;
      if (!shopDetail?.use_apps.includes(AlphaApps.REVIEW)) this.openDialogStartAlphaReview();

      let params = { product_id: result[0].id, shop_access_code: this.adminService.getShopAccessCode() };
      this.importedAnalysisReview(params, log, detail);
    });
  }

  importedAnalysisReview(params: {}, log: AnalysisLog, detail?: boolean) {
    this.importAnalysisReview(params, log).subscribe({
      next: (response) => {
        if (!response.ok) return;
        !detail ? this.getAnalysisList(undefined, true) : this.getAnalysisDetail(log.app_platform, log.id);
        this.genericService.openSnackBar('Reviews Imported');
      },
      error: (error) => {
        if (error.status === 400) this.genericService.openSnackBar('Import Limit Reached');
      },
    });
  }

  // shopDetail: any;

  // getShopDetail() {
  //   this.shopService.getShopDetail().subscribe((response) => {
  //     this.shopDetail = response.body;

  //     this.patchShopDetail();
  //   });
  // }

  // patchShopDetail() {
  //   this.shopDetail.use_review = false;
  //   this.shopService.patchShopDetail(this.shopDetail).subscribe((response) => {});
  // }
}

class AnalysisLogFilter {
  progress_status: AnalysisLogProgressStatus[] = [AnalysisLogProgressStatus.SUCCESS];
}
