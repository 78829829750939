import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BackendService } from '../service/backend.service';

@Injectable({
  providedIn: 'root'
})
export class AuthSuperAdminService {

  constructor(
    private backendService: BackendService,
  ) { }

  superAdminSignUp(payload: UntypedFormGroup, profile_image: File | null) {


    let url = 'auth/super-admin/sign-up'
    const formData = new FormData()

    formData.append('name', payload.controls['name'].value)
    formData.append('account_id', payload.controls['account_id'].value)
    formData.append('password', payload.controls['password'].value)
    formData.append('email', payload.controls['email'].value)
    formData.append('nickname', payload.controls['nickname'].value)
    if (profile_image != null)
      formData.append('profile_image', profile_image as Blob)

    return this.backendService.form(url, formData)
  }

  superAdminLogin(payload: { account_id: string, password: string, }) {
    let url = 'auth/super-admin/sign-in'

    const formData = new FormData()
    for (let key in payload) {
      formData.append(key, payload[key as ('account_id' | 'password')])
    }
    return this.backendService.create(url, formData)
  }

  superAdminLogout() {
    let url = "super-admin/sign-out"
    sessionStorage.clear()
    localStorage.clear()
    return this.backendService.delete(url)
  }

  sendSuperAdminEmailCode(email: String) {
    let url = "auth/super-admin/email/send"
    return this.backendService.create(url, email)
  }

  verifySuperAdminEmailCode(email: String, verify_code: number) {
    let url = "auth/super-admin/email/verify"

    return this.backendService.create(url, { "email": email, "verify_code": verify_code })
  }

  vefirySuperAdminInvitationCode(verify_code: string) {
    let url = "auth/super-admin/invitation/verify"
    // const formData= new FormData()
    // formData.append('verify_code', verify_code)
    return this.backendService.select(url, '', { 'verify-code': verify_code })
  }

  superAdminAccountEdit(id: Number, edit: UntypedFormGroup, profile_image: File | null) {
    let url = 'super-admin/account'
    const formData = new FormData;
    formData.append('id', String(id))
    formData.append('name', edit.controls.name.value)
    formData.append('nickname', edit.controls.nickname.value)
    formData.append('email', edit.controls.email.value)
    if (profile_image != null)
      formData.append('profile_image', profile_image as Blob)

    return this.backendService.patch(url, formData)
  }

  superAdminUsers() {
    let url = 'super-admin/users'
    return this.backendService.select(url)
  }

  shopDashboardBridge(shop_id: number) {
    let url = 'super-admin/dashboard/bridge'
    let params = {
      "shop_id": shop_id
    }
    return this.backendService.select(url, params = params)
  }

}
