import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateFilterFn, DateRange, DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';

@Component({
  selector: 'alpha-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.less'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
})
export class CustomDatepickerComponent implements OnInit {
  constructor() {}

  @Input() selected: any | Date | DateRange<Date> = new Date();
  @Output() selectedChange = new EventEmitter<any>();
  @Input() dateFilter: (date: any) => boolean = () => {
    return true;
  };
  @Input() dateType: string = 'mediumDate';
  mode!: 'date' | 'dateRange';

  display: boolean = false;

  ngOnInit(): void {
    if (this.selected instanceof DateRange) {
      this.mode = 'dateRange';
    } else {
      this.mode = 'date';
    }
  }

  onModelChange(date: Date) {
    if (this.mode === 'dateRange') {
      if (this.selected && this.selected.start && !this.selected.end && this.selected.start < date) {
        this.selected = new DateRange(this.selected.start, date);
      } else {
        this.selected = new DateRange(date, null);
      }
    } else {
      this.selected = date;
    }

    this.selectedChange.emit(this.selected);
  }
}
