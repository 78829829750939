<article id="dialog-app-install-warning">
  <header class="admin-header-2">
    <img src="assets/icon/warning_red.svg" />
    Did you just install the app?
  </header>
  <section class="app-install-warning-content">
    If you've installed the app, <strong>you must sign out and then sign back in</strong> to ensure the admin panel functions correctly. Click the 'Yes, I installed the app' button
    below to sign out.
  </section>
  <section class="flex-wrapper">
    <div class="btn-white" (click)="onClickCancel()">No, I didn’t install the app</div>
    <div class="btn-black" (click)="onClickSignOut()">Yes, I installed the app</div>
  </section>
</article>
