import { Component, Inject, OnInit, Optional, SkipSelf } from '@angular/core';
import { KeyValue } from '@angular/common';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AliexpressSettingCountry, ReviewAppPlatform, ReviewImportLog, ReviewSettingInput } from 'src/app/models/shop/review/options/review-import.model';
import { Product } from 'src/app/models/product/product.model';
import { AmazonTranslateUnableCountries } from 'src/app/models/review/review.model';
import { AdminService } from 'src/app/service/admin.service';
import { BackendService } from 'src/app/service/backend.service';
import { BillingService } from 'src/app/service/billing.service';
import { ImportService } from '../import.service';
import { inOutAnimation } from 'src/app/admin/animations';
import { urlRegexp } from 'src/app/shared/regexp/shared-regexp-url';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-review-import-migration',
  templateUrl: './dialog-review-import-migration.component.html',
  styleUrls: ['./dialog-review-import-migration.component.less'],
  animations: [inOutAnimation],
})
export class DialogReviewImportMigrationComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DialogReviewImportMigrationComponent>,
    private backendService: BackendService,
    private adminService: AdminService,
    public reviewImportService: ImportService,
    @Optional() @SkipSelf() public billingService: BillingService,
    @Inject(MAT_DIALOG_DATA) public data: { product: Product; import_platform: ReviewAppPlatform },
  ) {}

  ngOnInit(): void {
    this.inputUrl = new UntypedFormControl('', [Validators.required, Validators.pattern(urlRegexp)]);

    this.initCountryList();
    this.getImportSetting();
    this.makeCountryList();
  }

  inputUrl!: UntypedFormControl;
  verifiedUrls: string[] = [];
  translate: boolean = true;
  verifying: boolean = false;
  reviewSettingInput!: ReviewSettingInput;
  countries: { [key: string]: boolean } = {};
  verifyError: boolean = false;
  verifyDetails: string = '';
  verifyUrlCheck: boolean = true;

  onNoClick() {
    this.dialogRef.close(false);
  }

  original = (a: KeyValue<string, boolean>, b: KeyValue<string, boolean>): number => {
    return 0;
  };

  initCountryList() {
    for (let i in this.reviewImportService.AliexpressSettingCountry) {
      var isValueProperty = Number(i) >= 0;
      if (!isValueProperty) {
        this.countries[i] = false;
      }
    }
  }

  makeCountryList() {
    let accessCountry: number[] = [];
    for (let key in this.countries) {
      if (this.countries[key]) {
        accessCountry.push(this.reviewImportService.AliexpressSettingCountry[key as keyof typeof AliexpressSettingCountry]);
      }
    }

    return accessCountry;
  }

  isInputUrlValid(): boolean {
    if (this.inputUrl.value.length == 0) {
      this.verifyDetails = '';
      this.verifyError = false;
    }
    if (this.inputUrl.valid) return true;

    return false;
  }

  verifyUrl() {
    if (!this.isInputUrlValid()) this.verifyUrlCheck = false;
    if (this.isInputUrlValid()) {
      this.verifyUrlCheck = true;
      let url = 'reviews/import/verify';

      this.verifying = true;
      this.verifyError = false;
      this.verifyDetails = '';
      this.translate = true;
      AmazonTranslateUnableCountries.forEach((country) => {
        if (this.inputUrl.value.includes(country)) {
          this.translate = false;
          this.reviewSettingInput.is_translation = false;
        }
      });
      this.backendService.select(url, { url: this.inputUrl.value, platform: this.data.import_platform }).subscribe({
        next: (response) => {
          if (response.body as boolean) this.verifiedUrls.push(this.inputUrl.value);

          this.verifying = false;
        },
        error: (error) => {
          this.verifying = false;
          this.verifyError = true;
          this.verifyDetails = error.error.detail;
        },
      });
    }
  }

  checkVerifiedUrl() {
    const l = this.verifiedUrls.filter((item) => item == this.inputUrl.value);
    return l.length > 0;
  }

  onKeyUp(limit: number) {
    if (this.reviewSettingInput.number_of_import >= limit) {
      this.reviewSettingInput.number_of_import = limit;
    }
  }

  getImportSetting() {
    let url = 'reviews/import/product/option/';
    this.backendService.select(url, { shop_access_code: this.adminService.getShopAccessCode(), import_platform: this.data.import_platform }).subscribe((response) => {
      const body = response.body as ReviewSettingInput;
      this.reviewSettingInput = body;
      if (!this.reviewSettingInput.after_date) this.reviewSettingInput.after_date = new Date();

      for (let country of this.reviewSettingInput.access_country) {
        this.countries[AliexpressSettingCountry[country]] = true;
      }
    });
  }

  migrateReview() {
    if (this.checkVerifiedUrl()) {
      let url = `reviews/import/product/${this.data.product.id}`;
      this.reviewSettingInput.access_country = this.makeCountryList();

      this.backendService
        .create(url, this.reviewSettingInput, {
          url: this.inputUrl.value,
          platform: this.data.import_platform,
          shop_access_code: this.adminService.getShopAccessCode(),
          id: this.data.product.id,
          admin_email: this.adminService.ShopAdminAccount.account_id,
          author: this.adminService.ShopAdminAccount.user_name,
        })
        .subscribe((response) => {
          this.dialogRef.close(response.body as ReviewImportLog);
        });
    } else {
      this.backendService.openSnackBar('Not verified URL');
    }
  }

  isAllChecked(c?: any) {
    if (c) {
      this.countries[c.key] = c.value;
    } else {
      for (let i in this.countries) {
        this.reviewSettingInput.is_all ? (this.countries[i] = true) : (this.countries[i] = false);
      }
    }
  }
}
