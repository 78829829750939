<article id="shop-info">
  <header *ngIf="option == superAdminService.SuperAdminDetailOption.SHOP_INFO; else infoStatus" class="shop-info-header" style="margin-bottom: 13px">쇼핑몰 정보</header>
  <ng-template #infoStatus>
    <header class="shop-info-header" style="margin-bottom: 13px">상태</header>
  </ng-template>

  <body class="shop-info-content" *ngIf="option == superAdminService.SuperAdminDetailOption.SHOP_INFO; else appStatus">
    <div class="basic-info">
      <div class="small-header">
        <span>기본 정보</span>
        <button *ngIf="basicInfoEdit == false; else basicModify" class="button" (click)="onBasicInfoEdit()">수정</button>
        <ng-template #basicModify>
          <button class="button" (click)="onBasicInfoSubmit()" (click)="onBasicInfoEdit()">저장</button>
        </ng-template>
      </div>
      <mat-divider></mat-divider>
      <div class="basic-info-row">
        <span class="key">쇼핑몰명</span>
        <span class="values" *ngIf="basicInfoEdit == false; else basicEdit_1">{{ shopInfo?.shop_name }}</span>
        <ng-template #basicEdit_1
          ><span class="values"><textarea class="basic-info-textarea" [(ngModel)]="shopInfo!.shop_name"></textarea></span
        ></ng-template>

        <span class="key">회사명</span>
        <span class="values" *ngIf="basicInfoEdit == false; else basicEdit_2">{{ shopInfo?.company_name }}</span>
        <ng-template #basicEdit_2
          ><span class="values"><textarea class="basic-info-textarea" [(ngModel)]="shopInfo!.company_name"></textarea></span
        ></ng-template>

        <span class="key">산업</span>
        <span class="values" *ngIf="basicInfoEdit == false; else basicEdit_3">{{ shopInfo?.shop_detail?.industry }}</span>
        <ng-template #basicEdit_3
          ><span class="values">
            <mat-select [formControl]="industryControl" required>
              <mat-option *ngFor="let industry of industries" [value]="industry">
                {{ industry }}
              </mat-option>
            </mat-select>
          </span></ng-template
        >
        <!-- <ng-template #basicEdit_3><span class="values">
                <input [(ngModel)]="shopInfo?.shop_detail!.industry"></span></ng-template>
            -->
        <span class="key">국가</span>
        <span class="values" *ngIf="basicInfoEdit == false; else basicEdit_4">{{ countries[countryControl.value] }}</span>
        <ng-template #basicEdit_4
          ><span class="values">
            <mat-select [formControl]="countryControl" required>
              <mat-option *ngFor="let country of countries | keyvalue" [value]="country.key">
                {{ country.value }}
              </mat-option>
            </mat-select>
          </span></ng-template
        >
      </div>
    </div>

    <div class="basic-info">
      <div class="small-header">
        <span>담당자별 정보</span>
        <button *ngIf="adminInfoEdit == false; else adminModify" class="button" (click)="onAdminInfoEdit()">수정</button>
        <ng-template #adminModify>
          <button class="button" (click)="onAdminInfoSubmit()" (click)="onAdminInfoEdit()">저장</button>
        </ng-template>
      </div>
      <div>
        <mat-divider></mat-divider>
        <div *ngIf="adminInfoEdit == false; else adminEdit">
          <div class="admin-info-row" *ngFor="let account of shopInfo?.super_admin_shop_contact; let i = index">
            <span class="key">담당자{{ i + 1 }}</span>
            <span class="values">{{ account.first_name }} {{ account.last_name }}</span>
            <span class="values"
              ><mat-icon *ngIf="account.accepts_marketing" class="email-verified-icon">check_circle</mat-icon>{{ account.account_id }}
              <mat-icon style="font-size: 10px" (click)="copyEmail(account.account_id)">file_copy</mat-icon></span
            >
            <span class="values">{{ account.memo }}</span>
          </div>
        </div>
        <ng-template #adminEdit>
          <div class="admin-info-row" *ngFor="let account of shopInfo?.super_admin_shop_contact; let i = index">
            <span class="key">담당자{{ i + 1 }}</span>
            <span class="values"><input [(ngModel)]="account.first_name" placeholder="담당자 성" /> <input [(ngModel)]="account.last_name" placeholder="담당자 이름" /></span>

            <span class="values"><input [(ngModel)]="account.account_id" placeholder="이메일" /></span>
            <span class="values"><textarea style="width: 300px" [(ngModel)]="account.memo" placeholder="메모"></textarea></span>
            <a (click)="onAdminDelete(account.id)"><mat-icon style="padding: 8px">close</mat-icon></a>
          </div>
          <div class="admin-info-row">
            <span class="key">담당자 +</span>
            <span class="values">
              <input [(ngModel)]="shopAdminContact.first_name" placeholder="담당자 성" /><input [(ngModel)]="shopAdminContact.last_name" placeholder="담당자 이름"
            /></span>
            <span class="values"><input [(ngModel)]="shopAdminContact.account_id" placeholder="이메일" /></span>
            <span class="values"><textarea style="width: 300px" [(ngModel)]="shopAdminContact.memo" placeholder="메모"></textarea></span>
            <button class="button-admin-create" (click)="onAdminCreate()">생성</button>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="shopify-info">
      <div class="small-header">쇼피파이 정보</div>
      <mat-divider></mat-divider>
      <div class="shopify-info-row">
        <span class="key">쇼핑몰명</span>
        <span class="values">{{ shopInfo?.shop_detail?.store_name }}</span>
      </div>
      <div class="shopify-info-row">
        <span class="key">대표자명</span>
        <span class="values">{{ shopInfo?.shop_detail?.shop_owner }}</span>
      </div>
      <div class="shopify-info-row">
        <span class="key">이메일</span>
        <span class="values">{{ shopInfo?.shop_detail?.email }}</span>
      </div>
      <div class="shopify-info-row">
        <span class="key">전화번호</span>
        <span class="values">{{ shopInfo?.shop_detail?.phone }}</span>
      </div>
      <div class="shopify-info-row">
        <span class="key">주소</span>
        <span class="values">{{ shopInfo?.shop_detail?.address }}</span>
      </div>
      <div class="shopify-info-row">
        <span class="key">고객센터</span>
        <span class="values">{{ shopInfo?.shop_detail?.customer_email }}</span>
      </div>
    </div>
  </body>

  <ng-template #appStatus class="shop_status">
    <body class="shop_status_content">
      <div class="small-header">앱상태</div>
      <div class="app-status-body">
        <div class="app-status-colunm"><span class="app-status">Time(한국)</span><span class="app-status">Time(쇼핑몰)</span><span class="app-status">서비스 상태</span></div>

        <mat-divider></mat-divider>

        <div class="app-status-row">
          <div class="app-status-time" *ngIf="shopInfo?.shop_initial_info?.installed_at">
            <div class="values">{{ shopInfo?.shop_initial_info?.installed_at | date : 'yyyy-MM-dd HH:mm' }}</div>
            <div class="values">{{ shopInfo?.shop_initial_info?.installed_at | date : 'yyyy-MM-dd HH:mm' : 'Z' }}</div>
            <div class="values">앱 설치</div>
          </div>
          <div class="app-status-time" *ngIf="shopInfo?.shop_initial_info?.published_at">
            <div class="values">{{ shopInfo?.shop_initial_info?.published_at | date : 'yyyy-MM-dd HH:mm' }}</div>
            <div class="values">{{ shopInfo?.shop_initial_info?.published_at | date : 'yyyy-MM-dd HH:mm' : 'Z' }}</div>
            <div class="values">서비스 개시</div>
          </div>
          <div class="app-status-time" *ngIf="shopInfo?.shop_initial_info?.expired_at">
            <div class="values">{{ shopInfo?.shop_initial_info?.expired_at | date : 'yyyy-MM-dd HH:mm' }}</div>
            <div class="values">{{ shopInfo?.shop_initial_info?.expired_at | date : 'yyyy-MM-dd HH:mm' : 'Z' }}</div>
            <div class="values">앱 삭제</div>
          </div>
        </div>
      </div>
    </body>
  </ng-template>
</article>
