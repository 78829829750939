<article id="admin-float-alert" cdkDrag cdkDragBoundary=".admin-body">
  <ng-container *ngFor="let reviewImportLog of floatService.reviewImportLogList">
    <section class="admin-float-alert-item" *ngIf="!reviewImportLog.closed" (click)="reviewImportLog.closed = true">

      <header class="admin-float-header">
        {{reviewImportService.TransferStatus[reviewImportLog.progress_status]}}
        Uploading File
      </header>
      <summary class="admin-float-description">
        This may take several minutes.
      </summary>
    </section>
  </ng-container>
</article>

<app-admin-launcher-support>
</app-admin-launcher-support>