"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.setLineItemProperties = exports.removeLineItemDiscount = exports.setLineItemDiscount = exports.removeLineItem = exports.updateLineItem = exports.addLineItem = exports.removeProperties = exports.setProperties = exports.setDiscount = exports.updateCustomerAddress = exports.addCustomerAddress = exports.setCustomer = exports.update = exports.fetch = exports.Cart = exports.Action = void 0;
var Cart_1 = require("@shopify/app-bridge-core/actions/Cart");
Object.defineProperty(exports, "Action", { enumerable: true, get: function () { return Cart_1.Action; } });
Object.defineProperty(exports, "Cart", { enumerable: true, get: function () { return Cart_1.Cart; } });
Object.defineProperty(exports, "fetch", { enumerable: true, get: function () { return Cart_1.fetch; } });
Object.defineProperty(exports, "update", { enumerable: true, get: function () { return Cart_1.update; } });
Object.defineProperty(exports, "setCustomer", { enumerable: true, get: function () { return Cart_1.setCustomer; } });
Object.defineProperty(exports, "addCustomerAddress", { enumerable: true, get: function () { return Cart_1.addCustomerAddress; } });
Object.defineProperty(exports, "updateCustomerAddress", { enumerable: true, get: function () { return Cart_1.updateCustomerAddress; } });
Object.defineProperty(exports, "setDiscount", { enumerable: true, get: function () { return Cart_1.setDiscount; } });
Object.defineProperty(exports, "setProperties", { enumerable: true, get: function () { return Cart_1.setProperties; } });
Object.defineProperty(exports, "removeProperties", { enumerable: true, get: function () { return Cart_1.removeProperties; } });
Object.defineProperty(exports, "addLineItem", { enumerable: true, get: function () { return Cart_1.addLineItem; } });
Object.defineProperty(exports, "updateLineItem", { enumerable: true, get: function () { return Cart_1.updateLineItem; } });
Object.defineProperty(exports, "removeLineItem", { enumerable: true, get: function () { return Cart_1.removeLineItem; } });
Object.defineProperty(exports, "setLineItemDiscount", { enumerable: true, get: function () { return Cart_1.setLineItemDiscount; } });
Object.defineProperty(exports, "removeLineItemDiscount", { enumerable: true, get: function () { return Cart_1.removeLineItemDiscount; } });
Object.defineProperty(exports, "setLineItemProperties", { enumerable: true, get: function () { return Cart_1.setLineItemProperties; } });
function create(app, options) {
    return new Cart_1.Cart(app, options);
}
exports.create = create;
