import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-widget-create',
  standalone: true,
  imports: [],
  templateUrl: './dialog-widget-create.component.html',
  styleUrl: './dialog-widget-create.component.less',
})
export class DialogWidgetCreateComponent {
  constructor(private dialogRef: MatDialogRef<DialogWidgetCreateComponent>) {}
  onclose = () => this.dialogRef.close();
}
