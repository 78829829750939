import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ShopDetail } from 'src/app/models/shop/profile/store-detail.model';
import { INDUSTRY } from '../preset/industry';

@Component({
  selector: 'store-setting-input',
  templateUrl: './store-setting-input.component.html',
  styleUrls: ['./store-setting-input.component.less'],
})
export class StoreSettingInputComponent implements OnInit {
  @Input() shopDetail!: ShopDetail;
  @Input() type?: string;
  @Output() shopDetailChange: EventEmitter<ShopDetail> = new EventEmitter();
  @Output() fileEvent = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    this.image = this.shopDetail.store_logo;
  }

  industries: string[] = INDUSTRY;
  fileName?: string;
  image?: any;

  onChangeShopDetail() {
    this.shopDetailChange.emit(this.shopDetail);
  }

  onSelected(value: string) {
    let slice = value.indexOf(' ');
    this.shopDetail.industry = value.slice(slice).trim();
  }

  onFileChanged(event: any) {
    this.shopDetail.file = event.target.files[0];
    this.fileName = this.shopDetail.file?.name;

    this.fileEvent.emit(this.shopDetail.file);

    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.image = event.target?.result;
      };
    }
  }
}
