<article #article id="popup-general-settings" style="max-width: 1200px" *ngIf="salesPopupCommon">
  <section #positionSetting class="gray-border-wrapper">
    <header class="admin-header-4">Popup Position</header>
    <summary class="admin-content-description">Set the position where the Popup will be displayed on both desktop and mobile screens</summary>
    <div class="split-bar"></div>

    <section style="margin: 20px 0">
      <header class="admin-header-4" style="gap: 10px; margin-bottom: 20px">
        <img src="assets/icon/dot_black.svg" />
        Web
      </header>

      <section class="flex-wrapper" style="gap: 100px; justify-content: flex-start">
        <section class="settings-content-wrapper">
          <div class="settings-content basis">
            <div class="settings-name flex-wrapper">
              Margin-bottom (%) <span class="settings-slider-value">{{ salesPopupCommon.web_margin_bottom }}</span>
            </div>
            <mat-slider class="settings-slider" step="1" min="0" max="100">
              <input matSliderThumb #bottomPosition [(ngModel)]="salesPopupCommon.web_margin_bottom" (input)="changePosition(bottomPosition, 'bottomPc')" />
            </mat-slider>
          </div>
          <div class="settings-content basis">
            <div class="settings-name flex-wrapper">
              Margin-left (%) <span class="settings-slider-value">{{ salesPopupCommon.web_margin_left }}</span>
            </div>
            <mat-slider class="settings-slider" step="1" min="0" max="100">
              <input matSliderThumb #leftPosition [(ngModel)]="salesPopupCommon.web_margin_left" (input)="changePosition(leftPosition, 'left')" />
            </mat-slider>
          </div>
        </section>

        <section class="settings-preview-pc">
          <img
            [ngStyle]="{
              bottom: salesPopupCommon.web_margin_bottom + '%',
              left: salesPopupCommon.web_margin_left + '%',
              transform: 'translate(' + -salesPopupCommon.web_margin_left + '%,' + salesPopupCommon.web_margin_bottom + '%)'
            }"
            #previewPc
            src="assets/image/marketing/preview-icon.png"
          />
        </section>
      </section>
    </section>

    <div class="split-bar"></div>

    <section style="margin-top: 20px">
      <header class="admin-header-4" style="gap: 10px; margin-bottom: 20px">
        <img src="assets/icon/dot_black.svg" />
        Mobile
      </header>

      <section class="flex-wrapper" style="gap: 100px; justify-content: flex-start">
        <section class="settings-content-wrapper">
          <div class="settings-content basis">
            <div class="settings-name flex-wrapper">
              Margin-bottom (%) <span class="settings-slider-value">{{ salesPopupCommon.mobile_margin_bottom }}</span>
            </div>

            <mat-slider class="settings-slider" step="1" min="0" max="100">
              <input matSliderThumb #bottomPositionMo [(ngModel)]="salesPopupCommon.mobile_margin_bottom" (input)="changePosition(bottomPositionMo, 'bottomMo')" />
            </mat-slider>
          </div>
        </section>
        <section class="settings-preview-mo">
          <img
            [ngStyle]="{ bottom: salesPopupCommon.mobile_margin_bottom + '%', left: '50%', transform: 'translate(-50%,' + salesPopupCommon.mobile_margin_bottom + '%)' }"
            #previewMo
            src="assets/image/marketing/preview-icon.png"
          />
        </section>
      </section>
    </section>
  </section>

  <section #anonymizerSetting class="gray-border-wrapper">
    <section class="admin-disable-cover" *ngIf="billingService.shopBilling?.plan == billingService.ShopBillingPlan.FREE">
      <custom-tooltip iconSrc="assets/icon/diamond.svg" xPosition="center" yPosition="center">
        This feature is exclusive to Premium users. <a class="hyper-link-a" [routerLink]="['/admin/settings']" [state]="{ tabType: 'subscription' }"> Upgrade now!</a>
      </custom-tooltip>
    </section>

    <header class="admin-header-4">Anonymizer Settings</header>
    <summary class="admin-content-description">Choose to display first name or anonymize pops.</summary>

    <div class="split-bar" style="margin-bottom: 20px"></div>

    <div class="flex-wrapper" style="gap: 20px; width: fit-content; margin-bottom: 16px">
      <header class="admin-header-4" style="gap: 6px">
        Anonymize first names
        <img
          style="width: 16px; height: 16px"
          src="assets/icon/info_cool_gray.svg"
          matTooltip="When enabled, first name will be removed
                and be replaced by the fallback below."
        />
      </header>
      <alpha-toggle-btn [(sourceBoolean)]="salesPopupCommon.active_anonymize" [negativeText]="'Off'" [positiveText]="'On'"></alpha-toggle-btn>
    </div>

    <div class="flex-wrapper" style="gap: 48px; width: fit-content">
      <header class="admin-header-4" style="gap: 6px">
        First name fallback
        <img
          style="width: 16px; height: 16px"
          src="assets/icon/info_cool_gray.svg"
          matTooltip='If you leave this field blank,
              "Someone" will replace the first name.'
        />
      </header>
      <input [disabled]="!salesPopupCommon.active_anonymize" [(ngModel)]="salesPopupCommon.first_name_fallback" type="text" class="admin-input" style="width: 280px" />
    </div>
  </section>

  <section #blocklistSetting class="gray-border-wrapper">
    <section class="admin-disable-cover" *ngIf="billingService.shopBilling?.plan == billingService.ShopBillingPlan.FREE">
      <custom-tooltip iconSrc="assets/icon/diamond.svg" xPosition="center" yPosition="center">
        This feature is exclusive to Premium users. <a class="hyper-link-a" [routerLink]="['/admin/settings']" [state]="{ tabType: 'subscription' }"> Upgrade now!</a>
      </custom-tooltip>
    </section>
    <header class="admin-header-4">Product & Collection Blocklist</header>
    <summary class="admin-content-description">Select the products and collections where the Sales Popup will not be displayed</summary>

    <div class="split-bar" style="margin-bottom: 20px"></div>

    <div class="flex-wrapper" style="justify-content: flex-start; margin-bottom: 16px; gap: 42px">
      <header class="admin-header-4" style="width: 158px">Product Blocklist</header>
      <div class="btn-outline" (click)="openSelectBlocklist(salesPopupCommon, SeparationType.PRODUCT)">
        <span *ngIf="!salesPopupCommon.product_blocklist.length; else productLength">+ Select Product</span>
        <ng-template #productLength>{{ salesPopupCommon.product_blocklist.length }} item Selected</ng-template>
      </div>
    </div>

    <div class="flex-wrapper" style="justify-content: flex-start; gap: 42px">
      <header class="admin-header-4" style="width: 158px">Collection Blocklist</header>
      <div class="btn-outline" (click)="openSelectBlocklist(salesPopupCommon, SeparationType.COLLECTION)">
        <span *ngIf="!salesPopupCommon.collection_blocklist.length; else collectionLength">+ Select Collection</span>
        <ng-template #collectionLength>{{ salesPopupCommon.collection_blocklist.length }} item Selected</ng-template>
      </div>
    </div>
  </section>
</article>
