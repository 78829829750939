import { Component, Inject, OnInit, Optional, SkipSelf, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SuperAdminService } from 'src/app/service/super-admin.service';
import { INDUSTRY } from 'src/app/shared/preset/industry';

@Component({
  selector: 'app-industry-choice',
  templateUrl: './industry-choice.component.html',
  styleUrls: ['./industry-choice.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class IndustryChoiceComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<IndustryChoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: String[],
    @Optional() @SkipSelf() public superAdminService: SuperAdminService,
  ) {}
  industryCopy = this.data;

  industries = INDUSTRY;
  ngOnInit(): void {}
  isSelected(industry: String) {
    if (this.data.includes(industry)) {
      return true;
    } else return false;
  }
  appendData(industry: String) {
    if (this.data.includes(industry)) {
      const index = this.data.indexOf(industry);
      this.data.splice(index, 1);
    } else this.data.push(industry);
  }
  onSave() {
    this.dialogRef.close();
  }
  onCancel() {
    this.dialogRef.close(this.industryCopy);
  }
}
