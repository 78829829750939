import { Component, Input, OnInit } from '@angular/core';
import { inOutAnimation } from 'src/app/admin/animations';

@Component({
  selector: 'custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.less'],
  animations: [inOutAnimation],
})
export class CustomTooltipComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.setPosition()
  }

  setPosition() {
    if (this.xPosition == 'after') {
      this.right = 0
    } else if (this.xPosition == 'before') {
      this.left = 0
    } else {
      this.left = 50
      this.translateX = -50
    }

    if (this.yPosition == 'above') {
      this.bottom = 100
    } else if (this.yPosition == 'below') {
      this.top = 100
    } else {
      this.top = 50
      this.translateY = -50
    }

  }

  @Input() iconSrc?: string = undefined // 아이콘 이미지 주소
  @Input() iconBackground: boolean = true // 아이콘 배경 원 출력 여부
  @Input() contentWidth: string = '230px' // 텍스트 영역 너비
  @Input() textAlign: string = 'center' // 텍스트 영역 정렬

  @Input() xPosition: 'before' | 'center' | 'after' = 'center' // X축 시작 위치. before : 왼쪽에서 시작, after: 가운데에서 시작
  @Input() yPosition: 'above' | 'center' | 'below' = 'below' // Y축 시작 위치. above : 위쪽에 위치, below: 아래쪽에 위치

  @Input() marginTop: number = 0
  @Input() marginBottom: number = 0

  @Input() backgroundColor: string = '#000000CC'
  @Input() color: string = 'white'
  top: number = -1
  right: number = -1
  bottom: number = -1
  left: number = -1

  translateX = 0
  translateY = 0
}
