import { Shop } from "../shop/shop.model"


export enum ShopBillingPlan {
    FREE = "Free",
    PREMIUM = "Premium"
}


export enum BillingInterval {
    MONTHLY = "EVERY_30_DAYS",
    YEARLY = "ANNUAL"
}


export interface ShopBilling {
    id: number
    shop_id: number
    shop: Shop
    title: string
    plan: ShopBillingPlan
    price: number
    platform_id?: number
    interval: BillingInterval
    trial_expired_at: Date
}
