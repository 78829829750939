import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-template-delete',
  templateUrl: './dialog-template-delete.component.html',
  styleUrls: ['./dialog-template-delete.component.less'],
})
export class DialogTemplateDeleteComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<DialogTemplateDeleteComponent>) {}

  ngOnInit(): void {}

  onConfirm() {
    this.dialogRef.close(true);
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
