import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OnboardingService } from '../../onboarding/onboarding.service';
import { AlphaApps } from 'src/app/models/shop/profile/store-detail.model';

@Component({
  selector: 'app-dialog-start-list-designer',
  templateUrl: './dialog-start-list-designer.component.html',
  styleUrl: './dialog-start-list-designer.component.less',
})
export class DialogStartListDesignerComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public productsAvailable: boolean,
    private dialogRef: MatDialogRef<DialogStartListDesignerComponent>,
    private onboardingService: OnboardingService,
  ) {}

  ngOnInit(): void {}

  onCancel() {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.onboardingService.setShopDetailUseApp(AlphaApps.LIST_DESIGNER);
    this.dialogRef.close(true);
  }
}
