import { Component, OnInit, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import { AuthSuperAdminService } from 'src/app/auth/auth-super-admin.service';
import { SuperAdminService } from 'src/app/service/super-admin.service';


@Component({
  selector: 'app-left-navigation-bar',
  templateUrl: './left-navigation-bar.component.html',
  styleUrls: ['./left-navigation-bar.component.less'],
})
export class LeftNavigationBarComponent implements OnInit {

  constructor(
    @Optional() @SkipSelf() public superadminservice: SuperAdminService,
    private authSuperAdminService: AuthSuperAdminService,
    private router: Router,
  ) { }

  ngOnInit(): void {

  }
  toShops() {
    this.router.navigate(['super', 'shops'])
  }
  toPosts() {
    this.router.navigate(['super', 'posts'])
  }
  logout() {
    this.authSuperAdminService.superAdminLogout()
    this.router.navigate(['super', 'login'])
  }
  toAccounts() {

    this.router.navigate(['super', 'accounts'])
  }

}
