import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import SwiperCore, { Autoplay, EffectFade, Pagination } from 'swiper';
import { SwiperOptions } from 'swiper';
import { fadeAnimation } from '../animations';
import { LandingService } from '../landing.service';

SwiperCore.use([Autoplay, Pagination, EffectFade]);
@Component({
  selector: 'app-landing-collect',
  templateUrl: './landing-collect.component.html',
  styleUrls: ['./landing-collect.component.less', '../home.component.less'],
  animations: [fadeAnimation],
})
export class LandingCollectComponent implements OnInit {
  constructor(public landingService: LandingService, private router: Router) {
    this.landingService.stopLoop();
    this.landingService.isPause = false;
    this.landingService.requestType = this.requestTypeData;
  }

  ngOnInit(): void {
    this.landingService.nextPrev();
    if (this.landingService.mobile)
      this.landingService.requestType![3].title = 'Banner Request';
  }

  idx: string = 'collect';

  ngAfterViewInit(): void {
    let text = $('.textBox');
    this.landingService.itemBox = text;

    if (
      this.landingService.itemBox.length > 0 &&
      !this.landingService.isPause
    ) {
      this.landingService.startLoop(this.landingService.requestType);
    }
  }

  ngOnDestroy() {
    this.landingService.requestType = [];
  }

  textBox: any;

  requestTypeData = [
    {
      title: 'Email Request',
      image: 'assets/image/landing/collect-request-01.png',
      _isClick: true,
    },
    {
      title: 'SMS Request',
      image: 'assets/image/landing/collect-request-02.png',
    },
    {
      title: 'Web Push Request',
      image: 'assets/image/landing/collect-request-03.png',
    },
    {
      title: 'Revisit Banner Request',
      image: 'assets/image/landing/collect-request-04.png',
    },
  ];

  joyfulConfig: SwiperOptions = {
    slidesPerView: 1,
    pagination: {
      clickable: true,
      renderBullet: function (index: number, className: any) {
        let joyful = [
          `<img src='assets/icon/landing-write-review-gray.svg' /><span>Write a Review Page</span>`,
          `<img src='assets/icon/landing-write-mypage-gray.svg' /><span>My Reviews</span>`,
          `<img src='assets/icon/landing-write-coupon-gray.svg' /><span>My Coupons</span>`,
        ];

        return (
          '<div class="' +
          className +
          '"><span class="joyful-pagination">' +
          joyful[index] +
          '</span></div>'
        );
      },
    },
  };

  textSwiperConfig: SwiperOptions = {
    direction: 'vertical',
    slidesPerView: 1,
    autoplay: { delay: 1000 },
    speed: 1200,
    loop: true,
  };
}
