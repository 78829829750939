import { Injectable } from '@angular/core';
import { AppStatusType, CountryEnum, SuperAdminDetailOption, SuperAdminPermissionGroup, SuperAdminPostCategory } from '../models/super-admin/super-admin.shops.enums';
import { POSTCATEGORY, PostViewType, SuperAdminCurrentUser } from '../models/super-admin/super-admin.shops.model';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminService {

  constructor(
  ) {
  }
  superAdminAccessToken = 'super_admin_access_token';

  currentUser='current_user';

  getSuperAdminAccessToken() {
    return sessionStorage.getItem(this.superAdminAccessToken) as any as string;
  }

  setSuperAdminAccessToken(superAdminAccessToken: string) {
    sessionStorage.setItem(this.superAdminAccessToken, superAdminAccessToken);
  }

  set SuperAdminAccessToken(superAdminAccessToken: string) {
    sessionStorage.setItem(this.superAdminAccessToken, superAdminAccessToken);
  }

  get SuperAdminAccessToken() {
    return sessionStorage.getItem(this.superAdminAccessToken)!;
  }

  clearAccessCode() {
    sessionStorage.removeItem(this.superAdminAccessToken);
  }

  get AppStatusType(): typeof AppStatusType {
    return AppStatusType
  }
  get CountryEnum(): typeof CountryEnum {
    return CountryEnum
  }
  get PostViewType(): typeof PostViewType{
    return PostViewType
  }
  get SuperAdminDetailOption(): typeof SuperAdminDetailOption{
    return SuperAdminDetailOption
  }
  get SuperAdminPermissionGroup(): typeof SuperAdminPermissionGroup{
    return SuperAdminPermissionGroup
  }
  get SuperAdminPostCategory(): typeof SuperAdminPostCategory{
    return SuperAdminPostCategory
  }
  
  get CurrentUser(): SuperAdminCurrentUser{
    return JSON.parse(String(sessionStorage.getItem(this.currentUser))) as SuperAdminCurrentUser;
  }
  set CurrentUser(current_user: SuperAdminCurrentUser){
    sessionStorage.setItem(this.currentUser, JSON.stringify(current_user));
  }
  
  get SuperAdminPostCategoryList(): typeof POSTCATEGORY{
    return POSTCATEGORY
  }

}
