import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Messaging, MessagingEmailTemplate, MessagingSettingType, MessagingSettingTypeDetail, MessagingWebPush } from 'src/app/models/shop/review/options/messaging';
import { BackendService } from 'src/app/service/backend.service';
import { ShopMessagingOption } from 'src/app/models/settings/shop-messaging-option.model';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AdminService } from 'src/app/service/admin.service';
import { GenericService } from 'src/app/service/generic.service';
import { ShopService } from 'src/app/service/shop.service';
import { ContactInformation } from 'src/app/models/shop/profile/contact-information.model';
import { DialogTemplateDeleteComponent } from '../admin/dialog/dialog-template-delete/dialog-template-delete.component';
import { cloneDeep, isEqual } from 'lodash';
import { TouchpointsNotificationService } from '../admin/product-reviews/touchpoints/touchpoints-notification/touchpoints-notification.service';
import { MatDialog } from '@angular/material/dialog';
@Injectable({
  providedIn: 'root',
})
export class SettingMessagingService {
  constructor(
    private backendService: BackendService,
    private genericService: GenericService,
    private shopService: ShopService,
    private adminService: AdminService,
    private router: Router,
    private dialog: MatDialog,
    private notificationService: TouchpointsNotificationService,
  ) {
    // this.getShopMessagingOption();
    this.getContactInfomation();
  }

  contactInfomation!: ContactInformation;

  messagingList: Messaging[] = [];
  selectedMessaging: Messaging[] = [];
  originSelectedMessaging: Messaging[] = [];

  shopMessagingOption: ShopMessagingOption = new ShopMessagingOption();

  firstReviewRequest?: any;
  reviewReminder?: any;
  mediaReminder?: any;
  longtermReviewRequest?: any;

  verificationEmail: any;
  nonmemberBenefitApplyInfo: any;
  benefitInfo: any;
  reviewReplyInfo: any;

  notificationList: Messaging[] = [];
  selectedNotification: Messaging[] = [];

  successMessage = 'Succeed';

  sendTimeList: number[] = [8, 13, 19, 21];
  ratingList: number[] = [1, 2, 3, 4, 5];

  public get messagingSettingType(): typeof MessagingSettingType {
    return MessagingSettingType;
  }

  public get messagingSettingTypeDetail(): typeof MessagingSettingTypeDetail {
    return MessagingSettingTypeDetail;
  }

  getContactInfomation() {
    this.shopService
      .getShopContactInformation({
        shop_access_code: this.adminService.getShopAccessCode(),
      })
      .subscribe((response) => {
        this.contactInfomation = response.body;
      });
  }

  getShopMessagingOption() {
    let url = 'messaging/shop/option';
    this.messagingList = [];
    this.backendService
      .select(url, {
        shop_access_code: this.adminService.getShopAccessCode(),
      })
      .subscribe((response) => {
        if (this.backendService.validateResponse(response)) {
          this.shopMessagingOption = response.body;
        }
      });
  }

  patchShopMessagingOption(shop_messaging_option_id: number | undefined, payload: {} = {}) {
    let url = 'messaging/shop/option/' + shop_messaging_option_id;
    this.backendService.patch(url, payload).subscribe((response) => {
      if (this.backendService.validateResponse(response)) {
        this.shopMessagingOption = response.body;
        this.backendService.openSnackBar(this.successMessage);
      }
    });
  }

  getMessagingList(messaging_type: MessagingSettingType, type_detail?: MessagingSettingTypeDetail) {
    let url = 'messaging/';
    let params: { [key: string]: any } = {
      messaging_type: messaging_type,
      shop_access_code: this.adminService.getShopAccessCode(),
    };
    if (type_detail !== undefined) params.type_detail = type_detail;

    if (type_detail) params.type_detail = type_detail;
    this.backendService.select(url, params).subscribe((response) => {
      if (this.backendService.validateResponse(response)) {
        this.messagingList = [];
        for (let item of response.body) {
          this.messagingList.push(item);
        }
        this.selectedMessaging = this.filterMessaging(messaging_type!, type_detail);
        this.originSelectedMessaging = cloneDeep(this.selectedMessaging);
        if (messaging_type == MessagingSettingType.REVIEW_REQUEST_SETTING) {
          this.firstReviewRequest = this.filterMessaging(MessagingSettingType.REVIEW_REQUEST_SETTING, MessagingSettingTypeDetail.FIRST_REVIEW_REQUEST)[0];
          this.reviewReminder = this.filterMessaging(MessagingSettingType.REVIEW_REQUEST_SETTING, MessagingSettingTypeDetail.REVIEW_REMINDER)[0];
          this.mediaReminder = this.filterMessaging(MessagingSettingType.REVIEW_REQUEST_SETTING, MessagingSettingTypeDetail.MEDIA_REMINDER)[0];
          this.longtermReviewRequest = this.filterMessaging(MessagingSettingType.REVIEW_REQUEST_SETTING, MessagingSettingTypeDetail.LONGTERM_REVIEW_REQUEST)[0];
        }
      }
    });
  }

  checkSettingChange() {
    return isEqual(this.selectedMessaging, this.originSelectedMessaging);
  }

  getMessagingEmailTemplate(messaging: Messaging) {
    let url = 'messaging/email/template/';

    this.backendService
      .select(url, {
        messaging_id: messaging.id,
      })
      .subscribe((response) => {
        if (this.backendService.validateResponse(response)) {
          messaging.messaging_email_templates = response.body;
          this.originSelectedMessaging.find((item) => (item.messaging_email_templates = messaging.messaging_email_templates));
        }
      });
  }

  deleteMessagingEmailTemplate(messaging: Messaging, messagingEmailTemplate: MessagingEmailTemplate) {
    const dialogRef = this.dialog.open(DialogTemplateDeleteComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      if (messagingEmailTemplate.active) {
        this.genericService.openSnackBar('You cannot delete the active template. Please activate the other template and try again.');
      }
      let url = 'messaging/email/template/' + messagingEmailTemplate.id;

      this.backendService.delete(url).subscribe((response) => {
        if (this.backendService.validateResponse(response)) {
          this.backendService.openSnackBar('Deleted');
          this.getMessagingEmailTemplate(messaging);
        }
      });
    });
  }

  getMessagingNotificationList() {
    let url = 'messaging/';
    this.backendService
      .select(url, {
        messaging_type: MessagingSettingType.NOTIFICATION_SETTING,
        shop_access_code: this.adminService.getShopAccessCode(),
      })
      .subscribe((response) => {
        if (this.backendService.validateResponse(response)) {
          this.notificationList = [];
          for (let item of response.body) this.notificationList.push(item);

          this.verificationEmail = this.notificationList.find((notification) => notification.type_detail == MessagingSettingTypeDetail.VERIFICATION_EMAIL);
          this.notificationService.emailForBlockList.emailsForVerification = this.verificationEmail.messaging_email_blacklist.map(({ email }: { email: any }) => email).join(', ');

          this.benefitInfo = this.notificationList.find((notification) => notification.type_detail == MessagingSettingTypeDetail.BENEFIT_INFO_EMAIL);
          this.notificationService.emailForBlockList.emailForReviewReward = this.benefitInfo.messaging_email_blacklist.map(({ email }: { email: any }) => email).join(', ');

          this.reviewReplyInfo = this.notificationList.find((notification) => notification.type_detail == MessagingSettingTypeDetail.REVIEW_REPLY_INFO_EMAIL);
          this.notificationService.emailForBlockList.emailForReviewReply = this.reviewReplyInfo.messaging_email_blacklist.map(({ email }: { email: any }) => email).join(', ');
        }
      });
  }

  getMessaging(messaging_id: number) {
    let url = `messaging/${messaging_id}`;

    this.backendService.select(url).subscribe((response) => {
      if (this.backendService.validateResponse(response)) {
      }
    });
  }

  fromMessagingWebpush(messagingWebPush: MessagingWebPush) {
    let url = 'messaging/webpush/' + messagingWebPush.id;

    let formData = new FormData();

    for (let key in messagingWebPush) {
      // formData.append(key, this.data[key]);
    }

    // formData.append

    this.backendService.form(url).subscribe((event) => {});
  }

  filterMessaging(type: MessagingSettingType, type_detail?: MessagingSettingTypeDetail): Messaging[] {
    if (this.messagingList.length > 0) {
      return this.messagingList.filter((item) => item.type === type && item.type_detail === type_detail);
    }
    return [];
  }

  patchMessaging(messaging: Messaging, payload: {} = {}) {
    let url = `messaging/${messaging.id}?shop_access_code=${this.adminService.getShopAccessCode()}`;

    messaging.use_email = messaging.use_setting_type_detail;

    this.backendService.patch(url, payload).subscribe((response) => {
      if (this.backendService.validateResponse(response)) {
        this.backendService.openSnackBar(this.successMessage);
        this.selectedMessaging[0] = response.body;
        this.originSelectedMessaging = cloneDeep(this.selectedMessaging);
      }
    });
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      // this.fileName = file.name;

      const formData = new FormData();

      formData.append(event.target.id, file);

      let url = 'messaging/webpush/';

      this.backendService.form(url, formData).subscribe((response) => {
        if (this.backendService.validateResponse(response)) {
          this.shopMessagingOption = response.body;
          this.backendService.openSnackBar(this.successMessage);
        }
      });
    }
  }

  redirectEmailTemplate(messaging: Messaging, template?: MessagingEmailTemplate) {
    this.router.navigate(['/admin/product-reviews/email-custom'], {
      state: {
        messagingEmail: messaging,
        messagingSettingType: messaging.type,
        messagingSettingTypeDetail: messaging.type_detail,
        messagingEmailTemplate: template,
      },
    });
  }

  setPanelExpand(expandPanel: MatExpansionPanel, bool: boolean) {
    if (bool && expandPanel.closed) {
      expandPanel.close();
      return;
    }
    if (!bool) {
      expandPanel.open();
      return;
    }
  }

  redirectEmailTemplateCreate(messaging: Messaging, template?: MessagingEmailTemplate) {
    let stateObject = {
      messagingEmail: messaging,
      messagingSettingType: messaging.type,
      messagingSettingTypeDetail: messaging.type_detail,
    };

    this.router.navigate(['/admin/product-reviews/email-custom'], {
      state: stateObject,
    });
  }
}
