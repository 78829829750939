<article *ngIf="aiService.analysisDetail" id="analysis-details" class="setting-article" style="max-width: 1000px; padding-bottom: 20px">
  <div class="flex-wrapper" style="width: 100%">
    <header class="admin-header-1">
      <img onclick="history.back()" src="assets/icon/back_btn_gray.svg" style="cursor: pointer" />
      Detailed Review Analysis
    </header>
    <div
      (click)="onClickImportBtn(aiService.analysisDetail.analysis_log)"
      [ngClass]="aiService.analysisDetail.analysis_log.is_review_saved ? 'btn-white' : 'btn-black'"
      style="width: 145px; height: 36px"
    >
      <ng-container *ngIf="aiService.analysisDetail.analysis_log.is_review_saved; else saved">View Reviews</ng-container>
      <ng-template #saved>Import Reviews</ng-template>
    </div>
  </div>

  <section class="gray-border-wrapper admin-header-4">
    <div class="ellipsis-2">
      {{ aiService.analysisDetail.product_name }}
    </div>
  </section>

  <section class="flex-wrapper" style="gap: 20px">
    <section class="gray-border-wrapper" style="flex: 1; height: 100%">
      <div class="details-contents">
        <div class="title">Review Count</div>
        <div class="content">{{ aiService.analysisDetail.review_count }} reviews</div>
      </div>
      <div class="details-contents">
        <div class="title">Ratings</div>
        <div class="content">★{{ aiService.analysisDetail.rating }}</div>
      </div>
    </section>
    <section class="gray-border-wrapper" style="flex: 1; height: 100%">
      <div class="details-contents">
        <div class="title">Date of Most Recent Review</div>
        <div class="content">{{ aiService.analysisDetail.latest_review_date | date : 'mediumDate' }}</div>
      </div>
      <div class="details-contents">
        <div class="title">Date of Oldest Review</div>
        <div class="content">{{ aiService.analysisDetail.oldest_review_date | date : 'mediumDate' }}</div>
      </div>
    </section>
  </section>

  <section class="gray-border-wrapper summary-container" style="padding: 0">
    <div style="padding: 20px">
      <header class="admin-header-4" style="gap: 8px">
        <img src="assets/icon/magic_wand.svg" />
        Quick Summary
      </header>
      <div class="details-summary">{{ aiService.analysisDetail.paragraph_summary }}</div>
    </div>
  </section>

  <section class="gray-border-wrapper">
    <header class="admin-header-4 flex-wrapper">
      Positive-Negative Ratio
      <div class="details-progress">
        Negative: {{ aiService.analysisDetail.negative_proportion }}%｜Neutral: {{ aiService.neutralProportion }}%｜Positive: {{ aiService.analysisDetail.positive_proportion }}%
      </div>
    </header>

    <div class="details-progress-bar">
      <span *ngIf="aiService.analysisDetail.negative_proportion > 0" [ngStyle]="{ width: aiService.analysisDetail.negative_proportion + '%' }" class="negative">
        <ng-container *ngIf="aiService.analysisDetail.negative_proportion >= 6">Negative</ng-container>
      </span>
      <span *ngIf="aiService.neutralProportion > 0" [ngStyle]="{ width: aiService.neutralProportion + '%' }" class="neutral">
        <ng-container *ngIf="aiService.neutralProportion >= 6">Neutral</ng-container>
      </span>
      <span *ngIf="aiService.analysisDetail.positive_proportion > 0" [ngStyle]="{ width: aiService.analysisDetail.positive_proportion + '%' }" class="positive">
        <ng-container *ngIf="aiService.analysisDetail.positive_proportion >= 6">Positive</ng-container>
      </span>
    </div>
  </section>

  <section class="flex-wrapper" style="gap: 20px; height: 380px">
    <section class="gray-border-wrapper" style="flex: 1; height: 100%">
      <header class="admin-header-4">Keywords Mentioned in Positive Reviews</header>
      <div class="details-scroll">
        <ng-container *ngFor="let positive of aiService.analysisDetail.positive_keyword_list; let i = index">
          <div class="flex-wrapper positive-keyword" style="gap: 10px">
            <div class="flex-wrapper" style="gap: 10px">
              <div class="positive-rank">{{ i + 1 }}</div>
              <div class="positive-text ellipsis-1">{{ positive[0] }}</div>
            </div>
            <div class="positive-count">{{ positive[1] }} times</div>
          </div>
        </ng-container>
      </div>
    </section>
    <section class="gray-border-wrapper" style="flex: 1; height: 100%">
      <header class="admin-header-4">Keywords Mentioned in Negative Reviews</header>
      <div class="details-scroll">
        <ng-container *ngFor="let negative of aiService.analysisDetail.negative_keyword_list; let i = index">
          <div class="flex-wrapper negative-keyword">
            <div class="flex-wrapper" style="gap: 10px">
              <div class="negative-rank">{{ i + 1 }}</div>
              <div class="negative-text ellipsis-1">{{ negative[0] }}</div>
            </div>
            <div class="negative-count">{{ negative[1] }} times</div>
          </div>
        </ng-container>
      </div>
    </section>
  </section>

  <section id="details-experience" onclick="window.open('https://forms.gle/Ze41AZ7HjQ1FC1EGA ')">
    <img src="assets/image/ai/experience_ai.png" class="coming-img" />
  </section>
  <section class="gray-border-wrapper">
    <header class="admin-header-4 flex-wrapper">
      Review Trends Over Time
      <span class="coming">Upcoming</span>
    </header>
    <img src="assets/image/ai/trend_ai.png" class="coming-img" />
  </section>
  <section class="flex-wrapper" style="gap: 20px">
    <div class="gray-border-wrapper" style="flex: 1">
      <header class="admin-header-4 flex-wrapper">
        Suggestions for Marketing Targets
        <span class="coming">Upcoming</span>
      </header>
      <img src="assets/image/ai/marketing_ai_1.png" class="coming-img" />
    </div>
    <div class="gray-border-wrapper" style="flex: 1">
      <header class="admin-header-4 flex-wrapper">
        Suggestions for Marketing Targets
        <span class="coming">Upcoming</span>
      </header>
      <img src="assets/image/ai/marketing_ai_2.png" class="coming-img" />
    </div>
  </section>
</article>
