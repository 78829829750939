<main id="custom-toggle-btn">
  <label class="switch">
    <input type="checkbox" class="setting-content-toggle" [(ngModel)]="sourceBoolean" (ngModelChange)="toggle()"
      [disabled]="disabled">
    <span class="slider" [ngClass]="{'disabled': disabled}"></span>
  </label>
  <div class="toggle-desc toggle-desc-negative" *ngIf="!sourceBoolean && negativeText">
    {{negativeText}}
  </div>
  <div class="toggle-desc toggle-desc-positive" *ngIf="sourceBoolean && positiveText">
    {{positiveText}}
  </div>
  <ng-content class="toggle-desc"></ng-content>
</main>