<article id="dialog-learn-more" class="learn-more">
  <header class="flex-wrapper learn-header">{{ data.header }} <img (click)="closeDialog(false)" src="assets/icon/close_big_gray.svg" /></header>
  <ng-container *ngIf="!data.contentSlider; else contentSlider">
    <section class="learn-image">
      <ng-container *ngIf="data.image.length > 1; else notSwiperBlock">
        <div id="imgSlider" style="position: relative">
          <swiper #swiper [config]="swiperConfig">
            <ng-template swiperSlide *ngFor="let img of data.image">
              <img src="{{ img }}" />
            </ng-template>
          </swiper>
          <img (click)="slideNavigation('prev')" src="assets/icon/arrow-prev-slim.svg" class="learn-prev learn-navigation" />
          <img (click)="slideNavigation('next')" src="assets/icon/arrow-next-slim.svg" class="learn-next learn-navigation" />
        </div>
      </ng-container>
      <ng-template #notSwiperBlock>
        <img src="{{ data.image[0] }}" />
      </ng-template>
    </section>
    <section class="learn-content" [innerHTML]="data.content"></section>
  </ng-container>
  <ng-template #contentSlider>
    <div id="contentsSlider" style="position: relative">
      <swiper #swiper [config]="swiperConfig">
        <ng-template swiperSlide *ngFor="let content of data.contents">
          <img class="gif" src="{{ content.img }}" />
          <section class="learn-content">{{ content.content }}</section>
        </ng-template>
      </swiper>
      <img (click)="slideNavigation('prev')" src="assets/icon/arrow_prev_slim_gray.svg" class="learn-prev learn-navigation" />
      <img (click)="slideNavigation('next')" src="assets/icon/arrow_next_slim_gray.svg" class="learn-next learn-navigation" />
    </div>
  </ng-template>
  <div class="btn-confirm" (click)="closeDialog(true)">{{ data.btnText }}</div>
</article>
