import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-customer-notice-delete',
  templateUrl: './dialog-customer-notice-delete.component.html',
  styleUrls: ['./dialog-customer-notice-delete.component.less'],
})
export class DialogCustomerNoticeDeleteComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DialogCustomerNoticeDeleteComponent>) {}

  ngOnInit(): void {}

  onNoClick() {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.dialogRef.close(true);
  }
}
