<article id="dialog-browse-booster-onboarding">
  <img (click)="onClose()" src="assets/icon/close_big_gray.svg" class="booster-close" />
  <section class="booster-header" [@fade]="state">
    @switch (currentStep) { @case (1) {
    <header class="admin-header-1" style="display: block">How many products do you want customers to view?</header>
    <summary class="admin-content-description" style="padding-top: 16px">
      Set the number of products customers must view to receive a coupon. You can always change this setting later.
    </summary>
    } @case (2) {
    <header class="admin-header-1">How much of discount would you like to offer?</header>
    <summary class="admin-content-description">
      Set the value of the coupon that will be provided to customers who complete the mission. This coupon is redeemable only by registered users. You can always change this
      setting.
    </summary>
    }@case (3) {
    <header class="admin-header-1">It's time to activate the core script!</header>
    <summary class="admin-content-description">
      All the preparations are complete. Enable the core script and start boosting customer <br />
      engagement.
    </summary>
    } }
  </section>

  <section class="pagination-wrapper">
    <span class="pagination" [ngClass]="{ active: currentStep! <= 3 }"></span>
    <span class="pagination" [ngClass]="{ active: currentStep! > 1 && currentStep! <= 3 }"></span>
    <span class="pagination" [ngClass]="{ active: currentStep! > 2 && currentStep! <= 3 }"></span>
  </section>

  <section class="booster-options flex-wrapper">
    @if (currentStep < 3) { @for(value of [3, 5, 7]; track $index) {
    <div
      (click)="onClickOptionValue(value)"
      class="booster-btn"
      [ngClass]="{ active: currentStep == 1 ? selectValue.productCount === value : selectValue.discountValue === value }"
    >
      {{ value }}@if (currentStep == 2) {{{ '%' }}}@if (currentStep == 1) { Products } @else { Discount }
    </div>
    } }
  </section>

  <swiper #imgSwiper [config]="swiperConfig">
    <ng-template swiperSlide>
      <section class="booster-contents">
        <div class="booster-content">
          <img src="assets/icon/left.svg" />
          <div class="content-text">
            Explore {{ selectValue.productCount }} Products, <br />
            <span class="focus">Earn {{ selectValue.discountValue }}% Discount</span>
          </div>
          <div class="content-bg">
            <div class="content-icon" [ngStyle]="{ padding: selectValue.productCount == 5 ? '0 30px' : '' }">
              @for (item of genericService.range(1, selectValue.productCount); track $index) {
              <div class="dash-circle">{{ item }}</div>
              }
              <div class="dash-circle"><img src="assets/icon/giftbox.svg" /></div>
            </div>
            <div class="content-description">
              If you explore any product in <span class="focus">15 seconds,</span> <br />
              you can get 1 stamp.
            </div>
          </div>
        </div>
      </section>
    </ng-template>
    <ng-template swiperSlide>
      <section class="booster-contents">
        <div class="booster-content">
          <img src="assets/icon/left.svg" />
          <div class="content-text">
            Explore {{ selectValue.productCount }} Products, <br />
            <span class="focus">Earn {{ selectValue.discountValue }}% Discount</span>
          </div>
          <div class="content-bg discount">
            <div class="discount-value">{{ selectValue.discountValue }}% off</div>
            <div class="discount-date">Valid until tomorrow</div>
            <div class="discount-copy flex-wrapper">
              THANKYOU
              <div class="btn-white">Copy</div>
            </div>
          </div>
        </div>
      </section>
    </ng-template>
    <ng-template swiperSlide>
      <img style="width: 100%; height: 400px" src="assets/image/browse-booster/browse-booster-core.gif" />
    </ng-template>
  </swiper>

  <section class="booster-btns" [@fade]="state">
    @if (currentStep === 1) {
    <div (click)="nextStep()" class="btn-black" style="width: 100%">Next</div>
    } @else if (currentStep === 2) {
    <div class="flex-wrapper" style="gap: 10px">
      <div (click)="previousStep()" class="btn-white">Previous</div>
      <div (click)="nextStep()" class="btn-black">Next</div>
    </div>
    } @else {
    <div class="flex-wrapper">
      <div (click)="previousStep()" class="flex-wrapper" style="cursor: pointer; font-weight: 600">
        <img src="assets/icon/chevron_left.svg" />
        Previous
      </div>
      <div class="flex-wrapper" style="gap: 10px">
        <div (click)="onConfirm(true)" class="btn-black" style="width: 200px">Activate Core Script</div>
        <div (click)="onConfirm(false)" class="btn-white" style="width: 200px">Enable Later</div>
      </div>
    </div>
    }
  </section>
</article>
