import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-dialog-learn-more',
  templateUrl: './dialog-learn-more.component.html',
  styleUrls: ['./dialog-learn-more.component.less'],
})
export class DialogLearnMoreComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DialogLearnMoreComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      header: string;
      image: string[];
      content: string;
      btnText: string;
      contentSlider: boolean;
      contents: { img: string; content: string }[];
    },
  ) {}
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  ngOnInit(): void {}

  swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    pagination: { clickable: true },
  };

  closeDialog(result: boolean) {
    this.dialogRef.close(result);
  }

  slideNavigation(direction: string) {
    direction == 'next' ? this.swiper?.swiperRef.slideNext() : this.swiper?.swiperRef.slidePrev();
  }
}
