export enum MessagingSettingType {
  REVIEW_REQUEST_SETTING = 0,
  NOTIFICATION_SETTING = 1,
}

export enum MessagingSettingTypeDetail {
  FIRST_REVIEW_REQUEST = 0,
  REVIEW_REMINDER = 1,
  MEDIA_REMINDER = 2,
  LONGTERM_REVIEW_REQUEST = 3,
  VERIFICATION_EMAIL = 10,
  NONMEMBER_BENEFIT_APPLY_INFO_EMAIL = 11,
  BENEFIT_INFO_EMAIL = 12,
  REVIEW_REPLY_INFO_EMAIL = 13,
}

export enum PromotedProductDisplaySelectTypeAuto {
  RELATED_PRODUCT = 0,
  TOP_SELLING_PRODCT = 1,
  TOP_RATED_PRODUCT = 2,
}

export interface MessagingEmailBlacklist {
  id: number;
  messaging_id: number;
  email: string;
}

// 리퀘스트 리팩토링 하며 모델 삭제
// export interface MessagingEmail {
//   id: number;
//   active: boolean;

//   use_messaging_email: boolean;
//   sender_name: string;
//   email_address: string;
//   messaging_email_product_display_option: {
//     use_promoted_product_display: boolean;
//     promoted_product_display_select_type: boolean;
//     promoted_product_display_select_type_auto: PromotedProductDisplaySelectTypeAuto;
//     promoted_product_display_select_type_manual_items: string;
//   };

//   messaging_email_blacklist: MessagingEmailBlacklist[];
//   messaging_email_templates: MessagingEmailTemplate[];
// }

export interface MessagingSMS {
  template: string;
  price: {
    use_price_limit: boolean;
    price_limit: number;
  };
  use_no_request_for_foreign: boolean;
  method_for_exceed_text_limit: number;

  id: number;
  use_messaging_sms: boolean;
  sender_name: string;
  sender_phone: string;
}

export interface MessagingWebPush {
  title: string;
  message: string;
  image: {
    window: string | null;
    android: string | null;
    mac: string | null;
  };
  price: {
    use_price_limit: boolean;
    price_limit: number;
  };

  id: number;
  use_messaging_web_push: boolean;
}

export interface MessagingRevisitBanner {
  title: string;
  message: string;

  id: number;
  use_messaging_revisit_banner: boolean;
}

export interface Messaging {
  id: number;
  shop_id: number;
  type: MessagingSettingType;
  type_detail: MessagingSettingTypeDetail;
  use_setting_type_detail: boolean;
  messaging_option: {
    request_time_setting: {
      domestic_order: {
        day_standard: string;
        day_deadline: number;
        send_time: number;
      };
      overseas_order: {
        day_standard: string;
        day_deadline: number;
        send_time: number;
      };
      min_star_rating: number;
      day_standard: string;
      day_deadline: number;
      send_time: number;
    };
  };
  use_email: boolean;
  use_sms: boolean;
  use_web_push: boolean;
  use_revisit_banner: boolean;
  messaging_channel_setting: {
    id: number;
    sms: MessagingSMS;
    web_push: MessagingWebPush;
    revisit_banner: MessagingRevisitBanner;
    messaging_id: number;
  };
  messaging_email_templates: MessagingEmailTemplate[];
  messaging_email_blacklist: MessagingEmailBlacklist[];
}

/**
 * 템플릿 반환하는 심플팩토리
 */
export class MessagingEmailTemplateFactory {
  constructor(private messagingSettingTypeDetail: MessagingSettingTypeDetail) { }
  getTemplate(): MessagingEmailTemplate {
    return this.createMessagingEmailTemplate()!;
  }

  private createMessagingEmailTemplate() {
    switch (this.messagingSettingTypeDetail) {
      case MessagingSettingTypeDetail.FIRST_REVIEW_REQUEST:
        return new EmailTemplateFirstReview();

      case MessagingSettingTypeDetail.REVIEW_REMINDER:
        return new EmailTemplateReviewReminder();

      case MessagingSettingTypeDetail.MEDIA_REMINDER:
        return new EmailTemplateMediaReminder();

      case MessagingSettingTypeDetail.LONGTERM_REVIEW_REQUEST:
        return new EmailTemplateLongtermReviewRequest();

      case MessagingSettingTypeDetail.VERIFICATION_EMAIL:
        return new EmailTemplateReviewVerification();

      case MessagingSettingTypeDetail.BENEFIT_INFO_EMAIL:
        return new EmailTemplateBenefitInfoEmail();

      case MessagingSettingTypeDetail.REVIEW_REPLY_INFO_EMAIL:
        return new EmailTemplateReviewReplyInfoEmail();
      default:
        return undefined;
    }
  }
}

class EmailTemplateFirstReview implements MessagingEmailTemplate {
  messaging_id!: number;
  created_at?: Date;
  updated_at?: Date;

  template_title = '';
  author = '';

  subject = 'How was your recent purchase, {buyer_first_name}?';
  preview_text = 'Had a great experience with your recent purchase? Share your thoughts!';

  design_theme = 'button';
  font: string = 'Inter';
  main_color: string = '#ff6060';
  secondary_color: string = '#ffc144';
  image_logo: string = '';
  content_subject: string = `Share your thoughts
about the latest order`;
  content: string =
    'Dear {buyer_name}! Please take a moment to write a review of the products you ordered recently. The reviews left by {buyer_name} are very helpful not only to us but also to other customers who wish to purchase the product. ';
  note: string = ``;
  button_content: string = 'Write a review';

  use_review_page_button: boolean = true;
  review_page_button_message: string = 'See more';

  use_instagram_share_button?: boolean;
  instagram_share_it_message?: string = '';
  instagram_button_message?: string = '';
  use_unsubscribe_message?: boolean = true;
  unsubscribe_message: string = 'To unsubscribe, ';
}
class EmailTemplateReviewReminder implements MessagingEmailTemplate {
  messaging_id!: number;
  created_at?: Date;
  updated_at?: Date;

  template_title = '';
  author = '';

  subject = 'Don’t forget to leave a review, {buyer_first_name}';
  preview_text = 'Did you get a chance to use your product? Share your experience!';

  design_theme = 'emoji';
  font: string = 'Inter';
  main_color: string = '#ff6060';
  secondary_color: string = '#ffc144';
  image_logo: string = '';
  content_subject: string = `Share your thoughts
about the latest order`;
  content: string = "Dear {buyer_name}! It seems like you haven't written a review yet. If you want to help other customers, leave a review now.";
  note: string = ``;
  button_content: string = 'Write a review';

  use_review_page_button: boolean = true;
  review_page_button_message: string = 'See more';

  use_instagram_share_button?: boolean;
  instagram_share_it_message?: string = '';
  instagram_button_message?: string = '';
  use_unsubscribe_message?: boolean = true;
  unsubscribe_message: string = 'To unsubscribe, ';
}
class EmailTemplateMediaReminder implements MessagingEmailTemplate {
  messaging_id!: number;
  created_at?: Date;
  updated_at?: Date;

  template_title = '';
  author = '';

  subject = 'Share a Picture, Share Your Experience, {buyer_first_name}';
  preview_text = 'A picture is worth a thousand words. Share a photo or video of your product.';

  design_theme = 'button';
  font: string = 'Inter';
  main_color: string = '#ff6060';
  secondary_color: string = '#ffc144';
  image_logo: string = '';
  content_subject: string = `Please add
  a picture or video
  to your review!`;
  content: string =
    'Dear {buyer_name}! Do you have a picture for us as well? Media reviews can help other customers get further information about products. Please share your pictures and videos of the products.';
  note: string = ``;
  button_content: string = 'Upload Media';

  use_review_page_button: boolean = true;
  review_page_button_message: string = 'See more';

  use_instagram_share_button?: boolean;
  instagram_share_it_message?: string = '';
  instagram_button_message?: string = '';
  use_unsubscribe_message?: boolean = true;
  unsubscribe_message: string = 'To unsubscribe, ';
}

class EmailTemplateReviewVerification implements MessagingEmailTemplate {
  messaging_id!: number;
  created_at?: Date;
  updated_at?: Date;

  template_title = '';
  author = '';

  subject = 'Please verify your review.';
  preview_text = 'Thank you for leaving a review. Please verify your review to maximize customer trust.';

  design_theme = 'button';
  font: string = 'Poppins';
  main_color: string = '#ff6060';
  secondary_color: string = '#ffc144';
  image_logo: string = '';
  content_subject: string = `Verify
Your Review`;
  content: string = `Thank you for leaving a review.
[{shop_name} to maximize customer trust]} is in the process of verifying the review.
Please check the contents of the review below,
and if you left the review,
press the button below to verify the review.`;
  note: string = `We will never show your email address on our website.
We just want to confirm it was really you who left the review.`;
  button_content: string = 'Verify Email Address';

  use_review_page_button: boolean = true;
  review_page_button_message: string = 'If you want to modify or delete the review? ';

  use_instagram_share_button?: boolean;
  instagram_share_it_message?: string = '';
  instagram_button_message?: string = '';
  use_unsubscribe_message?: boolean = true;
  unsubscribe_message: string = 'To unsubscibe click ';
}

class EmailTemplateLongtermReviewRequest implements MessagingEmailTemplate {
  messaging_id!: number;
  created_at?: Date;
  updated_at?: Date;

  template_title = '';
  author = '';

  subject = 'Share your long-term experience, {buyer_first_name}';
  preview_text =
    "Tell us how your product is holding up over time. We're always here to listen.";

  design_theme = 'button';
  font: string = 'Poppins';
  main_color: string = '#ff6060';
  secondary_color: string = '#ffc144';
  image_logo: string = '';
  content_subject: string = `How was our products
  after using it for {n_days_ago} days?`;
  content: string = `Dear {buyer_name}! It's already been {n_days_ago} days since you bought the product. How was it using the product for {n_days_ago} days? Please write a genuine review for other buyers`;
  note: string = `We will never show your email address on our website.
We just want to confirm it was really you who left the review.`;
  button_content: string = 'Write a review';

  use_review_page_button: boolean = true;
  review_page_button_message: string = 'See more >';

  use_instagram_share_button?: boolean;
  instagram_share_it_message?: string = '';
  instagram_button_message?: string = '';
  use_unsubscribe_message?: boolean = true;
  unsubscribe_message: string = 'To unsubscibe click ';
}
class EmailTemplateReviewReplyInfoEmail implements MessagingEmailTemplate {
  messaging_id!: number;
  created_at?: Date;
  updated_at?: Date;

  template_title = '';
  author = '';

  subject = `Thank you
  for leaving a review!`;
  preview_text = '';

  design_theme = 'button';
  font: string = 'Poppins';
  main_color: string = '#ff6060';
  secondary_color: string = '#ffc144';
  image_logo: string = '';
  content_subject: string = `Thank you
for leaving a review!`;
  content: string = `The comments are written on the reviews that you wrote! Check the comments.`;
  note: string = ``;
  button_content: string = 'Get Rewards';

  use_review_page_button: boolean = true;
  review_page_button_message: string = 'If you want to modify or delete the review? ';

  use_instagram_share_button?: boolean;
  instagram_share_it_message?: string = '';
  instagram_button_message?: string = '';
  use_unsubscribe_message?: boolean = true;
  unsubscribe_message: string = 'To unsubscibe click ';
}

class EmailTemplateBenefitInfoEmail implements MessagingEmailTemplate {
  messaging_id!: number;
  created_at?: Date;
  updated_at?: Date;

  template_title = '';
  author = '';

  subject = `Thank you
  for leaving a review!`;
  preview_text = 'I gave you a coupon to express my gratitude, so try it right now.';

  design_theme = 'button';
  font: string = 'Poppins';
  main_color: string = '#ff6060';
  secondary_color: string = '#ffc144';
  image_logo: string = '';
  content_subject: string = `Thank you
for leaving a review!`;
  content: string = `Use the following discount code and get {discount_code_value} OFF.`;
  note: string = ``;
  button_content: string = 'Get Rewards';

  use_review_page_button: boolean = true;
  review_page_button_message: string = 'If you want to modify or delete the review? ';

  use_instagram_share_button?: boolean;
  instagram_share_it_message?: string = '';
  instagram_button_message?: string = '';
  use_unsubscribe_message?: boolean = true;
  unsubscribe_message: string = 'To unsubscibe click ';
}

export interface MessagingEmailTemplate {
  id?: number | null;
  active?: boolean;
  messaging_id: number;
  subject: string;
  created_at?: Date;
  updated_at?: Date;
  template_title: string;
  preview_text: string;
  author: string;
  font: string;
  main_color: string;
  secondary_color: string;
  image_logo: string;
  content_subject: string;
  content: string;
  note: string;
  design_theme: string;
  button_content: string;
  use_instagram_share_button?: boolean;
  instagram_share_it_message?: string;
  instagram_button_message?: string;
  use_unsubscribe_message?: boolean;
  unsubscribe_message: string;
  use_review_page_button: boolean;
  review_page_button_message: string;
}
