import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AdminService } from 'src/app/service/admin.service';
import { GenericService } from 'src/app/service/generic.service';
import { RouterExtService } from 'src/app/service/routerExt.service';
import { LeftNavigationBarService } from './left-navigation-bar.service';
import { ListDesignerService } from '../../list-designer/list-designer.service';
import { BrowseBoosterService } from '../../browse-booster/browse-booster.service';
import { AlphaApps, ShopProduction } from 'src/app/models/shop/profile/store-detail.model';

@Component({
  selector: 'left-navigation-bar',
  templateUrl: './left-navigation-bar.component.html',
  styleUrls: ['./left-navigation-bar.component.less'],
})
export class LeftNavigationBarComponent implements OnInit {
  @ViewChildren(MatExpansionPanel) matExpansionPanelQueryList?: QueryList<MatExpansionPanel>;
  constructor(
    public lnbService: LeftNavigationBarService,
    public genericService: GenericService,
    public adminService: AdminService,
    private routerExpService: RouterExtService,
    private router: Router,

    //테스트용
    private LDService: ListDesignerService,
    private BBService: BrowseBoosterService,
  ) {}

  ngOnInit(): void {
    this.setShopInfo();
    this.routerExpService.saveHistory();

    this.lnbService.shopDetail = this.adminService.ShopAdminAccount?.shop?.shop_detail!;
    this.lnbService.setRecommendation();
  }

  ngAfterViewInit(): void {}

  shopName: string | undefined = '';
  shopUrl: string = '';

  setShopInfo() {
    this.shopName = this.adminService.ShopAdminAccount.shop?.shop_name;
    this.shopUrl = `https://${this.adminService.ShopUrl}`;
  }

  routeStore() {
    window.open(this.shopUrl);
  }

  changeReviewMenu() {
    if (this.adminService.ShopAdminAccount.shop?.shop_detail?.use_production.includes(ShopProduction.OG)) this.lnbService.selectedApp = AlphaApps.REVIEW;
  }

  onClickCategory(target: string, apps?: string) {
    let link = !apps ? `admin/${target}` : `admin/${apps}`;
    apps === 'market-place' ? (this.genericService.LNBCategory = apps!) : (this.genericService.LNBCategory = target);
    this.router.navigate([link]);

    const OGAppHandler = () => {
      if (apps === 'market-place') {
        switch (target) {
          case 'product-reviews':
            this.lnbService.selectedApp = AlphaApps.REVIEW;
            break;
          case 'alpha-ai':
            this.lnbService.openDialogUseAI();
            break;
          case 'list-designer':
            this.lnbService.openDialogUseListDesigner();
            break;
        }
      }
    };

    const NXAppHandler = () => {
      if (target === 'browse-booster' && apps === 'market-place') this.lnbService.openDialogBrowseBooster();
    };

    let useApps = this.adminService.ShopAdminAccount.shop?.shop_detail?.use_apps;

    if (useApps?.some((app) => app === AlphaApps.REVIEW || app === AlphaApps.AI || app === AlphaApps.LIST_DESIGNER)) OGAppHandler();
    if (useApps?.includes(AlphaApps.BROWSE_BOOSTER)) NXAppHandler();
  }
}
