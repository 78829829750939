import { Review, ReviewRewardStatus, ReviewType } from 'src/app/models/review/review.model';

export enum ReviewCommonPolicy {
  CATEGORY = 10,
  PRODUCT = 20,
}

export enum BasedProductAmount {
  SALE_PRICE = 1,
  COMPARE_AT_PRICE = 2,
}

export enum BasedOrderTotalCalculation {
  BEFORE_DISCOUNT_CODE = 1,
  AFTER_DISCOUNT_CODE = 2,
}

export enum MinPurchaseOption {
  NONE_LIMITED = 0,
  MINIMUM_AMOUNT_RESTRICTION = 1,
  PURCHASE_AMOUNT_RESTRICTION = 2,
}

export enum MaxAmountBenefitReview {
  NONE_LIMITED = 0,
  AMOUNT_PERCENTAGE = 1,
}

export enum ShippingStatus {
  SHIPPING = 10,
  SHIPPED = 20,
}

export enum ReviewBenefitRestriction {
  REVIEW_BENEFIT_ACCESS = 1,
  REVIEW_RESTRICTION = 2,
  BENEFIT_RESTRICTION = 3,
  NONE_LIMITED = 4,
}

export enum OrderStatusReviewWriteAvailable {
  REVIEW_ACCESS = 1,
  BENEFIT_ACCESS = 2,
}

export enum ReviewBenefitAccessRestriction {
  REVIEW_REGISTRATION = 1,
  ACCESS_AFTER_ALERT = 2,
  BENEFIT_RESTRICTION = 3,
}

export enum ReviewManipulationPoint {
  ALWAYS_AVAILABLE = 1,
  RESTRICTION_AFTER_BENEFIT = 2,
  ALWAYS_UNAVAILABLE = 3,
}

export enum DiscountCodeTarget {
  ALL = 1,
  COLLECTION = 2,
  PRODUCT = 3,
}

export enum MinRequirement {
  NONE = 1,
  PURCHASE_AMOUNT = 2,
  QUANTITY = 3,
}

export enum DiscountCodeType {
  FIXED_AMOUNT = 1,
  PERCENTAGE = 2,
}
export enum SeparationType {
  PRODUCT = 10,
  CUSTOMER = 20,
  COLLECTION = 30,
  CUSTOMER_GROUP = 40,
}

export enum MinPurchaseAmountOption {
  PRODUCT = 0,
  ORDER = 1,
}

/**
   Enum : 추가 혜택 종류

  | Name | Value  | Description |
  |-----|-----|-------|
  | TEXT_REVIEW   | 1   | 텍스트리뷰에 대한 추가혜택 설정     |
  | PHOTO_REVIEW   | 2   | 포토리뷰에 대한 추가혜택 설정     |
  | VIDEO_REVIEW   | 3   | 영상리뷰에 대한 추가혜택 설정 |
  | SURVEY_REVIEW   | 4   | 설문리뷰에 대한 추가혜택 설정 |
  | PRODUCT_AND_ORDER_AMOUNT   | 31   | 상품&주문에 대한 추가혜택 설정 |
  | SPECIFIC_PRODUCT   | 32   | 특정 상품에 대한 추가혜택 설정 |
  | CUSTOMER_SEGMENT   | 41   | 특정 고객군에 대한 추가혜택 설정 |
  */
export enum AdditionalBenefitType {
  TEXT_REVIEW = 1,
  PHOTO_REVIEW = 2,
  VIDEO_REVIEW = 3,
  SURVEY_REVIEW = 4,
  PRODUCT_AND_ORDER_AMOUNT = 31,
  SPECIFIC_PRODUCT = 32,
  CUSTOMER_SEGMENT = 41,
}

/**
 *  Enum : 텍스트리뷰에 대한 추가혜택 설정

    | Name | Value  | Description |
    |-----|-----|-------|
    | TEXT_LENGTH   | 1   | 리뷰 내용 글자수     |

 */
export enum TextReviewCondition {
  TEXT_LENGTH = 1,
}

/**
 * Enum : 포토리뷰에 대한 추가혜택 설정

    | Name | Value  | Description |
    |-----|-----|-------|
    | IMAGE_COUNT   | 1   | 사진 개수     |
 */
export enum PhotoReviewCondition {
  IMAGE_COUNT = 1,
}

/**
 * Enum : 영상리뷰에 대한 추가혜택 설정

    | Name | Value  | Description |
    |-----|-----|-------|
    | VIDEO_LENGTH   | 1   | 동영상 길이     |
 */
export enum VideoReviewCondition {
  VIDEO_LENGTH = 1,
}

/**
 *   Enum : 설문리뷰에 대한 추가혜택 설정

    | Name | Value  | Description |
    |-----|-----|-------|
    | STANDARD   | 1   | 기준. all or more_than 아마 이것도 따로 이넘만들듯    |
    | QUESTION_COUNT   | 2   | 질문 개수     |
 */
export enum SurveyReviewCondition {
  STANDARD = 1,
  QUESTION_COUNT = 2,
}

/**
 * Enum : 상품&주문에 대한 추가혜택 설정

    | Name | Value  | Description |
    |-----|-----|-------|
    | ORDER_AMOUNT   | 1   | 최소 주문금액     |
    | PRODUCT_AMOUNT   | 2   | 최소 상품금액     |
 */
export enum ProductAndOrderAmountCondition {
  ORDER_AMOUNT = 1,
  PRODUCT_AMOUNT = 2,
}

/**
 *  Enum : 특정 상품에 대한 추가혜택 설정

    | Name | Value  | Description |
    |-----|-----|-------|
    | COLLECTION_IDS   | 1   | 대상 Collection id들     |
    | PRODUCT_IDS   | 2   | 대상 Product id들     |
 */
export enum SpecificProductCondition {
  COLLECTION_IDS = 1,
  PRODUCT_IDS = 2,
}

/**
 * Enum : 특정 고객군에 대한 추가혜택 설정

    | Name | Value  | Description |
    |-----|-----|-------|
    | CUSTOMER_GROUP_IDS   | 1   | 대상 customer group id들     |
 */
export enum SpecificCustomerCondition {
  CUSTOMER_GROUP_IDS = 1,
}

// 리뷰 혜택 "지급" 정책
export interface ReviewBenefitProvisionPolicy {
  id: number;
  shop_id: number;

  // 기본 공통 정책
  based_product_amount: BasedProductAmount;
  //# 상품 금액 기준
  based_order_total_calculation: BasedOrderTotalCalculation;
  // 주문 총액 계산 기준
  payment_restriction_order_review: boolean;

  min_purchase_option: MinPurchaseOption;
  min_purchase_amount_option: MinPurchaseAmountOption;
  min_purchase_upto_amount: number;
  min_purchase_upto_count: number;
  // 1개의 리뷰 당 최대 혜택 지급 가능액
  max_amount_benefit_review: MaxAmountBenefitReview;
  max_amount_fixed_amount: number;
  max_amount_percentage: number;
  // 최소 지급 보장 설정
  min_payment_guarantee_option: boolean;
  min_payment_guarantee_percentage: number;
  // 취소/교환/ 반품 시 혜택 자동 취소 및 회수
  auto_cancel_benefit_option: boolean;

  // 1개의 주문 건 내 동일한 상품에 대한 리뷰
  is_identical_items_restriction: boolean;
  identical_items_policy: ReviewBenefitRestriction;
  identical_items_amount: number;
}

// 리뷰 "혜택" 지급 정책
export interface ReviewBenefitRestrictionPolicy {
  id: number;
  shop_id: number;

  using_auto_review_comment: boolean;

  // 배송상태별 정책
  // 리뷰 작성 가능 시작 지점
  review_writing_available_point: ShippingStatus;
  // 주문 수령 이후 리뷰 작성 가능 기한
  shipped_review_period_restriction: boolean;
  shipped_review_period_restriction_date: number;
  // 주문 수령 이후 혜택 지급 가능 기한
  shipped_benefit_period_restriction: boolean;
  shipped_benefit_period_restriction_date: number;

  // 상품 및 주문 상태별 정책
  // 리뷰 작성 시점에 품절 상태인 상품
  soldout_status_review: ReviewBenefitRestriction;
  inactive_status_review: ReviewBenefitRestriction;
  using_discount_code: ReviewBenefitRestriction;

  is_identical_items_restriction: boolean;
  identical_items_policy: ReviewBenefitRestriction;
  identical_items_amount: number;

  // 리뷰 등록 수정 삭제 정책
  is_text_review_restriction: boolean;
  text_review_length_restriction: ReviewBenefitAccessRestriction;
  text_review_restriction_length: number;

  is_photo_review_restriction: boolean;
  photo_review_length_restriction: ReviewBenefitAccessRestriction;
  photo_review_restriction_length: number;

  is_video_review_restriction: boolean;
  video_review_length_restriction: ReviewBenefitAccessRestriction;
  video_review_restriction_length: number;

  is_survey_review_restriction: boolean;
  survey_review_restriction: ReviewBenefitAccessRestriction;

  is_social_review_restriction: boolean;
  social_review_date_restriction: number;

  modify_review_point: ReviewManipulationPoint;
  delete_review_point: ReviewManipulationPoint;

  // 리뷰 작성 가능한 주문 건이 있는 비회원
  review_available_verified_customer: boolean;
  benefit_available_verified_customer: boolean;
}

export interface ReviewBenefitProvisionPolicyDetail {
  id: number;
  review_benefit_provision_policy_id: number;
  use_discount: boolean;
  review_type: ReviewType;
  discount_code: string;

  // 쇼핑몰이 설정한 discount code에 uuid 32자리 포함한 진짜 할인코드
  real_discount_code: string;

  discount_code_type: DiscountCodeType;
  use_fixed_amount_percentage: boolean;
  value_quick_review: number;
  value_text_review: number;
  value_photo_review: number;
  value_insta_review: number;

  // 추가혜택 부분
  additional_benefits: AdditionalBenefit[];

  discount_code_target: DiscountCodeTarget;
  discount_code_target_detail: any[];
  min_requirement: MinRequirement;
  min_purchase_amount: number;
  min_quantity: number;
  discount_code_validity_period: number;
}

export class AdditionalBenefit {
  review_benefit_provision_policy_detail_id?: number;
  type?: AdditionalBenefitType;
  option_1?: string | number;
  option_2?: string | number;
  value?: number | string;
  position?: number;
}

export class MappedAdditionalBenefit {
  type?: ReviewType;
  benefits?: AdditionalBenefit[];
}

export class AdditionalBenefitData {
  key: string = '';
  value: AdditionalBenefitDataValue[] = [];
  maxLength: number = -1;
}

export class AdditionalBenefitDataValue {
  detail: {} = {};
  value?: number | null;
}

export interface ShopBenefit {
  id: number;
  review_benefit_provision_policy: ReviewBenefitProvisionPolicy;
  review_benefit_restriction_policy: ReviewBenefitRestrictionPolicy;
}

export interface AdditionalBenefitDataPreset {
  category: string;
  name: string;
  type: AdditionalBenefitType;
  desc: string;
  backgroundColor: string;
  color: string;
}

export class Blacklist {
  id!: number;
  shop_id!: number;
  blacklist_set: BlacklistElement[] = [];
}

export class BlacklistElement {
  rank!: number;
  separation_type!: SeparationType;
  review: BlacklistElementReview = new BlacklistElementReview();
  benefit: {
    benefit_restriction: boolean;
  } = { benefit_restriction: false };
  restriction_targets: any[] = [];
  update: boolean = false;
}

export class BlacklistElementReview {
  review_write_restriction: boolean = false;
  review_quick_restriction: boolean = false;
  review_text_media_restriction: boolean = false;
  review_survey: boolean = false;
  review_longterm: boolean = false;
  review_social_restriction: boolean = false;
}

export class Whitelist {
  id!: number;
  shop_id!: number;
  whitelist_set: WhitelistSet = new WhitelistSet();
}

export class WhitelistSet {
  review: WhitelistSetElement[] = [];
  benefit: WhitelistSetElement[] = [];
}

export interface WhitelistSetElement {
  separation_type: SeparationType;
  access_target: any[];
  update: boolean;
}
export class PriceRule {
  id?: number;
  shop_id?: number;

  review?: Review;
  review_id?: number;

  admin_graphql_api_id?: string;

  allocation_method: 'across' = 'across';
  created_at?: Date;
  updated_at?: Date;

  title: string = '';
  discount_code: string = '';
  currency?: string; // 통화
  starts_at?: Date;
  ends_at?: Date;
  usage_count: number = 0;
  prerequisite_customer_ids: string[] = [];
  customer_selection: 'prerequisite' = 'prerequisite';

  target_date?: Date;
  target_selection: DiscountCodeTarget = DiscountCodeTarget.ALL;
  target_type: 'line_item' = 'line_item';

  target_selection_platform_ids: number[] = []; // 타겟의 platform id 리스트

  discount_code_validity_period: number = 0;

  min_requirement?: MinRequirement;
  min_requirement_detail?: number;

  status?: ReviewRewardStatus;
  value: number = 0;
  discount_code_type: DiscountCodeType = DiscountCodeType.FIXED_AMOUNT;
  is_active: boolean = false;
  is_additional: boolean = false;
  is_auto?: boolean;

  _hover?: boolean;
}
