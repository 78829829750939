import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LandingService {
  constructor(public router: Router) {}

  isPause!: boolean;
  count: number = 0;

  next!: string;
  prev!: string;
  idx!: string;

  itemBox?: any;

  collectAndManage?: collectAndManage[];

  requestType?: requestType[];

  timer: any;

  mobile: boolean = false;

  gnbSlide: boolean = false;

  mediaChange(breakpointObserver: BreakpointObserver) {
    return breakpointObserver.observe(['(max-width: 540px)']).subscribe((state: BreakpointState) => {
      state.matches ? (this.mobile = true) : (this.mobile = false);
    });
  }

  clickCollect(item: any, list: any) {
    this.stopLoop();
    list.forEach((item: any) => {
      item._isClick = false;
    });

    item._isClick = true;

    //;
  }

  startLoop(list: any) {
    if (!this.isPause) {
      this.timer = setInterval(() => {
        this.collectLoop(list);
      }, 4000);
    }
  }

  stopLoop() {
    this.isPause = true;
    this.count = 0;
    clearInterval(this.timer);
  }

  collectLoop(list: any) {
    if (!this.isPause) {
      this.itemBox[this.count].classList.add('active');
      list[this.count]._isClick = true;

      if (this.count > 0) {
        this.itemBox![this.count - 1].classList.remove('active');
        list[this.count - 1]._isClick = false;
      } else if (this.count == 0) {
        this.itemBox![this.itemBox!.length - 1].classList.remove('active');
        list[this.itemBox!.length - 1]._isClick = false;
      }
      //;
      this.count = this.count + 1;
      if (this.count == this.itemBox!.length) {
        this.count = 0;
      }
    }
  }

  nextPrev() {
    switch (this.idx) {
      case 'collect':
        this.next = './moderate';
        this.prev = './marketing';
        break;
      case 'moderate':
        this.next = './showcase';
        this.prev = './collect';
        break;
      case 'showcase':
        this.next = './marketing';
        this.prev = './moderate';
        break;
      case 'marketing':
        this.next = './collect';
        this.prev = './showcase';
        break;
      default:
        this.next = '';
        this.prev = '';
    }

    //;
  }

  startFreeTrial() {
    window.open('https://apps.shopify.com/alphareview');
  }
}

interface collectAndManage {
  title: string;
  summary: string;
  image: string;
  _isClick?: boolean;
}

interface requestType {
  title: string;
  image: string;
  _isClick?: boolean;
}
