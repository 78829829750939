import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StarFilledComponent } from './star-filled/star-filled.component';
import { CircleQuestionComponent } from './circle-question/circle-question.component';
import { CircleCheckSmallComponent } from './circle-check-small/circle-check-small.component';
import { CircleCheckLargeComponent } from './circle-check-large/circle-check-large.component';
import { CounterTextLengthComponent } from './counter-text-length/counter-text-length.component';
import { WriteHeaderComponent } from './write/write-header/write-header.component';
import { ProductOptionComponent } from './product-option/product-option.component';
import { IconLabelComponent } from './icon-label/icon-label.component';
import { IconEmptyImageComponent } from './icon-empty-image/icon-empty-image.component';
import { EmptyDataComponent } from './empty-data/empty-data.component';
import { RatingStylePreviewComponent } from './rating-style-preview/rating-style-preview.component';
import { IconCheckComponent } from './icon-check/icon-check.component';

@NgModule({
  declarations: [
    StarFilledComponent,
    CircleQuestionComponent,
    CircleCheckSmallComponent,
    CircleCheckLargeComponent,
    CounterTextLengthComponent,
    WriteHeaderComponent,
    ProductOptionComponent,
    IconLabelComponent,
    IconEmptyImageComponent,
    EmptyDataComponent,
    RatingStylePreviewComponent,
    IconCheckComponent,
  ],
  imports: [CommonModule],
  exports: [
    StarFilledComponent,
    CircleQuestionComponent,
    CircleCheckSmallComponent,
    CircleCheckLargeComponent,
    CounterTextLengthComponent,
    WriteHeaderComponent,
    ProductOptionComponent,
    IconLabelComponent,
    IconEmptyImageComponent,
    EmptyDataComponent,
    RatingStylePreviewComponent,
    IconCheckComponent,
  ],
})
export class TemplateModule {}
