import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AiService } from 'src/app/service/ai.service';

@Component({
  selector: 'app-dialog-ai-import-dummy',
  templateUrl: './dialog-ai-import-dummy.component.html',
  styleUrl: './dialog-ai-import-dummy.component.less',
})
export class DialogAiImportDummyComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<DialogAiImportDummyComponent>, public aiService: AiService) {}

  ngOnInit(): void {}

  onCancel() {
    this.dialogRef.close(false);
  }

  onStart() {
    this.dialogRef.close(true);
  }
}
