import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-selectbox',
  templateUrl: './custom-selectbox.component.html',
  styleUrls: ['./custom-selectbox.component.less']
})
export class CustomSelectboxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
