import {
  animate,
  animateChild,
  animation,
  group,
  query,
  state,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';

export const inOutAnimation = [
  trigger('inOutAnimation', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('0.2s ease-in', style({ opacity: 1 })),
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate('0.2s ease-out', style({ opacity: 0 })),
    ]),
  ]),
];

export const showAnimation = [
  trigger('openClose', [
    state('true', style({ height: '*' })),
    state('false', style({ height: '0px' })),
    transition('false <=> true', [animate(400)]),
  ]),
];

export const upDownAnimation = [
  trigger('upDownAnimation', [
    transition(':enter', [
      style({
        opacity: 0,
        transform: 'scale(0.3)',
      }),
      animate('0.5s ease-in', style({ transform: 'scale(1)', opacity: 1 })),
    ]),
    transition(':leave', [
      style({
        transform: 'scale(1)',
        opacity: 1,
      }),
      animate('0.5s ease-out', style({ transform: 'scale(0.3)', opacity: 0 })),
    ]),
  ]),
];

export const fadeAnimation = [
  trigger('fade', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('0.5s ease-in', style({ opacity: 1 })),
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate('0.5s ease-out', style({ opacity: 0 })),
    ]),
  ]),
];

export const slideAnimation = [
  trigger('slide', [
    transition(':enter', [
      style({ opacity: 0, transform: 'translateX(-100%)' }),
      animate(
        '0.5s ease-in',
        style({ opacity: 1, transform: 'translateX(100%)' })
      ),
    ]),
    transition(':leave', [
      style({ opacity: 1, transform: 'translateX(100%)' }),
      animate(
        '0.5s ease-out',
        style({ opacity: 0, transform: 'translateX(-100%)' })
      ),
    ]),
  ]),
];

export const openAnimation = [
  trigger('openClose', [
    state(
      'close',
      style({
        height: '52px',
        overflow: 'hidden',
      })
    ),
    state(
      'open',
      style({
        height: '*',
        overflow: 'hidden',
      })
    ),
    transition('open <=> close', [animate('0.3s')]),
  ]),
];

export const fadeTextAnimation = [
  trigger('fadeText', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('0.8s ease-in', style({ opacity: 1 })),
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate('0.8s ease-out', style({ opacity: 0 })),
    ]),
  ]),
];

export const routeAnimation = trigger('routeAnimation', [
  transition('* => *', [
    query(':enter', [style({ opacity: 0 })], {
      optional: true,
    }),
    query(
      ':leave',
      [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))],
      { optional: true }
    ),
    query(
      ':enter',
      [style({ opacity: 0 }), animate('0.5s', style({ opacity: 1 }))],
      { optional: true }
    ),
  ]),
]);
