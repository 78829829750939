<article id="custom-confirm" style="padding: 22px 24px" [ngStyle]="{ 'text-align': alignCenter ? 'center' : 'left' }">
  @if (header) {
  <header class="admin-header-3" style="margin-bottom: 10px; color: #151516; gap: 6px" [ngStyle]="alignCenter ? { 'text-align': 'center', 'justify-content': 'center' } : {}">
    @if (headerImgSrc){ <img src="{{ headerImgSrc }}" /> }
    {{ header }}
  </header>
  }
  <section style="margin-bottom: 20px; white-space: pre-line; color: #616161">
    {{ content }}
  </section>
  @if (image) {
  <section class="image-container" style="margin-bottom: 24px">
    <img style="width: 100%" src="{{ image }}" />
  </section>
  }

  <section class="flex-wrapper" style="gap: 10px" [ngStyle]="{ 'justify-content': alignCenter ? 'center' : 'flex-end' }">
    <button *ngIf="!hideCancel" class="btn-white" (click)="onCancel()" style="padding: 0 10px; height: 36px">{{ cancel }}</button>
    <button class="btn-black" (click)="onConfirm()" style="padding: 0 10px" [ngStyle]="{ width: !hideCancel ? 'fit-content' : confirmWidth ? confirmWidth : '100%' }">
      {{ confirm }}
    </button>
  </section>
</article>
