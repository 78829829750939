"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.LeaveConfirmation = exports.confirm = exports.disable = exports.enable = exports.Action = void 0;
var LeaveConfirmation_1 = require("@shopify/app-bridge-core/actions/LeaveConfirmation");
Object.defineProperty(exports, "LeaveConfirmation", { enumerable: true, get: function () { return LeaveConfirmation_1.LeaveConfirmation; } });
var LeaveConfirmation_2 = require("@shopify/app-bridge-core/actions/LeaveConfirmation");
Object.defineProperty(exports, "Action", { enumerable: true, get: function () { return LeaveConfirmation_2.Action; } });
Object.defineProperty(exports, "enable", { enumerable: true, get: function () { return LeaveConfirmation_2.enable; } });
Object.defineProperty(exports, "disable", { enumerable: true, get: function () { return LeaveConfirmation_2.disable; } });
Object.defineProperty(exports, "confirm", { enumerable: true, get: function () { return LeaveConfirmation_2.confirm; } });
/**
 * Returns a new instance of a Leave Confirmation action set
 * @param app the client application
 */
function create(app, options) {
    if (options === void 0) { options = {}; }
    return new LeaveConfirmation_1.LeaveConfirmation(app, options);
}
exports.create = create;
