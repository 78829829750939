<article id="profile-account" style="max-width: 1000px">
  <section class="gray-border-wrapper">
    <header class="admin-header-3">Store Details</header>
    <store-setting-input
      *ngIf="shopDetail"
      (fileEvent)="getShopLogoChange($event)"
      (shopDetailChange)="getShopDetailChange($event)"
      [shopDetail]="shopDetail"
    ></store-setting-input>
    <!-- <section class="flex-wrapper">
        <div></div>
        <div class="flex-wrapper">
          <button class="btn-cancel" [disabled]="genericService.isDirtyObject(shopDetail, originShopDetail)" (click)="getShopDetail()">Cancel</button>
          <div class="btn-confirm" (click)="patchShopDetail()">Save</div>
        </div>
      </section> -->
  </section>
  <section *ngIf="contactInformation && useAlphaPlus" class="gray-border-wrapper" style="margin-top: 20px">
    <header class="admin-header-3">Contact Information</header>

    <div class="content-container">
      <div class="content-wrap flex-wrapper">
        <header class="admin-header-4">Phone Number</header>
        <input class="admin-input" placeholder="Phone Number" [(ngModel)]="contactInformation.sms_sender_phone" />
      </div>
      <div class="content-wrap flex-wrapper">
        <header class="admin-header-4">Sender Name</header>
        <input class="admin-input" placeholder="Sender Name" [(ngModel)]="contactInformation.email_sender_name" />
      </div>
      <div class="content-wrap flex-wrapper">
        <header class="admin-header-4">Sender Email Address</header>
        <div class="flex-wrapper" style="gap: 6px">
          <input class="admin-input" placeholder="Sender Email Address" [(ngModel)]="contactInformation.email_sender_email" disabled="true" />
          <div class="btn-white" style="width: 60px; height: 34px" (click)="openEmailVerifyDialog()">Edit</div>
        </div>
      </div>
    </div>
    <div class="weak-authentication" *ngIf="weakAuthentication"><img src="assets/icon/info_red.svg" />Weak Authentication</div>

    <!-- <section class="flex-wrapper">
      <div></div>
      <div class="flex-wrapper">
        <button class="btn-cancel" [disabled]="genericService.isDirtyObject(contactInformation, originContactInformation)" (click)="getShopContactInformation()">Cancel</button>
        <button class="btn-confirm" (click)="patchShopContactInformation()">Save</button>
      </div>
    </section> -->
  </section>
  <!--
  <header class="admin-header-1" style="padding-top: 30px;">
    Security & Privacy
  </header>
  <section class="gray-border-wrapper">
    <section class="border-bottom">
      <header class="admin-header-2">
        Web Push Privacy
      </header>
    </section>
    <section class="content-wrapper border-top">
      <header class="admin-header-2">
        Notification Preferences
      </header>
      <summary class="admin-header-summary">
        구독자에게 데이터에 액세스하거나 삭제할 수 있는 옵션과 푸시 알림 구독을 취소하는 옵션을 제공하도록 선택할 수 있습니다.
        구독자에게 제공할 옵션들을 선택하세요.
      </summary>
    </section>
    <section style="display: flex; margin-top: 30px;" *ngIf="webpushPrivacy">
      <section style="flex-grow: 1; margin-right: 10px;">

        <section class="content-row">
          <header class="content-title big width-110">
            Option
          </header>
          <section class="content-value checkbox-container">
            <label class="content-value-checkbox">
              <input type="checkbox" [(ngModel)]="webpushPrivacy.use_access_data">
              Access Data
            </label>

            <label class="content-value-checkbox">
              <input type="checkbox" [(ngModel)]="webpushPrivacy.use_delete_data">
              Delete Data
            </label>

            <label class="content-value-checkbox">
              <input type="checkbox" [(ngModel)]="webpushPrivacy.use_unsubscribe">
              Unsubscribe
            </label>
          </section>
        </section>

        <section class="content-row">
          <header class="content-title big width-110">
            Title Text
          </header>
          <section class="content-value">
            <input class="admin-input width-220" placeholder="Notification Preference"
              [(ngModel)]="webpushPrivacy.title_text">
          </section>
        </section>

        <section class="content-title big" style="padding-top: 8px; padding-bottom: 12px;">
          Customized Buttons
        </section>
        <section class="content-row">
          <header class="content-title width-110">
            Access Data
          </header>
          <section class="content-value">
            <input class="admin-input width-220" placeholder="View My Data"
              [(ngModel)]="webpushPrivacy.access_data_title_text">
          </section>
        </section>

        <section class="content-row">
          <header class="content-title width-110">
            Delete Data
          </header>
          <section class="content-value">
            <input class="admin-input width-220" placeholder="Stop Usage Tracking"
              [(ngModel)]="webpushPrivacy.delete_data_title_text">
          </section>
        </section>

        <section class="content-row">
          <header class="content-title width-110">
            Unsubscribe
          </header>
          <section class="content-value">
            <input class="admin-input width-220" placeholder="Unsubscribe"
              [(ngModel)]="webpushPrivacy.unsubscribe_title_text">
          </section>
        </section>

      </section>
      <section style="flex-basis: 350px; background-color: wheat;">

      </section>
    </section>
    <section class="flex-wrapper" style="margin-top: 24px;">
      <div></div>
      <div class="flex-wrapper">
        <button class="btn-cancel">Cancel</button>
        <div class="btn-confirm" (click)="patchWebpushPrivacy()">Save</div>
      </div>
    </section>
  </section> -->
</article>
