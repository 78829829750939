import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingGlobalNavigationBarComponent } from './landing-global-navigation-bar/landing-global-navigation-bar.component';
import { LandingFooterComponent } from './landing-footer/landing-footer.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [LandingGlobalNavigationBarComponent, LandingFooterComponent],
  imports: [CommonModule, RouterModule],
  exports: [LandingGlobalNavigationBarComponent, LandingFooterComponent],
})
export class LandingLayoutModule {}
