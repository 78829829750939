<article id="dialog-admin-account-delete">
  <header class="admin-header-1" style="margin-bottom: 20px;">
    Are you sure?
  </header>
  <section style="margin-bottom: 20px;">
    We are going to remove {{data.user_name}} from your member list.
    Deleted member account will be permanently removed
    from your store, and this action cannot be reversed.
  </section>

  <section class="flex-wrapper">
    <div></div>
    <section class="flex-wrapper">
      <button class="btn-gray" style="padding: 12px 22px; height: unset; width: unset;" (click)="onNoClick()">
        Cancel
      </button>
      <div (mouseenter)="activeBtn = true" (mouseleave)="activeBtn = false">
        <button class="btn-red" style="width: 120px; margin-left: 10px;" [disabled]="!activeBtn" (click)="onConfirm()">
          Delete
        </button>
      </div>
    </section>

  </section>
</article>
