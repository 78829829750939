<article id="dialog-start-browse-booster">
  <header class="admin-header-1">
    <div class="apps-icon"><img src="assets/icon/icon_browse_booster_purple.svg" /></div>
    Capture first-time visitors with Browse Booster.
  </header>

  <div class="scroll-container">
    <img src="assets/image/browse-booster/info.gif" />
    <img src="assets/image/browse-booster/info.png" />
  </div>

  <section class="flex-wrapper">
    <div (click)="onCancel()" class="btn-white">Cancel</div>
    <div (click)="onConfirm()" class="btn-black">Activate Browse Booster</div>
  </section>
</article>
