export interface ShopDetail {
  id: number;
  shop_id: number;
  company_name: string;
  industry: string;
  store_name: string;
  store_url: string;
  store_logo: string;
  country: string;
  currency: string;
  file?: File;

  use_apps: AlphaApps[];
  use_production: ShopProduction[];

  is_dropshipper: boolean;
}

export enum AlphaApps {
  REVIEW = 100,
  AI = 200,
  LIST_DESIGNER = 300,
  BROWSE_BOOSTER = 400,
}

export enum ShopProduction {
  OG = 100, // Alpha +
  NX = 200, // Browse Booster
}
