<article id="analysis-log" class="setting-article" style="max-width: 1000px">
  <header class="admin-header-1">
    <img onclick="history.back()" src="assets/icon/back_btn_gray.svg" style="cursor: pointer" />
    Analysis Logs
  </header>

  <section class="gray-border-wrapper log-scroll">
    <ng-container *ngFor="let log of aiService.analysisLogs">
      <section class="log-container">
        <header class="log-header">AliExpress</header>
        <div class="log-title ellipsis-1">{{ log.title }}</div>

        <section class="flex-wrapper">
          <div class="log-info-wrapper">
            <div class="flex-wrapper" style="gap: 20px">
              <div class="log-info">
                <div class="info-title">Ratings</div>
                <div class="info-value">★ {{ log.rating }}</div>
              </div>
              <div class="log-info">
                <div class="info-title">Analyzed at</div>
                <div class="info-value">{{ log.created_at | date : 'mediumDate' }}</div>
              </div>
            </div>

            <div class="flex-wrapper" style="gap: 20px">
              <div class="log-info">
                <div class="info-title">Review Count</div>
                <div class="info-value">{{ log.review_count }} reviews</div>
              </div>
              <div *ngIf="!log.is_review_saved" class="log-info valid">
                <div class="info-title">Valid until</div>
                <div class="info-value">{{ log.expired_at | date : 'mediumDate' }}</div>
              </div>
            </div>
          </div>

          <div class="log-btn-wrapper flex-wrapper" style="gap: 5px; flex-shrink: 0">
            <div (click)="viewDetails(log.id)" class="btn-white" style="height: 36px; width: 122px">View Analysis</div>
            <div *ngIf="!log.is_review_saved" (click)="aiService.openDialogProductVariantSelect(log)" class="btn-black" style="width: 197px">Save and Import Reviews</div>
          </div>
        </section>
      </section>
    </ng-container>
  </section>

  <section>
    <mat-paginator
      #paginator
      (page)="aiService.getAnalysisList($event, true)"
      [pageSizeOptions]="[10, 20, 100]"
      [disabled]="aiService.manageAnalysisLoading"
      showFirstLastButtons
      aria-label="Select page"
      ><input class="admin-input" />
    </mat-paginator>
  </section>
</article>
