import { Component, EventEmitter, Input, OnInit, Optional, Output, SkipSelf, ViewEncapsulation } from '@angular/core';
import { SuperAdminShopInfo } from 'src/app/models/super-admin/super-admin.shops.model';
import { BackendService } from 'src/app/service/backend.service';
import { SuperAdminService } from 'src/app/service/super-admin.service';

@Component({
  selector: 'app-memo',
  templateUrl: './memo.component.html',
  styleUrls: ['./memo.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class MemoComponent implements OnInit {

  constructor(
    @Optional() @SkipSelf() public superadminservice: SuperAdminService,
    private backendService: BackendService,
  ) { }
  @Input('shopInfo') shopInfo?: SuperAdminShopInfo;
  @Output() refresh = new EventEmitter<any>();
  memo: String = "";
  ngOnInit(): void {
  }
  createMemo() {

    let url = "super-admin/shops/memo"
    this.backendService.create(url, { 'shop_id': this.shopInfo?.id, 'content': this.memo }).subscribe(response => {
      if (response.status == 200) {
        this.memo = ""
        this.onRefresh()
      }
    })
  }

  onRefresh() {
    this.refresh.emit()
  }

}
