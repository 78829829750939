<article id="alpha-textarea" [ngClass]="{ minimal: minimumDisplay }">
  <main class="admin-textarea" [ngStyle]="{ height: height }" (mouseleave)="mouseLeave()">
    <ng-container *ngIf="displayFooter">
      <div class="textarea-body-wrapper">
        <textarea
          *ngIf="!minimumDisplay"
          class="textarea-body"
          [(ngModel)]="sourceString"
          [placeholder]="placeholder"
          (input)="checkString()"
          [maxLength]="maxStringLength"
          (ngModelChange)="changeSourceString()"
        ></textarea>
        <textarea
          *ngIf="minimumDisplay"
          rows="1"
          class="textarea-body"
          [(ngModel)]="sourceString"
          [placeholder]="placeholder"
          (input)="checkString()"
          [maxLength]="maxStringLength"
          (ngModelChange)="changeSourceString()"
        ></textarea>
      </div>
      <div class="textarea-footer">
        <div class="textarea-footer-icon-container">
          <img [src]="'assets/icon/emoji_deep_gray.svg'" *ngIf="displayEmojiPicker" style="margin-right: 12px" (click)="openEmojiPicker = !openEmojiPicker" />
          <img
            [src]="'assets/icon/personal_param_deep_gray.svg'"
            *ngIf="displayPersonalVarsPicker"
            style="margin-right: 12px"
            (click)="openPersonalvarsPicker = !openPersonalvarsPicker"
          />
        </div>
        <div class="textarea-footer-text-length" *ngIf="!minimumDisplay">
          {{ getStringLength(sourceString) }} <span class="light-font">/ {{ maxStringLength }}</span>
        </div>
        <div class="textarea-picker-container">
          <emoji-mart
            *ngIf="displayEmojiPicker && openEmojiPicker"
            [darkMode]="false"
            title="Pick your emoji!"
            emoji="point_up"
            set="apple"
            [i18n]="{ search: 'Search' }"
            (emojiClick)="addEmoji($event)"
          >
          </emoji-mart>
          <section class="textarea-vars-picker" *ngIf="openPersonalvarsPicker">
            <div class="textarea-vars-picker-content" *ngFor="let item of personalVars" (click)="addPersonalVars(item)">
              {{ item }}
            </div>
          </section>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!displayFooter">
      <textarea [(ngModel)]="sourceString" [placeholder]="placeholder" (input)="checkString()" [maxLength]="maxStringLength"></textarea>
    </ng-container>
  </main>
  <ng-container *ngIf="outerVarsPicker">
    <div class="outer-vars">
      <div class="outer-vars-btn" (click)="openOuterVarsPicker = !openOuterVarsPicker">Add <img [src]="'assets/icon/personal_param_deep_gray.svg'" *ngIf="outerVarsPicker" /></div>
      <section class="outer-vars-picker" *ngIf="openOuterVarsPicker" (mouseleave)="openOuterVarsPicker = false">
        <div class="outer-vars-picker-content" *ngFor="let item of personalVars" (click)="addPersonalVars(item)">
          {{ item }}
        </div>
      </section>
    </div>
  </ng-container>
  <div class="textarea-footer-text-length" *ngIf="minimumDisplay && displayMaxLengthLabel">{{ getStringLength(sourceString) }} / {{ maxStringLength }}</div>
  <label class="max-length-label" *ngIf="displayMaxLengthLabel && !minimumDisplay">
    <img src="assets/icon/info_deep_gray.svg" /> You can enter up to {{ maxStringLength }} characters
  </label>
</article>
