"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TitleBar = exports.update = exports.clickBreadcrumb = exports.clickActionButton = exports.Action = void 0;
var ActionSet_1 = require("../ActionSet");
var Button_1 = require("../Button");
var ButtonGroup_1 = require("../ButtonGroup");
var buttonGroupHelper_1 = require("../buttonGroupHelper");
var buttonHelper_1 = require("../buttonHelper");
var helper_1 = require("../helper");
var types_1 = require("../types");
var Action;
(function (Action) {
    Action["UPDATE"] = "APP::TITLEBAR::UPDATE";
    Action["BUTTON_CLICK"] = "APP::TITLEBAR::BUTTONS::BUTTON::CLICK";
    Action["BUTTON_UPDATE"] = "APP::TITLEBAR::BUTTONS::BUTTON::UPDATE";
    Action["BUTTON_GROUP_UPDATE"] = "APP::TITLEBAR::BUTTONS::BUTTONGROUP::UPDATE";
    Action["BREADCRUMBS_CLICK"] = "APP::TITLEBAR::BREADCRUMBS::BUTTON::CLICK";
    Action["BREADCRUMBS_UPDATE"] = "APP::TITLEBAR::BREADCRUMBS::BUTTON::UPDATE";
})(Action = exports.Action || (exports.Action = {}));
var TITLEBAR_BUTTON_PROPS = {
    group: types_1.Group.TitleBar,
    subgroups: ['Buttons'],
};
var BREADCRUMB_BUTTON_PROPS = {
    group: types_1.Group.TitleBar,
    subgroups: ['Breadcrumbs'],
    type: types_1.ComponentType.Button,
};
function clickActionButton(id, payload) {
    var type = types_1.ComponentType.Button;
    var component = __assign({ id: id, type: type }, TITLEBAR_BUTTON_PROPS);
    return Button_1.clickButton(types_1.Group.TitleBar, component, payload);
}
exports.clickActionButton = clickActionButton;
function clickBreadcrumb(id, payload) {
    var component = __assign({ id: id }, BREADCRUMB_BUTTON_PROPS);
    return Button_1.clickButton(types_1.Group.TitleBar, component, payload);
}
exports.clickBreadcrumb = clickBreadcrumb;
function update(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.TitleBar,
        type: Action.UPDATE,
    });
}
exports.update = update;
var TitleBar = /** @class */ (function (_super) {
    __extends(TitleBar, _super);
    function TitleBar(app, options) {
        var _this = _super.call(this, app, types_1.Group.TitleBar, types_1.Group.TitleBar) || this;
        if (!options.title && !options.breadcrumbs && !options.buttons) {
            return _this;
        }
        // Trigger 'update' on creation
        _this.set(options);
        return _this;
    }
    Object.defineProperty(TitleBar.prototype, "buttons", {
        get: function () {
            if (!this.primary && !this.secondary) {
                return undefined;
            }
            return {
                primary: this.primary,
                secondary: this.secondary,
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TitleBar.prototype, "buttonsOptions", {
        get: function () {
            if (!this.primaryOptions && !this.secondaryOptions) {
                return undefined;
            }
            return {
                primary: this.primaryOptions,
                secondary: this.secondaryOptions,
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TitleBar.prototype, "options", {
        get: function () {
            return {
                breadcrumbs: this.breadcrumbsOption,
                buttons: this.buttonsOptions,
                title: this.title,
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TitleBar.prototype, "payload", {
        get: function () {
            return __assign(__assign({}, this.options), { breadcrumbs: this.breadcrumb, buttons: this.buttons, id: this.id });
        },
        enumerable: false,
        configurable: true
    });
    TitleBar.prototype.set = function (options, shouldUpdate) {
        if (shouldUpdate === void 0) { shouldUpdate = true; }
        var mergedOptions = helper_1.getMergedProps(this.options, options);
        var title = mergedOptions.title, buttons = mergedOptions.buttons, breadcrumbs = mergedOptions.breadcrumbs;
        this.title = title;
        this.setBreadcrumbs(breadcrumbs);
        this.setPrimaryButton(buttons ? buttons.primary : undefined);
        this.setSecondaryButton(buttons ? buttons.secondary : undefined);
        if (shouldUpdate) {
            this.dispatch(Action.UPDATE);
        }
        return this;
    };
    TitleBar.prototype.dispatch = function (action) {
        switch (action) {
            case Action.UPDATE:
                this.app.dispatch(update(this.payload));
                break;
        }
        return this;
    };
    TitleBar.prototype.getButton = function (button, subgroups, updateCb) {
        if (button instanceof ButtonGroup_1.ButtonGroup) {
            return buttonGroupHelper_1.getGroupedButton(this, button, subgroups, updateCb);
        }
        return buttonHelper_1.getSingleButton(this, button, subgroups, updateCb);
    };
    TitleBar.prototype.updatePrimaryButton = function (newPayload) {
        if (!this.primary) {
            return;
        }
        if (helper_1.updateActionFromPayload(this.primary, newPayload)) {
            this.dispatch(Action.UPDATE);
        }
    };
    TitleBar.prototype.updateSecondaryButtons = function (newPayload) {
        if (!this.secondary) {
            return;
        }
        var buttonToUpdate = this.secondary.find(function (action) { return action.id === newPayload.id; });
        if (!buttonToUpdate) {
            return;
        }
        var updated = false;
        if (ButtonGroup_1.isGroupedButtonPayload(newPayload)) {
            updated = helper_1.updateActionFromPayload(buttonToUpdate, newPayload);
        }
        else {
            updated = helper_1.updateActionFromPayload(buttonToUpdate, newPayload);
        }
        if (updated) {
            this.dispatch(Action.UPDATE);
        }
    };
    TitleBar.prototype.updateBreadcrumbButton = function (newPayload) {
        if (!this.breadcrumb) {
            return;
        }
        if (helper_1.updateActionFromPayload(this.breadcrumb, newPayload)) {
            this.dispatch(Action.UPDATE);
        }
    };
    TitleBar.prototype.setPrimaryButton = function (newOptions) {
        this.primaryOptions = this.getChildButton(newOptions, this.primaryOptions);
        this.primary = this.primaryOptions
            ? this.getButton(this.primaryOptions, TITLEBAR_BUTTON_PROPS.subgroups, this.updatePrimaryButton)
            : undefined;
    };
    TitleBar.prototype.setSecondaryButton = function (newOptions) {
        var _this = this;
        var newButtons = newOptions || [];
        var currentButtons = this.secondaryOptions || [];
        this.secondaryOptions = this.getUpdatedChildActions(newButtons, currentButtons);
        this.secondary = this.secondaryOptions
            ? this.secondaryOptions.map(function (action) {
                return _this.getButton(action, TITLEBAR_BUTTON_PROPS.subgroups, _this.updateSecondaryButtons);
            })
            : undefined;
    };
    TitleBar.prototype.setBreadcrumbs = function (breadcrumb) {
        this.breadcrumbsOption = this.getChildButton(breadcrumb, this.breadcrumbsOption);
        this.breadcrumb = this.breadcrumbsOption
            ? this.getButton(this.breadcrumbsOption, BREADCRUMB_BUTTON_PROPS.subgroups, this.updateBreadcrumbButton)
            : undefined;
    };
    TitleBar.prototype.getChildButton = function (newAction, currentAction) {
        var newButtons = newAction ? [newAction] : [];
        var currentButtons = currentAction ? [currentAction] : [];
        var updatedButton = this.getUpdatedChildActions(newButtons, currentButtons);
        return updatedButton ? updatedButton[0] : undefined;
    };
    return TitleBar;
}(ActionSet_1.ActionSetWithChildren));
exports.TitleBar = TitleBar;
