<article id="dialog-start-list-designer">
  <header class="admin-header-1">
    <div class="apps-icon"><img src="assets/icon/icon_list_designer.svg" /></div>
    Hire a List Designer right now
  </header>
  <summary class="admin-content-description">
    List Designer allows you to freely arrange elements that show the popularity of products, enabling you to create eye-catching product lists in just 3 minutes.
  </summary>

  <div class="scroll-container">
    <section class="list-designer-img">
      <img src="assets/image/list-designer/info_ld.png" />
    </section>

    <section class="list-designer-product-check">
      @if(productsAvailable) {
      <div class="available">
        <span class="circle circle-out"><span class="circle circle-in"></span></span>Available
      </div>
      You have some products! Let List Designer beautifully decorate your products for you! } @else {

      <div class="unavailable">
        <span class="circle circle-out"><span class="circle circle-in"></span></span>Unavailable
      </div>
      You must have products in your store to use the List Designer. }
    </section>
  </div>

  <section class="flex-wrapper list-designer-btns">
    <div (click)="onCancel()" class="btn-white">Cancel</div>
    <div (click)="onConfirm()" class="btn-black" [class.disabled]="!productsAvailable">Hire a List Designer</div>
  </section>
</article>
