import { Component, Input, OnInit, Optional, SkipSelf, ViewEncapsulation } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { SuperAdminUrls } from 'src/app/models/super-admin/super-admin-urls';
import { SuperAdminService } from 'src/app/service/super-admin.service';

@Component({
  selector: 'app-super-admin-header',
  templateUrl: './super-admin-header.component.html',
  styleUrls: ['./super-admin-header.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class SuperAdminHeaderComponent implements OnInit {
  headerName?: any;
  constructor(private router: Router,
    @Optional() @SkipSelf() public superAdminService: SuperAdminService,
    ) { }
  @Input('headerContent')headerContent?: String;
  ngOnInit(): void {
    let url: string[] = [];
    this.setHeaderName();
  }
  setHeaderName(){
    let url = this.router.url.split('/');
    this.headerName = url[url.length - 1];
  }

}
