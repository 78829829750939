import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { DeleteType, ReviewAppPlatform, ReviewImportLog, TransferStatus } from 'src/app/models/shop/review/options/review-import.model';
import { AdminService } from 'src/app/service/admin.service';
import { BackendService } from 'src/app/service/backend.service';
import { ImportService } from '../import.service';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-review-import-upload',
  templateUrl: './dialog-review-import-upload.component.html',
  styleUrls: ['./dialog-review-import-upload.component.less'],
})
export class DialogReviewImportUploadComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('targetFile') targetFile!: ElementRef;
  constructor(
    private dialogRef: MatDialogRef<DialogReviewImportUploadComponent>,
    private backendService: BackendService,
    private adminService: AdminService,
    @Optional() private reviewImportService: ImportService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reviewAppPlatform: ReviewAppPlatform;
    },
    private route: Router,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.clickInputFile();
  }

  ngOnDestroy(): void {
    clearInterval(this.checkReviewImportProgress);
  }

  mode: 'import' | 'cancel' = 'import';

  fileName: string = '';
  reviewCountFromCSV: number = 0;
  progressSize: number = 0;
  bufferSize: number = 100;

  reviewImportLog: ReviewImportLog = new ReviewImportLog();
  checkReviewImportProgress: any;
  TransferStatus = this.reviewImportService.TransferStatus;
  submitLock = false;

  clickInputFile() {
    this.targetFile.nativeElement.click();
  }

  onCancelClick() {
    this.reviewImportService.deleteReviewImportLog(this.reviewImportLog, DeleteType.CANCEL).subscribe((response) => {
      this.reviewImportLog = response.body as ReviewImportLog;
      this.mode = 'import';
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }

  viewImportedReviewRoute() {
    this.dialogRef.close();
    this.route.navigate(['/admin/product-reviews/reviews'], { state: { log_id: this.reviewImportLog.id } });
  }

  onFileChange() {
    this.fileName = this.targetFile.nativeElement.files ? this.targetFile.nativeElement.files[0].name : '';
    this.checkReviewCountFromCSV();
  }

  checkReviewCountFromCSV() {
    let url = 'reviews/import/length_check';
    let data = new FormData();

    data.append('file', this.targetFile.nativeElement.files[0]);

    this.backendService.form(url, data, { shop_access_code: this.adminService.getShopAccessCode(), review_platform: this.data.reviewAppPlatform }).subscribe((response) => {
      this.reviewCountFromCSV = response;
    });
  }

  startImportReview() {
    let url = 'reviews/import/';
    let data = new FormData();

    data.append('file', this.targetFile.nativeElement.files[0]);
    this.submitLock = true;

    this.backendService
      .form(url, data, {
        shop_access_code: this.adminService.getShopAccessCode(),
        review_platform: this.data.reviewAppPlatform,
        author: this.adminService.ShopAdminAccount.user_name,
      })
      .subscribe({
        next: (response) => {
          this.submitLock = false;
          this.reviewImportLog = response as ReviewImportLog;

          this.checkReviewImportProgress = setInterval(() => {
            if (this.reviewImportLog.progress_status > TransferStatus.IN_PROGRESS) {
              clearInterval(this.checkReviewImportProgress);
              return;
            }
            this.reviewImportService.pollingReviewImportProgress(this.reviewImportLog).subscribe((response: any) => {
              this.reviewImportLog = response.body as ReviewImportLog;
              this.progressSize = Math.floor((this.reviewImportLog.task_count / this.reviewCountFromCSV) * 100);
            });
          }, 3000);
        },
        error: (error) => {
          if (error.status == 400) {
            this.reviewImportLog.progress_status = this.TransferStatus.FAILED;
            this.reviewImportLog.error_reason = error.error.detail;
          }
        },
      });
  }
}
