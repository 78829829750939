import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuperAdminRoutingModule } from './super-admin-routing.module';
import { LoginComponent } from './login/super-admin-login.component';
import { RouterModule } from '@angular/router';
import { SuperAdminComponent } from './super-admin.component';
import { LeftNavigationBarComponent } from './layout/left-navigation-bar/left-navigation-bar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SuperAdminHeaderComponent } from './layout/super-admin-header/super-admin-header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SuperAdminRegistComponent } from './regist/super-admin-regist.component';
import { MatIconModule } from '@angular/material/icon';
import { SuperAdminService } from '../service/super-admin.service';
import { AuthSuperAdminService } from '../auth/auth-super-admin.service';
import { BackendService } from '../service/backend.service';
import { ShopsModule } from './shops/shops.module';
import { SuperAdminSharedModule } from './super-admin-shared/super-admin-shared.module';
import { AccountModule } from '../admin/account/account.module';
import { PostsModule } from './posts/posts.module';
import { GenericService } from '../service/generic.service';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [LoginComponent, SuperAdminComponent, LeftNavigationBarComponent, SuperAdminHeaderComponent, SuperAdminRegistComponent],
  imports: [
    CommonModule,
    SuperAdminRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    ShopsModule,
    SuperAdminSharedModule,
    AccountModule,
    PostsModule,
  ],
  providers: [SuperAdminService, AuthSuperAdminService, BackendService, GenericService],
})
export class SuperAdminModule {}
