<span id="admin-account-rold-description" [ngSwitch]="shopAdminAccount.position">
  <span *ngSwitchCase="shopService.ShopAccountPosition.OWNER">
    Owner can access to every pages including "Add or Edit Members".
  </span>
  <span *ngSwitchCase="shopService.ShopAccountPosition.MANAGER">
    Manager can access to every pages except for editing or deleting members.
  </span>
  <span *ngSwitchCase="shopService.ShopAccountPosition.STAFF">
    Staff can access to every pages except for "Settings".
  </span>
</span>
