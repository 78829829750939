import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from 'src/app/guard/admin.guard';
import { AnalysisDetailComponent } from './analysis-detail/analysis-detail.component';

const routes: Routes = [
  {
    path: 'aliexpress-analysis',
    canActivate: [AdminGuard],
    data: { title: 'AliExpress Analysis' },
    loadChildren: () => import('./ai-aliexpress/ai-aliexpress.module').then((m) => m.AiAliexpressModule),
  },
  { path: 'analysis-details', canActivate: [AdminGuard], component: AnalysisDetailComponent },
  { path: '', pathMatch: 'full', redirectTo: 'aliexpress-analysis' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AlphaAiRoutingModule {}
