<article id="profile-account">
  <section class="gray-border-wrapper" style="padding-bottom: 9px">
    <header class="admin-header-3" style="margin-bottom: 20px">
      <div>Invite and Manage Members</div>
      <button class="btn-black" style="margin-left: auto" (click)="openDialogAdminAccountInvite()" *ngIf="adminService.OnwerAccountDo">Add member</button>
    </header>
    <table mat-table #matTable [dataSource]="dataSource" style="width: 100%; border-top: 1px solid whitesmoke">
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef>Role</th>
        <td *matCellDef="let element" mat-cell>
          <div class="member-position">
            {{ shopService.ShopAccountPosition[element.position] | titlecase }}
          </div>
        </td>
        <!-- <td class="member-name" mat-cell >{{ element.first_name }} {{ element.last_name }}</td> -->
      </ng-container>

      <ng-container matColumnDef="member&email">
        <th mat-header-cell *matHeaderCellDef>Member/Email</th>
        <td mat-cell *matCellDef="let element">
          <div class="member-name" style="margin-bottom: 4px">{{ element.first_name }} {{ element.last_name }}</div>
          <div class="member-email">
            {{ element.account_id }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status&lastAccess">
        <th mat-header-cell *matHeaderCellDef>Status/Last Access</th>
        <td mat-cell *matCellDef="let element">
          <ng-container [ngSwitch]="element.status">
            <div class="member-status" *ngSwitchCase="shopService.ShopAccountStatus.DISABLE">
              <div class="member-status-circle disable"></div>
              <div>Disabled</div>
            </div>
            <div class="member-status sent" *ngSwitchCase="shopService.ShopAccountStatus.EMAIL_SENT">
              <div class="member-status-circle sent"></div>
              Sent Invitation
            </div>
            <div class="member-status active" *ngSwitchCase="shopService.ShopAccountStatus.ACTIVE">
              <div class="member-status-circle active"></div>
              Active
            </div>
          </ng-container>
          <div style="margin-top: 4px">
            {{ element.last_access_at | date : 'medium' }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Edit</th>
        <td mat-cell *matCellDef="let element">
          <div (click)="openDialogAdminAccountUpdatePosition(element)" class="btn-white" style="width: 47px">Edit</div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns" style="position: sticky"></tr>
    </table>
    <mat-paginator #paginator (page)="($event)" [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons [disabled]="false" aria-label="Select page">
      <input class="admin-input" />
    </mat-paginator>
  </section>
  <!-- <div class="delete-app" (click)="openDialogDeleteApp()">Delete App</div> -->
</article>
