import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { LandingMainComponent } from './landing-main/landing-main.component';
import { SwiperModule } from 'swiper/angular';
import { LandingCollectComponent } from './landing-collect/landing-collect.component';
import { LandingSubFooterComponent } from './landing-sub-footer/landing-sub-footer.component';
import { LandingManageComponent } from './landing-manage/landing-manage.component';
import { LandingShowcaseComponent } from './landing-showcase/landing-showcase.component';
import { LandingMarketingComponent } from './landing-marketing/landing-marketing.component';
import { LayoutModule } from '@angular/cdk/layout';

@NgModule({
  declarations: [
    LandingMainComponent,
    LandingCollectComponent,
    LandingSubFooterComponent,
    LandingManageComponent,
    LandingShowcaseComponent,
    LandingMarketingComponent,
  ],
  imports: [CommonModule, HomeRoutingModule, SwiperModule, LayoutModule],
  exports: [LandingSubFooterComponent],
})
export class HomeModule {}
