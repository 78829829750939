import { Component, OnInit } from '@angular/core';
import { GenericService } from 'src/app/service/generic.service';

@Component({
  selector: 'app-touchpoints',
  templateUrl: './touchpoints.component.html',
  styleUrls: ['./touchpoints.component.less'],
})
export class TouchpointsComponent implements OnInit {
  constructor(public genericService: GenericService) {}

  ngOnInit(): void {}
}
