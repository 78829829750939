import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { GenericService } from './generic.service';
import { CustomConfirmDialogComponent } from '../dialog/custom-confirm-dialog/custom-confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LeftNavigationBarService } from '../admin/layout/left-navigation-bar/left-navigation-bar.service';
import { AdminService } from './admin.service';
import { AlphaApps, ShopProduction } from '../models/shop/profile/store-detail.model';

@Injectable({
  providedIn: 'root',
})
export class RouterExtService {
  private previousUrl!: string;
  private currentUrl!: string;
  private history: string[] = [];
  public lnbTrigger: boolean = false;

  constructor(private router: Router, private adminService: AdminService, private genericService: GenericService, private dialog: MatDialog) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;

        this.changeAccessCode();
      }
    });
    this.saveHistory();
    this.popHistory();
  }

  setUrl() {
    this.currentUrl = this.router.url;
  }

  getPreviousUrl() {
    return this.previousUrl;
  }

  public saveHistory(): void {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        let value = e.urlAfterRedirects.split('/').reverse()[0];
        let depth = e.urlAfterRedirects.split('/').reverse()[1];
        let previousValue = this.previousUrl.split('/').reverse()[0];
        this.history.push(value);

        switch (value) {
          case 'import':
            this.genericService.LNBCategory = 'From Aliexpress';
            break;
          case 'sales-popup':
            this.genericService.LNBCategory = 'Homepage';
            break;
          case 'touchpoints':
            this.genericService.LNBCategory = 'Notification Emails';
            break;
          case 'email-custom':
            break;
          default:
            this.genericService.LNBCategory = value;
            break;
        }
        this.history = [...new Set(this.history)];

        switch (depth) {
          case 'group':
          case 'advanced-manage':
          case 'review-settings':
          case 'auto-request':
          case 'aliexpress-analysis':
          case 'list-designer':
            this.genericService.LNBCategory = depth;
            break;
          case 'touchpoints':
            this.genericService.LNBCategory = 'Notification Emails';
            break;
        }

        sessionStorage.setItem('history', JSON.stringify(this.history));
      }
    });
  }

  public popHistory(): void {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        if (e.navigationTrigger === 'popstate' && !this.lnbTrigger) {
          this.history.pop();
        }
      }
    });
  }

  public getHistory(): string[] {
    return this.history;
  }

  async leavePage() {
    let dialogRef = this.dialog.open(CustomConfirmDialogComponent, {
      width: '480px',
    });
    let instance = dialogRef.componentInstance;
    instance.header = 'Unsaved Changes Detected';
    instance.content = 'You have unsaved changes. Are you sure you want to leave this\npage without saving? All unsaved changes will be lost.';
    instance.confirm = 'Leave Page';
    instance.cancel = 'Stay on Page';

    return dialogRef
      .afterClosed()
      .toPromise()
      .then((result: Promise<boolean>) => {
        return Promise.resolve(result);
      });
  }

  changeAccessCode() {
    if (this.currentUrl.includes('onboarding')) return;
    let flag = this.currentUrl.includes('browse-booster') || !this.adminService.ShopAdminAccount.shop?.shop_detail?.use_production.includes(ShopProduction.OG);
    if (this.adminService.getShopAccessCode()?.length <= 6) {
      flag ? this.adminService.setShopAccessCode(`BB${this.adminService.getShopAccessCode()}`) : this.adminService.setShopAccessCode(`AA${this.adminService.getShopAccessCode()}`);
    } else {
      flag
        ? this.adminService.setShopAccessCode(`BB${this.adminService.getShopAccessCode()?.substring(2)}`)
        : this.adminService.setShopAccessCode(`AA${this.adminService.getShopAccessCode()?.substring(2)}`);
    }
  }
}
