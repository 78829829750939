<article id="write-dialog-notice" style="padding: 30px 16px 20px;">
  <header class="write-dialog-header">
    Not allowed
  </header>

  <section class="write-dialog-content">
    This product is restricted to
    writing a review.
  </section>

  <section class="write-dialog-btn-container">
    <button class="write-dialog-btn write-dialog-btn-cancel" (click)="onClickCancel()">
      Cancel
    </button>

    <button class="write-dialog-btn write-dialog-btn-confirm" (click)="onClickConfirm()">
      Confirm
    </button>

  </section>
</article>