import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AiAnalysis } from 'src/app/models/ai/ai.model';
import { ReviewAppPlatform } from 'src/app/models/shop/review/options/review-import.model';

@Component({
  selector: 'app-ali-analysis-template',
  templateUrl: './ali-analysis-template.component.html',
  styleUrls: ['./ali-analysis-template.component.less'],
})
export class AliAnalysisTemplateComponent implements OnInit {
  @Input() analysis!: AiAnalysis;
  constructor(private router: Router) {}
  neutralProportion: number = 0;

  ngOnInit(): void {
    this.neutralProportion = 100 - (this.analysis.negative_proportion + this.analysis.positive_proportion);
  }

  goToAliexpress() {
    window.open(this.analysis.analysis_log.url);
  }

  viewDetails(id: number) {
    this.router.navigate(['/admin/ai-analysis/analysis-details'], { state: { id: id, platform: ReviewAppPlatform.ALI_EXPRESS } });
  }
}
