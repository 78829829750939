import { Component, Inject, OnInit, Optional, SkipSelf } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BillingService } from 'src/app/service/billing.service';

@Component({
  selector: 'app-dialog-setting-subscription-downgrade',
  templateUrl: './dialog-setting-subscription-downgrade.component.html',
  styleUrls: ['./dialog-setting-subscription-downgrade.component.less', '../../admin.component.less'],
})
export class DialogSettingSubscriptionDowngradeComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DialogSettingSubscriptionDowngradeComponent>,
    @Optional() @SkipSelf() public billingService: BillingService,
  ) // @Inject(MAT_DIALOG_DATA) public data: {}
  {}

  ngOnInit(): void {}

  onClickDowngrade() {
    this.dialogRef.close(true);
  }

  goToSupportChannel() {
    window.open('https://a-review.channel.io/home', '_blank');
    this.dialogRef.close(false);
  }
}
