<main id="landing-main">
  <article class="main-visual box">
    <section class="main-visual-img">
      <div class="main-visual-img-container">
        <img
          class="main-visual-img-bg"
          src="assets/image/landing/main-visual-bg.png"
        />
        <img
          #mainVisual
          class="main-visual-img-top"
          src="assets/image/landing/main-visual.png"
        />
      </div>
    </section>
    <section class="main-visual-text">
      <div class="main-visual-slogan">
        ALL-IN-ONE <br />
        <span>Review App</span>
      </div>
      <div class="main-visual-sub-slogan">
        <swiper
          [direction]="'vertical'"
          [loop]="true"
          [autoplay]="{ delay: 1000 }"
          [speed]="1200"
          class="main-visual-sub-swiper"
          #swiperSlideShow
        >
          <ng-template swiperSlide>Connect to Other Apps</ng-template>
          <ng-template swiperSlide>Collect More Reviews</ng-template>
          <ng-template swiperSlide>Easily Moderate Reviews</ng-template>
          <ng-template swiperSlide>Display Reviews Beautifully</ng-template>
          <ng-template swiperSlide>Make Reviews Go Viral</ng-template>
        </swiper>
        <div class="main-visual-sub-title focus-text">With Alpha Review</div>
      </div>
      <div class="main-visual-btn">
        <button
          class="landing-btn-black"
          (click)="landingService.startFreeTrial()"
        >
          Start Free Trial
        </button>
      </div>
    </section>
  </article>
  <!-- main visual end -->

  <article class="main-checkout box">
    <header class="main-checkout-header">
      <div class="main-checkout-header-bubble">
        <img
          style="width: 522px; height: 75px"
          src="assets/icon/landing-text-bubble.svg"
        />
        <span class="main-checkout-header-bubble-text"
          >What makes AlphaReview Different?</span
        >
      </div>
      <h2 class="main-checkout-header-title">
        Check out AlphaReview <span class="focus-text">Exclusive</span> Features
      </h2>
    </header>

    <section class="main-checkout-container inner">
      <div class="main-checkout-top" *ngIf="!landingService.mobile">
        <img src="assets/image/landing/checkout-header.png" alt="" />
      </div>
      <swiper
        #checkoutSwiper
        [config]="checkoutConfig"
        style="padding-bottom: 165px"
      >
        <ng-template swiperSlide>
          <div class="main-checkout-content">
            <div class="main-checkout-description">
              <h3 class="main-checkout-description-title">
                Selects and Displays <br class="mobr" />
                <span class="focus-text">the Best Reviews</span> <br />
                with <span class="focus-text">Auto-pilot</span>
              </h3>
              <p
                class="main-checkout-description-summary"
                *ngIf="!landingService.mobile"
              >
                You no longer have to go through all the reviews and flag the
                best ones on your own. AlphaReview automatically selects the
                best reviews based on your criteria and showcase them on the
                appropriate widgets.
              </p>
            </div>
            <div class="main-checkout-bg">
              <img
                class="mobile-size"
                src="assets/image/landing/checkout-01.png"
                style="max-width: 681px; max-height: 695px"
              />
            </div>
          </div>
        </ng-template>
        <!-- checkout 1 end  -->
        <ng-template swiperSlide>
          <div
            class="main-checkout-content"
            style="justify-content: flex-start; height: 100%"
          >
            <div
              class="main-checkout-description"
              style="max-width: 520px; padding-bottom: 100px"
            >
              <h3 class="main-checkout-description-title">
                Share Reviews <br />
                Across Products <br />
                with <span class="focus-text">Review Groups</span>
              </h3>
              <p
                class="main-checkout-description-summary"
                *ngIf="!landingService.mobile"
              >
                We provide the feature to create groups to share reviews between
                products. You can collect and show reviews of similar but
                different products by grouping products.
              </p>
            </div>
            <div
              class="main-checkout-bg"
              style="max-width: 730px; position: absolute; right: 0; top: 0"
            >
              <img
                src="assets/image/landing/checkout-02.png"
                style="width: 100%"
              />
            </div>
          </div>
        </ng-template>
        <!-- checkout 2 end -->
        <ng-template swiperSlide>
          <div
            class="main-checkout-content"
            style="justify-content: flex-start; height: 100%"
          >
            <div
              class="main-checkout-description"
              style="max-width: 520px; padding-bottom: 100px"
            >
              <h3 class="main-checkout-description-title">
                Make Reviewing <br />

                Process <span class="focus-text">Joyful</span>
              </h3>
              <p
                class="main-checkout-description-summary"
                *ngIf="!landingService.mobile"
              >
                AlphaReview provides the most beautiful and advanced "Write a
                Review Page" so that customers can enjoy the process of
                reviewing. Enhance customer experience with this AlphaReview
                exclusive feature.
              </p>
            </div>
            <div class="after" *ngIf="!landingService.mobile"></div>
            <div
              class="main-checkout-bg"
              style="
                max-width: 765px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
              "
            >
              <img
                src="assets/image/landing/checkout-03.png"
                style="width: 100%"
              />
            </div>
          </div>
        </ng-template>
        <!-- checkout 3 end -->
        <ng-template swiperSlide>
          <div
            class="main-checkout-content"
            style="justify-content: flex-start; height: 100%"
          >
            <div
              class="main-checkout-description"
              style="max-width: 588px; padding-bottom: 100px"
            >
              <h3 class="main-checkout-description-title">
                <span class="focus-text">Incentivize</span> Reviewers <br />
                with <span class="focus-text">Customizable Coupons</span>
              </h3>
              <p
                class="main-checkout-description-summary"
                *ngIf="!landingService.mobile"
              >
                Incentivizing is a good way to get more reviews with the higher
                quality. You can set reward value differently by customer
                groups, review types, review quality, and order and product
                amount range.
              </p>
            </div>
            <div class="after"></div>
            <div
              class="main-checkout-bg"
              style="
                max-width: 832px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
              "
            >
              <img
                src="assets/image/landing/checkout-04.png"
                style="width: 100%"
              />
            </div>
          </div>
        </ng-template>
        <!-- checkout 4 end -->
        <ng-template swiperSlide>
          <div
            class="main-checkout-content"
            style="justify-content: flex-start; height: 100%"
          >
            <div
              class="main-checkout-description"
              style="max-width: 545px; padding-bottom: 100px"
            >
              <h3 class="main-checkout-description-title">
                The Easiest Way to <br />
                Write a Review, <br />
                <span class="focus-text">Quick Review</span>
              </h3>
              <p
                class="main-checkout-description-summary"
                *ngIf="!landingService.mobile"
              >
                Quick Review is the easiest way to write a review with just one
                click. The review request sent to the customers involves
                satisfaction buttons, which are immediately created in
                satisfaction text when clicked. Text of reviews by each
                satisfaction can be set through AlphaReview admin.
              </p>
            </div>
            <div class="after" style="bottom: 0"></div>
            <div
              class="main-checkout-bg"
              style="
                max-width: 675px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
              "
            >
              <img
                class="mobile-size"
                src="assets/image/landing/checkout-05.png"
                style="width: 100%"
              />
            </div>
          </div>
        </ng-template>
        <!-- checkout 5 end -->
        <ng-template swiperSlide>
          <div class="main-checkout-content">
            <div class="main-checkout-description" style="padding-bottom: 0">
              <h3 class="main-checkout-description-title">
                Manage
                <span class="focus-text">Customer Relationship</span> Smartly
                with Review Replies
              </h3>
              <p
                class="main-checkout-description-summary"
                *ngIf="!landingService.mobile"
              >
                Responding to all customer reviews is essential to protect your
                brand reputation, build stronger relationships with customers,
                and boost customers' loyalty, whether the reviews are positive
                or negative.
              </p>
            </div>
            <div class="main-checkout-bg">
              <img
                src="assets/image/landing/checkout-06.png"
                style="max-width: 715px; max-height: 600px"
              />
            </div>
          </div>
        </ng-template>
        <!-- checkout 6 end -->
        <ng-template swiperSlide>
          <div
            class="main-checkout-content"
            style="justify-content: flex-start; height: 100%"
          >
            <div
              class="main-checkout-description"
              style="max-width: 545px; padding-bottom: 90px"
            >
              <h3 class="main-checkout-description-title">
                Let Customers <br />
                Be Your <span class="focus-text">Best Marketers</span>
              </h3>
              <p
                class="main-checkout-description-summary"
                *ngIf="!landingService.mobile"
              >
                AlphaReview encourages customers to write not only basic product
                reviews but also social reviews. When a customer posts a review
                on Instagram, we automatically bring it to your online store.
              </p>
            </div>
            <!-- <div class="after" style="bottom: 0"></div> -->
            <div
              class="main-checkout-bg"
              style="
                max-width: 820px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
              "
            >
              <img
                src="assets/image/landing/checkout-07.png"
                style="width: 100%"
              />
            </div>
          </div>
        </ng-template>
        <!-- checkout 7 end -->
      </swiper>
    </section>
  </article>
  <!-- main checkout end -->

  <article class="main-center-menu box">
    <div class="main-center-container">
      <header class="main-center-menu-header landing-header">
        On Top of That, <br class="mobr" />
        AlphaReview has <br class="mobr" />
        <span class="focus-text">ALL</span> What You Need
      </header>
      <section class="main-center-menu-list">
        <div
          #Collect
          class="main-center-menu-box"
          [ngClass]="{ hover: collect }"
          (mouseover)="collect = true"
          (mouseleave)="collect = false"
          [routerLink]="'/home/collect'"
        >
          <div class="main-center-menu-img">
            <img src="assets/image/landing/center-menu-01.png" alt="" />
            <img
              [@inOutAnimation]
              *ngIf="collect"
              class="main-center-menu-arrow"
              src="assets/image/landing/center-menu-arrow.png"
              alt=""
            />
          </div>
          <div class="main-center-menu-text">
            <h4>Collect Reviews</h4>
            <span
              ><span *ngIf="!landingService.mobile">For more </span>details
              <img src="assets/icon/arrow_right_gray.svg" alt="" />
            </span>
          </div>
        </div>
        <div
          #Collect
          class="main-center-menu-box"
          [ngClass]="{ hover: manage }"
          (mouseover)="manage = true"
          (mouseleave)="manage = false"
          [routerLink]="'/home/moderate'"
        >
          <div class="main-center-menu-img">
            <img src="assets/image/landing/center-menu-02.png" alt="" />
            <img
              [@inOutAnimation]
              *ngIf="manage"
              class="main-center-menu-arrow"
              src="assets/image/landing/center-menu-arrow.png"
              alt=""
            />
          </div>
          <div class="main-center-menu-text">
            <h4>Manage & Moderate</h4>
            <span
              ><span *ngIf="!landingService.mobile">For more </span>details
              <img src="assets/icon/arrow_right_gray.svg" alt="" />
            </span>
          </div>
        </div>
        <div
          class="main-center-menu-box"
          [ngClass]="{ hover: showCase }"
          (mouseover)="showCase = true"
          (mouseleave)="showCase = false"
          [routerLink]="'/home/showcase'"
        >
          <div class="main-center-menu-img">
            <img src="assets/image/landing/center-menu-03.png" alt="" />
            <img
              [@inOutAnimation]
              *ngIf="showCase"
              class="main-center-menu-arrow"
              src="assets/image/landing/center-menu-arrow.png"
              alt=""
            />
          </div>
          <div class="main-center-menu-text">
            <h4>Showcase Reviews</h4>
            <span
              ><span *ngIf="!landingService.mobile">For more </span>details
              <img src="assets/icon/arrow_right_gray.svg" alt="" />
            </span>
          </div>
        </div>
        <div
          #Collect
          class="main-center-menu-box"
          [ngClass]="{ hover: marketing }"
          (mouseover)="marketing = true"
          (mouseleave)="marketing = false"
          [routerLink]="'/home/marketing'"
        >
          <div class="main-center-menu-img">
            <img src="assets/image/landing/center-menu-04.png" alt="" />
            <img
              [@inOutAnimation]
              *ngIf="marketing"
              class="main-center-menu-arrow"
              src="assets/image/landing/center-menu-arrow.png"
              alt=""
            />
          </div>
          <div class="main-center-menu-text">
            <h4>Marketing & SEO</h4>
            <span
              ><span *ngIf="!landingService.mobile">For more </span>details
              <img src="assets/icon/arrow_right_gray.svg" alt="" />
            </span>
          </div>
        </div>
      </section>
    </div>
  </article>

  <!-- main center menu end -->

  <article
    class="main-boost box"
    (mouseover)="boostGraph = true; fadeText = true"
    (mouseout)="boostGraph = false"
  >
    <div class="inner">
      <header
        class="main-boost-header landing-header"
        *ngIf="!landingService.mobile"
      >
        <span class="main-boost-fade" *ngIf="!fadeText" [@fadeText]
          >Want to Boost Sales?</span
        >
        <span *ngIf="fadeText" class="main-boost-fade" [@fadeText]
          >Reviews are the Best Way to
          <span class="focus-text">Boost Sales</span></span
        >
      </header>
      <header
        class="main-boost-header landing-header"
        *ngIf="landingService.mobile"
      >
        <span class="main-boost-fade"
          >Reviews are the Best Way <br />
          to <span class="focus-text">Boost Sales</span></span
        >
      </header>
      <section class="main-boost-graph">
        <header class="main-boost-graph-header">
          Reviews have a significant impact <br />
          on purchase decisions
        </header>

        <section class="main-boost-graph-container">
          <div class="main-boost-graph-box" style="margin-right: 10px">
            <div
              [ngClass]="{ animate: boostGraph }"
              class="pie no-round"
              style="--p: 89; --c: white; --b: 15px"
            >
              89%
            </div>
            <p>
              of consumers <br class="mobr" />
              consult <br />
              product <br class="mobr" />
              reviews <br />
              before <br class="mobr" />
              making a <br />
              purchase
            </p>
          </div>

          <div class="main-boost-graph-box">
            <div
              [ngClass]="{ animate: boostGraph }"
              class="pie no-round"
              style="--p: 78; --c: white; --b: 15px"
            >
              78%
            </div>
            <p>
              of consumers said <br class="mobr" />
              product <br />
              <br class="mobr" />
              reviews have biggest <br />
              <br class="mobr" />
              influence on their <br class="mobr" />
              purchase <br />
              decisions
            </p>
          </div>
        </section>
        <span class="main-boost-graph-source" *ngIf="!landingService.mobile"
          >Source: 2022 BazaarVoice 'What's in a review?'</span
        >
        <span class="main-boost-graph-bg" *ngIf="!landingService.mobile">
          <img src="assets/image/landing/boost-buy.png" />
        </span>
      </section>
      <!-- graph end -->
      <span class="main-boost-graph-source" *ngIf="landingService.mobile"
        >Source: 2022 BazaarVoice 'What's in a review?'</span
      >

      <section class="main-boost-other">
        <header class="main-boost-other-header focus-text">
          Reviews can boost sales <br />
          in many other ways
        </header>

        <section class="main-boost-other-container">
          <span class="main-boost-other-bg" *ngIf="!landingService.mobile">
            <img src="assets/image/landing/boost-other.png" />
          </span>

          <p class="main-boost-other-text">
            Reviews increase trust and loyalty
          </p>
          <p class="main-boost-other-text">
            Reviews give customers insights <br class="mobr" />
            into your product
          </p>
          <p class="main-boost-other-text">
            Reviews are a great marketing tool <br class="mobr" />
            for your business
          </p>
          <p class="main-boost-other-text">
            Reviews help improve customer <br class="mobr" />
            service and product quality
          </p>
        </section>
      </section>
      <!-- other end -->
    </div>
  </article>
  <!-- main boost end -->

  <article class="main-quickly">
    <div class="inner" style="position: relative">
      <header class="main-quickly-header landing-header white">
        <span style="color: #b9afce">Get Started</span> Easily and Quickly
      </header>
      <summary
        class="landing-summary"
        style="padding-top: 15px"
        *ngIf="!landingService.mobile"
      >
        Starting a new app can be another hassle for busy people. <br />
        But starting AlphaReview is easy and quick.
      </summary>
      <button
        class="landing-btn-white"
        style="margin: 30px 0 40px"
        (click)="landingService.startFreeTrial()"
      >
        Start Free Trial
      </button>

      <section class="main-quickly-icon">
        <p class="main-quickly-download">
          <img
            style="margin-right: 13.5px"
            src="assets/icon/landing-quickly-download.svg"
          />
          Install AlphaReview FOR FREE
        </p>
        <p class="main-quickly-clock">
          <img
            style="margin-right: 12px"
            src="assets/icon/landing-quickly-clock.svg"
          />
          Quick and Easy Onboarding
        </p>
        <p class="main-quickly-tools">
          <img
            style="margin-right: 17.9px"
            src="assets/icon/landing-quickly-tools.svg"
          />
          1-Click Easy Widgets Setup
        </p>
        <p class="main-quickly-repeat">
          <img
            style="margin-right: 18.2px"
            src="assets/icon/landing-quickly-repeat.svg"
          />
          Migrate Existing Reviews in CSV
        </p>
      </section>
      <div class="main-quickly-image">
        <img src="assets/image/landing/quickly.png" />
      </div>
    </div>
  </article>
  <!-- main quickly end -->

  <article class="main-collect">
    <div class="inner">
      <header class="main-collect-header landing-header">
        Collect <span class="focus-text">More Reviews</span> <br class="mobr" />
        in Smartest Way
        <br />
        and Sell More
      </header>
      <summary class="landing-summary" *ngIf="!landingService.mobile">
        Customers easily forget to leave reviews and think it’s pretty
        troublesome. <br />
        Then, how would AlphaReview solve this problem?
      </summary>

      <div class="landing-flex-wrapper" style="margin-top: 50px">
        <div class="main-collect-img-container" *ngIf="!landingService.mobile">
          <ng-container *ngFor="let collect of landingService.collectAndManage">
            <section
              class="main-collect-img-box"
              *ngIf="collect._isClick"
              [@upDownAnimation]
            >
              <img src="{{ collect.image }}" alt="" />
            </section>
          </ng-container>
        </div>

        <div class="main-collect-summary-container">
          <ng-container *ngFor="let collect of landingService.collectAndManage">
            <section
              class="main-collect-summary-box homeTimer"
              (click)="
                landingService.isPause = true;
                landingService.clickCollect(
                  collect,
                  landingService.collectAndManage
                )
              "
              [ngClass]="{ active: collect._isClick == true }"
            >
              <div class="show-box">
                <header>
                  {{ collect.title }}
                </header>
                <p>
                  {{ collect.summary }}
                </p>
              </div>
            </section>
          </ng-container>
        </div>
      </div>

      <button
        routerLink="/home/collect"
        class="landing-btn-purple"
        style="margin-top: 65px"
        *ngIf="!landingService.mobile"
      >
        For more details
        <img
          style="margin-left: 9px"
          src="assets/icon/arrow_right_purple.svg"
          alt=""
        />
      </button>
    </div>

    <section class="main-manage">
      <header class="main-manage-header landing-header">
        Manage and Moderate <span class="focus-text">Reviews</span> <br />
        More <span class="focus-text">Easily</span> and
        <span class="focus-text">Smartly</span>
      </header>
      <button
        routerLink="/home/moderate"
        class="landing-btn-purple"
        style="margin-top: 20px"
        *ngIf="landingService.mobile"
      >
        For more details
        <img
          style="margin-left: 9px"
          src="assets/icon/arrow_right_purple.svg"
        />
      </button>
      <summary class="landing-summary" *ngIf="!landingService.mobile">
        Sending requests, collecting reviews, and giving rewards are all
        critical factors that merchants should consider, <br />
        but they are also quite a hassle. With AlphaReview, all of them can be
        easily managed and moderated.
      </summary>

      <section class="landing-flex-wrapper inner">
        <div class="main-manage-content">
          <img src="assets/image/landing/manage-01.png" />
          <p class="main-manage-text">
            Manage requests <br />
            with an easy-to-use <br />
            request queue
          </p>
        </div>
        <div class="main-manage-content">
          <img src="assets/image/landing/manage-02.png" />
          <p class="main-manage-text">
            Save time <br />
            with auto-publish <br />
            & auto-reward features
          </p>
        </div>
        <div class="main-manage-content">
          <img src="assets/image/landing/manage-03.png" />
          <p class="main-manage-text">
            Auto-reply <br />
            for better CRM
          </p>
        </div>
      </section>

      <button
        routerLink="/home/moderate"
        class="landing-btn-purple"
        style="margin-top: 30px"
        *ngIf="!landingService.mobile"
      >
        For more details
        <img
          style="margin-left: 9px"
          src="assets/icon/arrow_right_purple.svg"
        />
      </button>
    </section>
  </article>
  <!-- main collect end -->

  <article class="main-showcase">
    <div class="inner">
      <header class="landing-header">
        <span class="focus-text">Showcase</span> Reviews Beautifully <br />
        and Boost Sales
      </header>
      <summary class="landing-summary" *ngIf="!landingService.mobile">
        The store's design influences the customers’ purchase decisions more
        than expected. <br />
        Therefore, AlphaReview offers the most beautiful widgets which are fully
        customizable.
      </summary>
      <swiper
        #showcaseSwiper
        [config]="showcaseConfig"
        style="margin: 50px 0 0"
      >
        <ng-template swiperSlide>
          <div class="main-showcase-widget">
            <img
              *ngIf="!landingService.mobile"
              src="assets/image/landing/widget-reviews.png"
            />
            <img
              style="width: 70%; padding-top: 10px"
              *ngIf="landingService.mobile"
              src="assets/image/landing/widget-reviews-mo.png"
            />
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="main-showcase-widget">
            <img
              *ngIf="!landingService.mobile"
              src="assets/image/landing/widget-photos.png"
            />
            <img
              style="width: 80%"
              *ngIf="landingService.mobile"
              src="assets/image/landing/widget-photos-mo.png"
            />
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="main-showcase-widget">
            <img
              *ngIf="!landingService.mobile"
              src="assets/image/landing/widget-simple.png"
            />
            <img
              *ngIf="landingService.mobile"
              src="assets/image/landing/widget-simple-mo.png"
            />
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="main-showcase-widget">
            <img
              *ngIf="!landingService.mobile"
              src="assets/image/landing/widget-rating.png"
            />
            <img
              *ngIf="landingService.mobile"
              src="assets/image/landing/widget-rating-mo.png"
            />
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="main-showcase-widget">
            <img
              *ngIf="!landingService.mobile"
              src="assets/image/landing/widget-sns.png"
            />
            <img
              *ngIf="landingService.mobile"
              src="assets/image/landing/widget-sns-mo.png"
            />
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="main-showcase-widget">
            <img
              *ngIf="!landingService.mobile"
              src="assets/image/landing/widget-notice.png"
            />
            <img
              *ngIf="landingService.mobile"
              src="assets/image/landing/widget-notice-mo.png"
            />
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="main-showcase-widget">
            <img
              *ngIf="!landingService.mobile"
              src="assets/image/landing/widget-ranking.png"
            />
            <img
              *ngIf="landingService.mobile"
              src="assets/image/landing/widget-ranking-mo.png"
            />
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="main-showcase-widget">
            <img
              *ngIf="!landingService.mobile"
              src="assets/image/landing/widget-text.png"
            />
            <img
              style="width: 80%"
              *ngIf="landingService.mobile"
              src="assets/image/landing/widget-text-mo.png"
            />
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="main-showcase-widget">
            <img
              *ngIf="!landingService.mobile"
              src="assets/image/landing/widget-highlight.png"
            />
            <img
              *ngIf="landingService.mobile"
              src="assets/image/landing/widget-highlight-mo.png"
            />
          </div>
        </ng-template>
      </swiper>

      <section class="main-showcase-custom">
        <div class="main-showcase-custom-img">
          <img
            *ngIf="!landingService.mobile"
            src="assets/image/landing/widget-custom.png"
          />
          <img
            *ngIf="landingService.mobile"
            src="assets/image/landing/widget-custom-mo.png"
          />
        </div>
        <!-- <div class="main-showcase-custom-bg">
          <img src="assets/image/landing/widget-custom-bg.png" />
        </div>
        <div class="main-showcase-custom-img">
          <img src="assets/image/landing/widget-custom-top.png" />
        </div> -->
        <div class="main-showcase-custom-text">
          <img
            src="assets/icon/landing-checkbox-purple.svg"
            *ngIf="!landingService.mobile"
          />
          <header class="main-showcase-custom-header">
            Customize the widgets <br />
            to your shop's <br />
            unique style
          </header>
          <p
            class="main-showcase-custom-discription"
            *ngIf="!landingService.mobile"
          >
            Choose a widget you want, <br />
            change colors, font sizes, date formats, <br />
            style, and much more! <br />
            CSS JS editor is also available.
          </p>
        </div>
      </section>
    </div>
  </article>
  <div
    style="display: flex; justify-content: center"
    *ngIf="!landingService.mobile"
  >
    <button
      routerLink="/home/showcase"
      class="landing-btn-purple"
      style="margin: 30px auto 120px"
    >
      For more details
      <img style="margin-left: 9px" src="assets/icon/arrow_right_purple.svg" />
    </button>
  </div>
  <!-- main showcase end -->

  <article class="main-viral">
    <header class="landing-header">
      Make Reviews <span class="focus-text">Go Viral</span> <br />
      and <span class="focus-text">Increase Conversions</span>
    </header>
    <summary class="landing-summary" style="margin-bottom: 44px">
      Your product reviews can go viral by allowing customers to share the
      reviews on social media. <br />
      You can also amplify the reach of reviews with rich snippets and Google
      Shopping.
    </summary>
    <div
      class="inner"
      style="padding: 260px 120px; position: relative"
      *ngIf="!landingService.mobile"
    >
      <div class="main-viral-imgbox back">
        <img src="assets/image/landing/viral-01.png" />
      </div>
      <div class="main-viral-imgbox front">
        <img src="assets/image/landing/viral-02.png" />
      </div>
    </div>
    <button
      routerLink="/home/marketing"
      class="landing-btn-purple"
      style="margin-top: 44px"
    >
      For more details
      <img style="margin-left: 9px" src="assets/icon/arrow_right_purple.svg" />
    </button>
    <img
      *ngIf="landingService.mobile"
      src="assets/image/landing/landing-viral-mo.png"
      style="width: 100%"
    />
  </article>
  <!-- main viral end -->

  <article class="main-endpoint">
    <header class="main-endpoint-header">
      <div class="main-endpoint-header-bubble">
        <img
          style="width: 204px; height: 68px"
          src="assets/icon/landing-text-bubble-black.svg"
        />
        <span class="main-endpoint-header-bubble-text">Still not sure?</span>
      </div>
      <h2 class="main-endpoint-header-title landing-header">
        Check Out <span class="focus-text">WHY AlphaReview</span> is the Best
        Choice
      </h2>
    </header>
    <div class="inner">
      <section class="main-endpoint-card">
        <div class="main-endpoint-card-img">
          <img src="assets/image/landing/card-yellow.png" />
          <span>Use Cases</span>
        </div>
        <div class="main-endpoint-card-img">
          <img src="assets/image/landing/card-red.png" />
          <span>Demo Stores</span>
        </div>
        <div class="main-endpoint-card-img">
          <img src="assets/image/landing/card-green.png" />
          <span
            >AlphaReview <br />
            vs.</span
          >
        </div>
      </section>

      <section class="main-endpoint-brand">
        <header class="landing-header">
          1500+ Best Brands Chose AlphaReview
        </header>
        <div class="main-endpoint-brand-img" *ngIf="!landingService.mobile">
          <img src="assets/image/landing/brand.png" />
        </div>

        <div class="main-endpoint-brand-slider" *ngIf="landingService.mobile">
          <div class="marquee marquee1">
            <ul class="marquee-content">
              <li *ngFor="let logo of storeLogoA">
                <img src="{{ logo }}" />
              </li>
            </ul>
            <ul aria-hidden="true" class="marquee-content">
              <li *ngFor="let logo of storeLogoA">
                <img src="{{ logo }}" />
              </li>
            </ul>
          </div>
          <div class="marquee marquee2">
            <ul class="marquee-content">
              <li *ngFor="let logo of storeLogoB">
                <img src="{{ logo }}" />
              </li>
            </ul>
            <ul aria-hidden="true" class="marquee-content">
              <li *ngFor="let logo of storeLogoB">
                <img src="{{ logo }}" />
              </li>
            </ul>
          </div>
        </div>

        <div class="btn-wrapper">
          <button
            class="landing-btn-black"
            (click)="landingService.startFreeTrial()"
          >
            Start Free Trial
          </button>
        </div>
      </section>
    </div>
  </article>
</main>
