import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import SwiperCore, { SwiperOptions } from 'swiper';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomConfirmDialogComponent } from 'src/app/dialog/custom-confirm-dialog/custom-confirm-dialog.component';
import { GenericService } from 'src/app/service/generic.service';
import { ListDesignerService } from '../../list-designer.service';
import { inOutAnimation } from 'src/app/admin/animations';

@Component({
  selector: 'app-dialog-install-widget-guide',
  standalone: true,
  imports: [SwiperModule, CommonModule],
  templateUrl: './dialog-install-widget-guide.component.html',
  styleUrl: './dialog-install-widget-guide.component.less',
  animations: [inOutAnimation],
})
export class DialogInstallWidgetGuideComponent {
  @ViewChild(SwiperComponent) swiper!: SwiperComponent;
  constructor(
    @Inject(MAT_DIALOG_DATA) public code: string,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogInstallWidgetGuideComponent>,
    private genericService: GenericService,
    private listDesignerService: ListDesignerService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.slideIndex = this.swiper.swiperRef.activeIndex + 1;

    this.cdr.detectChanges();
  }

  swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    allowTouchMove: false,
  };

  slideIndex?: number;
  slideList?: number[];

  swipePrev() {
    this.swiper.swiperRef.slidePrev();
  }
  swipeNext() {
    this.swiper.swiperRef.slideNext();
  }
  getSlideIndex() {
    this.slideIndex = this.swiper.swiperRef.activeIndex + 1;
  }

  copyWidgetId(code: string) {
    navigator.clipboard.writeText(code).then(() => this.genericService.openSnackBar('The code has been copied to the clipboard.'));
  }

  goToThemeEditor() {
    this.listDesignerService.getDeepLinkingUrl();
    this.dialogRef.close(true);
  }

  onClose() {
    const dialog = this.dialog.open(CustomConfirmDialogComponent, { width: '431px', disableClose: true });
    let dialogInstance = dialog.componentInstance;

    dialogInstance.alignCenter = true;
    dialogInstance.header = `Would you like to leave
                            without applying the widget in Shopify?`;
    dialogInstance.content = `The widget you designed will be saved,
                              and you can check it in the widget list.`;
    dialogInstance.confirm = 'Leave';

    dialog.afterClosed().subscribe((result) => {
      this.dialogRef.close(result);
    });
  }
}
