import { Label } from '../shop/review/options/review-label.model';

export enum WidgetTheme {
  LIGHT = 1,
  DARK = 0,
}

export enum WidgetType {
  ALPHA_EMPTY_TEMPLATE = 'alpha_empty_template',
  ALPHA_REVIEWS = 'alpha_reviews',
  ALPHA_STORE_REVIEW = 'alpha_store_review',
  ALPHA_RATING = 'alpha_rating',
  ALPHA_WALL = 'alpha_wall',
  ALPHA_WALL_GRID = 'alpha_wall_grid',

  ALPHA_GALLERY = 'alpha_gallery',
  ALPHA_GALLERY_GRID = 'alpha_gallery_grid',

  ALPHA_RANKED = 'alpha_ranked',
  ALPHA_BADGE = 'alpha_badge',

  ALPHA_HIGHLIGHT = 'alpha_highlight',
  ALPHA_HIGHLIGHT_TEXT = 'alpha_highlight_text',

  ALPHA_SOCIAL = 'alpha_social',
  ALPHA_NOTICE = 'alpha_notice',

  ALPHA_DETAIL_REVIEW = 'alpha_detail_review',
  ALPHA_DETAIL_OTHER_REVIEW = 'alpha_detail_other_review',

  ALPHA_SIDE_DRAWER = 'alpha_side_drawer',
  ALPHA_POPUP = 'alpha_popup',
}

export interface Widget {
  id?: number;
  title: string;
  access_code: string;
  shop_id: number;
  created_at: Date;

  installation_position: WidgetInstallationPosition;

  widget_options: {};

  custom_css: string;
  custom_js: string;

  label_id?: number;
  label?: Label;

  _selected: boolean;
}

/**
 * ### 위젯 설치 위치

  | Name | Value | Description |
    | -----| -----| -------|
    | MAIN_PAGE | 1 | 메인페이지 |
    | PRODUCT_DETAIL_PAGE | 10 | 상품상세페이지 |
 */
export enum WidgetInstallationPosition {
  _COMMON_DESIGN = 0,
  MAIN_PAGE = 1,
  PRODUCT_DETAIL_PAGE = 10,
}

export enum CustomerNameFormat {
  ORIGIN = 0,
  USING_INITIALS_AFTER_THE_FIRST_NAME = 1,
  USING_INITIALS_FOR_THE_ENTIRE_AUTHORS_NAME = 2,
}

export interface CommonDesign {
  id: number;
  shop_id: number;
  rating_style: string;
  point_color: string;
  round: boolean;
  loading_type: string;
  date_format: string;
  display_rating: boolean;
  display_reviewer_name: boolean;
  product_price: boolean;
  created_at: boolean;
  like: boolean;
  review_comment: boolean;
  verified: boolean;
  customer_name_format: CustomerNameFormat;

  font_size: number;
  font_style: string;
  main_color: string;
  review_rating_limit: number;
  sorting_type: string;
  star_rating_product: boolean;
  star_rating_product_thumb: boolean;
  star_rating_cart: boolean;
}

export class WidgetInitialInstallation {
  theme_id: number = 0;
  widgets: WidgetPositionWithType[] = [];
  shop: string = '';
}

export enum WidgetPositionWithType {
  MAIN_PAGE__BEST_REVIEW = 'main_page__review_best',
  PRODUCT_DETAIL_PAGE__BOARD_REVIEW = 'product_detail_page__review_board',
  PRODUCT_DETAIL_PAGE__PHOTO_REVIEW = 'product_detail_page__review_photo',
}

export interface RatingModuleSetting {
  star_rating_product: boolean;
  star_rating_product_thumb: boolean;
  star_rating_cart: boolean;
}

export interface WidgetOption {
  id: number;
  shop_id: number;
  custom_widget_option_set_id: number;
  type: WidgetType;
  option: {
    display_product_infomation: boolean;
    display_store_review: boolean;
    display_reviewer_name: boolean;
    display_star: boolean;
    display_review_write_button: boolean;
    display_text_only_review: boolean;
    display_product_thumbnail: boolean;
    display_review_count: boolean;
    display_rank: boolean;
    display_review: boolean;
    display_rating: boolean;
    item_per_page: number;
    widget_shape: number;
    number_of_column: number;
    number_of_row: number;
  };

  _index?: number;
  _select?: boolean;
}
export class WidgetSet {
  id: number = 0;
  shop_id: number = 0;
  type: WidgetInstallationPosition = WidgetInstallationPosition.MAIN_PAGE;
  custom_widget_options: WidgetOption[] = [];
  _is_active: boolean = false;
}

interface LocalizationDictionary {
  [key: string]: string;
}

export interface WidgetLocalizationSetting {
  id: number;
  shop_id: number;
  country_number: number;
  localization_dictionary: LocalizationDictionary;
  is_active: boolean;
}

export interface requestWidgetLocalizationSetting {
  id: number;
  shop_id: number;
  country_number: number;
  localization_dictionary: string;
  is_active: boolean;
}

export enum LocalizationCountryLanguage {
  ENGLISH = 1,
  FRENCH = 33,
  SPANISH = 34,
  PORTUGUESE = 351,
  GERMAN = 49,
  JAPANESE = 81,
  KOREAN = 82,
  CHINESE = 86,
}

export enum LocalizationOption {
  Widget = 0,
  Review = 1,
}
