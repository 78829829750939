import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { inOutAnimation } from 'src/app/admin/animations';
import { ListDesignerWidget } from 'src/app/models/list-designer/widget.model';
import { Product } from 'src/app/models/product/product.model';
import { CurrencyOnlyPipe } from 'src/app/pipe/currency-only.pipe';
import { GenericService } from 'src/app/service/generic.service';
import { ShopService } from 'src/app/service/shop.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-widget-preview-template',
  templateUrl: './widget-preview-template.component.html',
  styleUrl: './widget-preview-template.component.less',
  providers: [CurrencyOnlyPipe],
  animations: [inOutAnimation],
})
export class WidgetPreviewTemplateComponent {
  @Input() widget!: ListDesignerWidget;
  @Input() mode!: 'PC' | 'MO';
  @Input() product!: Product;
  constructor(public genericService: GenericService, private currencyOnlyPipe: CurrencyOnlyPipe, private shopService: ShopService, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.setPrice();
    this.setRating();
    this.cdr.detectChanges();
  }

  discountValue?: number;
  beforePrice?: number;
  afterPrice?: number;

  swiperConfig: SwiperOptions = {
    direction: 'vertical',
    autoplay: { delay: 1000 },
    speed: 1200,
    allowTouchMove: false,
  };

  setPrice() {
    const productVariants = this.product.product_variants;

    if (productVariants && productVariants.length > 0) {
      this.beforePrice = productVariants[0].compare_at_price ?? 0;
      this.afterPrice = productVariants[0].price ?? 0;
    } else {
      this.beforePrice = 0;
      this.afterPrice = 0;
    }

    this.discountValue = this.beforePrice ? Math.floor(((this.beforePrice - this.afterPrice) / this.beforePrice) * 100) : 0;
  }

  setRating() {
    if (!this.product.rating) return;
    this.product.rating = Number(this.product.rating.toFixed(0));
  }

  getCurrency(): string {
    let result: string = '';
    result = this.currencyOnlyPipe.transform(null, this.shopService.currency);
    return result;
  }
}
