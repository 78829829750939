<article id="dialog-setting-product-collection-select">
  <section class="content-container">
    <section class="left-container">
      <section class="select-box">
        <section *ngIf="data.swappable" class="toggle-select-box-container">
          <div *ngFor="let type of SeparationType | keyvalue">
            <ng-container *ngIf="data.target == SeparationType.COLLECTION || data.target == SeparationType.PRODUCT">
              <div class="toggle-select-box" [ngClass]="{ active: data.target == type.value }" (click)="toggleTarget(type.value)" *ngIf="type.value === SeparationType.COLLECTION">
                Collection
              </div>
              <div class="toggle-select-box" [ngClass]="{ active: data.target == type.value }" (click)="toggleTarget(type.value)" *ngIf="type.value === SeparationType.PRODUCT">
                Product
              </div>
            </ng-container>
            <ng-container *ngIf="data.target == SeparationType.CUSTOMER || data.target == SeparationType.CUSTOMER_GROUP">
              <div class="toggle-select-box" [ngClass]="{ active: data.target == type.value }" (click)="toggleTarget(type.value)" *ngIf="type.value === SeparationType.CUSTOMER">
                Customer
              </div>
              <div
                class="toggle-select-box"
                [ngClass]="{ active: data.target == type.value }"
                (click)="toggleTarget(type.value)"
                *ngIf="type.value === SeparationType.CUSTOMER_GROUP"
              >
                Customer Segments
              </div>
            </ng-container>
          </div>
        </section>
        <header style="font-size: 16px; line-height: 24px; margin-bottom: 20px; font-weight: 600" *ngIf="!data.swappable">
          <span *ngIf="data.target === SeparationType.COLLECTION">Collection</span>
          <span *ngIf="data.target === SeparationType.PRODUCT">Product</span>
          <span *ngIf="data.target === SeparationType.CUSTOMER">Customer</span>
          <span *ngIf="data.target === SeparationType.CUSTOMER_GROUP">Customer Segments</span>
        </header>
        <section class="search-container">
          <select style="width: 90px; margin-right: 10px">
            <option selected>All</option>
          </select>
          <input style="flex-grow: 1" class="admin-input" placeholder="Search" (keyup)="applyFilter($event)" #searchInput />
        </section>
      </section>
      <section class="item-box">
        <ng-container *ngFor="let item of itemList" (click)="item.checked = !item.checked">
          <section class="item-info-container" *ngIf="!filterValue || item.title?.toLowerCase().includes(filterValue) || item.name?.toLowerCase().includes(filterValue)">
            <div class="item-info" style="width: 100%; line-height: unset; align-items: unset">
              <alpha-checkbox [(ngModel)]="item.checked"> </alpha-checkbox>
              <div style="display: flex; width: 100%; margin-left: 10px" (click)="item.checked = !item.checked">
                <ng-container *ngIf="data.target == SeparationType.PRODUCT">
                  <img [src]="item.product_image" style="width: 53px; height: 53px; object-fit: cover" />
                  <div style="flex-grow: 1; margin-left: 10px">
                    <div>{{ item.title }}</div>
                    <div class="item-id cool-gray-2"><b>ID</b> {{ item.platform_id }}</div>
                    <div class="cool-gray-2" style="display: flex; justify-content: space-between">
                      <div>
                        <!--price-->
                      </div>
                      <div>Created at {{ item.created_at | date : 'mediumDate' }}</div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="data.target == SeparationType.CUSTOMER_GROUP">
                  <div style="flex-grow: 1">
                    <div>{{ item.name }}</div>
                    <div class="item-id cool-gray-2">
                      {{ item.created_at | date : 'mediumDate' }}
                    </div>
                    <!-- <div class="cool-gray-2" style="display: flex; justify-content: space-between;">
                    <div>

                    </div>
                    <div>Created at {{item.created_at | date:"mediumDate"}}</div>
                  </div> -->
                  </div>
                </ng-container>
                <ng-container *ngIf="data.target == SeparationType.COLLECTION">
                  <img *ngIf="item.image" [src]="item.image?.url" style="width: 53px; height: 53px; object-fit: cover" />
                  <icon-empty-image *ngIf="!item.image"></icon-empty-image>
                  <div style="flex-grow: 1; margin-left: 10px">
                    <div>{{ item.title }}</div>
                    <div class="item-id cool-gray-2"><b>ID</b> {{ item.platform_id }}</div>
                    <div class="cool-gray-2" style="display: flex; justify-content: space-between">
                      <div>
                        <!--price-->
                      </div>
                      <div>Created at {{ item.created_at | date : 'mediumDate' }}</div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </section>
        </ng-container>
      </section>
      <div class="item-detail-menu">
        {{ getCheckedListLength(itemList) | number }} Selected
        <div class="dialog-btn-add" (click)="addItem()">Add to List</div>
      </div>
    </section>
    <section class="right-container">
      <section class="result-box">
        <header style="font-size: 16px; line-height: 24px; margin-bottom: 14px">Selected Items</header>
        <section style="margin-bottom: 20px">
          <input class="admin-input" style="width: 100%" />
        </section>
        <section class="result-item-box">
          <section *ngIf="selectedItemList.length <= 0" class="result-item-image-container">
            <div style="text-align: center">
              <img src="assets/icon/product_empty.svg" (click)="click()" />
              <div style="margin-top: 12px">
                <div [ngSwitch]="data.target">
                  <div *ngSwitchCase="SeparationType.COLLECTION">Select collection from the left box to add it.</div>
                  <div *ngSwitchCase="SeparationType.PRODUCT">Select Product from the left box to add it.</div>
                  <div *ngSwitchCase="SeparationType.CUSTOMER_GROUP">Select Customer Segment from the left box to add it.</div>
                </div>
              </div>
            </div>
          </section>
          <section class="result-item-container" *ngFor="let item of selectedItemList" (click)="item.checked = !item.checked">
            <alpha-checkbox [(ngModel)]="item.checked" (click)="$event.stopPropagation()"></alpha-checkbox>
            <img *ngIf="data.target == SeparationType.PRODUCT" class="round_image" [src]="item.product_image" />
            <div class="item-name">{{ item.title }}</div>
          </section>
        </section>
      </section>
      <div class="item-detail-menu">
        {{ getCheckedListLength(selectedItemList) | number }} Selected
        <div class="dialog-btn-add" (click)="removeItem()">Remove from List</div>
      </div>
    </section>
  </section>
  <section class="flex-wrapper" style="margin: 12px 20px 14px">
    <div></div>
    <div style="display: flex">
      <div class="btn-cancel" (click)="onNoClick()">Cancel</div>
      <div class="btn-confirm" (click)="onConfirm()">Save</div>
    </div>
  </section>
</article>
