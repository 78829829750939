import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { Messaging, MessagingSettingTypeDetail } from 'src/app/models/shop/review/options/messaging';
import { BackendService } from 'src/app/service/backend.service';

@Injectable({
  providedIn: 'root',
})
export class TouchpointsNotificationService {
  constructor(private backendService: BackendService) {}

  emailForBlockList = {
    emailsForVerification: '',
    emailForReviewReward: '',
    emailForReviewReply: '',
  };

  getEmailBlacklist(messaging: Messaging) {
    let url = 'messaging/emails/blacklist';

    this.backendService.select(url, { messaging_id: messaging.id }).subscribe((response) => {
      if (!this.backendService.validateResponse(response)) return;
      messaging.messaging_email_blacklist = response.body;
    });
  }

  createEmailBlacklist(messaging: Messaging, emails: string, settingTypeDetail: MessagingSettingTypeDetail) {
    let emailList = this.parseEmailList(emails);
    const uniqueEmailList = [...new Set(emailList)].slice(0, 200);

    this.updateEmailForBlockList(settingTypeDetail, uniqueEmailList);

    this.backendService.create('messaging/emails/blacklist', { messaging_id: messaging.id, email_list: uniqueEmailList }).subscribe((response) => {
      if (this.backendService.validateResponse(response)) {
        this.getEmailBlacklist(messaging);
        this.backendService.openSnackBar('Success');
      }
    });
  }

  parseEmailList(emails: string) {
    let emailList: string[] = [];
    if (emails.length) {
      emailList = emails
        .split(',')
        .map((item) => item.trim())
        .filter((email) => email !== '' && email.length > 0);
    }
    return emailList;
  }

  updateEmailForBlockList(settingTypeDetail: MessagingSettingTypeDetail, uniqueEmailList: string[]): void {
    if (settingTypeDetail === MessagingSettingTypeDetail.VERIFICATION_EMAIL) this.emailForBlockList.emailsForVerification = uniqueEmailList.join(', ');
    if (settingTypeDetail === MessagingSettingTypeDetail.BENEFIT_INFO_EMAIL) this.emailForBlockList.emailForReviewReward = uniqueEmailList.join(', ');
    if (settingTypeDetail === MessagingSettingTypeDetail.REVIEW_REPLY_INFO_EMAIL) this.emailForBlockList.emailForReviewReply = uniqueEmailList.join(', ');
  }

  deleteEmailBlacklist(messaging_email_id: number, messaging: Messaging) {
    let url = 'messaging/emails/blacklist/' + messaging_email_id;

    this.backendService.delete(url).subscribe((response) => {
      if (this.backendService.validateResponse(response)) {
        this.getEmailBlacklist(messaging);
        this.backendService.openSnackBar('Success');
      }
    });
  }
}
