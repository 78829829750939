<article id="admin-settings" class="setting-article" style="max-width: 1000px">
  <header class="admin-header-1" style="margin-bottom: 20px">Settings</header>
  <mat-tab-group #tabGroup class="admin-tab">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="advanced-ranking" (click)="store.ngOnInit()">
          <div>Store Settings</div>
        </div>
      </ng-template>
      <app-store-setting [useAlphaPlus]="useAlphaPlus"></app-store-setting>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="advanced-ranking" (click)="account.ngOnInit(); account.ngAfterViewInit()">
          <div>Account Settings</div>
        </div>
      </ng-template>
      <app-account-setting></app-account-setting>
    </mat-tab>
    @if (useAlphaPlus) {
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="advanced-ranking">
          <div>Subscriptions</div>
        </div>
      </ng-template>
      <app-subscription-setting></app-subscription-setting>
    </mat-tab>
    }
  </mat-tab-group>
  <alpha-save-bar
    *ngIf="store && tabGroup.selectedIndex == 0"
    (save)="onConfirm()"
    (discard)="onCancel()"
    [show]="!store.checkSettingsChange()"
    [maxWidth]="'960px'"
  ></alpha-save-bar>
</article>
