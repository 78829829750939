import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-app-install-warning',
  standalone: true,
  imports: [],
  templateUrl: './dialog-app-install-warning.component.html',
  styleUrl: './dialog-app-install-warning.component.less',
})
export class DialogAppInstallWarningComponent {
  constructor(private dialogRef: MatDialogRef<DialogAppInstallWarningComponent>, private router: Router) {}
  onClickCancel() {
    this.dialogRef.close();
  }
  onClickSignOut() {
    this.dialogRef.close();
    this.router.navigate(['/admin/login']);
    sessionStorage.clear();
  }
}
