import { Component, ViewChild } from '@angular/core';
import { fadeAnimation } from '../../animations';
import { MatDialogRef } from '@angular/material/dialog';
import { SwiperComponent } from 'swiper/angular';
import { SwiperOptions } from 'swiper';
import { GenericService } from 'src/app/service/generic.service';
import { BrowseBoosterService } from '../../browse-booster/browse-booster.service';
import { AdminService } from 'src/app/service/admin.service';

@Component({
  selector: 'app-dialog-browse-booster-onboarding',
  templateUrl: './dialog-browse-booster-onboarding.component.html',
  styleUrl: './dialog-browse-booster-onboarding.component.less',
  animations: [fadeAnimation],
})
export class DialogBrowseBoosterOnboardingComponent {
  @ViewChild(SwiperComponent) swiper!: SwiperComponent;
  constructor(
    public genericService: GenericService,
    private adminService: AdminService,
    private browseBoosterService: BrowseBoosterService,
    private dialogRef: MatDialogRef<DialogBrowseBoosterOnboardingComponent>,
  ) {
    this.adminService.getShopAccessCode().length === 6 && this.adminService.setShopAccessCode(`BB${this.adminService.getShopAccessCode()}`);
  }
  state: string = 'in';
  slide: string = 'in';
  currentStep = 1;

  selectValue: {
    productCount: number;
    discountValue: number;
  } = {
    productCount: 3,
    discountValue: 3,
  };

  swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    allowTouchMove: false,
    speed: 500,
  };

  onClickOptionValue(value: number) {
    if (this.currentStep === 1) this.selectValue.productCount = value;
    else this.selectValue.discountValue = value;
  }

  nextStep() {
    this.state = 'out';
    this.slide = 'out-left';
    this.swiper.swiperRef.slideNext();
    setTimeout(() => {
      this.currentStep++;
      this.state = 'in';
      this.slide = 'in';
    }, 300);
  }

  previousStep() {
    this.state = 'out';
    this.slide = 'out-right';
    this.swiper.swiperRef.slidePrev();
    setTimeout(() => {
      this.currentStep--;
      this.state = 'in';
      this.slide = 'in';
    }, 300);
  }

  onClose() {
    this.dialogRef.close();
  }

  onConfirm(core: boolean) {
    this.dialogRef.close(this.selectValue);
    core && this.browseBoosterService.getDeepLinkingUrl();
  }
}
