<article id="dialog-delete-app">

    <header class="admin-header-3">
      <img src="assets/icon/arrow_left_background.svg" class="cancle-button-image" (click)="onNoClick()" />
      <img src="assets/icon/crying_with_tears.png" />
      We're sad to see you go...
    </header>

    <section class="center-body">
      <div>Before you go, please let us know the reason you are leaving. Every bit of feedback helps!</div>
      <div class="checkbox-container">
      @for (reason of uninstallReasonDict; track $index) {
        <alpha-checkbox (click)="updateUninstallReasons(reason.value)">{{reason.key}}</alpha-checkbox>
      }
      </div>
      <textarea class="delete-app-textarea" placeholder="Anything you want to share?" [(ngModel)]="uninstallComment"></textarea>
    </section>

    <div class="flex-wrapper note-content" style="gap: 6px; justify-content: flex-start">
        <img src="assets/icon/note_green.svg" />
        <div class="text-box-in-gray"><span class="need-help">Need Help?</span> If you're experiencing issues or need more<br />
          information, contact our Customer Support team.</div>
    </div>

    <div class="flex-wrapper button-wrap">
      <button class="btn-white" color="btn-white" (click)="openLink('https://a-review.channel.io/home')">Contact Customer Support</button>
      <button class="btn-confirm" (click)="onConfirm()" [ngClass]="{ disabled: selectedReasons.length === 0}">Delete App</button>
    </div>

</article>