import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ListDesignerRoutingModule } from './list-designer-routing.module';
import { ListDesignerComponent } from './list-designer.component';
import { WidgetCustomSettingsComponent } from './widget-custom-settings/widget-custom-settings.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSliderModule } from '@angular/material/slider';
import { MatMenuModule } from '@angular/material/menu';
import { TemplateModule } from 'src/app/template/template.module';
import { WidgetsComponent } from './widgets/widgets.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { WidgetPreviewTemplateComponent } from './widget-custom-settings/widget-preview-template/widget-preview-template.component';
import { SwiperModule } from 'swiper/angular';
import { MatTooltipModule } from '@angular/material/tooltip';
import { WidgetGuideComponent } from './widget-guide/widget-guide.component';

@NgModule({
  declarations: [ListDesignerComponent, WidgetCustomSettingsComponent, WidgetsComponent, WidgetPreviewTemplateComponent, WidgetGuideComponent],
  imports: [
    CommonModule,
    SharedModule,
    TemplateModule,
    PipeModule,
    ListDesignerRoutingModule,
    MatExpansionModule,
    MatSliderModule,
    MatMenuModule,
    MatPaginatorModule,
    MatTooltipModule,
    SwiperModule,
  ],
})
export class ListDesignerModule {}
