<article id="dialog-add-and-edit-products">
  @if (!showAddProduct) {
  <section [@inOutAnimation]>
    <section class="flex-wrapper" style="padding: 0 8px">
      <header class="admin-header-1">Products to Display</header>
      <div class="flex-wrapper list-products-header-btn" style="gap: 10px; align-self: flex-start">
        <div
          (click)="checkedProductLength() === 0 ? return() : deleteProduct()"
          [ngClass]="{ disabled: !checkedProductLength() }"
          matTooltip="Select the products to delete."
          [matTooltipDisabled]="checkedProductLength()! > 0"
          class="btn-white"
          style="height: 36px; width: 64px"
          [@inOutAnimation]
        >
          Delete
        </div>
        <div
          (click)="filteringProducts?.length! >= 20 ? return() : changeSection(true)"
          [ngClass]="{ disabled: filteringProducts?.length! >= 20 }"
          matTooltip="You can display up to 20 products maximum."
          [matTooltipDisabled]="filteringProducts?.length! < 20"
          class="btn-black"
          style="width: 113px"
        >
          Add Product
        </div>
      </div>
    </section>

    <section class="list-products-table">
      <header class="content-table-header">
        <div class="content-table-name drag"></div>
        <div class="content-table-name checkbox">
          <alpha-checkbox [(ngModel)]="isAll" (ngModelChange)="checkedAll($event)"> </alpha-checkbox>
        </div>
        <div class="content-table-name product">{{ filteringProducts?.length ? filteringProducts?.length : '0' }} products</div>
        <div class="content-table-name status">Status</div>
      </header>

      <div class="scroll-container" cdkDropList (cdkDropListDropped)="drop($event)">
        @if (filteringProducts !== null) { @for (listProduct of filteringProducts; track $index) {
        <section
          cdkDrag
          class="content-table-row custom-drop"
          (mousemove)="listProduct._hover = true"
          (mouseleave)="listProduct._hover = false"
          [ngClass]="{ hover: listProduct._hover }"
        >
          <div class="drag">
            <img src="/assets/icon/menu_vertical.svg" />
            <img src="/assets/icon/menu_vertical.svg" />
          </div>
          <div class="checkbox">
            <alpha-checkbox [(ngModel)]="listProduct.checked" (ngModelChange)="checkedProductLength()"> </alpha-checkbox>
          </div>
          <div class="product">
            <img src="{{ listProduct.product_image }}" />
            <div class="bold">{{ listProduct.title }}</div>
          </div>
          <div class="status bold" [ngClass]="listProduct.is_published ? 'active' : 'inactive'">
            <div class="flex-wrapper">
              <span class="circle circle-out"><span class="circle circle-in"></span></span>
              {{ listProduct.is_published ? 'Active' : 'Inactive' }}
            </div>
          </div>
        </section>
        } @empty {
        <section class="list-products-empty">
          <header class="admin-header-4">Please set the criteria for displaying products.</header>
          <summary class="admin-content-description">You can fetch up to 20 products that meet the criteria.</summary>
          <div (click)="changeSection(true)" class="btn-black" style="width: 143px">+ Add a Product</div>
        </section>
        } }
      </div>
    </section>

    <section class="list-products-footer flex-wrapper">
      <div (click)="onCancel()" class="btn-white">Cancel</div>
      <div (click)="saveListDesignerProducts()" class="btn-black" [class.disabled]="!filteringProducts?.length">Save</div>
    </section>
  </section>
  } @else {
  <section [@inOutAnimation]>
    <div class="list-products-header">
      <header class="admin-header-1"><img (click)="changeSection(false)" src="assets/icon/back_btn_gray.svg" />Select a Product</header>
    </div>

    <section class="list-products-filter" style="padding-top: 14px">
      <select [(ngModel)]="sortingType" (ngModelChange)="getProducts(sortingType)">
        <option value="newest">Recently Created</option>
        <option value="selling">Best Selling</option>
        <option value="-review_count">Most Reviewed</option>
      </select>

      <form class="admin-form" style="width: 100%">
        <button (click)="getProducts(this.sortingType)"><img src="assets/icon/search_gray.svg" /></button>
        <input placeholder="Search" [ngModelOptions]="{ standalone: true }" [(ngModel)]="productService.searchContent" />
      </form>
    </section>

    <section class="list-products-table">
      <section class="scroll-container" style="height: calc(80vh - 228px)">
        @for (product of products; track $index) {
        <section class="content-table-row products-table">
          <alpha-checkbox [(ngModel)]="product.checked" (ngModelChange)="getSelectedProduct()" style="width: 100%; padding-left: 12px">
            <div class="product-info-wrap">
              <img class="product-info-image" [src]="product.product_image" />
              <div class="product-info">
                <header class="product-info-title ellipsis-1 bold">{{ product.title }}</header>
                <div class="product-info-content">
                  <div class="flex-wrapper" style="gap: 12px">
                    <div class="flex-wrapper" style="gap: 6px"><span class="bold">ID</span>{{ product.platform_id }}</div>
                    <div class="flex-wrapper" style="gap: 2px">
                      <img src="assets/icon/star_new_yellow.svg" />{{ product.rating | number : '1.1-1' }}({{ product.review_count | number }} reviews)
                    </div>
                  </div>
                  <div class="flex-wrapper" style="gap: 6px"><span class="bold">Created at</span>{{ product.created_at | date : 'mediumDate' }}</div>
                </div>
              </div>
            </div>
          </alpha-checkbox>
        </section>
        }
      </section>
    </section>

    <section class="list-products-footer flex-wrapper">
      <div (click)="onCancel()" class="btn-white">Cancel</div>
      <div (click)="mergedFilteringProducts()" class="btn-black" [class.disabled]="maxItems">Add the {{ selectedProducts?.length || '0' }} selected products</div>
    </section>
  </section>
  }
</article>
