<article id="dialog-template-delete">
  <section class="dialog-body">
    Are you sure you want to delete this? <br />
    Once deleted, it cannot be restored.
  </section>
  <section class="flex-wrapper">
    <button class="btn-white" (click)="onCancel()" style="width: 80px; height: 36px">Cancel</button>
    <button class="btn-black" (click)="onConfirm()" style="width: 80px; height: 36px">Confirm</button>
  </section>
</article>
