import { Injectable } from '@angular/core';
import { AdminService } from './admin.service';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private backendService: BackendService,
    private adminService: AdminService
  ) { }

  getReviewCount(params: {} = {}) {
    // const url = 'reviews/analytics/count'
    const url = 'reviews/analytics/' + AnalyticsType.REVIEW_COUNT + '/'

    params = { ...params, ...{ shop_access_code: this.adminService.getShopAccessCode() } }
    return this.backendService.select(url, params)
  }

  getReviewRating(params: {} = {}) {
    const url = 'reviews/analytics/' + AnalyticsType.REVIEW_RATING_COUNT + '/'

    params = { ...params, ...{ shop_access_code: this.adminService.getShopAccessCode() } }
    return this.backendService.select(url, params)
  }

  getReviewRate(params: {} = {}) {
    // const url = 'reviews/analytics/count'
    const url = 'orders/analytics/' + AnalyticsType.REVIEW_RATE + '/'

    params = { ...params, ...{ shop_access_code: this.adminService.getShopAccessCode() } }
    return this.backendService.select(url, params)
  }

  getRequestCount(params: {} = {}) {
    // const url = 'reviews/analytics/count'
    const url = 'messaging/analytics/' + AnalyticsType.REQUEST_COUNT + '/'

    params = { ...params, ...{ shop_access_code: this.adminService.getShopAccessCode() } }
    return this.backendService.select(url, params)
  }

  getTopProduct(params: {} = {}) {
    // const url = 'reviews/analytics/count'
    const url = 'products/analytics/' + AnalyticsType.TOP_PRODUCT + '/'

    params = { ...params, ...{ shop_access_code: this.adminService.getShopAccessCode() } }
    return this.backendService.select(url, params)
  }

  getTopCustomer(params: {} = {}) {
    // const url = 'reviews/analytics/count'
    const url = 'reviews/analytics/' + AnalyticsType.TOP_CUSTOMER + '/'

    params = { ...params, ...{ shop_access_code: this.adminService.getShopAccessCode() } }
    return this.backendService.select(url, params)
  }


}

export enum AnalyticsType {
  REVIEW_COUNT = "review_count",
  REVIEW_RATE = "review_rate",
  REVIEW_RATING_COUNT = "review_rating_count",
  REQUEST_COUNT = "request_count",
  TOP_PRODUCT = "top_product",
  TOP_CUSTOMER = "top_customer"
}
