import { Component, Inject, OnInit } from '@angular/core';
import { ShopAdminAccount } from 'src/app/models/shop/shop.model';
import { GenericService } from 'src/app/service/generic.service';
import { ShopService } from 'src/app/service/shop.service';
import { DialogAdminAccountDeleteComponent } from '../dialog-admin-account-delete/dialog-admin-account-delete.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-admin-account-update-position',
  templateUrl: './dialog-admin-account-update-position.component.html',
  styleUrls: ['./dialog-admin-account-update-position.component.less', '../../admin.component.less'],
})
export class DialogAdminAccountUpdatePositionComponent implements OnInit {
  constructor(
    public shopService: ShopService,
    public dialogRef: MatDialogRef<DialogAdminAccountUpdatePositionComponent>,
    public dialog: MatDialog,
    public genericService: GenericService,
    @Inject(MAT_DIALOG_DATA) public data: ShopAdminAccount,
  ) {}

  ngOnInit(): void {}

  onNoClick() {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.shopService.patchShopAccount(this.data).subscribe((response) => {
      this.genericService.openSnackBar('Update success.');
      this.dialogRef.close(true);
    });
  }

  openDialogAccountDelete() {
    const dialogRef = this.dialog.open(DialogAdminAccountDeleteComponent, {
      width: '480px',
      data: {},
    });
    dialogRef.afterClosed().subscribe((result: boolean | undefined) => {
      if (result) {
        this.shopService.deleteInvitedShopAccount(this.data).subscribe((response) => {
          this.dialogRef.close(true);
        });
      }
    });
  }
}
