<article id="admin-float-launcher-support" cdkDrag cdkDragBoundary=".admin-body">
  <section #FAQTrigger="matMenuTrigger" [matMenuTriggerFor]="FAQ" class="admin-float-launcher-support" [ngClass]="{ activate: FAQTrigger.menuOpen }">
    <img class="launcher-image" src="assets/icon/question_sign.svg" *ngIf="!FAQTrigger.menuOpen" />
    <img class="launcher-image" src="assets/icon/close_launcher.svg" *ngIf="FAQTrigger.menuOpen" />
  </section>

  <mat-menu class="admin-support-menu-container" #FAQ="matMenu" style="margin-bottom: 10px">
    <section class="admin-support-menu" (click)="$event.stopPropagation()" *ngIf="mode == 'FAQ'">
      <header class="admin-header-2" style="margin-bottom: 5px">How can we help you today?</header>
      <summary class="admin-header-summary" style="margin-bottom: 10px">Choose from the following options to get the help you need.</summary>
      <div class="mat-menu-item-container" mat-menu-item>
        <a class="faq-item" href="https://alph.oopy.io/bf4bfeb2-9e52-49c0-b34c-1acb750a5bf0" target="_blank"> Migrate reviews from another app </a>
        <img src="assets/icon/share_out_big_gray.svg" />
      </div>
      <div class="mat-menu-item-container" mat-menu-item>
        <a class="faq-item" href="https://alph.oopy.io/686bd6d9-ec37-4ae8-8893-e551ec8b9e43" target="_blank"> Set up a widget on your store </a>
        <img src="assets/icon/share_out_big_gray.svg" />
      </div>
      <div class="mat-menu-item-container" mat-menu-item>
        <a class="faq-item" href="https://alph.oopy.io/472cbc82-3109-4bbb-a83b-dfb9adec11cf#3f07c559-2e29-4805-9f04-4ca71e017562" target="_blank"> Import AliExpress reviews </a>
        <img src="assets/icon/share_out_big_gray.svg" />
      </div>
      <div class="mat-menu-item-container" mat-menu-item>
        <a class="faq-item" href="https://alph.oopy.io/472cbc82-3109-4bbb-a83b-dfb9adec11cf#4dbb2a11-99c5-413e-91ba-8210e5414958" target="_blank"> Import Amazon reviews </a>
        <img src="assets/icon/share_out_big_gray.svg" />
      </div>
      <div class="mat-menu-item-container" mat-menu-item>
        <a class="faq-item" href="https://alph.oopy.io/1571e9a0-fb5a-460d-a53b-4b4ce45afe2b#b857147c-6652-4c22-a853-9b643ffb1877" target="_blank">
          Display ratings in the collections
        </a>
        <img src="assets/icon/share_out_big_gray.svg" />
      </div>

      <button class="mat-menu-item-container" class="faq-item" mat-menu-item target="_blank" (click)="$event.stopPropagation(); mode = 'EMAIL'">
        <img src="assets/icon/speech_bubble_square.svg" /> <span style="margin-left: 5px">Contact us directly</span>
      </button>
    </section>
    <section
      class="admin-support-menu"
      (click)="$event.stopPropagation()"
      *ngIf="mode == 'EMAIL' || mode == 'COMPLETE'"
      style="display: flex; height: 100%; justify-content: space-between; flex-direction: column"
    >
      <header class="admin-header-2">
        <img style="margin-right: 10px; cursor: pointer" (click)="$event.stopPropagation(); mode = 'FAQ'" src="assets/icon/arrow_left_background.svg" />
        Contact us directly
      </header>

      <section *ngIf="mode == 'EMAIL'">
        <summary class="admin-header-summary" style="margin-bottom: 10px">If you leave a message here, we’ll get back to you via email as soon as possible.</summary>
        <div style="margin-bottom: 10px">
          <input class="admin-input" placeholder="Your email" [(ngModel)]="email" (keydown.tab)="preventClose($event)" />
        </div>
        <div style="margin-bottom: 10px">
          <textarea style="height: 220px" class="admin-input" placeholder="Content" [(ngModel)]="content" (keydown.tab)="preventClose($event)"> </textarea>
        </div>
        <div style="margin-bottom: 10px">
          <input class="admin-input" [formControl]="fileFormControl" type="file" accept="image/* | video/*" multiple (change)="onChangeFile($event)" />
        </div>

        <section>
          <button class="btn-confirm" (click)="sendEmail()" style="width: 100%" [disabled]="lock">Contact us directly</button>
        </section>
      </section>
      <section *ngIf="mode == 'COMPLETE'" style="flex: 1; text-align: center; display: flex; flex-direction: column; justify-content: center">
        <div style="margin-bottom: 20px">
          <circle-check-large fillColor="#333336"></circle-check-large>
        </div>
        <header class="admin-header-3" style="align-self: center; margin-bottom: 5px">Message Sent Successfully!</header>
        <summary>
          Thank you for reaching out to us.<br />
          We'll get back to you within<br />
          1 business day.
        </summary>
      </section>
    </section>
  </mat-menu>
</article>
