<div id="preview-product" class="product-container" [class.mobile]="mode === 'MO'">
  <div class="product-img" [style.border-radius]="widget.design_option.adjust_border_radius + 'px'">
    <img src="{{ product.product_image }}" />
    <div class="preview-flex product-badge-container" [@inOutAnimation]>
      @if (widget.design_option.use_discount_badge_color && discountValue! > 0) {
      <div class="product-badge bold" [style.background-color]="widget.design_option.discount_badge_color" [@inOutAnimation]>{{ discountValue }}% off</div>
      } @if (widget.design_option.use_new_badge_color) {
      <div class="product-badge bold" [style.background-color]="widget.design_option.new_badge_color" [@inOutAnimation]>New</div>
      } @if (widget.design_option.use_best_badge_color) {
      <div class="product-badge bold" [style.background-color]="widget.design_option.best_badge_color" [@inOutAnimation]>Best</div>
      } @if (widget.design_option.use_top_reviewed_badge_color) {
      <div class="product-badge bold" [style.background-color]="widget.design_option.top_reviewed_badge_color" [@inOutAnimation]>TOP reviewed</div>
      }
    </div>
  </div>
  <div class="product-title">{{ product.title }}</div>

  @if (widget.standard_option.use_purchase_summary_standard || widget.standard_option.use_visitor_summary_standard) {
  <div class="preview-flex" style="gap: 4px" [@inOutAnimation]>
    @if (widget.standard_option.use_purchase_summary_standard) {
    <div class="product-icon preview-flex" style="gap: 1px" [@inOutAnimation]><img src="assets/icon/fire_icon.svg" />1.2K+ Sold</div>
    } @if (widget.standard_option.use_visitor_summary_standard) {
    <div class="product-icon preview-flex" style="gap: 1px" [@inOutAnimation]><img src="assets/icon/eye_icon.svg" /> 8.9K+</div>
    }
  </div>
  }

  <div class="product-price preview-flex" style="gap: 6px">
    <div class="preview-flex" style="gap: 4px; flex-wrap: wrap">
      <div class="product-price-after bold">{{ getCurrency() }}{{ afterPrice?.toLocaleString() ?? beforePrice?.toLocaleString() }}</div>
      @if (widget.standard_option.use_compare_at_price && (beforePrice && beforePrice > afterPrice!)) {
      <div class="product-price-before" [@inOutAnimation]>{{ getCurrency() }}{{ beforePrice.toLocaleString() }}</div>
      }
    </div>
    @if (widget.standard_option.use_realtime_stock_standard) {
    <div class="product-price-stock" [@inOutAnimation]>7 left</div>
    }
  </div>

  @if (widget.standard_option.use_realtime_purchase_standard || widget.standard_option.use_realtime_review_standard || widget.standard_option.use_review_summary) {
  <div class="product-proof" [@inOutAnimation]>
    <swiper class="product-proof-swiper" [config]="swiperConfig">
      @if (widget.standard_option.use_realtime_purchase_standard) {
      <ng-template swiperSlide
        ><div class="product-proof-content preview-flex"><span class="circle-black">J</span> John just bought</div></ng-template
      >
      } @if (widget.standard_option.use_realtime_review_standard) {
      <ng-template swiperSlide
        ><div class="product-proof-content preview-flex"><span class="circle-black">J</span> John rated 5 stars</div></ng-template
      >
      } @if (widget.standard_option.use_review_summary) {
      <ng-template swiperSlide
        ><div class="product-proof-content preview-flex ellipsis-1"><img src="assets/icon/star_new_yellow.svg" class="star" /> 123 people rated it 5 stars</div></ng-template
      >
      }
    </swiper>
  </div>
  }

  <div class="product-rating preview-flex" style="gap: 4px">
    <div class="preview-flex" style="gap: 4px">
      @for (rate of genericService.range(product.rating); track $index) {<img src="assets/icon/star_new_yellow.svg" class="star" />} @for (rate of genericService.range(5 -
      product.rating); track $index) {<img src="assets/icon/star_new_empty.svg" class="star" />}
    </div>
    <div class="product-review">({{ product.review_count }} Reviews)</div>
  </div>
</div>
