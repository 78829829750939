import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ReviewAppPlatform } from 'src/app/models/shop/review/options/review-import.model';
import { AiService } from 'src/app/service/ai.service';
import { PaginationHandler } from 'src/app/shared/handler/pagination-handler';
import { AdminService } from 'src/app/service/admin.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-ali-analysis-log',
  templateUrl: './ali-analysis-log.component.html',
  styleUrls: ['./ali-analysis-log.component.less'],
})
export class AliAnalysisLogComponent implements OnInit {
  @ViewChild('paginator') paginator!: MatPaginator;
  constructor(public aiService: AiService, private adminService: AdminService, private router: Router, private dialog: MatDialog, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    //this.aiService.getShopDetail(); // 테스트용
  }

  ngAfterViewInit(): void {
    this.aiService.paginationHandler = new PaginationHandler(this.paginator, 0, 10);
    this.aiService.getAnalysisList(undefined, true);

    this.cdr.detectChanges();
  }

  viewDetails(id: number) {
    this.router.navigate(['/admin/ai-analysis/analysis-details'], { state: { id: id, platform: ReviewAppPlatform.ALI_EXPRESS } });
  }
}
