import { Component, OnInit, Optional, SkipSelf } from '@angular/core';
import { BillingInterval, ShopBilling, ShopBillingPlan } from '../../../models/billing/billing.model';
import { BillingService } from 'src/app/service/billing.service';
import { DialogSettingSubscriptionSummaryComponent } from '../../dialog/dialog-setting-subscription-summary/dialog-setting-subscription-summary.component';
import { DialogSettingSubscriptionDowngradeComponent } from '../../dialog/dialog-setting-subscription-downgrade/dialog-setting-subscription-downgrade.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-subscription-setting',
  templateUrl: './subscription-setting.component.html',
  styleUrls: ['./subscription-setting.component.less'],
})
export class SubscriptionSettingComponent implements OnInit {
  constructor(@Optional() @SkipSelf() public billingService: BillingService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.billingService.getBilling();
  }

  openSettingSubscriptionSummaryDialog() {
    const dialogRef = this.dialog.open(DialogSettingSubscriptionSummaryComponent, { width: '620px' });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  openSettingSubscriptionDowngradeDialog() {
    if (ShopBillingPlan.FREE == this.billingService.shopBilling?.plan) {
      return;
    }
    const dialogRef = this.dialog.open(DialogSettingSubscriptionDowngradeComponent, { width: '660px' });
    dialogRef.afterClosed().subscribe((downgrade: boolean) => {
      downgrade && this.downgradeBillingPlan();
    });
  }

  upgradeBillingPlan(plan: ShopBillingPlan, billingInterval: BillingInterval) {
    this.billingService.upgradeBillingPlan(plan, billingInterval).subscribe((response) => {
      const redirectUrl = response.body['redirect_url'];
      window.location.href = redirectUrl;
    });
  }

  downgradeBillingPlan() {
    this.billingService.downgradeBillingPlan(ShopBillingPlan.FREE).subscribe((response) => {
      this.billingService.getBilling().subscribe((response) => {
        this.billingService.shopBilling = response.body as ShopBilling;
      });
    });
  }
}
