<footer class="fixed-save-bar" *ngIf="show" [@inOutAnimation]>
  <div class="fixed-save-inner" [ngStyle]="{ 'max-width': maxWidth }">
    <div class="flex-wrapper">
      <img src="assets/icon/info_white.svg" />
      You have unsaved changes. Save before you leave.
    </div>
    <div class="fixed-save-btn">
      <div class="fixed-save-cancel" (click)="onCancel()">Discard</div>
      <div class="fixed-save-confirm" (click)="onSave()">Save</div>
    </div>
  </div>
</footer>
