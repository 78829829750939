<article id="account-invite">
  <div style="background-color: white; border-radius: 20px; width: 500px; max-height: 800px;  padding: 30px;">

    <form>
      <header class="admin-header-1">
        Your Information
      </header>
      <summary class="account-summary" style="margin-bottom: 20px;">
        Already have an account? <a routerLink="/admin/login">Sign In</a>
      </summary>

      <section style="display: flex;">
        <div class="account-input-container">
          <label class="account-input-label">
            First Name
          </label>
          <input class="admin-input account-input" type="text" [(ngModel)]="shopAdminAccount.first_name"
            #firstName="ngModel" name="firstName">
          <div class='admin-warning' *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
            <img class="info-red" src="assets/icon/info_red.svg">
            First name is required.
          </div>
        </div>
        <div class="account-input-container">
          <label class="account-input-label">
            Last Name
          </label>
          <input class="admin-input account-input" type="text" [(ngModel)]="shopAdminAccount.last_name"
            #lastName="ngModel" name="lastName">
          <div class='admin-warning' *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
            <img class="info-red" src="assets/icon/info_red.svg">
            Last name is required.
          </div>
        </div>
      </section>
      <div class="account-input-container">
        <label class="account-input-label">
          Email Address
        </label>
        <input class="admin-input account-input" type="text" [(ngModel)]="shopAdminAccount.account_id" [disabled]="true"
          #accountId="ngModel" name="accountId">
        <div class='admin-warning' *ngIf="accountId.invalid && (accountId.dirty || accountId.touched)">
          <img class="info-red" src="assets/icon/info_red.svg">
          Enter a valid email address.
        </div>
      </div>
      <div style="margin-bottom: 30px;">
        <alpha-checkbox [(ngModel)]="shopAdminAccount.accepts_marketing" name="acceptsMarketing">
          I consent to receive promotional emails about your products and services.
        </alpha-checkbox>
      </div>
      <header class="admin-header-1" style="margin-bottom: 20px;">
        Activate Your Account
      </header>
      <div class="account-input-container">
        <label class="account-input-label">
          Contain at least 8 characters
        </label>
        <input class="admin-input account-input" type="password" [(ngModel)]="shopAdminAccount.password"
          #password="ngModel" name="password">
        <div class='admin-warning' *ngIf="password.invalid && (password.dirty || password.touched)">
          <img class="info-red" src="assets/icon/info_red.svg">
          Password is required.
        </div>

        <div class="account-summary" style="font-size: 12px; margin-top: 10px;">
          Mixing upper, lower case letters, symbols, and more enhances password safety.
        </div>


      </div>
      <div class="account-input-container">
        <label class="account-input-label">
          Confirm password
        </label>
        <input class="admin-input account-input" type="password" [(ngModel)]="shopAdminAccount._passwordConfirm"
          #passwordConfirm="ngModel" name="passwordConfirm">
        <div class='admin-warning' *ngIf="password.valid && passwordConfirm.value !== password.value">
          <img class="info-red" src="assets/icon/info_red.svg">
          Please enter the same Password as above.
        </div>
      </div>

      <div style="display: flex; justify-content: center;">
        <button (click)="onClickSubmit()" class="btn-confirm"
          [disabled]="firstName.invalid || lastName.invalid || !shopService.checkValidAdminAccountPassword(password.value)"
          style="padding-left: 56px; padding-right: 56px; width: unset;">
          Submit
        </button>
      </div>
    </form>
  </div>


</article>
