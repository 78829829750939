import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { routeAnimation } from './animations';
import { LandingService } from './landing.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less'],
  // animations: [routeAnimation],
})
export class HomeComponent implements OnInit {
  constructor(public landingService: LandingService, private router: Router, public breakpointObserver: BreakpointObserver) {
    this.landingService.mediaChange(this.breakpointObserver);
    this.router.url == '/home' && this.router.navigate(['/home/main']);
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  scrolled: boolean = false;
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrolled = window.scrollY > 0;
  }

  onActivate(componentRef: any) {
    this.landingService.idx = componentRef.idx;
    //;
  }

  // prepareRoute(outlet: RouterOutlet) {
  //   return (
  //     outlet &&
  //     outlet.activatedRouteData &&
  //     outlet.activatedRouteData['animation']
  //   );
  // }
}
