<article id="analysis-template" class="gray-border-wrapper">
  <section>
    <header class="analysis-header" style="margin-bottom: 0">Product Name</header>
    <header class="admin-header-4" style="height: 72px; align-items: flex-start">
      <div class="ellipsis-3">
        {{ analysis.product_name }}
      </div>
    </header>
  </section>
  <div class="analysis-scroll">
    <section class="analysis-summary">
      <header class="analysis-header">Quick Summary</header>
      <div class="summary-content">{{ analysis.paragraph_summary }}</div>
    </section>

    <section class="analysis-ratio">
      <header class="analysis-header">Positive-Negative Ratio</header>
      <section class="flex-wrapper">
        Positive: {{ analysis.positive_proportion | number : '1.0-0' }}%｜Neutral: {{ neutralProportion | number : '1.0-0' }}%｜Negative:
        {{ analysis.negative_proportion | number : '1.0-0' }}%
      </section>
    </section>

    <section class="analysis-positive">
      <header class="analysis-header">Keywords Mentioned in Positive Reviews</header>
      <section class="positive-container">
        <ng-container *ngFor="let positive of analysis.positive_keyword_list; let i = index">
          <div *ngIf="i < 3" class="flex-wrapper positive-keyword" style="gap: 10px">
            <div class="flex-wrapper" style="gap: 10px">
              <div class="positive-rank">{{ i + 1 }}</div>
              <div class="positive-text ellipsis-1">{{ positive[0] }}</div>
            </div>
            <div class="positive-count">{{ positive[1] }} times</div>
          </div>
        </ng-container>
      </section>
    </section>

    <section class="analysis-negative">
      <header class="analysis-header">Keywords Mentioned in Negative Reviews</header>
      <section class="negative-container">
        <ng-container *ngFor="let negative of analysis.negative_keyword_list; let i = index">
          <div *ngIf="i < 3" class="flex-wrapper negative-keyword">
            <div class="flex-wrapper" style="gap: 10px">
              <div class="negative-rank">{{ i + 1 }}</div>
              <div class="negative-text ellipsis-1">{{ negative[0] }}</div>
            </div>
            <div class="negative-count">{{ negative[1] }} times</div>
          </div>
        </ng-container>
      </section>
    </section>
  </div>

  <section class="flex-wrapper" style="gap: 10px">
    <div (click)="goToAliexpress()" class="btn-white" style="height: 36px; width: 50%">Go to AliExpress</div>
    <div (click)="viewDetails(analysis.analysis_log.id)" class="btn-black" style="width: 50%">View Detail</div>
  </section>
</article>
