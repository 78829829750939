"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDevelopmentClient = exports.isProduction = exports.isDevelopment = exports.isUnframed = exports.isServer = exports.isClient = void 0;
var env_1 = require("@shopify/app-bridge-core/util/env");
var env_2 = require("@shopify/app-bridge-core/util/env");
Object.defineProperty(exports, "isClient", { enumerable: true, get: function () { return env_2.isClient; } });
Object.defineProperty(exports, "isServer", { enumerable: true, get: function () { return env_2.isServer; } });
Object.defineProperty(exports, "isUnframed", { enumerable: true, get: function () { return env_2.isUnframed; } });
exports.isDevelopment = typeof process !== 'undefined' && process.env && process.env.NODE_ENV === 'development';
exports.isProduction = !exports.isDevelopment;
exports.isDevelopmentClient = exports.isDevelopment && env_1.isClient;
