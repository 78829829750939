<article id="display" class="setting-article" style="max-width: 1000px">
  <header class="admin-header-1">Display</header>

  <section class="gray-border-wrapper flex-wrapper" [routerLink]="'widget-custom'" (click)="genericService.LNBCategory = 'Widget Custom'">
    <img src="assets/icon/display_widget_custom.svg" />
    <div class="setting-content-detail">
      <header class="setting-content-header">
        <div class="setting-content-header-value">
          <span>Widget Custom</span>
        </div>
      </header>
      <summary class="setting-content-header-summary">Customize the detailed design for widgets on the homepage and product pages.</summary>
    </div>
    <div class="setting-content-go">
      <img [src]="'assets/icon/arrow_plat_right.svg'" />
    </div>
  </section>

  <section class="gray-border-wrapper flex-wrapper" [routerLink]="'widget-localization'" (click)="genericService.LNBCategory = 'Widget Language Localization'">
    <img src="assets/icon/widget_localization.svg" />
    <div class="setting-content-detail">
      <header class="setting-content-header">
        <div class="setting-content-header-value">
          <span>Widget Language Localization</span>
        </div>
      </header>
      <summary class="setting-content-header-summary">Customize widget text to fit your audience's language with Widget Language Localization.</summary>
    </div>
    <div class="setting-content-go">
      <img [src]="'assets/icon/arrow_plat_right.svg'" />
    </div>
  </section>

  <section class="gray-border-wrapper flex-wrapper" [routerLink]="'design-theme'" (click)="genericService.LNBCategory = 'Design Theme'">
    <img src="assets/icon/review_settings_text.svg" />
    <div class="setting-content-detail">
      <header class="setting-content-header">
        <div class="setting-content-header-value">
          <span>Design Theme</span>
        </div>
      </header>
      <summary class="setting-content-header-summary">Customize the overall design theme, including fonts and star shapes.</summary>
    </div>
    <div class="setting-content-go">
      <img [src]="'assets/icon/arrow_plat_right.svg'" />
    </div>
  </section>

  <section class="gray-border-wrapper flex-wrapper" [routerLink]="'rating-display'" (click)="genericService.LNBCategory = 'Rating Display'">
    <img src="assets/icon/review_settings_quick.svg" />
    <div class="setting-content-detail">
      <header class="setting-content-header">
        <div class="setting-content-header-value">
          <span>Rating Display</span>
        </div>
      </header>
      <summary class="setting-content-header-summary">Display product ratings and review counts on product collection and product page.</summary>
    </div>
    <div class="setting-content-go">
      <img [src]="'assets/icon/arrow_plat_right.svg'" />
    </div>
  </section>

  <section class="gray-border-wrapper flex-wrapper" (click)="showCaseService.getDeepLinkingUrl()">
    <img src="assets/icon/add_app_block.svg" />
    <div class="setting-content-detail">
      <header class="setting-content-header">
        <div class="setting-content-header-value">
          <span>Add App Blocks on Your Online Store</span>
        </div>
      </header>
    </div>
    <div class="setting-content-go">
      <img [src]="'assets/icon/external_link.svg'" />
    </div>
  </section>
</article>
