import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-label',
  template: `<svg style="display: inherit" xmlns="http://www.w3.org/2000/svg" width="16" height="16.5" viewBox="0 0 16 16.5"><path d="M329.1 379.283v8.109l8.321 8.391 7.679-8.391-8-8.109zm5.687 5.765a1.7 1.7 0 0 1-2.421 0 1.729 1.729 0 0 1-.379-.583 1.755 1.755 0 0 1 .378-1.872 1.7 1.7 0 0 1 2.422 0 1.754 1.754 0 0 1-.003 2.455z" transform="translate(-329.097 -379.283)" [ngStyle]="{'fill' : fill}" /></svg>`,
  styles: [
  ]
})
export class IconLabelComponent implements OnInit {

  constructor() { }

  @Input() fill: string = '#91949c'

  ngOnInit(): void {
  }

}
