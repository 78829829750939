import { Component, OnInit, Optional, SkipSelf, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthSuperAdminService } from 'src/app/auth/auth-super-admin.service';
import { SuperAdminCurrentUser } from 'src/app/models/super-admin/super-admin.shops.model';
import { BackendService } from 'src/app/service/backend.service';
import { GenericService } from 'src/app/service/generic.service';
import { SessionService } from 'src/app/service/session.service';
import { SuperAdminService } from 'src/app/service/super-admin.service';

@Component({
  selector: 'app-login',
  templateUrl: './super-admin-login.component.html',
  styleUrls: ['./super-admin-login.component.less'],
})
export class LoginComponent implements OnInit {
  constructor(
    @Optional() @SkipSelf() public superAdminService: SuperAdminService,
    @Optional() @SkipSelf() public authSuperAdminService: AuthSuperAdminService,
    private genericService: GenericService,
    private sessionservice: SessionService,
    private backendService: BackendService,
    private router: Router,
  ) {
    sessionStorage.clear();
  }

  superAdminAccessCode!: string;

  account_id: string = '';
  password: string = '';

  doLogin: boolean = false;

  init = false;
  egg: any = undefined;

  top = 50;
  left = 50;
  count = 0;
  interval = 1024;
  size = 1;

  ngOnInit(): void {
    this.superAdminService.clearAccessCode();

    setTimeout(() => {
      this.init = true;
    }, this.interval);

    setTimeout(() => {
      this.egg = setInterval(() => this.easterEgg(), this.interval);
    }, 15000);
  }

  easterEgg() {
    return;
    this.count += 1;
    this.top = Math.floor(Math.random() * 100);
    this.left = Math.floor(Math.random() * 100);
    this.interval = this.interval > 50 ? Math.floor(this.interval / 1.02) : this.interval;
    this.count = 0;
    clearInterval(this.egg);
    this.egg = setInterval(() => this.easterEgg(), this.interval);
  }

  onLogin(): void {
    this.doLogin = true;
    this.authSuperAdminService.superAdminLogin({ account_id: this.account_id, password: this.password }).subscribe({
      next: (response) => {
        if (response.status == 200) {
          this.superAdminService.SuperAdminAccessToken = response.body['super_admin_access_token'];
          let url = 'super-admin/current-user';
          this.backendService.select(url).subscribe((response) => {
            this.superAdminService.CurrentUser = response.body as SuperAdminCurrentUser;

            this.router.navigate(['super', 'shops']);
          });
        }
      },
      error: (e) => {
        if (e.status == 401) this.genericService.openSnackBar('Invalid account or password. Please try again. ');

        this.doLogin = false;
      },
      complete: () => {
        this.doLogin = false;
      },
    });
  }
}
