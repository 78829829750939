<main id="landing-footer-content">
  <section class="footer-header inner" *ngIf="!landingService.mobile">
    <h1 class="footer-logo">
      <img
        src="assets/icon/landing-logo-icon.svg"
        alt="logo image"
        style="width: 20px; height: 20px; margin-right: 6px"
      />
      Alpha Review
    </h1>
  </section>

  <section class="footer-sitemap landing-flex-wrapper inner">
    <div class="landing-flex-wrapper">
      <div class="footer-sitemap-wrapper">
        <dl>
          <dt>Features</dt>
          <dd><a [routerLink]="'./collect'">Collect Reviews</a></dd>
          <dd><a [routerLink]="'./moderate'">Moderate Reviews</a></dd>
          <dd><a [routerLink]="'./showcase'">Showcase Reviews</a></dd>
          <dd><a [routerLink]="'./marketing'">Marketing & SEO</a></dd>
        </dl>
        <dl *ngIf="landingService.mobile">
          <dt>Legal</dt>
          <dd><a href="">Terms</a></dd>
          <dd>
            <a
              href="https://alph.oopy.io/7f1928da-4440-45ec-9559-592a313043e7"
              target="_blank"
              >Privacy Policy</a
            >
          </dd>
        </dl>
      </div>
      <div class="footer-sitemap-wrapper">
        <dl>
          <dt><a href="https://alph.oopy.io/" target="_blank">User Guide</a></dt>
        </dl>
        <dl>
          <dt><a href="https://alph.oopy.io/f7e9cb43-fb97-4c47-a899-3844a14dec01" target="_blank">Pricing</a></dt>
        </dl>
      </div>
      <div class="footer-sitemap-wrapper" *ngIf="!landingService.mobile">
        <dl>
          <dt>Legal</dt>
          <dd><a href="">Terms</a></dd>
          <dd>
            <a
              href="https://alph.oopy.io/7f1928da-4440-45ec-9559-592a313043e7"
              target="_blank"
              >Privacy Policy</a
            >
          </dd>
        </dl>
      </div>
    </div>
    <div class="footer-support">
      <strong>Support</strong>
      <p>support&#64;alph.kr</p>
    </div>
  </section>

  <section class="footer-copyright">
    <div class="landing-flex-wrapper inner">
      <section class="footer-contact">
        <h1 class="footer-contact-compuny">
          Saladlab<span>,Inc.</span>
          <span class="compuny-number">l &nbsp; 249-88-00700</span>
        </h1>
        <p class="footer-contact-compuny-address">
          8F, 14-11, Teheran-ro 78-gil, <br />
          Gangnam-gu, Seoul, Republic of Korea
        </p>
      </section>
      <section class="footer-copyright-text">
        <p>Copyright(c) Saladlab Inc. ALL RIGHT RESERVED</p>
        <p>
          We make a commerce market where both <br class="mobr" />
          consumers and merchants are happy.
        </p>
      </section>
    </div>
  </section>
</main>
