<article id="email-verify">
    <div class="title">이메일 인증</div>
<div class="super-admin-info">
    
    
    <div class="email-input-box">
        <div class="lable">email</div>
        <div class="email">{{superAdminService.CurrentUser.email}}</div>
        <button  class="email-change-button" type="button"  (click)="sendVerifyCode()">인증번호 발송</button>
    </div>
    <div class="email-input-box">
        <div class="lable">인증코드</div>
        <input class="email-field" [formControl]="verifyCode">
        <button class="email-change-button" type="button" (click)="checkVerifyCode()">확인하기</button>
    </div>
</div>  

</article>