import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SuperAdminGuard } from '../guard/super-admin.guard';
import { LoginComponent } from './login/super-admin-login.component';
import { SuperAdminRegistComponent } from './regist/super-admin-regist.component';
import { SuperAdminComponent } from './super-admin.component';


@NgModule({
  imports: [RouterModule.forChild([
    { path: 'login', component: LoginComponent },
    { path: 'regist/:pid', component: SuperAdminRegistComponent},
    {
      path: '',
      component: SuperAdminComponent,
      children: [
        {
          path: 'shops',
          canActivate: [SuperAdminGuard],
          data: { title: 'Shops' },
          loadChildren: () =>
            import('./shops/shops.module').then((m) => m.ShopsModule),
        },
        {
          path: 'accounts',
          canActivate: [SuperAdminGuard],
          data: {title: 'Accounts'},
          loadChildren: () =>
            import('./accounts/accounts.module').then((m) => m.AccountsModule),
        },
        {
          path: 'posts',
          canActivate: [SuperAdminGuard],
          data: {title: 'Posts'},
          loadChildren: ()=>
            import('./posts/posts.module').then((m => m.PostsModule)),
        }
      ]
    }
  ]
  )],
  exports: [RouterModule]
})
export class SuperAdminRoutingModule { }
