import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { GenericService } from 'src/app/service/generic.service';
import { RouterExtService } from 'src/app/service/routerExt.service';
import { AlphaApps, ShopDetail } from 'src/app/models/shop/profile/store-detail.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogStartAiComponent } from '../../dialog/dialog-start-ai/dialog-start-ai.component';
import { DialogStartListDesignerComponent } from '../../dialog/dialog-start-list-designer/dialog-start-list-designer.component';
import { ProductService } from 'src/app/service/product.service';
import { AdminService } from 'src/app/service/admin.service';
import { ListDesignerService } from '../../list-designer/list-designer.service';
import { DialogStartBrowseBoosterComponent } from '../../dialog/dialog-start-browse-booster/dialog-start-browse-booster.component';
import { BrowseBoosterService } from '../../browse-booster/browse-booster.service';

@Injectable({
  providedIn: 'root',
})
export class LeftNavigationBarService {
  constructor(
    private adminService: AdminService,
    private genericService: GenericService,
    private routerExpService: RouterExtService,
    private productService: ProductService,
    private listDesignerService: ListDesignerService,
    private browseBoosterService: BrowseBoosterService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.onChangeLNB();
  }

  history!: string[];
  selectedApp: AlphaApps | undefined = undefined;
  shopDetail!: ShopDetail;
  recommendation: boolean = false;

  onChangeLNB() {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        let url = this.router.url;
        if (url.includes('product-reviews')) this.selectedApp = AlphaApps.REVIEW;
        else this.selectedApp = undefined;
      }
    });
  }

  setRecommendation() {
    if (!this.shopDetail) return;
    this.recommendation = [AlphaApps.REVIEW, AlphaApps.AI, AlphaApps.LIST_DESIGNER, AlphaApps.BROWSE_BOOSTER].every((value) => this.shopDetail.use_apps.includes(value));
  }

  popStateLNBActive(lnbContent: any, matPanel: any) {
    this.router.events.subscribe((e) => {
      let category =
        lnbContent.find((item: any) => item.value == this.genericService.LNBCategory) ||
        lnbContent.find((item: any) => item.content.find((item: any) => item.value == this.genericService.LNBCategory || item.name == this.genericService.LNBCategory));

      if (category == undefined) return;
      if (category.value == 'home') matPanel?.forEach((item: any, idx: number) => matPanel?.get(idx)?.close());
      if (e instanceof NavigationStart && this.routerExpService.lnbTrigger) {
        const trigger = e.navigationTrigger === 'popstate' && this.genericService.LNBCategory != this.history[this.history.length - 1];
        trigger && matPanel?.get(category.idx)?.close();
      }
      if (e instanceof NavigationEnd && category.idx > 0) matPanel?.get(category.idx)?.open();
    });
  }

  openDialogUseAI() {
    const dialogRef = this.dialog.open(DialogStartAiComponent, { width: '530px', data: { shopName: this.shopDetail.store_name } });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.router.navigate([`admin/ai-analysis`]);
    });
  }

  openDialogUseListDesigner() {
    this.getProducts().then((hasProducts) => {
      const dialogRef = this.dialog.open(DialogStartListDesignerComponent, { width: '768px', maxHeight: '80vh', data: hasProducts, disableClose: true });

      dialogRef.afterClosed().subscribe((result) => {
        if (!result) return;
        this.router.navigate(['/admin/list-designer/widget-custom']);
      });
    });
  }

  openDialogBrowseBooster() {
    const dialogRef = this.dialog.open(DialogStartBrowseBoosterComponent, { width: '768px', maxHeight: '80vh' });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.browseBoosterService.openDialogOnboarding();
    });
  }

  async getProducts(): Promise<boolean> {
    const response = await this.productService.getProducts({ shop_access_code: this.adminService.getShopAccessCode() }).toPromise();
    return response.body.length > 0;
  }

  get AlphaApps(): typeof AlphaApps {
    return AlphaApps;
  }
}
