import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { LandingCollectComponent } from './landing-collect/landing-collect.component';
import { LandingMainComponent } from './landing-main/landing-main.component';
import { LandingManageComponent } from './landing-manage/landing-manage.component';
import { LandingMarketingComponent } from './landing-marketing/landing-marketing.component';
import { LandingShowcaseComponent } from './landing-showcase/landing-showcase.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: HomeComponent,
        children: [
          {
            path: 'main',
            component: LandingMainComponent,
          },
          {
            path: 'collect',
            component: LandingCollectComponent,
          },
          {
            path: 'moderate',
            component: LandingManageComponent,
          },
          {
            path: 'showcase',
            component: LandingShowcaseComponent,
          },
          {
            path: 'marketing',
            component: LandingMarketingComponent,
          },
          { path: '**', redirectTo: '/home/main' },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
