<article id="dialog-admin-account" style="padding: 30px">
  <header class="admin-header-1">Add Member</header>

  <section style="margin-top: 10px; margin-bottom: 20px">
    <div class="container">
      <div class="title">First Name</div>
      <input class="admin-input content" [(ngModel)]="shopAdminAccount.first_name" placeholder="John" #firstName="ngModel" />
    </div>

    <div class="container">
      <div class="title">Email Address</div>
      <input class="admin-input content" [(ngModel)]="shopAdminAccount.account_id" placeholder="example@gmail.com" #accountID="ngModel" (keydown.enter)="onConfirm()" />
    </div>

    <div class="container" style="margin-bottom: 8px">
      <div class="title">Role</div>
      <section class="select-wrapper" style="width: 100%">
        <select class="admin-select" [(ngModel)]="shopAdminAccount.position" style="width: 100%">
          <option [ngValue]="shopService.ShopAccountPosition.OWNER">Owner</option>
          <option [ngValue]="shopService.ShopAccountPosition.MANAGER">Manager</option>
          <option [ngValue]="shopService.ShopAccountPosition.STAFF">Staff</option>
        </select>
      </section>
    </div>

    <div class="container">
      <div class="title"></div>
      <div class="content summary">
        <admin-account-role-description [shopAdminAccount]="shopAdminAccount"></admin-account-role-description>
      </div>
    </div>
  </section>

  <div class="flex-wrapper" style="justify-content: flex-end; gap: 10px">
    <div class="btn-white" style="width: 90px; height: 36px" (click)="onNoClick()">Cancel</div>
    <div
      class="btn-black"
      style="width: 125px"
      (click)="onConfirm()"
      [class.disabled]="shopAdminAccount.first_name.length == 0 || !checkValidEmail(this.shopAdminAccount.account_id)"
    >
      Send Invitation
    </div>
  </div>
</article>
