import { Component, OnInit, Optional, SkipSelf } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { createApp } from '@shopify/app-bridge';
import { Redirect } from '@shopify/app-bridge/actions';
import { AuthAdminService } from 'src/app/auth/auth-admin.service';
import { AlphaApps } from 'src/app/models/shop/profile/store-detail.model';
import { ShopAdminAccount } from 'src/app/models/shop/shop.model';
import { AdminService } from 'src/app/service/admin.service';
import { ShopService } from 'src/app/service/shop.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login-bridge',
  templateUrl: './login-bridge.component.html',
  styleUrls: ['./login-bridge.component.less'],
})
export class LoginBridgeComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Optional() @SkipSelf() private shopService: ShopService,
    @Optional() @SkipSelf() private authService: AuthAdminService,
    @Optional() @SkipSelf() private adminService: AdminService,
  ) {
    this.route.params.subscribe((params) => {
      const shopAdminAccountToken = params['shopAdminAccountToken'];
      const isEmbeded = params['isEmbeded'];

      if (shopAdminAccountToken) {
        if (isEmbeded) {
          this.dashboardUrl = this.router.serializeUrl(this.router.createUrlTree([`/admin/login/bridge/${shopAdminAccountToken}`]));
          this.onClickBtn();
        } else {
          this.adminService.ShopAccessToken = shopAdminAccountToken;
          this.authService.adminLoginBridge(this.adminService.ShopAccessToken).subscribe((response) => {
            if (response.status == 200) {
              const shopAdminAccount = response.body as ShopAdminAccount;
              shopAdminAccount.shops = [...shopAdminAccount.alpha_plus_shops, ...shopAdminAccount.browse_booster_shops].filter(
                (shop, idx, self) => idx === self.findIndex((t) => t.platform_id === shop.platform_id),
              );
              shopAdminAccount.shops.forEach((shop) => (shop.access_code = shop.access_code?.slice(2, shop.access_code.length)));

              this.adminService.setAdminAccountSession(shopAdminAccount);
              this.shopService.currency = shopAdminAccount.shop!.currency as string;

              this.router.navigate(['admin', 'market-place'], { replaceUrl: true });
            }
          });
        }
      }
    });
  }

  isEmbeded: boolean = false;

  dashboardUrl: string | undefined = undefined;

  ngOnInit(): void {}

  onClickBtn() {
    if (self == top) {
      document.documentElement.style.display = 'block';
    } else {
      const app = createApp({
        apiKey: environment.shopifyApiKey,
        host: new URLSearchParams(location.search).get('host')!,
      });
      const redirect = Redirect.create(app);
      redirect.dispatch(Redirect.Action.REMOTE, app.localOrigin + this.dashboardUrl!);
    }
  }
}
