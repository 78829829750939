import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from 'src/app/guard/admin.guard';
import { WidgetsComponent } from './widgets/widgets.component';
import { WidgetCustomSettingsComponent } from './widget-custom-settings/widget-custom-settings.component';
import { WidgetGuideComponent } from './widget-guide/widget-guide.component';

const routes: Routes = [
  { path: 'widget-list', canActivate: [AdminGuard], data: { title: 'Widget List' }, component: WidgetsComponent },
  { path: 'widget-custom', canActivate: [AdminGuard], data: { title: 'Widget Custom Settings' }, component: WidgetCustomSettingsComponent },
  { path: 'widget-guide', canActivate: [AdminGuard], data: { title: 'Widget Guide' }, component: WidgetGuideComponent },

  { path: '', pathMatch: 'full', redirectTo: 'widget-list' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ListDesignerRoutingModule {}
