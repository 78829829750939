<article id="customer-notice">
  <section class="gray-border-wrapper">
    <header class="admin-header-3">Add Notice Widget</header>
    <summary class="admin-content-description" style="padding: 0 0 16px">Install a Notice widget in your store to display important messages to your customers</summary>
    <div (click)="getDeepLinkingUrl()" class="btn-black deeplink" style="width: 206px">Go to Shopify Theme Editor</div>
  </section>

  <section class="gray-border-wrapper" style="margin-top: 20px">
    <header class="admin-header-3 flex-wrapper">
      Customer Notices
      <div (click)="addShopNotice()" class="btn-black" style="width: 107px">Add a Notice</div>
    </header>
    <section class="content-wrapper" style="padding: 13px 0 0">
      <table mat-table #noticeTable [dataSource]="noticeDataSource" style="width: 100%">
        <ng-container matColumnDef="status">
          <th i18n mat-header-cell *matHeaderCellDef width="100px">Status</th>
          <td mat-cell *matCellDef="let notice" class="text-align-center">
            <ng-container [ngSwitch]="notice.status">
              <div *ngSwitchCase="NoticeStatus.BEFORE_POSTING" class="status-type">
                <img src="assets/icon/notice_status_before_posting.svg" alt="Before Posting" />
                To be posted
              </div>

              <div *ngSwitchCase="NoticeStatus.POSTING" class="status-type">
                <img src="assets/icon/notice_status_posting.svg" alt="Posting" />
                Posted
              </div>

              <div *ngSwitchCase="NoticeStatus.END_POSTING" class="status-type">
                <img src="assets/icon/notice_status_end_posting.svg" alt="End Posting" />
                Hidden
              </div>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th i18n mat-header-cell *matHeaderCellDef width="497px">Title</th>
          <td mat-cell *matCellDef="let notice">
            <div i18n class="ellipsis-2">{{ notice.title }}</div>
            <div class="flex-wrapper" style="width: fit-content; gap: 6px; margin-top: 6px">
              <img src="assets/icon/user_tiny_gray.svg" />
              {{ notice.writer }}
            </div>
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="posting_start">
          <th i18n mat-header-cell *matHeaderCellDef width="110px">Views</th>
          <td mat-cell *matCellDef="let notice">
            {{ notice.posting_start | date : 'MMMM d, y' }}
          </td>
        </ng-container> -->

        <ng-container matColumnDef="posting_end">
          <th i18n mat-header-cell *matHeaderCellDef width="110px">End Date</th>
          <td mat-cell *matCellDef="let notice">
            {{ notice.posting_end | date : 'mediumDate' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th i18n mat-header-cell *matHeaderCellDef width="121px">Date of Creation</th>
          <td mat-cell *matCellDef="let notice">
            {{ notice.created_at | date : 'mediumDate' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th i18n mat-header-cell *matHeaderCellDef width="84px">Delete</th>
          <td mat-cell *matCellDef="let notice" (click)="deleteShopNotice(notice.id); $event.stopPropagation()" class="text-align-center" style="cursor: pointer">
            <div class="btn-white" style="width: 64px">Delete</div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="noticeDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: noticeDisplayedColumns" class="notice-table-row" (click)="openCreateNoticeDialog(row)"></tr>
      </table>
      <div *ngIf="emptyData" style="padding-bottom: 30px; border-bottom: 1px solid #d6d6d6">
        <app-empty-data
          style="display: block; margin: -30px 0 16px"
          [summary]="'Please refer to the User Guide on<br>how to create a new customer notice.'"
          [btnShow]="true"
          [btnText]="'How to create a customer notice'"
          [url]="'https://alph.oopy.io/a979122e-93ec-4bb0-b159-d28943b58cd8'"
        ></app-empty-data>
      </div>
    </section>
  </section>
</article>
