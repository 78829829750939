import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ListDesignerPreset, ListDesignerProduct, RecentProductFilter } from 'src/app/models/list-designer/widget.model';
import { ListDesignerService } from '../../list-designer.service';
import { CommonModule } from '@angular/common';
import { cloneDeep, isEqual } from 'lodash';
import { fadeAnimation, inOutAnimation } from 'src/app/admin/animations';
import { CustomConfirmDialogComponent } from 'src/app/dialog/custom-confirm-dialog/custom-confirm-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { AdminService } from 'src/app/service/admin.service';
import { Product } from 'src/app/models/product/product.model';
import * as _ from 'lodash';
import { ProductService } from 'src/app/service/product.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { GenericService } from 'src/app/service/generic.service';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-dialog-add-and-edit-products',
  standalone: true,
  imports: [CommonModule, MatMenuModule, SharedModule, DragDropModule, MatTooltipModule],
  templateUrl: './dialog-add-and-edit-products.component.html',
  styleUrl: './dialog-add-and-edit-products.component.less',
  animations: [inOutAnimation, fadeAnimation],
})
export class DialogAddAndEditProductsComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { widgetId: number; template: ListDesignerPreset; widgetInProducts: Product[] },
    public listDesignerService: ListDesignerService,
    private adminService: AdminService,
    public productService: ProductService,
    private genericService: GenericService,
    private dialogRef: MatDialogRef<DialogAddAndEditProductsComponent>,
    private dialog: MatDialog,
  ) {
    if (data.widgetInProducts) {
      this.filteringProducts = data.widgetInProducts;
      this.originFilteringProducts = cloneDeep(this.filteringProducts);
    }
  }

  ngOnInit(): void {
    this.getProducts(this.sortingType);
  }

  filteringProducts: Product[] | null = null;
  originFilteringProducts?: Product[];
  isUpdated: boolean | null = null;
  originFilter?: RecentProductFilter;
  showAddProduct: boolean = false;

  products: Product[] = [];
  originProducts?: Product[];
  selectedProducts?: Product[];

  state: string = '';
  sortingType: '-review_count' | 'selling' | 'newest' = 'newest';

  maxItems: boolean = false;

  isAll: boolean = false;

  changeSection(trigger: boolean) {
    this.showAddProduct = trigger;

    this.showAddProduct && this.getProducts(this.sortingType);
  }

  return = () => {
    return;
  };

  checkedAll(event: boolean) {
    this.filteringProducts?.forEach((product) => (product.checked = event));
  }

  checkedProductLength() {
    return this.filteringProducts?.filter((product) => product.checked).length;
  }

  mergedFilteringProducts() {
    this.filteringProducts = this.filteringProducts?.length ? [...this.filteringProducts!, ...this.selectedProducts!] : [...this.selectedProducts!];
    this.data.template = this.listDesignerService.ListDesignerPreset.MANUAL;
    this.filteringProducts.forEach((product) => (product.checked = false));
    this.changeSection(false);

    this.genericService.openSnackBar('Selected products are added to the widget’s product list.');
  }

  getSelectedProduct() {
    this.selectedProducts = this.products.filter((product) => product.checked);
    this.maxItems = this.selectedProducts.length + this.filteringProducts?.length! > 20;
  }

  getProducts(sort: string) {
    let params = {
      search_content: this.productService.searchContent,
      sorting_type: sort,
      shop_access_code: this.adminService.getShopAccessCode(),
    };
    this.productService.getProducts(params).subscribe((response) => {
      this.products = response.body.data as Product[];
      this.products = _.differenceWith(this.products, this.filteringProducts!, (product, filterProduct) => product.id === filterProduct.id);
    });
  }

  saveListDesignerProducts() {
    let result = { filteringProducts: this.filteringProducts, template: this.data.template };
    this.dialogRef.close(result);
  }

  orderedDictFromArray(ids: number[]) {
    const dictionary = ids.reduce((acc: { [key: number]: number }, value, index) => {
      acc[index + 1] = value;
      return acc;
    }, {});
    return dictionary;
  }

  getListFilterProducts() {
    let params: any = {
      shop_access_code: this.adminService.getShopAccessCode(),
    };

    params = _.pickBy(params, (value) => value !== null);

    this.listDesignerService.getListFilterProducts(params).subscribe((response) => {
      if (this.isUpdated) this.isUpdated = false;
      this.filteringProducts = response.body;
      this.products = _.differenceWith(this.products, this.filteringProducts!, (product, filterProduct) => product.id === filterProduct.id);
    });
  }

  drop(event: CdkDragDrop<Product[]>) {
    moveItemInArray(this.filteringProducts!, event.previousIndex, event.currentIndex);
  }

  deleteProduct() {
    this.openConfirmDialog().subscribe((result) => {
      if (!result) return;
      this.filteringProducts = this.filteringProducts!.filter((product) => !product.checked);
      this.data.template = this.listDesignerService.ListDesignerPreset.MANUAL;
      this.products = _.differenceWith(this.products, this.filteringProducts!, (product, filterProduct) => product.id === filterProduct.id);
      this.isAll = false;
    });
  }

  openConfirmDialog(reset?: boolean) {
    let manualTemplate = this.data.template === this.listDesignerService.ListDesignerPreset.MANUAL;
    let dialogRef = this.dialog.open(CustomConfirmDialogComponent, { width: '431px' });
    let instance = dialogRef.componentInstance;
    instance.alignCenter = true;
    instance.header = reset ? 'Are you sure you want to reset all to default?' : 'Would you like to delete the selected products?';
    instance.content = !manualTemplate && !reset ? 'The template application will be undone.\nThe settings will all be maintained.' : '';

    return dialogRef.afterClosed();
  }

  onCancel() {
    const dialogRef = this.dialog.open(CustomConfirmDialogComponent, { width: '431px' });
    let instance = dialogRef.componentInstance;
    instance.header = `Unsaved Changes Detected`;
    instance.content = `Are you sure you want to leave this
      page without saving? All unsaved changes will be lost.`;
    instance.cancel = `Stay`;
    instance.confirm = `Leave`;
    instance.alignCenter = true;

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.dialogRef.close();
    });
  }

  dayTransform(value: number): string {
    switch (value) {
      case 7:
        return '1 week';
      case 14:
        return '2 weeks';
      case 21:
        return '3 weeks';
      case 30:
        return '1 month';
      default:
        return value.toString();
    }
  }
}
