import { Component, OnInit, Optional, SkipSelf } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ReviewUnpublishedReason, ReviewUnpublishReasonCheckBox } from 'src/app/models/review/review.model';
import { ReviewService } from 'src/app/service/review.service';

@Component({
  selector: 'app-dialog-manage-review-unpublish',
  templateUrl: './dialog-manage-review-unpublish.component.html',
  styleUrls: ['./dialog-manage-review-unpublish.component.less'],
})
export class DialogManageReviewUnpublishComponent implements OnInit {
  constructor(@Optional() @SkipSelf() public reviewService: ReviewService, private dialogRef: MatDialogRef<DialogManageReviewUnpublishComponent>) {}

  reviewUnpublishedReason: ReviewUnpublishedReason = new ReviewUnpublishedReason();

  ngOnInit(): void {}

  setUnpublishedReason(reason: ReviewUnpublishReasonCheckBox, event: boolean) {
    if (event) {
      this.reviewUnpublishedReason.unpublished_reasons.push(reason);
    } else {
      this.reviewUnpublishedReason.unpublished_reasons = this.reviewUnpublishedReason.unpublished_reasons.filter((item) => item !== reason);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close(this.reviewUnpublishedReason);
  }
}
