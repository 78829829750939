import { Location } from '@angular/common';

import { Component, OnInit, Optional, SkipSelf } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessagingEmailTemplate, MessagingEmailTemplateFactory, MessagingSettingType, MessagingSettingTypeDetail } from 'src/app/models/shop/review/options/messaging';
import { AdminService } from 'src/app/service/admin.service';
import { BackendService } from 'src/app/service/backend.service';
import { SettingMessagingService } from '../../../../../service/setting-messaging.service';

@Component({
  selector: 'app-notification-email-custom',
  templateUrl: './notification-email-custom.component.html',
  styleUrls: ['./notification-email-custom.component.less'],
})
export class NotificationEmailCustomComponent implements OnInit {
  constructor(
    @Optional() @SkipSelf() public adminService: AdminService,
    public backendService: BackendService,
    public settingMessagingService: SettingMessagingService,
    public route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.messagingEmail = history.state.messagingEmail as any;
    this.messagingEmailTemplate = history.state.messagingEmailTemplate as MessagingEmailTemplate;
    this.messagingSettingType = history.state.messagingSettingType as MessagingSettingType;
    this.messagingSettingTypeDetail = history.state.messagingSettingTypeDetail as MessagingSettingTypeDetail;

    if (!this.messagingSettingTypeDetail) {
      this.messagingSettingTypeDetail = MessagingSettingTypeDetail.FIRST_REVIEW_REQUEST;
    }
    if (!this.messagingEmailTemplate && (this.messagingSettingTypeDetail === 0 || this.messagingSettingTypeDetail)) {
      this.messagingEmailTemplate = new MessagingEmailTemplateFactory(this.messagingSettingTypeDetail).getTemplate();
      // this.messagingEmailTemplate.id = this.messagingEmail.id;
      this.messagingEmailTemplate.messaging_id = this.messagingEmail.id;
      this.messagingEmailTemplate.template_title = 'Custom Template ' + (this.messagingEmail.messaging_email_templates.length + 1);
    }
    // if (!this.messagingEmail) {
    //   history.back()
    // }
    this.onChange();
  }

  previewEmailHTML: string = '';

  updateTemplateName = false;

  messagingSettingType: MessagingSettingType = MessagingSettingType.NOTIFICATION_SETTING;
  messagingEmail: any;
  messagingEmailTemplate!: MessagingEmailTemplate;
  messagingSettingTypeDetail!: MessagingSettingTypeDetail;

  demoSrc = 'messaging/email/template/demo';
  demoParams = '';

  demoDevice = 'PC';

  text = '';
  previewText = '';
  subject = '';

  previewTemplate: string = '';

  fontList = [
    { key: 'Inter', value: 'Inter' },
    { key: 'Poppins', value: 'Poppins' },
    { key: 'Open+Sans', value: 'Open Sans' },
    { key: 'Lora', value: 'Lora' },
    { key: 'PT+Serif', value: 'PT Serif' },
    { key: 'Roboto+Slab', value: 'Roboto Slab' },
    { key: 'Noto+Sans+KR', value: 'Noto Sans KR' },
  ];

  designThemeList = [
    { key: 'Button Theme', value: 'button' },
    { key: 'Star rating Theme', value: 'star' },
    { key: 'Emoji Theme', value: 'emoji' },
  ];

  templateVars: string[] = [
    'reviewer_email',
    'reviewer_first_name',
    'reviewer_name',
    'product_title',
    'shop_name',
    'product_stars',
    'product_reviews_count',
    'last_review_date',
    'n_days_ago',
  ];

  saveMessagingEmailTemplate() {
    let url = 'messaging/email/template';
    if (this.messagingEmailTemplate.id) {
      // 이미 생성되어있는 이메일 템플릿
      this.backendService.update(url, this.messagingEmailTemplate).subscribe((response) => {
        if (this.backendService.validateResponse(response)) {
          if (!response.body) {
            this.backendService.openSnackBar('최대 템플릿 개수는 3개 입니다.');
          } else {
            this.backendService.openSnackBar('Success');
            this.back();
          }
        }
      });
    } else {
      this.messagingEmailTemplate.messaging_id = this.messagingEmail.id;
      this.backendService.create(url, this.messagingEmailTemplate).subscribe((response) => {
        if (this.backendService.validateResponse(response)) {
          if (!response.body) {
            this.backendService.openSnackBar('최대 템플릿 개수는 3개 입니다.');
          } else {
            this.backendService.openSnackBar('Success');
            this.back();
          }
        }
      });
    }
  }

  saveSelectMessagingEmailTemplate() {
    this.messagingEmailTemplate.active = true;
    this.saveMessagingEmailTemplate();
  }

  onChange() {
    let url = 'messaging/email/template/demo';
    this.backendService.postText(url, { type_detail: this.messagingSettingTypeDetail, messaging_email_template: this.messagingEmailTemplate }).subscribe((response) => {
      this.previewEmailHTML = response;
    });
  }

  back() {
    history.back();
  }
}
