import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { AdminService } from 'src/app/service/admin.service';
import { BackendService } from 'src/app/service/backend.service';

@Component({
  selector: 'app-admin-launcher-support',
  templateUrl: './admin-launcher-support.component.html',
  styleUrls: ['./admin-launcher-support.component.less'],
})
export class AdminLauncherSupportComponent implements OnInit {
  constructor(private adminService: AdminService, private backendService: BackendService) {}

  ngOnInit(): void {
    this.email = this.adminService.ShopAdminAccount.account_id;
  }
  @ViewChild('FAQTrigger') FAQTrigger!: MatMenuTrigger;

  mode: 'FAQ' | 'EMAIL' | 'COMPLETE' = 'FAQ';

  email: string = '';
  subject: string = '';
  content: string = '';
  files = [];

  fileFormControl: UntypedFormControl = new UntypedFormControl('');

  lock: boolean = false;

  toggleMenu() {
    if (this.FAQTrigger.menuOpen) {
      this.FAQTrigger.closeMenu();
    } else {
      this.FAQTrigger.openMenu();
    }
  }

  sendEmail() {
    let url = 'shops/support/voc';
    let params = {
      shop_access_code: this.adminService.getShopAccessCode(),
    };
    let payload = new FormData();
    payload.set('to_email', this.email);
    payload.set('subject', this.subject);
    payload.set('content', this.content);
    for (let file of this.files) {
      payload.append('files', file);
    }
    this.lock = true;
    this.backendService.form(url, payload, params).subscribe({
      next: (response) => {
        this.lock = false;
        this.content = '';
        this.mode = 'COMPLETE';
      },
      error: (error) => {
        this.lock = false;
      },
    });
  }

  onChangeFile(event: any) {
    if (event.target.files && event.target.files.length) {
      this.files = event.target.files;
    }
  }
  preventClose(event: Event) {
    event.stopPropagation();
  }
}
