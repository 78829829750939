import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogProductVariantSelectComponent } from './dialog-product-variant-select/dialog-product-variant-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { TemplateModule } from 'src/app/template/template.module';
import { DialogAdminAccountInviteComponent } from './dialog-admin-account-invite/dialog-admin-account-invite.component';
import { DialogAdminAccountUpdatePositionComponent } from './dialog-admin-account-update-position/dialog-admin-account-update-position.component';
import { AdminSharedModule } from '../admin-shared/admin-shared.module';
import { DialogAdminAccountDeleteComponent } from './dialog-admin-account-delete/dialog-admin-account-delete.component';
import { DialogAdminAccountResetPasswordComponent } from './dialog-admin-account-reset-password/dialog-admin-account-reset-password.component';
import { DialogTemplateDeleteComponent } from './dialog-template-delete/dialog-template-delete.component';
import { DialogLearnMoreComponent } from './dialog-learn-more/dialog-learn-more.component';
import { SwiperModule } from 'swiper/angular';
import { PipeModule } from '../../pipe/pipe.module';
import { DialogSettingSubscriptionDowngradeComponent } from './dialog-setting-subscription-downgrade/dialog-setting-subscription-downgrade.component';
import { DialogSettingSubscriptionSummaryComponent } from './dialog-setting-subscription-summary/dialog-setting-subscription-summary.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { DialogSelectLdTemplateComponent } from './dialog-select-ld-template/dialog-select-ld-template.component';
import { DialogDeleteAppComponent } from './dialog-delete-app/dialog-delete-app.component';
import { DialogBrowseBoosterOnboardingComponent } from './dialog-browse-booster-onboarding/dialog-browse-booster-onboarding.component';
@NgModule({
  declarations: [
    DialogProductVariantSelectComponent,
    DialogAdminAccountInviteComponent,
    DialogAdminAccountUpdatePositionComponent,
    DialogAdminAccountDeleteComponent,
    DialogAdminAccountResetPasswordComponent,
    DialogTemplateDeleteComponent,
    DialogLearnMoreComponent,
    DialogSettingSubscriptionDowngradeComponent,
    DialogSettingSubscriptionSummaryComponent,
    DialogSelectLdTemplateComponent,
    DialogDeleteAppComponent,
    DialogBrowseBoosterOnboardingComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatSelectModule,
    MatRadioModule,
    SharedModule,
    AdminSharedModule,
    TemplateModule,
    SwiperModule,
    PipeModule,
  ],
})
export class DialogModule {}
