import { ElementRef, Injectable } from '@angular/core';
import { filter, first, map, throttleTime } from 'rxjs/operators';
import { cloneDeep, isEqual } from 'lodash';
import { fromEvent } from 'rxjs';
import { emailRegexp } from '../shared/regexp/shared-regexp-email';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class GenericService {
  constructor(private _snackBar: MatSnackBar) {}

  LNBCategory!: string;

  changeSelectedIndex() {
    let selectedIndex;
    if (this.LNBCategory == 'From Aliexpress' || this.LNBCategory == 'Homepage' || this.LNBCategory == 'Notification Emails') selectedIndex = 0;
    if (this.LNBCategory == 'From Amazon' || this.LNBCategory == 'Product Page' || this.LNBCategory == 'Customer Notice') selectedIndex = 1;
    if (this.LNBCategory == 'From CSV Import' || this.LNBCategory == 'General Settings') selectedIndex = 2;

    return selectedIndex;
  }

  checkValidEmail(email: string) {
    return emailRegexp.test(email);
  }
  keyPressNumbers(event: any) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  getUnicodeTextLength(text: string) {
    return new TextEncoder().encode(text).length;
  }

  isDirtyObject(origin: any, target: any) {
    return isEqual(origin, target);
  }

  getOriginObject(origin: any) {
    return cloneDeep(origin);
  }

  range(start: number, stop: number | undefined = undefined, step: number | undefined = undefined) {
    if (typeof stop == 'undefined') {
      // one param defined
      stop = start;
      start = 0;
    }

    if (typeof step == 'undefined') {
      step = 1;
    }

    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
      return [];
    }

    var result = [];
    for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
      result.push(i);
    }

    return result;
  }

  openSnackBar(message: string, action: string = 'X') {
    this._snackBar.open(message, action, { duration: 2 * 1000 });
  }

  openLink(link: string) {
    window.open(link, '_blank');
  }

  setReachHeightEndEvent(el: ElementRef<HTMLElement>) {
    fromEvent(el.nativeElement, 'scroll')
      .pipe(
        // Throttle to trigger only if 50ms have elapsed
        throttleTime(50),
        map((event: any) => {
          const el: HTMLElement = event.target;
          const pageHeight = el.clientHeight;
          const totalHeight = el.scrollHeight;
          const currentScroll = el.scrollTop;

          // Get the bottom point of the page
          const bottomPoint = pageHeight + currentScroll;

          // Get the counts of page (if needed);
          const pageCount = Math.round(totalHeight / pageHeight);

          // Get the current scroll % of the user in the page
          const percentScroll = Math.round((bottomPoint * 100) / totalHeight);

          return { pageCount, percentScroll };
        }),
        // Check if more than 80% has been scrolled
        filter((v: any) => v.percentScroll >= 80),
        // Take only the first emission
        first(),
      )
      .subscribe(() => {});
  }

  scrollTo(element: HTMLElement) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  delay = (time: number) => new Promise<void>((resolve) => setTimeout(() => resolve(), time));

  calculateDaysLeft(futureDate: Date | string): number {
    if (!futureDate) return -1;

    if (typeof futureDate == 'string') futureDate = new Date(futureDate);
    const currentDate: Date = new Date(); // 현재 날짜 및 시간
    // 두 날짜 간의 차이를 밀리초로 계산
    const diffInMilliseconds: number = futureDate.getTime() - currentDate.getTime();
    // 밀리초를 일로 변환 (1일 = 24시간 = 1440분 = 86400초 = 86400000밀리초)
    const diffInDays: number = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

    return diffInDays;
  }
}
