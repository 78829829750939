import { NgModule } from '@angular/core';
import { CustomConfirmDialogComponent } from './custom-confirm-dialog/custom-confirm-dialog.component';
import { DialogSettingProductCollectionSelectComponent } from './setting-dialog/dialog-setting-product-collection-select/dialog-setting-product-collection-select.component';
import { TemplateModule } from '../template/template.module';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [CustomConfirmDialogComponent, DialogSettingProductCollectionSelectComponent],
  imports: [CommonModule, TemplateModule, SharedModule],
})
export class DialogModule { }
