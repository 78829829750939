import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-start-alpha-review',
  templateUrl: './dialog-start-alpha-review.component.html',
  styleUrls: ['./dialog-start-alpha-review.component.less'],
})
export class DialogStartAlphaReviewComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<DialogStartAlphaReviewComponent>) {}

  ngOnInit(): void {}

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
