import { FacebookPage } from 'src/app/admin/product-reviews/marketing/social.service';
import { Review } from '../review/review.model';
import { SeparationType } from '../shop/auth/benefit/benefit.model';

export enum SocialPushLogStatus {
  ALL = 0,
  FAILURE = 1,
  PENDING = 2,
  SENT = 3,
}

export enum SocialPushPlatform {
  ALL = 0,
  FACEBOOK = 1 || 'facebook',
  TWITTER = 2 || 'twitter',
  _SETTINGS = 3 || 'settings',
}

export enum SocialPushType {
  AUTO = 1,
  MANUAL = 2,
}

export enum SocialUseFor {
  SOCIAL_PUSH = 1,
  INSTAGRAM_CURATION_LOGIN = 2,
}

export enum InstagramPostTab {
  MY_POSTS = 'my_posts',
  TAGGED_ME = 'tagged_me',
  HASHTAGS = 'hashtags',
  MENTIONED_ME = 'mentioned_me',
  ASKED = 'asked',
  APPROVED = 'approved',
}

export enum InstagramMediaType {
  IMAGE = 1,
  VIDEO = 2,
  CAROUSEL_ALBUM = 3,
}

export interface SocialPushLogFilters {
  platform?: number[];
  is_auto?: boolean[];
  status?: number[];
}

export interface AutoPushException {
  separation_type: SeparationType;
  access_target: any[];
}

export interface SocialPushLogStatusLength {
  all: number;
  pending: number;
  sent: number;
  fail: number;
}

export interface SocialPush {
  id: number;
  shop_id: number;
  platform: SocialPushPlatform;
  use_auto_push: boolean;
  maximum_number_reviews?: number;
  period: number;
  target_hour: number;
  is_am: boolean;
  use_min_rating: boolean;
  min_rating: number;
  use_only_media_review?: boolean;
  use_only_order_exist?: boolean;
  template_contents?: string;
  auto_push_exception?: AutoPushException | undefined;
}

export interface SocialPushLog {
  id?: number;
  is_auto: boolean;
  review: Review;
  review_id: number;
  contents: string;
  platform: SocialPushPlatform;
  scheduled_date: Date;
  send_date?: Date;
  status?: SocialPushLogStatus;
  failure_reason?: string;
}

export interface InstagramCurationPage {
  page_list: FacebookPage[];
  shop: {
    facebook_access_token: string;
    facebook_page_access_token: string;
    facebook_page_id: string;
    facebook_page_name: string;
    facebook_user_id: string;
    id: number;
    shop_access_code: string;
    use_for: SocialUseFor;
  };
}

export interface InstagramPost {
  id: number;
  shop_id: number;
  comments_count: number;
  media_id: string;
  caption: string;
  like_count: number;
  media_type: InstagramMediaType | string;
  media_url: string;
  permalink: string;
  timestamp: Date;
  username: string;
  hashtags: string;
  is_mentioned: boolean;
  is_my_post: boolean;
  is_asked: boolean;
  is_approved: boolean;

  children: {
    data: [
      {
        id: string;
        media_type: string;
        media_url: string;
      },
    ];
  };
}

export interface HashtagsPost {
  data: InstagramPost[];
  paging: {
    cursors: {
      before: string;
      after: string;
    };
    next: string;
  };
}
