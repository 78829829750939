<article id="dialog-email-verify">
  <header class="admin-header-2" style="margin-bottom: 30px">Email Address Verification</header>

  <header class="admin-header-3" style="margin-bottom: 10px">Identity Type</header>
  <section style="display: flex; justify-content: space-between">
    <button class="method-select-box" [ngClass]="{ active: identityType == 'domain' }" (click)="identityType = 'domain'">
      <img class="method-select-box-icon" src="assets/icon/domain_gray.svg" />
      <header class="method-select-box-header-container">
        <div class="method-select-box-haeder-content">Domain</div>
        <div class="method-select-box-header-recommend">Recommended</div>
      </header>
      <div class="method-select-box-description">
        Adding a DKIM value to a DNS provider improves the reliability of the email and gives AlphaReview the right to send the email instead as a third-party.
      </div>
      <a class="method-select-box-learn-more">Learn More</a>
    </button>

    <button
      class="method-select-box"
      [ngClass]="{ active: identityType == 'email' }"
      [disabled]="contactInfomationCopy.domain_status == shopService.IdentityStatus.VERIFIED"
      (click)="identityType = 'email'"
    >
      <img class="method-select-box-icon" src="assets/icon/message_gray.svg" />
      <header class="method-select-box-header-container">
        <div class="method-select-box-haeder-content">Email address</div>
      </header>
      <div class="method-select-box-description" style="z-index: 1">
        You can verify that you are the owner of the saved email address, and then send an email through AlphaReview. However, if DKIM is not verified, the email address may not be
        sent normally due to the lack of reliability of the email address.
      </div>
    </button>
  </section>
  <section *ngIf="identityType === 'domain'">
    <section>
      <header class="admin-header-3" style="margin-top: 20px">DKIM Verifications</header>
      <header class="admin-header-3" style="margin-top: 10px">
        <div>Domain</div>
        <input class="admin-input" style="margin-left: 10px; width: 218px" placeholder="Enter a domain or subdomain" [(ngModel)]="contactInfomationCopy.domain" />
        <div class="btn-confirm" style="width: unset" (click)="createShopSESDomian()" *ngIf="!contactInfomationCopy.domain_status">Get CNAME Records</div>
        <div class="btn-confirm" style="width: unset" (click)="createShopSESDomian()" *ngIf="contactInfomationCopy.domain_status">Edit</div>
      </header>
    </section>

    <section class="domain-verify-description-container">
      <div class="domain-verify-description">Note: Verify DKIM to complete setting up your custom email address.</div>
      <div class="domain-verify-description">1. Copy the DKIM value below, and paste it into your hosting site.</div>
      <div class="domain-verify-description">2. Return to verify the DKIM.</div>
    </section>

    <section>
      <table mat-table #dkimTable [dataSource]="dataSource" style="width: 100%">
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef class="text-align-center">Type</th>
          <td mat-cell *matCellDef="let element" class="text-align-center">
            <div>
              {{ element.type }}
            </div>
            <div
              class="identity-verify-status"
              [ngClass]="{
                pending: contactInfomationCopy.domain_status == shopService.IdentityStatus.PENDING,
                verified: contactInfomationCopy.domain_status == shopService.IdentityStatus.VERIFIED
              }"
            >
              {{ shopService.IdentityStatus[contactInfomationCopy.domain_status] | titlecase }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
            <div class="domain-dkim-content-container">
              <div class="domain-dkim-content">
                {{ element.name }}
              </div>
              <img style="margin-left: 12px; cursor: pointer" src="assets/icon/copy_gray.svg" (click)="copy(element.name)" />
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>Add this value</th>
          <td mat-cell *matCellDef="let element">
            <div class="domain-dkim-content-container">
              <div class="domain-dkim-content">
                {{ element.value }}
              </div>
              <img style="margin-left: 12px; cursor: pointer" src="assets/icon/copy_gray.svg" (click)="copy(element.value)" />
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
      </table>

      <div
        *ngIf="contactInfomationCopy.domain_status == shopService.IdentityStatus.UNVERIFIED"
        class="btn-confirm"
        style="margin: 16px auto 0"
        (click)="verifyShopIdentity(contactInfomationCopy.domain)"
      >
        Verify
      </div>
      <div
        *ngIf="contactInfomationCopy.domain_status == shopService.IdentityStatus.PENDING || (!contactInfomationCopy.domain_status && dataSource.length)"
        class="btn-confirm"
        style="margin: 16px auto 0"
        (click)="verifyShopIdentity(contactInfomationCopy.domain)"
        [matTooltipClass]="{ 'admin-tooltip': true }"
        matTooltip="It may take 10-15 minutes to go from Pending status to Verified status. If the status doesn't change, please try again."
      >
        <img src="assets/icon/refresh_white.svg" style="margin-right: 6px" />Refresh
      </div>
      <div *ngIf="contactInfomationCopy.domain_status === shopService.IdentityStatus.VERIFIED" class="btn-confirm" style="margin: 16px auto 0" (click)="onNoClick()">Finish</div>
    </section>
  </section>

  <section *ngIf="identityType === 'email'">
    <section>
      <header class="admin-header-3" style="margin-top: 20px">Confirm custom email address</header>
      <header class="admin-header-3" style="margin-top: 10px">
        <div>Email</div>
        <input
          class="admin-input"
          style="margin-left: 10px; width: 218px"
          placeholder="Enter a domain or subdomain"
          [(ngModel)]="contactInfomationCopy.email_sender_email"
          [disabled]="contactInfomationCopy.email_status == shopService.IdentityStatus.VERIFIED"
        />

        <ng-container *ngIf="contactInfomationCopy.email_status">
          <div
            class="btn-confirm"
            style="width: unset"
            (click)="verifyShopIdentity(contactInfomationCopy.email_sender_email)"
            *ngIf="contactInfomationCopy.email_status !== shopService.IdentityStatus.VERIFIED"
          >
            Verify
          </div>
          <div
            class="btn-outline"
            style="padding-left: 25px; padding-right: 25px; margin-left: 12px"
            (click)="contactInfomationCopy.email_status = 0"
            *ngIf="contactInfomationCopy.email_status == shopService.IdentityStatus.VERIFIED"
          >
            Edit
          </div>
        </ng-container>

        <div class="btn-confirm" style="width: unset" (click)="createShopSESEmail()" *ngIf="!contactInfomationCopy.email_status">Send</div>
      </header>
    </section>

    <section class="email-verify-container" *ngIf="contactInfomationCopy.email_status == shopService.IdentityStatus.PENDING">
      <header class="email-verify-header">
        <img src="assets/icon/checkbox_green.svg" style="margin-right: 6px" />
        A verification link has been sent to your email account
      </header>
      <div class="email-verify-description">
        Please click on the link that has been sent to your email account to verify your email. The link expires 24 hours after your request. If you haven't got the email yet,
        re-send it.
      </div>
    </section>

    <section class="email-warning-container" *ngIf="contactInfomationCopy.email_status == shopService.IdentityStatus.VERIFIED">
      <img src="assets/icon/info_red.svg" style="margin-right: 6px" />
      Your email address is now verified, but we highly recommend you to DKIM verify for higher deliverability.
    </section>
  </section>
</article>
