import { Component, Inject, OnInit } from '@angular/core';
import { ReviewImportLog, TransferStatus } from 'src/app/models/shop/review/options/review-import.model';
import { ReviewService } from 'src/app/service/review.service';
import { ImportService } from '../import.service';
import { Router } from '@angular/router';
import { Product } from 'src/app/models/product/product.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-review-import-done',
  templateUrl: './dialog-review-import-done.component.html',
  styleUrls: ['./dialog-review-import-done.component.less'],
})
export class DialogReviewImportDoneComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DialogReviewImportDoneComponent>,
    public reviewService: ReviewService,
    public reviewImportService: ImportService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { reviewImportLog: ReviewImportLog; product: Product },
  ) {}

  ngOnInit(): void {
    this.checkImportStatus();
  }
  reviewImportProgress = TransferStatus.TASK_CREATED;
  reviewImportLog = this.data.reviewImportLog;
  checkReviewImportProgress: any;
  progressSize = 0;

  onConfirm() {
    this.dialogRef.close(true);
  }

  checkImportStatus() {
    this.checkReviewImportProgress = setInterval(() => {
      if (this.reviewImportLog.progress_status == TransferStatus.FAILED || this.reviewImportLog.progress_status == TransferStatus.SUCCESSED) {
        clearInterval(this.checkReviewImportProgress);
        return;
      }
      this.reviewImportService.pollingReviewImportProgress(this.reviewImportLog).subscribe((response: any) => {
        this.reviewImportLog = response.body as ReviewImportLog;
        this.reviewImportProgress = this.reviewImportLog.progress_status;
        this.progressSize = Math.floor((this.reviewImportLog.page_count / Math.ceil(this.reviewImportLog.total_count / 10)) * 100);
      });
    }, 3000);
  }

  viewAllReview() {
    this.dialogRef.close();
    this.router.navigate(['/admin/product-reviews/reviews'], { state: { log_id: this.reviewImportLog.id } });
  }

  reTryImport() {
    this.dialogRef.close('retry');
  }
}
