import { List, StringIterator } from 'lodash';
import { SuperAdminPostCategory } from './super-admin.shops.enums';

export interface ShopInitialInfoBase {
  used_date?: number;
  process_onboarding_1?: boolean;
  process_onboarding_2?: boolean;
  process_onboarding_3?: boolean;
  process_onboarding_4?: boolean;
  process_setup_1?: boolean;
  process_setup_2?: boolean;
  process_setup_3?: boolean;
  process_setup_4?: boolean;
  process_setup_5?: boolean;
  process_setup_6?: boolean;
}
export interface SuperAdminShopDetail {
  company_name?: Text;
  industry?: Text;
  store_name?: Text;
}
export interface ShopDetail {
  id?: number;
  shop_id?: number;
  company_name?: String;
  industry?: String;
  store_name?: String;
  store_url?: String;
  shop_owner?: String;
  email?: String;
  phone?: String;
  address?: String;
  customer_email?: String;
  access_code?: String;
}

export class SuperAdminShops {
  id?: number;
  shop_name?: string;
  shop_url?: string;
  service_status?: number;
  shop_initial_info?: ShopInitialInfoBase;
  shop_detail?: ShopDetail;
  onboarding?: number;
  setup?: number;
}

export interface SuperAdminShopInfoAdmin {
  id: number;
  account_id: string;
  first_name: string;
  last_name: string;
  accepts_marketing: boolean;
  memo?: string;
}

export interface SuperAdminShopMemoOutput {
  id?: number;
  content?: String;
  author?: String;
  created_at?: Date;
}

export interface SuperAdminShopMemoInput {
  shop_id?: number;
  content?: String;
  author?: String;
}

export interface ShopInitialInfoOutput {
  installed_at?: Date;
  published_at?: Date;
  expired_at?: Date;
}

export interface SuperAdminShopInfo {
  //detail 에
  id?: number;
  shop_name: string;
  company_name: string;
  country_code?: string;
  currency?: string;
  iana_timezone?: string;
  shop_detail?: ShopDetail;
  access_token?: string;
  access_code?: string;
  super_admin_shop_contact?: SuperAdminShopInfoAdmin[];
  shop_initial_info?: ShopInitialInfoOutput;
  super_admin_shop_memos?: SuperAdminShopMemoOutput[];
}
export interface SuperAdminAccount {
  id?: Number;
  name?: String;
  email?: String;
  account_id?: String;
  password?: String;
  nickname?: String;
  permission_group?: Number;
  profile_image?: string;
}
export interface SuperAdminAccountRegist {
  name?: String;
  email?: String;
  account_id?: String;
  password?: String;
  nickname?: String;
}
export interface SuperAdminCurrentUser {
  id?: Number;
  name?: string;
  email?: string;
  account_id?: string;
  nickname?: string;
  profile_image?: string;
  permission_group?: Number;
}

export interface BasicInfoEditShopInput {
  shop_name?: String;
  company_name?: String;
  country_code?: String;
}
export interface BasicInfoEditDetailInput {
  industry?: String;
}

export class SuperAdminProfileImage {
  file?: File | Blob;
  file_name?: string;
}

export const POSTCATEGORY = [
  ['Notice', 'NOTICE'],
  ['Update News', 'UPDATE_NEWS'],
  ['Articles', 'ARTICLES'],
];

export const POSTSTATUS = [
  ['대시보드', 0],
  ['랜딩페이지', 1],
  ['임시저장', 2],
];

export interface PostTopic {
  id?: number;
  value?: string;
  // super_admin_post_id?: number;
}

export interface PublishTargetExcel {
  shop_id: number;
  shop_name: string;
}
export enum PostViewType {
  NEW_TAB = 0,
  MODAL = 1,
}
export const POSTVIEWTYPE: { [key: string]: number } = {
  NEW_TAB: 0,
  MODAL: 1,
};

export const POSTPUBLISHSTATUS = [
  ['대시보드', 'feed_start, popup_start'],
  ['랜딩페이지', 'randing_start'],
  ['임시저장', 'is_temporary'],
];
export interface SuperAdminPostIndustry {
  id?: number;
  value?: string;
}
export interface SuperAdminPostResponse {
  id?: number;
  title?: string;
  content?: string;
  thumbnail?: string;
  category?: string;
  super_admin_post_topic?: PostTopic[];
  linked_post1?: string;
  linked_post2?: string;
  linked_post3?: string;
  summary?: string;
  is_temprary?: string;
  create_at?: Date;
  randing_start?: Date;
  randing_end?: Date;
  feed_start?: Date;
  feed_end?: Date;
  popup_start?: Date;
  popup_end?: Date;
  super_admin_post_industry?: SuperAdminPostIndustry[];
  views?: number;
}
export interface SuperAdminPostList {
  length?: number;
  posts?: SuperAdminPost[];
}

export interface SuperAdminPost {
  id?: number;
  title?: string;
  category?: string | SuperAdminPostCategory;
  super_admin_post_topic?: PostTopic[];
  super_admin_account?: SuperAdminCurrentUser;
  is_temprary?: string;
  create_at?: Date;
  randing_start?: Date;
  feed_start?: Date;
  popup_start?: Date;
  views?: number;
  is_featured?: boolean;
  is_fixed_top?: boolean;
  randing_on?: boolean;
  dashboard_on?: boolean;
  pined?: string[];
}

export const COUNTRYNAME: { [key: string]: string } = {
  AF: 'Afghanistan',
  AX: 'AlandIslands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'AmericanSamoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'AntiguaAndBarbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BQ: 'BonaireSintEustatiusSaba',
  BA: 'BosniaAndHerzegovina',
  BW: 'Botswana',
  BV: 'BouvetIsland',
  BR: 'Brazil',
  IO: 'BritishIndianOceanTerritory',
  BN: 'BruneiDarussalam',
  BG: 'Bulgaria',
  BF: 'BurkinaFaso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'CapeVerde',
  KY: 'CaymanIslands',
  CF: 'CentralAfricanRepublic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'ChristmasIsland',
  CC: 'CocosKeelingIslands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'CongoDemocraticRepublic',
  CK: 'CookIslands',
  CR: 'CostaRica',
  CI: 'CoteDIvoire',
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curacao',
  CY: 'Cyprus',
  CZ: 'CzechRepublic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'DominicanRepublic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'ElSalvador',
  GQ: 'EquatorialGuinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'FalklandIslands',
  FO: 'FaroeIslands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'FrenchGuiana',
  PF: 'FrenchPolynesia',
  TF: 'FrenchSouthernTerritories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'GuineaBissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'HeardIslandMcdonaldIslands',
  VA: 'HolySeeVaticanCityState',
  HN: 'Honduras',
  HK: 'HongKong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'IsleOfMan',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KR: 'Korea',
  KP: 'KoreaDemocraticPeoplesRepublic',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: 'LaoPeoplesDemocraticRepublic',
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'LibyanArabJamahiriya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macedonia',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'MarshallIslands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NC: 'NewCaledonia',
  NZ: 'NewZealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'NorfolkIsland',
  MP: 'NorthernMarianaIslands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'PalestinianTerritory',
  PA: 'Panama',
  PG: 'PapuaNewGuinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'PuertoRico',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RU: 'RussianFederation',
  RW: 'Rwanda',
  BL: 'SaintBarthelemy',
  SH: 'SaintHelena',
  KN: 'SaintKittsAndNevis',
  LC: 'SaintLucia',
  MF: 'SaintMartin',
  PM: 'SaintPierreAndMiquelon',
  VC: 'SaintVincentAndGrenadines',
  WS: 'Samoa',
  SM: 'SanMarino',
  ST: 'SaoTomeAndPrincipe',
  SA: 'SaudiArabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'SierraLeone',
  SG: 'Singapore',
  SX: 'SintMaarten',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'SolomonIslands',
  SO: 'Somalia',
  ZA: 'SouthAfrica',
  GS: 'SouthGeorgiaAndSandwichIsl',
  SS: 'SouthSudan',
  ES: 'Spain',
  LK: 'SriLanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'SvalbardAndJanMayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'SyrianArabRepublic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TL: 'TimorLeste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'TrinidadAndTobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'TurksAndCaicosIslands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'UnitedArabEmirates',
  GB: 'UnitedKingdom',
  US: 'UnitedStates',
  UM: 'UnitedStatesOutlyingIslands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Vietnam',
  VG: 'VirginIslandsBritish',
  VI: 'VirginIslandsUS',
  WF: 'WallisAndFutuna',
  EH: 'WesternSahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};

export interface ShopAdminContact {
  first_name: string;
  last_name: string;
  account_id: string;
  memo: string;
}
