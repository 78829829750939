import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'alpha-number-input',
  templateUrl: './custom-number-input.component.html',
  styleUrl: './custom-number-input.component.less',
})
export class CustomNumberInputComponent implements OnInit {
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() value: number = 0;
  @Output() valueChange = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  decrement() {
    if (this.value > this.min) {
      this.value--;
      this.valueChange.emit(this.value);
    }
  }

  increment() {
    if (this.value < this.max) {
      this.value++;
      this.valueChange.emit(this.value);
    }
  }
}
