import { Component, OnInit, Optional, SkipSelf } from '@angular/core';
import { AdminService } from 'src/app/service/admin.service';
import { ShopService } from 'src/app/service/shop.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.less'],
})
export class AdminHomeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
