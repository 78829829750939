<article id="dialog-select-ld-template">
  <header class="admin-header-1 flex-wrapper" style="font-size: 24px">
    Select a template often used by successful stores. <img (click)="onClose()" src="assets/icon/close_big_gray.svg" class="select-template-close" />
  </header>
  <summary class="admin-content-description" style="padding: 16px 0 32px">
    We’ve made designing your product listings much simpler by providing a template. Of course, you’ll have the freedom to adjust the details as you like in the next step
  </summary>

  <mat-radio-group [(ngModel)]="selectedRecommendation" class="select-template-recommend">
    <div class="toggle-select-box-container">
      <mat-radio-button [ngClass]="{ active: selectedRecommendation === ListDesignerPreset.BEST_SELLERS }" [value]="ListDesignerPreset.BEST_SELLERS" class="toggle-select-box">
        <div class="recommend-content">
          @if(selectedRecommendation === ListDesignerPreset.BEST_SELLERS){<img src="assets/icon/check_deep_green.svg" />}Best Seller<span class="recommend-label popular"
            >Most Popular</span
          >
        </div>
      </mat-radio-button>
      <mat-radio-button [ngClass]="{ active: selectedRecommendation === ListDesignerPreset.NEW_ARRIVALS }" [value]="ListDesignerPreset.NEW_ARRIVALS" class="toggle-select-box">
        <div class="recommend-content">@if(selectedRecommendation === ListDesignerPreset.NEW_ARRIVALS){<img src="assets/icon/check_deep_green.svg" />}New Arrivals</div>
      </mat-radio-button>
      <mat-radio-button
        [ngClass]="{ active: selectedRecommendation === ListDesignerPreset.TOP_REVIEWED_PRODUCTS, disabled: !useProductReviews }"
        [value]="ListDesignerPreset.TOP_REVIEWED_PRODUCTS"
        class="toggle-select-box"
      >
        <div class="recommend-content">
          @if(selectedRecommendation === ListDesignerPreset.TOP_REVIEWED_PRODUCTS){<img src="assets/icon/check_deep_green.svg" />} Top Reviewed<span class="recommend-label review"
            ><img src="assets/icon/logo_small.svg" />review</span
          >
        </div>
      </mat-radio-button>
    </div>
  </mat-radio-group>

  <section class="select-template-preview">
    <div class="select-template-preview-toggle">
      <section class="device-toggle-container">
        <div class="device-toggle" [ngClass]="{ active: mode == 'PC' }" (click)="mode = 'PC'">PC</div>
        <div class="device-toggle" [ngClass]="{ active: mode == 'MO' }" (click)="mode = 'MO'">Mobile</div>
      </section>
      @if(selectedRecommendation === ListDesignerPreset.TOP_REVIEWED_PRODUCTS) {
      <div class="only-review" [@inOutAnimation]>
        Only Available for <span><img src="assets/icon/logo_small.svg" />Product Reviews</span>Users
      </div>
      }
    </div>

    <div class="select-template-preview-image">
      @if (selectedRecommendation === ListDesignerPreset.BEST_SELLERS) { @if(mode ==='PC') {
      <img src="assets/image/list-designer/preview_best_pc.png" [@inOutAnimation] />
      } @else {
      <img src="assets/image/list-designer/preview_best_mo.png" [@inOutAnimation] />
      } } @else if(selectedRecommendation === ListDesignerPreset.NEW_ARRIVALS) { @if(mode ==='PC') {
      <img src="assets/image/list-designer/preview_new_pc.png" [@inOutAnimation] />
      } @else {
      <img src="assets/image/list-designer/preview_new_mo.png" [@inOutAnimation] />
      } } @else if (selectedRecommendation === ListDesignerPreset.TOP_REVIEWED_PRODUCTS) { @if(mode ==='PC') {
      <img src="assets/image/list-designer/preview_top_pc.png" [@inOutAnimation] />
      } @else {
      <img src="assets/image/list-designer/preview_top_mo.png" [@inOutAnimation] />
      } }
    </div>
  </section>

  <section class="flex-wrapper select-template-btns">
    <div (click)="onScratch()" class="btn-white">Start from Scratch</div>
    <div (click)="onRecommendedTemplate()" class="btn-black">Start with this Template</div>
  </section>
</article>
