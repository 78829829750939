<article id="list-widget-custom-settings">
  <section class="admin-content-gnb">
    <div class="flex-wrapper" style="width: 100%">
      <header class="admin-header-1">
        <img [routerLink]="'/admin/list-designer/widget-list'" src="assets/icon/back_btn_gray.svg" />
        <span>{{ widget.name }}</span>
        <img (click)="onTitleEdit()" src="assets/icon/edit.svg" />
      </header>
      <div class="flex-wrapper" style="gap: 10px">
        <div class="list-widget-updated-at"><strong>Updated at</strong> {{ widget.updated_at | date : 'MMM dd yyyy, hh:mm aa' }}</div>
        <a href="https://alph.oopy.io/a260adc5-2eb0-43e6-aab1-d9e541250df1" target="_blank" class="btn-white" style="padding: 15px; height: fit-content; text-decoration: none"
          >Guide</a
        >
        <div (click)="onClickPublish()" class="btn-black" style="padding: 15px; height: fit-content">Publish</div>
      </div>
    </div>
  </section>

  <section class="list-widget-template">
    <section class="list-widget-template-preview">
      <header class="admin-header-3 split">
        <section class="header-info">Preview</section>
        <section class="device-toggle-container">
          <div class="device-toggle" [ngClass]="{ active: mode == 'PC' }" (click)="changedMode('PC')">PC</div>
          <div class="device-toggle" [ngClass]="{ active: mode == 'MO' }" (click)="changedMode('MO')">Mobile</div>
        </section>
        <div (click)="allClear()" class="btn-reset" style="width: 157px"><img src="assets/icon/filter_reset.svg" />Reset all to Default</div>
      </header>

      <section class="flex-wrapper list-designer-preview-container" style="margin: 20px; justify-content: center">
        <section class="list-widget-template-preview-wrapper" [class.mobile]="mode === 'MO'" [ngStyle]="{ width: mode === 'PC' ? '100%' : '360px' }">
          @if (mode === 'PC') { <img class="header-img" src="assets/image/list-designer/pc_header.png" /> } @else {
          <div class="header-img">
            <img src="assets/image/list-designer/mo_header1.png" />
            <img src="assets/image/list-designer/mo_header2.png" />
          </div>
          }
          <article
            id="list-designer-preview"
            [ngClass]="mode"
            style="--web-columns: {{ widget.design_option.web_columns }}; --mo-columns: {{ widget.design_option.mobile_columns }}"
          >
            <header
              class="preview-title bold"
              [ngStyle]="{ 'font-size': mode === 'PC' ? widget.design_option.web_font_size + 'px' : widget.design_option.mobile_font_size + 'px' }"
            >
              {{ widget.design_option.title }}
            </header>
            @if (isLoading) {
            <div class="loader-wrapper" [@inOutAnimation]><span class="loader"></span></div>
            }@else { @if (widgetProducts) { @if (widget.design_option.layout === 'list') {
            <section
              class="preview-products grid"
              [ngStyle]="{
                'grid-template-columns': mode === 'PC' ? 'repeat(var(--web-columns), 1fr)' : 'repeat(var(--mo-columns), 1fr)',
                gap: mode === 'PC' ? widget.design_option.web_adjusting_spacing + 'px' : widget.design_option.mobile_adjust_spacing + 'px'
              }"
            >
              @for (product of widgetProducts; track $index) { @if ($index < (mode ==='PC' ? widget.design_option.web_columns * widget.design_option.web_rows :
              widget.design_option.mobile_columns! * widget.design_option.mobile_rows)) {
              <app-widget-preview-template [widget]="widget" [mode]="mode" [product]="product"></app-widget-preview-template>
              } }
            </section>
            } @else { @if (swiperConfig) {
            <swiper #previewSwiper class="preview-swiper" [config]="swiperConfig">
              @for (product of widgetProducts; track $index) {
              <ng-template swiperSlide><app-widget-preview-template [widget]="widget" [mode]="mode" [product]="product"></app-widget-preview-template></ng-template>
              }
            </swiper>
            } } } @else {
            <section class="preview-empty">
              <div class="empty-content">
                There are no products matching the template. <br />
                Please select the products to display manually.
              </div>
              <div (click)="openDialogAddProducts()" class="btn-black" [ngStyle]="{ width: mode === 'PC' ? '170px' : '100%' }">Add/Edit Products</div>
              <img src="assets/image/list-designer/preview_empty.png" />
            </section>
            } }
          </article>
        </section>
      </section>
    </section>
    <section class="list-widget-template-settings">
      <section class="settings-presets">
        <header class="admin-header-1">Presets</header>
        <summary class="admin-content-description" style="padding: 8px 0">If you're unsure where to start, try using a preset.</summary>
        <div class="flex-column">
          @for (preset of [listDesignerService.ListDesignerPreset.MANUAL, listDesignerService.ListDesignerPreset.BEST_SELLERS, listDesignerService.ListDesignerPreset.NEW_ARRIVALS,
          listDesignerService.ListDesignerPreset.TOP_REVIEWED_PRODUCTS]; track $index) {
          <div
            (click)="preset === listDesignerService.ListDesignerPreset.TOP_REVIEWED_PRODUCTS && !useProductReviews ? return() : changePresetOption(preset)"
            (mouseover)="
              preset === listDesignerService.ListDesignerPreset.TOP_REVIEWED_PRODUCTS && (presetOptions[listDesignerService.ListDesignerPreset.TOP_REVIEWED_PRODUCTS].hover = true)
            "
            (mouseleave)="
              preset === listDesignerService.ListDesignerPreset.TOP_REVIEWED_PRODUCTS && (presetOptions[listDesignerService.ListDesignerPreset.TOP_REVIEWED_PRODUCTS].hover = false)
            "
            class="settings-presets-btn"
            [ngClass]="{ active: widget.template === preset, disabled: preset === listDesignerService.ListDesignerPreset.TOP_REVIEWED_PRODUCTS && !useProductReviews }"
          >
            <img src="{{ presetOptions[preset].btn.icon }}" />
            {{ presetOptions[preset].btn.name }}
            @if (preset === listDesignerService.ListDesignerPreset.TOP_REVIEWED_PRODUCTS) {
            <span class="use-review"><img src="assets/icon/logo_small.svg" />review</span>
            @if (!useProductReviews) {
            <div class="review-cover">
              @if (presetOptions[listDesignerService.ListDesignerPreset.TOP_REVIEWED_PRODUCTS].hover) {
              <span class="review-tooltip" [@inOutAnimation]
                >It is only available when using the <span class="review-link" routerLink="/admin/market-place">Product Reviews.</span></span
              >
              }
            </div>
            } }
          </div>
          }
        </div>
      </section>
      <section class="settings-products">
        <header class="admin-header-1">Products to Display</header>
        <summary class="admin-content-description" style="padding: 8px 0">
          Select the products to display in the widget. You can choose them manually or set them to be automatically selected.
        </summary>
        <div class="flex-column">
          <div
            (click)="setAutomaticProduct()"
            class="settings-products-btn"
            [ngClass]="{ active: widget.update_product_filter !== null && widget.update_product_filter !== listDesignerService.ListDesignerPreset.MANUAL }"
          >
            <div class="flex-wrapper">
              <div class="flex-wrapper" style="gap: 6px">
                <img src="assets/icon/icon_auto.svg" />
                <div class="flex-column" style="gap: 0">
                  <div>Automatic Display</div>
                  <div class="settings-products-auto" [ngSwitch]="widget.update_product_filter">
                    <ng-container *ngSwitchCase="listDesignerService.ListDesignerPreset.NEW_ARRIVALS">Newly released products</ng-container>
                    <ng-container *ngSwitchCase="listDesignerService.ListDesignerPreset.BEST_SELLERS">Top selling products</ng-container>
                    <ng-container *ngSwitchCase="listDesignerService.ListDesignerPreset.TOP_REVIEWED_PRODUCTS">Most reviewed products</ng-container>
                  </div>
                </div>
              </div>
              @if (widget.update_product_filter !== null && widget.update_product_filter !== listDesignerService.ListDesignerPreset.MANUAL ) {
              <div class="settings-products-edit">Edit</div>
              }
            </div>
          </div>
          <div
            (click)="openDialogAddProducts()"
            class="settings-products-btn"
            [ngClass]="{ active: widget.update_product_filter === null || widget.update_product_filter === listDesignerService.ListDesignerPreset.MANUAL }"
          >
            <div class="flex-wrapper">
              <div class="flex-wrapper" style="gap: 6px">
                <img src="assets/icon/icon_manual.svg" />
                Manual Display
              </div>
              @if (widget.update_product_filter === null || widget.update_product_filter === listDesignerService.ListDesignerPreset.MANUAL) {
              <div class="settings-products-edit">Edit</div>
              }
            </div>
          </div>
        </div>
      </section>
      <section class="settings-design">
        <header class="admin-header-1">Widget Design <span class="upcoming">Upcoming</span></header>
        <summary class="admin-content-description" style="padding: 8px 0">Coming soon! Vote on new widgets to be added.</summary>
        <a href="https://forms.gle/Xg6p32K7NRQCWZxb8" target="_blank" class="btn-white">Vote Now</a>
      </section>
      <section class="settings-info">
        <header class="admin-header-1">Elements</header>
        <summary class="admin-content-description">You can choose elements that indicate the popularity of products in the product list.</summary>

        @if (widget.template !== listDesignerService.ListDesignerPreset.MANUAL) {
        <div class="using bold" [ngSwitch]="widget.template" [@inOutAnimation]>
          Using the
          <ng-container *ngSwitchCase="listDesignerService.ListDesignerPreset.BEST_SELLERS">BEST SELLER</ng-container>
          <ng-container *ngSwitchCase="listDesignerService.ListDesignerPreset.NEW_ARRIVALS">New Arrivals</ng-container>
          <ng-container *ngSwitchCase="listDesignerService.ListDesignerPreset.TOP_REVIEWED_PRODUCTS">Top Reviewed Products</ng-container>
          template
        </div>
        }
      </section>

      <section class="settings-title split">
        <header class="admin-header-3">Title</header>
        <input [(ngModel)]="widget.design_option.title" (ngModelChange)="onTitleChange($event)" type="text" maxlength="50" class="admin-input" />
        <div class="length">{{ widget.design_option.title.length }}/50</div>
      </section>

      <mat-accordion>
        <mat-expansion-panel class="settings-expansion">
          <mat-expansion-panel-header class="settings-expansion-header split"> Title Design Settings </mat-expansion-panel-header>
          <section class="settings-font">
            <header class="admin-header-3">Font Size</header>
            <summary class="settings-description no-wrap">Recommend 14pt font for mobile, 16pt for web.</summary>

            <div class="settings-slider">
              <header class="admin-header-4 flex-wrapper">
                Web <span class="slider-value">{{ widget.design_option.web_font_size }}</span>
              </header>
              <mat-slider class="settings-slider" step="1" min="14" max="32">
                <input matSliderThumb #leftPosition [(ngModel)]="widget.design_option.web_font_size" (ngModelChange)="setCustomPreset()" />
              </mat-slider>
            </div>

            <div class="settings-slider">
              <header class="admin-header-4 flex-wrapper">
                Mobile <span class="slider-value">{{ widget.design_option.mobile_font_size }}</span>
              </header>
              <mat-slider class="settings-slider" step="1" min="13" max="20">
                <input matSliderThumb #leftPosition [(ngModel)]="widget.design_option.mobile_font_size" (ngModelChange)="setCustomPreset()" />
              </mat-slider>
            </div>
          </section>
        </mat-expansion-panel>
      </mat-accordion>

      <section class="settings-layout split">
        <header class="admin-header-3">Layout</header>
        <div class="flex-wrapper" style="gap: 8px; padding-top: 8px">
          <div [ngClass]="{ active: widget.design_option.layout === 'list' }" class="layout-radio">
            <input [(ngModel)]="widget.design_option.layout" (ngModelChange)="setCustomPreset()" value="list" type="radio" name="layout" id="list" hidden />
            <label for="list"><img src="assets/icon/layout_list.svg" /> List</label>
          </div>
          <div [ngClass]="{ active: widget.design_option.layout === 'carousel' }" class="layout-radio">
            <input
              [(ngModel)]="widget.design_option.layout"
              (ngModelChange)="setSwiperConfig(); setCustomPreset()"
              value="carousel"
              type="radio"
              name="layout"
              id="carousel"
              hidden
            />
            <label for="carousel"><img src="assets/icon/layout_carousel.svg" /> Carousel</label>
          </div>
        </div>
      </section>

      <mat-accordion>
        <mat-expansion-panel class="settings-expansion">
          <mat-expansion-panel-header class="settings-expansion-header split"> Layout Detail Settings </mat-expansion-panel-header>
          <section class="settings-matrix">
            <header class="admin-header-3">Adjust Rows/Columns</header>
            <summary class="settings-description soft" style="padding-bottom: 0">You can set the number of rows and columns.</summary>

            <section class="matrix-web matrix">
              <header class="admin-header-4">Web</header>
              <div class="flex-wrapper">
                <div class="matrix-header" style="gap: 6px"><img src="assets/icon/icon_column.svg" />Columns</div>
                <alpha-number-input [min]="2" [max]="6" [(value)]="widget.design_option.web_columns" (valueChange)="changedMode('PC')"></alpha-number-input>
              </div>
              <div class="flex-wrapper">
                <div class="matrix-header" style="gap: 6px"><img src="assets/icon/icon_row.svg" />Rows</div>
                <alpha-number-input
                  [class.disabled]="widget.design_option.layout === 'carousel'"
                  [min]="2"
                  [max]="widget.design_option.layout == 'list' ? getCustomMax(widget.product_count, widget.design_option.web_columns) : 2"
                  [(value)]="widget.design_option.web_rows"
                  (valueChange)="changedMode('PC')"
                ></alpha-number-input>
              </div>
            </section>

            <section class="matrix-mo matrix">
              <header class="admin-header-4">Mobile</header>
              @if(widget.design_option.layout === 'carousel') {
              <div class="matrix-note" [@inOutAnimation]>On mobile, it's fixed to display as a single row.</div>
              }
              <div class="flex-wrapper">
                <div class="matrix-header" style="gap: 6px"><img src="assets/icon/icon_column.svg" />Columns</div>
                <alpha-number-input [min]="2" [max]="2" [(value)]="widget.design_option.mobile_columns!" (valueChange)="changedMode('MO')"></alpha-number-input>
              </div>
              <div class="flex-wrapper">
                <div class="matrix-header" style="gap: 6px"><img src="assets/icon/icon_row.svg" />Rows</div>
                <alpha-number-input
                  [class.disabled]="widget.design_option.layout === 'carousel'"
                  [min]="2"
                  [max]="widget.design_option.layout == 'list' ? getCustomMax(widget.product_count, widget.design_option.mobile_columns!) : 2"
                  [(value)]="widget.design_option.mobile_rows"
                  (valueChange)="changedMode('MO')"
                ></alpha-number-input>
              </div>
            </section>
          </section>

          <section class="settings-spacing">
            <header class="admin-header-3">Adjust Spacing</header>
            <summary class="settings-description soft">You can adjust the spacing between products.</summary>

            <div class="settings-slider">
              <header class="admin-header-4 flex-wrapper">
                Web <span class="slider-value">{{ widget.design_option.web_adjusting_spacing }}</span>
              </header>
              <mat-slider class="settings-slider" step="1" min="2" max="20">
                <input matSliderThumb #leftPosition [(ngModel)]="widget.design_option.web_adjusting_spacing" (ngModelChange)="changedMode('PC')" />
              </mat-slider>
            </div>

            <div class="settings-slider">
              <header class="admin-header-4 flex-wrapper">
                Mobile <span class="slider-value">{{ widget.design_option.mobile_adjust_spacing }}</span>
              </header>
              <mat-slider class="settings-slider" step="1" min="2" max="16">
                <input matSliderThumb #leftPosition [(ngModel)]="widget.design_option.mobile_adjust_spacing" (ngModelChange)="changedMode('MO')" />
              </mat-slider>
            </div>
          </section>

          <section class="settings-spacing">
            <header class="admin-header-3">Adjust Border Radius</header>
            <summary class="settings-description soft">You can adjust the border radius of product thumbnails.</summary>

            <div class="settings-slider">
              <header class="admin-header-4 flex-wrapper">
                Border Radius <span class="slider-value">{{ widget.design_option.adjust_border_radius }}</span>
              </header>
              <mat-slider class="settings-slider" step="1" min="0" max="16">
                <input matSliderThumb #leftPosition [(ngModel)]="widget.design_option.adjust_border_radius" (ngModelChange)="setCustomPreset()" />
              </mat-slider>
            </div>
          </section>
        </mat-expansion-panel>
      </mat-accordion>

      <section class="settings-colors">
        <header class="admin-header-3">Thumbnail Badges</header>
        <summary class="settings-description">It is recommended to use up to 3.</summary>

        <section class="settings-accordion">
          <mat-accordion>
            <mat-expansion-panel [(expanded)]="widget.design_option.use_discount_badge_color" class="toggle-expansion">
              <mat-expansion-panel-header class="toggle-expansion-header split">
                <alpha-checkbox [(ngModel)]="widget.design_option.use_discount_badge_color" (ngModelChange)="setCustomPreset()">Discount Value</alpha-checkbox>
              </mat-expansion-panel-header>
              <alpha-color-picker
                [selectedColor]="widget.design_option.discount_badge_color"
                (colorChange)="widget.design_option.discount_badge_color = $event"
              ></alpha-color-picker>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel [(expanded)]="widget.design_option.use_new_badge_color" class="toggle-expansion">
              <mat-expansion-panel-header class="toggle-expansion-header split">
                <alpha-checkbox [(ngModel)]="widget.design_option.use_new_badge_color" (ngModelChange)="setCustomPreset()">New</alpha-checkbox>
              </mat-expansion-panel-header>
              <alpha-color-picker [selectedColor]="widget.design_option.new_badge_color" (colorChange)="widget.design_option.new_badge_color = $event"></alpha-color-picker>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel [(expanded)]="widget.design_option.use_best_badge_color" class="toggle-expansion">
              <mat-expansion-panel-header class="toggle-expansion-header split">
                <alpha-checkbox [(ngModel)]="widget.design_option.use_best_badge_color" (ngModelChange)="setCustomPreset()">BEST</alpha-checkbox>
              </mat-expansion-panel-header>
              <alpha-color-picker [selectedColor]="widget.design_option.best_badge_color" (colorChange)="widget.design_option.best_badge_color = $event"></alpha-color-picker>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel [(expanded)]="widget.design_option.use_top_reviewed_badge_color" class="toggle-expansion">
              <mat-expansion-panel-header class="toggle-expansion-header split">
                <alpha-checkbox [(ngModel)]="widget.design_option.use_top_reviewed_badge_color" (ngModelChange)="setCustomPreset()">TOP Reviewed</alpha-checkbox>
              </mat-expansion-panel-header>
              <alpha-color-picker
                [selectedColor]="widget.design_option.top_reviewed_badge_color"
                (colorChange)="widget.design_option.top_reviewed_badge_color = $event"
              ></alpha-color-picker>
            </mat-expansion-panel>
          </mat-accordion>
        </section>
      </section>

      <section class="settings-sentence">
        <header class="admin-header-3">Sentence-style Social Proof</header>
        <summary class="settings-description">Product information is displayed in a rolling manner.</summary>

        <section class="settings-accordion">
          <mat-accordion>
            <mat-expansion-panel [(expanded)]="widget.standard_option.use_realtime_purchase_standard" class="toggle-expansion">
              <mat-expansion-panel-header class="toggle-expansion-header split">
                <alpha-checkbox [(ngModel)]="widget.standard_option.use_realtime_purchase_standard" (ngModelChange)="setCustomPreset()">Real-time Purchase</alpha-checkbox>
              </mat-expansion-panel-header>
              <div class="settings-select-wrapper">
                Display purchases made within the <br />
                last
                <span class="settings-select" [matMenuTriggerFor]="realPurchase" style="width: 120px"
                  >{{ dayTransform(widget.standard_option.realtime_purchase_standard) }} <img src="assets/icon/chevron_right.svg"
                /></span>
                <mat-menu #realPurchase="matMenu" class="customize-mat-menu">
                  @for (realPurchase of [1,7,30,90]; track $index) {
                  <div mat-menu-item (click)="widget.standard_option.realtime_purchase_standard = realPurchase">{{ dayTransform(realPurchase) }}</div>
                  }
                </mat-menu>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel [(expanded)]="widget.standard_option.use_realtime_review_standard" class="toggle-expansion" [disabled]="!useProductReviews">
              <mat-expansion-panel-header class="toggle-expansion-header split">
                <alpha-checkbox [(ngModel)]="widget.standard_option.use_realtime_review_standard" (ngModelChange)="setCustomPreset()" [disabled]="!useProductReviews"
                  >Real-time Review <span class="use-review"><img src="assets/icon/logo_small.svg" />review</span></alpha-checkbox
                >
              </mat-expansion-panel-header>
              <div class="settings-select-wrapper">
                Display reviews written within <br />
                last
                <span class="settings-select" [matMenuTriggerFor]="realtime" style="width: 120px"
                  >{{ dayTransform(widget.standard_option.realtime_review_standard) }} <img src="assets/icon/chevron_right.svg"
                /></span>
                <mat-menu #realtime="matMenu" class="customize-mat-menu">
                  @for (realtime of [1,7,30,90]; track $index) {
                  <div mat-menu-item (click)="widget.standard_option.realtime_review_standard = realtime">{{ dayTransform(realtime) }}</div>
                  }
                </mat-menu>
                <div class="settings-description" style="padding: 24px 0 0">If review amount is not displayed, it likely means there are no reviews written for the product.</div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel [(expanded)]="widget.standard_option.use_review_summary" class="toggle-expansion" [disabled]="!useProductReviews">
              <mat-expansion-panel-header class="toggle-expansion-header split">
                <alpha-checkbox [(ngModel)]="widget.standard_option.use_review_summary" (ngModelChange)="setCustomPreset()" [disabled]="!useProductReviews"
                  >Review Summary<span class="use-review"><img src="assets/icon/logo_small.svg" />review</span></alpha-checkbox
                >
              </mat-expansion-panel-header>
              <div class="settings-select-wrapper">
                <header class="admin-header-4">Ratings</header>
                <div class="flex-wrapper" style="justify-content: flex-start; gap: 6px">
                  Higher than
                  <span class="settings-select" [matMenuTriggerFor]="rating" style="width: 130px; margin-top: 0">
                    @for(star of genericService.range(widget.standard_option.review_summary_rating_standard); track $index) {
                    <star-filled style="display: inline-flex" [width]="16" [height]="15" [fillColor]="'#FFB800'"></star-filled>
                    } @for(star of genericService.range(5 - widget.standard_option.review_summary_rating_standard); track $index) {
                    <star-filled class="empty" [width]="16" [height]="15"> </star-filled>
                    }
                    <img src="assets/icon/chevron_right.svg"
                  /></span>
                  <mat-menu #rating="matMenu" class="customize-mat-menu">
                    @for (item of [1,2,3,4,5].reverse(); track $index;) {
                    <div mat-menu-item (click)="widget.standard_option.review_summary_rating_standard = 5 - $index">
                      @for (item of genericService.range(5 - $index); track $index) {
                      <star-filled [width]="16" [height]="15" [fillColor]="'#FFB800'"></star-filled>
                      } @for(star of genericService.range($index); track $index) {
                      <star-filled [width]="16" [height]="15"> </star-filled>
                      }
                    </div>
                    }
                  </mat-menu>
                </div>
              </div>

              <div class="settings-select-wrapper" style="margin-top: 24px">
                <header class="admin-header-4">Review amount</header>
                <div class="flex-wrapper" style="justify-content: flex-start; gap: 6px">
                  More than
                  <span class="settings-select" [matMenuTriggerFor]="amount" style="width: 80px; margin-top: 0">
                    {{ widget.standard_option.review_summary_amount_standard }}
                    <img src="assets/icon/chevron_right.svg"
                  /></span>
                  reviews
                  <mat-menu #amount="matMenu" class="customize-mat-menu">
                    @for (amount of [1,5,10,30]; track $index) {
                    <div mat-menu-item (click)="widget.standard_option.review_summary_amount_standard = amount">{{ amount }}</div>
                    }
                  </mat-menu>
                </div>
              </div>
              <div class="settings-description" style="padding: 24px 0 0">If review amount is not displayed, it likely means there are no reviews written for the product.</div>
            </mat-expansion-panel>
          </mat-accordion>
        </section>
      </section>
      <section class="settings-icon">
        <header class="admin-header-3" style="margin-bottom: 16px">Icon-style Social Proof</header>

        <section class="settings-accordion">
          <mat-accordion>
            <mat-expansion-panel [(expanded)]="widget.standard_option.use_visitor_summary_standard" class="toggle-expansion">
              <mat-expansion-panel-header class="toggle-expansion-header split">
                <alpha-checkbox [(ngModel)]="widget.standard_option.use_visitor_summary_standard" (ngModelChange)="setCustomPreset()">Visitor Summary</alpha-checkbox>
              </mat-expansion-panel-header>
              <div class="settings-select-wrapper">
                Display when the total visitors are
                <span class="settings-select" [matMenuTriggerFor]="visit" style="width: 80px"
                  >{{ widget.standard_option.visitor_summary_standard }} <img src="assets/icon/chevron_right.svg"
                /></span>
                or more
                <mat-menu #visit="matMenu" class="customize-mat-menu">
                  @for (visit of [1,10,50,100]; track $index) {
                  <div mat-menu-item (click)="widget.standard_option.visitor_summary_standard = visit">{{ visit }}</div>
                  }
                </mat-menu>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel [(expanded)]="widget.standard_option.use_purchase_summary_standard" class="toggle-expansion">
              <mat-expansion-panel-header class="toggle-expansion-header split">
                <alpha-checkbox [(ngModel)]="widget.standard_option.use_purchase_summary_standard" (ngModelChange)="setCustomPreset()">Purchase Summary</alpha-checkbox>
              </mat-expansion-panel-header>
              <div class="settings-select-wrapper">
                Display when the total sales are
                <span class="settings-select" [matMenuTriggerFor]="purchase" style="width: 80px"
                  >{{ widget.standard_option.purchase_summary_standard }} <img src="assets/icon/chevron_right.svg"
                /></span>
                or more
                <mat-menu #purchase="matMenu" class="customize-mat-menu">
                  @for (purchase of [1,10,50,100]; track $index) {
                  <div mat-menu-item (click)="widget.standard_option.purchase_summary_standard = purchase">{{ purchase }}</div>
                  }
                </mat-menu>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel [(expanded)]="widget.standard_option.use_realtime_stock_standard" class="toggle-expansion">
              <mat-expansion-panel-header class="toggle-expansion-header split">
                <alpha-checkbox [(ngModel)]="widget.standard_option.use_realtime_stock_standard" (ngModelChange)="setCustomPreset()">Real-time Stock</alpha-checkbox>
              </mat-expansion-panel-header>
              <div class="settings-select-wrapper">
                Display when there are only
                <span class="settings-select" [matMenuTriggerFor]="stock" style="width: 80px"
                  >{{ widget.standard_option.realtime_stock_standard }} <img src="assets/icon/chevron_right.svg"
                /></span>
                items left in stock
                <mat-menu #stock="matMenu" class="customize-mat-menu">
                  @for (stock of [1,10,50,100]; track $index) {
                  <div mat-menu-item (click)="widget.standard_option.realtime_stock_standard = stock">{{ stock }}</div>
                  }
                </mat-menu>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </section>
      </section>

      <section class="settings-price flex-wrapper">
        <header class="admin-header-3">Compare at Price</header>
        <alpha-toggle-btn [(sourceBoolean)]="widget.standard_option.use_compare_at_price" positiveText="On" negativeText="Off"></alpha-toggle-btn>
      </section>
    </section>
  </section>

  <!-- <section class="list-widget-footer">
    <div class="flex-wrapper">
      <div (click)="openDialogAddProducts()" class="btn-white" style="height: 36px; width: 170px">+ Add/Edit Products</div>
      <div (click)="updateWidget(true)" class="btn-black" style="width: 127px">Save and Install</div>
    </div>
  </section> -->
</article>
