<article id="market-place" class="setting-article" style="max-width: 834px; padding-bottom: 20px">
  <!-- max-width: BB -> 834px / 1240px -->
  <header class="admin-header-1" style="gap: 12px">
    <!-- <img onclick="history.back()" src="assets/icon/back_btn_gray.svg" style="cursor: pointer" /> -->
    Apps
  </header>

  <section class="grid-wrapper">
    @for (content of appContents; track $index) {
    <section class="gray-border-wrapper">
      @if (content.app === AlphaApps.BROWSE_BOOSTER) {
      <div class="apps-new">New!</div>
      }
      <header class="admin-header-4">
        <div class="apps-header-icon"><img src="{{ content.icon }}" /></div>
        <div class="apps-header">
          <div class="apps-header-name">
            {{ content.name }}
            @if (shopDetail.use_apps.includes(content.app)) {
            <div class="apps-active"><img src="assets/icon/active_circle.svg" />Active</div>
            }
          </div>
          <div class="apps-header-summary">{{ content.summary }}</div>
        </div>
      </header>
      <div class="apps-media"><img src="{{ content.media }}" /></div>
      <div class="apps-content">
        <div class="apps-tag flex-wrapper">
          @for (tag of content.tag; track $index) { <span class="apps-tag-box">{{ tag }}</span> }
        </div>
        <div class="apps-description">{{ content.description }} <a href="{{ content.url }}" target="_blank" class="apps-guide">Learn More</a></div>
        <div (click)="onClickStarted(content.app)" class="apps-btn btn-white">
          @if (!shopDetail.use_apps.includes(content.app)) { Get Started } @else if(shopDetail.use_apps.includes(content.app)) { Open }
        </div>
      </div>
    </section>
    }
  </section>
  <section class="apps-footer gray-border-wrapper flex-wrapper">
    <div class="flex-wrapper" style="justify-content: flex-start; gap: 16px">
      <div class="apps-footer-icon"><img src="assets/icon/icon_star_bubble.svg" /></div>
      Any specific features you'd like? Feel free to suggest.
    </div>
    <a href="https://forms.gle/D5MBSFYojicA72RKA" target="_blank" class="external-link">Suggest a Feature</a>
  </section>
</article>
