import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { inOutAnimation } from 'src/app/admin/animations';
import { CustomConfirmDialogComponent } from 'src/app/dialog/custom-confirm-dialog/custom-confirm-dialog.component';

@Component({
  selector: 'alpha-save-bar',
  templateUrl: './custom-save-bar.component.html',
  styleUrls: ['./custom-save-bar.component.less'],
  animations: [inOutAnimation],
})
export class CustomSaveBarComponent implements OnInit {
  @Input('maxWidth') maxWidth!: string;
  @Input() show!: boolean;
  @Output() save = new EventEmitter();
  @Output() discard = new EventEmitter();

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  onSave = () => this.save.emit('save');

  onCancel() {
    const dialogRef = this.dialog.open(CustomConfirmDialogComponent, { width: '500px', backdropClass: 'learn-more-backdrop', disableClose: true });
    let instance = dialogRef.componentInstance;
    instance.header = 'Discard Changes?';
    instance.content = 'Are you sure you want to discard your changes? Any unsaved progress will be lost.';
    instance.confirm = 'Discard Changes';
    instance.cancel = 'Cancel';

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.discard.emit('discard');
    });
  }
}
