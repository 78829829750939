"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.Modal = exports.isMessageModal = exports.data = exports.update = exports.clickFooterButton = exports.updateModalSize = exports.closeModal = exports.openModal = exports.Size = exports.Action = exports.isIframeModal = exports.ModalMessage = exports.ModalIframe = void 0;
var Modal_1 = require("@shopify/app-bridge-core/actions/Modal");
Object.defineProperty(exports, "ModalIframe", { enumerable: true, get: function () { return Modal_1.ModalIframe; } });
Object.defineProperty(exports, "ModalMessage", { enumerable: true, get: function () { return Modal_1.ModalMessage; } });
Object.defineProperty(exports, "isIframeModal", { enumerable: true, get: function () { return Modal_1.isIframeModal; } });
var Modal_2 = require("@shopify/app-bridge-core/actions/Modal");
Object.defineProperty(exports, "Action", { enumerable: true, get: function () { return Modal_2.Action; } });
Object.defineProperty(exports, "Size", { enumerable: true, get: function () { return Modal_2.Size; } });
Object.defineProperty(exports, "openModal", { enumerable: true, get: function () { return Modal_2.openModal; } });
Object.defineProperty(exports, "closeModal", { enumerable: true, get: function () { return Modal_2.closeModal; } });
Object.defineProperty(exports, "updateModalSize", { enumerable: true, get: function () { return Modal_2.updateModalSize; } });
Object.defineProperty(exports, "clickFooterButton", { enumerable: true, get: function () { return Modal_2.clickFooterButton; } });
Object.defineProperty(exports, "update", { enumerable: true, get: function () { return Modal_2.update; } });
Object.defineProperty(exports, "data", { enumerable: true, get: function () { return Modal_2.data; } });
Object.defineProperty(exports, "isMessageModal", { enumerable: true, get: function () { return Modal_2.isMessageModal; } });
Object.defineProperty(exports, "Modal", { enumerable: true, get: function () { return Modal_2.Modal; } });
var create = function (app, options) {
    if (Modal_1.isIframeModal(options)) {
        return new Modal_1.ModalIframe(app, options);
    }
    return new Modal_1.ModalMessage(app, options);
};
exports.create = create;
