import { ShopBilling } from '../billing/billing.model';
import { ContactInformation } from './profile/contact-information.model';
import { ShopDetail } from './profile/store-detail.model';

export enum ShopAccountPosition {
  OWNER = 0,
  MANAGER = 1,
  STAFF = 2,
}

export enum ShopAccountStatus {
  DISABLE = 0,
  EMAIL_SENT = 1,
  ACTIVE = 2,
}

export class ShopAdminAccount {
  id?: number;
  shop_id?: number;
  account_id: string = '';
  password: string = '';
  _passwordConfirm?: string = '';
  user_name: string = '';
  first_name: string = '';
  last_name: string = '';
  nickname: string = '';
  access_token: string = '';
  created_at?: Date;
  accepts_marketing?: boolean;
  position?: ShopAccountPosition = ShopAccountPosition.STAFF;
  status?: ShopAccountStatus = ShopAccountStatus.DISABLE;
  shop?: Shop;
  shops: Shop[] = [];
  alpha_plus_shops: Shop[] = [];
  browse_booster_shops: Shop[] = [];
}
export class Shop {
  id?: number;
  shop_name?: string;
  access_code?: string;
  platform_id?: number;
  shop_url?: string;
  currency?: string;
  check_demo_review?: boolean;
  shop_admin_account?: ShopAdminAccount;
  shop_admin_accounts?: ShopAdminAccount[];
  shop_contact_information?: ContactInformation;
  shop_detail?: ShopDetail;
  shop_billing?: ShopBilling;
}

export interface ShopTheme {
  themes: [
    {
      id: number;
      name: string;
      created_at: Date;
      updated_at: Date;
      role: string;
      theme_store_id: number;
      previewable: boolean;
      processing: boolean;
      admin_graphql_api_id: string;
    },
  ];
}
