import { Review } from 'src/app/models/review/review.model';
import { Widget } from 'src/app/models/widget/widget.model';
import { SeparationType } from '../../auth/benefit/benefit.model';

export class Label {
  id: number = 0;
  shop_id: number = 0;
  type: LabelType = LabelType.COMMON;
  name: string = '';
  widgets: (Widget | undefined)[] = [];

  use_auto: boolean = false;

  review_count?: number;
  max_number_reviews_auto: number = 15;

  use_condition_rating: boolean = true;

  // 무관인 경우 0으로 저장
  condition_rating: number = 5;

  use_condition_photo_number: boolean = false;
  condition_photo_number: number = 0;

  use_condition_video: boolean = false;
  use_condition_survey: boolean = false;
  use_condition_social_media: boolean = false;
  use_condition_specific_target: boolean = false;
  condition_specific_target: {
    separation_type: SeparationType;
    access_target: any[];
  } = {
    separation_type: SeparationType.PRODUCT,
    access_target: [],
  };

  created_at?: Date;
  updated_at?: Date;
  author: string = '';

  reviews: Review[] = [];

  selected: boolean = false;
  pinnedTop: boolean = false;
  pinnedBottom: boolean = false;

  hover?: boolean;

  fixedTopActive: boolean | undefined = false;
  fixedBottomActive: boolean | undefined = false;
}

export enum LabelType {
  COMMON = 1,
  PRODUCT = 2,
}
