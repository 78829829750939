import { Component } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { BrowseBoosterService } from '../browse-booster.service';
import { GenericService } from 'src/app/service/generic.service';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { DialogProductVariantSelectComponent } from '../../dialog/dialog-product-variant-select/dialog-product-variant-select.component';
import { Product, ProductStatus } from 'src/app/models/product/product.model';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { BrowseBoosterProduct } from 'src/app/models/browse-booster/setup.model';
import { CustomConfirmDialogComponent } from 'src/app/dialog/custom-confirm-dialog/custom-confirm-dialog.component';

@Component({
  selector: 'app-browse-booster-program-setup',
  standalone: true,
  imports: [CommonModule, FormsModule, SharedModule, MatExpansionModule, MatMenuModule, MatRadioModule],
  templateUrl: './browse-booster-program-setup.component.html',
  styleUrl: './browse-booster-program-setup.component.less',
})
export class BrowseBoosterProgramSetupComponent {
  constructor(public browseBoosterService: BrowseBoosterService, public genericService: GenericService, private dialog: MatDialog) {}
  customDiscountValue: number | null = null;

  toNumber(value: string): number {
    return Number(value);
  }

  convertToBrowseBoosterProduct(product: Product) {
    return {
      product_thumbnail: product.product_image,
      product_title: product.title,
      product_status: product.status,
      secondary_product_id: product.id,
    };
  }

  openDialogProductVariantSelect() {
    const dialogRef = this.dialog.open(DialogProductVariantSelectComponent, {
      width: '480px',
      maxHeight: '70vh',
      data: { mode: 'checkBox', action: 'choice', maximum: 15 },
    });

    dialogRef.afterClosed().subscribe((result: Product[]) => {
      if (!result) return;
      let setup = this.browseBoosterService.browseBoosterSetup;

      const convertedProducts: BrowseBoosterProduct[] = result.map((product) => this.convertToBrowseBoosterProduct(product));
      setup.browse_booster_products = Array.from(
        new Map([...setup.browse_booster_products, ...convertedProducts].map((product) => [product.secondary_product_id, product])).values(),
      );
      setup.product_ids = setup.browse_booster_products.map((product) => product.secondary_product_id);
    });
  }

  removeProduct(product: BrowseBoosterProduct) {
    let dialogRef = this.dialog.open(CustomConfirmDialogComponent, { width: '431px' });
    let instance = dialogRef.componentInstance;

    instance.alignCenter = true;
    instance.header = 'Would you like to delete the selected products?';
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      let setup = this.browseBoosterService.browseBoosterSetup;
      setup.browse_booster_products = setup.browse_booster_products.filter((item) => item.secondary_product_id !== product.secondary_product_id);
      setup.product_ids = setup.product_ids!.filter((id) => id !== product.secondary_product_id);
    });
  }

  get ProductStatus(): typeof ProductStatus {
    return ProductStatus;
  }
}
