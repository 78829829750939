import { Component, Inject, OnInit, Optional, SkipSelf, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthSuperAdminService } from 'src/app/auth/auth-super-admin.service';
import { BackendService } from 'src/app/service/backend.service';
import { SuperAdminService } from 'src/app/service/super-admin.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class VerifyComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<VerifyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: boolean,
    @Optional() @SkipSelf() public superAdminService: SuperAdminService,
    public authSuperAdminService: AuthSuperAdminService,
    public backendService: BackendService,
  ) {}

  verifyCode = new UntypedFormControl(null);

  ngOnInit(): void {}

  isSended!: boolean;
  sendVerifyCode() {
    this.isSended = true;
    this.authSuperAdminService.sendSuperAdminEmailCode(this.superAdminService.CurrentUser.email as string).subscribe((response) => {});
  }

  checkVerifyCode() {
    this.authSuperAdminService.verifySuperAdminEmailCode(this.superAdminService.CurrentUser.email as string, this.verifyCode.value as number).subscribe((response) => {
      if (response.status == 200) {
        if (response.body['result'] == true) {
          this.data = true;
          this.dialogRef.close(true);
        }
      }
    });
  }
}
