import { Product } from '../product/product.model';

export class ListDesignerWidget {
  id: number = 0;
  code: string = '';
  template: ListDesignerPreset = ListDesignerPreset.MANUAL;
  name: string = '';
  design_option: ListDesignerDesignOption = new ListDesignerDesignOption();
  standard_option: ListDesignerStandardOption = new ListDesignerStandardOption();
  updated_at: Date = new Date();
  created_at: Date = new Date();

  product_count: number = 0;
  recent_product_filter?: RecentProductFilter;
  list_designer_products?: ListDesignerProduct[];
  is_update?: boolean;
  update_product_filter: ListDesignerPreset | null = ListDesignerPreset.NEW_ARRIVALS;
  product_ids: number[] = [];
  _checked?: boolean;
}

export interface RecentProductFilter {
  creation_date: number | null;
  review_amount: number | null;
  sales_amount: number | null;
}

export enum ListDesignerPreset {
  MANUAL = 0,
  NEW_ARRIVALS = 10,
  BEST_SELLERS = 20,
  TOP_REVIEWED_PRODUCTS = 30,
}
export class ListDesignerDesignOption {
  title: string = 'Featured Products';
  web_font_size: number = 16;
  mobile_font_size: number = 14;
  layout: string = 'list';
  web_columns: number = 4;
  web_rows: number = 2;
  mobile_columns: number = 2;
  mobile_rows: number = 2;
  web_adjusting_spacing: number = 12;
  mobile_adjust_spacing: number = 8;
  adjust_border_radius: number = 8;
  discount_badge_color: string = '#FFB800';
  discount_value: string = '';
  new_badge_color: string = '#3C73FF';
  best_badge_color: string = '#FF6060';
  top_reviewed_badge_color: string = '#FF91E0';
  use_discount_badge_color: boolean = false;
  use_best_badge_color: boolean = false;
  use_new_badge_color: boolean = true;
  use_top_reviewed_badge_color: boolean = false;
}

export class ListDesignerStandardOption {
  realtime_purchase_standard: number = 7;
  realtime_review_standard: number = 7;
  review_summary_rating_standard: number = 5;
  review_summary_amount_standard: number = 1;
  visitor_summary_standard: number = 10;
  purchase_summary_standard: number = 10;
  realtime_stock_standard: number = 10;
  use_compare_at_price: boolean = true;
  use_purchase_summary_standard: boolean = true;
  use_realtime_purchase_standard: boolean = true;
  use_realtime_review_standard: boolean = false;
  use_realtime_stock_standard: boolean = false;
  use_review_summary: boolean = false;
  use_visitor_summary_standard: boolean = true;
}

export interface ListDesignerProduct {
  id?: number | null;
  list_designer_widget_id: number;
  product_id: number;
  product_platform_id: number;
  sequence_in_widget: number;
  thumbnail_img: string;
  realtime_purchase: string;
  realtime_review: string;
  review_summary: string;
  visitor_summary: string;
  purchase_summary: string;
  realtime_stock: number;
  before_price: number;
  after_price: number;
  product: Product;

  _move: boolean;
}
