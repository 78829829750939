import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopAdminAccount } from 'src/app/models/shop/shop.model';
import { AdminService } from 'src/app/service/admin.service';
import { ShopService } from 'src/app/service/shop.service';
import { AccountInviteCompleteComponent } from './account-invite-complete/account-invite-complete.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-account-invite',
  templateUrl: './account-invite.component.html',
  styleUrls: ['./account-invite.component.less', '../../admin.component.less'],
})
export class AccountInviteComponent implements OnInit {
  constructor(public shopService: ShopService, public adminService: AdminService, private dialog: MatDialog, private route: ActivatedRoute, private router: Router) {
    route.params.subscribe((params) => {
      if ('accessToken' in params) {
        this.accessToken = params['accessToken'];
      }
    });
  }

  shopAdminAccount: ShopAdminAccount = new ShopAdminAccount();
  accessToken: string | undefined = undefined;
  shopAccessCode: string | undefined = undefined;
  ngOnInit(): void {
    if (!this.accessToken) {
      this.onInvalidAccess();
    }
    this.getInvitedShopAdminAcoount();
  }

  onInvalidAccess() {
    alert('Invalid access detected.');
    window.close();
  }

  getInvitedShopAdminAcoount() {
    this.shopService.verifyInviteShopAccount(this.accessToken!).subscribe({
      next: (response) => {
        this.shopAdminAccount = response.body as ShopAdminAccount;
        this.adminService.setShopAccessCode(response.body.shop_access_code);
      },
      error: (error) => {
        if (error.status === 409) {
          alert('An account already exists.');
        }
        this.onInvalidAccess();
      },
    });
  }

  onClickSubmit() {
    this.shopService.updateInvitedShopAccount(this.shopAdminAccount).subscribe((response) => {
      this.dialog
        .open(AccountInviteCompleteComponent, { width: '420px', height: '320px', disableClose: true })
        .afterClosed()
        .subscribe((result) => {
          this.router.navigate(['/admin', 'login'], {
            state: {
              email: this.shopAdminAccount.account_id,
            },
          });
        });
    });
  }
}
