import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { GenericService } from 'src/app/service/generic.service';
import {
  inOutAnimation,
  openAnimation,
  slideAnimation,
} from '../../animations';
import { LandingService } from '../../landing.service';

@Component({
  selector: 'landing-gnb',
  templateUrl: './landing-global-navigation-bar.component.html',
  styleUrls: [
    './landing-global-navigation-bar.component.less',
    '../../home.component.less',
  ],
  animations: [
    inOutAnimation,
    trigger('toggle', [
      state(
        'close',
        style({
          height: '40px',
          overflow: 'hidden',
        })
      ),
      state(
        'open',
        style({
          height: '*',
          overflow: 'hidden',
        })
      ),
      transition('open <=> close', [animate('0.3s')]),
    ]),
  ],
})
export class LandingGlobalNavigationBarComponent implements OnInit {
  constructor(
    private genericService: GenericService,
    public landingService: LandingService
  ) {}

  ngOnInit(): void {}

  features: boolean = false;
  resources: boolean = false;
  language: boolean = true; // true = en; false = ko

  async toggleLanguages() {
    this.language = !this.language;
    await this.genericService.delay(500);
    if (!this.language) window.open('https://alph.kr/');

    this.language = true;
  }

  openLink(link: string): void {
    window.open(link, '_blank');
  }
}
