import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { addOnlyAnimation } from 'src/app/admin/animations';
import { AliexpressVerifyUrlList } from 'src/app/models/ai/ai.model';
import { ReviewAppPlatform } from 'src/app/models/shop/review/options/review-import.model';
import { AiService } from 'src/app/service/ai.service';

@Component({
  selector: 'app-dialog-ali-import',
  templateUrl: './dialog-ali-import.component.html',
  styleUrls: ['./dialog-ali-import.component.less'],
  animations: [addOnlyAnimation],
})
export class DialogAliImportComponent implements OnInit {
  constructor(
    private aiService: AiService,
    private dialogRef: MatDialogRef<DialogAliImportComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { limit: number; dummy: boolean },
  ) {}

  ngOnInit(): void {
    if (this.data.dummy) this.addDummyInputs();
    else this.addInput();
  }

  urlList: any[] = [];
  aliexpressVerifyUrlList: AliexpressVerifyUrlList[] = [];
  verifyClear: boolean = true;
  submitLock: boolean = false;
  remainingLimit?: number;
  limitInput?: boolean;

  removeInput(index: number) {
    if (this.urlList.length > 1) {
      this.urlList.splice(index, 1);
      this.urlList = this.urlList.map((input, i) => ({ ...input, index: i + 1 }));
    }
  }

  addInput() {
    this.remainingLimit = Math.min(this.data.limit - this.urlList.length, 5);

    if (this.remainingLimit > 0 && this.urlList.length < 5) {
      const newInput = { url: '', title: '', index: this.urlList.length + 1 };
      this.urlList.push(newInput);
    }
  }

  addDummyInputs() {
    for (const product of this.aiService.dummyProducts) {
      if (this.urlList.length < 5) {
        const newInput = {
          url: product.url,
          title: product.title,
          index: this.urlList.length + 1,
        };
        this.urlList.push(newInput);
      } else {
        break;
      }
    }
  }

  disabledImportBtnClick() {
    const checked = this.urlList.some((input) => input.url.trim() !== '' && input.title.trim() !== '');

    return checked;
  }

  onConfirm() {
    this.submitLock = false;
    this.urlList = this.cleanAndIndexInputs();

    this.aiService.verifyECommerceUrlList(this.urlList, ReviewAppPlatform.ALI_EXPRESS).subscribe((response) => {
      this.aliexpressVerifyUrlList = response.body;
      this.updateUrlListWithVerificationResults();
      this.submitLock = true;

      this.verifyClear = this.urlList.every((item) => item.is_verify === true && item.title.trim() !== '');

      this.verifyClear && this.importECommerceData();
    });
  }

  cleanAndIndexInputs() {
    return this.urlList
      .filter((input) => input.url.trim() !== '' || input.title.trim() !== '')
      .map((input, i) => {
        const isUrlEmpty = input.url.trim() === '';
        const isTitleEmpty = input.title.trim() === '';

        if (isUrlEmpty) input.url_error = true;
        if (isTitleEmpty) input.title_error = true;

        return {
          ...input,
          index: i + 1,
        };
      });
  }

  updateUrlListWithVerificationResults() {
    this.urlList = this.urlList.map((item) => ({
      ...item,
      ...this.aliexpressVerifyUrlList.find((verifyItem) => verifyItem.index === item.index),
    }));
  }

  importECommerceData() {
    const payload = this.urlList.map((item) => ({ title: item.title, url: item.url }));
    this.aiService.importECommerce(payload, ReviewAppPlatform.ALI_EXPRESS).subscribe((response) => {
      response.ok && this.dialogRef.close(true);
    });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
