import { Injectable } from '@angular/core';
import {
  Shop,
  ShopAccountPosition,
  ShopAccountStatus,
  ShopAdminAccount,
} from '../models/shop/shop.model';
import { BackendService } from './backend.service';
import { AdminService } from './admin.service';
import {
  ContactInformation,
  IdentityStatus,
} from '../models/shop/profile/contact-information.model';
import { toString } from 'lodash';
import { adminAccountPasswordRegexp } from '../shared/regexp/shared-regexp-account-password';

@Injectable({
  providedIn: 'root',
})
export class ShopService {
  constructor(
    private backendService: BackendService,
    private adminService: AdminService
  ) { }

  currencyKey: string = '_cu';
  shopNameKey: string = '_sn';

  getShop(shopAccessCode: string) {
    let url = 'shops/';
    return this.backendService.select(url, {
      shop_access_code: shopAccessCode,
    });
  }

  getShopThemes(shopAccessCode: string) {
    let url = 'shops/themes';

    return this.backendService.select(url, {
      shop_access_code: shopAccessCode,
    });
  }

  getShopDetail() {
    let url = 'shops/detail';
    return this.backendService.select(url, {
      shop_access_code: this.adminService.getShopAccessCode(),
    });
  }

  patchShopDetail(payload: Payload) {
    const formData: FormData = new FormData();
    if (payload.file) {
      formData.set('file', payload.file);
    }
    formData.set('shop_detail', JSON.stringify(payload));

    let url = 'shops/detail';
    return this.backendService.patch(url, formData, {
      shop_access_code: this.adminService.getShopAccessCode(),
    });
  }

  getShopReviewComment(params: {}) {
    const url = 'reviews/options/comments/templates/';

    return this.backendService.select(url, params);
  }

  checkExistsAccount() {
    let url = 'shops/accounts/integrate/check';
    return this.backendService.create(
      url,
      {},
      {
        shop_access_code: this.adminService.getShopAccessCode(),
      }
    );
  }

  integrateAccount(account_id: string, password: string) {
    let url = 'shops/accounts/integrate';
    return this.backendService.create(
      url,
      {
        account_id: account_id,
        password: password,
      },
      {
        shop_access_code: this.adminService.getShopAccessCode(),
      }
    );
  }

  sendShopAdminAccountResetPasswordEmail(email: string) {
    let url = 'shops/accounts/reset-password/email';
    return this.backendService.create(
      url,
      {},
      {
        shop_access_code: this.adminService.getShopAccessCode(),
        account_id: email,
      }
    );
  }

  resetShopAdminAccountPassword(password: string, accessToken: string) {
    let url = 'shops/accounts/reset-password';
    return this.backendService.patch(
      url,
      {},
      {
        access_token: accessToken,
        password: password,
      }
    );
  }

  sendShopAdminAccountVerifyEmail(email: string) {
    let url = 'auth/shop/sign-in/email/send';
    return this.backendService.create(
      url,
      {},
      {
        shop_access_code: this.adminService.getShopAccessCode(),
        email: email,
      }
    );
  }

  verifyShopAdminAccount(email: string, verifyCode: number) {
    let url = 'auth/shop/sign-in/email/verify';
    return this.backendService.create(
      url,
      {},
      {
        shop_access_code: this.adminService.getShopAccessCode(),
        email: email,
        verify_code: verifyCode,
      }
    );
  }

  convertShopAccount2FormData(shopAdminAccount: ShopAdminAccount) {
    let formData = new FormData();
    if (shopAdminAccount.id) {
      formData.append('id', shopAdminAccount.id.toString());
    }
    formData.append('account_id', shopAdminAccount.account_id);
    formData.append('first_name', shopAdminAccount.first_name);
    formData.append('last_name', shopAdminAccount.last_name);
    formData.append('password', shopAdminAccount.password);
    formData.append(
      'accepts_marketing',
      toString(shopAdminAccount.accepts_marketing)
    );
    formData.append('position', toString(shopAdminAccount.position));

    return formData;
  }

  createShopAccount(shopAdminAccount: FormData) {
    const url = 'auth/shop/sign-in';
    return this.backendService.create(url, shopAdminAccount, {
      shop_access_code: this.adminService.getShopAccessCode(),
    });
  }

  checkValidAdminAccountPassword(password: string) {
    return adminAccountPasswordRegexp.test(password);
  }

  patchShopAccount(shopAdminAccount: ShopAdminAccount) {
    const url = 'shops/accounts';
    return this.backendService.patch(url, shopAdminAccount, {
      shop_access_code: this.adminService.getShopAccessCode(),
    });
  }

  updateInvitedShopAccount(shopAdminAccount: ShopAdminAccount) {
    const url = 'shops/accounts';
    return this.backendService.update(url, shopAdminAccount, {
      shop_access_code: this.adminService.getShopAccessCode(),
    });
  }

  deleteInvitedShopAccount(shopAdminAccount: ShopAdminAccount) {
    const url = 'shops/accounts/' + shopAdminAccount.id;
    return this.backendService.delete(url, {
      shop_access_code: this.adminService.getShopAccessCode(),
    });
  }

  inviteShopAccount(shopAdminAccount: ShopAdminAccount) {
    const url = 'auth/shop/accounts/invite';
    return this.backendService.create(url, shopAdminAccount, {
      shop_access_code: this.adminService.getShopAccessCode(),
    });
  }

  verifyInviteShopAccount(access_token: string) {
    const url = 'auth/shop/accounts/invite/verify';
    return this.backendService.select(url, { access_token: access_token });
  }

  getShopContactInformation(params: { shop_access_code: string }) {
    let url = 'shops/contact';

    return this.backendService.select(url, params);
  }

  patchShopContactInformation(
    contactInformation: ContactInformation,
    params: { shop_access_code: string }
  ) {
    let url = 'shops/contact';

    return this.backendService.patch(url, contactInformation, params);
  }

  getShopInitialInfo() {
    let url = 'shops/shop/initial-info';
    return this.backendService.select(url, {
      shop_access_code: this.adminService.getShopAccessCode(),
    });
  }

  patchShopInitialInfo(payload: {} = {}) {
    let url = 'shops/shop/initial-info';

    return this.backendService.patch(url, payload, {
      shop_access_code: this.adminService.getShopAccessCode(),
    });
  }

  public get IdentityStatus(): typeof IdentityStatus {
    return IdentityStatus;
  }

  get currency() {
    return sessionStorage.getItem(this.currencyKey)
      ? sessionStorage.getItem(this.currencyKey)!
      : '';
  }

  set currency(currencyCode: string) {
    sessionStorage.setItem(this.currencyKey, currencyCode);
  }

  get shopName() {
    return sessionStorage.getItem(this.shopNameKey)
      ? sessionStorage.getItem(this.shopNameKey)!
      : '';
  }

  set shopName(shopName: string) {
    sessionStorage.setItem(this.shopNameKey, shopName);
  }

  get ShopAccountPosition(): typeof ShopAccountPosition {
    return ShopAccountPosition;
  }

  get ShopAccountStatus(): typeof ShopAccountStatus {
    return ShopAccountStatus;
  }
}

interface Payload {
  [key: string]: any;
}
