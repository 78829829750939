<article id="create-super-admin">
    <div class="input-box">
        <div class="welcome-text">환영해요 ! 슈퍼관리자에서 사용할 계정을 만들어주세요.</div>
        <form [formGroup]="register" (ngSubmit)="onSubmit()">
            <div class="input-section">
                <div class="basic-section">1. 기본 정보</div>
                <div class="basic-space">
                    <div class="lable">이름</div>
                    <input class="input-field" type="text" formControlName="name">
                </div>
                <div class="basic-space">
                    <div class="lable">Email</div>
                    <div class="email"><input class="email-field" type="email" formControlName="email"><button class="email-verify-button" type="button" (click)="sendVerifyCode()">인증번호 발송</button></div>
                    <div class="email"><input class="email-field" [formControl]="verifyCode"><button class="email-check-button" type="button" (click)="checkVerifyCode()">
                        <ng-container *ngIf="!isVerified">확인하기</ng-container>
                        <ng-container *ngIf="isVerified">인증완료</ng-container>
                    </button></div>
                </div>
                <div class="input-guide">본인 인증이 필요한 기능 사용 시 이 주소로 인증번호를 받습니다.</div>
            </div>
            <div class="input-section">
                <div class="basic-section">2. 추가 정보(선택)</div>
                <div class="input-guide">포스트 기능 사용 시 고객에게 노출될 포스트 작성자명 및 프로필 사진입니다.</div>
                <div class="basic-space">
                    <div class="lable" >필명</div>
                    <input class="input-field" type="text" formControlName="nickname">
                </div>
                <div class="basic-space">
                    <div class="lable">프로필 사진</div>
                    <input type="file" accept="image/*" [formControl]="profile_image" (change)="onUpload($event)">
                </div>
            </div>
            <div class="input-section">
                <div class="basic-section">3. 계정 정보</div>
                <div class="basic-space">
                    <div class="lable">ID</div>
                    <input class="input-field" type="text" formControlName="account_id">
                    <div class="input-guide">6~16글자 이내의 영문자와 숫자로 구성돼야 합니다.</div>
                </div>
                <div class="basic-space">
                    <div class="lable">PW</div>
                    <input class="input-field" [type]="passwordHide ? 'password' : 'text'" formControlName="password" >
                    <a class="visible-icon"><mat-icon (click)="passwordHide = !passwordHide">{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon></a>
                    <div class="input-guide">8자 이상의 영문자와 숫자를 함께 사용해야 합니다.</div>
                </div>
            </div>
            <div class="submit-button-space">
                <button type="submit" class="submit-button" [disabled]="!register.valid&&!isVerified">계정 생성하기</button>
            </div>
        </form>
    </div>
</article>