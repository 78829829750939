import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'circle-check-large',
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g >
        <g >
            <rect  width="40" height="40" rx="20" [attr.fill]="fillColor"/>
            <g >
                <path  transform="translate(10 9)" style="fill:none" d="M0 0h21v23H0z"/>
                <path data-name="Icon feather-check" d="M22.4 8 11.573 20.35l-8.042-8.089" transform="translate(6.94 5.37)" style="stroke:#fff;stroke-width:3px;fill:none"/>
            </g>
        </g>
    </g>
</svg>
  `,
  styles: [
  ]
})
export class CircleCheckLargeComponent implements OnInit {
  @Input() fillColor: string = '#333336'
  constructor() { }

  ngOnInit(): void {
  }

}
