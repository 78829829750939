import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthAdminService } from '../auth/auth-admin.service';
import { PipeModule } from '../pipe/pipe.module';
import { AdminService } from '../service/admin.service';
import { ReviewService } from '../service/review.service';
import { SharedModule } from '../shared/shared.module';
import { TemplateModule } from '../template/template.module';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { FloatComponent } from './float/float.component';
import { LoginBridgeComponent } from './login/login-bridge/login-bridge.component';
import { AdminLauncherSupportComponent } from './float/admin-launcher-support/admin-launcher-support.component';
import { LoginComponent } from './login/login.component';
import { LayoutModule } from './layout/layout.module';
import { SettingComponent } from './setting/setting.component';
import { StoreSettingComponent } from './setting/store-setting/store-setting.component';
import { DialogEmailVerifyComponent } from './setting/store-setting/dialog-email-verify/dialog-email-verify.component';
import { AccountSettingComponent } from './setting/account-setting/account-setting.component';
import { SubscriptionSettingComponent } from './setting/subscription-setting/subscription-setting.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MarketPlaceComponent } from './market-place/market-place.component';
import { AlphaAiModule } from './alpha-ai/alpha-ai.module';
import { ProductReviewsModule } from './product-reviews/product-reviews.module';
import { DialogModule } from './dialog/dialog.module';
import { ListDesignerModule } from './list-designer/list-designer.module';

@NgModule({
  declarations: [
    AdminComponent,
    LoginComponent,
    FloatComponent,
    LoginBridgeComponent,
    AdminLauncherSupportComponent,
    SettingComponent,
    StoreSettingComponent,
    SubscriptionSettingComponent,
    DialogEmailVerifyComponent,
    AccountSettingComponent,
    MarketPlaceComponent,
  ],
  exports: [AdminComponent],
  providers: [AdminService, AuthAdminService, ReviewService],
  imports: [
    CommonModule,
    AdminRoutingModule,
    AlphaAiModule,
    ProductReviewsModule,
    ListDesignerModule,
    LayoutModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    MatTabsModule,
    MatMenuModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    LayoutModule,
    SharedModule,
    TemplateModule,
    PipeModule,
    DialogModule,
  ],
})
export class AdminModule {}
