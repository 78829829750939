import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OnboardingService } from '../../onboarding/onboarding.service';
import { AlphaApps } from 'src/app/models/shop/profile/store-detail.model';

@Component({
  selector: 'app-dialog-start-ai',
  standalone: true,
  imports: [],
  templateUrl: './dialog-start-ai.component.html',
  styleUrl: './dialog-start-ai.component.less',
})
export class DialogStartAiComponent {
  constructor(private onboardingService: OnboardingService, private dialogRef: MatDialogRef<DialogStartAiComponent>, @Inject(MAT_DIALOG_DATA) public data: { shopName: string }) {}

  onClose() {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.onboardingService.setShopDetailUseApp(AlphaApps.AI);
    this.dialogRef.close(true);
  }
}
