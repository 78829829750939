import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlphaApps, ShopDetail, ShopProduction } from 'src/app/models/shop/profile/store-detail.model';
import { AdminService } from 'src/app/service/admin.service';
import { LeftNavigationBarService } from '../layout/left-navigation-bar/left-navigation-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogAppInstallWarningComponent } from './dialog-app-install-warning/dialog-app-install-warning.component';
import { BackendService } from 'src/app/service/backend.service';

@Component({
  selector: 'app-market-place',
  templateUrl: './market-place.component.html',
  styleUrl: './market-place.component.less',
})
export class MarketPlaceComponent {
  constructor(
    public adminService: AdminService,
    private backEndService: BackendService,
    private lnbService: LeftNavigationBarService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.shopDetail = this.adminService.ShopAdminAccount.shop?.shop_detail!;
    this.lnbService.selectedApp = undefined;

    if (history.state.onboarding) {
      let trigger = this.shopDetail.use_production.every((production) => production === ShopProduction.NX);
      trigger && this.lnbService.openDialogBrowseBooster();
    }
  }

  shopDetail!: ShopDetail;

  appContents = [
    {
      icon: 'assets/icon/icon_list_designer.svg',
      name: 'Product List Designer',
      summary: 'Product List Design Meets Trust in Minutes: No-Code Tool',
      media: 'assets/image/page/apps-list-designer.png',
      description: "Recommended for solo entrepreneurs looking to enhance their store's visual appeal without design or development expertise.",
      tag: ['Store Design', 'Social Proof', 'FOMO'],
      url: 'https://alph.oopy.io/c53f997d-7ba7-4155-b70b-a4eed435f8ba',
      app: AlphaApps.LIST_DESIGNER,
    },
    {
      icon: 'assets/icon/icon_browse_booster.svg',
      name: 'Browse Booster',
      summary: 'Gamify product browsing to boost customer engagement',
      media: 'assets/image/page/apps-browse-booster.png',
      description: 'Recommended for Shopify stores aiming to encourage customers to explore more products and increase store dwell time.',
      tag: ['Page View', 'Gamification', 'Store Design'],
      // url: 'https://alph.oopy.io/297abbce-de11-493c-abf0-496d4edf47ba',
      url: 'https://apps.shopify.com/alpha-browse-booster',
      app: AlphaApps.BROWSE_BOOSTER,
    },
    {
      icon: 'assets/icon/icon_product_review.svg',
      name: 'Product Reviews',
      summary: 'Collect, import, manage and display product reviews.',
      media: 'assets/image/page/apps-product-reviews.png',
      description: 'Recommended for early-stage entrepreneurs looking to elegantly display reviews while minimizing expenses.',
      tag: ['Social Proof', 'Video Review', 'Store Design'],
      url: 'https://alph.oopy.io/f7e9cb43-fb97-4c47-a899-3844a14dec01',
      app: AlphaApps.REVIEW,
    },
    {
      icon: 'assets/icon/icon_ai.svg',
      name: 'AI Ali Review Analyzer',
      summary: 'Winning Products – Tons of Reviews, Zero Overwhelm',
      media: 'assets/image/page/apps-ai.png',
      description: 'Recommended for dropshippers looking for an easy way to find winning products.',
      tag: ['Dropship', 'AliExpress', 'AI-Powered'],
      url: 'https://alph.oopy.io/cf84de09-850f-4007-bdc5-c0db5dbd3a04',
      app: AlphaApps.AI,
    },
  ];

  appLaunchHandlers = {
    [AlphaApps.REVIEW]: () => {
      this.lnbService.selectedApp = AlphaApps.REVIEW;

      if (this.checkUseProduction(ShopProduction.OG)) {
        this.router.navigate(
          this.shopDetail.use_apps.includes(AlphaApps.REVIEW) ? [`admin/product-reviews/home`] : [`admin/onboarding/${this.adminService.getShopAccessCode()}/app-install`],
        );
      } else {
        this.openAppInstallWarning();
      }
    },
    [AlphaApps.AI]: () => {
      if (this.checkUseProduction(ShopProduction.OG)) {
        this.router.navigate(this.shopDetail.use_apps.includes(AlphaApps.AI) ? [`admin/ai-analysis`] : []);
        !this.shopDetail.use_apps.includes(AlphaApps.AI) && this.lnbService.openDialogUseAI();
      } else {
        this.openAppInstallWarning();
      }
    },
    [AlphaApps.LIST_DESIGNER]: () => {
      if (this.checkUseProduction(ShopProduction.OG)) {
        this.router.navigate(this.shopDetail.use_apps.includes(AlphaApps.LIST_DESIGNER) ? [`admin/list-designer`] : []);
        !this.shopDetail.use_apps.includes(AlphaApps.LIST_DESIGNER) && this.lnbService.openDialogUseListDesigner();
      } else {
        this.openAppInstallWarning();
      }
    },
    [AlphaApps.BROWSE_BOOSTER]: () => {
      if (this.checkUseProduction(ShopProduction.NX)) {
        this.router.navigate(this.shopDetail.use_apps.includes(AlphaApps.BROWSE_BOOSTER) ? [`admin/browse-booster`] : []);
        !this.shopDetail.use_apps.includes(AlphaApps.BROWSE_BOOSTER) && this.lnbService.openDialogBrowseBooster();
      } else {
        this.openAppInstallWarning();
      }
    },
  };

  checkUseProduction(production: ShopProduction) {
    return this.adminService.ShopAdminAccount.shop?.shop_detail?.use_production.includes(production);
  }

  onClickStarted(app: AlphaApps) {
    const startStrategy = this.appLaunchHandlers[app];
    startStrategy && startStrategy();
  }

  openAppInstallWarning() {
    let url = this.checkUseProduction(ShopProduction.NX) ? `shops/app-store` : `browse-booster-shops/app-store`;
    this.backEndService.select(url, { shop_access_code: this.adminService.getShopAccessCode() }).subscribe((response) => {
      let redirectUrl = response.body;
      window.open(redirectUrl, '_blank');
      this.dialog.open(DialogAppInstallWarningComponent, { width: '520px' });
    });
  }

  get AlphaApps(): typeof AlphaApps {
    return AlphaApps;
  }
}
