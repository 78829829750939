<main style="display: flex">
  <div id="display-status-live" *ngIf="status">
    <div class="display-status-live-container">
      <!-- <div class="circle-container">
        <div class="circle-inner">
        </div>
        <div class="circle-outer">
        </div>
      </div> -->
      <div class="live">LIVE</div>
    </div>
  </div>

  <div id="display-status-off" *ngIf="!status">· OFF</div>
</main>
