import { Injectable, Optional } from '@angular/core';
import { ReviewImportLog } from 'src/app/models/shop/review/options/review-import.model';
import { BackendService } from 'src/app/service/backend.service';
import { ImportService } from '../product-reviews/import/import.service';

@Injectable({
  providedIn: 'root',
})
export class FloatService {
  public reviewImportLogList: ReviewImportLog[] = [];
  constructor(@Optional() public reviewImportService: ImportService) {
    // this.getReviewImportHistory()
  }

  getReviewImportHistory() {
    this.reviewImportService.getReviewImportLog().subscribe((response) => {
      const body = response.body as ReviewImportLog[];
      this.reviewImportLogList = body.filter((item) => item.progress_status >= this.reviewImportService.TransferStatus.IN_PROGRESS);

      for (let reviewImportLog of this.reviewImportLogList) {
        // this.checkReviewImportProgress = setInterval(() => {
        //   if (this.reviewImportLog.progress_status !== TransferStatus.IN_PROGRESS) {
        //     clearInterval(this.checkReviewImportProgress)
        //     return
        //   }
        //   this.reviewImportService.pollingReviewImportProgress(this.reviewImportLog).subscribe((response: any) => {
        //     this.reviewImportLog = response.body as ReviewImportLog
        //     this.progressSize = Math.floor(this.reviewImportLog.task_count / this.reviewCountFromCSV * 100)
        //   })
        // }, 3000)
      }
    });
  }
}
