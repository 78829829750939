"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.replace = exports.push = exports.Action = exports.History = void 0;
var History_1 = require("@shopify/app-bridge-core/actions/Navigation/History");
Object.defineProperty(exports, "History", { enumerable: true, get: function () { return History_1.History; } });
var History_2 = require("@shopify/app-bridge-core/actions/Navigation/History");
Object.defineProperty(exports, "Action", { enumerable: true, get: function () { return History_2.Action; } });
Object.defineProperty(exports, "push", { enumerable: true, get: function () { return History_2.push; } });
Object.defineProperty(exports, "replace", { enumerable: true, get: function () { return History_2.replace; } });
function create(app) {
    return new History_1.History(app);
}
exports.create = create;
