<mat-toolbar class="super-admin-header-toolbar" style="justify-content: space-between">
  <div class="super-admin-header-text">포스트 목록</div>
  <div class="new-post">
    <button class="button-new-post" (click)="createPost()"><img src="assets/image/super-admin/super-admin-edit-icon.svg" />새 글 작성</button>
  </div>
</mat-toolbar>
<article id="posts">
  <div class="filter-bar">
    <div class="filter-box">
      <mat-select style="align-self: center; width: 110px" [formControl]="categorySelect" placeholder="카테고리" (selectionChange)="firstPage(); getPosts()">
        <mat-option [value]="[]">ALL</mat-option>
        <mat-option *ngFor="let category of categories" [value]="[category[1]]">
          {{ category[0] }}
        </mat-option>
      </mat-select>
    </div>
    <div class="filter-box">
      <mat-select style="align-self: center; width: 220px" [formControl]="topicSelect" placeholder="세부 토픽" (selectionChange)="firstPage(); getPosts()">
        <mat-option [value]="[]">ALL</mat-option>
        <mat-option *ngFor="let topic of topics" [value]="[topic]">
          {{ topic }}
        </mat-option>
      </mat-select>
    </div>
    <div class="filter-box">
      <mat-select style="align-self: center; width: 220px" [formControl]="authorSelect" placeholder="작성자" (selectionChange)="firstPage(); getPosts()">
        <mat-option [value]="[]">ALL</mat-option>
        <mat-option *ngFor="let author of authors" [value]="[author[1]]">
          {{ author[0] }}
        </mat-option>
      </mat-select>
    </div>
    <div class="filter-box">
      <mat-select style="align-self: center; width: 110px" [formControl]="publishStatusSelect" placeholder="게시 상태" (selectionChange)="firstPage(); getPosts()">
        <mat-option [value]="[]">ALL</mat-option>
        <mat-option *ngFor="let status of publishStatus" [value]="[status[1]]">
          {{ status[0] }}
        </mat-option>
      </mat-select>
    </div>
    <div class="filter-box" style="align-self: center; width: 500px; position: relative">
      <img class="search-icon" style="align-self: center" src="assets/image/super-admin/super-admin-search-icon.svg" />
      <input
        style="top: -1px"
        class="post-search-input"
        placeholder="Search"
        (blur)="firstPage(); getPosts()"
        (keydown.enter)="firstPage(); getPosts()"
        [(ngModel)]="searchKeyword"
      />
      <button class="post-search-button">검색</button>
    </div>
  </div>

  <div class="posts-list-table">
    <table mat-table [dataSource]="posts" class="post-list">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="width: 3%" mat-button [matMenuTriggerFor]="menu">
          <mat-icon style="color: gainsboro">more_vert</mat-icon>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="deletePost(element.id)">삭제</button>

            <!-- 랜딩 feature of not -->
            <ng-container *ngIf="element.pined.includes('랜딩'); else randing">
              <button mat-menu-item (click)="getPinUpdate(element.id, !element.is_featured, element.is_fixed_top)">Feature 해제</button>
            </ng-container>
            <ng-template #randing>
              <button *ngIf="featuredCnt < 4" mat-menu-item (click)="getPinUpdate(element.id, !element.is_featured, element.is_fixed_top)">Feature</button>
            </ng-template>

            <!-- 홈상단 고정 or not -->
            <ng-container *ngIf="element.pined.includes('홈'); else fixTop">
              <button mat-menu-item (click)="getPinUpdate(element.id, element.is_featured, !element.is_fixed_top)">홈 상단 해제</button>
            </ng-container>
            <ng-template #fixTop>
              <button *ngIf="fixedTopCnt < 2" mat-menu-item (click)="getPinUpdate(element.id, element.is_featured, !element.is_fixed_top)">홈 상단 고정</button>
            </ng-template>
          </mat-menu>
        </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef style="text-align: center; align-items: center">카테고리</th>
        <td mat-cell *matCellDef="let element" style="width: 7%">
          <div [ngSwitch]="element.category">
            <div *ngSwitchCase="superAdminService.SuperAdminPostCategory.NOTICE">
              <div class="post-category" style="background-color: #ffe8e5; color: #ff6d6d">Notice</div>
            </div>
            <div *ngSwitchCase="superAdminService.SuperAdminPostCategory.UPDATE_NEWS">
              <div class="post-category" style="background-color: #eeeefe; color: #837aff">Update News</div>
            </div>
            <div *ngSwitchCase="superAdminService.SuperAdminPostCategory.ARTICLES">
              <div class="post-category" style="background-color: #e8e5ff; color: #734aff">Articles</div>
            </div>
          </div>
          <div class="post-pined-status" *ngIf="element.pined.length">
            <mat-icon style="color: gainsboro; transform: scale(0.5); vertical-align: text-top">push_pin</mat-icon>
            {{ element.pined }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="super_admin_post_topic">
        <th mat-header-cell *matHeaderCellDef>토픽</th>
        <td mat-cell *matCellDef="let element" style="width: 10%">
          <ng-container *ngFor="let topic of element.super_admin_post_topic"> #{{ topic['value'] }} </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>제목</th>
        <td mat-cell *matCellDef="let element" style="width: 35%" (click)="editPost(element.id)">
          <span style="display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden">
            <span *ngIf="element.is_temporary">(임시저장 포스트)</span>{{ element.title }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="author">
        <th mat-header-cell *matHeaderCellDef>작성자</th>
        <td mat-cell *matCellDef="let element" style="width: 10%">{{ element.super_admin_account.nickname }}</td>
      </ng-container>

      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef>등록일시</th>
        <td mat-cell *matCellDef="let element" style="width: 10%">
          {{
            element.created_at
              | date
                : 'YY-MM-dd HH시
                    mm분'
                : '+0900'
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="feed_start">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">대시보드</th>
        <td mat-cell *matCellDef="let element" style="width: 5%; text-align: center">
          <ng-container *ngIf="element.dashboard_on; else noDash">
            <div class="service-status-now" style="background-color: #bcd7ff">
              <img class="status-dot" src="assets/image/super-admin/super-admin-list-status-blue-dot.svg" />
              on
            </div>
          </ng-container>
          <ng-template #noDash>
            <div class="service-status-now" style="background-color: #e6e9ec">
              <img class="status-dot" src="assets/image/super-admin/super-admin-list-status-grey-dot.svg" />
              off
            </div>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="randing_start">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">랜딩페이지</th>
        <td mat-cell *matCellDef="let element" style="width: 5%; text-align: center">
          <ng-container *ngIf="element.randing_on; else noRanding">
            <div class="service-status-now" style="background-color: #bcd7ff">
              <img class="status-dot" src="assets/image/super-admin/super-admin-list-status-blue-dot.svg" />
              on
            </div>
          </ng-container>
          <ng-template #noRanding>
            <div class="service-status-now" style="background-color: #e6e9ec">
              <img class="status-dot" src="assets/image/super-admin/super-admin-list-status-grey-dot.svg" />
              off
            </div>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="views">
        <th mat-header-cell *matHeaderCellDef>조회수</th>
        <td mat-cell *matCellDef="let element" style="width: 10%">{{ element.views }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons aria-label="Select page of periodic elements" (page)="getPosts()"> </mat-paginator>
  </div>
</article>
