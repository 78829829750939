import { Component, OnInit } from '@angular/core';
import { LandingService } from '../landing.service';

@Component({
  selector: 'app-landing-marketing',
  templateUrl: './landing-marketing.component.html',
  styleUrls: ['./landing-marketing.component.less', '../home.component.less'],
})
export class LandingMarketingComponent implements OnInit {
  constructor(public landingService: LandingService) {}

  ngOnInit(): void {
    this.landingService.nextPrev();
  }

  idx: string = 'marketing';
}
