<article id="notification-setting" *ngIf="settingMessagingService.benefitInfo">
  <section style="margin-top: 20px">
    <header class="admin-header-4">Blocklist for Review Reward Email</header>
    <summary class="admin-content-description" style="padding: 0 0 6px">Don't send Reward Reminder emails to the email addresses in this blocklist:</summary>
    <section style="display: flex; flex-direction: column">
      <textarea
        [(ngModel)]="settingNotificationService.emailForBlockList.emailForReviewReward"
        class="block-list-input"
        placeholder="List of emails separated by comma (,)."
      ></textarea>
      <div class="block-list-btn">
        <!-- <div *ngIf="settingMessagingService.verificationEmail.messaging_email_blacklist.length == 200" class="max-email">email max</div> -->
        <div
          (click)="
            settingNotificationService.createEmailBlacklist(
              settingMessagingService.benefitInfo,
              settingNotificationService.emailForBlockList.emailForReviewReward,
              settingMessagingService.messagingSettingTypeDetail.BENEFIT_INFO_EMAIL
            )
          "
          class="btn-black"
          style="width: 53px"
        >
          Save
        </div>
      </div>
    </section>

    <section class="flex-wrapper" style="margin-top: 20px">
      <header class="admin-header-4">Set Template</header>
      <div (click)="settingMessagingService.redirectEmailTemplate(settingMessagingService.benefitInfo)" class="btn-black" style="padding: 0 10px">Add Customer Template</div>
    </section>

    <section class="content-wrapper" style="padding-top: 12px">
      <header class="content-table-header">
        <div class="content-table-title">Subject</div>
        <div class="content-table-created-at">Created at.</div>
        <div class="content-table-updated-at">Updated at.</div>
        <div class="content-table-action">Edit/Delete</div>
      </header>
      <article class="content-table-row" *ngFor="let template of settingMessagingService.benefitInfo.messaging_email_templates">
        <div class="content-table-title" style="display: flex; align-items: center">
          <div class="ellipsis-1" style="font-weight: bold">{{ template.template_title }}</div>
          <app-display-status-live [status]="template.active! && settingMessagingService.benefitInfo.use_email" style="margin-left: 8px; flex-shrink: 0"> </app-display-status-live>
        </div>
        <div class="content-table-created-at">
          {{ template.created_at | date : 'mediumDate' }}
        </div>
        <div class="content-table-updated-at">
          {{ template.updated_at | date : 'mediumDate' }}
        </div>
        <div class="content-table-action">
          <div (click)="settingMessagingService.redirectEmailTemplate(settingMessagingService.benefitInfo, template)" class="btn-white" style="width: 47px">Edit</div>
          <div (click)="this.settingMessagingService.deleteMessagingEmailTemplate(settingMessagingService.benefitInfo, template)" class="btn-white" style="width: 64px">Delete</div>
        </div>
      </article>
    </section>
  </section>
</article>
