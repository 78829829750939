import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShopAdminAccount } from 'src/app/models/shop/shop.model';

@Component({
  selector: 'app-dialog-admin-account-delete',
  templateUrl: './dialog-admin-account-delete.component.html',
  styleUrls: ['./dialog-admin-account-delete.component.less', '../../admin.component.less'],
})
export class DialogAdminAccountDeleteComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<DialogAdminAccountDeleteComponent>, @Inject(MAT_DIALOG_DATA) public data: ShopAdminAccount) {}

  activeBtn: boolean = false;

  ngOnInit(): void {}

  onNoClick() {
    this.dialogRef.close(false);
  }
  onConfirm() {
    this.dialogRef.close(true);
  }
}
