<mat-toolbar>
    Industry 선택
</mat-toolbar>
<mat-divider></mat-divider>
<section class="industry-choice">
    <!-- <span class="industry-list-section">
      <mat-checkbox class="check-box"
                    [checked]="allComplete"
                    [color]="task.color"
                    [indeterminate]="someComplete()"
                    (change)="setAll($event.checked)">
        {{task.name}}
      </mat-checkbox>
    </span> -->
    <div class="industry-list-section">
        <div class="industry-item" *ngFor="let industry of industries">
            <mat-checkbox [checked]="isSelected(industry)" (change)="appendData(industry)">
                {{industry}}
            </mat-checkbox>
        </div>
    </div>
</section>
<div class="button-section">
    <button class="cancel" (click)="onCancel()">취소</button>
    <button class="save" (click)="onSave()">선택</button>
</div>