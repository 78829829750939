import { ReviewType } from '../review/review.model';
import { ReviewAppPlatform } from '../shop/review/options/review-import.model';

export enum ProductStatus {
  ACTIVE = 1,
  ARCHIVED = 2,
  DRAFT = 3,
  DELETED = 4,
}

export class Product {
  id: number = 0;
  platform_id: number = 0;
  created_at!: Date;

  handle!: string;

  shop_id!: string;
  product_type!: string;
  published_at!: Date;
  published_scope!: string;
  status!: ProductStatus;
  title!: string;
  updated_at!: Date;
  product_image!: string;

  rating!: number;
  review_count!: number;

  product_option?: ProductOption;
  product_options?: ProductOption[];

  is_published?: boolean;
  product_variant?: ProductVariant;
  product_variants?: ProductVariant[];
  product_judge?: ProductJudge;
  product_review_import_limit?: ProductReviewImportLimit[];

  checked?: boolean;
  _hover?: boolean;
  _moved?: boolean;
  _disabled?: boolean;
  review_stats?: ProductReviewStats[];
}

export interface ProductOption {
  id: number;
  option_id: number;
  name: string;
  values: string;
  position: number;
  product_id: number;
}

export interface ProductVariant {
  id: number;
  title: string;
  option1: string;
  option2: string;
  option3: string;
  position: number;
  price: number;
  compare_at_price: number;

  platform_id: number;

  [key: string]: any;
}

export interface ProductJudge {
  grouped_review_number: number; //그룹 상품의 리뷰 개수
  grouped_review_photo_number: number; // 그룹 상품 리뷰들의 사진이 포함된 리뷰 개수
  grouped_rating: number; // 그룹 상품 리뷰들의 평균 평점
  grouped_rating_percent: number[]; // 그룹 상품의 각 평점 퍼센티지

  product_id: number;
  product?: Product;
}
export interface ProductReviewDetail {
  review_detail_type: ReviewDetailsType;
  review_count: number;
  rating: number;
}
export interface ProductReviewStats {
  review_type: ReviewType;
  review_count: number;
  rating: number;
  detail: ProductReviewDetail[];
  toggle: boolean;
}

export enum ReviewDetailsType {
  QUICK = 1,
  TEXT = 2,
  PHOTO = 3,
  VIDEO = 4,
  SURVEY = 5,
}

export interface ProductReviewImportLimit {
  id: number;
  product_id: number;
  product?: Product;
  import_platform: ReviewAppPlatform;
  imported_review_count: number;
}

export interface ProductInReviewImport extends Product {
  imported_review_count?: number;
  last_imported?: Date;
  is_aliexpress_importing?: boolean;

  _deleteLock?: boolean;
}
