<article id="dialog-start-ai">
  <section class="start-ai-logo-wrapper">
    <div class="start-ai-logo">Alpha.AI</div>
    <img (click)="onClose()" src="assets/icon/close_big_gray.svg" />
  </section>

  <section class="start-ai-header">
    <header class="admin-header-1">Which Reviews Would You Like to Analyze?</header>
    <summary class="admin-content-description">Choose the type of reviews you want to analyze right now. Remember, you can explore other features later as well.</summary>
  </section>

  <section (click)="onConfirm()" id="type-ali" class="gray-border-wrapper flex-wrapper" style="margin-top: 0">
    <div class="description-wrap">
      <header class="admin-header-3"><img src="assets/icon/ali_icon.png" />Analyze AliExpress Reviews</header>
      <summary>
        Verify the quality of products before dropshipping by <br />
        analyzing customer reviews.
      </summary>
    </div>
    <img src="assets/icon/arrow_plat_right_deep_gray.svg" class="arrow-icon" />
  </section>

  <section id="type-csv" class="gray-border-wrapper flex-wrapper" style="margin-top: 15px; position: relative; pointer-events: none">
    <div class="disabled-cover"></div>
    <div class="description-wrap">
      <header class="admin-header-3">
        <img src="assets/icon/store_icon.svg" />Analyze {{ data.shopName }} Shopify Reviews
        <div class="coming">Upcoming</div>
      </header>
      <summary>Discover the value and areas for improvement of your products through the voice of your customers.</summary>
    </div>
    <!-- <img src="assets/icon/arrow_plat_right.svg" /> -->
  </section>
</article>
