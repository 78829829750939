import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.less'],
})
export class ReviewsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
