<main id="admin-gnb">
  <div class="gnb-wrapper">
    <div style="display: flex; align-items: center">
      <div style="display: flex; align-items: center; cursor: pointer; height: 36px" routerLink="admin/market-place">
        <img src="assets/icon/logo_alpha_plus.svg" alt="logo" />
      </div>
      <ng-container *ngIf="!isOnboarding">
        <div style="margin-left: 6px">for</div>
        <section class="select-wrapper">
          <mat-select
            class="admin-select"
            style="width: 240px"
            [(ngModel)]="selectedShop"
            (ngModelChange)="onChangeSelectedShop($event)"
            panelClass="gnb-mat-panel"
            [disabled]="adminService.isDemo"
          >
            <mat-option *ngFor="let shop of shops" [value]="shop">
              {{ shop.shop_detail?.store_name }}
            </mat-option>
          </mat-select>
        </section>
      </ng-container>
    </div>

    <div class="gnb-icon-container" *ngIf="!isOnboarding">
      <!-- <input class="admin-input" placeholder="Search..." style="margin-right: 10px; width: 160px; text-align: right;"> -->
      <!-- <div class="gnb-icon">
        <img src="assets/icon/star_small_gray.svg">
      </div> -->

      <!-- <div
        class="gnb-btn"
        style="margin-right: 6px"
        [routerLink]="'/admin/login'"
        *ngIf="!isOnboarding"
      >
        Logout
      </div> -->
      <section *ngIf="billingService.shopBilling" [routerLink]="['/admin/settings']">
        <div
          class="gnb-icon gnb-icon-upgrade-wrapper"
          style="font-weight: 600"
          [routerLink]="['/admin/settings']"
          [state]="{ tabType: 'subscription' }"
          *ngIf="billingService.shopBilling?.plan == billingService.ShopBillingPlan.FREE; else trialDaysLeft"
        >
          Upgrade
        </div>
        <ng-template #trialDaysLeft>
          <div class="gnb-icon gnb-icon-trial-days-left" *ngIf="genericService.calculateDaysLeft(billingService.shopBilling?.trial_expired_at!) > 0">
            Trial
            {{ genericService.calculateDaysLeft(billingService.shopBilling.trial_expired_at!) }}
            Days Left
          </div>
        </ng-template>
      </section>

      <div class="gnb-icon gnb-icon-help-wrapper" style="position: relative" (click)="showHelp = !showHelp; showUser = false">
        Help

        <div [@inOutAnimation] class="gnb-icon-menu help" *ngIf="showHelp" (mouseleave)="showHelp = false">
          <span (click)="goToExternalLink('https://saladlab.notion.site/ALPHAREVIEW-USER-GUIDE-c69aab3aca174f31a7fc3eeadaeaa8e9')">User Guide</span>
          <span onclick="window.open('https://a-review.channel.io/lounge')">Chat with us</span>
          <span onclick="location.href='mailto:support@alph.kr';"> Email us </span>
        </div>
      </div>
      <!-- <div class="gnb-icon" (click)="
          goToExternalLink(
            'https://saladlab.notion.site/ALPHAREVIEW-USER-GUIDE-c69aab3aca174f31a7fc3eeadaeaa8e9'
          )
        ">
        <img src="assets/icon/bell_small_yellow.svg" />
      </div> -->
      <!-- <div class="gnb-icon">
        <img src="assets/icon/alert_small_gray.svg">
      </div> -->
      <div class="gnb-icon" *ngIf="!isOnboarding" style="position: relative">
        <img class="gnb-icon-user" (click)="showUser = !showUser; showHelp = false" src="assets/icon/gnb_user_gray.svg" />

        <div [@inOutAnimation] class="gnb-icon-menu user" *ngIf="showUser" (mouseleave)="showUser = false">
          <span [routerLink]="['/admin/settings']" [state]="{ tabType: 'store' }" class="gnb-user-store" *ngIf="!adminService.isDemo">Store Settings</span>
          <span [routerLink]="['/admin/settings']" [state]="{ tabType: 'account' }" class="gnb-user-account" *ngIf="!adminService.isDemo">Account Settings</span>
          <span [routerLink]="'/admin/login'" class="gnb-user-store">Logout</span>
        </div>
      </div>

      <div class="gnb-btn" [routerLink]="'/admin/login'" *ngIf="isOnboarding" style="height: 36px">Login</div>
    </div>
  </div>
</main>
