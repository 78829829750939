<nav id="admin-lnb">
  <div style="display: flex; height: 100%">
    <div class="lnb-fix">
      @if (lnbService.selectedApp !== lnbService.AlphaApps.REVIEW) {
      <section (click)="routeStore()" class="lnb-main-title lnb-split bold">
        <img class="circle-bg" src="assets/icon/lnb_store.svg" />
        <div class="flex-wrapper" style="gap: 12px">
          <span class="ellipsis-1">{{ shopName }}</span>
          <img src="assets/icon/arrow_right_dark.svg" class="lnb-arrow" />
        </div>
      </section>

      <section class="lnb-category">
        <div (click)="onClickCategory('settings')" [ngClass]="{ active: genericService.LNBCategory == 'settings' }" class="lnb-main-title">
          <img src="assets/icon/lnb_settings.svg" /> Settings
        </div>
        <div (click)="onClickCategory('market-place')" [ngClass]="{ active: genericService.LNBCategory == 'market-place' }" class="lnb-main-title">
          <img src="assets/icon/lnb_apps.svg" /> Apps
        </div>
      </section>

      @if (lnbService.shopDetail.use_apps.length) {
      <section class="lnb-category">
        <header class="lnb-category-title">Your Apps</header>
        @if (lnbService.shopDetail.use_apps.includes(lnbService.AlphaApps.REVIEW)) {
        <div (click)="onClickCategory('product-reviews')" class="lnb-main-title deps bold"><img src="assets/icon/arrow_right_dark.svg" class="lnb-arrow" /> Product Reviews</div>
        } @if(lnbService.shopDetail.use_apps.includes(lnbService.AlphaApps.AI)) {
        <div (click)="onClickCategory('ai', 'ai-analysis')" [ngClass]="{ active: genericService.LNBCategory == 'aliexpress-analysis' }" class="lnb-main-title deps bold">
          <img src="assets/icon/arrow_right_dark.svg" class="lnb-arrow" /> AI Review Analyzer
        </div>
        } @if(lnbService.shopDetail.use_apps.includes(lnbService.AlphaApps.LIST_DESIGNER)) {
        <div (click)="onClickCategory('list-designer')" [ngClass]="{ active: genericService.LNBCategory == 'list-designer' }" class="lnb-main-title deps bold">
          <img src="assets/icon/arrow_right_dark.svg" class="lnb-arrow" /> List Designer
        </div>
        } @if(lnbService.shopDetail.use_apps.includes(lnbService.AlphaApps.BROWSE_BOOSTER)) {
        <div (click)="onClickCategory('browse-booster')" [ngClass]="{ active: genericService.LNBCategory == 'browse-booster' }" class="lnb-main-title deps bold">
          <img src="assets/icon/arrow_right_dark.svg" class="lnb-arrow" /> Browse Booster
        </div>
        }
      </section>
      } @if (!lnbService.recommendation) {
      <section class="lnb-category">
        <header class="lnb-category-title">Recommendation</header>
        @if (!lnbService.shopDetail.use_apps.includes(lnbService.AlphaApps.REVIEW)) {
        <div (click)="onClickCategory('product-reviews', 'market-place')" class="lnb-main-title deps bold">
          <img src="assets/icon/arrow_right_dark.svg" class="lnb-arrow" /> Product Reviews
        </div>
        } @if(!lnbService.shopDetail.use_apps.includes(lnbService.AlphaApps.AI)) {
        <div (click)="onClickCategory('alpha-ai', 'market-place')" class="lnb-main-title deps bold">
          <img src="assets/icon/arrow_right_dark.svg" class="lnb-arrow" /> AI Review Analyzer
        </div>
        } @if(!lnbService.shopDetail.use_apps.includes(lnbService.AlphaApps.LIST_DESIGNER)) {
        <div (click)="onClickCategory('list-designer', 'market-place')" class="lnb-main-title deps bold">
          <img src="assets/icon/arrow_right_dark.svg" class="lnb-arrow" /> List Designer
        </div>
        } @if(!lnbService.shopDetail.use_apps.includes(lnbService.AlphaApps.BROWSE_BOOSTER)) {
        <div (click)="onClickCategory('browse-booster', 'market-place')" class="lnb-main-title deps bold">
          <img src="assets/icon/arrow_right_dark.svg" class="lnb-arrow" /> Browse Booster
        </div>
        }
      </section>
      } } @else {
      <app-product-reviews-nav></app-product-reviews-nav>
      }
    </div>
  </div>
</nav>
