import { AfterViewInit, Component, OnChanges, OnInit, Optional, SimpleChanges, SkipSelf, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthSuperAdminService } from 'src/app/auth/auth-super-admin.service';
import { POSTCATEGORY, POSTSTATUS, PostTopic, SuperAdminCurrentUser, SuperAdminPost } from 'src/app/models/super-admin/super-admin.shops.model';
import { BackendService } from 'src/app/service/backend.service';
import { SuperAdminService } from 'src/app/service/super-admin.service';
import { ConfirmComponent } from '../../super-admin-shared/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-super-admin-post-list',
  templateUrl: './super-admin-post-list.component.html',
  styleUrls: ['./super-admin-post-list.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class SuperAdminPostListComponent implements OnInit, AfterViewInit, OnChanges {
  constructor(
    public dialog: MatDialog,
    @Optional() @SkipSelf() public superAdminService: SuperAdminService,
    @Optional() @SkipSelf() public authSuperAdminService: AuthSuperAdminService,
    public backendService: BackendService,
    public fb: UntypedFormBuilder,
    public router: Router,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.posts.paginator = this.paginator;
  }
  ngAfterViewInit(): void {
    this.getPosts();
  }
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  categories = POSTCATEGORY;
  categorySelect = new UntypedFormControl([], [Validators.required]);

  topics: string[] = [];
  topicSelect = new UntypedFormControl([], [Validators.required]);

  authors: string[] = [];
  authorSelect: UntypedFormControl = new UntypedFormControl([], [Validators.required]);

  publishStatus = POSTSTATUS;
  publishStatusSelect = new UntypedFormControl([], [Validators.required]);

  posts: MatTableDataSource<SuperAdminPost> = new MatTableDataSource();
  displayedColumns = ['id', 'category', 'super_admin_post_topic', 'title', 'author', 'created_at', 'feed_start', 'randing_start', 'views'];
  featuredCnt: number = 0;
  fixedTopCnt: number = 0;

  searchKeyword: string = '';
  ngOnInit(): void {
    this.getAuthors();
    this.getTopics();
  }
  editPost(post_id: string) {
    this.router.navigate(['super/posts/edit', post_id]);
  }
  getPosts() {
    let url = 'super-admin/posts/board';
    let params = {
      category: this.categorySelect.value,
      topic: this.topicSelect.value,
      author: this.authorSelect.value,
      status: this.publishStatusSelect.value,
      search_keyword: this.searchKeyword,
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize,
    };
    return this.backendService.select(url, params).subscribe((response) => {
      // this.posts=new MatTableDataSource(response.body as SuperAdminPost[])
      const rawData: SuperAdminPost[] = response.body.posts;
      this.fixedTopCnt = 0;
      this.featuredCnt = 0;
      rawData.forEach((post: SuperAdminPost) => {
        post.pined = [];
        if (post.is_featured) {
          this.featuredCnt += 1;
          post.pined.push('랜딩');
        }
        if (post.is_fixed_top) {
          this.fixedTopCnt += 1;
          post.pined.push('홈');
        }
      });
      this.posts.data = rawData;
      this.paginator.length = response.body.length;
    });
  }
  firstPage() {
    this.paginator.pageIndex = 0;
  }
  getTopics() {
    let url = 'super-admin/topics';
    return this.backendService.select(url).subscribe((response_topic) => {
      this.topics = response_topic.body.map((topic: PostTopic) => topic.value);
    });
  }
  getAuthors() {
    return this.authSuperAdminService.superAdminUsers().subscribe((response_author) => {
      this.authors = response_author.body.map((author: SuperAdminCurrentUser) => [author.nickname, author.id]);
      // this.authorSelect.setValue(this.authors.map(a => a[1]))
    });
  }
  createPost() {
    this.router.navigate(['super/posts', 'edit']);
  }
  openDeleteConfirmDialog() {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      height: '180px',
      width: '300px',
      data: '삭제',
      panelClass: 'confirm-dialog',
    });
    return dialogRef.afterClosed();
  }

  deletePost(post_id: number) {
    this.openDeleteConfirmDialog().subscribe((result) => {
      if (result) {
        let url = `super-admin/posts/${post_id}`;
        this.backendService.delete(url).subscribe((response) => {
          this.getPosts();
        });
      }
    });
  }
  getPinUpdate(post_id: number, is_featured: boolean, is_fixed_top: boolean) {
    let url = `super-admin/posts/${post_id}`;
    const payload = {
      is_featured: is_featured,
      is_fixed_top: is_fixed_top,
    };
    this.backendService.patch(url, payload).subscribe((response) => {
      this.getPosts();
    });
  }
  // 필터를 리스트로 할 때에 추가하는 함수
  // addStatusFilter(event: MatSelectChange){
  //
  // }
}
