<article id="alpha-color-picker">
  <section class="palette">
    @for (color of colors; track $index) {
    <div class="color-swatch" [style.background-color]="color" (click)="selectColor(color)" [class.active]="selectedColor === color"></div>
    }
  </section>
  <section class="color-picker">
    <div
      class="color-swatch"
      (click)="picker.click()"
      [style.background-color]="colors.includes(selectedColor) ? '#151516' : selectedColor"
      [class.active]="!colors.includes(selectedColor)"
    ></div>
    <div class="color-label" (click)="picker.click()">
      {{ colors.includes(selectedColor) ? '#000000' : selectedColor }}
      <img src="assets/icon/chevron_right.svg" />
    </div>
    <input #picker type="color" [ngModel]="selectedColor" (ngModelChange)="openColorPicker($event)" style="visibility: hidden" id="custom-color" />
  </section>
</article>
