export interface ContactInformation {
  id: number;
  shop_id: number;
  domain: string;
  domain_status: IdentityStatus;
  email_sender_name: string;
  email_sender_email: string;
  email_status: IdentityStatus | 0;
  sms_sender_name: string;
  sms_sender_phone: string;

  origin_email_sender_name: string;
  origin_domain: string;

  shop_dkims: ShopDKIM[];
}

export interface ShopDKIM {
  id: number;
  shop_contact_info_id: number;
  type: string;
  name: string;
  value: string;
}

export enum IdentityStatus {
  PENDING = 1,
  VERIFIED = 2,
  // FAILED = 3
  // TEMPORARY_FAILURE = 4
  UNVERIFIED = 3,
}
