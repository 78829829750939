<article id="dialog-automatic-product">
  <header class="admin-header-1" style="font-size: 24px">Products to Auto-Display</header>
  <section class="automatic-option">
    @for (automatic of [ListDesignerPreset.MANUAL,ListDesignerPreset.BEST_SELLERS, ListDesignerPreset.NEW_ARRIVALS, ListDesignerPreset.TOP_REVIEWED_PRODUCTS]; track $index) { @if
    ($index > 0) {
    <div
      (click)="!useReview && automatic === ListDesignerPreset.TOP_REVIEWED_PRODUCTS ? return() : (selectedAutomatic = automatic)"
      (mouseover)="automatic === ListDesignerPreset.TOP_REVIEWED_PRODUCTS && (automaticOptions[ListDesignerPreset.TOP_REVIEWED_PRODUCTS].hover = true)"
      (mouseleave)="automatic === ListDesignerPreset.TOP_REVIEWED_PRODUCTS && (automaticOptions[ListDesignerPreset.TOP_REVIEWED_PRODUCTS].hover = false)"
      class="gray-border-wrapper"
      [ngClass]="{ active: selectedAutomatic === automatic }"
    >
      <div class="flex-wrapper">
        <img src="{{ automaticOptions[automatic].icon }}" />
        <header class="admin-header-3">{{ automaticOptions[automatic].title }}</header>
      </div>
      <div class="automatic-description">{{ automaticOptions[automatic].description }}</div>
      @if (!useReview && automatic === ListDesignerPreset.TOP_REVIEWED_PRODUCTS) {
      <div class="review-cover">
        @if (automaticOptions[ListDesignerPreset.TOP_REVIEWED_PRODUCTS].hover) {
        <span class="review-tooltip" style="top: 50%; transform: translate(-50%, -50%)" [@inOutAnimation]
          >It is only available when using the <span class="review-link" routerLink="/admin/market-place" style="cursor: pointer">Product Reviews.</span></span
        >
        }
      </div>
      }
    </div>
    } }
  </section>
  <section class="automatic-btns flex-wrapper">
    <div (click)="onCancel()" class="btn-white" style="height: 36px">Cancel</div>
    <div (click)="onConfirm()" class="btn-black" [ngClass]="{ disabled: !selectedAutomatic }">Select</div>
  </section>
</article>
