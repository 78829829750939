import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-requests',
  templateUrl: './collect.component.html',
  styleUrls: ['./collect.component.less'],
})
export class RequestsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
