import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rating-style-preview',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.912"
      height="10.288"
      viewBox="0 0 10.912 10.288"
      *ngIf="ratingStyle == 'star'"
    >
      <path
        data-name="Icon awesome-star"
        d="M6.312.357 4.98 3.017 2 3.445a.641.641 0 0 0-.361 1.1L3.8 6.611l-.51 2.923a.65.65 0 0 0 .946.677L6.9 8.831l2.666 1.38a.651.651 0 0 0 .946-.677L10 6.611l2.156-2.069a.641.641 0 0 0-.361-1.1l-2.98-.428L7.483.357a.658.658 0 0 0-1.171 0z"
        transform="translate(-1.441 .001)"
        [ngStyle]="{ fill: fillColor }"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="10"
      viewBox="0 0 11 10"
      *ngIf="ratingStyle == 'heart'"
    >
      <path
        data-name="Icon metro-heart"
        d="M10.683 3.856a3.135 3.135 0 0 0-2.612 1.888 3.136 3.136 0 0 0-2.613-1.888 2.863 2.863 0 0 0-2.887 2.835c0 3.183 3.271 4.018 5.5 7.165 2.107-3.128 5.5-4.083 5.5-7.165a2.863 2.863 0 0 0-2.888-2.835z"
        transform="translate(-2.571 -3.856)"
        [ngStyle]="{ fill: fillColor }"
      />
    </svg>
  `,
  styles: [],
})
export class RatingStylePreviewComponent implements OnInit {
  @Input() fillColor!: string;
  @Input() ratingStyle!: string;
  constructor() {}

  ngOnInit(): void {}
}
