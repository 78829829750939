import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/service/backend.service';
import { AdminService } from 'src/app/service/admin.service';
import { SalesPopupConfig, SalesPopupMetric, SalesPopupMetricType } from 'src/app/models/marketing/sales-popup.model';
import { WidgetInstallationPosition } from 'src/app/models/widget/widget.model';
@Injectable({
  providedIn: 'root',
})
export class SalesPopupService {
  constructor(private backendService: BackendService, private adminService: AdminService) {}

  pointColor?: string;
  deepLinkingUrl?: string;
  coreScriptActive?: boolean;
  dragInduce: boolean = false;

  selectedPopupConfig!: SalesPopupConfig;
  salesPopupConfig!: SalesPopupConfig;
  originSalesPopupConfig!: SalesPopupConfig;
  selectedMetric!: SalesPopupMetric;

  liveMetrics: SalesPopupMetric[] = [];
  deActivatedMetrics: SalesPopupMetric[] = [];

  getPopupConfig() {
    let url = 'shops/popup/sales/config';

    return this.backendService.select(url, {
      shop_access_code: this.adminService.getShopAccessCode(),
    });
  }

  getPopupConfigDetail(type: WidgetInstallationPosition) {
    let url = `shops/popup/sales/config/${type}`;

    return this.backendService.select(url, {
      shop_access_code: this.adminService.getShopAccessCode(),
    });
  }

  updatePopupConfigDetail(type: WidgetInstallationPosition, payload: {}) {
    let url = `shops/popup/sales/config/${type}`;

    return this.backendService.update(url, payload, { shop_access_code: this.adminService.getShopAccessCode() });
  }

  getPopupConfigCommon() {
    let url = 'shops/popup/sales/config/common';

    return this.backendService.select(url, {
      shop_access_code: this.adminService.getShopAccessCode(),
    });
  }

  updatePopupConfigCommon(payload: {}) {
    let url = 'shops/popup/sales/config/common';

    return this.backendService.update(url, payload, { shop_access_code: this.adminService.getShopAccessCode() });
  }

  getCommonDesign() {
    let url = 'widget/design/common';

    this.backendService
      .select(url, {
        shop_access_code: this.adminService.getShopAccessCode(),
      })
      .subscribe((response) => {
        let body = response.body;
        this.pointColor = body['point_color'];
      });
  }

  getDeepLinkingUrl() {
    let url = 'widget/deeplinking';

    this.backendService
      .select(url, {
        shop_access_code: this.adminService.getShopAccessCode(),
      })
      .subscribe((response) => {
        let body = response.body;
        this.deepLinkingUrl = body.app_embed_block_url;
      });
  }

  checkCoreScriptActive() {
    let url = 'shops/action/check/core-script';

    this.backendService
      .create(
        url,
        {},
        {
          shop_access_code: this.adminService.getShopAccessCode(),
        },
      )
      .subscribe((response) => {
        let body = response.body;
        this.coreScriptActive = body.ok;
      });
  }

  get WidgetInstallationPosition(): typeof WidgetInstallationPosition {
    return WidgetInstallationPosition;
  }

  get SalesPopupMetricType(): typeof SalesPopupMetricType {
    return SalesPopupMetricType;
  }
}
