<div id="admin">
  <header class="admin-gnb">
    <global-navigation-bar></global-navigation-bar>
  </header>

  <body class="admin-body">
    <admin-float></admin-float>
    <left-navigation-bar class="admin-lnb"></left-navigation-bar>
    <main class="admin-content" [@routeAnimations]="getRouteAnimationData()">
      <router-outlet></router-outlet>
    </main>
  </body>
</div>
