<main id="landing-gnb">
  <div class="gnb-wrapper">
    <section class="gnb-left" (click)="landingService.gnbSlide = false">
      <h1 class="gnb-logo" [routerLink]="'/home/main'" (mouseover)="features = false; resources = false">
        <img src="assets/icon/landing-logo-icon.svg" alt="logo image" style="width: 20px; height: 20px; margin-right: 6px" />
        Alpha Review
      </h1>

      <div class="gnb-menu" *ngIf="!landingService.mobile">
        <ul class="gnb-main">
          <li style="padding-right: 44px" (mouseover)="features = true; resources = false">
            Features <img src="assets/icon/arrow_down_black.svg" />
            <ul [@inOutAnimation] class="gnb-sub" *ngIf="features" (mouseleave)="features = false">
              <li [routerLink]="'./collect'">Collect Review</li>
              <li [routerLink]="'./moderate'">Manage&Moderate</li>
              <li [routerLink]="'./showcase'">Showcase Reviews</li>
              <li [routerLink]="'./marketing'">Marketing&SEO</li>
            </ul>
          </li>
          <li style="padding-right: 34px" (mouseover)="features = false; resources = false" (click)="openLink('https://alph.oopy.io/')">User Guide</li>
          <li (mouseover)="features = false; resources = false" (click)="openLink('https://alph.oopy.io/f7e9cb43-fb97-4c47-a899-3844a14dec01')">Pricing</li>
        </ul>
      </div>
    </section>
    <section class="gnb-right">
      <div class="gnb-toggle-btn" (click)="toggleLanguages(); landingService.gnbSlide = false">
        <span
          [ngStyle]="
            language
              ? {
                  'background-color': '#333336',
                  color: '#fff'
                }
              : {}
          "
          class="gnb-toggle-btn-en"
          >EN</span
        >
        <span
          [ngStyle]="
            !language
              ? {
                  'background-color': '#333336',
                  color: '#fff'
                }
              : {}
          "
          class="gnb-toggle-btn-ko"
          >KO</span
        >
      </div>
      <div class="gnb-mobile-slide" *ngIf="landingService.mobile" (click)="landingService.gnbSlide = !landingService.gnbSlide">
        <span class="gnb-mobile-btn">
          <span class="top-line"></span>
          <span class="bot-line"></span>
        </span>
      </div>
      <ng-container *ngIf="!landingService.mobile">
        <button class="gnb-login" onClick="window.open('https://a-review.co/admin/login')">Login</button>
        <button class="gnb-trial" (click)="landingService.startFreeTrial()">Start Free Trial</button>

        <a href="https://www.shopify.com/" target="_blank">
          <img src="assets/icon/Shopify_logo_2018.svg" alt="Shopify logo" style="width: 60px; cursor: pointer" />
        </a>
      </ng-container>
    </section>
  </div>
  <section class="gnb-slide-menu" [ngClass]="{ active: landingService.gnbSlide }" *ngIf="landingService.mobile">
    <section class="gnb-slide-container">
      <div>
        <ul class="gnb-slide-main">
          <li [@toggle]="features ? 'open' : 'close'" style="height: 40px !important">
            <div class="gnb-slide-flex" (click)="features = !features">
              Features
              <img *ngIf="!features" src="assets/icon/arrow_down_black.svg" />
              <img *ngIf="features" src="assets/icon/arrow_up_black.svg" />
            </div>
            <ul class="gnb-slide-sub">
              <li (click)="landingService.gnbSlide = false" [routerLink]="'./collect'">Collect Review</li>
              <li (click)="landingService.gnbSlide = false" [routerLink]="'./moderate'">Manage&Moderate</li>
              <li (click)="landingService.gnbSlide = false" [routerLink]="'./showcase'">Showcase Reviews</li>
              <li (click)="landingService.gnbSlide = false" [routerLink]="'./marketing'">Marketing&SEO</li>
            </ul>
          </li>
          <li (click)="landingService.gnbSlide = false">
            <div>Why AlphaReview</div>
          </li>
          <li (click)="landingService.gnbSlide = false"><div>Pricing</div></li>
        </ul>
        <a href="https://www.shopify.com/" target="_blank" class="shopify-logo">
          <img src="assets/icon/Shopify_logo_2018.svg" alt="Shopify logo" style="width: 60px; cursor: pointer" />
        </a>
      </div>
      <button class="gnb-slide-trial" (click)="landingService.startFreeTrial()" (click)="landingService.gnbSlide = false">Start Free Trial</button>
    </section>
  </section>
</main>
