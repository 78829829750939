<main id="landing-manage">
  <article class="manage-visual">
    <div class="inner">
      <span class="landing-header-label red">Manage & Moderate</span>
      <header class="landing-header white">
        <span style="color: #ff5555">Save Your Precious Time</span>
        <br class="mobr" />
        with AlphaReview
      </header>
      <summary class="landing-summary" *ngIf="!landingService.mobile">
        There are so many things to do while running an online store. <br />
        That's why it is essential to use your time efficiently. <br />
        Therefore, AlphaReview will help you save time in moderating reviews
        with automated technologies.
      </summary>
      <summary class="landing-summary" *ngIf="landingService.mobile">
        AlphaReview will help you save time in <br class="mobr" />
        moderating reviews with automated <br class="mobr" />technologies.
      </summary>
      <section class="manage-visual-img">
        <img src="assets/image/landing/manage-main-visual.png" />
        <span class="manage-visual-icon" *ngIf="!landingService.mobile">
          <img src="assets/image/landing/manage-main-icon.png" />
        </span>
      </section>
    </div>
  </article>
  <!-- manage visual end -->

  <article class="manage-easy">
    <div class="inner">
      <header class="landing-header">
        Manage Reviews with <br class="mobr" />
        Easy-to-use Reviews Queue
      </header>
      <summary class="landing-summary" *ngIf="!landingService.mobile">
        You can view all the reviews written in your store with an easy-to-use
        reviews queue. <br />
        Moreover, you can decide whether to publish reviews, <br />
        send coupons, or filter reviews by specific criteria.
      </summary>
      <img
        *ngIf="!landingService.mobile"
        class="manage-easy-img"
        src="assets/image/landing/manage-easy.png"
      />
      <img
        *ngIf="landingService.mobile"
        class="manage-easy-img"
        src="assets/image/landing/manage-easy-mo.png"
      />
    </div>
  </article>

  <!-- manage easy end -->

  <article class="manage-moderate">
    <div class="inner">
      <span class="landing-header-label navy">✨ AlphaReview Exclusive</span>
      <header class="landing-header">
        Moderate and Reward Reviews on Autopilot
      </header>
      <summary class="landing-summary" *ngIf="!landingService.mobile">
        Since your time is valuable, we offer the function to publish reviews
        automatically <br />
        or send review coupons based on your criteria. <br />
        With this AlphaReview Exclusive feature, you can save more time than you
        imagine.
      </summary>
      <img
        *ngIf="!landingService.mobile"
        class="manage-moderate-img"
        src="assets/image/landing/manage-moderate.png"
      />
      <img
        *ngIf="landingService.mobile"
        class="manage-moderate-img"
        src="assets/image/landing/manage-moderate-mo.png"
      />
    </div>
  </article>
  <!-- manage moderate end -->

  <article class="manage-replies">
    <div class="inner">
      <span class="landing-header-label navy"> ✨ AlphaReview Exclusive </span>
      <header class="landing-header">
        Manage Customer Relationship Smartly <br />
        with Review Replies
      </header>
      <summary class="landing-summary" *ngIf="!landingService.mobile">
        Responding to all customer reviews is essential to protect your brand
        reputation,<br />
        build stronger relationships with customers, and boost customers'
        loyalty,<br />
        whether the reviews are positive or negative.
      </summary>
      <img
        src="assets/image/landing/manage-replies.png"
        class="manage-replies-img"
      />
    </div>
  </article>
  <!-- manage replies end -->

  <article class="manage-request">
    <header class="landing-header">
      Manage Requests <br />
      with <br class="mobr" />
      the Advanced Request Queue
    </header>
    <summary class="landing-summary" *ngIf="!landingService.mobile">
      AlphaReview provides the most convenient and smart request queue. <br />
      Through this request queue, you can check the schedule or status of the
      review requests. <br />
      Moreover, you can take some actions on the requests, such as changing
      <br />
      or deleting the schedule or resending the requests.
    </summary>
    <img
      *ngIf="!landingService.mobile"
      src="assets/image/landing/manage-request.png"
      class="manage-request-img"
    />
    <img
      *ngIf="landingService.mobile"
      src="assets/image/landing/manage-request-mo.png"
      class="manage-request-img"
    />
  </article>
  <!-- manage request end -->

  <article class="manage-improve">
    <span class="landing-header-label navy">✨ AlphaReview Exclusive</span>
    <header class="landing-header">
      Improve Review Quality <br />
      <br class="mobr" />
      with Elaborated Policies
    </header>
    <summary class="landing-summary">
      With AlphaReview, you can set your criteria <br class="mobr" />
      to limit reviewing or rewards
      <br />
      based on order <br class="mobr" />
      status, delivery status, etc. Implementing <br class="mobr" />
      these policies
      <br />
      can improve review quality <br class="mobr" />
      and discourage subpar write-ups.
    </summary>

    <section class="manage-improve-wrapper inner">
      <div class="manage-improve-content improve01">
        <p class="manage-improve-text">
          For text review content under 30 characters, limit review submission
        </p>
        <span class="manage-improve-img">
          <img src="assets/image/landing/manage-improve-01.png" />
        </span>
      </div>
      <div class="manage-improve-content improve02">
        <p class="manage-improve-text">
          Discount code can only be received when a product amount is over $50
        </p>
        <span class="manage-improve-img">
          <img src="assets/image/landing/manage-improve-02.png" />
        </span>
      </div>
      <div
        class="manage-improve-content improve04"
        *ngIf="landingService.mobile"
      >
        <p class="manage-improve-text">
          Customers should write a review within 60 days from "Delivered" status
        </p>
        <span class="manage-improve-img">
          <img src="assets/image/landing/manage-improve-04.png" />
        </span>
      </div>
      <div class="manage-improve-content improve03">
        <p class="manage-improve-text">
          Only Verified Buyers can write reviews and receive rewards
        </p>
        <span class="manage-improve-img">
          <img src="assets/image/landing/manage-improve-03.png" />
        </span>
      </div>
      <div
        class="manage-improve-content improve04"
        *ngIf="!landingService.mobile"
      >
        <p class="manage-improve-text">
          Customers should write a review within 60 days from "Delivered" status
        </p>
        <span class="manage-improve-img">
          <img src="assets/image/landing/manage-improve-04.png" />
        </span>
      </div>
      <div class="manage-improve-content improve05">
        <p class="manage-improve-text">
          Do not send review request to <br class="mobr" />
          <br />
          reward-restricted orders
        </p>
      </div>
    </section>
  </article>
</main>
