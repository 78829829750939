"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.isProductVariantCreateResourcePayload = exports.isProductVariantResourcePayload = exports.isCreateResourcePayload = exports.isAdminSection = exports.isRemotePayload = exports.isAdminSectionPayload = exports.isAdminPathPayload = exports.isAppPayload = exports.getRelativePath = exports.normalizeUrl = exports.getPathWithSearchAndHash = exports.toDestination = exports.toApp = exports.toRemote = exports.toAdminSection = exports.toAdminPath = exports.isResourcePayload = exports.ResourceType = exports.Action = exports.Redirect = void 0;
var Redirect_1 = require("@shopify/app-bridge-core/actions/Navigation/Redirect");
Object.defineProperty(exports, "Redirect", { enumerable: true, get: function () { return Redirect_1.Redirect; } });
Object.defineProperty(exports, "isCreateResourcePayload", { enumerable: true, get: function () { return Redirect_1.isCreateResourcePayload; } });
Object.defineProperty(exports, "isProductVariantResourcePayload", { enumerable: true, get: function () { return Redirect_1.isProductVariantResourcePayload; } });
var Redirect_2 = require("@shopify/app-bridge-core/actions/Navigation/Redirect");
Object.defineProperty(exports, "Action", { enumerable: true, get: function () { return Redirect_2.Action; } });
Object.defineProperty(exports, "ResourceType", { enumerable: true, get: function () { return Redirect_2.ResourceType; } });
Object.defineProperty(exports, "isResourcePayload", { enumerable: true, get: function () { return Redirect_2.isResourcePayload; } });
Object.defineProperty(exports, "toAdminPath", { enumerable: true, get: function () { return Redirect_2.toAdminPath; } });
Object.defineProperty(exports, "toAdminSection", { enumerable: true, get: function () { return Redirect_2.toAdminSection; } });
Object.defineProperty(exports, "toRemote", { enumerable: true, get: function () { return Redirect_2.toRemote; } });
Object.defineProperty(exports, "toApp", { enumerable: true, get: function () { return Redirect_2.toApp; } });
Object.defineProperty(exports, "toDestination", { enumerable: true, get: function () { return Redirect_2.toDestination; } });
Object.defineProperty(exports, "getPathWithSearchAndHash", { enumerable: true, get: function () { return Redirect_2.getPathWithSearchAndHash; } });
Object.defineProperty(exports, "normalizeUrl", { enumerable: true, get: function () { return Redirect_2.normalizeUrl; } });
Object.defineProperty(exports, "getRelativePath", { enumerable: true, get: function () { return Redirect_2.getRelativePath; } });
Object.defineProperty(exports, "isAppPayload", { enumerable: true, get: function () { return Redirect_2.isAppPayload; } });
Object.defineProperty(exports, "isAdminPathPayload", { enumerable: true, get: function () { return Redirect_2.isAdminPathPayload; } });
Object.defineProperty(exports, "isAdminSectionPayload", { enumerable: true, get: function () { return Redirect_2.isAdminSectionPayload; } });
Object.defineProperty(exports, "isRemotePayload", { enumerable: true, get: function () { return Redirect_2.isRemotePayload; } });
Object.defineProperty(exports, "isAdminSection", { enumerable: true, get: function () { return Redirect_2.isAdminSection; } });
function isProductVariantCreateResourcePayload(resource) {
    if (!Redirect_1.isProductVariantResourcePayload(resource)) {
        return false;
    }
    return Redirect_1.isCreateResourcePayload(resource.variant);
}
exports.isProductVariantCreateResourcePayload = isProductVariantCreateResourcePayload;
function create(app) {
    return new Redirect_1.Redirect(app);
}
exports.create = create;
