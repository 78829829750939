import { Component, OnInit, ViewChild } from '@angular/core';
import { SalesPopupService } from './sales-popup.service';
import { GenericService } from 'src/app/service/generic.service';
import { RouterExtService } from 'src/app/service/routerExt.service';
import { SalesPopupConfig } from 'src/app/models/marketing/sales-popup.model';
import { WidgetInstallationPosition } from 'src/app/models/widget/widget.model';
import { SalesPopupDetailComponent } from './sales-popup-detail/sales-popup-detail.component';
import { SalesPopupGeneralSettingsComponent } from './sales-popup-general-settings/sales-popup-general-settings.component';
import { inOutAnimation } from '../../animations';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-sales-popup',
  templateUrl: './sales-popup.component.html',
  styleUrls: ['./sales-popup.component.less'],
  animations: [inOutAnimation],
})
export class SalesPopupComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  @ViewChild(SalesPopupDetailComponent) popupDetail!: SalesPopupDetailComponent;
  @ViewChild(SalesPopupGeneralSettingsComponent) generalSettings!: SalesPopupGeneralSettingsComponent;
  constructor(public genericService: GenericService, private marketingPopupService: SalesPopupService, private routerExtService: RouterExtService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.popupDetail.getSalesPopupConfigDetail(WidgetInstallationPosition.MAIN_PAGE);
  }

  canDeactivate(): boolean | Promise<boolean> {
    if (!this.popupDetail.checkSettingChanges() || !this.generalSettings.checkSettingChanges()) {
      this.routerExtService.lnbTrigger = false;
      return this.routerExtService.leavePage();
    }
    return true;
  }

  salesPopupConfig!: SalesPopupConfig;

  onSave() {
    if (this.tabGroup.selectedIndex === TabSalesPopup.SETTING) this.generalSettings.updateSalesPopupCommon(this.generalSettings.salesPopupCommon);
    else this.popupDetail.updateSalesPopupConfigDetail(this.marketingPopupService.salesPopupConfig);
  }

  onDiscard() {
    this.tabGroup.selectedIndex === TabSalesPopup.HOMEPAGE && this.popupDetail.getSalesPopupConfigDetail(WidgetInstallationPosition.MAIN_PAGE);
    this.tabGroup.selectedIndex === TabSalesPopup.PRODUCT && this.popupDetail.getSalesPopupConfigDetail(WidgetInstallationPosition.PRODUCT_DETAIL_PAGE);
    this.tabGroup.selectedIndex! < TabSalesPopup.SETTING && this.generalSettings.getSalesPopupCommon();
    if (this.tabGroup.selectedIndex === TabSalesPopup.SETTING) {
      this.generalSettings.getSalesPopupCommon();
      this.ngAfterViewInit();
    }
  }

  checkSettingChanges() {
    if (!this.tabGroup) return;
    if (this.tabGroup.selectedIndex === TabSalesPopup.SETTING) return this.generalSettings.checkSettingChanges();
    else return this.popupDetail.checkSettingChanges();
  }

  get WidgetInstallationPosition(): typeof WidgetInstallationPosition {
    return WidgetInstallationPosition;
  }

  get TabSalesPopup(): typeof TabSalesPopup {
    return TabSalesPopup;
  }
}
enum TabSalesPopup {
  HOMEPAGE = 0,
  PRODUCT = 1,
  SETTING = 2,
}
