import { Component, OnInit, Optional } from '@angular/core';
import { BackendService } from 'src/app/service/backend.service';
import { ImportService } from '../product-reviews/import/import.service';
import { FloatService } from './float.service';

@Component({
  selector: 'admin-float',
  templateUrl: './float.component.html',
  styleUrls: ['./float.component.less'],
})
export class FloatComponent implements OnInit {
  constructor(@Optional() public floatService: FloatService, @Optional() public reviewImportService: ImportService) {
    if (floatService) {
    }
  }

  ngOnInit(): void {}
}
