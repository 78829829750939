import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { CommaSeparatedNumberPipe } from './comma-separated-number.pipe';
import { SafePipe } from './safe.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { OrdinalPipe } from './ordinal.pipe';
import { DateAsAgoPipe } from './date-as-ago.pipe';
import { CurrencyOnlyPipe } from './currency-only.pipe';



@NgModule({
  declarations: [
    CommaSeparatedNumberPipe,
    SafePipe,
    SafeHtmlPipe,
    OrdinalPipe,
    DateAsAgoPipe,
    CurrencyOnlyPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CommaSeparatedNumberPipe,
    SafePipe,
    SafeHtmlPipe,
    OrdinalPipe,
    DateAsAgoPipe,
    CurrencyOnlyPipe
  ],
  providers: [
    CurrencyPipe
  ]
})
export class PipeModule { }
