import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowseBoosterService } from './browse-booster.service';
import { BrowseBoosterProgramSetupComponent } from './browse-booster-program-setup/browse-booster-program-setup.component';
import { SharedModule } from '../../shared/shared.module';
import { BrowseBoosterDesignBrandingComponent } from './browse-booster-design-branding/browse-booster-design-branding.component';
import { inOutAnimation } from '../animations';

@Component({
  selector: 'app-browse-booster',
  templateUrl: './browse-booster.component.html',
  standalone: true,
  styleUrl: './browse-booster.component.less',
  imports: [MatTabsModule, SharedModule, BrowseBoosterProgramSetupComponent, BrowseBoosterDesignBrandingComponent],
  animations: [inOutAnimation],
})
export class BrowseBoosterComponent {
  constructor(public browseBoosterService: BrowseBoosterService) {}

  ngOnInit(): void {
    this.browseBoosterService.getBrowseBoosterSetup();
    this.browseBoosterService.checkedCoreScript();
  }
}
