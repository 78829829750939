import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { BillingInterval, ShopBilling, ShopBillingPlan } from '../models/billing/billing.model';
import { AdminService } from './admin.service';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { AlphaApps } from '../models/shop/profile/store-detail.model';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(private backendService: BackendService, private adminService: AdminService, private router: Router) {
    if (!this.shopBilling) {
      this.getBilling().subscribe((response) => {
        this.shopBilling = response.body as ShopBilling;
        this.originShopBilling = cloneDeep(response.body as ShopBilling);
      });
    }
  }

  PREMIUM_PLAN_PRICE = 9.9;
  YEARLY_PLAN_DISCOUNT = 0.4;

  shopBilling?: ShopBilling = undefined;
  originShopBilling?: ShopBilling = undefined;

  getBilling() {
    const url = 'billing';

    return this.backendService.select(url, {
      shop_access_code: this.adminService.getShopAccessCode()?.length <= 6 ? `AA${this.adminService.getShopAccessCode()}` : this.adminService.getShopAccessCode(),
    });
  }

  goToSubscription() {
    this.router.navigate(['/admin/settings'], {
      state: { tabType: 'subscription' },
    });
  }

  upgradeBillingPlan(plan: ShopBillingPlan, billingInterval: BillingInterval) {
    const url = 'billing/subscription';

    return this.backendService.create(
      url,
      {},
      {
        shop_access_code: this.adminService.getShopAccessCode(),
        billing_interval: billingInterval,
        plan: plan,
      },
    );
  }

  downgradeBillingPlan(plan: ShopBillingPlan) {
    const url = 'billing/subscription';

    return this.backendService.delete(url, {
      shop_access_code: this.adminService.getShopAccessCode(),
      shop_billing_id: this.shopBilling?.id,
      plan: plan,
    });
  }

  get BillingInterval(): typeof BillingInterval {
    return BillingInterval;
  }

  get ShopBillingPlan(): typeof ShopBillingPlan {
    return ShopBillingPlan;
  }
}
