<article id="admin-login">
    <section
      class="login-input-container"
      [ngStyle]="{ top: this.top + '%', left: this.left + '%' }"
    >
      <header class="admin-header-2">당신은 샐러드맨인가요 ?</header>
      <section style="margin-top: 25px">
        <div class="input-container">
          <div class="admin-header-3">ID</div>
          <input
            class="admin-input"
            type="text"
            placeholder="ID"
            autofocus
            (keydown.enter)="onLogin()"
            [(ngModel)]="account_id"
          />
        </div>
        <div class="input-container">
          <div class="admin-header-3">Password</div>
          <input
            class="admin-input"
            type="password"
            placeholder="Password"
            (keydown.enter)="onLogin()"
            [(ngModel)]="password"
          />
        </div>
      </section>
      <section class="flex-wrapper" style="margin-top: 20px">
        <div></div>
        <div>
          <button [disabled]="doLogin" class="btn-confirm" (click)="onLogin()">
            출근 !
          </button>
        </div>
      </section>
    </section>
  </article>
  