<main id="landing-sub-footer">
  <article class="sub-footer">
    <header class="landing-header landing-flex-wrapper">
      <a [routerLink]="landingService.prev" *ngIf="!landingService.mobile">
        <img src="assets/icon/landing-prev-big.svg" />
      </a>
      <span>WHAT'S NEXT</span>
      <a [routerLink]="landingService.next" *ngIf="!landingService.mobile">
        <img src="assets/icon/landing-next-big.svg" />
      </a>
    </header>

    <section class="sub-footer-list landing-flex-wrapper">
      <div
        #Collect
        class="sub-footer-menu-box"
        [ngClass]="{ active: landingService.idx == 'collect' }"
        [routerLink]="'./collect'"
      >
        <div class="main-center-menu-img">
          <img src="assets/image/landing/center-menu-01.png" alt="" />
        </div>
        <div class="sub-footer-menu-text">
          <h3 class="sub-footer-menu-title">Collect Reviews</h3>
          <p class="sub-footer-menu-list" *ngIf="!landingService.mobile">
            · Automatic Review Requests <br />
            · Various Reminders <br />
            · Incentivize Customers <br />
            · Quick Reviews <br />
            · Joyful Reviewing Process <br />
            · Import Reviews
          </p>
          <span *ngIf="landingService.mobile"
            >details <img src="assets/icon/arrow_right_gray.svg" alt=""
          /></span>
        </div>
      </div>
      <div
        #Collect
        class="sub-footer-menu-box"
        [ngClass]="{ active: landingService.idx == 'moderate' }"
        [routerLink]="'./moderate'"
      >
        <div class="main-center-menu-img">
          <img src="assets/image/landing/center-menu-02.png" alt="" />
        </div>
        <div class="sub-footer-menu-text">
          <h3 class="sub-footer-menu-title">Manage & Moderate</h3>
          <p class="sub-footer-menu-list" *ngIf="!landingService.mobile">
            · Easy-to-use Review Queue <br />
            · Moderate Reviews on Autopilot <br />
            · Smart Request Queue <br />
            · Elaborated Review · Reward Policies
          </p>
          <span *ngIf="landingService.mobile"
            >details <img src="assets/icon/arrow_right_gray.svg" alt=""
          /></span>
        </div>
      </div>
      <div
        #Collect
        class="sub-footer-menu-box"
        [ngClass]="{ active: landingService.idx == 'showcase' }"
        routerLink="./showcase"
      >
        <div class="main-center-menu-img">
          <img src="assets/image/landing/center-menu-03.png" alt="" />
        </div>
        <div class="sub-footer-menu-text">
          <h3 class="sub-footer-menu-title">Showcase Reviews</h3>
          <p class="sub-footer-menu-list" *ngIf="!landingService.mobile">
            · Reviews Widgets <br />
            · Media Reviews Widgets <br />
            · Happy Customers Widgets <br />
            · Widget Customization
          </p>
          <span *ngIf="landingService.mobile"
            >details <img src="assets/icon/arrow_right_gray.svg" alt=""
          /></span>
        </div>
      </div>
      <div
        #Collect
        class="sub-footer-menu-box"
        [ngClass]="{ active: landingService.idx == 'marketing' }"
        routerLink="./marketing"
      >
        <div class="main-center-menu-img">
          <img src="assets/image/landing/center-menu-04.png" alt="" />
        </div>
        <div class="sub-footer-menu-text">
          <h3 class="sub-footer-menu-title">Marketing & SEO</h3>
          <p class="sub-footer-menu-list" *ngIf="!landingService.mobile">
            · Facebook & Twitter Social Push <br />
            · Instagram Curation <br />
            · Social Reviews <br />
            · Google SEO & Google Shopping
          </p>
          <span *ngIf="landingService.mobile"
            >details <img src="assets/icon/arrow_right_gray.svg" alt=""
          /></span>
        </div>
      </div>
    </section>
  </article>
</main>
