<article id="review-import-history">
  <header class="admin-header-1" style="margin-bottom: 17px">Import history</header>

  <section style="max-height: 600px; overflow-y: auto; overflow-x: hidden; display: block; position: relative; border-bottom: 1px solid #d7d9de">
    <table mat-table #matTable [dataSource]="dataSource" style="max-width: 100%">
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef>Created at / Author</th>
        <td mat-cell *matCellDef="let element" style="position: relative; cursor: pointer; width: 150px" matTooltip="{{ element.created_at | date : 'MMM d, y ah:mm' }}">
          <div class="ellipsis" style="font-weight: 600; margin-bottom: 6px">
            {{ element.author }}
          </div>
          <div>
            {{ element.created_at | date : 'mediumDate' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="platform-product">
        <th mat-header-cell *matHeaderCellDef>Platform/Product</th>
        <td mat-cell *matCellDef="let element">
          <div [ngSwitch]="element.app_platform">
            <span class="import-platform" *ngSwitchCase="ReviewAppPlatform.CSV">CSV</span>
            <span class="import-platform" *ngSwitchCase="ReviewAppPlatform.PRODUCT_REVIEWS">Product Reviews</span>
            <span class="import-platform" *ngSwitchCase="ReviewAppPlatform.JUDGE_ME">Judge Me</span>
            <span class="import-platform" *ngSwitchCase="ReviewAppPlatform.LOOX">Loox</span>
            <span class="import-platform" *ngSwitchCase="ReviewAppPlatform.STAMPED_IO">Stamped IO</span>
            <span class="import-platform" *ngSwitchCase="ReviewAppPlatform.YOTPO">Yotpo</span>
            <span class="import-platform" *ngSwitchCase="ReviewAppPlatform.OKENDO">Okendo</span>
            <span class="import-platform" *ngSwitchCase="ReviewAppPlatform.RYVIU">Ryviu</span>
            <span class="import-platform" *ngSwitchCase="ReviewAppPlatform.RIVYO">RIVYO</span>
            <span class="import-platform" *ngSwitchCase="ReviewAppPlatform.ALI_EXPRESS" style="margin-bottom: 6px; display: inline-block">Aliexpress</span>
            <span class="import-platform" *ngSwitchCase="ReviewAppPlatform.AMAZON" style="margin-bottom: 6px; display: inline-block">Amazon</span>
          </div>
          <div class="ellipsis">
            {{ element.product_names.join(', ') }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="result">
        <th mat-header-cell *matHeaderCellDef width="150px">Result</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.progress_status !== TransferStatus.IN_PROGRESS && element.progress_status !== TransferStatus.FAILED">
            <div *ngIf="element.task_count >= 0" class="result-content">
              <img class="result-img" src="assets/icon/check_circle_green.svg" />
              {{ element.task_count | number }} reviews imported
              <div
                *ngIf="
                  (element.app_platform == ReviewAppPlatform.ALI_EXPRESS || element.app_platform == ReviewAppPlatform.AMAZON) &&
                  element.progress_status == TransferStatus.SUCCESSED &&
                  element.task_count < element.total_count
                "
                class="admin-tool-tip-container"
                style="display: inline-flex; margin-left: 5px"
              >
                <img
                  style="width: 16px; height: 16px"
                  src="assets/icon/info_gray.svg"
                  matTooltip="Due to your import criteria,
                  fewer reviews were imported than
                  you had attempted."
                />
              </div>
            </div>
            <div *ngIf="element.error_count" class="result-content" style="margin-top: 6px">
              <img class="result-img" src="assets/icon/warning_circle_red.svg" />
              {{ element.error_count | number }} reviews failed
              <div *ngIf="element.troubleshoot_url" style="margin-left: 6px">
                <a
                  class="result-download-report"
                  [href]="element.troubleshoot_url"
                  target="_blank"
                  matTooltip="Expired date : {{ element.troubleshoot_expired_at | date : 'yyyy/MM/dd' }}"
                >
                  Error report
                </a>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="TransferStatus.TASK_CREATED < element.progress_status">
            <ng-container *ngIf="element.app_platform == ReviewAppPlatform.ALI_EXPRESS || element.app_platform == ReviewAppPlatform.AMAZON">
              <div *ngIf="element.progress_status <= TransferStatus.IN_PROGRESS && element.task_count" class="result-content">
                <span class="loader"></span>
                In progress
              </div>

              <div *ngIf="element.progress_status <= TransferStatus.IN_PROGRESS && !element.task_count" style="color: #589bff" class="result-content">
                <img style="margin-right: 6px" src="assets/icon/up_down_blue.svg" />
                Wait for 15~30 minutes
              </div>
            </ng-container>
            <ng-container *ngIf="element.progress_status == TransferStatus.FAILED">
              <div *ngIf="!element.error_count" class="result-content">
                <img class="result-img" src="assets/icon/warning_circle_red.svg" />
                Failed
                <div style="display: inline-flex; gap: 3px; margin-left: 6px; cursor: pointer">
                  <a
                    *ngIf="element.app_platform == ReviewAppPlatform.ALI_EXPRESS"
                    class="result-download-report"
                    href="https://alph.oopy.io/9546176e-fa1c-4e7c-b5ab-2b3375cc1b0b"
                    target="_blank"
                    >Troubleshooting guide</a
                  >
                  <a
                    *ngIf="element.app_platform == ReviewAppPlatform.AMAZON"
                    class="result-download-report"
                    href="https://alph.oopy.io/25b48a0c-f497-47e9-83ba-d724e8956cba2"
                    target="_blank"
                    >Troubleshooting guide</a
                  >
                  <a
                    *ngIf="element.app_platform !== ReviewAppPlatform.ALI_EXPRESS && element.app_platform !== ReviewAppPlatform.AMAZON"
                    class="result-download-report"
                    href="https://alph.oopy.io/1a0ad946-dba1-4ef8-be28-dc37a0b03db2"
                    target="_blank"
                    >Troubleshooting guide</a
                  >
                  <img src="assets/icon/link_bold.svg" />
                </div>
              </div>

              <div *ngIf="element.error_count" class="result-content" style="margin-top: 6px">
                <img class="result-img" src="assets/icon/warning_circle_red.svg" />
                {{ element.error_count | number }} reviews failed
                <div *ngIf="element.troubleshoot_url" style="margin-left: 6px">
                  <a
                    class="result-download-report"
                    [href]="element.troubleshoot_url"
                    target="_blank"
                    matTooltip="Expired date : {{ element.troubleshoot_expired_at | date : 'yyyy/MM/dd' }}"
                  >
                    Error report
                  </a>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>View/Delete</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex-wrapper" style="gap: 6px; justify-content: flex-start">
            <div
              *ngIf="TransferStatus.IN_PROGRESS <= element.progress_status && element.progress_status < TransferStatus.FAILED && element.task_count"
              class="btn-white"
              style="width: 54px"
              (click)="viewAllReview(element.id)"
            >
              View
            </div>
            <div
              *ngIf="TransferStatus.SUCCESSED <= element.progress_status && element.progress_status <= TransferStatus.FAILED"
              class="btn-white"
              style="width: 64px"
              (click)="onCancelClick(element)"
            >
              Delete
            </div>
          </div>
          <div style="font-size: 13px" *ngIf="TransferStatus.DELETED == element.progress_status">Deleted</div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
    </table>

    <ng-container *ngIf="emptyData">
      <app-empty-data style="display: block; margin-bottom: 50px" [summary]="'There is no import history yet.'"> </app-empty-data>
    </ng-container>
  </section>
  <button class="btn-white" style="width: 71px; margin: 16px 0 0 auto" (click)="onNoClick()">Close</button>
</article>
