import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';

export const slideInAnimation = trigger('routeAnimations', [
  transition('SettingDetail => Setting', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      }),
    ]),
    /* Leave component SettingDetail start */
    query(':enter', [style({ left: '-100%' })]),
    query(':leave', style({ left: '0%' })),
    /* Leave component SettingDetail end */

    /* Enter component SettingDetail start */
    group([query(':leave', [animate('300ms ease-out', style({ left: '100%' }))]), query(':enter', [animate('300ms ease-out', style({ left: '0%' }))])]),
    /* Enter component SettingDetail end */
  ]),
  transition('Setting => SettingDetail', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      }),
    ]),
    query(':enter', [style({ left: '100%' })]),
    query(':leave', style({ left: '0%' })),
    group([query(':leave', [animate('300ms ease-out', style({ left: '-100%' }))]), query(':enter', [animate('300ms ease-out', style({ left: '0%' }))])]),
  ]),
  transition('Setting => Setting', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      }),
    ]),
    query(':enter', [style({ top: '-100%' })]),
    query(':leave', [style({ top: '0%' })]),
    /* query(':leave', animateChild()), */
    group([query(':leave', [animate('300ms ease-out', style({ top: '100%' }))]), query(':enter', [animate('300ms ease-out', style({ top: '0%' }))])]),
  ]),
  /* transition('* <=> *', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ left: '-100%' })
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('200ms ease-out', style({ left: '100%', opacity: 0 }))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%' }))
        ]),
        query('@*', animateChild())
      ]),
    ]) */
]);

export const inOutAnimation = [
  trigger('inOutAnimation', [
    transition(':enter', [style({ opacity: 0 }), animate('0.2s ease-in', style({ opacity: 1 }))]),
    transition(':leave', [style({ opacity: 1 }), animate('0.2s ease-out', style({ opacity: 0 }))]),
  ]),
];

export const fadeAnimation = trigger('fade', [state('in', style({ opacity: 1 })), state('out', style({ opacity: 0 })), transition('in <=> out', animate('0.3s ease-in-out'))]);

export const addOnlyAnimation = [trigger('addOnlyAnimation', [transition(':enter', [style({ opacity: 0 }), animate('0.3s linear', style({ opacity: 1 }))])])];

export const slider = trigger('routeSlide', [
  transition(
    'signIn => storeSetting, signUp => storeSetting, storeSetting => appSelect, appSelect => appInstall, appInstall => reviewRequest, appSelect => typeSelect, typeSelect => aliImport',
    slideTo('right'),
  ),
  transition(
    'aliImport => typeSelect,typeSelect => appSelect, reviewRequest => appInstall, appInstall => appSelect, appSelect => storeSetting, storeSetting => signUp, storeSetting => signIn',
    slideTo('left'),
  ),
]);

function slideTo(direction: string) {
  const optional = { optional: true };
  return [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          [direction]: 0,
          width: '100%',
        }),
      ],
      optional,
    ),
    query(':enter', [style({ [direction]: '-100%' })]),
    group([query(':leave', [animate('500ms ease', style({ [direction]: '100%' }))], optional), query(':enter', [animate('500ms ease', style({ [direction]: '0%' }))])]),
  ];
}
