"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.ModalContent = exports.Action = void 0;
var ModalContent_1 = require("@shopify/app-bridge-core/actions/Modal/ModalContent");
Object.defineProperty(exports, "ModalContent", { enumerable: true, get: function () { return ModalContent_1.ModalContent; } });
var ModalContent_2 = require("@shopify/app-bridge-core/actions/Modal/ModalContent");
Object.defineProperty(exports, "Action", { enumerable: true, get: function () { return ModalContent_2.Action; } });
/**
 * @public
 */
function create(app, options) {
    return new ModalContent_1.ModalContent(app, options);
}
exports.create = create;
