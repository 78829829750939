import { AfterViewInit, Component, Input, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ShopAdminAccount } from 'src/app/models/shop/shop.model';
import { AdminService } from 'src/app/service/admin.service';
import { BackendService } from 'src/app/service/backend.service';
import { ShopService } from 'src/app/service/shop.service';
import { PaginationHandler } from 'src/app/shared/handler/pagination-handler';
import { DialogAdminAccountInviteComponent } from '../../dialog/dialog-admin-account-invite/dialog-admin-account-invite.component';
import { DialogAdminAccountUpdatePositionComponent } from '../../dialog/dialog-admin-account-update-position/dialog-admin-account-update-position.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { DialogDeleteAppComponent } from '../../dialog/dialog-delete-app/dialog-delete-app.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-setting',
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.less'],
})
export class AccountSettingComponent implements OnInit, AfterViewInit {
  constructor(
    @Optional() @SkipSelf() public adminService: AdminService,
    private backendService: BackendService,
    public shopService: ShopService,
    private dialog: MatDialog,
    private router: Router,
  ) {}
  @ViewChild('paginator') paginator!: MatPaginator;

  paginationHandler!: PaginationHandler;

  @ViewChild('matTable') matTable!: MatTable<ShopAdminAccount>;
  displayColumns: string[] = [];
  dataSource: ShopAdminAccount[] = [];

  ngOnInit(): void {
    if (this.adminService.ShopAdminAccount.position == this.shopService.ShopAccountPosition.OWNER) {
      this.displayColumns = ['role', 'member&email', 'status&lastAccess', 'action'];
    } else {
      this.displayColumns = ['role', 'member&email', 'status&lastAccess'];
    }
  }

  ngAfterViewInit(): void {
    this.paginationHandler = new PaginationHandler(this.paginator, 0, 10);
    this.getShopAdminAccount();
  }

  getShopAdminAccount() {
    let url = 'shops/accounts';
    this.backendService
      .select(url, {
        shop_access_code: this.adminService.getShopAccessCode(),
        skip: this.paginationHandler.skip,
        limit: this.paginationHandler.limit,
        embed: ['shop'],
      })
      .subscribe((response) => {
        this.dataSource = [];
        this.dataSource = response.body.data as ShopAdminAccount[];
        this.paginator.length = response.body.length;
        this.matTable.renderRows();
      });
  }

  openDialogAdminAccountInvite() {
    const dialogRef = this.dialog.open(DialogAdminAccountInviteComponent, {
      width: '480px',
      maxHeight: '800px',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result: boolean | undefined) => {
      this.getShopAdminAccount();
    });
  }

  openDialogAdminAccountUpdatePosition(shopAdminAccount: ShopAdminAccount) {
    const dialogRef = this.dialog.open(DialogAdminAccountUpdatePositionComponent, {
      width: '480px',
      maxHeight: '800px',
      data: shopAdminAccount,
    });

    dialogRef.afterClosed().subscribe((result: boolean | undefined) => {
      this.getShopAdminAccount();
    });
  }

  openDialogDeleteApp() {
    const dialogRef = this.dialog.open(DialogDeleteAppComponent, {
      width: '480px',
      maxHeight: '80vh',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;

      let url = 'shops/uninstall';
      let data = {
        uninstall_reason: result.uninstallReason,
        uninstall_comment: result.uninstallComment,
      };
      this.backendService.create(url, data, { shop_access_code: this.adminService.getShopAccessCode() }).subscribe();

      this.router.navigate(['/admin/login']);
    });
  }
}
