<article id="dialog-manage-review-unpublish">
  <header class="admin-header-2" style="margin-bottom: 12px">Choose a reason for unpublishing it</header>
  <section>
    <div style="margin-bottom: 20px; line-height: 20px">
      The FTC strongly encourages brands to publish all reviews - both positive and negative. Therefore, you must have legitimate reasons for unpublishing reviews. Otherwise, the
      FTC may view your action as a law violation. Therefore, please choose the reason for unpublishing the review.
    </div>

    <div style="margin-bottom: 20px">Click <a class="to-ftc" href="https://www.ftc.gov">here</a> to see the FTC's Guidance.</div>

    <div style="margin-bottom: 30px">
      <div class="modal-checkbox-container">
        <alpha-checkbox
          [ngModel]="reviewUnpublishedReason.unpublished_reasons.indexOf(reviewService.ReviewUnpublishReasonCheckBox.CONTAIN_CONFIDENTIAL) >= 0"
          (ngModelChange)="setUnpublishedReason(reviewService.ReviewUnpublishReasonCheckBox.CONTAIN_CONFIDENTIAL, $event)"
        >
          Contains confidential or private information - for example, a person’s financial, medical, or personnel file information or a company’s trade secrets
        </alpha-checkbox>
      </div>

      <div class="modal-checkbox-container">
        <alpha-checkbox
          [ngModel]="reviewUnpublishedReason.unpublished_reasons.indexOf(reviewService.ReviewUnpublishReasonCheckBox.CONTAIN_LIBELOUS) >= 0"
          (ngModelChange)="setUnpublishedReason(reviewService.ReviewUnpublishReasonCheckBox.CONTAIN_LIBELOUS, $event)"
        >
          is libelous, harassing, abusive, obscene, vulgar, sexually explicit, or is inappropriate with respect to race, gender, sexuality, ethnicity, or other intrinsic
          characteristic.
        </alpha-checkbox>
      </div>

      <div class="modal-checkbox-container">
        <alpha-checkbox
          [ngModel]="reviewUnpublishedReason.unpublished_reasons.indexOf(reviewService.ReviewUnpublishReasonCheckBox.UNRELATED_CONTENT) >= 0"
          (ngModelChange)="setUnpublishedReason(reviewService.ReviewUnpublishReasonCheckBox.UNRELATED_CONTENT, $event)"
        >
          is unrelated to the company’s products or services.
        </alpha-checkbox>
      </div>

      <div class="modal-checkbox-container">
        <alpha-checkbox
          [ngModel]="reviewUnpublishedReason.unpublished_reasons.indexOf(reviewService.ReviewUnpublishReasonCheckBox.MISLEADING) >= 0"
          (ngModelChange)="setUnpublishedReason(reviewService.ReviewUnpublishReasonCheckBox.MISLEADING, $event)"
        >
          is clearly false or misleading.
        </alpha-checkbox>
      </div>

      <div class="modal-checkbox-container">
        <alpha-checkbox
          [ngModel]="reviewUnpublishedReason.unpublished_reasons.indexOf(reviewService.ReviewUnpublishReasonCheckBox.OTHER) >= 0"
          (ngModelChange)="setUnpublishedReason(reviewService.ReviewUnpublishReasonCheckBox.OTHER, $event)"
        >
          <div style="margin-bottom: 12px">the other reason</div>
          <textarea
            *ngIf="reviewUnpublishedReason.unpublished_reasons.indexOf(reviewService.ReviewUnpublishReasonCheckBox.OTHER) >= 0"
            class="admin-input"
            placeholder="Enter"
            style="width: 100%; height: 100px"
            [(ngModel)]="reviewUnpublishedReason.detail_reason"
          >
          </textarea>
        </alpha-checkbox>
      </div>
    </div>
  </section>

  <section class="flex-wrapper">
    <div></div>
    <div class="flex-wrapper">
      <button class="btn-cancel" (click)="onCancel()">Cancel</button>

      <button
        class="btn-confirm"
        (click)="onConfirm()"
        [disabled]="
          reviewUnpublishedReason.unpublished_reasons.length <= 0 ||
          (reviewUnpublishedReason.unpublished_reasons.indexOf(reviewService.ReviewUnpublishReasonCheckBox.OTHER) >= 0 && !reviewUnpublishedReason.detail_reason)
        "
      >
        Save
      </button>
    </div>
  </section>
</article>
