import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminService } from '../service/admin.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard {
  constructor(private adminService: AdminService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    try {
      if (!this.adminService.ShopAccessToken || !this.adminService.getShopAccessCode()) {
        this.router.navigateByUrl('/admin/login');
        alert('Expired session.');
        return false;
      }

      if (route.routeConfig?.path === 'settings' && this.adminService.isDemo) {
        this.router.navigateByUrl('/admin/market-place');
        alert('Cannot access in demo mode.');
        return false;
      }
    } catch {
      this.router.navigateByUrl('/admin/login');
      alert('Expired session.');
      return false;
    }

    return true;
  }
}
