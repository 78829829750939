"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Action = void 0;
var Action;
(function (Action) {
    Action["LARGEST_CONTENTFUL_PAINT"] = "APP::WEB_VITALS::LARGEST_CONTENTFUL_PAINT";
    Action["FIRST_INPUT_DELAY"] = "APP::WEB_VITALS::FIRST_INPUT_DELAY";
    Action["CUMULATIVE_LAYOUT_SHIFT"] = "APP::WEB_VITALS::CUMULATIVE_LAYOUT_SHIFT";
    Action["FIRST_CONTENTFUL_PAINT"] = "APP::WEB_VITALS::FIRST_CONTENTFUL_PAINT";
    Action["TIME_TO_FIRST_BYTE"] = "APP::WEB_VITALS::TIME_TO_FIRST_BYTE";
    Action["INTERACTION_TO_NEXT_PAINT"] = "APP::WEB_VITALS::INTERACTION_TO_NEXT_PAINT";
})(Action = exports.Action || (exports.Action = {}));
