import { Component, OnInit } from '@angular/core';
import { GenericService } from 'src/app/service/generic.service';
import { DisplayService } from './display.service';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.less'],
})
export class DisplayComponent implements OnInit {
  constructor(public genericService: GenericService, public showCaseService: DisplayService) {}

  ngOnInit(): void {}
}
