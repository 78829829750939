import { Product, ProductVariant } from '../product/product.model';
import { Customer } from 'src/app/models/customer/customer.model';
import { LineItem } from '../order/line-item.model';
import { Label } from '../shop/review/options/review-label.model';
import { ReviewFixed } from '../shop/review/options/review-fixed.model';
import { PriceRule, ReviewBenefitProvisionPolicyDetail } from '../shop/auth/benefit/benefit.model';

export enum ReviewType {
  BASIC_REVIEW = 1,
  LONGTERM_USE_REVIEW = 2,
  SOCIAL_REVIEW = 3,
  STORE_REVIEW = 4,
}

export enum WriterType {
  CUSTOMER_WITH_ORDER = 1,
  CUSTOMER_WITHOUT_ORDER = 2,
  ADMINISTRATOR = 3,
  OTHERS = 4,
}

export enum ReviewMediaType {
  PHOTO = 10,
  GIF = 11,
  VIDEO = 20,
}

export enum WritingPlatform {
  SHOPIFY = 1,
  ALIEXPRESS = 2,
  AMAZON = 3,
  CSV = 4,
}

export enum WritingSource {
  EMAIL = 1,
  SMS = 2,
  WEBPUSH = 3,
  REVISIT = 4,
  WEB = 5,
}

export enum VerificationStatus {
  VERIFIED = 1,
  UNVERIFIED = 2,
  WAITING = 3,
}

/**
리뷰 혜택 관리 상태

| Name | Value  | Description |
|-----|-----|-------|
| NO_PAYMENT | 10 | 혜택 지급 불가/안함 |
| MANUAL_PAYMENT_WAITING | 21 | 수동 지급 대기중 |
| AUTO_PAYMENT_WAITING | 22 | 자동 지급 대기중 |
| PAYMENT_ONGOING | 30 | 지급 프로세스 진행중 |
| PAYMENT_COMPLETE | 40 | 지급 프로세스 완료 |
| RECOVER_ONGOING | 50 | 회수 프로세스 진행중(discount code 비활성화) |
| RECOVER_COMPLETE | 60 | 회수 프로세스 완료(discount code 비활성화) |
*/
export enum ReviewRewardStatus {
  NO_PAYMENT = 10,
  MANUAL_PAYMENT_WAITING = 21,
  AUTO_PAYMENT_WAITING = 22,
  PAYMENT_ONGOING = 30,
  PAYMENT_COMPLETE = 40,
  RECOVER_ONGOING = 50,
  RECOVER_COMPLETE = 60,
}

/**
 * Enum : Review 인증 상태별

    | Name | Value  | Description |
    |-----|-----|-------|
    | MANUAL_PAYMENT   | 10   | 수동지급     |
    | AUTO_PAYMENT   | 11   | 자동지급     |
    | UPDATE_REWARD   | 20   | 혜택 수정 |
    | NO_PAYMENT   | 30   | 혜택 지급 안함 |
    | CANCEL_REWARD   | 31   | 혜택 취소 |
    | ADDITIONAL_REWARD   | 40   | 추가 지급 |
    | RECOVER_REWARD   | 50   | 혜택 회수(deactivate) |
    | CANCEL_RECOVER   | 60   | 혜택 회수 취소 |
    """
 */
export enum AdminRewardAction {
  MANUAL_PAYMENT = 10,
  AUTO_PAYMENT = 11,
  UPDATE_REWARD = 20,
  NO_PAYMENT = 30,
  CANCEL_REWARD = 31,
  ADDITIONAL_REWARD = 40,
  RECOVER_REWARD = 50,
  CANCEL_RECOVER = 60,
}

export class ReviewMedia {
  id?: number;
  review_id!: number;
  type!: ReviewMediaType;
  file?: File | Blob;
  previewURL?: string;
  file_name?: string;
  origin?: string;
  large?: string;
  medium?: string;
  small?: string;
  thumbnail?: string;
}
export class ReviewComment {
  id?: number;
  review_id?: number;
  created_at?: Date;
  content: string = '';
  is_private: boolean = false;
  is_admin: boolean = false;
  is_email_send: boolean = false;
  author_id?: number;
  author_firstname: string = '';
  author_lastname: string = '';

  update: boolean = false;
}

export class ReviewMemoComment {
  id?: number;
  review_memo_id?: number;
  created_at?: Date;
  updated_at?: Date;
  content: string = '';
  author: string = '';
  author_id?: number;
  update: boolean = false;
}

export class ReviewMemo {
  id?: number;
  review_id?: number;
  created_at?: Date;
  updated_at?: Date;
  content: string = '';
  author: string = '';
  author_id?: number;
  review_memo_comments: ReviewMemoComment[] = [];
  update: boolean = false;
}

export class ReviewRewardScheduler {
  id?: number;
  is_auto?: boolean;
  means?: 'PriceRule';
  means_id?: number;
  review_id?: number;
  send_date?: Date;
  status?: ReviewRewardStatus;
  target_date?: Date;

  _priceRule?: PriceRule;
  _hover?: boolean = false;
}

export enum CheckRequiredStatus {
  WAITING_BENEFIT = 1,
  WAITING_PUBLISH = 2,
  PAYMENT_RESTRICTION = 3,
  WITH_KEYWORD = 4,
}

export enum ReviewPublishStatus {
  PUBLISH_WAITING = 0,
  PUBLISHED = 1,
  UNPUBLISHED = 2,
}

export enum ReviewUnpublishReasonCheckBox {
  CONTAIN_CONFIDENTIAL = 10,
  CONTAIN_LIBELOUS = 20,
  UNRELATED_CONTENT = 30,
  MISLEADING = 40,
  OTHER = 100,
}

export class ReviewUnpublishedReason {
  id?: number;
  review_id?: number;
  unpublished_reasons: ReviewUnpublishReasonCheckBox[] = [];
  detail_reason: string = ''; // OTHER 일 경우 세부 사유 텍스트
}

export class Review {
  id?: number;
  shop_id: number = 0;
  product_id: number = 0;
  line_item_id?: number;

  review_no?: number;

  line_item?: LineItem;
  created_at?: Date;
  updated_at?: Date;
  rating: number = 0; // 별점
  title: string = '';
  content: string = '';
  product_option?: string;

  is_photo: boolean = false; // 미디어첨부 여부
  is_video: boolean = false; // 미디어첨부 여부
  is_benefit: boolean = false; // 혜택지금여부
  is_comment: boolean = false; // 댓글작성 여부
  is_verified: boolean = false; // 리뷰 인증(주문건 존재) 여부
  like: number = 0;

  type: ReviewType = ReviewType.BASIC_REVIEW;
  // sns review 고유 아이디 -> media id 로 조회
  sns_review_id?: string;
  sns_user_id?: string;
  is_longterm_use_review?: boolean;

  writing_source: WritingSource = WritingSource.WEB;
  writing_platform: WritingPlatform = WritingPlatform.SHOPIFY;
  views: number = 0;
  helps: number = 0;

  non_order_benefit_restriction?: boolean; // True 가 제한
  total_benefit?: number; // 리뷰 혜택지급 총액
  benefit_reason = {}; // 리뷰 혜택 추가 항목 {지급사유:지급액}
  // 최대지급 가n능 금액
  // 지급가능한 상품금액의 퍼센테이지 계산 이후 금액이 n원 미만일 경우 n원 까지의 지급을 보장함

  is_imported?: boolean;
  source?: string; // 리뷰 출처 (!= 이관 출처)
  unpublished_at?: Date;
  publish_status?: ReviewPublishStatus;

  published_at?: Date;
  publish_reservation_date?: Date;

  // 고객 이름
  customer_name?: string;
  customer_firstname?: string;
  customer_lastname?: string;
  // 고객 핸드폰
  customer_phone?: string;
  // 고객 이메일
  customer_email?: string;
  // 고객 국가
  customer_country_code?: string;
  // 고객 회원가입일
  customer_created_at?: Date;
  // 고객 최근 구매일(마지막 구매일)
  customer_last_ordered_at?: Date;
  // 주문번호
  order_no?: string;

  writer_type?: WriterType;
  // 관리자에 의해 수정되었는지
  is_updated_by_admin?: boolean;
  // 이동된 리뷰인지
  is_moved?: boolean;
  // 복사된 리뷰인지
  is_copied?: boolean;
  // 신고된 리뷰인지
  is_reported?: boolean;
  // 소셜 공유된 리뷰인지
  is_social_shared?: boolean;
  // 키워드를 포함하는지
  is_keyword?: boolean;
  is_bookmark?: boolean;
  is_archived?: boolean;

  variant_id?: number;
  product_variant?: ProductVariant;

  check_required_status?: CheckRequiredStatus[] = []; // 확인 필요 상태 리스트.
  checked_status?: CheckRequiredStatus[] = []; // 확인한 상태 리스트 (이미 확인한 걸 또 위 컬럼에 넣을수없으니 만든 컬럼)
  customer_id?: number;

  customer?: Customer;

  review_media: ReviewMedia[] = [];

  review_comments: ReviewComment[] = [];
  review_memos: ReviewMemo[] = [];
  labels?: Label[];
  product?: Product;
  review_reward_schedulers: ReviewRewardScheduler[] = [];

  // review_fixeds: ReviewFixed

  // review_logs: ReviewLog

  review_import_log_id?: number;
  // review_import_log?: ReviewImportLog
  price_rule: PriceRule[] = [];

  selected: boolean = false;
  update: boolean = false;
  _hover: boolean = false;
  _publishStatusHover: boolean = false;

  // my Page
  review_benefit_provision_policy_detail?: ReviewBenefitProvisionPolicyDetail;
  actualBenefit?: number;
  is_finish?: boolean;

  name_private?: boolean;
  email_private?: boolean;
}

export interface ReviewManageReview extends Review {
  displayActionContinaer: boolean;
  displayActionType: 'fixedTop' | 'fixedBottom' | 'label' | 'etc' | 'publish' | 'unPublish' | 'archive';

  fixedTopProductActive: boolean;
  fixedBottomProductActive: boolean;

  fixedTopAllActive: boolean;
  fixedBottomAllActive: boolean;

  review_fixeds: ReviewFixed[];
}

export const AmazonTranslateUnableCountries = [
  'www.amazon.com.br',
  'www.amazon.cn',
  'www.amazon.fr',
  'www.amazon.it',
  'www.amazon.com.mx',
  'www.amazon.pl',
  'www.amazon.es',
  'www.amazon.com.tr',
];
