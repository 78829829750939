import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  private readonly gtmScriptSrc = 'https://www.googletagmanager.com/gtag/js?id=G-YFBMKCFH8D&l=dataLayer&cx=c';
  constructor(private router: Router, private titleService: Title, private renderer2: Renderer2, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }
          return routeTitle;
        }),
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleService.setTitle(`Alpha Review - ${title}`);
        }
      });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        event.url.includes('/write') && this.removeGTMScript();

        (<any>window).dataLayer.push({
          event: 'pageview',
          pagePath: event.urlAfterRedirects,
        });
      }
    });
  }

  private isScriptLoaded(src: string): boolean {
    return Array.from(document.head.querySelectorAll('script')).some((script) => script.src === src);
  }

  private removeScriptBySrc(src: string): void {
    const scriptElements = document.head.querySelectorAll('script');
    scriptElements.forEach((script) => {
      if (script.src === src) script.remove();
    });
  }

  private removeGTMScript(): void {
    const interval = setInterval(() => {
      if (this.isScriptLoaded(this.gtmScriptSrc)) {
        clearInterval(interval);
        this.removeScriptBySrc(this.gtmScriptSrc);
      }
    }, 1000);

    setTimeout(() => clearInterval(interval), 10000);
  }
}
