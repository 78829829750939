"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.respond = exports.request = exports.Action = void 0;
var helper_1 = require("../helper");
var types_1 = require("../types");
var Action;
(function (Action) {
    Action["REQUEST"] = "APP::SESSION_TOKEN::REQUEST";
    Action["RESPOND"] = "APP::SESSION_TOKEN::RESPOND";
})(Action = exports.Action || (exports.Action = {}));
function request() {
    return helper_1.actionWrapper({
        group: types_1.Group.SessionToken,
        type: Action.REQUEST,
    });
}
exports.request = request;
function respond(sessionToken) {
    return helper_1.actionWrapper({
        payload: sessionToken,
        group: types_1.Group.SessionToken,
        type: Action.RESPOND,
    });
}
exports.respond = respond;
