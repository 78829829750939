"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.permissionAction = exports.isErrorEventName = exports.throwError = exports.invalidOriginAction = exports.fromAction = exports.AppBridgeError = exports.AppActionType = exports.Action = void 0;
var types_1 = require("../types");
var helper_1 = require("../helper");
// Errors triggered in response to an action
var Action;
(function (Action) {
    Action["INVALID_ACTION"] = "APP::ERROR::INVALID_ACTION";
    Action["INVALID_ACTION_TYPE"] = "APP::ERROR::INVALID_ACTION_TYPE";
    Action["INVALID_PAYLOAD"] = "APP::ERROR::INVALID_PAYLOAD";
    Action["INVALID_OPTIONS"] = "APP::ERROR::INVALID_OPTIONS";
    Action["UNEXPECTED_ACTION"] = "APP::ERROR::UNEXPECTED_ACTION";
    Action["PERSISTENCE"] = "APP::ERROR::PERSISTENCE";
    Action["UNSUPPORTED_OPERATION"] = "APP::ERROR::UNSUPPORTED_OPERATION";
    Action["NETWORK"] = "APP::ERROR::NETWORK";
    Action["PERMISSION"] = "APP::ERROR::PERMISSION";
    Action["FAILED_AUTHENTICATION"] = "APP::ERROR::FAILED_AUTHENTICATION";
    Action["INVALID_ORIGIN"] = "APP::ERROR::INVALID_ORIGIN";
})(Action = exports.Action || (exports.Action = {}));
// Errors thrown in response to app setup
var AppActionType;
(function (AppActionType) {
    AppActionType["INVALID_CONFIG"] = "APP::ERROR::INVALID_CONFIG";
    AppActionType["MISSING_CONFIG"] = "APP::APP_ERROR::MISSING_CONFIG";
    AppActionType["MISSING_APP_BRIDGE_MIDDLEWARE"] = "APP::APP_ERROR::MISSING_APP_BRIDGE_MIDDLEWARE";
    AppActionType["WINDOW_UNDEFINED"] = "APP::APP_ERROR::WINDOW_UNDEFINED";
    AppActionType["REDUX_REINSTANTIATED"] = "APP::APP_ERROR::REDUX_REINSTANTIATED";
    AppActionType["MISSING_LOCAL_ORIGIN"] = "APP::APP_ERROR::MISSING_LOCAL_ORIGIN";
    AppActionType["MISSING_HOST_PROVIDER"] = "APP::APP_ERROR::MISSING_HOST_PROVIDER";
    AppActionType["MISSING_ROUTER_CONTEXT"] = "APP::APP_ERROR::MISSING_ROUTER_CONTEXT";
    AppActionType["MISSING_HISTORY_BLOCK"] = "APP::APP_ERROR::MISSING_HISTORY_BLOCK";
})(AppActionType = exports.AppActionType || (exports.AppActionType = {}));
var AppBridgeError = /** @class */ (function () {
    function AppBridgeError(message) {
        this.name = 'AppBridgeError';
        this.message = message;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        }
        else {
            this.stack = new Error(this.message).stack;
        }
    }
    return AppBridgeError;
}());
exports.AppBridgeError = AppBridgeError;
AppBridgeError.prototype = Object.create(Error.prototype);
function fromAction(message, type, action) {
    var errorMessage = message ? type + ": " + message : type;
    var error = new AppBridgeError(errorMessage);
    error.action = action;
    error.type = type;
    return error;
}
exports.fromAction = fromAction;
function invalidOriginAction(message) {
    return helper_1.actionWrapper({
        group: types_1.Group.Error,
        payload: {
            message: message,
            type: Action.INVALID_ORIGIN,
        },
        type: Action.INVALID_ORIGIN,
    });
}
exports.invalidOriginAction = invalidOriginAction;
function throwError() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    var type = args[0];
    var message;
    var action;
    if (typeof args[1] === 'string') {
        message = args[1];
    }
    else {
        action = args[1];
        message = args[2] || '';
    }
    throw fromAction(message, type, action);
}
exports.throwError = throwError;
function isErrorEventName(eventName) {
    var match = helper_1.findMatchInEnum(Action, eventName);
    return typeof match === 'string';
}
exports.isErrorEventName = isErrorEventName;
function errorActionWrapperWithId(type, action, message) {
    var castPayload = action.payload;
    return helper_1.actionWrapper({
        type: type,
        group: types_1.Group.Error,
        payload: {
            action: action,
            message: message,
            type: type,
            id: castPayload && castPayload.id ? castPayload.id : undefined,
        },
    });
}
function permissionAction(action, message) {
    return errorActionWrapperWithId(Action.PERMISSION, action, message || 'Action is not permitted');
}
exports.permissionAction = permissionAction;
