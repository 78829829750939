<article id="dialog-ali-import">
  <header class="admin-header-1" style="margin-bottom: 20px">Analyze AliExpress Reviews</header>
  <header class="admin-header-3">Tell us about AliExpress products you want to analyze</header>
  <summary class="admin-content-description">For optimal analysis, we recommend requesting products with 100 or more reviews.</summary>
  <section class="input-wrapper">
    <section class="input-scroll">
      <ng-container *ngFor="let input of urlList; let i = index">
        <div class="input-header">
          AliExpress Product {{ i + 1 }}
          <span *ngIf="i > 0" (click)="removeInput(i)" class="input-delete">Delete</span>
        </div>
        <div class="flex-wrapper input-wrapper" [ngClass]="{ error: input.title_error }" [@addOnlyAnimation]>
          <div class="url-header">
            Product Name
            <img *ngIf="input.title_error" matTooltip="Please fill in the product name." src="assets/icon/info_red.svg" />
          </div>
          <div class="flex-wrapper" style="gap: 6px; width: 100%">
            <input
              [(ngModel)]="urlList[i].title"
              (ngModelChange)="disabledImportBtnClick()"
              placeholder="Copy and paste the product name from AliExpress"
              class="admin-input"
              type="text"
              style="width: 100%"
            />
          </div>
        </div>
        <div class="flex-wrapper input-wrapper" [ngClass]="{ error: input.is_verify === false || input.url_error }" [@addOnlyAnimation]>
          <div class="url-header">
            Product URL
            <img *ngIf="input.error_reason" matTooltip="{{ input.error_reason }}" src="assets/icon/info_red.svg" />
            <img *ngIf="input.url_error && !input.error_reason" matTooltip="Please fill in the product URL." src="assets/icon/info_red.svg" />
          </div>
          <div class="flex-wrapper" style="gap: 6px; width: 100%">
            <input
              [(ngModel)]="urlList[i].url"
              (ngModelChange)="disabledImportBtnClick()"
              placeholder="Copy and paste the product URL from AliExpress"
              class="admin-input"
              type="text"
              style="width: 100%"
            />
          </div>
        </div>
      </ng-container>
    </section>
    <div *ngIf="urlList.length < 5 && urlList.length < data.limit" (click)="addInput()" class="btn-white" [@addOnlyAnimation]>+ Add a product to analyze</div>
  </section>
  <section class="flex-wrapper button-container">
    <div (click)="onCancel()" class="btn-white" style="height: 36px; width: 80px">Cancel</div>
    <div (click)="onConfirm()" class="btn-black" [ngClass]="{ disabled: !disabledImportBtnClick() }" style="width: 164px">Import and Analyze</div>
  </section>
</article>
