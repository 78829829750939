import { Injectable } from '@angular/core';
import { Shop, ShopAccountPosition, ShopAdminAccount } from '../models/shop/shop.model';
import { detectIncognito } from 'detectincognitojs';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor() {}

  randint = Math.random() * 100;

  rightNavigationHandler = new RightNavigationHandler();

  accessCodeKey = '_ac';
  shopAdminAccountKey = '_saa';
  shopIdKey = '_si';
  shopURLKey = '_su';
  shopAccessTokenKey = '_sit';
  shopPlatformIDKey = '_sp';
  shopDemoValue = '_sd';

  use_review?: boolean;
  use_ai?: boolean;

  _shopAdminAccount: ShopAdminAccount | undefined = undefined;

  setAdminAccountSession(shopAdminAccount: ShopAdminAccount, shop: Shop | undefined = undefined) {
    if (shopAdminAccount.shops?.length) {
      if (!shop) {
        shopAdminAccount.shop = shopAdminAccount.shops![0];
      } else {
        shopAdminAccount.shop = shop;
      }

      this.setShopId(shopAdminAccount.shop.id!.toString());
      this.setShopAccessCode(shopAdminAccount.shop.access_code!);
      this.setShopPlatformID(shopAdminAccount.shop.platform_id!.toString());
      this.ShopUrl = shopAdminAccount.shop.shop_url as string;

      this.ShopAdminAccount = shopAdminAccount;
      this.ShopAccessToken = shopAdminAccount.access_token;
    }

    return shopAdminAccount;
  }

  getShopId(): number {
    return sessionStorage.getItem(this.shopIdKey) as any as number;
  }

  setShopId(shopId: string) {
    sessionStorage.setItem(this.shopIdKey, shopId);
  }

  getShopAccessCode() {
    return sessionStorage.getItem(this.accessCodeKey) as any as string;
  }

  setShopAccessCode(accessCode: string) {
    sessionStorage.setItem(this.accessCodeKey, accessCode);
  }

  getShopPlatformID() {
    return sessionStorage.getItem(this.shopPlatformIDKey) as any as number;
  }

  setShopPlatformID(shopPlatformID: string) {
    sessionStorage.setItem(this.shopPlatformIDKey, shopPlatformID);
  }
  get ShopUrl() {
    return sessionStorage.getItem(this.shopURLKey)!;
  }

  set ShopUrl(shopUrl: string) {
    sessionStorage.setItem(this.shopURLKey, shopUrl);
  }

  get ShopAdminAccount() {
    if (!this._shopAdminAccount) {
      this._shopAdminAccount = JSON.parse(sessionStorage.getItem(this.shopAdminAccountKey)!) as any as ShopAdminAccount;
    }
    return this._shopAdminAccount;
  }

  set ShopAdminAccount(shopAdminAccount: ShopAdminAccount) {
    this._shopAdminAccount = shopAdminAccount;
    sessionStorage.setItem(this.shopAdminAccountKey, JSON.stringify(shopAdminAccount));
  }

  get OnwerAccountDo(): boolean {
    return this._shopAdminAccount?.position == ShopAccountPosition.OWNER;
  }

  get ManagerAccountDo(): boolean {
    return this._shopAdminAccount?.position == ShopAccountPosition.MANAGER;
  }

  get ShopAccessToken() {
    return sessionStorage.getItem(this.shopAccessTokenKey)!;
  }

  set ShopAccessToken(ShopAccessToken: string) {
    sessionStorage.setItem(this.shopAccessTokenKey, ShopAccessToken);
  }

  clearAccessCode() {
    sessionStorage.removeItem(this.accessCodeKey);
  }

  get isDemo(): boolean {
    return sessionStorage.getItem(this.shopDemoValue) == 'true';
  }

  set isDemo(isDemo: boolean) {
    sessionStorage.setItem(this.shopDemoValue, isDemo.toString());
  }
}

class RightNavigationHandler {
  constructor() {
    try {
      detectIncognito().then((result) => {
        this.isPrivate = result.isPrivate;
      });
    } catch (error) {}
  }
  isPrivate: boolean = false;

  guideIframeSrc: string = '';

  private _selectedIndex: number = 0;

  get selectedIndex(): number {
    return this._selectedIndex;
  }

  set selectedIndex(index: any) {
    this._selectedIndex = index;
  }

  closedLeft: string = 'calc(100% - 43px)';
  openedLeft: string = 'calc(100% - 500px)';
  active: boolean = false;
  left: string = this.closedLeft;
}
