<div id="landing-page" (scroll)="onWindowScroll()">
  <header class="landing-gnb" [ngClass]="{ scroll: scrolled }">
    <landing-gnb></landing-gnb>
  </header>

  <body class="landing-body" style="margin-top: 60px">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
    <sub-footer *ngIf="landingService.idx != 'main'"></sub-footer>
  </body>

  <footer class="landing-footer">
    <landing-footer></landing-footer>
  </footer>
</div>
