<main id="landing-marketing">
  <article class="marketing-visual">
    <span class="landing-header-label mint">Showcase Reviews</span>
    <header class="landing-header white">
      Amplify the Reach of Reviews and <br />
      <span class="marketing-mint">Increase Traffic and Conversion</span>
    </header>
    <summary class="landing-summary">
      Share your happy customer reviews on <br class="mobr" />
      social media, <br />
      and let your customers share <br class="mobr" />
      their reviews on social media. <br />
      Moreover, <br class="mobr" />
      you can display product reviews in Google <br class="mobr" />
      Shopping <br />
      and mark up ratings and stars in <br class="mobr" />
      Google searches. <br />
      <span *ngIf="!landingService.mobile">
        By amplifying the reach of reviews, you can increase traffic and boost
        the conversion rate.
      </span>
    </summary>

    <img src="assets/image/landing/marketing-main.png" />
  </article>
  <!-- marketing visual end -->

  <article class="marketing-marketers">
    <span class="landing-header-label navy">✨ AlphaReview Exclusive</span>
    <header class="landing-header">
      Let Customers <br class="mobr" />
      Be Your Best Marketers
    </header>
    <summary class="landing-summary">
      AlphaReview encourages customers to <br class="mobr" />
      write not only basic product reviews but <br class="mobr" />
      also social reviews. <br />
      When a customer posts <br class="mobr" />
      a review on Instagram, we automatically <br class="mobr" />
      bring it to your online store.
    </summary>
    <img src="assets/image/landing/marketing-marketers.png" />
  </article>

  <article class="marketing-instagram">
    <header class="landing-header">
      Bring in Instagram Posts and Enrich Your Online Store
    </header>
    <summary class="landing-summary" *ngIf="!landingService.mobile">
      AlphaReview enables you to bring in Instagram posts related to your
      products <br />
      written by influencers (ambassadors) or loyal customers to your online
      store as reviews. <br />
      You can easily search Instagram posts, including your products, on
      AlphaReview admin.
    </summary>
    <img src="assets/image/landing/marketing-instagram.png" />
  </article>

  <article class="marketing-viral">
    <header class="landing-header">
      Make It Go Viral on <br class="mobr" />
      Social Media with Reviews
    </header>
    <summary class="landing-summary" *ngIf="!landingService.mobile">
      Nowadays, more than half of consumers use social media channels to
      research products before <br />
      buying them. Therefore, sharing your reviews on social media can help you
      build trust and drive more traffic <br />
      to your online store. AlphaReview helps you to share them on social media
      automatically or manually.
    </summary>
    <img src="assets/image/landing/marketing-viral.png" />
  </article>

  <article class="marketing-result">
    <header class="landing-header">
      Make Your Products Stand Out from Google Search Results
    </header>
    <summary class="landing-summary" *ngIf="!landingService.mobile">
      Through AlphaReview's Google integration, you can mark up your reviews'
      ratings and stars <br />
      in Google Search with rich snippets. Moreover, you can display product
      reviews in Google shopping <br />
      to boost the traffic, conversion rate, and CTR. With our Google
      integration features, <br />
      you can highlight your product listing from Google search results.
    </summary>
    <img src="assets/image/landing/marketing-result.png" />
  </article>
</main>
