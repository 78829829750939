"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Fullscreen = exports.exit = exports.enter = exports.Action = void 0;
var helper_1 = require("../helper");
var ActionSet_1 = require("../ActionSet");
var types_1 = require("../types");
/**
 * Fullscreen action type enum
 * @remarks includes the action prefix and group
 *
 * @beta
 */
var Action;
(function (Action) {
    Action["ENTER"] = "APP::FULLSCREEN::ENTER";
    Action["EXIT"] = "APP::FULLSCREEN::EXIT";
})(Action = exports.Action || (exports.Action = {}));
function enter() {
    return helper_1.actionWrapper({
        group: types_1.Group.Fullscreen,
        type: Action.ENTER,
    });
}
exports.enter = enter;
function exit() {
    return helper_1.actionWrapper({
        group: types_1.Group.Fullscreen,
        type: Action.EXIT,
    });
}
exports.exit = exit;
/**
 * Fullscreen action set
 * @beta
 */
var Fullscreen = /** @class */ (function (_super) {
    __extends(Fullscreen, _super);
    /**
     * Returns a new instance of a Fullscreen action set
     * @param app the client application
     */
    function Fullscreen(app) {
        return _super.call(this, app, types_1.Group.Fullscreen, types_1.Group.Fullscreen) || this;
    }
    Object.defineProperty(Fullscreen.prototype, "payload", {
        /**
         * Returns the action set payload
         */
        get: function () {
            return { id: this.id };
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Dispatches a given action with the action set payload
     * @param action the action enum
     * @returns the action set instance
     */
    Fullscreen.prototype.dispatch = function (action) {
        this.app.dispatch(helper_1.actionWrapper({
            group: this.group,
            type: action,
            payload: this.payload,
        }));
        return this;
    };
    return Fullscreen;
}(ActionSet_1.ActionSet));
exports.Fullscreen = Fullscreen;
