import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'alpha-textarea',
  templateUrl: './custom-textarea.component.html',
  styleUrls: ['./custom-textarea.component.less'],
})
export class CustomTextareaComponent implements OnInit {
  constructor() {}
  @Input() sourceString: string = '';
  @Output() sourceStringChange = new EventEmitter<string>();

  @Input() placeholder: string = 'Leave a reply';

  @Input() personalVars: string[] = [
    'buyer_name', // (구매자 풀네임)
    'buyer_first_name', // (구매자 order_name (쇼피파이 order dashboard 내에 있는 order 이름)
    'n_days_ago', // (주문이 완료된 날로부터 지금까지의 일수)
    'prodict_title', // (상품명)
    'shop_name', // (쇼핑몰명)
    'product_stars', // (상품 평점)
    'product_reviews_count', // (상품 리뷰 개수)
    'last_review_date', // (해당 상품에 대해 리뷰를 최초 작성한 일자)
  ];

  @Input() height: string = '100%';
  @Input() maxStringLength: number = 150;
  @Input() displayFooter: boolean = true;
  @Input() displayEmojiPicker: boolean = true;
  @Input() displayPersonalVarsPicker: boolean = true;
  @Input() displayMaxLengthLabel: boolean = true;
  @Input() minimumDisplay?: boolean;
  @Input() outerVarsPicker: boolean = false;

  ngOnInit(): void {}

  openEmojiPicker = false;
  openPersonalvarsPicker = false;
  openOuterVarsPicker = false;

  mouseLeave() {
    this.openEmojiPicker = false;
    this.openPersonalvarsPicker = false;
  }

  addEmoji(event: any) {
    let emoji = event.emoji.native;
    if (this.sourceString.length <= this.maxStringLength - 2) {
      this.sourceString = this.sourceString + emoji;
    }
  }

  addPersonalVars(str: string) {
    if ((this.sourceString + str).length + 2 <= this.maxStringLength) {
      this.sourceString = this.sourceString + '{' + str + '}';
      this.changeSourceString();
      this.openPersonalvarsPicker = false;
    }
  }

  checkString() {
    if (this.sourceString.split('').length > this.maxStringLength) {
      this.sourceString = this.sourceString.slice(0, this.sourceString.split('').length);
    }
  }

  changeSourceString() {
    this.sourceStringChange.emit(this.sourceString);
  }

  getStringLength(str: string) {
    return str ? str.length : 0;
  }
}
