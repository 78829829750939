import { Component, OnInit } from '@angular/core';
import { LandingService } from '../../landing.service';

@Component({
  selector: 'landing-footer',
  templateUrl: './landing-footer.component.html',
  styleUrls: ['./landing-footer.component.less', '../../home.component.less'],
})
export class LandingFooterComponent implements OnInit {
  constructor(public landingService: LandingService) {}

  ngOnInit(): void {}
}
