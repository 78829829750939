<article id="dialog-sample-products">
  <div class="ai-icon">AI</div>
  <header class="admin-header-1">Try With Sample Products</header>
  <summary class="admin-content-description" style="padding: 5px 0 18px">
    We've prepared a few products for you to try out. Experience our analysis with these samples and see the insights we can uncover for you.
  </summary>

  <section class="gray-border-wrapper">
    @for (product of aiService.dummyProducts; track $index) {
    <div class="flex-wrapper dummy-container" style="gap: 10px">
      <img src="{{ product.img }}" class="dummy-img" />
      <div class="dummy-info">
        <div class="dummy-category">{{ product.category }}</div>
        <div class="dummy-title">{{ product.title }}</div>
      </div>
    </div>
    }
  </section>
  <section class="flex-wrapper btn-border">
    <div (click)="onCancel()" class="btn-white" style="width: 80px; height: 36px">Cancel</div>
    <div (click)="onStart()" class="btn-black" style="width: 127px">Start Analysis</div>
  </section>
</article>
