"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.AppLink = exports.update = exports.Action = void 0;
var AppLink_1 = require("@shopify/app-bridge-core/actions/Link/AppLink");
Object.defineProperty(exports, "AppLink", { enumerable: true, get: function () { return AppLink_1.AppLink; } });
var AppLink_2 = require("@shopify/app-bridge-core/actions/Link/AppLink");
Object.defineProperty(exports, "Action", { enumerable: true, get: function () { return AppLink_2.Action; } });
Object.defineProperty(exports, "update", { enumerable: true, get: function () { return AppLink_2.update; } });
function create(app, options) {
    return new AppLink_1.AppLink(app, options);
}
exports.create = create;
