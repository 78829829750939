"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.respond = exports.Action = void 0;
var helper_1 = require("../helper");
var types_1 = require("../types");
var Action;
(function (Action) {
    Action["REQUEST"] = "APP::AUTH_CODE::REQUEST";
    Action["RESPOND"] = "APP::AUTH_CODE::RESPOND";
})(Action = exports.Action || (exports.Action = {}));
function respond(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.AuthCode,
        type: Action.RESPOND,
    });
}
exports.respond = respond;
