import { Customer } from '../../customer/customer.model';
import { MessagingSettingTypeDetail } from '../../shop/review/options/messaging';
import { LineItem } from '../line-item.model';
import { Order } from '../order.model';
export interface RequestsOrder extends Order {
  order_no: number;
  shipment_updated_at: Date;
  line_items: LineItem[];
  customer: Customer;
  messaging_log: MessagingLog[];
  request_status: RequestStatus;
  platform_id: number;
  is_manual: boolean;
  _hover: boolean;
}

export interface Requests {
  Order: RequestsOrder;
  delivery_datetime: Date;
  delivery_status: DeliveryShipmentStatus;
}
export interface MessagingLog {
  id: number;
  failure_reason: string;
  send_channel: SendingChannel;
  send_type_detail: MessagingSettingTypeDetail;
  send_datetime: Date;
  target_datetime: Date;
  sending_status: SendingStatus;
  sent_content: string;

  hover: boolean;
}

export interface RequestStatusLength {
  all: number;
  pending: number;
  sent: number;
  reviewed: number;
  not_sent: number;
}
export enum SendingChannel {
  EMAIL = 1,
  SMS = 2,
  WEBPUSH = 3,
}

export enum RequestStatus {
  ALL = 0,
  PENDING = 10, // 발송대기
  SENT = 20, // 발송완료
  REVIEWED = 30, // 리뷰 작성됨, 미디어 리마인더에서는 미디어 리뷰 작성됨
  NOT_SENT = 40, // 발송 실패
}

export enum SendingStatus {
  WAITING = 0, // 발송 대기
  SUCCESS = 100, // 전송 성공
  FAILURE = 200, // 전송 살패
  CANCEL = 300, // 전송 취소
  CHANNEL_OFF = 400, // review request 설정 상 해당 발송 채널에 대해 발송 안함 인경우
  UNABLE = 500, // Review&Rewards policy 설정 상 리뷰 작성 유도가 불가능 한 경우
}

export enum DeliveryShipmentStatus {
  NONE = 0, // 상태없음
  DELIVERING = 1, // 배송중
  DELIVERED = 2, // 배송완료
  FAILURE = 3, // 배송실패
}
