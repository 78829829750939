<article id="admin-login">
  <!-- 데모 로그인 중일 때 표시될 메시지 -->
  <div *ngIf="isdemoLoading">
    <section class="demo-message-container" [ngStyle]="{ top: this.top + '%', left: this.left + '%' }">
      <div class="demo-text-box"><br />Heading to the AlphaReview demo page, <br />a journey towards your business success!🚀<br /></div>
      <span class="loader"></span>
    </section>
  </div>

  <div *ngIf="!isdemoLoading">
    <div class="backdrop" [ngClass]="{ init: init }"></div>
    <section class="login-input-container" [ngStyle]="{ top: this.top + '%', left: this.left + '%' }">
      <header class="admin-header-2">Admin Login</header>
      <section style="margin-top: 20px">
        <div class="input-container">
          <div class="admin-header-3">ID (Email)</div>
          <input class="admin-input" type="text" placeholder="ID (Email)" autofocus (keydown.enter)="onLogin()" [(ngModel)]="id" />
        </div>
        <div class="input-container">
          <div class="admin-header-3" style="justify-content: space-between">
            <div>Password</div>
            <div class="reset-password" (click)="openDialogAccountResetPassword()">Forgot your password?</div>
          </div>
          <input class="admin-input" type="password" placeholder="Password" (keydown.enter)="onLogin()" [(ngModel)]="password" />
        </div>
      </section>
      <section class="flex-wrapper" style="margin-top: 10px">
        <div></div>
        <div>
          <button [disabled]="doLogin" class="btn-confirm" (click)="onLogin()">Login</button>
        </div>
      </section>
    </section>
  </div>
</article>
