import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AiService } from 'src/app/service/ai.service';
import { PaginationHandler } from 'src/app/shared/handler/pagination-handler';
import { DialogAliImportComponent } from './ali-dialog/dialog-ali-import/dialog-ali-import.component';
import { ReviewAppPlatform } from 'src/app/models/shop/review/options/review-import.model';
import { finalize, switchMap, take, takeUntil, takeWhile } from 'rxjs/operators';
import { AnalysisLogStatus, AnalysisLogStatusValue } from 'src/app/models/ai/ai.model';
import { Subject, interval } from 'rxjs';
import moment from 'moment';
import { inOutAnimation } from '../../animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DialogAiImportDummyComponent } from './ali-dialog/dialog-ai-import-dummy/dialog-ai-import-dummy.component';
import { LeftNavigationBarService } from '../../layout/left-navigation-bar/left-navigation-bar.service';

@Component({
  selector: 'app-ai-aliexpress',
  templateUrl: './ai-aliexpress.component.html',
  styleUrls: ['./ai-aliexpress.component.less'],
  animations: [inOutAnimation],
})
export class AiAliexpressComponent implements OnInit {
  @ViewChild('paginator') paginator!: MatPaginator;
  private destroy$ = new Subject<void>();
  constructor(public aiService: AiService, private lnbService: LeftNavigationBarService, private dialog: MatDialog, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.checkAnalysisStatus();
    this.lnbService.selectedApp = undefined;
  }

  ngAfterViewInit(): void {
    this.aiService.paginationHandler = new PaginationHandler(this.paginator, 0, 10);
    this.aiService.getAnalysisList();

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  analysisStatus!: AnalysisLogStatusValue;
  remainingSeconds: any;
  limitStartDateTime?: Date;
  showTooltip?: boolean;
  firstLoading: boolean = false;

  openDialogAliImport(dummy?: boolean) {
    const dialogRef = this.dialog.open(DialogAliImportComponent, {
      width: '600px',
      maxHeight: '70vh',
      data: { limit: this.analysisStatus.remaining_count, dummy: dummy },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.checkAnalysisStatus();
    });
  }

  onClickNewBtn(analysisStatus: AnalysisLogStatusValue) {
    if (analysisStatus.status === AnalysisLogStatus.ANALYSIS_LIMITED) this.startTimer();
    if (analysisStatus.status === AnalysisLogStatus.ANALYSIS_COMPLETED) this.openDialogAliImport();
    return;
  }

  checkAnalysisStatus() {
    this.aiService
      .getAnalysisStatus(ReviewAppPlatform.ALI_EXPRESS)
      .pipe(
        switchMap((response) => {
          this.analysisStatus = response.body;
          this.limitStartDateTime = moment(this.analysisStatus.limit_start_datetime).toDate();

          if (this.analysisStatus.status == AnalysisLogStatus.ANALYSIS_IN_PROGRESS) {
            if (this.aiService.isEmpty) this.firstLoading = true;
            return interval(3000).pipe(
              switchMap(() => this.aiService.getAnalysisStatus(ReviewAppPlatform.ALI_EXPRESS)),
              takeWhile((nextResponse) => nextResponse.body.status === AnalysisLogStatus.ANALYSIS_IN_PROGRESS),
              finalize(() => {
                this.aiService.getAnalysisStatus(ReviewAppPlatform.ALI_EXPRESS).subscribe((response) => {
                  this.analysisStatus = response.body;
                  this.firstLoading = false;
                });
                this.aiService.getAnalysisList();
              }),
            );
          } else {
            return interval(0).pipe(take(1));
          }
        }),
      )
      .subscribe();
  }

  startTimer(): void {
    this.showTooltip = true;
    this.calculateTimeDifference();

    const destroy$ = new Subject<void>();

    interval(1000)
      .pipe(takeUntil(destroy$)) // destroy$ 이벤트가 발생시 구독 종료
      .subscribe(() => {
        this.calculateTimeDifference();
      });

    this.destroy$ = destroy$;
  }

  stopTimer(): void {
    if (this.destroy$) {
      this.destroy$.next(); // Subject에 이벤트 발생
      this.destroy$.complete(); // Subject를 완전히 종료
    }
    this.showTooltip = false;
  }

  formatTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${this.padNumber(hours)}:${this.padNumber(minutes)}:${this.padNumber(remainingSeconds)}`;
  }

  padNumber(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }

  calculateTimeDifference(): void {
    const currentDate = new Date();
    const limitStartDateTime = this.limitStartDateTime!;
    const futureDateTime = new Date(limitStartDateTime.getTime() + 24 * 60 * 60 * 1000);
    const differenceInSeconds = Math.max(0, Math.floor((futureDateTime.getTime() - currentDate.getTime()) / 1000));
    this.remainingSeconds = this.formatTime(differenceInSeconds);
  }

  openDialogSampleProducts() {
    const dialogRef = this.dialog.open(DialogAiImportDummyComponent, {
      width: '530px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.openDialogAliImport(result);
    });
  }

  get AnalysisLogStatus(): typeof AnalysisLogStatus {
    return AnalysisLogStatus;
  }
}
function tab(): import('rxjs').OperatorFunction<import('@angular/material/paginator').PageEvent | null, unknown> {
  throw new Error('Function not implemented.');
}
