<section id="product-reviews-nav" class="app-nav">
  <div class="lnb-main">
    <div class="lnb-title">
      <img src="assets/icon/back_btn_gray.svg" (click)="lnbService.selectedApp = undefined" style="cursor: pointer" />
      Product Reviews
    </div>
    <mat-accordion hideToggle>
      <mat-expansion-panel *ngFor="let mainCategory of LNBContent" class="lnb-main-category">
        <mat-expansion-panel-header
          (click)="onClickCategory(mainCategory, mainCategory.value)"
          [ngClass]="{ active: mainCategory.value == genericService.LNBCategory || mainCategory.name == genericService.LNBCategory }"
        >
          <header class="flex-wrapper" style="width: 100%">
            <div class="lnb-main-title">
              <img src="{{ mainCategory.icon }}" />
              {{ mainCategory.name }}
            </div>
            @if (mainCategory.value !== 'home' && mainCategory.value !== 'analytics') {
            <img src="assets/icon/arrow_right_dark.svg" class="lnb-arrow" />
            }
          </header>
        </mat-expansion-panel-header>
        <div
          class="lnb-sub-title"
          *ngFor="let subCategory of mainCategory.content"
          (click)="onClickCategory(subCategory, mainCategory.value + '/' + subCategory.value)"
          [ngClass]="{ active: subCategory.name == genericService.LNBCategory || subCategory.value == genericService.LNBCategory }"
        >
          {{ subCategory.name }}
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>
