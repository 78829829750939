import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { AdminService } from './admin.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  constructor(private http: HttpClient, private adminService: AdminService, private _snackBar: MatSnackBar) {}

  get shop_id(): number {
    return this.adminService.getShopId() as number;
  }
  get shop_access_code(): string {
    return this.adminService.getShopAccessCode() as string;
  }

  baseUrl = environment.baseURL;

  shopDomainList: string[] = ['shops', 'messaging', 'orders', 'customers', 'auth', 'super-admin', 'integration', 'billing', 'browse-booster-shops'];

  reviewDomainList: string[] = ['products', 'reviews', 'review-benefit', 'social', 'widget', 'ai', 'list-designer'];

  makeOptions(
    headers: {},
    params: {},
    etc: {
      observe?: 'events' | 'response';
      contentType?: 'application/json' | 'multipart/form-data';
    } = {
      observe: 'response',
      contentType: 'application/json',
    },
    useSessionToken: boolean = false,
    sessionToken?: string,
  ) {
    const httpOptions: Object = {
      headers: this.request_headers(headers, etc.contentType, useSessionToken, sessionToken),
      params: params,
      reportProgress: true,
      withCredentials: true,
      responseType: 'json' as const,
      observe: etc.observe,
    };

    return httpOptions;
  }

  makeUrl(url: string) {
    // return `https://e3f994b7d331.ngrok.app/${url}`;
    // return `http://localhost/${url}`;
    if (this.shopDomainList.indexOf(url.split('/')[0]) >= 0) {
      return `https://shop.${this.baseUrl}/${url}`;
    } else {
      return `https://review.${this.baseUrl}/${url}`;
    }
  }

  openSnackBar(message: string, action: string = 'Ok') {
    this._snackBar.open(message, action, {
      duration: 2 * 1000,
    });
  }

  handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // 클라이언트나 네트워크 문제로 발생한 에러.
      console.error('An error occurred:', error.error.message);
      // this.openSnackBar(`An error occurred: ` + error.error.message)
    } else {
      // 백엔드에서 실패한 것으로 보낸 에러.
      // 요청으로 받은 에러 객체를 확인하면 원인을 확인할 수 있습니다.
      console.error(`Backend returned code ${error.status}, body was: `, error.error);
      // this.openSnackBar(`Backend returned code ${error.status}, body was: ` + error.error)
    }
    // 사용자가 이해할 수 있는 에러 메시지를 반환합니다.
    return throwError(error);
  }

  validateResponse(response: HttpResponse<any>) {
    if (response.ok) {
      return true;
    }
    return false;
  }

  select(url: string, params: {} = {}, headers = {}, useSessionToken: boolean = false, sessionToken?: string): Observable<any> {
    let options = this.makeOptions(headers, params, { observe: 'response' }, useSessionToken, sessionToken);

    return this.http
      .get<any>(this.makeUrl(url), options)
      .pipe
      // catchError(this.handleError)
      ();
  }

  getText(
    url: string,
    params: {} = {},
    headers: {} | HttpHeaders = {
      Accept: 'text/html, application/xhtml+xml, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  ) {
    let options = this.makeOptions(headers, params, { observe: 'response' });

    return this.http.get(this.makeUrl(url), {
      responseType: 'text',
      params: params,
    });
  }
  postText(
    url: string,
    payload: {} = {},
    params: {} = {},
    headers: {} | HttpHeaders = {
      Accept: 'text/html, application/xhtml+xml, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  ) {
    let options = this.makeOptions(headers, params, { observe: 'response' });

    return this.http.post(this.makeUrl(url), payload, {
      responseType: 'text',
      params: params,
    });
  }

  addBody(options: any, payload: any): any {
    options.body = payload;
    return options;
  }

  // create : body parameter 추가
  create(url: string, payload: {} = {}, params: {} = {}, headers = {}, useSessionToken: boolean = false, sessionToken?: string): Observable<HttpResponse<any>> {
    let options = this.makeOptions(headers, params, { observe: 'response' }, useSessionToken, sessionToken);

    return this.http
      .post<any>(this.makeUrl(url), payload, options)
      .pipe
      // catchError(this.handleError)
      ();
  }

  update(url: string, payload: {} = {}, params: {} = {}, headers = {}, useSessionToken: boolean = false, sessionToken?: string): Observable<any> {
    let options = this.makeOptions(headers, params, {}, useSessionToken, sessionToken);

    return this.http.put<any>(this.makeUrl(url), payload, options).pipe(catchError(this.handleError));
  }

  delete(url: string, params: {} = {}, headers = {}, useSessionToken: boolean = false, sessionToken?: string): Observable<any> {
    let options = this.makeOptions(headers, params, {}, useSessionToken, sessionToken);

    return this.http.delete<any>(this.makeUrl(url), options).pipe(catchError(this.handleError));
  }

  // patch : body parameter 추가
  patch(url: string, payload: {} = {}, params: {} = {}, headers = {}): Observable<HttpResponse<any>> {
    let options = this.makeOptions(headers, params);

    return this.http.patch<any>(this.makeUrl(url), payload, options).pipe(catchError(this.handleError));
  }

  form(url: string, payload: FormData = new FormData(), params: {} = {}, headers = {}): Observable<any> {
    let options = this.makeOptions(headers, params, {
      observe: 'events',
      contentType: 'multipart/form-data',
    });

    return this.http.post<any>(this.makeUrl(url), payload, options).pipe(
      map((event) => this.getEventMessage(event as HttpEvent<any>, payload.get('file') as File)),
      tap((message) => this.showProgress(message)),
      last(), // 최종 메시지는 실행한 컨텍스트로 반환합니다.
      catchError(this.handleError),
    );
  }

  formPatch(url: string, payload: FormData = new FormData(), params: {} = {}, headers = {}): Observable<any> {
    let options = this.makeOptions(headers, params, {
      observe: 'events',
      contentType: 'multipart/form-data',
    });

    return this.http.patch<any>(this.makeUrl(url), payload, options).pipe(
      map((event) => this.getEventMessage(event as HttpEvent<any>, payload.get('file') as File)),
      tap((message) => this.showProgress(message)),
      last(), // 최종 메시지는 실행한 컨텍스트로 반환합니다.
      catchError(this.handleError),
    );
  }

  formUpdate(url: string, payload: FormData = new FormData(), params: {} = {}, headers = {}): Observable<any> {
    let options = this.makeOptions(headers, params, {
      observe: 'events',
      contentType: 'multipart/form-data',
    });

    return this.http.put<any>(this.makeUrl(url), payload, options).pipe(
      map((event) => this.getEventMessage(event as HttpEvent<any>, payload.get('file') as File)),
      tap((message) => this.showProgress(message)),
      last(), // 최종 메시지는 실행한 컨텍스트로 반환합니다.
      catchError(this.handleError),
    );
  }

  showProgress(message: string) {
    return message;
  }

  private getEventMessage(event: HttpEvent<any>, file: File) {
    if (file) {
      switch (event.type) {
        case HttpEventType.Sent:
          return `Uploading file "${file.name}" of size ${file.size}.`;

        case HttpEventType.UploadProgress:
          // 진행률을 % 형식으로 변환
          const percentDone = Math.round((100 * event.loaded) / (event.total ?? 0));

          return `File "${file.name}" is ${percentDone}% uploaded.`;

        case HttpEventType.Response:
          return event.body;

        default:
          return `File "${file.name}" surprising upload event: ${event.type}.`;
      }
    }
  }

  request_options(params: {}, headers: { [header: string]: string | string[] }) {
    const httpOptions = {
      headers: headers,
      params: params,
      reportProgress: true,
      withCredentials: true,
      observe: 'response' as const,
      responseType: 'json' as const,
    };
    return httpOptions;
  }

  request_headers(headers: { [header: string]: string | string[] }, contentType: string = 'application/json', useSessionToken: boolean = false, sessionToken?: string) {
    if (useSessionToken && sessionToken) headers['Authorization'] = sessionToken;
    else headers['Authorization'] = this.adminService.ShopAccessToken ? this.adminService.ShopAccessToken : '';

    let _headers = new HttpHeaders({ ...headers });
    return _headers;
  }

  request_param(params: any = null) {
    let _params: HttpParams = new HttpParams();
    if (params) {
      for (let _key in params) {
        let _value = params[_key];
        _params.append(_key, _value);
      }
    }
    return _params;
  }

  getCookie(name: string) {
    let cookieArray: Array<string> = document.cookie.split(';');
    let cookieArrayLength: number = cookieArray.length;
    let cookieName = `${name}=`;
    let cookie: string;

    for (let i: number = 0; i < cookieArrayLength; i += 1) {
      cookie = cookieArray[i].replace(/^\s+/g, ''); // \s: 공백 문자, 문자열 내 공백문자를 삭제
      if (cookie.indexOf(cookieName) == 0) {
        // name과 cookieName이 일치할 경우 (name으로 cookieName이 시작될 경우)
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  }
}
