import { Component } from '@angular/core';
import { OnboardingService } from '../../onboarding/onboarding.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AlphaApps } from 'src/app/models/shop/profile/store-detail.model';
import { AdminService } from 'src/app/service/admin.service';

@Component({
  selector: 'app-dialog-start-browse-booster',
  standalone: true,
  imports: [],
  templateUrl: './dialog-start-browse-booster.component.html',
  styleUrl: './dialog-start-browse-booster.component.less',
})
export class DialogStartBrowseBoosterComponent {
  constructor(private adminService: AdminService, private dialogRef: MatDialogRef<DialogStartBrowseBoosterComponent>, private onboardingService: OnboardingService) {
    this.adminService.setShopAccessCode(`BB${this.adminService.getShopAccessCode()?.substring(2)}`);
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.onboardingService.setShopDetailUseApp(AlphaApps.BROWSE_BOOSTER);
    this.dialogRef.close(true);
  }
}
