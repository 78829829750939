<article id="customer-service-notice-detail">
  <section *ngIf="data.update">
    <header *ngIf="data.update" class="admin-header-4" style="gap: 33px; margin-bottom: 10px">
      <div>Title</div>
      <input [(ngModel)]="data.title" placeholder="Enter the title" class="admin-input" style="flex-grow: 1" />
    </header>

    <!-- <section
      style="
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 24px;
        font-size: 14px;
      "
    >
      <div>
        <alpha-checkbox>Set Mobile Version</alpha-checkbox>
      </div>
      <div class="toggle-pc-mobile-container">
        <div class="toggle-pc-mobile active">PC</div>
        <div class="toggle-pc-mobile">Mobile</div>
      </div>
    </section> -->

    <div *ngIf="deviceType == 'PC'" class="preview-content" [ngxSummernote]="config" (imageUpload)="onImageUpload($event)" [(ngModel)]="data.content_pc"></div>

    <div *ngIf="deviceType == 'MO'" class="preview-content" [ngxSummernote]="config" (imageUpload)="onImageUpload($event)" [(ngModel)]="data.content_mobile"></div>
    <div class="flex-wrapper" style="margin-top: 10px">
      <section class="option-container">
        <div class="option-title">Posting Period</div>
        <div class="option-content">
          <mat-form-field appearance="outline">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Start date" [(ngModel)]="data.posting_start" />
              <input matEndDate placeholder="End date" [(ngModel)]="data.posting_end" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </section>

      <section class="flex-wrapper" style="gap: 10px">
        <div (click)="onNoClick()" class="btn-white" style="width: 80px; height: 36px">Cancel</div>
        <div (click)="onConfirm()" [ngClass]="{ disabled: !checkValidNotice() }" class="btn-black" style="width: 80px">Save</div>
      </section>
    </div>
  </section>

  <article *ngIf="!data.update">
    <header>
      <div class="preview-header">Preview</div>
      <div class="preview-header-content">{{ data.title }}</div>
    </header>

    <section *ngIf="deviceType == 'PC'" class="preview-content" [ngxSummernoteView]="data.content_pc!" style="border: none"></section>
    <section *ngIf="deviceType == 'MO'" class="preview-content" [ngxSummernoteView]="data.content_mobile!" style="border: none"></section>

    <div class="flex-wrapper">
      <section class="option-container">
        <div class="option-title">Posting Period</div>
        <div class="option-inner" style="display: flex; align-items: center; gap: 10px">
          <div class="option-content">Start Date</div>
          <alpha-datepicker [(selected)]="data.posting_start"></alpha-datepicker>
          <div>~</div>
          <div class="option-content">End Date</div>
          <alpha-datepicker [(selected)]="data.posting_end"></alpha-datepicker>
        </div>
      </section>

      <section class="flex-wrapper" style="gap: 10px">
        <div (click)="onNoClick()" class="btn-white" style="width: 80px; height: 36px">Cancel</div>
        <div (click)="data.update = true" [ngClass]="{ disabled: !checkValidNotice() }" class="btn-black" style="width: 80px">Edit</div>
      </section>
    </div>
  </article>
</article>
