import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LandingService } from '../landing.service';

@Component({
  selector: 'sub-footer',
  templateUrl: './landing-sub-footer.component.html',
  styleUrls: ['./landing-sub-footer.component.less', '../home.component.less'],
})
export class LandingSubFooterComponent implements OnInit {
  constructor(public landingService: LandingService, private router: Router) {}

  ngOnInit(): void {}
}
