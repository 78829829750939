import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopAdminAccount } from 'src/app/models/shop/shop.model';
import { AdminService } from 'src/app/service/admin.service';
import { ShopService } from 'src/app/service/shop.service';
import { adminAccountPasswordRegexp } from 'src/app/shared/regexp/shared-regexp-account-password';

@Component({
  selector: 'app-account-reset-password',
  templateUrl: './account-reset-password.component.html',
  styleUrls: ['./account-reset-password.component.less', '../../admin.component.less'],
})
export class AccountResetPasswordComponent implements OnInit {
  constructor(public shopService: ShopService, public adminService: AdminService, private route: ActivatedRoute, private router: Router) {
    route.params.subscribe((params) => {
      if ('accessToken' in params) {
        this.accessToken = params['accessToken'];
      }
    });
  }

  mode: 'wait' | 'done' | 'fail' = 'wait';
  submitLock: boolean = false;
  accessToken: string | undefined = undefined;
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required, Validators.pattern(adminAccountPasswordRegexp)]),
    passwordConfirm: new UntypedFormControl('', [Validators.required, Validators.pattern(adminAccountPasswordRegexp)]),
  });

  ngOnInit(): void {
    if (!this.accessToken) {
      this.onInvalidAccess();
    }
  }

  onInvalidAccess() {
    alert('Invalid access detected.');
    window.close();
  }

  onConfirm() {
    this.submitLock = true;
    this.shopService.resetShopAdminAccountPassword(this.formGroup.controls['password'].value, this.accessToken!).subscribe({
      next: (response) => {
        this.submitLock = false;
        if (response.body.ok) {
          this.mode = 'done';
        }
      },
      error: (error) => {
        this.submitLock = false;
        this.mode = 'fail';
      },
    });
  }

  onDone() {
    window.close();
  }
}
