import { Product } from 'src/app/models/product/product.model';
import { Review } from 'src/app/models/review/review.model';

export enum ReviewAppPlatform {
  CSV = 100,
  PRODUCT_REVIEWS = 101,
  JUDGE_ME = 102,
  LOOX = 103,
  STAMPED_IO = 104,
  YOTPO = 105,
  OKENDO = 106,
  RYVIU = 107,
  RIVYO = 108,
  ALI_EXPRESS = 109,
  AMAZON = 110,
}
export enum ReviewAppPlatformTab {
  SHOPIFY = 0,
  ALI_EXPRESS = 109,
  AMAZON = 110,
}

export enum AmazonRatingFilter {
  '1 star or more' = 1,
  '4 starts or more' = 4, //4점 이상
  '5 starts only' = 5, //5점
}

export enum EcommerceReviewImportOrderBy {
  'Most recent' = 1,
  'Top reviews' = 2,
}
export interface ReviewSettingInput {
  shop_id: number;
  number_of_import: number; //	삽입할 리뷰 개수
  minimum_rating: number; //	최소 n점 이상 기준
  is_all: boolean; //	country 모두선택
  access_country: AliexpressSettingCountry[]; //	선택한 국가 AliexpressSettingCountry value 리스트
  have_content: boolean; //	컨텐츠가 있는 리뷰 선택
  have_media: boolean; //	미디어가 있는 리뷰 선택
  have_after_date: boolean; //	특정일 기준 이상의 리뷰 선택
  after_date: Date; //	have_after_date 사용한다면 그에 대한 일자
  is_translation: boolean; //	모든리뷰 영어 변경 체크
  is_email_sending: boolean; //	저장완료 후 이메일 전송 체크
  is_save_setting: boolean;
  sort_by: number; //리뷰 정렬
}

export enum AliexpressSettingCountry {
  // 인구통계별 정렬필터 적용 위해 벨류와 상관없이 key 정렬 한 상태 수정 하지 말 것

  USA = 1,
  UK = 44, // United Kingdom
  Spain = 34, // Spain
  France = 33, // France
  Australia = 61, // Australia
  Germany = 49, // Germany
  Brazil = 55, // Brazil
  Russia = 7, // RUSSIA
  Canada = 2, // Canada
  Turkey = 90, // Turkey
  Portugal = 351, // Portugal
  Italy = 39, // Italy
  Ukraine = 380, // UKRAINE
  Other = 999,
}

export enum TransferStatus {
  TASK_CREATED = 0, // 태스크 생성됨

  START = 10, // 진행(시작시점)
  IN_PROGRESS = 20, // 진행시점
  CANCEL = 30, // 진행시점

  SUCCESSED = 100, // 진행완료시점
  PARTIAL_SUCCESS = 101, // 부분성공 (한개이상)

  FAILED = 200, // 진행완료시점 (업로드 될데이터 없음)
  DELETED = 201, // 삭제 이후시점
  EXPIRED = 202, // 삭제 이후 기간 만료

  ANALYSIS_SAVE_AWAITING = 500, // 분석에서 생성된 로그일 경우 실제 리뷰 저장이 이루어지지 않았을때의 상태
}

export enum DeleteType {
  CANCEL = 10,
  DELETE = 20,
}

export enum ReviewFileFuncType {
  IMPORT = 10,
  EXPORT = 20,
}

export class ReviewImportLog {
  id?: number;
  shop_id?: number;
  author?: string;
  app_platform?: ReviewAppPlatform;
  type!: ReviewFileFuncType;

  created_at?: Date;
  progress_status: TransferStatus = TransferStatus.START;
  task_count: number = 0; // 성공한 데이터 수
  error_count: number = 0; // 에러(저장되지 않을 데이터 수)
  total_count: number = 0; // 리뷰삽입시도 csv 파일의 row 총개수(유효성을 따지지 않음)
  error_reason: string[] = [];
  page_count: number = 0;

  export_url?: string;
  export_created_at?: Date;
  export_expired_at?: Date;
  destination_email?: string;

  import_url?: string;
  import_expired_at?: Date;
  import_created_at?: Date;

  troubleshoot_url?: string; // import 에러 파일 내려받기, error_count 가 1이상인 것들만 얘 씀
  troubleshoot_expired_at?: Date; // 생성일 일주일 이후

  products: Product[] = [];
  review?: Review;

  closed: boolean = false;
}
