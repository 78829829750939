import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-display-status-live',
  templateUrl: './display-status-live.component.html',
  styleUrls: ['./display-status-live.component.less'],
})
export class DisplayStatusLiveComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input() status: boolean = false;
}
