<article id="sales-popup-detail" style="max-width: 1200px" *ngIf="marketingPopupService.salesPopupConfig">
  <section class="gray-border-wrapper">
    <header class="admin-header-4">
      <span *ngIf="marketingPopupService.salesPopupConfig.type == marketingPopupService.WidgetInstallationPosition.MAIN_PAGE">Homepage Popup</span>
      <span *ngIf="marketingPopupService.salesPopupConfig.type == marketingPopupService.WidgetInstallationPosition.PRODUCT_DETAIL_PAGE">Product Page Popup</span>
    </header>
    <summary class="admin-content-description">
      <span *ngIf="marketingPopupService.salesPopupConfig.type == marketingPopupService.WidgetInstallationPosition.MAIN_PAGE"> Set whether to use Homepage Popup. </span>
      <span *ngIf="marketingPopupService.salesPopupConfig.type == marketingPopupService.WidgetInstallationPosition.PRODUCT_DETAIL_PAGE">
        Set whether to use Product Page Popup
      </span>
    </summary>
    <div class="admin-header-4" style="gap: 45px">
      Activate
      <alpha-toggle-btn
        [(sourceBoolean)]="marketingPopupService.salesPopupConfig.is_active"
        (sourceBooleanChange)="togglePopupActive(marketingPopupService.salesPopupConfig)"
        [positiveText]="'On'"
        [negativeText]="'Off'"
      ></alpha-toggle-btn>
    </div>
  </section>

  <section class="gray-border-panel" *ngIf="marketingPopupService.selectedMetric" style="height: 825px">
    <section class="left" cdkDropListGroup>
      <header class="admin-header-3">Popup List</header>
      <summary class="admin-content-description">The popup placed at the top will be displayed first when several Sales Popups are eligible to appear.</summary>

      <section>
        <header class="admin-header-3">Live Popups</header>
        <section
          id="activate"
          class="metric-section"
          cdkDropList
          #activeList="cdkDropList"
          [cdkDropListData]="marketingPopupService.liveMetrics"
          (cdkDropListDropped)="dragAndDrop($event)"
          style="margin: 20px 0; min-height: '30px'"
        >
          <section class="drag-induce" *ngIf="marketingPopupService.dragInduce" [@inOutAnimation]>
            <img src="assets/icon/drag_popup.svg" />
            Drag a popup block into this area <br />
            to activate!
          </section>
          <ng-container *ngFor="let metric of marketingPopupService.liveMetrics">
            <section
              class="popup-metric"
              *ngIf="metric.is_active"
              (mousedown)="marketingPopupService.selectedMetric = metric"
              [ngClass]="{ active: marketingPopupService.selectedMetric == metric }"
              cdkDrag
            >
              <ng-container [ngSwitch]="metric.type">
                <div style="display: inline-flex; gap: 2px">
                  <img src="/assets/icon/menu_vertical.svg" />
                  <img src="/assets/icon/menu_vertical.svg" />
                </div>

                <span class="fancy-badge" *ngIf="premiumSalesPopupList.includes(metric.type)">
                  <img style="width: 14px; height: 12px" src="/assets/icon/diamond.svg" />
                </span>

                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.PRODUCT_PURCHASE_SUMMARY">Product Purchase Summary</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.STORE_PURCHASE_SUMMARY">Store Purchase Summary</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.PRODUCT_REVIEW_SUMMARY">Product Review Summary</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.RECENT_PURCHASE_SPOTLIGHT">Recent Purchase Spotlight</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.PURCHASE_SUMMERY">Purchase Summary</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.CART_SUMMARY">Cart Summary</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.ADD_TO_CARTS_SPOTLIGHT">Add to Carts Spotlight</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.ALL_REVIEW_SUMMARY">All Reviews Summary</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.RECENT_REVIEW_SPOTLIGHT">Recent Review Spotlight</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.LIVE_VISITORS">Live Visitors</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.VISITOR_SUMMARY">Visitor Summary</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.REVIEW_SUMMARY">Review Summary</span>
              </ng-container>
            </section>
          </ng-container>
        </section>
      </section>

      <section>
        <header class="admin-header-3">Popup List</header>
        <section
          id="deactivate"
          class="metric-section"
          cdkDropList
          #deactivateList="cdkDropList"
          [cdkDropListData]="marketingPopupService.deActivatedMetrics"
          (cdkDropListDropped)="dragAndDrop($event)"
          style="margin-top: 20px; min-height: '30px'"
        >
          <ng-container *ngFor="let metric of marketingPopupService.deActivatedMetrics">
            <section
              class="popup-metric"
              *ngIf="!metric.is_active"
              (mousedown)="marketingPopupService.selectedMetric = metric"
              [ngClass]="{ active: marketingPopupService.selectedMetric == metric }"
              cdkDrag
              [cdkDragDisabled]="billingService.shopBilling?.plan == billingService.ShopBillingPlan.FREE && premiumSalesPopupList.includes(metric.type)"
            >
              <ng-container [ngSwitch]="metric.type">
                <div style="display: inline-flex; gap: 2px">
                  <img src="/assets/icon/menu_vertical.svg" />
                  <img src="/assets/icon/menu_vertical.svg" />
                </div>
                <span class="fancy-badge" *ngIf="premiumSalesPopupList.includes(metric.type)">
                  <img style="width: 14px; height: 12px" src="/assets/icon/diamond.svg" />
                </span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.PRODUCT_PURCHASE_SUMMARY">Product Purchase Summary</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.STORE_PURCHASE_SUMMARY">Store Purchase Summary</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.PRODUCT_REVIEW_SUMMARY">Product Review Summary</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.RECENT_PURCHASE_SPOTLIGHT">Recent Purchase Spotlight</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.PURCHASE_SUMMERY">Purchase Summary</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.CART_SUMMARY">Cart Summary</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.ADD_TO_CARTS_SPOTLIGHT">Add to Carts Spotlight</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.ALL_REVIEW_SUMMARY">All Reviews Summary</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.RECENT_REVIEW_SPOTLIGHT">Recent Review Spotlight</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.LIVE_VISITORS">Live Visitors</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.VISITOR_SUMMARY">Visitor Summary</span>
                <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.REVIEW_SUMMARY">Review Summary</span>
              </ng-container>
            </section>
          </ng-container>
        </section>
      </section>
    </section>
    <section class="right">
      <header class="admin-header-3">
        <ng-container [ngSwitch]="marketingPopupService.selectedMetric.type">
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.PRODUCT_PURCHASE_SUMMARY">Product Purchase Summary</span>
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.STORE_PURCHASE_SUMMARY">Store Purchase Summary</span>
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.RECENT_PURCHASE_SPOTLIGHT">Recent Purchase Spotlight</span>
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.PURCHASE_SUMMERY">Purchase Summary</span>
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.CART_SUMMARY">Cart Summary</span>
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.ADD_TO_CARTS_SPOTLIGHT">Add to Carts Spotlight</span>
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.PRODUCT_REVIEW_SUMMARY">Product Review Summary</span>
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.ALL_REVIEW_SUMMARY">All Reviews Summary</span>
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.RECENT_REVIEW_SPOTLIGHT">Recent Review Spotlight</span>
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.LIVE_VISITORS">Live Visitors</span>
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.VISITOR_SUMMARY">Visitor Summary</span>
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.REVIEW_SUMMARY">Review Summary</span>
        </ng-container>
      </header>
      <summary class="admin-content-description">
        <ng-container [ngSwitch]="marketingPopupService.selectedMetric.type">
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.PRODUCT_PURCHASE_SUMMARY"
            >Display the number of customers who made purchases in your store over a specific period, enhancing your store's credibility</span
          >
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.PURCHASE_SUMMERY"
            >Display the number of customers who made purchases in your store over a specific period, enhancing your store's credibility</span
          >
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.STORE_PURCHASE_SUMMARY"
            >Display the number of customers who made purchases in your store over a specific period, enhancing your store's credibility</span
          >
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.RECENT_PURCHASE_SPOTLIGHT"
            >Show the experiences of other customers who have recently placed orders, increasing trust in the product</span
          >
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.CART_SUMMARY"
            >Display the products that have been added to carts frequently over a certain period, encouraging engagement with these products</span
          >
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.ADD_TO_CARTS_SPOTLIGHT"
            >Showcase the real-time experiences of other customers who have recently added items to their carts, stimulating social proof</span
          >
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.PRODUCT_REVIEW_SUMMARY"
            >Display products with a high number of satisfied reviews, leaving a positive first impression about your product</span
          >
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.REVIEW_SUMMARY">Display how many people were satisfied with the product, enhancing conversion rates</span>
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.ALL_REVIEW_SUMMARY"
            >Show the total number of satisfied reviews of your store, leaving a favorable impression of the store's satisfaction.</span
          >
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.RECENT_REVIEW_SPOTLIGHT"
            >Display recent experiences from satisfied customers, leaving a positive impression about your product</span
          >
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.LIVE_VISITORS"
            >Show the real-time number of people currently visiting the store, enhancing the store's credibility</span
          >
          <span *ngSwitchCase="marketingPopupService.SalesPopupMetricType.VISITOR_SUMMARY"
            >Display the total number of people who have visited the store during a specific period, instilling trust in the store</span
          >
        </ng-container>
      </summary>

      <div class="admin-header-4" style="gap: 50px; margin-bottom: 20px" (mouseenter)="displayUpdatePlanTooltip = true" (mouseleave)="displayUpdatePlanTooltip = false">
        Popup
        <alpha-toggle-btn
          [sourceBoolean]="marketingPopupService.selectedMetric.is_active"
          [disabled]="billingService.shopBilling?.plan == billingService.ShopBillingPlan.FREE && premiumSalesPopupList.includes(marketingPopupService.selectedMetric.type)"
          (sourceBooleanChange)="
            onSelectMetricChange($event, marketingPopupService.selectedMetric, 'is_active');
            togglePopupActive(marketingPopupService.salesPopupConfig, marketingPopupService.selectedMetric)
          "
          [positiveText]="'On'"
          [negativeText]="'Off'"
        >
          <custom-tooltip
            *ngIf="
              billingService.shopBilling?.plan == billingService.ShopBillingPlan.FREE &&
              premiumSalesPopupList.includes(marketingPopupService.selectedMetric.type) &&
              displayUpdatePlanTooltip
            "
            iconSrc="assets/icon/diamond.svg"
            xPosition="center"
            yPosition="above"
          >
            This feature is exclusive to Premium users. <a class="hyper-link-a" [routerLink]="['/admin/settings']" [state]="{ tabType: 'subscription' }"> Upgrade now!</a>
          </custom-tooltip>
        </alpha-toggle-btn>
      </div>

      <section
        class="core-script-info flex-wrapper"
        *ngIf="!marketingPopupService.coreScriptActive && marketingPopupService.selectedMetric.type == marketingPopupService.SalesPopupMetricType.LIVE_VISITORS"
        [@inOutAnimation]
      >
        <div class="core-script-header">
          <header class="admin-header-4" style="color: #008060; gap: 6px">
            <img src="assets/icon/info_green.svg" />
            Turn your core script on to use this metric.
          </header>
          <summary style="color: #008060">The core script tracks visitor counts.</summary>
        </div>
        <a [href]="marketingPopupService.deepLinkingUrl" target="_blank" style="text-decoration: none">
          <div class="btn-black">Enable Core Script</div>
        </a>
        <img src="assets/icon/close_green.svg" class="close-icon" (click)="marketingPopupService.coreScriptActive = false" />
      </section>

      <section style="position: relative">
        <section class="popup-preview-container">
          <section class="device-toggle-container">
            <div class="device-toggle" [ngClass]="{ active: demoDevice == 'PC' }" (click)="demoDevice = 'PC'">PC</div>
            <div class="device-toggle" [ngClass]="{ active: demoDevice == 'MO' }" (click)="demoDevice = 'MO'">Mobile</div>
          </section>
          <section class="popup-preview popup-preview-pc" *ngIf="demoDevice == 'PC'">
            <div class="flex-wrapper" style="bottom: 20px">
              <img src="assets/image/marketing/product-preview-icon.png" *ngIf="iconType.product.includes(marketingPopupService.selectedMetric.type)" />
              <img src="assets/image/marketing/cart-preview-icon.png" *ngIf="iconType.cart.includes(marketingPopupService.selectedMetric.type)" />
              <img src="assets/image/marketing/review-preview-icon.png" *ngIf="iconType.review.includes(marketingPopupService.selectedMetric.type)" />
              <img src="assets/image/marketing/visitor-preview-icon.png" *ngIf="iconType.visitor.includes(marketingPopupService.selectedMetric.type)" />
              <div>
                <div
                  style="font-size: 11px; color: #91949c; margin-bottom: 5px"
                  *ngIf="
                    !iconType.viewProduct.includes(marketingPopupService.selectedMetric.type) &&
                    marketingPopupService.salesPopupConfig.type == marketingPopupService.WidgetInstallationPosition.MAIN_PAGE
                  "
                >
                  {{ adminService.ShopAdminAccount.shop?.shop_name }}
                </div>
                <div class="preview-text" [innerHTML]="previewVariable(marketingPopupService.selectedMetric) | safeHtml"></div>
                <div
                  style="display: flex; align-items: center; justify-content: space-between; font-size: 11px; margin-top: 5px"
                  *ngIf="
                    iconType.viewProduct.includes(marketingPopupService.selectedMetric.type) &&
                    marketingPopupService.salesPopupConfig.type == marketingPopupService.WidgetInstallationPosition.MAIN_PAGE
                  "
                >
                  <span style="color: #91949c">Long Sleeve T-Shirt</span>
                  <span style="font-weight: bold">View ></span>
                </div>
              </div>
              <img class="preview-close" src="assets/icon/close_gray_circle.svg" />
            </div>
          </section>
          <section class="popup-preview popup-preview-mo" *ngIf="demoDevice == 'MO'">
            <div class="flex-wrapper" style="bottom: 44px">
              <img src="assets/image/marketing/product-preview-icon.png" *ngIf="iconType.product.includes(marketingPopupService.selectedMetric.type)" />
              <img src="assets/image/marketing/cart-preview-icon.png" *ngIf="iconType.cart.includes(marketingPopupService.selectedMetric.type)" />
              <img src="assets/image/marketing/review-preview-icon.png" *ngIf="iconType.review.includes(marketingPopupService.selectedMetric.type)" />
              <img src="assets/image/marketing/visitor-preview-icon.png" *ngIf="iconType.visitor.includes(marketingPopupService.selectedMetric.type)" />
              <div>
                <div
                  style="font-size: 10px; color: #91949c; margin-bottom: 3px"
                  *ngIf="
                    !iconType.viewProduct.includes(marketingPopupService.selectedMetric.type) &&
                    marketingPopupService.salesPopupConfig.type == marketingPopupService.WidgetInstallationPosition.MAIN_PAGE
                  "
                >
                  {{ adminService.ShopAdminAccount.shop?.shop_name }}
                </div>
                <div class="preview-text" [innerHTML]="previewVariable(marketingPopupService.selectedMetric) | safeHtml"></div>
                <!-- <div
                  style="display: flex; align-items: center; justify-content: space-between; font-size: 10px; margin-top: 3px"
                  *ngIf="iconType.viewProduct.includes(marketingPopupService.selectedMetric.type) && marketingPopupService.salesPopupConfig.type == marketingPopupService.WidgetInstallationPosition.MAIN_PAGE"
                >
                  <span style="color: #91949c">Long Sleeve T-Shirt</span>
                  <span style="font-weight: bold">View ></span>
                </div> -->
              </div>
              <img class="preview-close" src="assets/icon/close_gray_circle.svg" />
            </div>
          </section>
        </section>

        <section
          class="admin-disable-cover"
          *ngIf="billingService.shopBilling?.plan == billingService.ShopBillingPlan.FREE && premiumSalesPopupList.includes(marketingPopupService.selectedMetric.type)"
        >
          <custom-tooltip iconSrc="assets/icon/diamond.svg" xPosition="center" yPosition="center">
            This feature is exclusive to Premium users. <a class="hyper-link-a" [routerLink]="['/admin/settings']" [state]="{ tabType: 'subscription' }"> Upgrade now!</a>
          </custom-tooltip>
        </section>
        <section style="margin-top: 20px">
          <header class="admin-header-4">Popup Message</header>
          <summary class="popup-notice">
            <span>Notice:</span>Click &#123;&#125; to see what variables you can use. Include the &#123;&#125; symbols, for the variables to be applied.
          </summary>
          <alpha-textarea
            [displayEmojiPicker]="false"
            [sourceString]="marketingPopupService.selectedMetric.content"
            (sourceStringChange)="onSelectMetricChange($event, marketingPopupService.selectedMetric, 'content')"
            [displayMaxLengthLabel]="false"
            [personalVars]="setTemplateVars(marketingPopupService.selectedMetric)"
            [maxStringLength]="2000"
          ></alpha-textarea>
          <div class="flex-wrapper info-text" *ngIf="!checkIncludeVars" [@inOutAnimation]>
            <img src="assets/icon/info_red.svg" />
            <span>To include variables, use the "&#123;&#125;" symbols in your input, for example, &#123;country&#125; or &#123;time_range&#125;.</span>
          </div>
        </section>

        <section style="margin-top: 20px" class="popup-condition">
          <header class="admin-header-4" style="margin-bottom: 8px">Popup Condition</header>

          <div class="flex-wrapper" *ngIf="conditionType.onlyTimeRange.includes(marketingPopupService.selectedMetric.type)">
            Display this popup when the data that can be shown is within the last
            <input
              type="number"
              [ngModel]="marketingPopupService.selectedMetric.condition.time_range"
              (ngModelChange)="onSelectMetricChange($event, marketingPopupService.selectedMetric, 'time_range')"
              [min]="0"
              class="admin-input"
              style="width: 70px"
            />
            days
          </div>

          <div class="flex-wrapper" *ngIf="conditionType.onlyThreshold.includes(marketingPopupService.selectedMetric.type)">
            Display this popup when the data within is at least
            <input
              type="number"
              [ngModel]="marketingPopupService.selectedMetric.condition.threshold"
              (ngModelChange)="onSelectMetricChange($event, marketingPopupService.selectedMetric, 'threshold')"
              [min]="2"
              class="admin-input"
              style="width: 70px"
            />
          </div>

          <div class="flex-wrapper" *ngIf="conditionType.timeAndThreshold.includes(marketingPopupService.selectedMetric.type)">
            Display this popup when the data within
            <select
              class="admin-input"
              [ngModel]="marketingPopupService.selectedMetric.condition.time_range"
              (ngModelChange)="onSelectMetricChange($event, marketingPopupService.selectedMetric, 'time_range')"
              style="width: 180px"
            >
              <option [ngValue]="'last_day'">Last 1 day</option>
              <option [ngValue]="'last_week'">Last 1 week</option>
              <option [ngValue]="'last_month'">Last 1 month</option>
              <option [ngValue]="'last_3_month'">Last 3 months</option>
              <option [ngValue]="'last_6_month'">Last 6 months</option>
              <option [ngValue]="'last_year'">Last 1 year</option>
              <option [ngValue]="'entire_period'">Entire period</option>
            </select>
            is at least
            <input
              type="number"
              [ngModel]="marketingPopupService.selectedMetric.condition.threshold"
              (ngModelChange)="onSelectMetricChange($event, marketingPopupService.selectedMetric, 'threshold')"
              [min]="2"
              class="admin-input"
              style="width: 70px"
            />
          </div>
          <div *ngIf="conditionType.timeAndThresholdAndRating.includes(marketingPopupService.selectedMetric.type)" style="line-height: 3">
            Display this popup when the reviews with
            <select
              class="admin-input"
              [ngModel]="marketingPopupService.selectedMetric.condition.rating"
              (ngModelChange)="onSelectMetricChange($event, marketingPopupService.selectedMetric, 'rating')"
              style="width: 100px"
            >
              <option [ngValue]="3">3+stars</option>
              <option [ngValue]="4">4+stars</option>
              <option [ngValue]="5">5+stars</option>
            </select>
            written
            <select
              class="admin-input"
              [ngModel]="marketingPopupService.selectedMetric.condition.time_range"
              (ngModelChange)="onSelectMetricChange($event, marketingPopupService.selectedMetric, 'time_range')"
              style="width: 180px"
            >
              <option [ngValue]="'last_day'">Last 1 day</option>
              <option [ngValue]="'last_week'">Last 1 week</option>
              <option [ngValue]="'last_month'">Last 1 month</option>
              <option [ngValue]="'last_3_month'">Last 3 months</option>
              <option [ngValue]="'last_6_month'">Last 6 months</option>
              <option [ngValue]="'last_year'">Last 1 year</option>
              <option [ngValue]="'entire_period'">Entire period</option>
            </select>
            is at least
            <input
              type="number"
              [ngModel]="marketingPopupService.selectedMetric.condition.threshold"
              (ngModelChange)="onSelectMetricChange($event, marketingPopupService.selectedMetric, 'threshold')"
              [min]="2"
              class="admin-input"
              style="width: 70px"
            />
          </div>

          <div *ngIf="conditionType.timeAndRating.includes(marketingPopupService.selectedMetric.type)" style="line-height: 3">
            Display this popup when the recent review with
            <select
              class="admin-input"
              [ngModel]="marketingPopupService.selectedMetric.condition.rating"
              (ngModelChange)="onSelectMetricChange($event, marketingPopupService.selectedMetric, 'rating')"
              style="width: 100px"
            >
              <option [ngValue]="3">3+stars</option>
              <option [ngValue]="4">4+stars</option>
              <option [ngValue]="5">5+stars</option>
            </select>
            is within the last
            <input
              type="number"
              [ngModel]="marketingPopupService.selectedMetric.condition.time_range"
              (ngModelChange)="onSelectMetricChange($event, marketingPopupService.selectedMetric, 'time_range')"
              [min]="0"
              class="admin-input"
              style="width: 70px"
            />
            days
          </div>
        </section>
      </section>
    </section>
  </section>
</article>
