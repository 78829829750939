<article id="account-invite-complete">
  <section class="account-img">
    <img src="assets/icon/party-popper.png">
  </section>
  <section>
    <header class="account-title">
      Welcome to AlphaReview!
    </header>
  </section>
  <section class="account-content">
    Your account has been created successfully.
    Go back to AlphaReview admin login page
    and continue logging in.
  </section>

  <section>
    <button class="btn-black" style="width: 100%;" (click)="onConfirm()">
      Done
    </button>
  </section>

</article>
