<main id="landing-collect">
  <article class="collect-visual">
    <div class="inner">
      <section class="collect-visual-text">
        <div class="collect-visual-slogan">
          The Smartest Way <br />
          to
          <span class="focus-text">
            Collect <br />
            <br class="mobr" />
            More Reviews
          </span>
        </div>
        <div class="collect-visual-description" *ngIf="!landingService.mobile">
          It's difficult to collect reviews <br />
          yourself without someone's help. <br />
          Why don't you get help <br />
          from AlphaReview, <br />
          which knows the smartest and <br />
          easiest way to collect reviews? <br />
        </div>
        <div class="collect-visual-bg" *ngIf="!landingService.mobile">
          <img src="assets/image/landing/collect-main-bg-small.png" />
        </div>
      </section>
      <section class="collect-visual-img">
        <img src="assets/image/landing/collect-main.gif" />
      </section>
    </div>
  </article>
  <!-- collect main end -->

  <article class="collect-marketers">
    <div class="inner">
      <header class="landing-header">
        <div class="landing-flex-wrapper">
          <span
            class="collect-marketers-text-slider"
            style="height: 45px; display: inline-flex; align-items: flex-end"
            >Collect&nbsp;</span
          >
          <swiper [config]="textSwiperConfig" class="collect-marketers-swiper">
            <ng-template swiperSlide>Video Reviews</ng-template>
            <ng-template swiperSlide>Star Ratings</ng-template>
            <ng-template swiperSlide>Text Reviews</ng-template>
            <ng-template swiperSlide>Photo Reviews</ng-template>
            <ng-template swiperSlide>Social Reviews</ng-template>
          </swiper>
        </div>
        and Make Customers <br class="mobr" />
        Your Best Marketers
      </header>
      <summary class="landing-summary" *ngIf="!landingService.mobile">
        Customers trust reviews more than advertisements. <br />
        Therefore, star ratings for product evaluation, text reviews that
        explain the product in detail, <br />
        and photo and video reviews that show the product from various angles
        are more effective <br />
        than anything else in leading the conversion of potential customers who
        are hesitant to purchase. <br />
        Collect reviews and make customers your best marketer.
      </summary>
      <div class="collect-marketers-img">
        <img src="assets/image/landing/collect-marketers.png" />
      </div>
      <div class="collect-marketers-bg-box bg01" *ngIf="!landingService.mobile">
        <span class="collect-marketers-bg left"></span>
      </div>
      <div class="collect-marketers-bg-box bg02" *ngIf="!landingService.mobile">
        <span class="collect-marketers-bg right"></span>
      </div>
    </div>
  </article>
  <!-- collect marketers end -->

  <article class="collect-automatic">
    <div class="inner">
      <header class="landing-header">Send Review Request Automatically</header>
      <summary class="landing-summary" *ngIf="!landingService.mobile">
        We automatically send review requests at the right time to collect text,
        photos, and video reviews. We also send review reminders and media
        reminders to earn even more reviews.
      </summary>

      <section class="collect-automatic-process">
        <div class="collect-automatic-process-first">
          <div class="collect-automatic-container">
            <header class="collect-automatic-process-header">
              First Review Request
            </header>
            <summary class="collect-automatic-process-description">
              Sending customers the review <br class="mobr" />
              request is the most effective way to <br class="mobr" />
              collect reviews. Therefore, we send <br class="mobr" />
              multiple review requests to a <br class="mobr" />
              customer in sequence, and “The <br class="mobr" />
              First Review Request” is the first <br class="mobr" />
              one.
            </summary>
          </div>
          <div class="collect-automatic-process-img">
            <img src="assets/image/landing/collect-automatic-01.png" />
          </div>
          <span class="automatic-arrow-box">
            <img
              src="assets/image/landing/collect-request-arrow-yellow.png"
              class="automatic-arrow"
            />
          </span>
        </div>
        <div class="collect-automatic-process-reminder">
          <div class="collect-automatic-container">
            <header class="collect-automatic-process-header">
              Review Reminder
            </header>
            <summary class="collect-automatic-process-description">
              By sending a Review Reminder to <br class="mobr" />
              the customers who have not yet <br class="mobr" />
              written a review after receiving the <br class="mobr" />
              First Review Request, you can <br class="mobr" />
              collect more review data.
            </summary>
          </div>
          <div
            class="collect-automatic-process-img"
            *ngIf="!landingService.mobile"
          >
            <img src="assets/image/landing/collect-automatic-02.png" />
          </div>
          <span class="automatic-arrow-box">
            <img
              src="assets/image/landing/collect-request-arrow-orange.png"
              class="automatic-arrow"
            />
          </span>
        </div>
        <div class="collect-automatic-process-media">
          <div class="collect-automatic-container">
            <header class="collect-automatic-process-header">
              Media Reminder
            </header>
            <summary class="collect-automatic-process-description">
              A picture is worth a thousand words. <br class="mobr" />
              That’s why it’s so important to <br class="mobr" />
              collect more photo and video <br class="mobr" />
              reviews, which is so-called “Media <br class="mobr" />
              Review”. AlphaReview thus provides <br class="mobr" />
              the feature to ask reviewers who <br class="mobr" />
              have not written media reviews <br class="mobr" />
              to write a media review, which is <br class="mobr" />
              Media Reminder.
            </summary>
          </div>
          <div
            class="collect-automatic-process-img"
            *ngIf="!landingService.mobile"
          >
            <img src="assets/image/landing/collect-automatic-03.png" />
          </div>
        </div>
      </section>
    </div>
  </article>
  <!-- collect automatic end -->

  <article class="collect-request">
    <div class="inner">
      <header class="landing-header">
        Request via Email, SMS, Web Push <br />
        and Revisit Banner
      </header>
      <summary class="landing-summary" *ngIf="!landingService.mobile">
        We send review requests via Email, SMS, Web Push and Revisit Banner,
        <br />
        so that customers can write a review wherever they are. All of them are
        fully customizable.
      </summary>
      <div class="landing-flex-wrapper">
        <div class="collect-request-img-container">
          <ng-container *ngFor="let type of landingService.requestType">
            <section class="collect-request-img" *ngIf="type._isClick" [@fade]>
              <img src="{{ type.image }}" alt="" />
            </section>
          </ng-container>
        </div>

        <div class="collect-request-title-container">
          <ng-container *ngFor="let type of landingService.requestType">
            <section
              class="collect-request-title-box textBox"
              (click)="
                landingService.isPause = true;
                landingService.clickCollect(type, landingService.requestType)
              "
              [ngClass]="{ active: type._isClick == true }"
            >
              <div class="collect-request-title">
                {{ type.title }}
              </div>
            </section>
          </ng-container>
          <div class="collect-request-exclusive">
            <img src="assets/icon/landing-arrow-left-white.svg" />
            ✨AlphaReview Exclusive
          </div>
        </div>
      </div>
    </div>
  </article>
  <!-- collect request end -->

  <article class="collect-coupon">
    <span class="landing-header-label navy"> ✨ AlphaReview Exclusive </span>
    <header class="landing-header">
      Incentivize Reviewers <br />
      with Customizable Coupons
    </header>
    <summary class="landing-summary" *ngIf="!landingService.mobile">
      Incentivizing is a good way to get more reviews with the higher quality.
      <br />
      you can set reward value differently by customer groups, review types,
      review quality, <br />
      and order and product amount range.
    </summary>
    <span
      class="landing-header-label mo-label"
      *ngIf="landingService.mobile"
      style="margin-top: 20px"
    >
      Basic Rewards for
    </span>
    <section class="collect-coupon-green">
      <div class="collect-coupon-green-bg">
        <img src="assets/image/landing/collect-coupon-green.png" />
        <img
          src="assets/image/landing/coupon-green-01.png"
          class="collect-coupon-green-icon"
        />
        <div class="collect-coupon-green-text">
          <span
            class="collect-coupon-green-label"
            *ngIf="!landingService.mobile"
            >Basic Rewards for</span
          >
          <span class="collect-coupon-green-title"
            >Ratings <br class="mobr" />
            Review</span
          >
        </div>
      </div>
      <div class="collect-coupon-green-bg">
        <img src="assets/image/landing/collect-coupon-green.png" />
        <img
          src="assets/image/landing/coupon-green-02.png"
          class="collect-coupon-green-icon"
        />
        <div class="collect-coupon-green-text">
          <span
            class="collect-coupon-green-label"
            *ngIf="!landingService.mobile"
            >Basic Rewards for</span
          >
          <span class="collect-coupon-green-title"
            >Text <br class="mobr" />
            Review</span
          >
        </div>
      </div>
      <div class="collect-coupon-green-bg">
        <img src="assets/image/landing/collect-coupon-green.png" />
        <img
          src="assets/image/landing/coupon-green-03.png"
          class="collect-coupon-green-icon"
        />
        <div class="collect-coupon-green-text">
          <span
            class="collect-coupon-green-label"
            *ngIf="!landingService.mobile"
            >Basic Rewards for</span
          >
          <span class="collect-coupon-green-title"
            >Photo <br class="mobr" />
            Review</span
          >
        </div>
      </div>
    </section>

    <section class="collect-coupon-navy landing-flex-wrapper">
      <div class="collect-coupon-navy-container">
        <img src="assets/image/landing/coupon-navy-01.png" />
        <p class="collect-coupon-navy-label">
          Additional Rewards Depending on the
        </p>
        <h5 class="collect-coupon-navy-title">Quality of Review</h5>
        <p class="collect-coupon-navy-description">
          You can offer additional rewards depending on the number of letters in
          the text review, the number of images in the photo review, the length
          of video review.
        </p>
      </div>
      <div class="collect-coupon-navy-container">
        <img src="assets/image/landing/coupon-navy-02.png" />
        <p class="collect-coupon-navy-label">
          Additional Rewards Depending on the
        </p>
        <h5 class="collect-coupon-navy-title">The First Review</h5>
        <p class="collect-coupon-navy-description">
          You can offer additional rewards for the customer who writes a review
          for the first time after becoming a member of the store or writes the
          first review for a purchased product.
        </p>
      </div>
      <div class="collect-coupon-navy-container">
        <img src="assets/image/landing/coupon-navy-03.png" />
        <p class="collect-coupon-navy-label">
          Additional Rewards Depending on the
        </p>
        <h5 class="collect-coupon-navy-title">Purchased Product</h5>
        <p class="collect-coupon-navy-description">
          You can offer addtional rewards for the customer who purchases a
          specific product chosen by you or purchases a product over a specific
          amount.
        </p>
      </div>
      <div class="collect-coupon-navy-container">
        <img src="assets/image/landing/coupon-navy-04.png" />
        <p class="collect-coupon-navy-label">
          Additional Rewards Depending on the
        </p>
        <h5 class="collect-coupon-navy-title">Customer Segment</h5>
        <p class="collect-coupon-navy-description">
          You may offer additional rewards if a customer in a specific segment
          writes a review.
        </p>
      </div>
    </section>
  </article>
  <!-- collect coupon end -->

  <article class="collect-joyful">
    <div class="inner">
      <span class="landing-header-label navy">✨ AlphaReview Exclusive</span>
      <header class="landing-header">Make Reviewing Process Joyful</header>
      <summary class="landing-summary" *ngIf="!landingService.mobile">
        AlphaReview provides the most beautiful and advanced "Write a Review
        Page"
        <br />
        so that customers can enjoy the process of reviewing.
      </summary>
      <swiper [config]="joyfulConfig" style="margin: 50px 0 0">
        <ng-template swiperSlide>
          <section class="collect-joyful-write landing-flex-wrapper">
            <img src="assets/image/landing/collect-write-01.png" />
            <img src="assets/image/landing/collect-write-02.png" />
            <div class="collect-joyful-textbox" *ngIf="!landingService.mobile">
              <p class="collect-joyful-text right write01">
                <img src="assets/image/landing/joyful-icon-zero.png" />
                Customers can intuitively see how many more reviews are left for
                them to write.
              </p>
              <p class="collect-joyful-text right write02">
                <img src="assets/image/landing/joyful-icon-chain.png" />
                By intuitively displaying the rewards for each type of review,
                we encourage customers to write a more complete review.
              </p>
              <p class="collect-joyful-text right write03">
                <img src="assets/image/landing/joyful-icon-star.png" />
                By displaying the rewards customers can receive for writing
                high-quality reviews, we encourage customers to write better
                reviews.
              </p>
            </div>
          </section>
        </ng-template>
        <ng-template swiperSlide>
          <section class="collect-joyful-myreview landing-flex-wrapper">
            <div class="collect-joyful-textbox" *ngIf="!landingService.mobile">
              <p class="collect-joyful-text left myreview01">
                <img src="assets/image/landing/joyful-icon-note.png" />
                Customers can see a list of reviews they have written.
              </p>
              <p class="collect-joyful-text left myreview02">
                <img src="assets/image/landing/joyful-icon-air.png" />
                If a customer submits a review in an incomplete state, the
                additional rewards that can be received when the review is
                completed are shown to encourage them to finish a review.
              </p>
            </div>
            <img src="assets/image/landing/collect-myreview.png" />
            <div class="collect-joyful-textbox" *ngIf="!landingService.mobile">
              <p class="collect-joyful-text right myreview03">
                <img src="assets/image/landing/joyful-icon-check.png" />
                Customers can check detailed reviews written by themselves and
                comments written on reviews.
              </p>
              <p class="collect-joyful-text right myreview04">
                <img src="assets/image/landing/joyful-icon-edit.png" />
                Customers can edit or delete their reviews.
              </p>
              <p class="collect-joyful-text right myreview05">
                <img src="assets/image/landing/joyful-icon-sms.png" />
                Customers can see comments made by store managers or other
                customers in their reviews, and can reply to comments.
              </p>
            </div>
          </section>
        </ng-template>
        <ng-template swiperSlide>
          <section class="collect-joyful-coupons landing-flex-wrapper">
            <div class="collect-joyful-textbox" *ngIf="!landingService.mobile">
              <p class="collect-joyful-text left coupons01">
                <img src="assets/image/landing/joyful-icon-coupon.png" />
                Customers can easily check all the coupons they've received for
                reviews here.
              </p>
              <p class="collect-joyful-text left coupons02">
                <img src="assets/image/landing/joyful-icon-check.png" />
                Customers can easily check the conditions of coupon use.
              </p>
              <p class="collect-joyful-text left coupons03">
                <img src="assets/image/landing/joyful-icon-check-red.png" />
                If the coupon is applicable only to a specific product or
                collection, the customer can easily check the list of applicable
                products and collections.
              </p>
            </div>
            <img src="assets/image/landing/collect-coupons.png" />
            <div class="collect-joyful-textbox" *ngIf="!landingService.mobile">
              <p class="collect-joyful-text right coupons04 home">
                <img src="assets/image/landing/joyful-icon-home.png" />
                We provide a button that takes customers directly to the online
                store from within this coupon page, so you can increase your
                store's re-purchase rate.
              </p>
              <p class="collect-joyful-text right coupons05">
                <img src="assets/image/landing/joyful-icon-car.png" />
                Customers can directly check the validity period of the coupon.
              </p>
              <p class="collect-joyful-text right coupons06">
                <img src="assets/image/landing/joyful-icon-copy.png" />
                Customers can immediately and conveniently copy the coupon's
                code here.
              </p>
            </div>
          </section>
        </ng-template>
      </swiper>
    </div>
  </article>
  <!-- collect joyful end -->

  <article class="collect-quick">
    <div class="inner">
      <span class="landing-header-label white">✨ AlphaReview Exclusive</span>
      <header class="landing-header white">
        The Easiest Way to <br class="mobr" />
        Write a Review, Quick Review
      </header>
      <summary class="landing-summary" *ngIf="!landingService.mobile">
        Quick Review is the easiest way to write a review with just one click.
        <br />
        The review request sent to the customers involves satisfaction buttons,
        <br />
        which are immediately created in satisfaction text when clicked. <br />
        Text of reviews by each satisfaction can be set through AlphaReview
        admin.
      </summary>
      <img
        *ngIf="!landingService.mobile"
        src="assets/image/landing/collect-quick.png"
      />
      <img
        *ngIf="landingService.mobile"
        src="assets/image/landing/collect-quick-mo.png"
      />
    </div>
  </article>
  <!-- collect quick end -->

  <article class="collect-gather">
    <div class="inner">
      <header class="landing-header">
        Gather All Reviews <br class="mobr" />
        into Your Store
      </header>
      <summary class="landing-summary" *ngIf="!landingService.mobile">
        With AlphaReview, you can import product reviews from Aliexpress <br />
        with just one click. It's fast and easy. <br />
        You can also transfer reviews easily from other apps without any
        adjustment.
      </summary>

      <section class="collect-gather-import landing-flex-wrapper">
        <div class="collect-gather-import-ali">
          <h5 class="collect-gather-import-header">
            Import Aliexpress <br class="mobr" />
            Reviews
          </h5>
          <p class="collect-gather-import-description">
            You can import reviews from Aliexpress to your store. We also allow
            you to apply multiple filters when you import Aliexpress reviews,
            such as minimum ratings, only reviews with photos and etc,.
          </p>
          <span>
            <img src="assets/image/landing/collect-import-01.png" />
          </span>
        </div>
        <div class="collect-gather-import-csv">
          <h5 class="collect-gather-import-header">
            Import Reviews <br class="mobr" />
            from CSV
          </h5>
          <p class="collect-gather-import-description">
            You can import reviews from CSV file. This feature can be used when
            you need to transfer reviews from other apps, or you have reviews
            written from other platforms.
          </p>
          <span>
            <img src="assets/image/landing/collect-import-02.png" />
          </span>
        </div>
      </section>
    </div>
  </article>
  <!-- collect import end -->
</main>
