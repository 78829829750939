import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShopAdminAccount } from 'src/app/models/shop/shop.model';
import { ShopService } from 'src/app/service/shop.service';

@Component({
  selector: 'app-dialog-admin-account-reset-password',
  templateUrl: './dialog-admin-account-reset-password.component.html',
  styleUrls: ['./dialog-admin-account-reset-password.component.less', '../../admin.component.less'],
})
export class DialogAdminAccountResetPasswordComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DialogAdminAccountResetPasswordComponent>,
    private shopService: ShopService,
    @Inject(MAT_DIALOG_DATA) public data: ShopAdminAccount,
  ) {}

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  isCompleted: boolean = false;
  isFail: boolean = false;
  submitLock: boolean = false;
  ngOnInit(): void {}

  onNoClick() {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.dialogRef.close(true);
  }

  sendPasswordResetEmail() {
    this.submitLock = true;
    this.shopService.sendShopAdminAccountResetPasswordEmail(this.formGroup.controls['email'].value).subscribe({
      next: (response) => {
        this.submitLock = false;

        if (response.body.ok) {
          this.isCompleted = true;
        }
      },
      error: (error) => {
        this.submitLock = false;
        this.isFail = true;
      },
    });
  }
}
