import { Component, Input, OnInit } from '@angular/core';
import { EffectCreative, EffectFade, SwiperOptions } from 'swiper';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
SwiperCore.use([Autoplay, Pagination, EffectFade, EffectCreative]);

@Component({
  selector: 'app-analysis-loader',
  templateUrl: './analysis-loader.component.html',
  styleUrls: ['./analysis-loader.component.less'],
})
export class AnalysisLoaderComponent implements OnInit {
  @Input('loading') loading!: boolean;
  constructor() {}

  ngOnInit(): void {}

  swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    effect: 'fade',
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },

    pagination: {
      el: '.pagination',
      clickable: true,
    },
  };
}
