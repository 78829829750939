"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.capture = exports.openCamera = exports.Scanner = exports.Action = void 0;
var Scanner_1 = require("@shopify/app-bridge-core/actions/Scanner");
Object.defineProperty(exports, "Action", { enumerable: true, get: function () { return Scanner_1.Action; } });
Object.defineProperty(exports, "Scanner", { enumerable: true, get: function () { return Scanner_1.Scanner; } });
Object.defineProperty(exports, "openCamera", { enumerable: true, get: function () { return Scanner_1.openCamera; } });
Object.defineProperty(exports, "capture", { enumerable: true, get: function () { return Scanner_1.capture; } });
/**
 * @public
 */
function create(app, options) {
    return new Scanner_1.Scanner(app, options);
}
exports.create = create;
