<article id="manage-review-write" [ngClass]="{ loading: isLoading }">
  <section *ngIf="!writeAReview" [@inOutAnimation]>
    <header class="admin-header-3">Select a Product</header>

    <section class="manage-write-searching">
      <select [(ngModel)]="productService.searchAbout">
        <option value="all">All</option>
        <option value="published">Published Only</option>
        <option value="unpublished">Unpublished Only</option>
      </select>

      <form class="admin-form" style="width: 100%">
        <button (click)="getProducts()"><img src="assets/icon/search_gray.svg" /></button>
        <input placeholder="Search" [ngModelOptions]="{ standalone: true }" [(ngModel)]="productService.searchContent" (keydown.enter)="getProducts()" />
      </form>
    </section>

    <section class="manage-write-products manage-write-scroll-wrap" style="max-height: calc(70vh - 200px)">
      <ng-container *ngFor="let product of products">
        <mat-radio-button (click)="selectedProduct = product" [value]="product" [checked]="selectedProduct === product" class="product-info-radio" style="width: 100%">
          <div class="product-info-wrap">
            <img class="product-info-image" [src]="product.product_image" />
            <div class="product-info">
              <header class="product-info-title ellipsis-1">
                {{ product.title }}
              </header>
              <div class="product-info-id">{{ product.platform_id }}</div>
            </div>
          </div>
        </mat-radio-button>
      </ng-container>
    </section>

    <section class="flex-wrapper manage-write-btn" style="gap: 10px; justify-content: flex-end">
      <div (click)="onCancel()" class="btn-white" style="width: 80px; height: 36px">Cancel</div>
      <div (click)="onClickSelectedProduct()" class="btn-black" [ngClass]="{ disabled: !selectedProduct }" style="width: 80px">Select</div>
    </section>
  </section>
  <section *ngIf="writeAReview && review" [@inOutAnimation]>
    <ng-container *ngIf="data.type === 'product'">
      <header class="admin-header-3" style="gap: 12px; margin-bottom: 20px">
        <img (click)="openDialogCancelGuard('back')" src="assets/icon/back_btn_gray.svg" style="cursor: pointer" />
        Write a Product Review
      </header>
    </ng-container>
    <ng-container *ngIf="data.type === 'store'">
      <header class="admin-header-3 split">Write a Store Review</header>
    </ng-container>

    <ng-container *ngIf="data.type === 'edit'">
      <header class="admin-header-3 split">
        <ng-container *ngIf="review.type == ReviewType.STORE_REVIEW; else productReview">Write a Store Review</ng-container>
        <ng-template #productReview>Write a Product Review</ng-template>
      </header>
    </ng-container>

    <section class="manage-write-scroll-wrap" [ngStyle]="{ 'max-height': this.data.type === 'edit' ? 'calc(70vh - 166px)' : 'calc(70vh - 153px)' }">
      <section *ngIf="this.data.type === 'product'" class="manage-write-select-product flex-wrapper">
        <img [src]="selectedProduct!.product_image" />
        <div class="ellipsis-1">{{ selectedProduct!.title }}</div>
      </section>

      <section *ngIf="this.data.type === 'edit'" class="manage-write-edit-note">
        <header class="admin-header-4" style="gap: 5px"><img src="assets/icon/note_green.svg" />Note</header>
        <ul>
          <li>Review ratings cannot be edited.</li>
          <li>Due to Shopify's policy, reviews written by customers cannot be modified.</li>
          <li>
            You can edit Imported reviews and those you've written, but changes to review content are limited to 50 characters per edit (this includes both characters removed and
            added).
          </li>
        </ul>
      </section>

      <section class="manage-write-content flex-wrapper">
        <header class="admin-header-4" style="gap: 4px">Date of Creation<img src="assets/icon/require_red.svg" /></header>
        <alpha-datepicker [(selected)]="review.created_at" style="width: 183px"></alpha-datepicker>
      </section>

      <section class="manage-write-content split flex-wrapper">
        <header class="admin-header-4" style="gap: 4px">Reviewer Name<img src="assets/icon/require_red.svg" /></header>
        <div class="flex-wrapper" style="gap: 10px; width: 100%">
          <input class="admin-input" [(ngModel)]="review.customer_firstname" placeholder="First name" style="width: 147px" />
          <input class="admin-input" [(ngModel)]="review.customer_lastname" placeholder="Last name" style="width: 147px" />
        </div>
      </section>

      <section *ngIf="this.data.type === 'product'" class="manage-write-content flex-wrapper">
        <header class="admin-header-4">Review Type</header>
        <select [(ngModel)]="review.type" style="width: 100%">
          <option [value]="ReviewType.BASIC_REVIEW">Basic Review</option>
          <option [value]="ReviewType.LONGTERM_USE_REVIEW">Long-term Review</option>
        </select>
      </section>

      <section *ngIf="this.data.type !== 'edit'" class="manage-write-content">
        <header class="admin-header-4" style="gap: 4px">Ratings<img src="assets/icon/require_red.svg" /></header>
        <section class="manage-write-stars">
          <star-filled
            class="manage-write-star selected"
            [width]="34"
            [height]="32"
            [fillColor]="'#151516'"
            *ngFor="let i of genericService.range(1, review.rating + 1)"
            (click)="review.rating = i"
          ></star-filled>
          <star-filled class="manage-write-star" [width]="34" [height]="32" *ngFor="let i of genericService.range(review.rating, 5)" (click)="review.rating = i + 1"></star-filled>
        </section>
      </section>

      <section class="manage-write-content">
        <header class="admin-header-4">Review Title</header>
        <input class="admin-input" [(ngModel)]="review.title" placeholder="Review Title" style="width: 100%; margin: 5px 1px 0" />
      </section>

      <section class="manage-write-content">
        <header class="admin-header-4" style="gap: 4px">Contents<img src="assets/icon/require_red.svg" /></header>
        <textarea
          *ngIf="data.type !== 'edit'; else editContent"
          class="admin-input"
          style="width: 100%; height: 100px; margin: 5px 1px 0"
          [(ngModel)]="review.content"
          placeholder="Contents"
        >
        </textarea>
        <ng-template #editContent>
          <textarea
            [ngModel]="review.content"
            (ngModelChange)="onContentChange($event)"
            [ngStyle]="contentChangeAlert ? { border: '1px solid #ff6d6d' } : {}"
            class="admin-input"
            style="width: 100%; height: 100px; margin: 5px 1px 0"
            placeholder="Contents"
          >
          </textarea>
          <div *ngIf="contentChangeAlert" class="manage-write-edit-alert" [@inOutAnimation]>
            <img src="assets/icon/info_red.svg" />
            Changes to review content cannot exceed 50 characters.
          </div>
        </ng-template>
      </section>

      <section class="manage-write-content flex-wrapper" [ngStyle]="{ 'padding-bottom': review.review_media.length ? '10px' : '20px' }">
        <header class="admin-header-4">Media Attachment</header>
        <div (click)="reviewMediaInput.click()" [ngClass]="{ disabled: reviewMediaMax }" class="btn-white" style="width: 172px; height: 36px">+ Add Photo/Video File</div>
        <input #reviewMediaInput [formControl]="reviewMedia" type="file" accept="image/* | video/*" multiple style="display: none" (change)="onChangeMedia($event)" />
      </section>

      <section *ngIf="review.review_media.length" class="manage-write-media">
        <ng-container *ngFor="let media of review.review_media">
          <section class="preview-media">
            <div class="preview-media-delete" (click)="onClickReviewMediaDelete(media)">
              <img src="assets/icon/close_square_black.png" style="width: 24px; height: 24px" />
            </div>
            <ng-container *ngIf="media.previewURL; else storeReviewEdit">
              <img class="preview-media-image" [src]="media.previewURL | safe" *ngIf="media.type == ReviewMediaType.PHOTO || media.type == ReviewMediaType.GIF" />
              <video class="preview-media-image" *ngIf="media.type == ReviewMediaType.VIDEO" autoplay loop muted>
                <source [src]="media.previewURL | safe" />
              </video>
            </ng-container>
            <ng-template #storeReviewEdit>
              <img class="preview-media-image" [src]="media.thumbnail" *ngIf="media.type == ReviewMediaType.PHOTO || media.type == ReviewMediaType.GIF" />
              <video class="preview-media-image" *ngIf="media.type == ReviewMediaType.VIDEO" autoplay loop muted>
                <source [src]="media.thumbnail" />
              </video>
            </ng-template>
          </section>
        </ng-container>
      </section>
    </section>

    <section class="flex-wrapper manage-write-btn" style="gap: 10px; justify-content: flex-end">
      <div (click)="openDialogCancelGuard('cancel')" class="btn-white" style="width: 80px; height: 36px">Cancel</div>
      <div (click)="onSubmit()" [ngClass]="{ disabled: !checkSubmitLock() }" class="btn-black" style="width: 68px">Submit</div>
    </section>
  </section>
  <section *ngIf="isLoading" class="loading-cover">
    <span class="loader"></span>
  </section>
</article>
