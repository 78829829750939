import { Component, Inject, OnInit, Optional, SkipSelf } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Product } from 'src/app/models/product/product.model';
import { AdminService } from 'src/app/service/admin.service';
import { GenericService } from 'src/app/service/generic.service';
import { ProductService } from 'src/app/service/product.service';

@Component({
  selector: 'app-dialog-product-variant-select',
  templateUrl: './dialog-product-variant-select.component.html',
  styleUrls: ['./dialog-product-variant-select.component.less', '../../admin.component.less'],
})
export class DialogProductVariantSelectComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogProductVariantSelectComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      mode: 'radio' | 'checkBox';
      action: 'copy' | 'moving' | 'choice';
      ai?: boolean;
      maximum?: number;
    },
    public genericService: GenericService,
    public productService: ProductService,
    @Optional() @SkipSelf() private adminService: AdminService,
  ) {}

  products: Product[] = [];
  checkedProducts: Product[] = [];

  productsFilters: { is_published: boolean[] } = { is_published: [true, false] };

  searchContent = '';

  ngOnInit(): void {
    this.getProducts();
  }

  getProducts() {
    let params = {
      search_content: this.searchContent,
      filters: JSON.stringify(this.productsFilters),
      shop_access_code: this.adminService.getShopAccessCode(),
    };

    this.productService.getProducts(params).subscribe((response) => {
      if (response.body.data) this.products = response.body.data as Product[];
      else this.products = response.body;

      if (this.data.maximum) this.products.forEach((item) => (item._disabled = false));
    });
  }

  onChangeSortSelectBox(event: any) {
    this.productsFilters.is_published = event;
    this.getProducts();
  }

  onClickRadioBtn(product: Product) {
    this.checkedProducts = [product];
  }

  onClickCheckBox(product: Product) {
    product.checked = !product.checked;
    this.checkedProducts = this.products.filter((item) => item.checked);
    if (this.checkedProducts.length === this.data.maximum) {
      this.products.forEach((item) => {
        if (!item.checked) item._disabled = true;
      });
    } else {
      this.products.forEach((item) => (item._disabled = false));
    }
  }

  onNoClick() {
    return this.dialogRef.close();
  }

  onClickConfirm() {
    if (this.data.mode == 'checkBox') this.checkedProducts = this.products.filter((item) => item.checked);
    return this.dialogRef.close(this.checkedProducts);
  }

  disabledBtn() {
    if (this.data.ai && this.checkedProducts.length === 0) return true;
    return false;
  }
}
