import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fadeAnimation, inOutAnimation } from '../../animations';
import { Router } from '@angular/router';
import { ListDesignerPreset } from 'src/app/models/list-designer/widget.model';

@Component({
  selector: 'app-dialog-select-ld-template',
  templateUrl: './dialog-select-ld-template.component.html',
  styleUrl: './dialog-select-ld-template.component.less',
  animations: [inOutAnimation, fadeAnimation],
})
export class DialogSelectLdTemplateComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public useProductReviews: boolean, private dialogRef: MatDialogRef<DialogSelectLdTemplateComponent>, private router: Router) {}

  selectedRecommendation: ListDesignerPreset = ListDesignerPreset.BEST_SELLERS;
  mode: 'PC' | 'MO' = 'MO';

  onClose() {
    this.dialogRef.close(false);
    this.router.navigate(['admin/list-designer']);
  }

  onScratch() {
    this.dialogRef.close(false);
    this.router.navigate(['admin/list-designer/widget-custom']);
  }

  onRecommendedTemplate() {
    this.dialogRef.close(this.selectedRecommendation);
  }

  get ListDesignerPreset(): typeof ListDesignerPreset {
    return ListDesignerPreset;
  }
}
