import { Component, OnInit, ViewChild } from '@angular/core';
import Swiper, { EffectCreative, EffectFade, SwiperOptions } from 'swiper';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { fadeAnimation, fadeTextAnimation, inOutAnimation, upDownAnimation } from '../animations';

SwiperCore.use([Autoplay, Pagination, EffectFade, EffectCreative]);

import { LandingService } from '../landing.service';
import $ from 'jquery';
@Component({
  selector: 'app-landing-main',
  templateUrl: './landing-main.component.html',
  styleUrls: ['./landing-main.component.less'],
  animations: [inOutAnimation, upDownAnimation, fadeTextAnimation],
})
export class LandingMainComponent implements OnInit {
  constructor(public landingService: LandingService) {
    this.landingService.stopLoop();
    this.landingService.isPause = false;
    this.landingService.collectAndManage = this.collectAndManageData;

    this.storeLogoArray();
  }

  @ViewChild('showcaseSwiper') swiper!: Swiper;

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.landingService.mobile) {
      let showCase = $('.main-showcase');
      showCase.find('.swiper-pagination-bullet')[4].innerText = 'Social Media';
    } else {
      let text = $('.homeTimer');
      this.landingService.itemBox = text;
      if (this.landingService.itemBox.length > 0 && !this.landingService.isPause) {
        this.landingService.startLoop(this.landingService.collectAndManage);
      }
    }
  }

  ngOnDestroy() {
    this.landingService.collectAndManage = [];
  }

  idx = 'main';

  currentUrl?: string;

  homeTimer: any;

  textBox: any;
  collect: boolean = false;
  manage: boolean = false;
  showCase: boolean = false;
  marketing: boolean = false;

  isShow: boolean = false;

  boostGraph: boolean = false;
  fadeText: boolean = false;

  storeLogoA: string[] = [];
  storeLogoB: string[] = [];

  checkOutPaginationMo = {
    clickable: true,
  };

  showCasePaginationMo = {
    clickable: true,
    renderBullet: function (index: number, className: any) {
      let checkMenu = ['Reviews', 'Wall Photos', 'Gallery', 'Ratings', 'Social Media', 'Notice', 'Ranked Reviews', 'Highlight-Text', 'Highlight'];

      return '<div class="' + className + ' autoplay"><span>' + checkMenu[index] + '</span></div>';
    },
  };

  collectAndManageData = [
    {
      title: 'Send Review Request, Reminder, and Media Reminder Automatically',
      summary:
        'We automatically send review requests at the right time to collect text, photos, and video reviews. We also send review reminders and media reminders to earn even more reviews.',
      image: 'assets/image/landing/collect-01.png',
      _isClick: true,
    },
    {
      title: 'Review Requests via Email, SMS, Web Push, and Revisit Banner',
      summary: 'We send review requests via Email, SMS, Web Push, and Revisit Banner so that customers can write reviews wherever they are. ',
      image: 'assets/image/landing/collect-02.png',
    },
    {
      title: 'Import Reviews from Aliexpress Easily',
      summary: "With AlphaReview, you can import product reviews from Aliexpress with just one click. It's fast and easy.",
      image: 'assets/image/landing/collect-03.png',
    },
    {
      title: 'Incentivize Reviewers with Customizable Coupons',
      summary:
        'Incentivizing is an excellent way to get more reviews with higher quality. You can set reward value differently by customer groups, review types, review quality, and order and product amount range.',
      image: 'assets/image/landing/collect-04.png',
    },
    {
      title: 'Make Reviewing Process Joyful',
      summary: 'AlphaReview provides the most beautiful and advanced "Review Write Page" so that customers can enjoy the review process.',
      image: 'assets/image/landing/collect-05.png',
    },
  ];

  checkoutConfig: SwiperOptions = {
    slidesPerView: 1,
    effect: 'fade',
    loop: true,
    autoplay: {
      delay: 2500,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },
    pagination: {
      clickable: true,
      renderBullet: function (index: number, className: any) {
        let checkMenu = [
          'Auto-Pilot <br> Best Reviews',
          'Review <br> Groups',
          'Reviewing <br> Process',
          'Customizable <br> Coupons',
          'Quick <br> Review',
          'Manage <br> CRM',
          'Social <br> Marketing',
        ];

        return '<div class="' + className + '"><span>' + checkMenu[index] + '</span></div>';
      },
    },
  };

  showcaseConfig: SwiperOptions = {
    slidesPerView: 1,
    loop: true,
    effect: 'fade',
    autoplay: {
      delay: 3000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },
    pagination: {
      clickable: true,
      renderBullet: function (index: number, className: any) {
        let checkMenu = ['Reviews', 'Wall Photos', 'Gallery', 'Ratings', 'Social Media Review', 'Notice', 'Ranked Reviews', 'Highlight-Text', 'Highlight'];

        return '<div class="' + className + ' autoplay"><span>' + checkMenu[index] + '</span></div>';
      },
    },
  };

  storeLogoArray() {
    let storeLogo = [];
    for (let i = 1; i <= 23; i++) {
      let src = `assets/image/landing/storelogo/${i}.png`;
      storeLogo.push(src);
      //;
    }
    this.storeLogoA = storeLogo.slice(0, 11);
    this.storeLogoB = storeLogo.slice(11, 23);
  }
}
