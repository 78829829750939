<article id="manage-review-demo-guide">
  <section class="archive-preview">
    <img class="archive-img-preview" src="assets/image/page/demo-guide-top.png" style="width: 600px; height: 195px" />
  </section>
  <section class="demo-review-archive-guide">
    <header class="demo-review-archive-guide-header">
      <img class="header-bulb" src="assets/icon/light-bulb.png" style="width: 21px; height: 21px" /> Don’t forget to archive dummy reviews!
    </header>
    <div class="demo-review-archive-guide-description">
      Just a quick heads up - we've provided some dummy review data to help you understand our widgets and review management system. <br />
      Don't forget to archive them once you're done reviewing!
    </div>
  </section>
  <section class="demo-review-shortcut">
    <table class="demo-review-shortcut-table">
      <td class="go-check-widgets" (click)="goCheckWidgets()">
        <img class="go-check-widgets-img" src="assets/image/page/go-check-widget.png" style="width: 70; height: 44px; align-self: center; margin: 8px" />
        <div class="go-check-widgets-letter">Go check widgets ></div>
      </td>
      <td class="go-check-widgets" (click)="goCheckModerationGuide()">
        <img class="go-check-widgets-img" src="assets/image/page/go-check-moderation-guide.png" style="width: 70; height: 44px; align-self: center; margin: 8px" />
        <div class="go-check-widgets-letter">Go check moderation guide ></div>
      </td>
    </table>
  </section>
  <section class="demo-review-done-button-" style="display: flex; flex-direction: row-reverse; margin-right: 24px">
    <button (click)="close()" style="background-color: black; padding: 20px" class="btn-black">Done</button>
  </section>
</article>
