"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.networkAction = exports.persistenceAction = exports.unsupportedOperationAction = exports.unexpectedAction = exports.invalidAction = exports.invalidActionType = exports.invalidPayload = exports.Message = exports.fromAction = exports.Action = exports.permissionAction = exports.isErrorEventName = exports.throwError = exports.invalidOriginAction = exports.AppBridgeError = exports.AppActionType = void 0;
var Error_1 = require("@shopify/app-bridge-core/actions/Error");
Object.defineProperty(exports, "Action", { enumerable: true, get: function () { return Error_1.Action; } });
Object.defineProperty(exports, "fromAction", { enumerable: true, get: function () { return Error_1.fromAction; } });
var helper_1 = require("../helper");
var types_1 = require("../types");
var Error_2 = require("@shopify/app-bridge-core/actions/Error");
Object.defineProperty(exports, "AppActionType", { enumerable: true, get: function () { return Error_2.AppActionType; } });
Object.defineProperty(exports, "AppBridgeError", { enumerable: true, get: function () { return Error_2.AppBridgeError; } });
Object.defineProperty(exports, "invalidOriginAction", { enumerable: true, get: function () { return Error_2.invalidOriginAction; } });
Object.defineProperty(exports, "throwError", { enumerable: true, get: function () { return Error_2.throwError; } });
Object.defineProperty(exports, "isErrorEventName", { enumerable: true, get: function () { return Error_2.isErrorEventName; } });
Object.defineProperty(exports, "permissionAction", { enumerable: true, get: function () { return Error_2.permissionAction; } });
function errorActionWrapperWithId(type, action, message) {
    var castPayload = action.payload;
    return helper_1.actionWrapper({
        type: type,
        group: types_1.Group.Error,
        payload: {
            action: action,
            message: message,
            type: type,
            id: castPayload && castPayload.id ? castPayload.id : undefined,
        },
    });
}
var Message;
(function (Message) {
    Message["MISSING_PAYLOAD"] = "Missing payload";
    Message["INVALID_PAYLOAD_ID"] = "Id in payload is missing or invalid";
})(Message = exports.Message || (exports.Message = {}));
function invalidPayload(action, message) {
    return errorActionWrapperWithId(Error_1.Action.INVALID_PAYLOAD, action, message || "The action's payload is missing required properties or has invalid properties");
}
exports.invalidPayload = invalidPayload;
function invalidActionType(action, message) {
    return helper_1.actionWrapper({
        group: types_1.Group.Error,
        payload: {
            action: action,
            message: message || 'The action type is invalid or unsupported',
            type: Error_1.Action.INVALID_ACTION_TYPE,
        },
        type: Error_1.Action.INVALID_ACTION_TYPE,
    });
}
exports.invalidActionType = invalidActionType;
function invalidAction(action, message) {
    return helper_1.actionWrapper({
        group: types_1.Group.Error,
        payload: {
            action: action,
            message: message || "The action's has missing/invalid values for `group`, `type` or `version`",
            type: Error_1.Action.INVALID_ACTION,
        },
        type: Error_1.Action.INVALID_ACTION,
    });
}
exports.invalidAction = invalidAction;
function unexpectedAction(action, message) {
    return helper_1.actionWrapper({
        group: types_1.Group.Error,
        payload: {
            action: action,
            message: message || 'Action cannot be called at this time',
            type: Error_1.Action.UNEXPECTED_ACTION,
        },
        type: Error_1.Action.UNEXPECTED_ACTION,
    });
}
exports.unexpectedAction = unexpectedAction;
function unsupportedOperationAction(action, message) {
    return errorActionWrapperWithId(Error_1.Action.UNSUPPORTED_OPERATION, action, message || 'The action type is unsupported');
}
exports.unsupportedOperationAction = unsupportedOperationAction;
function persistenceAction(action, message) {
    return errorActionWrapperWithId(Error_1.Action.PERSISTENCE, action, message || 'Action cannot be persisted on server');
}
exports.persistenceAction = persistenceAction;
function networkAction(action, message) {
    return errorActionWrapperWithId(Error_1.Action.NETWORK, action, message || 'Network error');
}
exports.networkAction = networkAction;
