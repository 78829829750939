import { Injectable } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { OrderImportLog } from '../models/order/requests-order/order-import-log.model';
import {
  DeliveryShipmentStatus,
  Requests,
  RequestsOrder,
  RequestStatus,
  RequestStatusLength,
  SendingChannel,
  SendingStatus,
} from '../models/order/requests-order/requests-order.model';
import { MessagingSettingTypeDetail } from '../models/shop/review/options/messaging';
import { TransferStatus } from '../models/shop/review/options/review-import.model';
import { PaginationHandler } from '../shared/handler/pagination-handler';
import { AdminService } from './admin.service';
import { BackendService } from './backend.service';
import { PageEvent } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root',
})
export class RequestsService {
  constructor(private backendService: BackendService, private adminService: AdminService) {}

  requestsDateRange: DateRange<Date> = new DateRange(new Date(new Date().setMonth(new Date().getMonth() - 3)), new Date());

  date_about: 'fulfilled_date' | 'requested_date' | 'ordered_date' | 'content' = 'fulfilled_date';

  sendTypeDetail: MessagingSettingTypeDetail = MessagingSettingTypeDetail.FIRST_REVIEW_REQUEST;

  searchAbout: 'customer_name' | 'customer_email' | 'order_name' | 'content' = 'customer_name';
  searchContent: string = '';

  requestsOrderFilters: RequestsOrderFilters = new RequestsOrderFilters();

  manageRequestLoading: boolean = false;

  paginationHandler!: PaginationHandler;

  requests: Requests[] = [];
  delivery?: Requests;
  requestStatusLength?: RequestStatusLength;
  selectedRequest!: RequestsOrder;

  orderUrl!: string;
  customerUrl!: string;

  emptyData: boolean = false;

  isoDateStart = new Date(this.requestsDateRange.start!.getTime() - this.requestsDateRange.start!.getTimezoneOffset() * 60000).toISOString();
  isoDateEnd = new Date(this.requestsDateRange.end!.getTime() - this.requestsDateRange.end!.getTimezoneOffset() * 60000).toISOString();

  getRequestsOrder(params: {} = {}) {
    let url = 'orders';
    return this.backendService.select(url, params);
  }

  getRequestsOrderParams() {
    return {
      date_about: this.date_about,
      start_at: this.isoDateStart,
      end_at: this.isoDateEnd,
      shop_access_code: this.adminService.getShopAccessCode(),
      // shop_access_code: 3,
      search_about: this.searchAbout,
      search_content: this.searchContent,
      embed: ['line_items', 'messaging_log', 'customer'],
      hybrids: 'delivery_status,delivery_datetime',
      filters: JSON.stringify(this.requestsOrderFilters),
      skip: this.paginationHandler.skip,
      limit: this.paginationHandler.limit,
    };
  }

  getSelectRequests(event: PageEvent | undefined = undefined, orderStatus?: RequestStatus, params: {} = {}) {
    if (this.manageRequestLoading) {
      return;
    } else {
      this.manageRequestLoading = true;
    }

    if (event) {
      if (orderStatus !== this.RequestStatus.ALL) this.onClickProductFilterElement('request_status', orderStatus);

      this.paginationHandler.skip = event.pageSize * event.pageIndex;
      this.paginationHandler.limit = event.pageSize;
    } else {
      this.paginationHandler.skip = 0;
      this.paginationHandler.paginator.firstPage();
    }

    const queryParams = {
      send_type_detail: this.sendTypeDetail,
      ...this.getRequestsOrderParams(),
      ...params,
    };
    this.getRequestsOrder(queryParams).subscribe((response) => {
      this.requests = response.body.data;
      this.requestStatusLength = response.body.length;

      this.paginationHandler.paginator.length = this.requestStatusLength?.all!;

      if (this.requestsOrderFilters.request_status.includes(this.RequestStatus.PENDING)) {
        this.paginationHandler.paginator.length = this.requestStatusLength?.pending!;
      } else if (this.requestsOrderFilters.request_status.includes(this.RequestStatus.SENT)) {
        this.paginationHandler.paginator.length = this.requestStatusLength?.sent!;
      } else if (this.requestsOrderFilters.request_status.includes(this.RequestStatus.REVIEWED)) {
        this.paginationHandler.paginator.length = this.requestStatusLength?.reviewed!;
      } else if (this.requestsOrderFilters.request_status.includes(this.RequestStatus.NOT_SENT)) {
        this.paginationHandler.paginator.length = this.requestStatusLength?.not_sent!;
      }

      this.manageRequestLoading = false;
      this.requestsOrderFilters.request_status = [];
      this.requests.length == 0 ? (this.emptyData = true) : (this.emptyData = false);
    });
  }

  onClickProductFilterElement(key: keyof RequestsOrderFilters, value: any) {
    const idx = (this.requestsOrderFilters[key] as any).indexOf(value, 0);
    if (idx > -1) {
      (this.requestsOrderFilters[key] as any).splice(idx, 1);
    } else {
      (this.requestsOrderFilters[key] as any).push(value);
    }
  }

  updateRequestsOrder(payload: {} = {}, params?: {}) {
    let url = 'messaging/messaging-logs';

    return this.backendService.update(url, payload, params);
  }

  pollingOrderImportProgress(orderImportLog: OrderImportLog) {
    let url = 'orders/import/status';
    return this.backendService.select(url, {
      log_id: orderImportLog.id,
    });
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  get MessagingSettingTypeDetail(): typeof MessagingSettingTypeDetail {
    return MessagingSettingTypeDetail;
  }
  get RequestStatus(): typeof RequestStatus {
    return RequestStatus;
  }
  get SendingStatus(): typeof SendingStatus {
    return SendingStatus;
  }
  get SendingChannel(): typeof SendingChannel {
    return SendingChannel;
  }
  public get TransferStatus(): typeof TransferStatus {
    return TransferStatus;
  }
  get DeliveryShipmentStatus(): typeof DeliveryShipmentStatus {
    return DeliveryShipmentStatus;
  }
}

class RequestsOrderFilters {
  request_status: number[] = [];
  is_manual: boolean[] = [];
}
