import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-check',
  template: `
    <svg width="26" height="17" viewBox="0 0 26 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M6 6.93478L11.7273 12.5L20 4.5" [ngStyle]="{ stroke: color }" stroke-width="2" stroke-miterlimit="10" />
      </g>
    </svg>
  `,
  styles: [],
})
export class IconCheckComponent implements OnInit {
  constructor() {}

  @Input() color: string = '#ADADB1';

  ngOnInit(): void {}
}
