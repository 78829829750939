<article id="import-review-migration" *ngIf="reviewSettingInput">
  <header class="admin-header-2" style="font-size: 20px; margin-bottom: 16px">
    Import reviews from
    <ng-container [ngSwitch]="this.data.import_platform">
      <ng-container *ngSwitchCase="reviewImportService.ReviewAppPlatform.ALI_EXPRESS">&nbsp;Aliexpress</ng-container>
      <ng-container *ngSwitchCase="reviewImportService.ReviewAppPlatform.AMAZON">&nbsp;Amazon</ng-container>
    </ng-container>
  </header>
  <section class="product-info">
    <div class="product-info-image">
      <img [src]="data.product.product_image" />
    </div>
    <div>
      <div class="product-info-title">{{ data.product.title }}</div>
      <div class="product-info-element">
        <span class="product-info-element-title"> Created at </span>
        <span class="product-info-element-content">
          {{ data.product.created_at | date : 'mediumDate' }}
        </span>
      </div>
      <div class="product-info-element">
        <span class="product-info-element-title"> Status </span>
        <span class="product-info-element-content"> True </span>
      </div>
    </div>
  </section>

  <section class="factor-select-container">
    <header class="admin-header-4" style="margin-bottom: 10px">
      URL of
      <ng-container [ngSwitch]="this.data.import_platform">
        <ng-container *ngSwitchCase="reviewImportService.ReviewAppPlatform.ALI_EXPRESS">Aliexpress</ng-container>
        <ng-container *ngSwitchCase="reviewImportService.ReviewAppPlatform.AMAZON">Amazon</ng-container>
      </ng-container>
      product (Required)
    </header>
    <section style="display: flex; align-items: center; gap: 8px">
      <input
        class="admin-input"
        style="width: 324px; height: 36px"
        [placeholder]="this.data.import_platform == reviewImportService.ReviewAppPlatform.ALI_EXPRESS ? 'E.g. https://aliexpress.com/item/' : 'E.g. https://amazon.com'"
        [formControl]="inputUrl"
      />
      <div *ngIf="!verifying">
        <button *ngIf="!checkVerifiedUrl()" class="btn-black" style="width: 80px" [disabled]="!inputUrl.value" (click)="verifyUrl()">Verify</button>
        <div *ngIf="checkVerifiedUrl()" style="font-size: 13px; display: flex; gap: 6px">
          <img src="assets/icon/check_circle_green.svg" />
          Verified
        </div>
      </div>
    </section>

    <section *ngIf="!verifyUrlCheck" class="admin-warning" [@inOutAnimation]>
      <img src="assets/icon/info_red.svg" />
      Not valid URL
    </section>

    <section *ngIf="verifyError && isInputUrlValid() && verifyDetails" class="admin-warning" [@inOutAnimation]>
      {{ verifyDetails }}
      <img src="assets/icon/info_red.svg" matTooltip="You cannot import reviews with the URL of product preview. Please paste the URL from the Product Details page, not the Preview screen."/>
    </section>
  </section>

  <section class="factor-select-container" style="display: flex; gap: 10px; flex-wrap: wrap; margin-bottom: 10px; width: 100%">
    <section *ngIf="this.data.import_platform == reviewImportService.ReviewAppPlatform.AMAZON" style="width: calc(50% - 5px)">
      <header class="admin-header-4" style="margin-bottom: 10px">Sort By</header>

      <select style="width: 100%" [(ngModel)]="reviewSettingInput.sort_by">
        <option *ngFor="let i of [1, 2]" [ngValue]="i">{{ reviewImportService.EcommerceReviewImportOrderBy[i] }}</option>
      </select>
    </section>
    <ng-container [ngSwitch]="this.data.import_platform">
      <section *ngSwitchCase="reviewImportService.ReviewAppPlatform.ALI_EXPRESS" style="position: relative; width: calc(50% - 5px)">
        <custom-tooltip
          *ngIf="
            billingService.shopBilling?.plan == billingService.ShopBillingPlan.FREE && reviewSettingInput.number_of_import > reviewImportService.LIMIT_IMPORT_REVIEW_ALIEXPRESS
          "
          iconSrc="assets/icon/diamond.svg"
          xPosition="before"
          yPosition="below"
        >
          Free Plan: {{ reviewImportService.LIMIT_IMPORT_REVIEW_ALIEXPRESS }} Reviews/Product.<a
            class="hyper-link-a"
            [routerLink]="['/admin/settings']"
            [state]="{ tabType: 'subscription' }"
          >
            Upgrade now</a
          >
          for Unlimited Reviews!
        </custom-tooltip>
        <header class="admin-header-4" style="margin-bottom: 10px">Number of reviews to import</header>
        <input [(ngModel)]="reviewSettingInput.number_of_import" class="admin-input" style="width: 100%" [max]="700" type="number" placeholder="15" (keyup)="onKeyUp(700)" />
      </section>
      <section *ngSwitchCase="reviewImportService.ReviewAppPlatform.AMAZON" style="position: relative; width: calc(50% - 5px)">
        <custom-tooltip
          *ngIf="billingService.shopBilling?.plan == billingService.ShopBillingPlan.FREE && reviewSettingInput.number_of_import > reviewImportService.LIMIT_IMPORT_REVIEW_AMAZON"
          iconSrc="assets/icon/diamond.svg"
          xPosition="after"
          yPosition="below"
        >
          Free Plan: {{ reviewImportService.LIMIT_IMPORT_REVIEW_AMAZON }} Reviews/Product.<a
            class="hyper-link-a"
            [routerLink]="['/admin/settings']"
            [state]="{ tabType: 'subscription' }"
          >
            Upgrade now</a
          >
          for Unlimited Reviews!
        </custom-tooltip>
        <header class="admin-header-4" style="margin-bottom: 10px">Number of reviews to import</header>
        <input [(ngModel)]="reviewSettingInput.number_of_import" class="admin-input" style="width: 100%" [max]="100" type="number" placeholder="15" (keyup)="onKeyUp(100)" />
      </section>
    </ng-container>

    <section style="width: calc(50% - 5px)">
      <header class="admin-header-4" style="margin-bottom: 10px">Minimum rating</header>
      <ng-container>
        <div [ngSwitch]="this.data.import_platform">
          <div *ngSwitchCase="reviewImportService.ReviewAppPlatform.ALI_EXPRESS">
            <select style="width: 100%" [(ngModel)]="reviewSettingInput.minimum_rating">
              <option *ngFor="let i of [1, 2, 3, 4, 5]" [ngValue]="i">{{ i }} stars or more</option>
            </select>
          </div>

          <div *ngSwitchCase="reviewImportService.ReviewAppPlatform.AMAZON">
            <select style="width: 100%" [(ngModel)]="reviewSettingInput.minimum_rating">
              <option *ngFor="let i of [1, 4, 5]" [ngValue]="i">{{ reviewImportService.AmazonRatingFilter[i] }}</option>
            </select>
          </div>
        </div>
      </ng-container>
    </section>
  </section>

  <section class="factor-select-container" *ngIf="this.data.import_platform == reviewImportService.ReviewAppPlatform.ALI_EXPRESS">
    <header class="admin-header-4" style="margin-bottom: 10px">Country filters</header>

    <section class="country-checkbox-container">
      <div class="country-checkbox">
        <alpha-checkbox [(ngModel)]="reviewSettingInput.is_all" (ngModelChange)="isAllChecked()">All</alpha-checkbox>
      </div>

      <div class="country-checkbox" *ngFor="let country of countries | keyvalue : original">
        <alpha-checkbox [(ngModel)]="country.value" (ngModelChange)="reviewSettingInput.is_all = false; isAllChecked(country)">{{ country.key }} </alpha-checkbox>
      </div>
    </section>
  </section>

  <section class="factor-select-container">
    <header class="admin-header-4" style="margin-bottom: 10px">Content filters</header>
    <div
      *ngIf="this.data.import_platform == reviewImportService.ReviewAppPlatform.ALI_EXPRESS"
      class="checkbox-container flex-wrapper"
      style="justify-content: flex-start; gap: 8px"
    >
      <alpha-checkbox [(ngModel)]="reviewSettingInput.have_content">Skip reviews without any content</alpha-checkbox>
      <img
        style="width: 16px; height: 16px"
        src="assets/icon/info_gray.svg"
        matTooltip="Apply this filter to exclude reviews
          with only star ratings"
      />
    </div>
    <div class="checkbox-container" style="margin-bottom: 8px">
      <alpha-checkbox [(ngModel)]="reviewSettingInput.have_media">Skip reviews without photos</alpha-checkbox>
    </div>

    <div class="checkbox-container" *ngIf="reviewSettingInput.after_date" style="display: flex; align-items: center">
      <alpha-checkbox [(ngModel)]="reviewSettingInput.have_after_date"> Review written after </alpha-checkbox>
      <alpha-datepicker [(selected)]="reviewSettingInput.after_date" style="margin-left: 8px; width: 150px"></alpha-datepicker>
    </div>
  </section>

  <section class="factor-select-container" *ngIf="this.data.import_platform != reviewImportService.ReviewAppPlatform.AMAZON">
    <header class="admin-header-4" style="margin-bottom: 10px">Translation Settings</header>

    <div class="translate-settings">
      <div class="flex-wrapper" style="gap: 4px">
        Translate to English
        <img
          matTooltip="Depending on the link you are trying to import,
          it may not be possible to translate."
          style="width: 16px; height: 16px"
          src="../../../../../assets/icon/info_gray.svg"
        />
      </div>
      <alpha-toggle-btn [disabled]="!translate" [(sourceBoolean)]="reviewSettingInput.is_translation" positiveText="On" negativeText="Off"></alpha-toggle-btn>
    </div>
  </section>
  <div class="checkbox-container save-settings" style="margin-bottom: 24px">
    <alpha-checkbox [(ngModel)]="reviewSettingInput.is_save_setting">Save these settings</alpha-checkbox>
  </div>

  <section class="flex-wrapper button-wrap">
    <button class="btn-white" (click)="onNoClick()" style="width: 80px; height: 36px">Cancel</button>
    <button class="btn-black" (click)="migrateReview()" [disabled]="!checkVerifiedUrl()" style="width: 80px; height: 36px">Import</button>
  </section>
</article>
