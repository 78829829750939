<article id="list-designer-widgets" class="setting-article" style="max-width: 1240px">
  <section class="flex-wrapper">
    <header class="admin-header-1">Widgets List <a href="https://alph.oopy.io/a260adc5-2eb0-43e6-aab1-d9e541250df1" target="_blank" class="btn-white">Guide</a></header>
    <div (click)="listDesignerService.getDeepLinkingUrl()" class="btn-black btn-shopify" style="width: 206px">Go to Shopify Theme Editor</div>
  </section>

  @if(listDesignerService.activeCoreScript !== null && !listDesignerService.activeCoreScript) {
  <section class="flex-wrapper widgets-core-script" [@inOutAnimation]>
    <div>
      <header class="admin-header-3" style="gap: 4px">The Alpha+ List Designer app has not been activated yet <img src="assets/icon/info_green.svg" /></header>
      <summary>Please activate the core script by clicking the 'Enable Core Script' button on the right side, and then click 'Save' on the next page.</summary>
    </div>
    <a (click)="listDesignerService.getDeepLinkingUrl()" class="bold" style="cursor: pointer">Enable Core Script</a>
  </section>
  }

  <section class="gray-border-wrapper" style="max-height: calc(100vh - 280px)">
    @if (listDesignerService.widgets !== null && listDesignerService.widgetLength) {
    <section class="widgets-list">
      <div class="flex-wrapper">
        <div class="list-count bold">{{ listDesignerService.widgetLength }} widgets</div>
        <div class="list-btns flex-wrapper">
          <div (click)="deletedWidget()" class="btn-white" style="width: 64px">Delete</div>
          <div (click)="copyWidget()" class="btn-white" style="width: 56px">Copy</div>
          <div routerLink="/admin/list-designer/widget-custom" class="btn-black" style="width: 156px">+ Create a Widget</div>
        </div>
      </div>

      <section class="list-table">
        <header class="content-table-header">
          <div class="content-table-name checkbox">
            <alpha-checkbox [(ngModel)]="isAll" (ngModelChange)="checkedAll($event)"></alpha-checkbox>
          </div>
          <div class="content-table-name layout" style="padding: 0 10px">layout</div>
          <div class="content-table-name name" style="padding: 0 10px">name</div>
          <div class="content-table-name date">Updated at.</div>
          <div class="content-table-name action">Actions</div>
        </header>

        <div class="scroll-container" style="max-height: calc(100vh - 460px)">
          @for (widget of listDesignerService.widgets; track $index) {
          <section class="content-table-row" [class.selected]="widget._checked">
            <div class="checkbox">
              <alpha-checkbox [(ngModel)]="widget._checked" (ngModelChange)="notCheckedAll()"></alpha-checkbox>
            </div>
            <div class="layout">
              @if (widget.design_option.layout === 'list' ) {
              <img src="assets/icon/layout_list.svg" />
              } @else {
              <img src="assets/icon/layout_carousel.svg" />
              }
            </div>
            <div class="name">
              <div class="bold ellipsis-1">{{ widget.name }}</div>
              <div class="template" [ngSwitch]="widget.template">
                <ng-container *ngSwitchCase="listDesignerService.ListDesignerPreset.BEST_SELLERS">Created with <strong>Best Seller template</strong></ng-container>
                <ng-container *ngSwitchCase="listDesignerService.ListDesignerPreset.TOP_REVIEWED_PRODUCTS">Created with <strong>Top Reviewed template</strong></ng-container>
                <ng-container *ngSwitchCase="listDesignerService.ListDesignerPreset.NEW_ARRIVALS">Created with <strong>New Arrivals template</strong></ng-container>
                <ng-container *ngSwitchCase="listDesignerService.ListDesignerPreset.MANUAL">Manual setting</ng-container>
              </div>
            </div>
            <div class="date">
              {{ widget.updated_at | date : 'MMM dd yyyy, hh:mm aa' }}
            </div>
            <div class="action flex-wrapper" style="gap: 6px">
              <div (click)="editWidget(widget.id)" class="btn-white" style="height: 36px; width: 47px">Edit</div>
              <div (click)="copyWidgetId(widget.code)" class="btn-black" style="width: 123px">Copy widget ID</div>
            </div>
          </section>
          }
        </div>
      </section>
    </section>
    } @if (listDesignerService.widgets !== null && !listDesignerService.widgetLength) {
    <section class="widgets-empty">
      <header class="admin-header-1">Enhance your store design with this Temu-inspired product list design!</header>
      <div (click)="listDesignerService.openDialogSelectTemplate()" class="btn-black" style="width: 161px">Start with Templates</div>
      <img src="assets/image/list-designer/widget_empty.png" />
    </section>
    }
    <section [ngStyle]="listDesignerService.widgetLength > 0 ? { opacity: 1 } : { opacity: 0 }">
      <mat-paginator
        #paginator
        (page)="listDesignerService.getWidgetList($event)"
        [pageSizeOptions]="[10, 20, 100]"
        [disabled]="listDesignerService.manageWidgetsLoading"
        showFirstLastButtons
        aria-label="Select page"
        ><input class="admin-input" />
      </mat-paginator>
    </section>
  </section>
</article>
