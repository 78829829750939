<article id="dialog-setting-subscription-downgrade">
    <header class="admin-header-3">
        Are You Sure You Want to Downgrade?
    </header>

    <summary class="admin-header-description" style="margin-top: 20px;">
        Please note: Downgrading to the Free plan will reset some of the advanced features you've customized.
    </summary>

    <section style="margin-top: 20px;">
        <div class="enjoy-list">
            By staying with our Premium Plan, you continue to enjoy:
        </div>
        <div class="enjoy-list">
            · Unlimited review imports from AliExpress and Amazon
        </div>
        <div class="enjoy-list">
            · Complete customization of review forms and widgets
        </div>
        <div class="enjoy-list">
            · Full metrics on Sales Popups
        </div>
        <div class="enjoy-list">
            · ...and much more!
        </div>
    </section>
    <section class="need-help">
        <img src="assets/icon/edit_gray.svg" style="padding: 20px">
        <div>
            <span style="font-weight: 600;">Need Help?</span> If you're experiencing issues or need more information,
            contact our Customer Support
            team.
        </div>
    </section>

    <section class="flex-wrapper" style="margin-top: 30px">
        <div></div>
        <div class="flex-wrapper">
            <button class="btn-black" style="margin-right: 10px" (click)="goToSupportChannel()">
                Contact Customer Support
            </button>
            <button class="btn-outline" (click)="onClickDowngrade()">
                Downgrade
            </button>
        </div>
    </section>
</article>