<article id="dialog-review-import-upload">
  <div [ngSwitch]="mode">
    <section *ngSwitchCase="'import'">
      <header class="admin-header-2">
        <div [ngSwitch]="reviewImportLog.progress_status">
          <div *ngSwitchCase="TransferStatus.START">
            <span *ngIf="!targetFile.files?.length"> File Upload </span>
            <span *ngIf="targetFile.files?.length"> File Uploaded </span>
          </div>
          <div *ngSwitchCase="TransferStatus.IN_PROGRESS">Importing reviews</div>
          <div *ngSwitchCase="TransferStatus.SUCCESSED">Import complete</div>
          <div *ngSwitchCase="TransferStatus.FAILED">Import failed</div>
          <div *ngSwitchCase="TransferStatus.CANCEL">Import canceled</div>
        </div>
      </header>
      <summary class="admin-header-description" style="margin-top: 8px">
        <div [ngSwitch]="reviewImportLog.progress_status">
          <div *ngSwitchCase="TransferStatus.START">We found {{ reviewCountFromCSV | number }} reviews in your file</div>
          <div *ngSwitchCase="TransferStatus.IN_PROGRESS">Importing {{ reviewCountFromCSV | number }} reviews in your store</div>
          <div *ngSwitchCase="TransferStatus.SUCCESSED">{{ reviewImportLog.task_count | number }} reviews imported successfully.</div>
        </div>
      </summary>

      <section
        class="upload-file-container"
        *ngIf="
          reviewImportLog.progress_status == TransferStatus.IN_PROGRESS ||
          (reviewImportLog.progress_status == TransferStatus.SUCCESSED && !reviewImportLog.error_count) ||
          reviewImportLog.progress_status == TransferStatus.START
        "
      >
        <img src="assets/icon/file_blue.svg" style="margin-right: 16px" (click)="clickInputFile()" />
        <div>
          <header class="admin-header-4">
            <span class="import-upload-filename">{{ fileName }}</span>
          </header>
          <summary class="admin-header-description">
            <ng-container [ngSwitch]="reviewImportLog.progress_status">
              <span *ngSwitchCase="TransferStatus.START">
                <span *ngIf="targetFile.files?.length"> <img src="assets/icon/check_circle_gray.svg" style="margin-right: 6px" />File Uploaded </span>
              </span>
              <alpha-progress-bar *ngSwitchCase="TransferStatus.IN_PROGRESS" [value]="progressSize"> </alpha-progress-bar>

              <span *ngSwitchCase="TransferStatus.SUCCESSED" class="import-upload-success">
                <img src="assets/icon/check_circle_blue.svg" style="margin-right: 6px" />
                Import complete
              </span>
            </ng-container>
          </summary>
        </div>
      </section>

      <!-- <section class="upload-end-container" *ngIf="reviewImportLog.progress_status == TransferStatus.FAILED"> -->
      <section *ngIf="(reviewImportLog.progress_status == TransferStatus.SUCCESSED || reviewImportLog.progress_status == TransferStatus.FAILED) && reviewImportLog.error_count">
        <section class="upload-end-container success" *ngIf="reviewImportLog.task_count">
          <div class="upload-end-title">
            <div><img src="assets/icon/check-green_small.svg" style="margin-right: 6px" /> Successfully imported</div>
            <div style="font-weight: normal">{{ reviewImportLog.task_count }} reviews</div>
          </div>
        </section>
        <section class="upload-end-container fail" *ngIf="reviewImportLog.error_count">
          <div class="upload-end-title">
            <div><img src="assets/icon/x_red_small.svg" style="margin-right: 6px" /> Failed to import</div>
            <div style="font-weight: normal">{{ reviewImportLog.error_count }} reviews</div>
          </div>

          <div class="upload-end-content">
            <div>Donwload the report to see why failed.</div>
            <div>Expired date : {{ reviewImportLog.troubleshoot_expired_at | date : 'medium' }}</div>
            <a class="upload-end-troubleshoot-url" href="https://alph.oopy.io/1a0ad946-dba1-4ef8-be28-dc37a0b03db2" target="_blank">
              How to troubleshoot errors <img src="assets/icon/share_out_red.png" style="margin-left: 6px; width: 14px; height: 14px" />
            </a>
            <div>
              <!-- Missing columns: <span *ngFor="let error of reviewImportLog.error_reason | keyvalue">
              {{error.key}}: [{{ error.value }}]
            </span> -->
            </div>
          </div>
        </section>
      </section>

      <section class="flex-wrapper" style="margin-top: 20px">
        <div></div>
        <div class="flex-wrapper">
          <button class="btn-cancel" (click)="onNoClick()">Close</button>
          <!-- <ng-container [ngSwitch]="true">
            <button class="btn-cancel"
              *ngSwitchCase="reviewImportLog.progress_status === TransferStatus.START || reviewImportLog.progress_status === TransferStatus.CANCEL || reviewImportLog.progress_status === TransferStatus.FAILED"
              (click)="onNoclick()">Close</button>
            <button class="btn-cancel"
              *ngSwitchCase="reviewImportLog.progress_status !== TransferStatus.START && reviewImportLog.progress_status !== TransferStatus.CANCEL && reviewImportLog.progress_status !== TransferStatus.FAILED"
              (click)="mode='cancel'">Cancel</button>
          </ng-container> -->

          <ng-container [ngSwitch]="true">
            <button
              *ngSwitchCase="reviewImportLog.progress_status === TransferStatus.START"
              class="btn-confirm"
              style="min-width: fit-content"
              (click)="startImportReview()"
              [disabled]="submitLock"
            >
              Import reviews
            </button>
            <button
              [disabled]="reviewImportLog.progress_status == TransferStatus.IN_PROGRESS"
              *ngSwitchCase="
                TransferStatus.START < reviewImportLog.progress_status &&
                (reviewImportLog.progress_status <= TransferStatus.SUCCESSED || reviewImportLog.progress_status == TransferStatus.FAILED)
              "
              class="btn-confirm"
              style="min-width: fit-content; width: unset"
            >
              <a *ngIf="reviewImportLog.error_count" [href]="reviewImportLog.troubleshoot_url" style="text-decoration: unset; color: white"> Download report </a>
              <a
                *ngIf="!reviewImportLog.error_count"
                style="text-decoration: unset"
                (click)="viewImportedReviewRoute()"
                [ngStyle]="{ color: reviewImportLog.progress_status >= TransferStatus.SUCCESSED ? 'white' : 'inherit' }"
              >
                See imported reviews
              </a>
            </button>
          </ng-container>
        </div>
      </section>
    </section>

    <section *ngSwitchCase="'cancel'">
      <div class="admin-header-description">Are you sure you want to stop importing reviews? All the reviews in the file you were importing will be deleted.</div>
      <div class="flex-wrapper" style="justify-content: right">
        <div class="flex-wrapper">
          <button class="btn-cancel" (click)="mode = 'import'">Cancel</button>
          <button class="btn-confirm" (click)="onCancelClick()">Yes, continue</button>
        </div>
      </div>
    </section>
  </div>
</article>

<input #targetFile type="file" accept=".xlsx, .xls, .csv" style="display: none" (change)="onFileChange()" />
