import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { AuthSuperAdminService } from 'src/app/auth/auth-super-admin.service';
import {
  PublishTargetExcel,
  POSTCATEGORY,
  PostTopic,
  SuperAdminCurrentUser,
  PostViewType,
  SuperAdminPost,
  SuperAdminPostIndustry,
} from 'src/app/models/super-admin/super-admin.shops.model';
import { SuperAdminService } from 'src/app/service/super-admin.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BackendService } from 'src/app/service/backend.service';
import { IndustryChoiceComponent } from './industry-choice/industry-choice.component';
import * as XLSX from 'xlsx';
import { Shop } from 'src/app/models/shop/shop.model';
import { ActivatedRoute, Router } from '@angular/router';
import { VerifyComponent } from './verify/verify.component';
import { ConfirmComponent } from '../../super-admin-shared/confirm/confirm.component';
import { INDUSTRY } from 'src/app/shared/preset/industry';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.less'],
})
export class CreateComponent implements OnInit {
  @ViewChild('topicInput') topicInput!: ElementRef<HTMLInputElement>;
  tmpSave = true;
  constructor(
    public dialog: MatDialog,
    @Optional() @SkipSelf() public superAdminService: SuperAdminService,
    @Optional() @SkipSelf() public authSuperAdminService: AuthSuperAdminService,
    public backendService: BackendService,
    public fb: UntypedFormBuilder,
    private activateRoute: ActivatedRoute,
    public router: Router,
  ) {
    const post_id = this.activateRoute.snapshot.paramMap.get('post_id');

    if (post_id) {
      this.getPost(post_id);
      this.tmpSave = false;
    }
  }
  industries = INDUSTRY;

  isVerified: UntypedFormControl = new UntypedFormControl(false, [Validators.required]);
  selectable = true;
  removable = true;
  addOnBlur = true;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  categories = POSTCATEGORY;

  authors!: SuperAdminCurrentUser[];

  filterdTopics!: Observable<PostTopic[]>;
  topicControl = new UntypedFormControl();
  topics: string[] = [];
  allTopics!: PostTopic[];

  postControl = new UntypedFormControl();
  filterdPosts!: Observable<SuperAdminPost[]>;
  allPosts!: SuperAdminPost[];

  excelFile!: File | null;
  unknownShops: any[] = [];

  randing = false;
  popup = false;
  feed = false;

  category = new UntypedFormControl('NOTICE', [Validators.required]);
  // topics= new FormControl([] as string[], [Validators.required])
  author = new UntypedFormControl(this.superAdminService.CurrentUser.id, [Validators.required]);
  linked_post1 = new UntypedFormControl({ title: '' });
  linked_post2 = new UntypedFormControl({ title: '' });
  linked_post3 = new UntypedFormControl({ title: '' });
  summary = new UntypedFormControl('');
  title = new UntypedFormControl('', [Validators.required]);
  content = new UntypedFormControl('', [Validators.required]);
  randingStart = new UntypedFormControl('');
  randingEnd = new UntypedFormControl('');
  feedStart = new UntypedFormControl('');
  feedEnd = new UntypedFormControl('');
  popupStart = new UntypedFormControl('');
  popupEnd = new UntypedFormControl('');
  targetIndustries = new UntypedFormControl([]);
  targetShops = new UntypedFormControl([] as PublishTargetExcel[]);
  thumbnail = new UntypedFormControl('');
  thumbnailFile!: File | null;
  is_featured = new UntypedFormControl('');
  is_fixed_top = new UntypedFormControl('');
  is_routine = new UntypedFormControl(false);

  viewType = new UntypedFormControl(PostViewType.NEW_TAB);

  ngOnInit(): void {
    this.getTopics();
    this.getAuthors();
    this.getLinkedPosts();
  }
  getPost(post_id: string) {
    let url = `super-admin/posts/${post_id}`;
    this.backendService.select(url).subscribe((response) => {
      this.category.setValue(this.categories[response.body['category']][1]);
      this.author.setValue(response.body['super_admin_account']['id']);
      if (response.body['linked_posts'][0]) {
        this.linked_post1.setValue(response.body['linked_posts'][0]);
        //this.linked_post1.patchValue({ "title": response.body['linked_posts'][0].title })
      }
      if (response.body['linked_posts'][1]) {
        this.linked_post2.setValue(response.body['linked_posts'][1]);
      }
      if (response.body['linked_posts'][2]) {
        this.linked_post3.setValue(response.body['linked_posts'][2]);
      }
      if (response.body['is_temporary'] == true) {
        this.tmpSave = true;
      }
      this.summary.setValue(response.body['summary']);
      this.title.setValue(response.body['title']);
      this.content.setValue(response.body['content']);
      this.is_featured.setValue(response.body['is_featured']);
      this.is_fixed_top.setValue(response.body['is_fixed_top']);
      this.viewType.setValue(response.body['detail_view_type']);
      this.randing = response.body['randing'];
      this.feed = response.body['feed'];
      this.popup = response.body['popup'];
      this.topics = response.body['super_admin_post_topic'].map((topic: PostTopic) => topic['value']);
      this.is_routine.setValue(response.body['is_routine']);

      if (response.body['randing']) {
        this.randing = response.body['randing'];
        if (response.body['randing_start']) {
          this.randingStart.setValue(response.body['randing_start'].slice(0, 16));
        }
        if (response.body['randing_end']) {
          this.randingEnd.setValue(response.body['randing_end'].slice(0, 16));
        }
      }
      if (response.body['feed']) {
        this.feed = response.body['feed'];
        if (response.body['feed_start']) {
          this.feedStart.setValue(response.body['feed_start'].slice(0, 16));
        }
        if (response.body['feed_end']) {
          this.feedEnd.setValue(response.body['feed_end'].slice(0, 16));
        }
      }

      if (response.body['popup']) {
        this.popup = response.body['popup'];
        if (response.body['popup_start']) {
          this.popupStart.setValue(response.body['popup_start'].slice(0, 16));
        }
        if (response.body['popup_end']) {
          this.popupEnd.setValue(response.body['popup_end'].slice(0, 16));
        }
      }
      if (response.body['super_admin_post_industry']) {
        this.targetIndustries.setValue(response.body['super_admin_post_industry'].map((industry: SuperAdminPostIndustry) => industry.value));
      }
    });
  }
  getLinkedPosts() {
    let url = 'super-admin/posts/board';
    this.backendService.select(url).subscribe((response) => {
      this.allPosts = response.body.posts;
    });
  }
  getPostOptions(keyword: UntypedFormControl) {
    this.filterdPosts = keyword.valueChanges.pipe(
      startWith(null),
      map((post) => this._post_filter(keyword.value.title || '')),
    );
  }
  getPostTitle(post: UntypedFormControl) {
    if (post.value) return post.value.title;
  }
  private _post_filter(value: string): SuperAdminPost[] {
    const filterValue = value.toLowerCase();

    return this.allPosts.filter((post: SuperAdminPost) => post.title!.toLowerCase().includes(filterValue));
  }
  selectPost(event: MatAutocompleteSelectedEvent, link: UntypedFormControl): void {
    link.setValue(event.option.value);
  }
  resetSelectedPost(link: UntypedFormControl): void {
    link.setValue({ title: '' });
  }
  getTopics() {
    let url = 'super-admin/topics';
    this.backendService.select(url).subscribe((response) => {
      this.allTopics = response.body;
      this.filterdTopics = this.topicControl.valueChanges.pipe(
        startWith(null),
        map((topic: PostTopic['value']) => (topic ? this._filter(topic) : this.allTopics.slice())),
      );
    });
  }

  private _filter(value: string): PostTopic[] {
    const filterValue = value.toLowerCase();
    return this.allTopics.filter((topic) => topic['value']!.toLowerCase().includes(filterValue));
  }

  getAuthors() {
    this.authSuperAdminService.superAdminUsers().subscribe((response) => {
      this.authors = response.body;
    });
  }
  addTopic(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.topics.push(value);
    }
    event.chipInput!.clear();

    this.topicControl.setValue;
  }

  removeTopic(topic: string): void {
    const index = this.topics.indexOf(topic);

    if (index >= 0) {
      this.topics.splice(index, 1);
    }
  }

  uploadThumbnail(event: any) {
    if (event.target.files && event.target.files.length) {
      this.thumbnailFile = event.target.files[0];
      this.thumbnail.setValue(this.thumbnailFile?.name);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.topics.push(event.option.viewValue);
    this.topicInput.nativeElement.value = '';
    this.topicControl.setValue(null);
  }

  onUploadFile(event: any) {
    if (event.target.files && event.target.files.length) {
      this.excelFile = event.target.files[0];
    }

    this.readExcelFile();
  }

  readExcelFile() {
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(this.excelFile as Blob);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws, { raw: true, header: ['shop_name', 'shop_id'] }) as PublishTargetExcel[]; // to get 2d array pass 2nd parameter as object {header: 1}
      this.verifyShops(data);
    };
  }

  verifyShops(data: PublishTargetExcel[]) {
    this.getShops().subscribe((response) => {
      let shop_name: string[] = response.body.map((shop: Shop) => shop.shop_name);
      let shop_id: number[] = response.body.map((shop: Shop) => shop.id);

      this.unknownShops = data.filter((shop) => !shop_name.includes(shop['shop_name']));
      this.unknownShops = data.filter((shop) => !shop_id.includes(shop['shop_id']));
      if (!this.unknownShops.length) this.targetShops.setValue(data.map((shop) => shop.shop_id));
    });
  }

  getShops() {
    let url = 'shops/shopify/token';
    return this.backendService.select(url);
  }

  openIndustryDialog() {
    const dialogRef = this.dialog.open(IndustryChoiceComponent, {
      height: '650px',
      width: '600px',
      data: this.targetIndustries.value,
      panelClass: 'industry-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  config = {
    placeholder: '',
    tabsize: 2,
    height: 500,
    width: 1000,
    uploadImagePath: this.backendService.makeUrl('/shops/wysiwyg/uploadfile'),
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'video', 'hr']],
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
  };

  formData = new FormData();
  createFormDate() {
    this.formData.append('category', this.category.value);
    // formData.append('topics', this.topics.value)
    this.formData.append('topics', String(this.topics));
    this.formData.append('author', this.author.value);

    this.formData.append('linked_posts', String([this.linked_post1.value.id, this.linked_post2.value.id, this.linked_post3.value.id]));
    this.formData.append('summary', this.summary.value);
    this.formData.append('title', this.title.value);
    this.formData.append('content', this.content.value);
    this.formData.append('randing', String(this.randing));
    this.formData.append('randing_start', this.addTimezone(this.randingStart.value));
    this.formData.append('randing_end', this.addTimezone(this.randingEnd.value));
    this.formData.append('feed', String(this.feed));
    this.formData.append('feed_tart', this.addTimezone(this.feedStart.value));
    this.formData.append('feed_end', this.addTimezone(this.feedEnd.value));
    this.formData.append('popup', String(this.popup));
    this.formData.append('popup_start', this.addTimezone(this.popupStart.value));
    this.formData.append('popup_end', this.addTimezone(this.popupEnd.value));
    this.formData.append(
      'target_industry',
      this.targetIndustries.value.map((industry: string) => this.industries.indexOf(industry) + 1),
    );
    this.formData.append('target_shops', String(this.targetShops.value));
    this.formData.append('is_featured', this.is_featured.value);
    this.formData.append('is_fixed_top', this.is_fixed_top.value);
    this.formData.append('is_routine', this.is_routine.value);
    this.formData.append('detail_view_type', PostViewType[this.viewType.value]);

    if (this.thumbnailFile) this.formData.append('thumbnail', this.thumbnailFile as Blob);
  }
  onSave() {
    this.createFormDate();
    this.formData.append('is_temporary', 'true');
    const post_id = this.activateRoute.snapshot.paramMap.get('post_id');
    if (post_id) {
      let url = `super-admin/posts/${post_id}`;
      this.backendService.formUpdate(url, this.formData).subscribe((response) => {
        // if(response.status==200){
        this.router.navigate(['super', 'posts']);
        // }
      });
    } else {
      let url = 'super-admin/posts';
      this.backendService.form(url, this.formData).subscribe((response) => {
        this.router.navigate(['super', 'posts']);
      });
    }
  }
  onCancel() {
    this.openConfirmDialog();
  }
  openConfirmDialog() {
    if (this.tmpSave == false) {
      const dialogRef = this.dialog.open(ConfirmComponent, {
        height: '180px',
        width: '300px',
        data: '계속 수정',
        panelClass: 'confirm-dialog',
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (!result) {
          this.router.navigate(['super', 'posts']);
        }
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmComponent, {
        height: '180px',
        width: '300px',
        data: '임시저장',
        panelClass: 'confirm-dialog',
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.onSave();
        }
        this.router.navigate(['super', 'posts']);
      });
    }
  }

  onCreate() {
    this.createFormDate();
    this.formData.append('is_temporary', 'false');

    const post_id = this.activateRoute.snapshot.paramMap.get('post_id');
    if (post_id) {
      let url = `super-admin/posts/${post_id}`;
      this.backendService.formUpdate(url, this.formData).subscribe((response) => {
        // if(response.status==200){
        this.router.navigate(['super', 'posts']);
        // }
      });
    } else {
      let url = 'super-admin/posts';
      this.backendService.form(url, this.formData).subscribe((response) => {
        this.router.navigate(['super', 'posts']);
      });
    }
  }

  openVerifyDialog() {
    const dialogRef = this.dialog.open(VerifyComponent, {
      height: '350px',
      width: '580px',
      data: this.isVerified.value,
      panelClass: 'verify-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isVerified.setValue(result);

        this.onCreate();
      }
    });
  }

  viewTypeChoice(isChecked: PostViewType) {
    if (this.randing == false) {
      this.viewType.setValue(PostViewType.MODAL);
    } else {
      this.viewType.setValue(isChecked);
    }
  }
  routineChoice(isChecked: boolean) {
    this.is_routine.setValue(isChecked);
  }
  addTimezone(time: string) {
    if (!time) {
      return '';
    }
    return new Date(time).toUTCString();
  }
}
