"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pos = exports.close = exports.Action = void 0;
var helper_1 = require("../helper");
var ActionSet_1 = require("../ActionSet");
var types_1 = require("../types");
/**
 * Pos action type enum
 * @remarks includes the action prefix and group
 *
 */
var Action;
(function (Action) {
    Action["CLOSE"] = "APP::POS::CLOSE";
    Action["LOCATION_UPDATE"] = "APP::POS::LOCATION::UPDATE";
    Action["USER_UPDATE"] = "APP::POS::USER::UPDATE";
    Action["DEVICE_UPDATE"] = "APP::POS::DEVICE::UPDATE";
})(Action = exports.Action || (exports.Action = {}));
function close() {
    return helper_1.actionWrapper({
        group: types_1.Group.Pos,
        type: Action.CLOSE,
    });
}
exports.close = close;
var Pos = /** @class */ (function (_super) {
    __extends(Pos, _super);
    function Pos(app) {
        return _super.call(this, app, types_1.Group.Pos, types_1.Group.Pos) || this;
    }
    Pos.prototype.dispatch = function (action) {
        switch (action) {
            case Action.CLOSE:
                this.app.dispatch(close());
                break;
        }
        return this;
    };
    return Pos;
}(ActionSet_1.ActionSet));
exports.Pos = Pos;
