import { Component, OnInit } from '@angular/core';
import { LandingService } from '../landing.service';

@Component({
  selector: 'app-landing-manage',
  templateUrl: './landing-manage.component.html',
  styleUrls: ['./landing-manage.component.less', '../home.component.less'],
})
export class LandingManageComponent implements OnInit {
  constructor(public landingService: LandingService) {}

  ngOnInit(): void {
    this.landingService.nextPrev();
  }

  idx: string = 'moderate';
}
