import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: [
    './coming-soon.component.less',
    '../../admin/admin.component.less',
  ],
})
export class ComingSoonComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
