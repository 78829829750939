import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AuthSuperAdminService } from 'src/app/auth/auth-super-admin.service';
import { GenericService } from 'src/app/service/generic.service';

@Component({
  selector: 'app-regist',
  templateUrl: './super-admin-regist.component.html',
  styleUrls: ['./super-admin-regist.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class SuperAdminRegistComponent implements OnInit {
  constructor(private fb: UntypedFormBuilder, private authSuperAdminService: AuthSuperAdminService, private router: Router, private activateRoute: ActivatedRoute) {}
  isValidInvitation?: boolean;

  ngOnInit(): void {
    const verify_code = this.activateRoute.snapshot.paramMap.get('pid') as string;
    this.authSuperAdminService.vefirySuperAdminInvitationCode(verify_code).subscribe({
      next: (response) => {
        this.isValidInvitation = response.body['result'];

        if (response.body['result'] != true) {
          this.router.navigate(['home', 'main']);
        }
      },
      error: (error) => {
        this.router.navigate(['home', 'main']);
      },
    });
  }
  register = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.pattern('^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$')]],
    account_id: ['', [Validators.required, Validators.pattern('^(?=.*[0-9]+)[a-zA-Z][a-zA-Z0-9]{5,15}$')]],
    password: ['', [Validators.required, Validators.pattern('^(?=.*[A-Za-z])(?=.*[0-9])[a-zA-Z0-9$@$!%*#?&~]{8,}$')]],
    nickname: ['', [Validators.required]],
  });
  upLoadFile!: File | null;
  profile_image = new UntypedFormControl('');
  verifyCode = new UntypedFormControl('');
  isVerified: boolean = false;
  passwordHide: boolean = true;

  onSubmit() {
    this.register.controls['email'].enable();

    this.authSuperAdminService.superAdminSignUp(this.register, this.upLoadFile).subscribe((response) => {
      // if(response.status == 200){
      this.router.navigate(['super', 'login']);
      // }
    });
  }

  onUpload(event: any) {
    if (event.target.files && event.target.files.length) {
      this.upLoadFile = event.target.files[0];
    }
  }

  sendVerifyCode() {
    this.authSuperAdminService.sendSuperAdminEmailCode(this.register.controls['email'].value).subscribe((response) => {});
  }

  checkVerifyCode() {
    this.authSuperAdminService.verifySuperAdminEmailCode(this.register.controls['email'].value, this.verifyCode.value).subscribe((response) => {
      if (response.status == 200) {
        this.isVerified = response.body['result'];
        this.register.controls['email'].disable();
        this.verifyCode.disable();
      }
    });
  }
}
