import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountInviteComponent } from './account-invite/account-invite.component';
import { AccountResetPasswordComponent } from './account-reset-password/account-reset-password.component';

const routes: Routes = [
  {
    path: 'invite/:accessToken',
    component: AccountInviteComponent,
  },
  {
    path: 'reset-password/:accessToken',
    component: AccountResetPasswordComponent,
  },
  { path: '**', redirectTo: '/home/main' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
