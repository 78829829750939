import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-empty-data',
  template: `
    <section class="empty-data">
      <img src="assets/image/tutorial/feed-empty.png" alt="" />
      <div class="empty-title">No results found</div>
      <div class="empty-summary" [innerHTML]="summary"></div>
      <div class="empty-btn" (click)="this.onClickRoute()" *ngIf="btnShow">
        {{ btnText }}
      </div>
    </section>
  `,
  styles: [
    `
      .empty {
        &-data {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          margin-top: 60px;
          img {
            width: 180px;
          }
        }
        &-title {
          margin-top: 15px;
          font-weight: bold;
          font-size: 16px;
        }
        &-summary {
          margin-top: 6px;
          max-width: 360px;
        }
        &-btn {
          margin-top: 16px;
          padding: 11px 16px;
          font-weight: 600;
          border: 1px solid #d7d9de;
          width: fit-content;
          border-radius: 6px;
          cursor: pointer;
        }
      }
    `,
  ],
})
export class EmptyDataComponent implements OnInit {
  @Input() summary: string = '';
  @Input() btnShow: boolean = false;
  @Input() btnText: string = '';
  @Input() route: string = '';
  @Input() url: string = '';

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.summary.replace('/', '<br>');
  }

  onClickRoute() {
    if (this.route) {
      this.router.navigate([this.route]);
    } else if (this.url) {
      window.open(this.url, '_blank');
    }
  }
}
