import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ListDesignerService } from '../list-designer.service';
import { PaginationHandler } from 'src/app/shared/handler/pagination-handler';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { GenericService } from 'src/app/service/generic.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomConfirmDialogComponent } from 'src/app/dialog/custom-confirm-dialog/custom-confirm-dialog.component';
import { inOutAnimation } from '../../animations';

@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrl: './widgets.component.less',
  animations: [inOutAnimation],
})
export class WidgetsComponent {
  @ViewChild('paginator') paginator!: MatPaginator;
  constructor(
    public listDesignerService: ListDesignerService,
    public genericService: GenericService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.listDesignerService.checkedCoreScript();
  }

  ngAfterViewInit(): void {
    this.listDesignerService.paginationHandler = new PaginationHandler(this.paginator, 0, 10);
    this.listDesignerService.getWidgetList();

    this.cdr.detectChanges();
  }

  isAll: boolean = false;

  editWidget(id: number) {
    this.router.navigate(['admin/list-designer/widget-custom'], { state: { widgetId: id } });
  }

  copyWidgetId(code: string) {
    navigator.clipboard.writeText(code).then(() => this.genericService.openSnackBar('The code has been copied to the clipboard.'));
  }

  checkedAll(event: boolean) {
    this.listDesignerService.widgets?.forEach((widget) => (widget._checked = event));
  }

  notCheckedAll() {
    this.isAll = this.listDesignerService.widgets?.every((widget) => widget._checked)!;
  }

  deletedWidget() {
    const deleteList = this.listDesignerService.widgets?.filter((widget) => widget._checked).map((widget) => widget.id);
    if (deleteList?.length) {
      this.openActionDialog('delete').subscribe((result) => {
        if (!result) return;
        this.listDesignerService.deleteWidget(deleteList!).subscribe((response) => {
          this.listDesignerService.widgets = response;
          this.listDesignerService.widgetLength = this.listDesignerService.widgets!.length;
          if (this.listDesignerService.widgetLength > 0) {
            this.listDesignerService.paginationHandler.paginator.length = this.listDesignerService.widgetLength;
          } else {
            return;
          }
          this.isAll = false;
        });
      });
    } else {
      this.openActionDialog(undefined);
    }
  }

  copyWidget() {
    const copyList = this.listDesignerService.widgets?.filter((widget) => widget._checked).map((widget) => widget.id);
    if (copyList?.length) {
      this.listDesignerService.copyWidget(copyList).subscribe((response) => {
        this.listDesignerService.widgets = response.body;
        this.listDesignerService.paginationHandler.paginator.length = this.listDesignerService.widgets?.length ?? 0;
        this.genericService.openSnackBar('A copy of the requested widget has been created.');
        this.isAll = false;
      });
    } else {
      this.openActionDialog(undefined);
    }
  }

  openActionDialog(actionType: 'delete' | undefined) {
    let width = actionType ? '310px' : '230px';
    let dialogRef = this.dialog.open(CustomConfirmDialogComponent, { width: width });
    let instance = dialogRef.componentInstance;
    instance.alignCenter = true;
    if (actionType === 'delete') {
      instance.content = 'Are you sure you want to delete this?\nOnce deleted, it cannot be restored.';
    } else {
      instance.content = 'Select a widget';
      instance.hideCancel = true;
    }

    return dialogRef.afterClosed();
  }
}
