import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingBenefitService } from '../service/setting-benefit.service';
import { LineItem } from '../models/order/line-item.model';
import { Review, ReviewType } from '../models/review/review.model';
import { ShopBenefit } from '../models/shop/auth/benefit/benefit.model';
import { CommonDesign } from '../models/widget/widget.model';
import { BackendService } from '../service/backend.service';
import { WriteDialogNoticeComponent } from './write-dialog/write-dialog-notice/write-dialog-notice.component';
import { ReviewFilters } from '../service/review.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class WriteService {
  #completedReviewLineItem!: LineItem;
  #completedReview!: Review;

  #earnedReward: string = '0';

  productPlatformIdKey: string = '_wppi';
  shopUrlKey: string = '_su';
  reviewAvailableVerifiedCustomerKey: string = '_ravc';

  lineItems: LineItem[] = [];

  writedReviews: Review[] = [];

  commonDesign!: CommonDesign;

  shopBenefit!: ShopBenefit;

  myReviewsFilters: ReviewFilters = new ReviewFilters();

  constructor(private backendService: BackendService, private benefitService: SettingBenefitService, private dialog: MatDialog) {
    this.getBenefitPolicyDetail();
  }

  get primaryColor() {
    return sessionStorage.getItem('primaryColor')!;
  }

  set primaryColor(mainColor: string) {
    sessionStorage.setItem('primaryColor', mainColor);
  }

  get secondaryColor() {
    return sessionStorage.getItem('secondaryColor')!;
  }

  set secondaryColor(pointColor: string) {
    sessionStorage.setItem('secondaryColor', pointColor);
  }

  get completedReview() {
    return this.#completedReview;
  }

  set completedReview(review: Review) {
    this.#completedReview = review;
  }

  get completedReviewLineItem() {
    return this.#completedReviewLineItem;
  }

  set completedReviewLineItem(lineItem: LineItem) {
    this.#completedReviewLineItem = lineItem;
  }

  get earnedReward(): string {
    return this.#earnedReward;
  }

  set earnedReward(reward: string) {
    this.#earnedReward = reward;
  }

  get productPlatformId() {
    return sessionStorage.getItem(this.productPlatformIdKey) ? sessionStorage.getItem(this.productPlatformIdKey)! : '';
  }

  set productPlatformId(shopUrl: string) {
    sessionStorage.setItem(this.productPlatformIdKey, shopUrl);
  }

  get shopUrl() {
    return sessionStorage.getItem(this.shopUrlKey) ? sessionStorage.getItem(this.shopUrlKey)! : '';
  }

  set shopUrl(shopUrl: string) {
    sessionStorage.setItem(this.shopUrlKey, shopUrl);
  }

  get reviewAvailableVerifiedCustomer() {
    return JSON.parse(sessionStorage.getItem(this.reviewAvailableVerifiedCustomerKey!)!) ? JSON.parse(sessionStorage.getItem(this.reviewAvailableVerifiedCustomerKey!)!) : false;
  }

  set reviewAvailableVerifiedCustomer(bool: boolean) {
    sessionStorage.setItem(this.reviewAvailableVerifiedCustomerKey, JSON.stringify(bool));
  }

  getCommonDesign(shop_access_code: string) {
    let url = 'widget/design/common';
    this.backendService.select(url, { shop_access_code: shop_access_code }).subscribe((response) => {
      this.commonDesign = response.body;

      this.primaryColor = this.commonDesign.main_color;
      this.secondaryColor = this.commonDesign.point_color;
    });
  }

  getLineItems(reviewType: ReviewType[], shortCode: string) {
    let url = 'orders/line-items';
    return this.backendService.select(url, {
      review_type: reviewType,
      short_code: shortCode,
    });
  }

  getBenefitPolicyDetail(params: {} = {}) {
    let url = 'review-benefit/detail';

    return this.backendService.select(url, params);
  }

  getShopBenefit(params: { shop_access_code: string }) {
    return this.benefitService.getShopReviewBenefit(params);
  }

  getMyReviews(shopAccessCode: string, reviewFilter: ReviewFilters) {
    let url = 'reviews';
    return this.backendService.select(url, {
      filters: JSON.stringify(reviewFilter),
      is_mypage: true,
      shop_access_code: shopAccessCode,
    });
  }

  getDiscountCode(shortCode: string) {
    let url = 'review-benefit/mypage/coupons';
    return this.backendService.select(url, {
      short_code: shortCode,
    });
  }
  openDialogNotice(data: { header: string; content: string; btn: number }): Observable<any> {
    const dialogRef = this.dialog.open(WriteDialogNoticeComponent, {
      width: '280px',
      data: data,
    });
    return dialogRef.afterClosed();
  }

  close() {
    if (this.inIframe()) {
      window.parent.postMessage('storeReviewClose', 'https://front.global-alpha-dev.click');
      window.parent.postMessage('storeReviewClose', `https://${this.backendService.baseUrl}`);
      window.parent.postMessage('storeReviewClose', `https://${this.shopUrl}`);
    } else {
      window.close();
    }

    // const dialogRef = this.dialog.open(DialogLeavePageComponent, {
    //   width: '80vw',
    //   maxWidth: '360px',
    //   panelClass: 'leave-page-dialog',
    //   disableClose: true,
    // });
    // dialogRef.afterClosed().subscribe((result) => {
    //   if (result) {
    //     // window.parent.postMessage('storeReviewClose', '*');
    //   }
    // });
  }
  inIframe(): boolean {
    if (window.self !== window.top) {
      return true;
    } else {
      return false;
    }
  }
}
