import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'alpha-progress-bar',
  templateUrl: './custom-progress-bar.component.html',
  styleUrls: ['./custom-progress-bar.component.less']
})
export class CustomProgressBarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input('value') value: number = 0
  @Input('isSuccess') isSuccess: boolean = true
}
