<article id="aliexpress-analysis" class="setting-article" style="max-width: 1240px">
  <header class="admin-header-1">AliExpress Review Analysis</header>
  <section class="flex-wrapper" style="margin-top: 20px">
    <div class="select-input-box">
      <select class="select-input-select" [(ngModel)]="aiService.searchAbout">
        <option [ngValue]="'product_name'" selected>Product Name</option>
      </select>
      <form class="admin-form">
        <input [ngModelOptions]="{ standalone: true }" [(ngModel)]="aiService.searchKeyword" (keydown.enter)="this.aiService.getAnalysisList()" placeholder="Search" />
        <button (click)="this.aiService.getAnalysisList()">
          <img src="assets/icon/search_gray.svg" />
        </button>
      </form>
    </div>
    <div class="flex-wrapper" style="gap: 10px">
      <div
        *ngIf="analysisStatus"
        (click)="onClickNewBtn(analysisStatus); $event.preventDefault()"
        [ngClass]="{
          active: analysisStatus.status === AnalysisLogStatus.ANALYSIS_IN_PROGRESS,
          disabled: analysisStatus.status === AnalysisLogStatus.ANALYSIS_LIMITED
        }"
        id="analysis-request-btn"
        class="btn-black"
        style="width: 200px; gap: 6px"
      >
        <ng-container *ngIf="analysisStatus.status === AnalysisLogStatus.ANALYSIS_IN_PROGRESS">
          <img class="rotate" src="assets/icon/circle_arrow.svg" />
          Analyzing...
        </ng-container>
        <ng-container *ngIf="analysisStatus.status !== AnalysisLogStatus.ANALYSIS_IN_PROGRESS"> + Request New Analysis </ng-container>

        <ng-container *ngIf="analysisStatus.status === AnalysisLogStatus.ANALYSIS_LIMITED && showTooltip">
          <div (click)="stopTimer(); $event.stopPropagation()" class="limit-tooltip" [@inOutAnimation]>
            You've reached today's limit of 10 requests. More analysis available in {{ remainingSeconds }}
            <img src="assets/icon/close_mini.svg" />
          </div>
        </ng-container>
      </div>
      <div *ngIf="!aiService.isEmpty" [routerLink]="['log']" id="analysis-log-btn" class="btn-white" style="width: 110px; height: 36px">Analysis Log</div>
    </div>
  </section>
  @if (!aiService.isEmpty) {
  <section class="analysis-container">
    <ng-container *ngFor="let analysis of aiService.analysisList">
      <app-ali-analysis-template [analysis]="analysis" style="flex: 1; min-width: 30%; max-width: 360px"></app-ali-analysis-template>
    </ng-container>
  </section>

  } @else {
  <section class="gray-border-wrapper" [@inOutAnimation]>
    <section class="analysis-empty">
      <div class="ai-icon">AI</div>
      <header class="admin-header-1">Have your AliExpress product URL ready to go!</header>
      <summary class="admin-content-description" style="padding: 5px 0 18px">
        This simple preparation paves the way for in-depth review analysis, equipping you <br />
        with the insights needed to drive strategic decisions.
      </summary>

      <div class="analysis-empty-img"><img src="assets/image/ai/ex_ai.png" style="vertical-align: bottom" /></div>

      <section class="flex-wrapper" style="gap: 10px">
        <div (click)="openDialogSampleProducts()" class="btn-white" style="width: 208px; height: 36px">Try With Sample Products</div>
        <div (click)="onClickNewBtn(analysisStatus); $event.preventDefault()" class="btn-black" style="width: 102px">I'm Ready!</div>
      </section>
    </section>
  </section>
  }
  <section [ngStyle]="!aiService.isEmpty ? { opacity: 1 } : { opacity: 0 }">
    <mat-paginator
      #paginator
      (page)="aiService.getAnalysisList($event)"
      [pageSizeOptions]="[10, 20, 100]"
      [disabled]="aiService.manageAnalysisLoading"
      showFirstLastButtons
      aria-label="Select page"
      ><input class="admin-input" />
    </mat-paginator>
  </section>

  @if (firstLoading) {
  <app-analysis-loader [loading]="firstLoading"></app-analysis-loader>
  }
</article>
