<article id="nav">
  <div class="nav-header">
    <img class="alpha-review-logo" src="assets/image/super-admin/alpa-review-full-logo.png" />
    <div class="shortcuts">
      <a class="shortcut" (click)="toShop()"> 쇼핑몰 <img src="assets/image/super-admin/super-admin-shortcut.svg" width="14px" /></a>
      <a class="shortcut" (click)="toDashboard()"> 대시보드 <img src="assets/image/super-admin/super-admin-shortcut.svg" width="14px" /></a>
    </div>
    <mat-divider></mat-divider>
    <div class="info-options">
      <div class="info-option" (click)="setOptionInfo()">쇼핑몰 정보</div>
      <div class="info-option" (click)="setOptionStatus()">상태</div>
    </div>
  </div>
</article>
