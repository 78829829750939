import { Injectable, Optional, SkipSelf } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SuperAdminCurrentUser } from '../models/super-admin/super-admin.shops.model';
import { SuperAdminService } from '../service/super-admin.service';
@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuard  {
  constructor(
    private router: Router,
    @Optional() @SkipSelf() public superadminservice: SuperAdminService,
  ) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    try {
      if (!sessionStorage.getItem('super_admin_access_token')) {
        this.router.navigate(['super', 'login'])
        alert("로그인을 해주세요")
        return false;
      }
    } catch {
      this.router.navigate(['super', 'login'])
      alert("로그인이 만료되었습니다.")
      return false;
    }
    return true;
  }

}
