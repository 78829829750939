import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'alpha-toggle-btn',
  templateUrl: './custom-toggle-btn.component.html',
  styleUrls: ['./custom-toggle-btn.component.less']
})
export class CustomToggleBtnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() sourceBoolean: boolean = false
  @Input() negativeText: string = ''
  @Input() positiveText: string = ''
  @Input() disabled: boolean = false
  @Output() sourceBooleanChange = new EventEmitter<boolean>();

  toggle() {
    this.sourceBooleanChange.emit(this.sourceBoolean)
  }
}
