import { Component, OnInit } from '@angular/core';
import { GenericService } from 'src/app/service/generic.service';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.less'],
})
export class MarketingComponent implements OnInit {
  constructor(public genericService: GenericService) {}

  ngOnInit(): void {}
}
