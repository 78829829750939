"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavigationMenu = exports.update = exports.Action = void 0;
var AppLink_1 = require("../../Link/AppLink");
var helper_1 = require("../../helper");
var ActionSet_1 = require("../../ActionSet");
var types_1 = require("../../types");
var SUBGROUPS = ['Navigation_Menu'];
var Action;
(function (Action) {
    Action["UPDATE"] = "APP::MENU::NAVIGATION_MENU::UPDATE";
    Action["LINK_UPDATE"] = "APP::MENU::NAVIGATION_MENU::LINK::UPDATE";
})(Action = exports.Action || (exports.Action = {}));
function update(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.Menu,
        type: Action.UPDATE,
    });
}
exports.update = update;
var NavigationMenu = /** @class */ (function (_super) {
    __extends(NavigationMenu, _super);
    function NavigationMenu(app, options) {
        var _this = _super.call(this, app, 'Navigation_Menu', types_1.Group.Menu) || this;
        _this.items = [];
        // Trigger 'update' on creation
        _this.set(options);
        return _this;
    }
    Object.defineProperty(NavigationMenu.prototype, "options", {
        get: function () {
            return {
                items: this.itemsOptions,
                active: this.activeOptions,
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NavigationMenu.prototype, "payload", {
        get: function () {
            return __assign(__assign({}, this.options), { active: this.active, items: this.items, id: this.id });
        },
        enumerable: false,
        configurable: true
    });
    NavigationMenu.prototype.set = function (options, shouldUpdate) {
        if (shouldUpdate === void 0) { shouldUpdate = true; }
        var mergedOptions = helper_1.getMergedProps(this.options, options);
        var items = mergedOptions.items, active = mergedOptions.active;
        this.setItems(items);
        this.activeOptions = active;
        this.active = active && active.id;
        if (shouldUpdate) {
            this.dispatch(Action.UPDATE);
        }
        return this;
    };
    NavigationMenu.prototype.dispatch = function (action) {
        switch (action) {
            case Action.UPDATE:
                this.app.dispatch(update(this.payload));
                break;
        }
        return this;
    };
    NavigationMenu.prototype.updateItem = function (newPayload) {
        if (!this.items) {
            return;
        }
        var itemToUpdate = this.items.find(function (action) { return action.id === newPayload.id; });
        if (!itemToUpdate) {
            return;
        }
        if (helper_1.updateActionFromPayload(itemToUpdate, newPayload)) {
            this.dispatch(Action.UPDATE);
        }
    };
    NavigationMenu.prototype.setItems = function (newOptions) {
        var _this = this;
        var newItems = newOptions || [];
        var currentItems = this.itemsOptions || [];
        this.itemsOptions = this.getUpdatedChildActions(newItems, currentItems);
        this.items = this.itemsOptions
            ? this.itemsOptions.map(function (action) {
                _this.addChild(action, _this.group, SUBGROUPS);
                _this.subscribeToChild(action, AppLink_1.Action.UPDATE, _this.updateItem);
                return action.payload;
            })
            : [];
    };
    return NavigationMenu;
}(ActionSet_1.ActionSetWithChildren));
exports.NavigationMenu = NavigationMenu;
