import { Injectable } from '@angular/core';
import { Product, ProductStatus } from '../models/product/product.model';
import { ReviewType } from '../models/review/review.model';
import { BackendService } from './backend.service';
import { AdminService } from './admin.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private backendService: BackendService, private adminService: AdminService) {}

  products: Product[] = [];
  selectedProduct!: Product;
  productFilters: ProductFilters = new ProductFilters();
  dateAbout: 'all' | 'review_created_at' | 'created_at' | 'updated_at' | 'published_at' = 'all';
  searchAbout: 'all' | 'customer_name' | 'customer_phone' | 'customer_email' | 'content' | 'published' | 'unpublished' = 'all';
  publishedSearch: 'all' | 'published' | 'unpublished' = 'all';
  sortingType: 'rating' | '-rating' | 'review_count' | '-review_count' | 'newest' = 'review_count';
  searchContent: string = '';

  getProducts(params: {}) {
    let url = 'products';

    return this.backendService.select(url, params);
  }

  getProductDetail(productID: number, params: {}) {
    let url = 'products/' + productID;
    return this.backendService.select(url, params);
  }

  getManageProducts(params: {} = {}) {
    const queryParams = { ...this.getManageProductParams(), ...params };

    this.getProducts(queryParams).subscribe((response) => {
      this.products = response.body.data as Product[];
    });
  }

  getManageProductParams() {
    return {
      shop_access_code: this.adminService.getShopAccessCode(),
      embed: ['product_judge'],
      filters: JSON.stringify(this.productFilters),
      search_about: this.searchAbout,
      search_content: this.searchContent,
      sorting_type: this.sortingType,
      date_about: this.dateAbout,
    };
  }

  get ProductStatus(): typeof ProductStatus {
    return ProductStatus;
  }
}

class ProductFilters {
  is_fixed_review: boolean[] = [];
  is_admin_review: boolean[] = [];
  review_type: ReviewType[] = [];
  is_quick: boolean[] = [];
  is_text: boolean[] = [];
  is_photo: boolean[] = [];
  is_video: boolean[] = [];
  is_survey: boolean[] = [];
  review_count: number[] = [];
  rating: number[] = [];
  status: ProductStatus[] = [];
  //collection_id: string[] = [];
}
