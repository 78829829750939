<article id="manage-review-export">
  <header class="admin-header-3">Export Reviews</header>
  <section class="review-export-description">
    <div class="flex-wrapper"><img src="assets/icon/note_green.svg" />Note</div>
    Download your review data as a CSV file. Once you request a download, it will be emailed to you <br />
    within 2-3 hours, depending on the data size. You can also find the file in the file archive.
  </section>

  <section class="flex-wrapper review-export-input-wrapper">
    <header class="admin-header-4" style="flex-shrink: 0">Email to Receive Data</header>
    <div class="review-export-input">
      <input [formControl]="email" placeholder="Email Address" class="admin-input" autofocus style="width: 100%" />
      <button class="btn-black" (click)="reviewExport()" style="flex-shrink: 0">Request Review Data</button>
    </div>
  </section>

  <section class="review-export-contents">
    <header class="admin-header-4">Archived Files</header>

    <section style="margin-top: 15px">
      <header class="content-table-header" style="justify-content: space-between; gap: 20px; padding: 10px">
        <div class="content-table-name" style="width: 170px">Request date and time</div>
        <div class="content-table-name" style="width: 160px">Email</div>
        <div class="content-table-name" style="width: 170px">Downloadable Due Date</div>
        <div class="content-table-name" style="width: 112px; text-align: center">Status</div>
      </header>
      <section class="review-export-content-scroll">
        <ng-container *ngFor="let log of reviewImportLog">
          <section class="content-table-row" style="align-items: center; justify-content: space-between; gap: 20px; padding: 15px 10px">
            <section class="content-table-row-request-date" style="width: 170px">{{ log.created_at | date : 'MMM dd, yyyy a h:mm' }}</section>
            <section class="content-table-row-email" style="width: 160px">{{ log.destination_email }}</section>
            <section class="content-table-downloadable-date" style="width: 170px">
              {{ log.export_created_at | date : 'MMM dd, yyyy a h:mm' }} - {{ log.export_expired_at | date : 'MMM dd, yyyy a h:mm' }}
            </section>
            <section class="content-table-row-status" style="width: 112px; text-align: center">
              <ng-container [ngSwitch]="log.progress_status">
                <span class="created" *ngSwitchCase="reviewService.TransferStatus.TASK_CREATED"> Task created </span>
                <span class="processing" *ngSwitchCase="reviewService.TransferStatus.START"> Request processing </span>
                <span class="download" *ngSwitchCase="reviewService.TransferStatus.SUCCESSED">
                  <a class="link-btn" [href]="log.export_url" target="_blank"> Download </a>
                </span>
                <span class="failed" *ngSwitchCase="reviewService.TransferStatus.FAILED" matTooltip="{{ log.error_reason }}"> Failed </span>
                <span class="expired" *ngSwitchCase="reviewService.TransferStatus.EXPIRED"> Expired </span>
              </ng-container>
            </section>
          </section>
        </ng-container>
      </section>

      <div class="flex-wrapper review-export-close">
        <div (click)="onNoClick()" class="btn-white" style="width: 80px; height: 36px">Cancel</div>
      </div>
    </section>
  </section>
</article>
