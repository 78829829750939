import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlphaAiRoutingModule } from './alpha-ai-routing.module';
import { AiAliexpressComponent } from './ai-aliexpress/ai-aliexpress.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AliAnalysisTemplateComponent } from './ai-aliexpress/ali-analysis-template/ali-analysis-template.component';
import { AnalysisDetailComponent } from './analysis-detail/analysis-detail.component';
import { AnalysisLoaderComponent } from './analysis-loader/analysis-loader.component';
import { SwiperModule } from 'swiper/angular';
import { DialogAliImportComponent } from './ai-aliexpress/ali-dialog/dialog-ali-import/dialog-ali-import.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AliAnalysisLogComponent } from './ai-aliexpress/ali-analysis-log/ali-analysis-log.component';
import { AlphaAiComponent } from './alpha-ai.component';

@NgModule({
  declarations: [
    AlphaAiComponent,
    AiAliexpressComponent,
    AliAnalysisTemplateComponent,
    AnalysisDetailComponent,
    AnalysisLoaderComponent,
    DialogAliImportComponent,
    AliAnalysisLogComponent,
  ],
  imports: [CommonModule, AlphaAiRoutingModule, SharedModule, MatPaginatorModule, SwiperModule, MatTooltipModule],
})
export class AlphaAiModule {}
