<article id="store-setting">
  <section class="input-field" style="margin-top: 0" *ngIf="type !== 'onboarding'">
    <header class="header-3">Company Name</header>
    <div class="input-container">
      <input
        id="company-name"
        class="admin-input"
        type="text"
        style="width: 300px"
        placeholder="Company Name"
        [(ngModel)]="shopDetail.company_name"
        (ngModelChange)="onChangeShopDetail()"
      />
    </div>
  </section>

  <section class="input-field">
    <header class="header-3">Shop Name</header>
    <div class="input-container">
      <input
        id="shop-name"
        class="admin-input"
        type="text"
        style="width: 300px"
        placeholder="Shop Name"
        [(ngModel)]="shopDetail.store_name"
        (ngModelChange)="onChangeShopDetail()"
      />
    </div>
  </section>

  <section class="input-field">
    <header class="header-3">Industry</header>
    <div class="input-container">
      <select #industry placeholder="Select" style="width: 220px" required [ngModel]="shopDetail.industry" (change)="onSelected(industry.value)">
        <option value="" selected disabled *ngIf="type == 'onboarding'">Select</option>
        <option *ngIf="type !== 'onboarding'" [ngValue]="shopDetail.industry">
          {{ shopDetail.industry }}
        </option>
        <option *ngFor="let industry of industries" [ngValue]="industry">
          {{ industry }}
        </option>
      </select>
    </div>
  </section>

  <section class="input-field">
    <header class="header-3">Shop URL</header>
    <div class="input-container">
      <input
        id="shop-url"
        class="admin-input"
        type="text"
        style="width: 300px; color: #91949c"
        placeholder="Shop URL"
        disabled
        [(ngModel)]="shopDetail.store_url"
        (ngModelChange)="onChangeShopDetail()"
      />
    </div>
  </section>

  <section class="input-field">
    <header class="header-3" style="align-self: flex-start">Shop Logo</header>
    <div style="position: relative">
      <div class="image-preview">
        <img *ngIf="shopDetail.store_logo" [src]="image" />
      </div>
      <div class="filebox">
        <form action="">
          <input class="upload-name" value="{{ fileName }}" placeholder="No files selected" style="width: 220px" />
          <label for="file">Select a File</label>
          <input type="file" id="file" name="image" #imageInput accept="image/*" (change)="onFileChanged($event)" />
        </form>
      </div>
      <div class="logo-note">
        <img src="assets/icon/info_deep_gray.svg" />
        Recommended Size: 105*40
      </div>
    </div>
  </section>
</article>
