import { Component, OnInit, ViewChild } from '@angular/core';
import { ShopNotice, NoticeStatus } from 'src/app/models/shop/shop-notice.model';
import { BackendService } from 'src/app/service/backend.service';
import { GenericService } from 'src/app/service/generic.service';
import { AdminService } from 'src/app/service/admin.service';
import { CustomerServiceNoticeDetailComponent } from '../customer-service-notice-detail/customer-service-notice-detail.component';
import { DialogCustomerNoticeDeleteComponent } from '../dialog-customer-notice-delete/dialog-customer-notice-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-customer-service-notice',
  templateUrl: './customer-service-notice.component.html',
  styleUrls: ['./customer-service-notice.component.less'],
})
export class CustomerServiceNoticeComponent implements OnInit {
  @ViewChild('noticeTable') noticeTable!: MatTable<ShopNotice>;
  constructor(private backendService: BackendService, private adminService: AdminService, public dialog: MatDialog, public genericService: GenericService) {}

  ngOnInit(): void {
    this.getShopNotices();
  }

  emptyData: boolean = false;
  noticeDisplayedColumns: string[] = ['status', 'title', 'posting_end', 'created_at', 'delete'];
  noticeDataSource: ShopNotice[] = [];
  deepLinkingUrl?: string;

  getShopNotices() {
    let url = 'shops/' + this.backendService.shop_id + '/notices';

    this.backendService.select(url).subscribe((response) => {
      this.noticeDataSource = response.body;
      this.noticeDataSource.length == 0 ? (this.emptyData = true) : (this.emptyData = false);
      this.noticeTable.renderRows();
    });
  }

  addShopNotice() {
    let shopNotice = new ShopNotice();
    shopNotice.shop_id = this.backendService.shop_id;
    shopNotice.update = true;
    this.openCreateNoticeDialog(shopNotice);
  }

  detailShopNotice(shopNotice: ShopNotice) {
    this.openCreateNoticeDialog(shopNotice);
  }

  openCreateNoticeDialog(shopNotice: ShopNotice) {
    const dialogRef = this.dialog.open(CustomerServiceNoticeDetailComponent, {
      width: '900px',
      maxHeight: '90vh',
      minHeight: '500px',
      height: 'fit-content',
      data: shopNotice,
    });

    dialogRef.afterClosed().subscribe((shopNotice) => {
      if (shopNotice) {
        if (shopNotice.id) {
          this.updateShopNotice(shopNotice);
        } else {
          this.saveShopNotice(shopNotice);
        }

        shopNotice.update = false;
        this.getShopNotices();
      }
    });
  }

  saveShopNotice(shopNotice: ShopNotice) {
    let url = 'shops/' + this.backendService.shop_id + '/notice';

    this.backendService.create(url, shopNotice).subscribe((response) => {
      this.getShopNotices();
    });
  }

  updateShopNotice(shopNotice: ShopNotice) {
    let url = 'shops/' + this.backendService.shop_id + '/notice/' + shopNotice.id;

    this.backendService.update(url, shopNotice).subscribe((response) => {
      this.getShopNotices();
    });
  }

  deleteShopNotice(notice_id: number) {
    const dialogRef = this.dialog.open(DialogCustomerNoticeDeleteComponent, {
      width: '480px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let url = 'shops/' + this.backendService.shop_id + '/notice/' + notice_id;
        this.backendService.delete(url).subscribe((response) => {
          this.getShopNotices();
        });
      }
    });
  }

  getDeepLinkingUrl() {
    let url = 'widget/deeplinking';

    this.backendService
      .select(url, {
        shop_access_code: this.adminService.getShopAccessCode(),
      })
      .subscribe((response) => {
        let deepLinkingUrl = response.body.app_embed_block_url;
        window.open(deepLinkingUrl);
      });
  }

  get NoticeStatus(): typeof NoticeStatus {
    return NoticeStatus;
  }
}
