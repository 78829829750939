import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenericService } from 'src/app/service/generic.service';

@Component({
  selector: 'counter-text-length',
  template: `
      <span class="text-length-container">{{genericService.getUnicodeTextLength(text)}}/{{maxLength}}</span>
  `,
  styles: [
  ]
})
export class CounterTextLengthComponent implements OnInit {

  constructor(public genericService: GenericService) { }

  @Input() text = ''
  @Output() textChange = new EventEmitter<string>();

  @Input() maxLength = 0



  changeText() {
    this.textChange.emit(this.text)
  }

  ngOnInit(): void {
  }

}
