import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountSettingComponent } from './account-setting/account-setting.component';
import { StoreSettingComponent } from './store-setting/store-setting.component';
import { MatTabGroup } from '@angular/material/tabs';
import { LeftNavigationBarService } from '../layout/left-navigation-bar/left-navigation-bar.service';
import { AdminService } from 'src/app/service/admin.service';
import { AlphaApps } from 'src/app/models/shop/profile/store-detail.model';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.less'],
})
export class SettingComponent implements OnInit {
  @ViewChild(StoreSettingComponent) store!: StoreSettingComponent;
  @ViewChild(AccountSettingComponent) account!: AccountSettingComponent;
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  constructor(private lnbService: LeftNavigationBarService, private adminService: AdminService) {}

  ngOnInit(): void {
    this.lnbService.selectedApp = undefined;
    this.useAlphaPlus = this.adminService.ShopAdminAccount.shop?.shop_detail?.use_apps.some(
      (app) => app === AlphaApps.AI || app === AlphaApps.LIST_DESIGNER || app === AlphaApps.REVIEW,
    );
  }

  useAlphaPlus?: boolean;

  ngAfterViewInit() {
    if (history.state.tabType == 'store') this.tabGroup.selectedIndex = 0;
    else if (history.state.tabType == 'account') this.tabGroup.selectedIndex = 1;
    else if (history.state.tabType == 'subscription') this.tabGroup.selectedIndex = 2;
  }

  onConfirm() {
    this.store.patchShopContactInformation();
    this.store.patchShopDetail();
  }

  onCancel() {
    this.store.getShopDetail();
    this.store.getShopContactInformation();
  }
}
