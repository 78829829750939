import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminGuard } from '../guard/admin.guard';
import { AdminComponent } from './admin.component';
import { LoginBridgeComponent } from './login/login-bridge/login-bridge.component';
import { LoginComponent } from './login/login.component';
import { SettingComponent } from './setting/setting.component';
import { MarketPlaceComponent } from './market-place/market-place.component';
import { BrowseBoosterComponent } from './browse-booster/browse-booster.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: 'admin/login', component: LoginComponent },
      { path: 'admin/login/demo', component: LoginComponent, data: { demoLogin: true } },
      { path: 'admin/login/bridge', component: LoginBridgeComponent },
      { path: 'admin/login/bridge/:shopAdminAccountToken', component: LoginBridgeComponent },
      { path: 'admin/login/bridge/:shopAdminAccountToken/:isEmbeded', component: LoginBridgeComponent },
      { path: 'admin/onboarding/:shopAccessCode', data: { title: 'Onboarding' }, loadChildren: () => import('./onboarding/onboarding.module').then((m) => m.OnboardingModule) },
      {
        path: 'admin/onboarding/:shopAccessCode/:isEmbeded',
        data: { title: 'Onboarding' },
        loadChildren: () => import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
      },
      { path: 'admin/account', data: { title: 'Account' }, loadChildren: () => import('./account/account.module').then((m) => m.AccountModule) },

      {
        path: 'admin',
        canActivate: [AdminGuard],
        component: AdminComponent,
        children: [
          {
            path: 'settings',
            canActivate: [AdminGuard],
            data: { title: 'Settings' },
            component: SettingComponent,
          },
          {
            path: 'market-place',
            canActivate: [AdminGuard],
            data: { title: 'Market Place' },
            component: MarketPlaceComponent,
          },
          {
            path: 'product-reviews',
            canActivate: [AdminGuard],
            data: { title: 'Product Reviews' },
            loadChildren: () => import('./product-reviews/product-reviews-routing.module').then((m) => m.ProductReviewsRoutingModule),
          },
          {
            path: 'ai-analysis',
            canActivate: [AdminGuard],
            data: { title: 'Ai Analysis' },
            loadChildren: () => import('./alpha-ai/alpha-ai-routing.module').then((m) => m.AlphaAiRoutingModule),
          },
          {
            path: 'list-designer',
            canActivate: [AdminGuard],
            data: { title: 'List Designer' },
            loadChildren: () => import('./list-designer/list-designer-routing.module').then((m) => m.ListDesignerRoutingModule),
          },
          {
            path: 'browse-booster',
            canActivate: [AdminGuard],
            data: { title: 'Browse Booster' },
            component: BrowseBoosterComponent,
          },
          {
            path: 'profile',
            canActivate: [AdminGuard],
            data: { title: 'Profile' },
            loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
          },
          { path: '', pathMatch: 'full', redirectTo: 'market-place' },
          { path: 'home', pathMatch: 'full', redirectTo: 'market-place' },
        ],
      },
      { path: '', pathMatch: 'full', redirectTo: './home' },
    ]),
  ],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
