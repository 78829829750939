import { ReviewAppPlatform } from '../shop/review/options/review-import.model';

export interface AiAnalysis {
  id: number;
  shop_id: number;
  shop_access_code: string;
  product_name: string;
  analysis_log_id: number;
  sold_count: string | number;
  review_count: number;
  rating: number;
  paragraph_summary: string;
  positive_proportion: number;
  negative_proportion: number;
  positive_keyword_list: [string, number][];
  negative_keyword_list: [string, number][];
  latest_review_date: Date;
  oldest_review_date: Date;

  analysis_log: AnalysisLog;
}

export interface AnalysisLog {
  app_platform: ReviewAppPlatform;
  created_at: Date;
  expired_at: Date;
  id: number;
  image: string;
  product_id: number;
  rating: number;
  review_count: number;
  review_import_log_id: number;
  shop_id: number;
  sold: string;
  title: string;
  url: string;
  is_review_saved: boolean;
}

export interface AnalysisLogStatusValue {
  status: AnalysisLogStatus;
  limit_start_datetime: Date | null; // 요청 가능한 횟수를 다 사용했을 경우에만 생성되는 날짜 데이터, 분석 가능 횟수가 남아 있을 경우 = null;
  remaining_count: number;
}

export interface AliexpressVerifyUrlList {
  error_reason: string;
  index: number;
  is_verify: boolean;
}

export enum AnalysisLogStatus {
  ANALYSIS_IN_PROGRESS = 10,
  ANALYSIS_COMPLETED = 20,
  ANALYSIS_LIMITED = 30,
}

export enum AnalysisLogProgressStatus {
  CREATED = 10,
  STARTED = 20,
  CANCELED = 30,
  SUCCESS = 40,
  FAILED = 50,
}
