import { Component, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/service/admin.service';
import { GenericService } from 'src/app/service/generic.service';
import { DialogAppGuidanceComponent } from '../dialog-app-guidance/dialog-app-guidance.component';
import { LeftNavigationBarService } from '../left-navigation-bar.service';
import { AlphaApps } from 'src/app/models/shop/profile/store-detail.model';

@Component({
  selector: 'app-product-reviews-nav',
  templateUrl: './product-reviews-nav.component.html',
  styleUrl: './product-reviews-nav.component.less',
})
export class ProductReviewsNavComponent {
  @ViewChildren(MatExpansionPanel) matExpansionPanelQueryList?: QueryList<MatExpansionPanel>;
  constructor(
    public genericService: GenericService,
    public lnbService: LeftNavigationBarService,
    private router: Router,
    public adminService: AdminService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.LNBContent.forEach((item: any, idx: number) => (item.idx = idx));

    this.lnbService.history = JSON.parse(sessionStorage.getItem('history')!);
    if (this.genericService.LNBCategory == undefined) this.genericService.LNBCategory = this.lnbService.history[this.lnbService.history.length - 1];
  }

  ngAfterViewInit(): void {
    this.lnbService.popStateLNBActive(this.LNBContent, this.matExpansionPanelQueryList);
  }

  onClickCategory(category: any, link: string) {
    const shopDetail = this.adminService.ShopAdminAccount.shop?.shop_detail;

    if (!shopDetail) return;

    if (!shopDetail.use_apps.includes(AlphaApps.REVIEW)) this.openAppGuidanceDialog(category);
    if (shopDetail.use_apps.includes(AlphaApps.REVIEW)) this.moveCategory(category, link);
  }

  openAppGuidanceDialog(category: any) {
    if (category.value === 'home') return;
    const dialogRef = this.dialog.open(DialogAppGuidanceComponent, {
      width: '475px',
      data: category.value,
    });

    this.matExpansionPanelQueryList?.forEach((item, idx) => this.matExpansionPanelQueryList?.get(idx)?.close());
  }

  moveCategory(category: any, link: string) {
    this.genericService.LNBCategory = category.name;
    if (category.name == 'Import') this.genericService.LNBCategory = 'From Aliexpress';
    if (category.name === 'Sales Popup') this.genericService.LNBCategory = 'Homepage';
    if (category.name === 'Touchpoints') this.genericService.LNBCategory = 'Notification Emails';

    this.router.navigate([`admin/product-reviews/${link}`]);
    category.idx && this.matExpansionPanelQueryList?.get(category.idx)?.open();
  }

  LNBContent: any = [
    {
      name: 'Home',
      value: 'home',
      icon: 'assets/icon/lnb_home.svg',
      content: [],
    },
    {
      name: 'Reviews',
      value: 'reviews',
      icon: 'assets/icon/lnb_reviews.svg',
      content: [
        {
          name: 'Auto Manage',
          value: 'auto-manage',
        },
        {
          name: 'Review Groups',
          value: 'group',
        },
        {
          name: 'Advanced Manage',
          value: 'advanced-manage',
        },
        {
          name: 'Review Settings',
          value: 'review-settings',
        },
      ],
    },
    {
      name: 'Collect',
      value: 'collect',
      icon: 'assets/icon/lnb_collect.svg',
      content: [
        {
          name: 'Auto Request',
          value: 'auto-request',
        },
        {
          name: 'Bulk Request',
          value: 'bulk-request',
        },
        {
          name: 'Coupons',
          value: 'coupons',
        },
        {
          name: 'Review Form',
          value: 'review-form',
        },
      ],
    },
    {
      name: 'Import',
      value: 'import',
      icon: 'assets/icon/lnb_Import.svg',
      content: [
        {
          name: 'From Aliexpress',
          value: '',
        },
        {
          name: 'From Amazon',
          value: '',
        },
        {
          name: 'From CSV Import',
          value: '',
        },
      ],
    },
    {
      name: 'Display',
      value: 'display',
      icon: 'assets/icon/lnb_display.svg',
      content: [
        {
          name: 'Widget Custom',
          value: 'widget-custom',
        },
        {
          name: 'Widget Language Localization',
          value: 'widget-localization',
        },
        {
          name: 'Design Theme',
          value: 'design-theme',
        },
        {
          name: 'Rating Display',
          value: 'rating-display',
        },
      ],
    },
    {
      name: 'Analytics',
      value: 'analytics',
      icon: 'assets/icon/lnb_analytics.svg',
      content: [],
    },
    {
      name: 'Sales Popup',
      value: 'sales-popup',
      icon: 'assets/icon/lnb_sales_popup.svg',
      content: [
        {
          name: 'Homepage',
          value: '',
        },
        {
          name: 'Product Page',
          value: '',
        },
        {
          name: 'General Settings',
          value: '',
        },
      ],
    },
    {
      name: 'SEO & Marketing',
      value: 'marketing',
      icon: 'assets/icon/lnb_seo.svg',
      content: [
        {
          name: 'Google SEO',
          value: 'google-seo',
        },
        {
          name: 'Social Push',
          value: 'social-push',
        },
        {
          name: 'Instagram Curation',
          value: 'instagram-curation',
        },
      ],
    },
    {
      name: 'Touchpoints',
      value: 'touchpoints',
      icon: 'assets/icon/lnb_touchpoints.svg',
      content: [
        {
          name: 'Notification Emails',
          value: '',
        },
        {
          name: 'Customer Notice',
          value: '',
        },
      ],
    },
  ];
}
