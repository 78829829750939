import { Injectable } from '@angular/core';
import { AliexpressSettingCountry, AmazonRatingFilter, DeleteType, EcommerceReviewImportOrderBy, ReviewAppPlatform, ReviewAppPlatformTab, ReviewImportLog, TransferStatus } from 'src/app/models/shop/review/options/review-import.model';
import { AdminService } from 'src/app/service/admin.service';
import { BackendService } from 'src/app/service/backend.service';

@Injectable({
  providedIn: 'root'
})
export class ImportService {

  constructor(
    private backendService: BackendService,
    private adminService: AdminService,
  ) { }

  LIMIT_IMPORT_REVIEW_AMAZON = 50
  LIMIT_IMPORT_REVIEW_ALIEXPRESS = 100

  pollingReviewImportProgress(reviewImportLog: ReviewImportLog) {
    let url = "reviews/import/status"
    return this.backendService.select(url, { shop_access_code: this.adminService.getShopAccessCode(), task_id: reviewImportLog.id })
  }

  getReviewImportLog() {
    let url = 'reviews/import/history'
    return this.backendService.select(url, { shop_access_code: this.adminService.getShopAccessCode() })
  }

  deleteReviewImportLog(reviewImportLog: ReviewImportLog, deleteType: DeleteType) {
    let url = "reviews/import"
    return this.backendService.delete(url, { shop_access_code: this.adminService.getShopAccessCode(), task_id: reviewImportLog.id, delete_type: deleteType })
  }


  public get ReviewAppPlatform(): typeof ReviewAppPlatform {
    return ReviewAppPlatform
  }

  public get TransferStatus(): typeof TransferStatus {
    return TransferStatus
  }

  public get AliexpressSettingCountry(): typeof AliexpressSettingCountry {
    return AliexpressSettingCountry
  }

  public get ReviewAppPlatformTab(): typeof ReviewAppPlatformTab {
    return ReviewAppPlatformTab
  }

  public get AmazonRatingFilter(): typeof AmazonRatingFilter {
    return AmazonRatingFilter
  }

  public get EcommerceReviewImportOrderBy(): typeof EcommerceReviewImportOrderBy {
    return EcommerceReviewImportOrderBy
  }
}
