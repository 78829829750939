<article id="dialog-widget-create">
  <header class="admin-header-1">Before you start with List Designer:</header>
  <section class="create-content" style="margin-bottom: 44px">
    <ol>
      <li>If you're unsure where to start, use a preset. We offer Best Sellers, New Arrivals, and Top Reviewed presets.</li>
      <li>You can change the products displayed in the widget. Choose manually or set them to be automatically displayed based on specific criteria.</li>
      <li>You can set the content and font size of the title displayed at the top.</li>
      <li>You can decide whether to show social proof and FOMO elements in the widget, and set the criteria for their display.</li>
    </ol>
  </section>
  <div (click)="onclose()" class="btn-black" style="width: 192px; margin-left: auto">Start Creating a Widget</div>
</article>
