import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-dialog-edit-widget-title',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './dialog-edit-widget-title.component.html',
  styleUrl: './dialog-edit-widget-title.component.less',
})
export class DialogEditWidgetTitleComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string; nameList: string[] }, private dialogRef: MatDialogRef<DialogEditWidgetTitleComponent>) {}

  ngOnInit() {}

  checkOverCharacters: boolean = true;
  checkDuplicateTitle: boolean = true;
  isInputValid: boolean = true;

  onTitleChanged() {
    this.checkOverCharacters = this.data.title.length > 50 ? false : true;
    this.checkDuplicateTitle = this.data.nameList?.includes(this.data.title) ? false : true;

    this.isInputValid = this.checkOverCharacters && this.checkDuplicateTitle;
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.dialogRef.close(this.data.title);
  }
}
