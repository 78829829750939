<article id="dialog-product-variant-select">
  <header class="admin-header-3" style="margin-bottom: 20px">
    <span *ngIf="data.action == 'copy'"> Copy Reviews </span>

    <span *ngIf="data.action == 'moving'"> Move Reviews </span>

    <span *ngIf="data.action == 'choice'"> Select a product </span>
  </header>

  <section class="searching-wrap">
    <select style="margin-right: 8px" [ngModel]="''" (ngModelChange)="onChangeSortSelectBox($event)">
      <option selected value="" disabled>Sort by</option>
      <option [ngValue]="[true, false]">All</option>
      <option [ngValue]="[true]">Published Only</option>
      <option [ngValue]="[false]">Unpublished Only</option>
    </select>

    <form class="admin-form" style="width: 100%">
      <button (click)="getProducts()"><img src="assets/icon/search_gray.svg" /></button>
      <input placeholder="Search" [ngModelOptions]="{ standalone: true }" [(ngModel)]="searchContent" (keydown.enter)="getProducts()" />
    </form>
  </section>

  <div class="content-wrap">
    <section class="product-table" *ngFor="let product of products">
      <section class="product-table-row" (click)="data.maximum ? onClickCheckBox(product) : (product.checked = !product.checked)">
        <alpha-checkbox *ngIf="data.mode == 'checkBox'" style="margin-right: 10px" [(ngModel)]="product.checked" [disabled]="product._disabled!">
          <div class="product-info-container" style="display: flex; align-items: center">
            <img class="product-info-image" [src]="product.product_image" />
            <div class="product-info">
              <header class="product-info-title">
                <div>
                  {{ product.title }}
                </div>
              </header>
              <div class="product-info-gray"><b>ID</b> {{ product.platform_id }}</div>
              <div style="display: flex; align-items: center; justify-content: space-between; gap: 3px">
                <div>
                  <star-filled [fillColor]="'#333336'" [width]="12" [height]="11.5"> </star-filled>
                  <span>
                    {{ product.rating | number : '1.1-1' }}
                  </span>
                  <span class="product-info-gray"> ({{ product.review_count | number }}) </span>
                </div>
                <div class="product-info-gray">Created at {{ product.created_at | date : 'mediumDate' }}</div>
              </div>
            </div>
          </div>
        </alpha-checkbox>
        <mat-radio-button *ngIf="data.mode == 'radio'" style="width: 100%" (click)="onClickRadioBtn(product)" [value]="product" [checked]="checkedProducts.includes(product)">
          <div class="product-info-container" style="display: flex; align-items: center">
            <img class="product-info-image" [src]="product.product_image" />
            <div class="product-info">
              <header class="product-info-title ellipsis-1">
                {{ product.title }}
              </header>
              <div class="product-info-gray">{{ product.platform_id }}</div>
            </div>
          </div>
        </mat-radio-button>
      </section>
    </section>
  </div>

  <div class="flex-wrapper">
    <button class="btn-white" (click)="onNoClick()" style="width: 80px; height: 36px">Cancel</button>
    <button class="btn-black" [ngClass]="{ disabled: disabledBtn() }" (click)="onClickConfirm()" style="width: 80px">
      <ng-container *ngIf="!data.ai; else import">Select</ng-container>
      <ng-template #import> Import </ng-template>
    </button>
  </div>
</article>
