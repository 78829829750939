import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReviewImportLog } from 'src/app/models/shop/review/options/review-import.model';
import { AdminService } from 'src/app/service/admin.service';
import { ReviewService } from 'src/app/service/review.service';

@Component({
  selector: 'app-dialog-manage-review-export',
  templateUrl: './dialog-manage-review-export.component.html',
  styleUrls: ['./dialog-manage-review-export.component.less'],
})
export class DialogManageReviewExportComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DialogManageReviewExportComponent>,
    private adminService: AdminService,
    public reviewService: ReviewService,
    @Inject(MAT_DIALOG_DATA)
    private data: { product_ids?: number[]; review_ids?: number[] },
  ) {}

  displayColumns = ['checkbox', 'date', 'email', 'dueDate', 'status'];
  reviewImportLog: ReviewImportLog[] = [];
  email = new UntypedFormControl('', [Validators.email, Validators.required]);

  ngOnInit(): void {
    this.getReviewExportLog();
  }

  onNoClick() {
    this.dialogRef.close();
  }

  getReviewExportLog() {
    this.reviewService.getReviewExportLog({ shop_access_code: this.adminService.getShopAccessCode() }).subscribe((response) => {
      this.reviewImportLog = response.body;

      this.reviewImportLog.sort((a: ReviewImportLog, b: ReviewImportLog) => {
        return <any>new Date(b.created_at!) - <any>new Date(a.created_at!);
      });
    });
  }

  reviewExport() {
    this.reviewService.createReviewExportLog(this.data, { shop_access_code: this.adminService.getShopAccessCode(), email: this.email.value }).subscribe((response) => {
      this.getReviewExportLog();
    });
  }
}
