import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomTextareaComponent } from './custom-textarea/custom-textarea.component';
import { CustomToggleBtnComponent } from './custom-toggle-btn/custom-toggle-btn.component';
import { DisplayStatusLiveComponent } from './display-status-live/display-status-live.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { CustomCheckboxComponent } from './custom-checkbox/custom-checkbox.component';
import { CustomProgressBarComponent } from './custom-progress-bar/custom-progress-bar.component';
import { CustomDatepickerComponent } from './custom-datepicker/custom-datepicker.component';
import { CustomPaginatorComponent } from './custom-paginator/custom-paginator.component';
import { CustomSelectboxComponent } from './custom-selectbox/custom-selectbox.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CustomerColorInputComponent } from './customer-color-input/customer-color-input.component';
import { StoreSettingInputComponent } from './store-setting-input/store-setting-input.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { MatNativeDateModule } from '@angular/material/core';
import { CustomSaveBarComponent } from './custom-save-bar/custom-save-bar.component';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';
import { MatMenuModule } from '@angular/material/menu';
import { CustomNumberInputComponent } from './custom-number-input/custom-number-input.component';
import { CustomColorPickerComponent } from './custom-color-picker/custom-color-picker.component';

@NgModule({
  declarations: [
    CustomTextareaComponent,
    CustomToggleBtnComponent,
    DisplayStatusLiveComponent,
    CustomCheckboxComponent,
    CustomProgressBarComponent,
    CustomDatepickerComponent,
    CustomPaginatorComponent,
    CustomSelectboxComponent,
    CustomerColorInputComponent,
    StoreSettingInputComponent,
    ComingSoonComponent,
    CustomSaveBarComponent,
    CustomTooltipComponent,
    CustomNumberInputComponent,
    CustomColorPickerComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, PickerModule, MatDatepickerModule, MatMenuModule],
  exports: [
    CustomTextareaComponent,
    CustomToggleBtnComponent,
    DisplayStatusLiveComponent,
    CustomCheckboxComponent,
    CustomProgressBarComponent,
    CustomDatepickerComponent,
    StoreSettingInputComponent,
    ComingSoonComponent,
    CustomSaveBarComponent,
    CustomTooltipComponent,
    FormsModule,
    ReactiveFormsModule,
    PickerModule,
    CustomerColorInputComponent,
    MatNativeDateModule,
    CustomNumberInputComponent,
    CustomColorPickerComponent,
  ],
})
export class SharedModule {}
