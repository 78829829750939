import { Component, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { BackendService } from 'src/app/service/backend.service';
import { WriteService } from 'src/app/write/write.service';

@Component({
  selector: 'write-header',
  template: `
    <section class="write-header-container" [ngClass]="{ underline: displayUnderline }" *ngIf="displayHeader">
      <div style="display: flex;">
        <header class="write-header-1">
          <ng-content> </ng-content>
        </header>
      </div>
      <!-- <img src="assets/icon/paper_gray.svg"> -->
      <div class="right-side-icon" *ngIf="displayButton" (click)="writeService.close()">
        <img style="width: 16px; height:16px" src="assets/icon/close_big_gray.svg" />
      </div>
    </section>
  `,
  styles: [
    `
      @import (reference) '../../../color-palette.less';

      :host {
        position: sticky;
        top: 0;
        left: 0;

        z-index: 10;
        background-color: rgba(255, 255, 255, 0.75);
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);
      }

      .write-header {
        &-1 {
          font-size: 20px;
          font-weight: 600;
        }

        &-2 {
          font-size: 16px;
          font-weight: 600;
        }

        &-container {
          padding: 30px 20px 25px;
          display: flex;
          align-items: center;
          justify-content: center;

          &.underline {
            border-bottom: 1px solid @pale-gray-2;
          }

          .right-side-icon {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: right;
            right: 0;
            top: 50%;
            transform: translate(0, -25%);
            cursor: pointer;
          }
        }
      }

      .write-badge {
        margin-left: 6px;
        padding: 0 6px;
        border-radius: 9.5px;
        color: white;
        font-size: 12px;
        font-weight: 100;
      }
    `,
  ],
})
export class WriteHeaderComponent implements OnInit {
  @Input() displayHeader: boolean = true;
  @Input() displayPolicy: boolean = false;
  @Input() displayBadge: boolean = false;
  @Input() displayUnderline: boolean = true;
  @Input() displayButton: boolean = true;
  @Input() badge: number = 0;
  @Input() iconSrc: string = '';
  @Input() badgeColor: string = '#333336';
  @Input() onIconClick: any;

  constructor(@Optional() @SkipSelf() public writeService: WriteService) {}

  ngOnInit(): void {}

  back() {
    history.back();
  }
}
