"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.app = exports.Action = void 0;
var helper_1 = require("../helper");
var types_1 = require("../types");
var Action;
(function (Action) {
    Action["APP"] = "APP::PRINT::APP";
})(Action = exports.Action || (exports.Action = {}));
function app() {
    return helper_1.actionWrapper({
        group: types_1.Group.Print,
        type: Action.APP,
    });
}
exports.app = app;
