<mat-toolbar class="super-admin-header-toolbar">
  <div class="super-admin-header-text">고객사 목록</div>
</mat-toolbar>

<mat-toolbar id="super-admin-details">
  <div>
    <span
      class="status-filter-choice"
      (click)="changeStatusFilter([superAdminService.AppStatusType.WAIT, superAdminService.AppStatusType.PROCEED]); firstPage()"
      matBadge="{{ shopWait + shopProceed }}"
      >전체</span
    >
    <span class="status-filter-choice" (click)="changeStatusFilter([superAdminService.AppStatusType.WAIT]); firstPage()" matBadge="{{ shopWait }}">서비스 대기 중</span>
    <span class="status-filter-choice" (click)="changeStatusFilter([superAdminService.AppStatusType.PROCEED]); firstPage()" matBadge="{{ shopProceed }}">서비스 중</span>
  </div>
  <div class="search-form">
    <img class="search-icon" src="assets/image/super-admin/super-admin-search-icon.svg" />
    <input
      class="search-input"
      type="text"
      [(ngModel)]="searchKeyword"
      placeholder="쇼핑몰명, 코드, 이름, 이메일"
      (blur)="getShops(); firstPage()"
      (keydown.enter)="getShops(); firstPage()"
    />
  </div>
</mat-toolbar>

<div class="shops-list-table">
  <table mat-table id="shop-list" [dataSource]="shops">
    <!-- shop name Column -->
    <ng-container matColumnDef="shop_name">
      <th mat-header-cell *matHeaderCellDef>쇼핑몰명</th>
      <td mat-cell *matCellDef="let element" style="width: 15%; cursor: pointer" (click)="openDetail(element.id)">
        <div *ngIf="element?.shop_detail?.store_name" class="store-name">{{ element.shop_detail.store_name }}</div>
        <div class="shop-name">({{ element?.shop_name }})</div>
      </td>
    </ng-container>

    <!-- shop url Column -->
    <ng-container matColumnDef="shop_url">
      <th mat-header-cell *matHeaderCellDef>바로가기</th>
      <td mat-cell *matCellDef="let element" style="width: 10%; cursor: pointer">
        <a (click)="toShop(element.shop_url)"> 쇼핑몰 <img src="assets/image/super-admin/super-admin-shortcut.svg" width="14px" /></a>
        <a (click)="toDashboard(element.id)"> 대시보드 <img src="assets/image/super-admin/super-admin-shortcut.svg" width="14px" /></a>
      </td>
    </ng-container>

    <!-- shop status Column -->
    <ng-container matColumnDef="service_status">
      <th mat-header-cell *matHeaderCellDef>서비스 상태</th>
      <td mat-cell *matCellDef="let element" style="width: 5%">
        <div [ngSwitch]="element.service_status">
          <div *ngSwitchCase="superAdminService.AppStatusType.WAIT">
            <div class="service-status-now" style="background-color: #bcd7ff">
              <img class="status-dot" src="assets/image/super-admin/super-admin-list-status-blue-dot.svg" />
              대기 중
            </div>
          </div>
          <div *ngSwitchCase="superAdminService.AppStatusType.PROCEED">
            <div class="service-status-now" style="background-color: #ffe8e5">
              <img class="status-dot" src="assets/image/super-admin/super-admin-list-status-red-dot.svg" />
              서비스 중
            </div>
          </div>
          <div *ngSwitchCase="superAdminService.AppStatusType.DELETE">
            <div class="service-status-now" style="background-color: #e6e9ec">
              <img class="status-dot" src="assets/image/super-admin/super-admin-list-status-grey-dot.svg" />
              앱 삭제
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <!-- id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Shop ID</th>
      <td mat-cell *matCellDef="let element" style="width: 10%">{{ element?.id }}</td>
    </ng-container>

    <!-- shop continuous_date, cumulative_date Column -->
    <ng-container matColumnDef="shop_initial_info">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>사용 일수</th>
      <td mat-cell *matCellDef="let element" style="width: 10%">
        <div>지속 {{ element?.shop_initial_info.used_date }}일</div>
      </td>
    </ng-container>
    <!-- shop shop_detail Column -->
    <ng-container matColumnDef="shop_detail">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>카테고리</th>
      <td mat-cell *matCellDef="let element" style="width: 10%">{{ element.shop_detail['industry'] }}</td>
    </ng-container>

    <!-- shop setup, onboarding Column -->
    <ng-container matColumnDef="setup">
      <th mat-header-cell *matHeaderCellDef>주요 세팅 진행도</th>
      <td
        mat-cell
        *matCellDef="let element"
        style="width: 10%"
        matTooltipShowDelay="500"
        matTooltipClass="process-tooltip"
        matTooltip="온보딩
                                                                                계정 생성 : {{ element.shop_initial_info.process_onboarding_1 ? 'o' : 'x' }}
                                                                                기본 정보 입력 : {{
          element.shop_initial_info.process_onboarding_2 ? 'o' : 'x'
        }}                                                                   
                                                                                위젯 설정 : {{ element.shop_initial_info.process_onboarding_3 ? 'o' : 'x' }}
                                                                                작성유도 설정 : {{ element.shop_initial_info.process_onboarding_4 ? 'o' : 'x' }}
                                                                                
                                                                                셋팅
                                                                                Review Request Settings : {{ element.shop_initial_info.process_setup_1 ? 'o' : 'x' }}
                                                                                Reward Settings : {{ element.shop_initial_info.process_setup_2 ? 'o' : 'x' }}
                                                                                Review Reply Settings : {{ element.shop_initial_info.process_setup_3 ? 'o' : 'x' }}
                                                                                Modration Settings : {{ element.shop_initial_info.process_setup_4 ? 'o' : 'x' }}
                                                                                Manage Reviews : {{ element.shop_initial_info.process_setup_5 ? 'o' : 'x' }}
                                                                                Shocase Reviews : {{ element.shop_initial_info.process_setup_6 ? 'o' : 'x' }}
                                                                                "
      >
        <div>온보딩 {{ element.onboarding }} / 4</div>
        <div>셋업 {{ element.setup }} / 6</div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-row *matCellDef="let element"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons aria-label="Select page of periodic elements" (page)="getShops()"> </mat-paginator>
</div>
