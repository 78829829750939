import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-custom-confirm-dialog',
  templateUrl: './custom-confirm-dialog.component.html',
  styleUrls: ['./custom-confirm-dialog.component.less'],
})
export class CustomConfirmDialogComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<CustomConfirmDialogComponent>) {}
  @Input() header?: string;
  @Input() image?: string = '';
  @Input() content: string | SafeHtml = '';
  @Input() confirm: string = 'Confirm';
  @Input() cancel: string = 'Cancel';
  @Input() hideCancel: boolean = false;
  @Input() alignCenter: boolean = false;
  @Input() confirmWidth?: string;
  @Input() headerImgSrc?: string;

  ngOnInit(): void {}

  onConfirm() {
    this.dialogRef.close(true);
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
