import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';

import { AccountInviteComponent } from './account-invite/account-invite.component';
import { AccountComponent } from './account.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccountInviteCompleteComponent } from './account-invite/account-invite-complete/account-invite-complete.component';
import { AccountResetPasswordComponent } from './account-reset-password/account-reset-password.component';


@NgModule({
  declarations: [
    AccountComponent,
    AccountInviteComponent,
    AccountInviteCompleteComponent,
    AccountResetPasswordComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AccountRoutingModule,
    SharedModule
  ]
})
export class AccountModule { }
