<article id="dialog-setting-subscription-summary">
    <section class="plan-table">
        <div class="plan-table-header">
            <div class="plan-benefit-name">

            </div>
            <div class="plan-benefit-free">
                <div class="plan-name">
                    Free
                </div>
                <div class="plan-price plan-price-title">
                    $0
                </div>
            </div>
            <div class="plan-benefit-premium">
                <div class="plan-name">
                    Premium
                </div>
                <div class="plan-price plan-price-title">
                    {{billingService.PREMIUM_PLAN_PRICE | currency}} <span class="period"> /mo</span>
                </div>

                <div class="plan-price-yearly">
                    {{billingService.PREMIUM_PLAN_PRICE * (1-billingService.YEARLY_PLAN_DISCOUNT) | currency}} <span>
                        /mo</span> for yearly
                </div>

            </div>
        </div>
        <section class="plan-table-content">
            <div class="class=plan-tabel-row-group">
                <div class="plan-table-row">
                    <div class="plan-benefit-name title">Manage</div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Photo & Video Reviews</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Store Review</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Long-term Review</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Review Queue</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Automated Review Management</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Automated Review Replies</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Labeling Reviews</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Pin Reviews to Top/bottom</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Review Groups</div>
                    <div class="plan-benefit-free"></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
            </div>

            <div class="class=plan-tabel-row-group">
                <div class="plan-table-row">
                    <div class="plan-benefit-name title">Collect</div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Automated Review Requests</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Review Reminders</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Media Reminders for Reviews</div>
                    <div class="plan-benefit-free"></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Long-term Review Requests</div>
                    <div class="plan-benefit-free"></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Bulk Review Requests via CSV</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Reward Coupons for Reviews</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Review Forms Customization</div>
                    <div class="plan-benefit-free"></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
            </div>

            <div class="class=plan-tabel-row-group">
                <div class="plan-table-row">
                    <div class="plan-benefit-name title">Import</div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Aliexpress Review Import</div>
                    <div class="plan-benefit-free">100 per product</div>
                    <div class="plan-benefit-premium">Unlimited</div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Amazon Review Import</div>
                    <div class="plan-benefit-free">50 per product</div>
                    <div class="plan-benefit-premium">Unlimited</div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Media Reminders for Reviews</div>
                    <div class="plan-benefit-free"></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
            </div>


            <div class="class=plan-tabel-row-group">
                <div class="plan-table-row">
                    <div class="plan-benefit-name title">Display</div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">13+ Review Widgets</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Review Counts & Rating Display</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Widget Customization</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Language Localization</div>
                    <div class="plan-benefit-free"></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
            </div>


            <div class="class=plan-tabel-row-group">
                <div class="plan-table-row">
                    <div class="plan-benefit-name title">Touchpoints</div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Customer Notices</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Reward Reminder Emails</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Reply Notification Emails</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Verification Emails</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Notification Emails Customization</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
            </div>

            <div class="class=plan-tabel-row-group">
                <div class="plan-table-row">
                    <div class="plan-benefit-name title">Sales Popup</div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Product Page Sales Popup</div>
                    <div class="plan-benefit-free">Limited Metrics</div>
                    <div class="plan-benefit-premium">Unlimited</div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Homepage Sales Popup</div>
                    <div class="plan-benefit-free">Limited Metrics</div>
                    <div class="plan-benefit-premium">Unlimited</div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Sales Popup Customization</div>
                    <div class="plan-benefit-free">Limited Metrics</div>
                    <div class="plan-benefit-premium">Unlimited</div>
                </div>
            </div>

            <div class="class=plan-tabel-row-group">
                <div class="plan-table-row">
                    <div class="plan-benefit-name title">SEO & Marketing</div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Google Rich Snippets Integration</div>
                    <div class="plan-benefit-free"><icon-check></icon-check></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Social Push (Facebook, Twitter)</div>
                    <div class="plan-benefit-free"></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
                <div class="plan-table-row">
                    <div class="plan-benefit-name">Instagram Curation</div>
                    <div class="plan-benefit-free"></div>
                    <div class="plan-benefit-premium"><icon-check color="#837AFF"></icon-check></div>
                </div>
            </div>
        </section>
    </section>
    <section class="flex-wrapper" style="padding-top: 10px">
        <div></div>
        <div>
            <button class="btn-cancel" (click)="onNoClick()">
                Close
            </button>
        </div>
    </section>
</article>