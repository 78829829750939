"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.Action = exports.Fullscreen = exports.exit = exports.enter = void 0;
var Fullscreen_1 = require("@shopify/app-bridge-core/actions/Fullscreen");
Object.defineProperty(exports, "Fullscreen", { enumerable: true, get: function () { return Fullscreen_1.Fullscreen; } });
Object.defineProperty(exports, "Action", { enumerable: true, get: function () { return Fullscreen_1.Action; } });
var Fullscreen_2 = require("@shopify/app-bridge-core/actions/Fullscreen");
Object.defineProperty(exports, "enter", { enumerable: true, get: function () { return Fullscreen_2.enter; } });
Object.defineProperty(exports, "exit", { enumerable: true, get: function () { return Fullscreen_2.exit; } });
/**
 * Returns a new instance of a Fullscreen action set
 * @param app the client application
 *
 * @beta
 */
function create(app) {
    return new Fullscreen_1.Fullscreen(app);
}
exports.create = create;
