import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'alpha-color-picker',
  templateUrl: './custom-color-picker.component.html',
  styleUrl: './custom-color-picker.component.less',
})
export class CustomColorPickerComponent implements OnInit {
  colors: string[] = ['#FF6060', '#FFB800', '#FF91E0', '#3C73FF', '#151516'];
  @Input() selectedColor: string = '';
  @Output() colorChange = new EventEmitter<string>();

  constructor() {}
  ngOnInit(): void {}

  selectColor(color: string) {
    this.selectedColor = color;
    this.colorChange.emit(color);
  }

  openColorPicker(color: string) {
    this.selectedColor = color;
    this.colorChange.emit(color);
  }
}
