<article id="dialog-edit-widget-title">
  <header class="admin-header-1 flex-wrapper">Edit Widget Name<img (click)="onCancel()" src="assets/icon/close_big_gray.svg" style="cursor: pointer" /></header>
  <input [(ngModel)]="data.title" (ngModelChange)="onTitleChanged()" [ngClass]="{ error: !isInputValid }" class="admin-input" type="text" />

  <section class="flex-wrapper" style="justify-content: flex-start; gap: 12px">
    <div class="flex-wrapper" [ngStyle]="{ color: checkOverCharacters ? '#0094D5' : '#ADADB1' }" style="gap: 4px">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M6 12.6998L2 8.69981L3.4 7.29981L6 9.89981L12.6 3.2998L14 4.6998L6 12.6998Z" [ngStyle]="{ fill: checkOverCharacters ? '#0094D5' : '#ADADB1' }" />
      </svg>
      Less than 50 characters
    </div>
    <div class="flex-wrapper" [ngStyle]="{ color: checkDuplicateTitle ? '#0094D5' : '#ADADB1' }" style="gap: 4px">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M6 12.6998L2 8.69981L3.4 7.29981L6 9.89981L12.6 3.2998L14 4.6998L6 12.6998Z" [ngStyle]="{ fill: checkDuplicateTitle ? '#0094D5' : '#ADADB1' }" />
      </svg>
      Must not duplicate other widget names
    </div>
  </section>

  <section class="flex-wrapper" style="justify-content: flex-end; gap: 10px; margin-top: 18px">
    <div (click)="onCancel()" class="btn-white" style="height: 36px; width: 80px">Cancel</div>
    <div (click)="onConfirm()" class="btn-black" [ngClass]="{ disabled: !isInputValid }" style="width: 68px">Done</div>
  </section>
</article>
