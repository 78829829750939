import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from 'src/app/guard/admin.guard';
import { ImportComponent } from './import/import.component';
import { CanDeactivateGuard } from 'src/app/guard/can-deactivate.guard';
import { AnalyticsComponent } from './analytics/analytics.component';
import { SalesPopupComponent } from './sales-popup/sales-popup.component';
import { NotificationEmailCustomComponent } from './touchpoints/touchpoints-notification/notification-email-custom/notification-email-custom.component';

const routes: Routes = [
  { path: 'home', canActivate: [AdminGuard], data: { title: 'Home' }, loadChildren: () => import('./admin-home/admin-home.module').then((m) => m.HomeModule) },
  { path: 'reviews', canActivate: [AdminGuard], data: { title: 'Reviews' }, loadChildren: () => import('./reviews/reviews.module').then((m) => m.ReviewsModule) },
  { path: 'collect', canActivate: [AdminGuard], data: { title: 'Collect' }, loadChildren: () => import('./collect/collect.module').then((m) => m.CollectModule) },
  { path: 'import', canActivate: [AdminGuard], data: { title: 'Import' }, component: ImportComponent },
  { path: 'display', canActivate: [AdminGuard], data: { title: 'Display' }, loadChildren: () => import('./display/display.module').then((m) => m.DisplayModule) },
  { path: 'analytics', canActivate: [AdminGuard], component: AnalyticsComponent, data: { title: 'Analytics' } },
  { path: 'sales-popup', canActivate: [AdminGuard], data: { title: 'Sales Popup' }, component: SalesPopupComponent, canDeactivate: [CanDeactivateGuard] },
  { path: 'marketing', canActivate: [AdminGuard], data: { title: 'Marketing' }, loadChildren: () => import('./marketing/marketing.module').then((m) => m.MarketingModule) },
  {
    path: 'touchpoints',
    canActivate: [AdminGuard],
    data: { title: 'Touchpoints' },
    loadChildren: () => import('./touchpoints/touchpoints.module').then((m) => m.TouchpointsModule),
  },
  { path: 'email-custom', canActivate: [AdminGuard], component: NotificationEmailCustomComponent },
  { path: '', pathMatch: 'full', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductReviewsRoutingModule {}
