<mat-toolbar class="super-admin-header-toolbar">
  <div class="super-admin-header-text">포스트 생성/수정</div>
</mat-toolbar>
<article id="post-create">
  <div class="settings">
    <div class="section-header">1) 포스트 설정</div>
    <div class="section-body">
      <div class="body-detail">
        <div class="post-input-lable">카테고리</div>
        <div class="post-input-value">
          <mat-select class="post-input-select" [formControl]="category">
            <mat-option *ngFor="let category of categories" [value]="category[1]">
              {{ category[0] }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="body-detail">
        <div class="detail-box">
          <div class="post-input-lable">세부 토픽</div>
          <div class="post-input-value">
            <input
              class="post-input-select"
              placeholder="새로운 토픽 이름"
              [formControl]="topicControl"
              #topicInput
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addTopic($event)"
            />
            <mat-chip-grid #chipList>
              <mat-chip *ngFor="let topic of topics" [removable]="removable" (removed)="removeTopic(topic)">
                {{ topic }}
                <button matChipRemove *ngIf="removable">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip>
            </mat-chip-grid>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
              <mat-option *ngFor="let topic of filterdTopics | async" [value]="topic.value">
                {{ topic.value }}
              </mat-option>
            </mat-autocomplete>
          </div>
        </div>
      </div>
      <div class="body-detail">
        <div class="post-input-lable">작성자</div>
        <div class="post-input-value">
          <mat-select class="post-input-select" [formControl]="author">
            <mat-option *ngFor="let author of authors" [value]="author.id">
              <div style="display: flex"><img class="author-profile-image" src="{{ author.profile_image }}" />{{ author.nickname }}</div>
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="body-detail">
        <div class="detail-box">
          <div class="post-input-lable">관련 포스트</div>
          <div class="post-input-value">
            <div class="linked-post">
              1)
              <input
                class="value-row"
                type="text"
                placeholder="관련 포스트"
                [ngModel]="getPostTitle(linked_post1)"
                [matAutocomplete]="post1"
                (ngModelChange)="getPostOptions(linked_post1)"
              />
              <mat-icon *ngIf="linked_post1.value.title" (click)="resetSelectedPost(linked_post1)" style="padding: 5px; font-size: 15px; color: #333336">close</mat-icon>
            </div>
            <mat-autocomplete #post1="matAutocomplete" (optionSelected)="selectPost($event, linked_post1)">
              <mat-option *ngFor="let post of filterdPosts | async" [value]="post">
                {{ post.title }}
              </mat-option>
            </mat-autocomplete>

            <div class="linked-post">
              2)
              <input
                class="value-row"
                type="text"
                placeholder="관련 포스트"
                [ngModel]="getPostTitle(linked_post2)"
                [matAutocomplete]="post2"
                (ngModelChange)="getPostOptions(linked_post2)"
              />
              <mat-icon *ngIf="linked_post2.value.title" (click)="resetSelectedPost(linked_post2)" style="padding: 5px; font-size: 15px; color: #333336">close</mat-icon>
            </div>
            <mat-autocomplete #post2="matAutocomplete" (optionSelected)="selectPost($event, linked_post2)">
              <mat-option *ngFor="let post of filterdPosts | async" [value]="post">
                {{ post.title }}
              </mat-option>
            </mat-autocomplete>

            <div class="linked-post">
              3)
              <input
                class="value-row"
                type="text"
                placeholder="관련 포스트"
                [ngModel]="getPostTitle(linked_post3)"
                [matAutocomplete]="post3"
                (ngModelChange)="getPostOptions(linked_post3)"
              />
              <mat-icon *ngIf="linked_post3.value.title" (click)="resetSelectedPost(linked_post3)" style="padding: 5px; font-size: 15px; color: #333336">close</mat-icon>
            </div>
            <mat-autocomplete #post3="matAutocomplete" (optionSelected)="selectPost($event, linked_post3)">
              <mat-option *ngFor="let post of filterdPosts | async" [value]="post">
                {{ post.title }}
              </mat-option>
            </mat-autocomplete>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="settings">
    <div class="section-header">2) 미리보기 설정</div>
    <div class="section-body">
      <div class="body-detail">
        <div class="detail-box">
          <div class="post-input-lable">썸네일</div>
          <!-- <img *ngIf="preview.value" src=preview.value onerror="this.style.display='none'" style="width: 100px; height: 100px;"> -->
          <input class="file-input" type="file" accept="image/*" [formControl]="thumbnail" (change)="uploadThumbnail($event)" />
        </div>
      </div>
      <div class="body-detail">
        <div class="detail-box">
          <div class="post-input-lable">요약</div>
          <div class="post-input-value">
            <textarea maxlength="90" class="summary" type="text" [formControl]="summary"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="settings">
    <div class="section-header">3) 포스트 내용</div>
    <div class="section-body">
      <div class="body-detail">
        <div class="detail-box">
          <div class="post-input-lable">제목</div>
          <div class="post-input-value">
            <input class="value-row" type="text" [formControl]="title" />
          </div>
        </div>
      </div>
      <div class="body-detail">
        <div class="detail-box">
          <div class="post-input-lable">내용</div>
          <div class="post-input-value">
            <div [ngxSummernote]="config" [formControl]="content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="settings">
    <div class="section-header">4) 게시 설정</div>
    <div class="section-body">
      <div class="publish-setting">게시 설정</div>
      <div class="body-detail">
        <div class="detail-box">
          <div class="post-input-lable">랜딩페이지</div>
          <div class="post-input-value">
            <mat-slide-toggle [(ngModel)]="randing" (change)="viewTypeChoice(superAdminService.PostViewType.NEW_TAB)"> </mat-slide-toggle>
            <div *ngIf="randing" class="publish-period-picker">
              <div class="publish-lable">게시 기간</div>
              <div>
                <input class="date-picker-space" type="datetime-local" [formControl]="randingStart" />
                <input class="date-picker-space" type="datetime-local" [formControl]="randingEnd" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body-detail">
        <div class="detail-box">
          <div class="post-input-lable">대시보드 피드</div>
          <div class="post-input-value">
            <mat-slide-toggle [(ngModel)]="feed"> </mat-slide-toggle>

            <div *ngIf="feed" class="publish-period-picker">
              <div class="publish-lable">게시 기간</div>
              <div class="publish-lable">
                <mat-checkbox
                  class="publish-option-checkbox"
                  [disabled]="!randing"
                  (change)="viewTypeChoice(superAdminService.PostViewType.NEW_TAB)"
                  [checked]="viewType.value == superAdminService.PostViewType.NEW_TAB"
                  >랜딩페이지</mat-checkbox
                >
                <mat-checkbox
                  class="publish-option-checkbox"
                  [disabled]="!randing"
                  (change)="viewTypeChoice(superAdminService.PostViewType.MODAL)"
                  [checked]="viewType.value == superAdminService.PostViewType.MODAL"
                  >대시보드 모달</mat-checkbox
                >
              </div>
              <div>
                <input class="date-picker-space" type="datetime-local" [formControl]="feedStart" />
                <input class="date-picker-space" type="datetime-local" [formControl]="feedEnd" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body-detail">
        <div class="detail-box">
          <div class="post-input-lable">대시보드 팝업</div>
          <div class="post-input-value">
            <mat-slide-toggle [(ngModel)]="popup"> </mat-slide-toggle>
            <div *ngIf="popup" class="publish-period-picker">
              <div class="publish-lable">게시 기간</div>
              <div class="publish-lable">
                <mat-checkbox class="publish-option-checkbox" [checked]="!is_routine.value" (change)="routineChoice(false)">한 번만 확인 </mat-checkbox>
                <mat-checkbox class="publish-option-checkbox" [checked]="is_routine.value" (change)="routineChoice(true)">24 시간 중 한 번만 확인</mat-checkbox>
              </div>
              <div>
                <input class="date-picker-space" type="datetime-local" [formControl]="popupStart" />
                <input class="date-picker-space" type="datetime-local" [formControl]="popupEnd" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="publish-setting">게시 대상</div>
      <div class="body-detail">
        <div class="detail-box">
          <div class="post-input-lable">industry 별 게시</div>
          <div class="post-input-value">
            <button class="industry-choice" (click)="openIndustryDialog()">
              <span *ngIf="!targetIndustries.value.length">+ Industry 선택</span>
              <span *ngIf="targetIndustries.value.length">+ Industry 재선택</span>
            </button>
            <div>{{ targetIndustries.value.join(', ') }}</div>
          </div>
        </div>
      </div>
      <div class="body-detail">
        <div class="detail-box">
          <div class="post-input-lable">엑셀 업로드</div>
          <div class="post-input-value">
            <input type="file" accept=".xlsx" (change)="onUploadFile($event)" />
          </div>
          <ng-container>
            <div *ngFor="let shop of unknownShops">{{ shop['shop_name'] }}</div>
            <div *ngIf="unknownShops.length">은 확인되지 않습니다.</div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="button-section">
    <button class="save" (click)="onCancel()">취소</button>
    <button *ngIf="tmpSave" class="save" (click)="onSave()">임시 저장</button>
    <span>
      <mat-spinner *ngIf="isVerified.value" [diameter]="40"></mat-spinner>
    </span>
    <button class="create" type="submit" [disabled]="category.invalid || author.invalid || title.invalid || content.invalid" (click)="openVerifyDialog()">포스트 등록</button>
  </div>
</article>
