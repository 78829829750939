import { WidgetInstallationPosition } from '../widget/widget.model';

export enum SalesPopupMetricType {
  // 구매(주문)
  RECENT_PURCHASE_SPOTLIGHT = 1,
  PRODUCT_PURCHASE_SUMMARY = 2,
  STORE_PURCHASE_SUMMARY = 3,
  PURCHASE_SUMMERY = 4,

  // 카트
  ADD_TO_CARTS_SPOTLIGHT = 11,
  CART_SUMMARY = 12,

  // 방문자
  LIVE_VISITORS = 21,
  VISITOR_SUMMARY = 22,

  // 리뷰
  RECENT_REVIEW_SPOTLIGHT = 31,
  PRODUCT_REVIEW_SUMMARY = 32,
  ALL_REVIEW_SUMMARY = 33,
  REVIEW_SUMMARY = 34,
}

export interface SalesPopupConfig {
  id: number;
  shop_id: number;

  is_active: boolean; // HOMEPAGE, PRODUCT PAGE에 세일즈 팝업 표시 여부
  type: WidgetInstallationPosition; // 세일즈 팝업을 표시할 위치 결정

  sales_popup_metrics: SalesPopupMetric[];
}

export interface SalesPopupConfigCommon {
  id: number;
  shop_id: number;
  theme: number;
  web_margin_bottom: number;
  web_margin_left: number;
  mobile_margin_bottom: number;
  first_name_fallback: string;
  active_anonymize: boolean;
  product_blocklist: number[];
  collection_blocklist: number[];
}

export interface SalesPopupMetric {
  id: number;
  shop_id: number;
  type: SalesPopupMetricType; // 세일즈 팝업 메트릭 타입 결정
  is_active: boolean; // 메트릭 표시 여부 결정
  // title: string
  content: string; // 사용자에게 표시될 컨텐츠
  condition: {
    time_range?: string | number;
    threshold?: number;
    rating?: number;
  }; // 필요한 데이터

  sales_popup_config?: SalesPopupConfig;
  sales_popup_config_id: number;
}
