"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSingleButton = void 0;
var Button_1 = require("./Button");
function getSingleButton(action, button, subgroups, updateCb) {
    action.addChild(button, action.group, subgroups);
    action.subscribeToChild(button, Button_1.Action.UPDATE, updateCb);
    return button.payload;
}
exports.getSingleButton = getSingleButton;
