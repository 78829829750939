import { ChangeDetectorRef, Component, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { ListDesignerService } from '../list-designer.service';
import { ListDesignerPreset, ListDesignerWidget } from 'src/app/models/list-designer/widget.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogEditWidgetTitleComponent } from '../list-designer-dialog/dialog-edit-widget-title/dialog-edit-widget-title.component';
import { GenericService } from 'src/app/service/generic.service';
import { NavigationStart, Router } from '@angular/router';
import { DialogAddAndEditProductsComponent } from '../list-designer-dialog/dialog-add-and-edit-products/dialog-add-and-edit-products.component';
import { DialogInstallWidgetGuideComponent } from '../list-designer-dialog/dialog-install-widget-guide/dialog-install-widget-guide.component';
import { Product } from 'src/app/models/product/product.model';
import { cloneDeep, isEqual, template } from 'lodash';
import { inOutAnimation } from '../../animations';
import { AdminService } from 'src/app/service/admin.service';
import { AlphaApps } from 'src/app/models/shop/profile/store-detail.model';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { CustomConfirmDialogComponent } from 'src/app/dialog/custom-confirm-dialog/custom-confirm-dialog.component';
import { DialogAutomaticProductComponent } from '../list-designer-dialog/dialog-automatic-product/dialog-automatic-product.component';
import { DialogWidgetCreateComponent } from '../list-designer-dialog/dialog-widget-create/dialog-widget-create.component';

@Component({
  selector: 'app-widget-custom-settings',
  templateUrl: './widget-custom-settings.component.html',
  styleUrl: './widget-custom-settings.component.less',
  animations: [inOutAnimation],
})
export class WidgetCustomSettingsComponent implements OnInit {
  @ViewChild(SwiperComponent) swiper!: SwiperComponent;
  constructor(
    public listDesignerService: ListDesignerService,
    public genericService: GenericService,
    private adminService: AdminService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.widgetId = history.state.widgetId;
    this.useProductReviews = this.adminService.ShopAdminAccount.shop?.shop_detail?.use_apps.includes(AlphaApps.REVIEW);
    this.widget.name = `Widget ${this.listDesignerService.widgetLength + 1}`;

    !this.widgetId && this.openDialogWidgetCreate();
  }

  ngOnInit(): void {
    if (this.widgetId) this.getWidget();
    else this.getWidgetTemplate();
  }

  selectPreset!: ListDesignerPreset;
  widgetId?: number;
  widget: ListDesignerWidget = new ListDesignerWidget();
  originWidget?: ListDesignerWidget;
  isEditable: boolean = false;
  widgetProducts: Product[] | null = null;
  originWidgetProducts!: Product[];
  useProductReviews: boolean | undefined = undefined;
  isLoading: boolean = false;

  mode: 'PC' | 'MO' = 'PC';

  swiperConfig?: SwiperOptions;

  changePresetOption(preset: ListDesignerPreset) {
    const selectedOption = this.presetOptions[preset];
    if (!selectedOption) return;
    this.widget.template = selectedOption.template;
    this.widget.update_product_filter = selectedOption._product_to_display;
    this.widget.design_option = { ...this.widget.design_option, ...selectedOption.design_option };
    this.widget.standard_option = { ...this.widget.standard_option, ...selectedOption.standard_option };
    this.disabledOnlyReviewAppProof();
    this.getFilteringProducts();
    selectedOption.design_option.layout === 'carousel' && this.setSwiperConfig();
  }

  setAutomaticProduct() {
    const dialogRef = this.dialog.open(DialogAutomaticProductComponent, { width: '540px', data: this.useProductReviews });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      if (result !== this.widget.template) this.widget.template = ListDesignerPreset.MANUAL;
      this.widget.update_product_filter = result;
      this.getFilteringProducts(result);
    });
  }

  setSwiperConfig(): void {
    this.swiperConfig = {
      slidesPerView: this.mode === 'PC' ? this.widget.design_option.web_columns : this.widget.design_option.mobile_columns!,
      spaceBetween: this.mode === 'PC' ? this.widget.design_option.web_adjusting_spacing : this.widget.design_option.mobile_adjust_spacing,
      autoplay: { delay: 3000 },
      speed: 1000,
      loop: true,
      allowTouchMove: false,
    };
  }

  getWidget() {
    this.widgetProducts = [];
    this.listDesignerService.getWidget(this.widgetId!).subscribe((response) => {
      this.widget = response.body;
      this.disabledOnlyReviewAppProof();
      this.originWidget = cloneDeep(this.widget);
      this.getListDesignerProduct();
    });
  }

  getWidgetTemplate() {
    this.disabledOnlyReviewAppProof();
    this.originWidget = cloneDeep(this.widget);
    this.getListDesignerProduct();
  }

  disabledOnlyReviewAppProof() {
    if (!this.useProductReviews) {
      this.widget.standard_option.use_realtime_review_standard = false;
      this.widget.standard_option.use_review_summary = false;
    } else return;
  }

  getListDesignerProduct() {
    this.isLoading = true;
    const params = { shop_access_code: this.adminService.getShopAccessCode() };
    if (this.widgetId) this.listDesignerService.getWidgetInProducts(this.widgetId, params).subscribe((response) => this.getProductsFlow(response));
    else
      this.listDesignerService.getListProducts(this.widget.template, params).subscribe((response) => {
        this.getProductsFlow(response);
      });

    this.widget.design_option.layout === 'carousel' && this.setSwiperConfig();
  }

  getFilteringProducts(automatic?: ListDesignerPreset) {
    const params = { shop_access_code: this.adminService.getShopAccessCode() };
    this.listDesignerService.getListProducts(automatic ? automatic : this.widget.template, params).subscribe((response) => this.getProductsFlow(response));
  }

  getProductsFlow(response: any) {
    this.widgetProducts = response.body;
    this.originWidgetProducts = cloneDeep(this.widgetProducts!);
    this.widget.product_count = this.widgetProducts!.length;
    if (!this.widgetProducts?.length) this.widgetProducts = null;
    this.isLoading = false;
  }

  createWidget() {
    this.widget.product_ids = this.widgetProducts?.map((product) => product.id) ?? [];
    this.listDesignerService.createWidget(this.widget).subscribe((response) => {
      this.widget = response.body;
      this.router.navigate(['/admin/list-designer/widget-guide'], { state: { widget: this.widget } });
    });
  }

  updateWidget() {
    this.widget.product_ids = this.widgetProducts?.map((product) => product.id) ?? [];
    this.listDesignerService.patchWidget(this.widget).subscribe((response) => {
      this.widget = response.body;
      this.router.navigate(['/admin/list-designer/widget-guide'], { state: { widget: this.widget } });
    });
  }

  onClickPublish() {
    if (this.widgetId) this.updateWidget();
    else this.createWidget();
  }

  onTitleEdit() {
    const dialogRef = this.dialog.open(DialogEditWidgetTitleComponent, {
      width: '768px',
      disableClose: true,
      data: { title: this.widget.name, nameList: this.listDesignerService.widgetNameList },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;

      this.widget.name = result;
      this.updateWidget();
    });
  }

  openDialogAddProducts() {
    const dialogRef = this.dialog.open(DialogAddAndEditProductsComponent, {
      width: '768px',
      maxHeight: '80vh',
      disableClose: true,
      data: { widgetId: this.widget.id, template: this.widget.template, widgetInProducts: this.widgetProducts },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) this.widgetProducts = this.originWidgetProducts;
      else {
        this.widgetProducts = result.filteringProducts;
        this.widget.template = result.template;
        this.widget.product_count = result.filteringProducts.length;
        this.widget.update_product_filter = null;
        this.genericService.openSnackBar('Your product list has been updated based on your settings.');
      }
    });
  }

  openDialogWidgetInstallGuide() {
    const dialogRef = this.dialog.open(DialogInstallWidgetGuideComponent, { width: '768px', data: this.widget.code, disableClose: true });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.router.navigate(['admin/list-designer/widget-list']);
    });
  }

  changedMode(mode: 'PC' | 'MO') {
    this.mode = mode;
    this.setCustomPreset();
    if (this.widget.design_option.layout === 'list') return;
    this.swiperConfig = {
      slidesPerView: this.mode === 'PC' ? this.widget.design_option.web_columns : this.widget.design_option.mobile_columns!,
      spaceBetween: this.mode === 'PC' ? this.widget.design_option.web_adjusting_spacing : this.widget.design_option.mobile_adjust_spacing,
    };
  }

  allClear() {
    if (this.checkSettingsChanges()) return;

    this.widget = this.originWidget!;
    this.originWidget = cloneDeep(this.widget);

    this.getListDesignerProduct();
  }

  setCustomPreset() {
    if (!this.checkSettingsChanges()) this.widget.template = ListDesignerPreset.MANUAL;
  }

  checkSettingsChanges() {
    return isEqual(this.widget, this.originWidget!);
  }

  dayTransform(value: number): string {
    switch (value) {
      case 1:
        return '1 day';
      case 7:
        return '1 week';
      case 30:
        return '1 month';
      case 90:
        return '3 month';
      default:
        return value.toString();
    }
  }

  onTitleChange(newValue: string) {
    function countBytes(str: string) {
      let byteCount = 0;
      for (let i = 0; i < str.length; i++) {
        const charCode = str.charCodeAt(i);
        if (charCode > 128) byteCount += 2;
        else byteCount += 1;
      }
      return byteCount;
    }

    const byteLength = countBytes(newValue);
    if (byteLength > 96) return;
    if (!this.checkSettingsChanges()) this.widget.template = ListDesignerPreset.MANUAL;
  }

  getCustomMax(productCount: number, columns: number): number {
    return Math.ceil(productCount / columns);
  }

  openDialogImgErrorInfo() {
    const dialogRef = this.dialog.open(CustomConfirmDialogComponent, { width: '768px' });
    let instance = dialogRef.componentInstance;

    instance.header = `Are your product images not displaying properly?`;
    instance.content = `If the file size of your product images is too large, they may not appear in the preview. Rest
    assured, they will still display properly within the widget itself. This is a Shopify capacity
    issue that we're actively working to resolve as quickly as possible.`;
    instance.hideCancel = true;
    instance.confirm = `Got it`;
    instance.image = `assets/image/list-designer/preview_img_error.png`;
    instance.confirmWidth = `200px`;
  }

  openDialogWidgetCreate = () => this.dialog.open(DialogWidgetCreateComponent, { width: '562px', disableClose: false });

  return = () => {
    return false;
  };

  presetOptions = {
    [ListDesignerPreset.MANUAL]: {
      template: ListDesignerPreset.MANUAL,
      _product_to_display: ListDesignerPreset.NEW_ARRIVALS,
      design_option: {
        title: 'Featured Products',
        layout: 'list',
        web_rows: 2,
        mobile_rows: 2,
        use_discount_badge_color: false,
        use_best_badge_color: false,
        use_new_badge_color: true,
        use_top_reviewed_badge_color: false,
      },
      standard_option: {
        use_realtime_purchase_standard: true,
        use_realtime_review_standard: false,
        use_review_summary: false,
        use_visitor_summary_standard: true,
        use_purchase_summary_standard: true,
        use_realtime_stock_standard: false,
      },
      btn: {
        icon: 'assets/icon/icon_custom.svg',
        name: 'Custom',
      },
    },
    [ListDesignerPreset.BEST_SELLERS]: {
      template: ListDesignerPreset.BEST_SELLERS,
      _product_to_display: ListDesignerPreset.BEST_SELLERS,
      design_option: {
        title: '🔥 Best Sellers',
        layout: 'carousel',
        web_rows: 1,
        mobile_rows: 1,
        use_discount_badge_color: true,
        use_best_badge_color: true,
        use_new_badge_color: false,
        use_top_reviewed_badge_color: false,
      },
      standard_option: {
        use_realtime_purchase_standard: true,
        use_realtime_review_standard: true,
        use_review_summary: true,
        use_visitor_summary_standard: true,
        use_purchase_summary_standard: true,
        use_realtime_stock_standard: true,
      },
      btn: {
        icon: 'assets/icon/icon_best_seller.svg',
        name: 'Best Seller',
      },
    },
    [ListDesignerPreset.NEW_ARRIVALS]: {
      template: ListDesignerPreset.NEW_ARRIVALS,
      _product_to_display: ListDesignerPreset.NEW_ARRIVALS,
      design_option: {
        title: '⚡️ New Arrivals',
        layout: 'carousel',
        web_rows: 1,
        mobile_rows: 1,
        use_discount_badge_color: true,
        use_best_badge_color: false,
        use_new_badge_color: true,
        use_top_reviewed_badge_color: false,
      },
      standard_option: {
        use_realtime_purchase_standard: true,
        use_realtime_review_standard: false,
        use_review_summary: false,
        use_visitor_summary_standard: true,
        use_purchase_summary_standard: false,
        use_realtime_stock_standard: false,
      },
      btn: {
        icon: 'assets/icon/icon_new.svg',
        name: 'New Arrival',
      },
    },
    [ListDesignerPreset.TOP_REVIEWED_PRODUCTS]: {
      template: ListDesignerPreset.TOP_REVIEWED_PRODUCTS,
      _product_to_display: ListDesignerPreset.TOP_REVIEWED_PRODUCTS,
      design_option: {
        title: '👑 Top Reviewed Products',
        layout: 'list',
        web_rows: 1,
        mobile_rows: 1,
        use_discount_badge_color: true,
        use_best_badge_color: false,
        use_new_badge_color: false,
        use_top_reviewed_badge_color: true,
      },
      standard_option: {
        use_realtime_purchase_standard: true,
        use_realtime_review_standard: true,
        use_review_summary: true,
        use_visitor_summary_standard: true,
        use_purchase_summary_standard: true,
        use_realtime_stock_standard: false,
      },
      btn: {
        icon: 'assets/icon/icon_top.svg',
        name: 'Top Reviewed',
      },
      hover: false,
    },
  };
}
