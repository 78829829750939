import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { ProductReviewsRoutingModule } from './product-reviews-routing.module';
import { AnalyticsComponent } from './analytics/analytics.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ProductReviewsComponent } from './product-reviews.component';
import { RequestsComponent } from './collect/collect.component';
import { DisplayComponent } from './display/display.component';
import { ImportComponent } from './import/import.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TemplateImportComponent } from './import/template-import/template-import.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DialogReviewImportDoneComponent } from './import/dialog-review-import-done/dialog-review-import-done.component';
import { DialogReviewImportHistoryComponent } from './import/dialog-review-import-history/dialog-review-import-history.component';
import { DialogReviewImportMigrationComponent } from './import/dialog-review-import-migration/dialog-review-import-migration.component';
import { DialogReviewImportUploadComponent } from './import/dialog-review-import-upload/dialog-review-import-upload.component';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TemplateModule } from 'src/app/template/template.module';
import { MarketingComponent } from './marketing/marketing.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { SalesPopupDetailComponent } from './sales-popup/sales-popup-detail/sales-popup-detail.component';
import { SalesPopupGeneralSettingsComponent } from './sales-popup/sales-popup-general-settings/sales-popup-general-settings.component';
import { SalesPopupComponent } from './sales-popup/sales-popup.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { MatSliderModule } from '@angular/material/slider';
import { NotificationReviewReplyComponent } from './touchpoints/touchpoints-notification/notification-review-reply/notification-review-reply.component';
import { NotificationReviewRewardComponent } from './touchpoints/touchpoints-notification/notification-review-reward/notification-review-reward.component';
import { NotificationVerificationComponent } from './touchpoints/touchpoints-notification/notification-verification/notification-verification.component';
import { TouchpointsNotificationComponent } from './touchpoints/touchpoints-notification/touchpoints-notification.component';
import { TouchpointsComponent } from './touchpoints/touchpoints.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CustomerServiceNoticeComponent } from './touchpoints/touchpoints-customer-notice/customer-service-notice/customer-service-notice.component';

@NgModule({
  declarations: [
    ProductReviewsComponent,
    AdminHomeComponent,
    AnalyticsComponent,
    RequestsComponent,
    DisplayComponent,
    ImportComponent,
    TemplateImportComponent,
    DialogReviewImportUploadComponent,
    DialogReviewImportMigrationComponent,
    DialogReviewImportHistoryComponent,
    DialogReviewImportDoneComponent,
    MarketingComponent,
    ReviewsComponent,
    SalesPopupComponent,
    SalesPopupGeneralSettingsComponent,
    SalesPopupDetailComponent,
    TouchpointsComponent,
    TouchpointsNotificationComponent,
    NotificationVerificationComponent,
    NotificationReviewRewardComponent,
    NotificationReviewReplyComponent,
    CustomerServiceNoticeComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TemplateModule,
    PipeModule,
    ProductReviewsRoutingModule,
    NgxChartsModule,
    MatTabsModule,
    MatPaginatorModule,
    MatTableModule,
    MatTooltipModule,
    MatSliderModule,
    MatExpansionModule,
    DragDropModule,
  ],
})
export class ProductReviewsModule {}
