import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { inOutAnimation } from 'src/app/admin/animations';
import { DeleteType, ReviewImportLog, TransferStatus } from 'src/app/models/shop/review/options/review-import.model';
import { Router } from '@angular/router';
import { ImportService } from '../import.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-dialog-review-import-history',
  templateUrl: './dialog-review-import-history.component.html',
  styleUrls: ['./dialog-review-import-history.component.less'],
  animations: [inOutAnimation],
})
export class DialogReviewImportHistoryComponent implements OnInit {
  @ViewChild('matTable') matTable!: MatTable<ReviewImportLog>;
  constructor(private dialogRef: MatDialogRef<DialogReviewImportHistoryComponent>, @Optional() private reviewImportService: ImportService, private router: Router) {}

  ngOnInit(): void {
    this.getReviewImportLog();
  }

  displayColumns: string[] = ['created_at', 'platform-product', 'result', 'actions'];
  dataSource: ReviewImportLog[] = [];
  emptyData: boolean = false;
  ReviewAppPlatform = this.reviewImportService.ReviewAppPlatform;
  TransferStatus = this.reviewImportService.TransferStatus;

  getReviewImportLog() {
    this.reviewImportService.getReviewImportLog().subscribe((response) => {
      this.dataSource = (response.body as ReviewImportLog[]).filter((item) => item.progress_status !== TransferStatus.ANALYSIS_SAVE_AWAITING);

      this.dataSource.length == 0 ? (this.emptyData = true) : (this.emptyData = false);
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onCancelClick(reviewImportLog: ReviewImportLog) {
    this.reviewImportService.deleteReviewImportLog(reviewImportLog, DeleteType.DELETE).subscribe((response) => {
      let body = response.body as ReviewImportLog;
      reviewImportLog.progress_status = body.progress_status;
      this.matTable.renderRows();
      this.ngOnInit();
    });
  }

  inProgressCount(task: number, total: number): number {
    return Math.floor((task / total) * 100);
  }

  viewAllReview(logId: number) {
    this.dialogRef.close();
    this.router.navigate(['/admin/product-reviews/reviews'], { state: { log_id: logId } });
  }
}
