<article id="dialog-review-import-done">
  <section class="dialog-body" *ngIf="data.reviewImportLog.app_platform == reviewImportService.ReviewAppPlatform.ALI_EXPRESS">
    <section class="subject_title">Review import completes in 5 minutes</section>
    The reviews will be imported in 5 minutes. We will inform you via email when it's done. In case of any errors, refer to our
    <a href="https://alph.oopy.io/9546176e-fa1c-4e7c-b5ab-2b3375cc1b0b" target="_blank" style="border-bottom: 1px solid #589bff; color: #589bff; font-weight: 500">guide</a> or
    reach out to us for assistance.
  </section>
  <section class="flex-wrapper" *ngIf="data.reviewImportLog.app_platform == reviewImportService.ReviewAppPlatform.ALI_EXPRESS">
    <button class="btn-confirm" (click)="onConfirm()">Got it</button>
  </section>
  <section class="dialog-body" *ngIf="data.reviewImportLog.app_platform == reviewImportService.ReviewAppPlatform.AMAZON" style="padding: 10px">
    <ng-container [ngSwitch]="reviewImportProgress">
      <header class="admin-header-2" style="font-weight: bold" *ngSwitchDefault>Importing reviews..</header>
      <header class="admin-header-2" style="font-weight: bold" *ngSwitchCase="reviewService.TransferStatus.SUCCESSED">Import Completed</header>
      <header class="admin-header-2" style="font-weight: bold" *ngSwitchCase="reviewService.TransferStatus.FAILED">Issue with Review Import</header>
    </ng-container>
    <ng-container [ngSwitch]="reviewImportProgress">
      <summary class="admin-header-description" style="margin-top: 8px; margin-bottom: 8px; font-weight: normal" *ngSwitchDefault>This might take a few seconds.</summary>
      <summary class="admin-header-description" style="margin-top: 8px; margin-bottom: 8px; font-weight: normal" *ngSwitchCase="reviewService.TransferStatus.SUCCESSED">
        {{ reviewImportLog.task_count }} reviews have been imported to your store!
      </summary>
      <summary class="admin-header-description" style="margin-top: 8px; margin-bottom: 8px; font-weight: normal" *ngSwitchCase="reviewService.TransferStatus.FAILED">
        Please delete any reviews that were successfully imported during the process and try again.
      </summary>
    </ng-container>

    <ng-container [ngSwitch]="reviewImportProgress">
      <alpha-progress-bar *ngSwitchDefault [value]="progressSize" style="margin-top: 10px; width: 100%"> </alpha-progress-bar>
      <!-- <span *ngSwitchCase="reviewService.TransferStatus.SUCCESSED" class="import-upload-success">
        <img src="assets/icon/check_circle_blue.svg" style="margin-right: 6px;">
        Import complete
      </span> -->

      <section class="flex-wrapper button-wrap" *ngSwitchCase="reviewService.TransferStatus.SUCCESSED">
        <div class="flex-wrapper">
          <button class="btn-cancel" (click)="onConfirm()">Cancel</button>
          <button class="btn-confirm" (click)="viewAllReview()">VIEW</button>
        </div>
      </section>
      <section class="flex-wrapper button-wrap" *ngSwitchCase="reviewService.TransferStatus.FAILED">
        <div class="flex-wrapper">
          <button class="btn-cancel" (click)="onConfirm()">Cancel</button>
          <button class="btn-confirm" (click)="reTryImport()">Try again</button>
        </div>
      </section>
    </ng-container>
  </section>
</article>
