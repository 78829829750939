import { Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, SkipSelf, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { List } from 'lodash';
import { CountryEnum, SuperAdminDetailOption } from 'src/app/models/super-admin/super-admin.shops.enums';
import { BasicInfoEditDetailInput, BasicInfoEditShopInput, COUNTRYNAME, ShopAdminContact, SuperAdminShopInfo } from 'src/app/models/super-admin/super-admin.shops.model';
import { BackendService } from 'src/app/service/backend.service';
import { GenericService } from 'src/app/service/generic.service';
import { SuperAdminService } from 'src/app/service/super-admin.service';
import { INDUSTRY } from 'src/app/shared/preset/industry';
@Component({
  selector: 'app-shop-info',
  templateUrl: './shop-info.component.html',
  styleUrls: ['./shop-info.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ShopInfoComponent implements OnInit, OnChanges {

  constructor(
    @Optional() @SkipSelf() public superAdminService: SuperAdminService,
    public genericService: GenericService,
    private backendService: BackendService,
  ) {

  }
  @Input('shopInfo') shopInfo?: SuperAdminShopInfo;
  @Input('option') option?: SuperAdminDetailOption;

  @Output() refresh = new EventEmitter<any>();

  basicInfoEdit: boolean = false;
  adminInfoEdit: boolean = false;

  countries = COUNTRYNAME
  countryControl = new UntypedFormControl('', Validators.required);

  industries = INDUSTRY
  industryControl = new UntypedFormControl('',)

  shopAdminContact: ShopAdminContact = {
    first_name: '',
    last_name: '',
    account_id: '',
    memo: ''
  };

  ngOnInit(): void {
  }
  copyEmail(email: string) {
    window.navigator.clipboard.writeText(email).then(() => {
      this.genericService.openSnackBar('이메일 복사 완료')
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.countryControl.setValue(this.shopInfo?.country_code as string)

    this.industryControl.setValue(this.shopInfo?.shop_detail?.industry)
  }

  onBasicInfoEdit() {
    this.basicInfoEdit = !this.basicInfoEdit
  }

  onBasicInfoSubmit() {
    let url = "super-admin/shops"
    let shop_input: BasicInfoEditShopInput = { "company_name": this.shopInfo?.company_name, "shop_name": this.shopInfo?.shop_name, "country_code": this.countryControl.value }
    let detail_input: BasicInfoEditDetailInput = { "industry": this.industryControl.value }
    let payload = {
      'shop_input': shop_input,
      'detail_input': detail_input

    }
    this.backendService.patch(url, payload = payload, { "shop_access_code": this.shopInfo?.access_code }).subscribe(response => {
      this.onRefresh()
    })
    this.onBasicInfoEdit()
  }

  onAdminInfoEdit() {

    this.adminInfoEdit = !this.adminInfoEdit
  }
  onAdminDelete(id: number | undefined) {
    let url = `super-admin/shops/admin/${id}`
    this.backendService.delete(url).subscribe(response => {
      this.onRefresh()
    })
  }
  onAdminCreate() {
    const formData = new FormData()
    formData.append('shop_id', String(this.shopInfo?.id))
    formData.append('first_name', this.shopAdminContact.first_name)
    formData.append('last_name', this.shopAdminContact.last_name)
    formData.append('account_id', this.shopAdminContact.account_id)
    formData.append('memo', this.shopAdminContact.memo)
    let payload = {
      'shop_id': this.shopInfo?.id,
      'payload': this.shopAdminContact
    }
    let url = 'super-admin/shops/admin'
    this.backendService.create(url, payload = payload).subscribe(response => {
      this.shopAdminContact.first_name = ''
      this.shopAdminContact.last_name = ''
      this.shopAdminContact.account_id = ''
      this.shopAdminContact.memo = ''
      this.onRefresh()
    })

  }
  onAdminInfoSubmit() {
    let url = "super-admin/shops/admin"
    this.backendService.patch(url, this.shopInfo?.super_admin_shop_contact).subscribe(response => {

    })
  }
  onRefresh() {
    this.refresh.emit()
  }
}
