<article id="memo-box">
  <div class="memo-input-box">
    <div><textarea class="memo-input" [(ngModel)]="memo"></textarea></div>
    <button class="button-memo" (click)="createMemo()">메모</button>
  </div>
  <cdk-virtual-scroll-viewport itemSize="20" class="memo-scroll-box">
    <div *cdkVirtualFor="let shopMemo of shopInfo?.super_admin_shop_memos" class="memos">
      <div class="memo-header">
        <span>{{ shopMemo.created_at | date : 'yyyy-MM-dd HH:mm' }}</span>
        <span>{{ shopMemo.author }}</span>
      </div>
      <div class="memo-content">{{ shopMemo.content }}</div>
      <mat-divider></mat-divider>
    </div>
  </cdk-virtual-scroll-viewport>
</article>
