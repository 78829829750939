<article id="admin-login-bridge" style="height: 100%">
  <section *ngIf="dashboardUrl">
    <header class="admin-login-header">
      Please click the button below<br>
      to open the admin in a new tab.
    </header>
    <img src="assets/image/page/mindungs_walking.png" style="width: 360px; height: 135px; object-fit: cover;">
    <button class="btn-goto" (click)="onClickBtn()">
      Open in a New Tab
    </button>
  </section>
</article>
