import { Component, Inject, OnInit, Optional, SkipSelf, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SuperAdminDetailOption } from 'src/app/models/super-admin/super-admin.shops.enums';
import { SuperAdminShopInfo } from 'src/app/models/super-admin/super-admin.shops.model';
import { BackendService } from 'src/app/service/backend.service';
import { SuperAdminService } from 'src/app/service/super-admin.service';

@Component({
  selector: 'app-detail',
  templateUrl: './super-admin-shops-detail.component.html',
  styleUrls: ['./super-admin-shops-detail.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class DetailComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number,
    public backendservice: BackendService,
    public router: Router,
    @Optional() @SkipSelf() publicsuperAdminService: SuperAdminService,
  ) {}

  option: SuperAdminDetailOption = SuperAdminDetailOption.SHOP_INFO;
  shopInfo?: SuperAdminShopInfo;
  ngOnInit(): void {
    this.getShopDetail();
  }
  getShopDetail() {
    const url = 'super-admin/shops/detail';
    this.backendservice.select(url, this.data).subscribe({
      next: (response) => {
        this.shopInfo = response.body as SuperAdminShopInfo;
      },
      error: (error) => {
        if (error.status == 403) {
          this.router.navigate(['super-admin', 'login']);
        }
      },
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  setOption(option: SuperAdminDetailOption) {
    this.option = option;
  }

  onRefresh(none: any) {
    this.getShopDetail();
  }
}
