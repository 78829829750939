import { Injectable } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import * as _ from 'lodash';
import { cloneDeep, isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { DialogProductVariantSelectComponent } from '../admin/dialog/dialog-product-variant-select/dialog-product-variant-select.component';
import { DialogManageReviewDemoGuideComponent } from '../admin/product-reviews/reviews/review-manage/manage-dialog/dialog-manage-review-demo-guide/dialog-manage-review-demo-guide.component';
import { DialogManageReviewExportComponent } from '../admin/product-reviews/reviews/review-manage/manage-dialog/dialog-manage-review-export/dialog-manage-review-export.component';
import { DialogManageReviewUnpublishComponent } from '../admin/product-reviews/reviews/review-manage/manage-dialog/dialog-manage-review-unpublish/dialog-manage-review-unpublish.component';
import { LineItemShipmentStatus } from '../models/order/line-item.model';
import { Product, ProductVariant, ReviewDetailsType } from '../models/product/product.model';

import {
  AdminRewardAction,
  CheckRequiredStatus,
  Review,
  ReviewComment,
  ReviewManageReview,
  ReviewMedia,
  ReviewMediaType,
  ReviewMemo,
  ReviewMemoComment,
  ReviewPublishStatus,
  ReviewRewardStatus,
  ReviewType,
  ReviewUnpublishedReason,
  ReviewUnpublishReasonCheckBox,
  VerificationStatus,
  WriterType,
  WritingPlatform,
  WritingSource,
} from '../models/review/review.model';
import { DiscountCodeType, PriceRule } from '../models/shop/auth/benefit/benefit.model';
import { ReviewFixedTarget, ReviewFixedType } from '../models/shop/review/options/review-fixed.model';
import { TransferStatus } from '../models/shop/review/options/review-import.model';
import { Label, LabelType } from '../models/shop/review/options/review-label.model';

import { SocialPushPlatform } from '../models/social/social.model';
import { PaginationHandler } from '../shared/handler/pagination-handler';
import { AdminService } from './admin.service';
import { BackendService } from './backend.service';
import { GenericService } from './generic.service';
import { ShopReviewComment } from '../models/shop/review/options/review-comment.model';
import { DialogManageReviewWriteComponent } from '../admin/product-reviews/reviews/review-manage/manage-dialog/dialog-manage-review-write/dialog-manage-review-write.component';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  constructor(private backendService: BackendService, private genericService: GenericService, private adminService: AdminService, private dialog: MatDialog) {
    this.getReviewManageReviewLabels();
  }

  reviewFilters: ReviewFilters = new ReviewFilters();
  originalReviewFilters?: ReviewFilters;

  selectedReview!: ReviewManageReview;

  manageReviewLoading: boolean = false;
  manageReviewProductIds: number[] = [];
  manageReviewIds: number[] = [];

  manageReviews: ReviewManageReview[] = [];
  manageReviewDateRange: DateRange<Date> = new DateRange(
    new Date(new Date(new Date().setMonth(new Date().getMonth() - 12)).setHours(0, 0, 0, 0)),
    new Date(new Date().setHours(23, 59, 59, 99)),
  );
  manageReviewLatestQueryParams = {};

  labels: Label[] = [];

  reviewLabelCommon = this.filterReviewLabels(LabelType.COMMON);
  reviewLabelProduct = this.filterReviewLabels(LabelType.PRODUCT);

  searchAbout: 'all' | 'customer_name' | 'customer_phone' | 'customer_email' | 'content' = 'all';
  searchContent: string = '';
  currentSearchContent: string = '';
  smartFilterOption: SmartFilterOptions | '' = '';
  needCheckOption: CheckRequiredStatus | '' = '';
  sortingType: string = 'newest';
  paginationHandler!: PaginationHandler;

  reviewImgSrc?: ReviewMedia[];
  reviewImgPreview?: boolean;
  reviewImgType?: ReviewMediaType;

  emptyData: boolean = false;

  storeTab: boolean = false;

  showClearButton: boolean = false;

  shopReviewComments: ShopReviewComment[] = [];

  selectedProduct?: Product;

  getReviews(params: {} = {}) {
    let url = 'reviews';
    return this.backendService.select(url, params);
  }

  getManageReviewParams(imported?: boolean) {
    return {
      shop_access_code: this.adminService.getShopAccessCode(),
      start_at: !imported ? this.manageReviewDateRange.start?.toISOString() : undefined,
      end_at: !imported ? this.manageReviewDateRange.end?.toISOString() : undefined,
      embed: [
        'product',
        'review_media',
        'labels',
        'review_fixeds',
        'product_variant',
        'product_variants',
        'review_comments',
        'review_memos',
        'review_reward_logs',
        'review_reward_schedulers',
        'price_rule',
      ],
      sorting_type: this.sortingType,
      filters: JSON.stringify(this.reviewFilters),
      skip: this.paginationHandler.skip,
      limit: this.paginationHandler.limit,
      search_about: this.searchAbout,
      search_content: this.searchContent,
      product_ids: this.manageReviewProductIds,
      review_ids: this.manageReviewIds,
    };
  }

  allClearReviewParams() {
    this.manageReviewDateRange = new DateRange(
      new Date(new Date(new Date().setMonth(new Date().getMonth() - 12)).setHours(0, 0, 0, 0)),
      new Date(new Date().setHours(23, 59, 59, 99)),
    );
    this.sortingType = 'newest';
    this.reviewFilters = new ReviewFilters();
    this.searchAbout = 'all';
    this.searchContent = '';
    this.manageReviewProductIds = [];
    this.manageReviewIds = [];
  }

  getManageReview(event: PageEvent | undefined = undefined, params: {} = {}, imported?: boolean, filterBtn?: boolean, el?: HTMLElement) {
    this.currentSearchContent = this.searchContent.toString();

    if (this.manageReviewLoading) return;
    this.manageReviewLoading = true;

    if (event) {
      this.updatePaginationOnEvent(event);
    } else {
      this.updatePaginationOnQueryParams(params);
    }

    let queryParams: any = { ...this.getManageReviewParams(imported), ...params };

    this.getReviews(queryParams).subscribe({
      next: (response) => {
        this.handleReviewResponse(response, queryParams, filterBtn, el);
      },
      error: (error) => {
        this.manageReviewLoading = false;
      },
    });
  }

  updatePaginationOnEvent(event: PageEvent): void {
    this.paginationHandler.skip = event.pageSize * event.pageIndex;
    this.paginationHandler.limit = event.pageSize;
  }

  updatePaginationOnQueryParams(params: {}): void {
    const queryParamsChanged = !isEqual({ ...this.manageReviewLatestQueryParams }, { ...this.getManageReviewParams(), ...params });

    if (queryParamsChanged) {
      this.paginationHandler.skip = 0;
      this.paginationHandler.paginator.firstPage();
    }
  }

  handleReviewResponse(response: any, queryParams: any, filterBtn?: boolean, el?: HTMLElement): void {
    this.manageReviewLoading = false;
    this.manageReviews = response.body.data as ReviewManageReview[];
    this.paginationHandler.paginator.length = response.body.length;

    this.manageReviews.forEach((review) => this.setFixedData(review));
    this.manageReviewLatestQueryParams = queryParams;
    this.emptyData = this.manageReviews.length === 0;

    if (filterBtn) {
      this.showClearButton = true;
      this.originalReviewFilters = cloneDeep(this.reviewFilters);
    }

    el && el.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  setFixedData(review: ReviewManageReview) {
    if (review.review_fixeds.length === 0) {
      this.resetFixedActive(review);
      return;
    }

    if (review.review_fixeds.some((item) => item.target !== ReviewFixedTarget.ALL)) {
      review.fixedTopAllActive = false;
      review.fixedBottomAllActive = false;
    }
    if (review.review_fixeds.some((item) => item.target !== ReviewFixedTarget.PRODUCT)) {
      review.fixedTopProductActive = false;
      review.fixedBottomProductActive = false;
    }
    for (let reviewFixed of review.review_fixeds) {
      if (reviewFixed.target === ReviewFixedTarget.ALL) this.setFixedActive(review, reviewFixed.rank, false);
      if (reviewFixed.target === ReviewFixedTarget.PRODUCT) this.setFixedActive(review, reviewFixed.rank, true);
      if (reviewFixed.target === ReviewFixedTarget.LABEL) this.setFixedLabelActive(review, reviewFixed.target_id, reviewFixed.rank);
    }
  }

  resetFixedActive(review: ReviewManageReview) {
    review.fixedTopAllActive = false;
    review.fixedBottomAllActive = false;
    review.fixedTopProductActive = false;
    review.fixedBottomProductActive = false;

    if (!review.labels?.length) return;
    for (let label of review.labels) {
      label.fixedTopActive = false;
      label.fixedBottomActive = false;
    }
  }

  setFixedActive(review: ReviewManageReview, rank: number, isProduct: boolean) {
    if (rank > 0 && !isProduct) review.fixedTopAllActive = true;
    if (rank < 0 && !isProduct) review.fixedBottomAllActive = true;
    if (rank > 0 && isProduct) review.fixedTopProductActive = true;
    if (rank < 0 && isProduct) review.fixedBottomProductActive = true;
  }

  setFixedLabelActive(review: ReviewManageReview, targetId: number, rank: number) {
    if (!review.labels?.length) return;
    review.labels.forEach((label) => {
      if (label.id !== targetId) return;
      if (rank > 0) {
        label.fixedTopActive = true;
        label.fixedBottomActive = false;
      } else {
        label.fixedTopActive = false;
        label.fixedBottomActive = true;
      }
    });
  }

  createReview(review: FormData, params: { [key: string]: any }) {
    let url = 'reviews';
    return this.backendService.form(url, review, params);
  }

  deleteReview(reviewId: number) {
    let url = 'reviews/' + reviewId;
    return this.backendService.delete(url);
  }

  createAdminReview(review: FormData, params: { [key: string]: any }) {
    let url = 'reviews/admin';
    return this.backendService.form(url, review, params);
  }

  createReviewMemo(reviewMemo: ReviewMemo, params: {} = {}) {
    const url = 'reviews/memos';
    return this.backendService.create(url, reviewMemo, params);
  }

  updateReviewMemo(reviewMemo: ReviewMemo, params: {} = {}) {
    const url = 'reviews/memo/' + reviewMemo.id;
    return this.backendService.update(url, reviewMemo, params);
  }

  deleteReviewMemo(params: { review_memo_id: number; author_id: number }) {
    const url = 'reviews/memo/' + params.review_memo_id;
    return this.backendService.delete(url, params);
  }

  convertReviewToFormdata(review: Review) {
    let reviewClone = Object.assign(new Review(), review);
    const formData = new FormData();
    reviewClone.review_media.forEach((item) => {
      if (item.file) {
        formData.append('files', item.file as Blob);
      }
    });
    reviewClone.review_media = reviewClone.review_media.filter((item) => !item.previewURL);
    formData.set('review', JSON.stringify(reviewClone));
    return formData;
  }

  createReviewMemoComment(reviewMemoComment: ReviewMemoComment, params: {} = {}) {
    const url = 'reviews/memo/' + reviewMemoComment.review_memo_id + '/comments';
    return this.backendService.create(url, reviewMemoComment, params);
  }

  getBenefitPolicy(param: {} = {}) {
    let url = 'reviews/benefit-policy';
    return this.backendService.select(url, param);
  }

  createReviewReply(payload: {} = {}, params: {} = {}) {
    let url = 'reviews/comments';
    return this.backendService.create(url, payload, params);
  }

  updateReviewReply(payload: ReviewComment, params: {} = {}) {
    let url = 'reviews/comment/' + payload.id;
    return this.backendService.update(url, payload, params);
  }

  deleteReviewReply(params: { review_comment_id: number; author_id: number; is_admin: boolean }) {
    let url = 'reviews/comment/' + params.review_comment_id;
    return this.backendService.delete(url, params);
  }

  /**
   * 리뷰 관리 화면 내 리뷰 수정에 대한 책임을 지는 함수.
   * 일반 사용자 화면(리뷰 작성 등)에서 사용되지 않는 방향으로 사용
   *
   * @param review : ReviewManageReview
   * @returns
   */
  patchReview(review: { id?: number; [key: string]: any }) {
    let url = 'reviews/' + review.id;
    return this.backendService.patch(url, review);
  }

  updateReview(review: FormData, params: { [key: string]: any }) {
    let url = 'reviews';
    return this.backendService.formPatch(url, review, params);
  }

  setReviewFixed(payload: {} = {}) {
    let url = 'reviews/fixed';
    return this.backendService.create(url, payload, {
      shop_access_code: this.adminService.getShopAccessCode(),
    });
  }

  removeReviewFixed(params: { review_id: number; target: ReviewFixedTarget; target_id: number; fixed_type: boolean }) {
    let url = 'reviews/fixed';
    return this.backendService.delete(url, {
      shop_access_code: this.adminService.getShopAccessCode(),
      ...params,
    });
  }

  getReviewLabels(params: {} = {}) {
    let url = 'reviews/labels';
    return this.backendService.select(url, params);
  }

  setReviewLabel(payload: {} = {}) {
    let url = 'reviews/labels/manual';
    return this.backendService.patch(url, payload);
  }

  removeReviewLabel(reviewIds: number[]) {
    let url = 'reviews/label/all/';
    return this.backendService.delete(url, { review_ids: reviewIds });
  }

  copyReview(payload: {} = {}, params: {} = {}) {
    let url = 'reviews/action/copy';
    return this.backendService.create(url, payload, params);
  }

  movingReview(payload: {} = {}, params: {} = {}) {
    let url = 'reviews/action/moving';
    return this.backendService.create(url, payload, params);
  }

  CopyOrMovingReview(action: 'copy' | 'moving', target: 'selected' | 'checked' = 'checked', review?: ReviewManageReview) {
    let mode: 'radio' | 'checkBox' = 'radio';

    if (action == 'copy') mode = 'checkBox';

    const dialogRef = this.dialog.open(DialogProductVariantSelectComponent, {
      width: '480px',
      maxHeight: '70vh',
      data: {
        mode: mode,
        action: action,
      },
    });

    dialogRef.afterClosed().subscribe((result: Product[]) => {
      if (result) {
        let review_ids = [];
        if (target == 'checked') {
          review_ids = this.manageReviews.filter((item) => item.selected).map((item) => item.id);
        } else {
          review_ids = [review?.id];
        }

        const payload = {
          review_ids: review_ids,
          product_ids: result.map((item) => item.id),
        };

        if (action == 'copy') {
          this.copyReview(payload).subscribe((response) => {
            this.genericService.openSnackBar('Saved successfully');
            this.getManageReview();
          });
        }

        if (action == 'moving') {
          this.movingReview(payload).subscribe((response) => {
            this.genericService.openSnackBar('Saved successfully');
            this.getManageReview();
          });
        }
      }
    });
  }

  onClickReviewWrite(type: string, review?: ReviewManageReview) {
    const dialogRef = this.dialog.open(DialogManageReviewWriteComponent, {
      width: '530px',
      maxHeight: '70vh',
      disableClose: true,
      data: {
        type: type,
        review: review,
      },
    });
  }

  onClickReviewFilterElement(key: keyof ReviewFilters, ...values: any[]) {
    const array = this.reviewFilters[key] as any;

    values.forEach((value) => {
      const idx = array.indexOf(value, 0);
      if (idx > -1) {
        array.splice(idx, 1);
      } else {
        array.push(value);
      }
    });

    if (!this.checkedFilterChanges()) this.showClearButton = false;
  }

  removeReviewFilterElement(key: keyof ReviewFilters, element: any) {
    this.reviewFilters[key] = (this.reviewFilters[key] as any).filter((item: any) => item !== element);
    if (!this.checkedFilterChanges()) this.showClearButton = false;
  }

  hasValueInReviewFilters(reviewFilters: ReviewFilters): boolean {
    const keys = Object.keys(reviewFilters);
    return Object.values(reviewFilters).some(
      (value, idx) =>
        value.length > 0 &&
        keys[idx] !== 'is_archived' &&
        keys[idx] !== 'type' &&
        keys[idx] !== 'label_id' &&
        keys[idx] !== 'writing_platform' &&
        keys[idx] !== 'review_import_log_id',
    );
  }

  checkedFilterChanges() {
    return isEqual(this.reviewFilters, this.originalReviewFilters);
  }

  allRemoveReviewFilters() {
    this.reviewFilters = new ReviewFilters();
  }

  getReviewExportLog(params: {} = {}) {
    const url = 'reviews/export';
    return this.backendService.select(url, params);
  }

  createReviewExportLog(payload: {} = {}, params: {} = {}) {
    const url = 'reviews/export/';
    return this.backendService.create(url, payload, params);
  }

  getReviewManageReviewLabels() {
    let param = {
      shop_access_code: this.adminService.getShopAccessCode()!,
    };

    if (this.adminService.getShopAccessCode()!) {
      this.getReviewLabels(param).subscribe((response) => {
        this.labels = response.body as Label[];
        this.reviewLabelCommon = this.filterReviewLabels(LabelType.COMMON);
        this.reviewLabelProduct = this.filterReviewLabels(LabelType.PRODUCT);
      });
    }
  }

  filterReviewLabels(type: LabelType) {
    return this.labels.filter((item) => item.type == type);
  }

  checkDisplayProductVariant(productVariant?: ProductVariant) {
    if (productVariant?.title == 'Default Title') {
      return false;
    }
    return true;
  }

  actionReviewReward(review: ReviewManageReview, priceRule: PriceRule, behavior: AdminRewardAction) {
    let url = 'review-benefit/action/reward';
    let params: {
      behavior: AdminRewardAction;
      review_id?: number;
      shop_access_code: string;
      price_rule_id?: number;
    } = {
      behavior: behavior,
      review_id: review.id,
      shop_access_code: this.adminService.getShopAccessCode(),
    };

    if (priceRule.id) params.price_rule_id = priceRule.id;
    return this.backendService.create(url, priceRule, params);
  }

  actionReviewPublish(reviewIds: number[]) {
    let url = 'reviews/action/publish';

    return this.backendService.patch(url, { review_ids: reviewIds }, { shop_access_code: this.adminService.getShopAccessCode() });
  }

  actionReviewUnpublish(reviewIds: number[], reviewUnpublishedReason: ReviewUnpublishedReason) {
    let url = 'reviews/action/unpublish';

    return this.backendService.patch(url, { data: reviewUnpublishedReason, review_ids: reviewIds }, { shop_access_code: this.adminService.getShopAccessCode() });
  }

  actionReviewUnpin(reviewIds: number[]) {
    let url = 'reviews/fixed/all';

    return this.backendService.delete(url, { review_ids: reviewIds, shop_access_code: this.adminService.getShopAccessCode() });
  }

  actionReviewArchive(reviewIds: number[], archived: boolean) {
    let url = 'reviews/archive/';

    return this.backendService.patch(url, { review_ids: reviewIds }, { archive: archived, shop_access_code: this.adminService.getShopAccessCode() });
  }

  actionReviewSocialPush(payload: {} = {}, isImmediately: boolean) {
    let url = 'social/push/logs';

    return this.backendService.create(url, payload, { is_immediately: isImmediately, shop_access_code: this.adminService.getShopAccessCode() });
  }

  openDialogManageReviewExport(data: { review_ids?: number[]; product_ids?: number[] }) {
    const dialogRef = this.dialog.open(DialogManageReviewExportComponent, {
      width: '740px',
      maxHeight: '70vh',
      data: data,
    });
  }

  openDialogManageReviewUnpublish(): Observable<any> {
    const dialogRef = this.dialog.open(DialogManageReviewUnpublishComponent, {
      width: '570px',
      maxHeight: '700px',
      data: {},
    });

    return dialogRef.afterClosed();
  }

  openDialogManageReviewDemoGuide() {
    const dialogRef = this.dialog.open(DialogManageReviewDemoGuideComponent, {
      width: '600px',
      height: '500px',
      data: {},
    });
    return dialogRef.afterClosed();
  }

  reviewImgPreviewer(reviewMedia: ReviewMedia[]) {
    this.reviewImgSrc = reviewMedia;
    this.reviewImgPreview = true;
  }

  get LabelType(): typeof LabelType {
    return LabelType;
  }

  get WritingPlatform(): typeof WritingPlatform {
    return WritingPlatform;
  }

  get WritingSource(): typeof WritingSource {
    return WritingSource;
  }

  get WriterType(): typeof WriterType {
    return WriterType;
  }

  get ReviewFixedTarget(): typeof ReviewFixedTarget {
    return ReviewFixedTarget;
  }

  get ReviewFixedType(): typeof ReviewFixedType {
    return ReviewFixedType;
  }

  get ReviewType(): typeof ReviewType {
    return ReviewType;
  }

  get ReviewMediaType(): typeof ReviewMediaType {
    return ReviewMediaType;
  }

  get ReviewDetailsType(): typeof ReviewDetailsType {
    return ReviewDetailsType;
  }

  get TransferStatus(): typeof TransferStatus {
    return TransferStatus;
  }

  public get SocialPushPlatform(): typeof SocialPushPlatform {
    return SocialPushPlatform;
  }

  public get AdminRewardAction(): typeof AdminRewardAction {
    return AdminRewardAction;
  }

  public get ReviewRewardStatus(): typeof ReviewRewardStatus {
    return ReviewRewardStatus;
  }

  public get ReviewPublishStatus(): typeof ReviewPublishStatus {
    return ReviewPublishStatus;
  }

  public get ReviewUnpublishReasonCheckBox(): typeof ReviewUnpublishReasonCheckBox {
    return ReviewUnpublishReasonCheckBox;
  }

  public get CheckRequiredStatus(): typeof CheckRequiredStatus {
    return CheckRequiredStatus;
  }

  public get VerificationStatus(): typeof VerificationStatus {
    return VerificationStatus;
  }

  public get DiscountCodeType(): typeof DiscountCodeType {
    return DiscountCodeType;
  }
}

export class ReviewFilters {
  rating: number[] = [];
  writing_platform: WritingPlatform[] = [];
  writing_source: WritingSource[] = [];
  type: ReviewType[] = [];
  writer_type: WriterType[] = [];
  verification_status: VerificationStatus[] = [];
  check_required_status: CheckRequiredStatus[] = [];
  updated_by_admin: boolean[] = [];
  publish_status: ReviewPublishStatus[] = [];
  customer_id: number[] = [];
  is_archived: boolean[] = [false];
  is_quick: boolean[] = [];
  is_text: boolean[] = [];
  is_photo: boolean[] = [];
  is_video: boolean[] = [];
  is_survey: boolean[] = [];
  is_keyword: boolean[] = [];
  is_comment: boolean[] = [];
  is_copied: boolean[] = [];
  is_reported: boolean[] = [];
  is_social_shared: boolean[] = [];
  is_verified: boolean[] = [];
  reward_status: ReviewRewardStatus[] = [];
  order_status: LineItemShipmentStatus[] = [];
  is_top_fixed: boolean[] = [];
  is_memo: boolean[] = [];
  label_id: number[] = [];
  is_imported: boolean[] = [];
  review_import_log_id: number[] = [];
}

export enum SmartFilterOptions {
  HIGH_RATED = 0,
  AVERAGE_RATED = 1,
  LOW_RATED = 2,
  REVIEW_WITH_PHOTO = 3,
  REVIEW_WITH_VIDEO = 4,
  VERIFIED_REVIEWS = 5,
  IMPORTED_REVIEWS = 6,
}

export enum ReviewSortOptions {
  NEWEST = 'newest',
  ORDEST = 'oldest',
  HIGH_RATED = '-rating',
  LOW_RATED = 'rating',
  LIKE = 'like',
  PHOTO = 'photo',
  CONTENT = 'long_content',
}
