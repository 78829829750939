import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'circle-question',
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" style="vertical-align:middle">
    <g >
        <g >
            <g  style="stroke:#ff50ca;stroke-width:1.5px;fill:none">
                <rect width="14" height="14" rx="7" style="stroke:none"/>
                <rect x=".75" y=".75" width="12.5" height="12.5" rx="6.25" style="fill:none"/>
            </g>
        </g>
        <path data-name="Icon awesome-question" d="M4.078 0a2.5 2.5 0 0 0-2.222 1.175.31.31 0 0 0 .067.425l.557.422a.31.31 0 0 0 .429-.054 1.253 1.253 0 0 1 1.069-.638c.4 0 .889.256.889.641 0 .291-.24.441-.633.661-.457.256-1.063.576-1.063 1.374v.126a.31.31 0 0 0 .31.31h.936a.31.31 0 0 0 .31-.31v-.074c0-.553 1.618-.577 1.618-2.074A2.219 2.219 0 0 0 4.078 0zm-.129 4.823a.895.895 0 1 0 .895.894.9.9 0 0 0-.895-.894z" transform="translate(2.928 3.694)"  [ngStyle]="{fill: fillColor}"/>
    </g>
</svg>

  `,
  styles: [
  ]
})
export class CircleQuestionComponent implements OnInit {
  @Input() fillColor = '#ffa750'
  constructor() { }

  ngOnInit(): void {
  }

}
