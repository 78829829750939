"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Redirect = exports.isProductVariantResourcePayload = exports.isCreateResourcePayload = exports.isAdminSection = exports.isRemotePayload = exports.isAdminSectionPayload = exports.isAdminPathPayload = exports.isAppPayload = exports.getRelativePath = exports.normalizeUrl = exports.getPathWithSearchAndHash = exports.toDestination = exports.toApp = exports.toRemote = exports.toAdminSection = exports.toAdminPath = exports.isResourcePayload = exports.ResourceType = exports.Action = void 0;
var helper_1 = require("../../helper");
var ActionSet_1 = require("../../ActionSet");
var types_1 = require("../../types");
var Action;
(function (Action) {
    Action["ADMIN_SECTION"] = "APP::NAVIGATION::REDIRECT::ADMIN::SECTION";
    Action["ADMIN_PATH"] = "APP::NAVIGATION::REDIRECT::ADMIN::PATH";
    Action["REMOTE"] = "APP::NAVIGATION::REDIRECT::REMOTE";
    Action["APP"] = "APP::NAVIGATION::REDIRECT::APP";
})(Action = exports.Action || (exports.Action = {}));
var ResourceType;
(function (ResourceType) {
    ResourceType["Product"] = "products";
    ResourceType["Collection"] = "collections";
    ResourceType["Order"] = "orders";
    ResourceType["Customer"] = "customers";
    ResourceType["Discount"] = "discounts";
})(ResourceType = exports.ResourceType || (exports.ResourceType = {}));
function isResourcePayload(resource) {
    // tslint:disable-next-line:no-boolean-literal-compare
    return typeof resource.id === 'string';
}
exports.isResourcePayload = isResourcePayload;
function toAdminPath(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.Navigation,
        type: Action.ADMIN_PATH,
    });
}
exports.toAdminPath = toAdminPath;
function toAdminSection(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.Navigation,
        type: Action.ADMIN_SECTION,
    });
}
exports.toAdminSection = toAdminSection;
function toRemote(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.Navigation,
        type: Action.REMOTE,
    });
}
exports.toRemote = toRemote;
function toApp(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.Navigation,
        type: Action.APP,
    });
}
exports.toApp = toApp;
function toDestination(action, payload, id) {
    switch (action) {
        case Action.APP: {
            var appPayload = isAppPayload(payload) ? payload : { path: payload };
            return toApp(__assign({ id: id }, appPayload));
        }
        case Action.ADMIN_PATH: {
            var adminPathPayload = isAdminPathPayload(payload) ? payload : { path: payload };
            return toAdminPath(__assign({ id: id }, adminPathPayload));
        }
        case Action.ADMIN_SECTION: {
            var adminSectionPayload = isAdminSectionPayload(payload)
                ? payload
                : { section: payload };
            return toAdminSection(__assign({ id: id }, adminSectionPayload));
        }
        case Action.REMOTE: {
            var remotePayload = isRemotePayload(payload) ? payload : { url: payload };
            return toRemote(__assign({ id: id }, remotePayload));
        }
    }
}
exports.toDestination = toDestination;
function getPathWithSearchAndHash(_a) {
    var pathname = _a.pathname, search = _a.search, hash = _a.hash;
    return "" + pathname + (search || '') + (hash || '');
}
exports.getPathWithSearchAndHash = getPathWithSearchAndHash;
function normalizeUrl(to) {
    if (to instanceof URL) {
        return to.toString();
    }
    if (typeof to === 'string') {
        return to;
    }
    return getRelativePath(to);
}
exports.normalizeUrl = normalizeUrl;
function getRelativePath(to) {
    if (typeof to === 'string') {
        if (to.startsWith('/')) {
            return to;
        }
        return getPathWithSearchAndHash(new URL(to));
    }
    var search = to.search instanceof URLSearchParams ? to.search.toString() : to.search;
    return getPathWithSearchAndHash(__assign(__assign({}, to), { search: search }));
}
exports.getRelativePath = getRelativePath;
function isAppPayload(payload) {
    return typeof payload === 'object' && Object.prototype.hasOwnProperty.call(payload, 'path');
}
exports.isAppPayload = isAppPayload;
function isAdminPathPayload(payload) {
    return typeof payload === 'object' && Object.prototype.hasOwnProperty.call(payload, 'path');
}
exports.isAdminPathPayload = isAdminPathPayload;
function isAdminSectionPayload(payload) {
    return (typeof payload === 'object' &&
        typeof payload.section === 'object' &&
        Object.prototype.hasOwnProperty.call(payload.section, 'name'));
}
exports.isAdminSectionPayload = isAdminSectionPayload;
function isRemotePayload(payload) {
    return typeof payload === 'object' && Object.prototype.hasOwnProperty.call(payload, 'url');
}
exports.isRemotePayload = isRemotePayload;
function isAdminSection(to) {
    return typeof to === 'object' && typeof (to === null || to === void 0 ? void 0 : to.name) === 'string';
}
exports.isAdminSection = isAdminSection;
function isCreateResourcePayload(resource) {
    // tslint:disable-next-line:no-boolean-literal-compare
    return resource.create === true;
}
exports.isCreateResourcePayload = isCreateResourcePayload;
function isProductVariantResourcePayload(resource) {
    var castResource = resource;
    // tslint:disable-next-line:no-boolean-literal-compare
    return castResource.id !== undefined && castResource.variant !== undefined;
}
exports.isProductVariantResourcePayload = isProductVariantResourcePayload;
var Redirect = /** @class */ (function (_super) {
    __extends(Redirect, _super);
    function Redirect(app) {
        return _super.call(this, app, 'Redirect', types_1.Group.Navigation) || this;
    }
    Object.defineProperty(Redirect.prototype, "payload", {
        get: function () {
            return { id: this.id };
        },
        enumerable: false,
        configurable: true
    });
    Redirect.prototype.dispatch = function (action, payload) {
        var redirectAction = toDestination(action, payload, this.payload.id);
        this.app.dispatch(redirectAction);
        return this;
    };
    return Redirect;
}(ActionSet_1.ActionSet));
exports.Redirect = Redirect;
