import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlphaApps } from 'src/app/models/shop/profile/store-detail.model';
import { AdminService } from 'src/app/service/admin.service';
import { DialogSelectLdTemplateComponent } from '../dialog/dialog-select-ld-template/dialog-select-ld-template.component';
import { Router } from '@angular/router';
import { BackendService } from 'src/app/service/backend.service';
import { ListDesignerPreset, ListDesignerWidget } from 'src/app/models/list-designer/widget.model';
import { PaginationHandler } from 'src/app/shared/handler/pagination-handler';
import { PageEvent } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root',
})
export class ListDesignerService {
  constructor(private adminService: AdminService, private backEndService: BackendService, private dialog: MatDialog, private router: Router) {}

  selectPreset?: ListDesignerPreset;
  widgetNameList?: string[];
  widgets: ListDesignerWidget[] | null = null;
  widgetLength: number = 0;
  paginationHandler!: PaginationHandler;
  manageWidgetsLoading: boolean = false;
  deepLinkingUrl: string = '';
  baseUrl: string = 'list-designer/';
  storeThemeEditUrl: string = `https://${this.adminService.ShopUrl}/admin/themes`;
  activeCoreScript: boolean | null = null;

  openDialogSelectTemplate() {
    this.router.navigate(['admin/list-designer/widget-custom']);
  }

  getWidgets(params: {}) {
    let url = this.baseUrl;
    return this.backEndService.select(url, params);
  }

  createWidget(widget: ListDesignerWidget) {
    let url = this.baseUrl;
    return this.backEndService.create(url, widget, { shop_access_code: this.adminService.getShopAccessCode() });
  }

  getWidget(widgetId: number) {
    let url = `${this.baseUrl}${widgetId}`;
    return this.backEndService.select(url, { shop_access_code: this.adminService.getShopAccessCode() });
  }

  patchWidget(payload: {}) {
    let url = this.baseUrl;
    return this.backEndService.patch(url, payload, { shop_access_code: this.adminService.getShopAccessCode() });
  }

  deleteWidget(widgetIds: number[]) {
    let url = this.baseUrl;
    return this.backEndService.delete(url, { widget_ids: widgetIds, shop_access_code: this.adminService.getShopAccessCode() });
  }

  copyWidget(widgetIds: number[]) {
    let url = `${this.baseUrl}copy`;
    return this.backEndService.create(url, widgetIds, { shop_access_code: this.adminService.getShopAccessCode() });
  }

  getWidgetPreview(widget: ListDesignerWidget) {
    let url = `${this.baseUrl}render`;
    return this.backEndService.getText(url, { data: JSON.stringify(widget), shop_platform_id: this.adminService.getShopPlatformID(), app_id: widget.code });
  }

  getListProducts(preset: number, params: {}) {
    let url = `${this.baseUrl}${preset}/products/`;
    return this.backEndService.select(url, params);
  }

  getWidgetInProducts(widgetId: number, params: {}) {
    let url = `${this.baseUrl}products-in-widget/${widgetId}`;
    return this.backEndService.select(url, params);
  }

  getListFilterProducts(params: {}) {
    let url = `${this.baseUrl}products/`;
    return this.backEndService.select(url, params);
  }

  saveListDesignerProducts(widgetId: number, payload: {}) {
    let url = `${this.baseUrl}${widgetId}/products/`;
    return this.backEndService.create(url, payload, { shop_access_code: this.adminService.getShopAccessCode() });
  }

  checkedCoreScript() {
    let url = `${this.baseUrl}check/core-script?shop_access_code=${this.adminService.getShopAccessCode()}`;
    this.backEndService.create(url).subscribe((response) => (this.activeCoreScript = response.body.ok));
  }

  getWidgetList(event: PageEvent | undefined = undefined) {
    if (this.manageWidgetsLoading) return;
    this.manageWidgetsLoading = true;

    event && this.updatePaginationOnEvent(event);

    let params: any = {
      skip: this.paginationHandler.skip ?? 0,
      limit: this.paginationHandler.limit ?? 10,
      shop_access_code: this.adminService.getShopAccessCode(),
    };

    this.getWidgets(params).subscribe((response) => {
      this.manageWidgetsLoading = false;
      this.widgets = response.body.data;
      this.widgetLength = response.body.length;
      this.paginationHandler.paginator.length = this.widgetLength;
      this.widgetNameList = this.widgets?.map((widget) => widget.name);
    });
  }

  updatePaginationOnEvent(event: PageEvent): void {
    if (event) {
      this.paginationHandler.skip = event.pageSize * event.pageIndex;
      this.paginationHandler.limit = event.pageSize;
    } else {
      this.paginationHandler.skip = 0;
      this.paginationHandler.paginator.firstPage();
    }
  }

  getDeepLinkingUrl() {
    let url = `${this.baseUrl}deeplinking`;

    this.backEndService.select(url, { shop_access_code: this.adminService.getShopAccessCode() }).subscribe((response) => {
      let body = response.body;
      this.deepLinkingUrl = body.app_embed_block_url;

      window.open(this.deepLinkingUrl, '_blank');
    });
  }

  get ListDesignerPreset(): typeof ListDesignerPreset {
    return ListDesignerPreset;
  }
}
