import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogBrowseBoosterOnboardingComponent } from '../dialog/dialog-browse-booster-onboarding/dialog-browse-booster-onboarding.component';
import { BackendService } from 'src/app/service/backend.service';
import { AdminService } from 'src/app/service/admin.service';
import { Router } from '@angular/router';
import { PromotionSettings } from 'src/app/models/browse-booster/setup.model';
import { cloneDeep, isEqual } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class BrowseBoosterService {
  constructor(private backendService: BackendService, private adminService: AdminService, private dialog: MatDialog, private router: Router) {}

  baseUrl: string = 'browse-booster/';
  deepLinkingUrl: string = '';
  activeCoreScript: boolean | null = null;

  browseBoosterSetup!: PromotionSettings;
  originBrowseBoosterSetup!: PromotionSettings;

  getBrowseBoosterSetup() {
    this.backendService.select(this.baseUrl, { shop_access_code: this.adminService.getShopAccessCode() }).subscribe((response) => {
      this.browseBoosterSetup = response.body;
      this.originBrowseBoosterSetup = cloneDeep(this.browseBoosterSetup);
      this.router.navigate(['admin/browse-booster']);
    });
  }

  updateBrowseBoosterSetup(payload: {}, onboarding?: boolean) {
    this.backendService
      .create(this.baseUrl, payload, {
        shop_access_code: this.adminService.getShopAccessCode().length === 6 ? `BB${this.adminService.getShopAccessCode()}` : this.adminService.getShopAccessCode(),
      })
      .subscribe((response) => {
        if (onboarding) this.router.navigate(['admin/browse-booster']);
        else this.getBrowseBoosterSetup();
      });
  }

  openDialogOnboarding() {
    const dialogRef = this.dialog.open(DialogBrowseBoosterOnboardingComponent, { width: '768px', maxHeight: '80vh', disableClose: true });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      const payload = { number_of_products: result.productCount, discount_value: result.discountValue };
      this.updateBrowseBoosterSetup(payload);
    });
  }

  onDiscard() {
    this.browseBoosterSetup = this.originBrowseBoosterSetup;
    this.originBrowseBoosterSetup = cloneDeep(this.browseBoosterSetup);
  }

  checkedCoreScript() {
    let url = `${this.baseUrl}check/core-script?shop_access_code=${this.adminService.getShopAccessCode()}`;
    this.backendService.create(url).subscribe((response) => (this.activeCoreScript = response.body.ok));
  }

  getDeepLinkingUrl() {
    let url = `${this.baseUrl}deeplinking`;

    this.backendService.select(url, { shop_access_code: this.adminService.getShopAccessCode() }).subscribe((response) => {
      let body = response.body;
      this.deepLinkingUrl = body.app_embed_block_url;

      window.open(this.deepLinkingUrl, '_blank');
    });
  }

  checkSettingsChanged() {
    return isEqual(this.browseBoosterSetup, this.originBrowseBoosterSetup);
  }
}
