import { Component, Inject, OnInit, Optional, SkipSelf } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BillingService } from 'src/app/service/billing.service';

@Component({
  selector: 'app-dialog-setting-subscription-summary',
  templateUrl: './dialog-setting-subscription-summary.component.html',
  styleUrls: ['./dialog-setting-subscription-summary.component.less', '../../admin.component.less'],
})
export class DialogSettingSubscriptionSummaryComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DialogSettingSubscriptionSummaryComponent>,
    @Optional() @SkipSelf() public billingService: BillingService,
    @Inject(MAT_DIALOG_DATA) public data: {},
  ) {}

  ngOnInit(): void {}

  onNoClick() {
    this.dialogRef.close();
  }
}
