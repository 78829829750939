import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'star-filled',
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" [ngStyle]="{ width: width, height: height }" style="width: {{ width }}; height:{{ height }}" viewBox="0 0 16 16" fill="none">
      <g filter="url(#filter0_b_1861_26596)">
        <path
          d="M5.26954 5.02851L0.623641 5.68943L0.541355 5.70586C0.416789 5.73831 0.303231 5.80261 0.212277 5.89221C0.121323 5.9818 0.0562326 6.09348 0.0236521 6.21583C-0.00892837 6.33818 -0.00783137 6.46682 0.026831 6.58862C0.0614934 6.71041 0.128479 6.821 0.220948 6.90909L3.58667 10.1237L2.79294 14.6643L2.78347 14.7429C2.77585 14.8693 2.8026 14.9955 2.86099 15.1084C2.91938 15.2213 3.00731 15.317 3.11577 15.3856C3.22424 15.4543 3.34935 15.4934 3.47828 15.499C3.60721 15.5045 3.73534 15.4764 3.84955 15.4174L8.00464 13.2739L12.1503 15.4174L12.2231 15.4503C12.3433 15.4967 12.4739 15.511 12.6016 15.4916C12.7292 15.4721 12.8493 15.4197 12.9495 15.3397C13.0497 15.2597 13.1265 15.155 13.1718 15.0363C13.2172 14.9176 13.2295 14.7893 13.2076 14.6643L12.4131 10.1237L15.7803 6.90838L15.8371 6.84764C15.9183 6.74959 15.9715 6.63219 15.9913 6.50739C16.0111 6.3826 15.9969 6.25487 15.9501 6.13723C15.9032 6.01959 15.8254 5.91624 15.7246 5.8377C15.6238 5.75916 15.5036 5.70825 15.3762 5.69014L10.7303 5.02851L8.65347 0.898672C8.59337 0.779017 8.50034 0.678258 8.3849 0.6078C8.26946 0.537342 8.13622 0.5 8.00027 0.5C7.86432 0.5 7.73109 0.537342 7.61565 0.6078C7.50021 0.678258 7.40717 0.779017 7.34708 0.898672L5.26954 5.02851Z"
          [ngStyle]="{ fill: fillColor }"
        />
      </g>
      <defs>
        <filter id="filter0_b_1861_26596" x="-4" y="-3.5" width="24" height="23" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1861_26596" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1861_26596" result="shape" />
        </filter>
      </defs>
    </svg>
  `,
  styles: [],
})
export class StarFilledComponent implements OnInit {
  @Input() fillColor = '#D3D4D8';
  @Input() width: number = 42;
  @Input() height: number = 40;

  constructor() {}

  ngOnInit(): void {}
}
