import { Component } from '@angular/core';
import { ListDesignerService } from '../list-designer.service';
import { Router } from '@angular/router';
import { ListDesignerWidget } from 'src/app/models/list-designer/widget.model';
import { GenericService } from 'src/app/service/generic.service';

@Component({
  selector: 'app-widget-guide',
  templateUrl: './widget-guide.component.html',
  styleUrl: './widget-guide.component.less',
})
export class WidgetGuideComponent {
  constructor(public listDesignerService: ListDesignerService, private router: Router, private genericService: GenericService) {
    this.widget = history.state.widget;
  }

  widget!: ListDesignerWidget;
  backToWidgetCustom() {
    this.router.navigate(['admin/list-designer/widget-custom'], { state: { widgetId: this.widget.id } });
  }

  copyWidgetId() {
    navigator.clipboard.writeText(this.widget.code).then(() => this.genericService.openSnackBar('The code has been copied to the clipboard.'));
  }
}
