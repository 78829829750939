import { Component, OnInit } from '@angular/core';
import { SettingMessagingService } from '../../../../../service/setting-messaging.service';
import { TouchpointsNotificationService } from '../touchpoints-notification.service';

@Component({
  selector: 'app-notification-verification',
  templateUrl: './notification-verification.component.html',
  styleUrls: ['./notification-verification.component.less'],
})
export class NotificationVerificationComponent implements OnInit {
  constructor(public settingMessagingService: SettingMessagingService, public settingNotificationService: TouchpointsNotificationService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}
}
