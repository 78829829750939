import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerGroup } from 'src/app/models/customer/customer-group.model';
import { Collection } from 'src/app/models/product/collection.model';
import { Product } from 'src/app/models/product/product.model';
import { SeparationType } from 'src/app/models/shop/auth/benefit/benefit.model';
import { AdminService } from 'src/app/service/admin.service';
import { BackendService } from 'src/app/service/backend.service';
import { ProductService } from 'src/app/service/product.service';

@Component({
  selector: 'app-dialog-setting-product-collection-select',
  templateUrl: './dialog-setting-product-collection-select.component.html',
  styleUrls: ['./dialog-setting-product-collection-select.component.less', '../../../admin/admin.component.less'],
})
export class DialogSettingProductCollectionSelectComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogSettingProductCollectionSelectComponent>,

    @Inject(MAT_DIALOG_DATA)
    public data: {
      target: SeparationType;
      swappable: boolean;
      items: any[];
      salesPopup?: boolean;
    },
    private productService: ProductService,
    private backendService: BackendService,
    private adminService: AdminService,
  ) {}

  itemList: any[] = [];
  originItemList: any[] = [];
  selectedItemList: any[] = [];

  filterValue: string = '';

  ngOnInit(): void {
    this.listSelecter();

    if (this.data.items && !this.data.salesPopup) {
      this.selectedItemList = this.data.items;
    }
  }

  public get SeparationType(): typeof SeparationType {
    return SeparationType;
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
  }

  click() {}

  listSelecter() {
    if (this.data.target == this.SeparationType.PRODUCT) {
      this.getProducts();
    } else if (this.data.target == this.SeparationType.COLLECTION) {
      this.getCollections();
    } else if (this.data.target == this.SeparationType.CUSTOMER) {
      this.getCustomer();
    } else if (this.data.target == this.SeparationType.CUSTOMER_GROUP) {
      this.getCustomerGroup();
    }
  }

  onConfirm() {
    let result = {
      target: this.data.target,
      items: this.selectedItemList,
    };

    this.dialogRef.close(result);
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  toggleTarget(target: SeparationType) {
    if (target == this.data.target) return;
    this.data.target = target;
    this.itemList = [];
    this.selectedItemList = [];
    this.listSelecter();
  }

  addItem() {
    this.selectedItemList = this.selectedItemList.concat(...this.itemList.filter((item) => item.checked));
    this.itemList = this.itemList.filter((item) => !item.checked);
    this.selectedItemList.forEach((item) => (item.checked = false));
  }

  removeItem() {
    this.itemList = this.itemList.concat(...this.selectedItemList.filter((item) => item.checked));
    this.selectedItemList = this.selectedItemList.filter((item) => !item.checked);
    this.itemList.forEach((item) => (item.checked = false));
  }

  getCheckedListLength(list: any[] = []): number {
    return list.filter((item) => item.checked).length;
  }

  setItemList(items: any[]) {
    this.originItemList = items;
    let key: 'product_id' | 'customer_group_id' | 'platform_id';

    switch (this.data.target) {
      case SeparationType.CUSTOMER_GROUP: {
        key = 'customer_group_id';
        break;
      }
      default: {
        key = 'platform_id';
      }
    }

    let idsList = this.data.items.map((item) => {
      return item[key];
    });
    this.itemList = items.filter((item) => idsList.indexOf(item[key]) < 0);

    if (this.data.target == SeparationType.CUSTOMER_GROUP) {
      this.selectedItemList = items.filter((item) => idsList.indexOf(item[key]) >= 0);
    }
  }

  getProducts() {
    this.productService
      .getProducts({
        shop_access_code: this.backendService.shop_access_code,
      })
      .subscribe((response) => {
        let items: Product[] = response.body.data as Product[];

        if (this.data.salesPopup) {
          for (let select of this.data.items) {
            this.selectedItemList.push(items.find((item) => item.platform_id == select));
            items = items.filter((item) => item.platform_id !== select);
          }
        }

        this.setItemList(items);
      });
  }

  getCollections() {
    let url = 'products/collections';

    this.backendService
      .select(url, {
        shop_access_code: this.adminService.getShopAccessCode(),
      })
      .subscribe((response) => {
        let items: Collection[] = response.body as Collection[];
        items.forEach((item) => {
          const graphqlIDStrings = item.id.toString().split('/');
          item.platform_id = +graphqlIDStrings[graphqlIDStrings.length - 1];
        });

        if (this.data.salesPopup) {
          for (let select of this.data.items) {
            this.selectedItemList.push(items.find((item) => item.platform_id == select));
            items = items.filter((item) => item.platform_id !== select);
          }
        }

        this.setItemList(items);
      });
  }

  getCustomer() {}

  getCustomerGroup() {
    let url = 'customers/customer-segment';

    this.backendService
      .select(url, {
        shop_access_code: this.adminService.getShopAccessCode(),
      })
      .subscribe((response) => {
        let items: CustomerGroup[] = response.body;
        this.setItemList(items);
      });
  }
}
