import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-delete-app',
  templateUrl: './dialog-delete-app.component.html',
  styleUrl: './dialog-delete-app.component.less',
})
export class DialogDeleteAppComponent implements OnInit {
  selectedReasons: number[] = [];
  uninstallComment: string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogDeleteAppComponent>,
  ) {}


  uninstallReasonDict: uninstallReason[] = [
    { key: 'Technical issues', value: 10 },
    { key: 'Too expensive premium plan', value: 20 },
    { key: 'Swith to another product', value: 30 },
    { key: 'Shutting down the company', value: 40 },
    { key: 'Not sure how to use the tools', value: 50},
    { key: 'Missing features I need', value: 60},
    { key: 'Other(Please explain below)', value: 70}
  ];

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close({
      uninstallReason: this.selectedReasons,
      uninstallComment: this.uninstallComment,
    });
  }

  updateUninstallReasons(value: number): void {
    if (this.selectedReasons.includes(value)) {
      this.selectedReasons = this.selectedReasons.filter(reason => reason !== value);
    } else {
      this.selectedReasons.push(value);
    }
  }

  openLink(link: string): void {
    window.open(link, '_blank');
  }
}

interface uninstallReason {
  key: string;
  value: number;
}