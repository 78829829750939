<article id="account-reset-password">
  <section *ngIf="!isCompleted">
    <section class="account-img">
      <img src="assets/icon/lock_blue.png">
    </section>
    <section>
      <header class="account-title-1">
        Enter your email and we'll send you
        a link to reset your password.
      </header>
    </section>

    <section style="margin-top: 20px;" [formGroup]="formGroup">
      <header class="account-title-2">Email Address</header>
      <input class="admin-input" style="width: 100%; margin-top: 10px;" placeholder="Email" formControlName="email"
        (keydown.enter)="btn.click()">
      <div *ngIf="formGroup.controls['email'].dirty && isFail" class="warning-container">
        <img src="assets/icon/caution_red.svg">
        <div style="margin-left: 8px;">
          Your email is not registered. <br>
          Please check your email account again or <a style="text-decoration: underline;">sign up</a>.
        </div>
      </div>
    </section>

    <section style="margin-top: 20px;">
      <button class="btn-black" style="width: 100%;" #btn (click)="sendPasswordResetEmail()"
        [disabled]="formGroup.controls['email'].invalid && !submitLock">
        Send link to email
      </button>
    </section>
  </section>

  <section *ngIf="isCompleted">
    <section class="account-img">
      <img src="assets/icon/paper_airplane.png">
    </section>
    <section>
      <header class="account-title-1">
        Email has been sent!
      </header>
    </section>

    <summary class="account-content">
      Please check your inbox and click
      in the received link to reset a password.
    </summary>

    <section style="margin-top: 20px;">
      <button class="btn-black" style="width: 100%;" (click)="onConfirm()">
        Login
      </button>
    </section>
  </section>


</article>
